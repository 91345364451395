import React from 'react';
import { Tabs, Tab, Divider, CardContent, Card, styled } from '@mui/material';
import TopAdviserTable from './TopAdviserTable';
import TopInvestorTable from './TopInvestorTable';
import TopOrganisationTable from './TopOrganisationTable';
import TopParentOrganisationTable from './TopParentOrganisationTable';
import { Margin } from 'components/Layout';
import useInitialElementSize from 'hooks/ui/useInitialElementSize';

enum TabEnum {
  TOP_ADVISERS = 'TOP_ADVISERS',
  TOP_INVESTORS = 'TOP_INVESTORS',
  TOP_ORGANISATIONS = 'TOP_ORGANISATIONS',
  TOP_PARENT_ORGANISATIONS = 'TOP_PARENT_ORGANISATIONS',
}
const TableContainer = styled('div')({
  height: '400px',
  overflowY: 'auto',
});

const StyledTabs = styled(Tabs)({
  minHeight: 'auto',
  '& .MuiTabs-flexContainer': {
    gap: '20px',
  },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  padding: theme.spacing(2, 1),
  minHeight: 'auto',
  minWidth: 'unset !important',
  color: theme.palette.text.secondary,
  fontSize: theme.fontSizes?.regular,
  '& .MuiTab-wrapper': {
    display: 'inline',
    textAlign: 'left',
  },
  '&.Mui-selected': {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
}));

const TopEntitiesTables = ({ firmId }) => {
  const [selectedTab, setSelectedTab] = React.useState<TabEnum>(
    TabEnum.TOP_INVESTORS,
  );

  const handleChange = (_event: unknown, newValue: TabEnum) => {
    setSelectedTab(newValue);
  };

  const [ref, { height }] = useInitialElementSize();

  return (
    <Card>
      <CardContent>
        <StyledTabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
        >
          <StyledTab label="Top Investors" value={TabEnum.TOP_INVESTORS} />
          <StyledTab label="Top Advisers" value={TabEnum.TOP_ADVISERS} />
          <StyledTab
            label="Top Organisations"
            value={TabEnum.TOP_ORGANISATIONS}
          />
          <StyledTab
            label="Top Parent Organisations"
            value={TabEnum.TOP_PARENT_ORGANISATIONS}
          />
        </StyledTabs>
        <Margin bottom="md">
          <Divider />
        </Margin>
        <TableContainer ref={ref}>
          {selectedTab === TabEnum.TOP_INVESTORS && (
            <TopInvestorTable firmId={firmId} height={height} />
          )}
          {selectedTab === TabEnum.TOP_ADVISERS && (
            <TopAdviserTable firmId={firmId} height={height} />
          )}
          {selectedTab === TabEnum.TOP_ORGANISATIONS && (
            <TopOrganisationTable
              firmId={firmId}
              type="child"
              height={height}
            />
          )}
          {selectedTab === TabEnum.TOP_PARENT_ORGANISATIONS && (
            <TopParentOrganisationTable firmId={firmId} height={height} />
          )}
        </TableContainer>
      </CardContent>
    </Card>
  );
};
export default TopEntitiesTables;
