import React from 'react';
import { CardContent, Card } from '@mui/material';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import HoverPointer from 'components/HoverPointer';
import { createInvestmentEditRoute } from 'adminConstants/routes';
import Heading from 'components/Heading';

type Props = {
  rows: Array<any>;
  trancheId: string;
};

export const RecentInvestmentsTable: React.FC<Props> = ({
  rows,
  trancheId,
}) => {
  const columns = [
    { sort: false, label: 'Investor', key: 'investorName' },
    {
      sort: false,
      label: 'Subscription date',
      key: 'investmentDate',
      render: (elm) => dateToLabel(elm?.investmentDate),
    },
    {
      sort: false,
      label: 'Subscription amount',
      key: 'investmentAmount',
      render: (elm) => (
        <HoverPointer
          to={createInvestmentEditRoute(elm.investmentId)}
          hoverText="View details"
        >
          {numberToCurrencyString(elm?.investmentAmount, {
            hidePenniesIfInteger: true,
          })}
        </HoverPointer>
      ),
    },
  ];

  return (
    <Card>
      <CardContent>
        <Heading
          variant="h3"
          showDivider
          link={{
            to: `/investment?fundId=${trancheId}`,
            label: 'View all',
          }}
        >
          Recent subscriptions
        </Heading>
        <Table
          columns={columns}
          tablebody={rows}
          pagination={false}
          emptyMessage="No subscriptions yet"
          variant="nohover"
        />
      </CardContent>
    </Card>
  );
};
