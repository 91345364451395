import React from 'react';
import { WithdrawalStatus } from 'further-types/withdrawal';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import { StatusToLabelMap } from 'adminConstants/withdrawalProperties';
import { useGetRole } from 'hooks/ui/useGetRole';
import AlertDialog from 'components/AlertDialog';
import { useDisclosure } from 'further-ui/hooks';
import { pluralize } from 'utils/pluralize';
import PageFilters, { Filter } from 'components/PageFilters';
import { Alert, Typography } from '@mui/material';
import Button from 'components/Button';
import Spacing from 'components/Spacing';

type Props = {
  filters: any;
  handleChangeFilters: any;

  resultCount: number;
  hasExportError: boolean;
  isExportLoading: boolean;
  onExport: (sendToCustodian?: boolean) => void;
};

const FiltersAndActions: React.FC<Props> = ({
  filters,
  handleChangeFilters,
  resultCount,
  hasExportError,
  isExportLoading,
  onExport,
}) => {
  const { isSuperAdmin: downloadOnly } = useGetRole();
  const exportDisclosure = useDisclosure();
  const disableCustodianExportButton =
    resultCount === 0 ||
    [WithdrawalStatus.Rejected, WithdrawalStatus.ExportedToCustodian].includes(
      filters.status,
    );

  const handleCustodianExport = () => {
    exportDisclosure.onClose();
    onExport(true);
  };

  return (
    <>
      <PageFilters
        pageId="withdrawals-review"
        buttons={[
          {
            label: 'General export',
            disabled: resultCount === 0,
            loading: isExportLoading,
            onClick: () => onExport(false),
          },
          {
            visible: !downloadOnly,
            label: 'Accept all and export to custodian',
            onClick: exportDisclosure.onOpen,
            disabled: disableCustodianExportButton,
          },
        ]}
      >
        <Filter>
          <MuiDateRangePicker
            dateRange={{
              startDate: filters.startDate,
              endDate: filters.endDate,
            }}
            onDateRangeChange={(dates) => {
              handleChangeFilters({
                ...dates,
              });
            }}
            TextFieldProps={{ placeholder: 'Select date range' }}
          />
        </Filter>
        <Filter>
          <AppSelectBox
            data={Object.values(WithdrawalStatus).map((status) => ({
              key: status,
              label: StatusToLabelMap[status],
            }))}
            valueKey="key"
            labelKey="label"
            value={filters.status}
            variant="outlined"
            onChange={(event) => {
              const { value } = event.target;
              handleChangeFilters({ status: value });
            }}
          />
        </Filter>
      </PageFilters>
      <AlertDialog
        open={exportDisclosure.isOpen}
        title={`Are you sure you wish to export ${pluralize(
          'this',
          resultCount,
          'these',
        )} ${resultCount} ${pluralize(
          'withdrawal',
          resultCount,
        )} to your custodian?`}
        content={
          <Spacing>
            <p>
              This cannot be undone. Upon exporting these withdrawal requests,
              they will be marked as transferred in the Further system. Exported
              files must be shared with your custodian for actioning.
            </p>
            {hasExportError && (
              <Alert severity="error">
                We were unable to update the status of the withdrawal requests
                in the system. They will still show as requests.{' '}
                <strong>
                  If a file downloaded, please don't sent this file to your
                  custodian.
                </strong>{' '}
                Instead, please refresh the page and try again, or contact{' '}
                <a href="mailto:support@joinfurther.com">
                  <Typography component="span" color="primary" fontSize="small">
                    support@joinfurther.com
                  </Typography>
                </a>{' '}
                for assistance.
              </Alert>
            )}
          </Spacing>
        }
        onClose={exportDisclosure.onClose}
        confirmBtnComponent={
          <Button
            color="primary"
            disabled={!resultCount || hasExportError}
            variant="contained"
            onClick={handleCustodianExport}
            loading={isExportLoading}
          >
            Download export
          </Button>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: false,
        }}
        cancelBtnProps={{
          color: 'primary',
          style: { minWidth: '120px' },
        }}
      />
    </>
  );
};

export default FiltersAndActions;
