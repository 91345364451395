import {
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridCellParams,
  GridColumnVisibilityModel,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { Column, Row, RowsGroup } from '.';
import { PaginationState } from 'further-types/utils';
import { isArray } from 'lodash';
import { GridProSlotProps } from '@mui/x-data-grid-pro/models/gridProSlotProps';

/** Config values */
export const rowHeight = 44;
export const pagSizeOptions = [10, 25, 50, 100];
export const columnVisibilityModelKey = 'columnVisibilityModel';
export const expandIconClass = 'interactive-table-expand-icon';
export const collapseIconClass = 'interactive-table-collapse-icon';
export const sectionCellClass = 'interactive-table-section-cell';
export const sectionSummaryCellClass = 'interactive-table-section-summary-cell';
export const summaryCellClass = 'interactive-table-summary-cell';
export const defaultColumnVisibilityModel: GridColumnVisibilityModel = {
  [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
};

export const getDetailPanelHeight = (): 'auto' => 'auto';
export const getRowHeight = (): number => rowHeight;
export const getTogglableColumns = (columns: Array<Column>) => {
  return columns
    .map((column) => column.field)
    .filter((column) => column !== GRID_DETAIL_PANEL_TOGGLE_FIELD);
};

export const slotProps: GridProSlotProps = {
  columnsManagement: {
    getTogglableColumns,
  },
  columnMenu: {
    slots: {
      sortItem: null,
    },
  },
};

export const getTreeDataPath = (row: Row) => row.section;

/** Helper functions */
export const showIcon = (
  icon: SVGElement | undefined | null,
  position: number,
) => {
  if (!icon) {
    return;
  }

  icon.style['display'] = 'block';
  icon.style['top'] = `${position}px`;
};

export const hideIcon = (icon: SVGElement | undefined | null) => {
  if (!icon) {
    return;
  }

  icon.style['display'] = 'none';
};

export const getIcon = (
  event: React.MouseEvent<HTMLDivElement>,
  iconClass: string,
) =>
  event.currentTarget
    .closest('.interactive-table')
    ?.querySelector<SVGElement>(`& > .${iconClass}`);

export const getSavedModel = <T>(
  tableId: string,
  modelName: string,
  defaultModel: T,
) => {
  const savedModel = localStorage.getItem(
    `interactive-table-${tableId}-${modelName}`,
  );

  try {
    return savedModel ? (JSON.parse(savedModel) as T) : defaultModel;
  } catch (_) {
    return defaultModel;
  }
};

export const saveModel = <T>(tableId: string, modelName: string, model: T) => {
  localStorage.setItem(
    `interactive-table-${tableId}-${modelName}`,
    JSON.stringify(model),
  );
};

export const getSummaryRow = (
  columns: Array<Column>,
  sectionValueField?: keyof Row,
  sectionSummary?: string,
): Row => {
  const summaryRow = {
    id: 'summary-row',
    section: ['summary'],
    isSummary: true,
    ...(sectionValueField ? { [sectionValueField]: sectionSummary } : {}),
  };

  columns.forEach((column) => {
    summaryRow[column.field] = column.summary;
  });
  return summaryRow;
};

export const getSortModel = (pagination?: PaginationState) => {
  const paginationHasSorting = pagination?.orderBy && pagination?.order;

  return paginationHasSorting
    ? [
        {
          field: pagination.orderBy,
          sort: pagination.order,
        },
      ]
    : [];
};

export const getColumns = (columns: Array<Column>) =>
  columns.map((column) => ({
    ...column,
    filterable: false,
    cellClassName: (params: GridCellParams<Row>) => {
      if (params.row.isSummary) {
        return summaryCellClass;
      }
      if (params.row.isGroupSummary) {
        return sectionSummaryCellClass;
      }
      if (params.row.isExpandable) {
        return 'pointer';
      }
      return '';
    },
    renderCell: (params: GridRenderCellParams<Row>) => {
      if (
        params.row.isSummary ||
        params.row.isGroupSummary ||
        params.rowNode.type === 'group'
      ) {
        return params.formattedValue;
      }

      return column.renderCell?.(params) ?? params.formattedValue;
    },
  }));

export const handleRowMouseLeave = (
  event: React.MouseEvent<HTMLDivElement>,
) => {
  const expandIcon = getIcon(event, expandIconClass);
  const collapseIcon = getIcon(event, collapseIconClass);
  hideIcon(collapseIcon);
  hideIcon(expandIcon);
};

export const handleRowHover = (
  row: Row,
  event: React.MouseEvent<HTMLDivElement>,
) => {
  const expandIcon = getIcon(event, expandIconClass);
  const collapseIcon = getIcon(event, collapseIconClass);

  if (row.isExpandable) {
    showIcon(
      row.isExpanded ? collapseIcon : expandIcon,
      rowHeight + 12 + event.currentTarget.offsetTop,
    );
    hideIcon(row.isExpanded ? expandIcon : collapseIcon);
  } else {
    hideIcon(collapseIcon);
    hideIcon(expandIcon);
  }
};

type GetRowsToRenderProps = {
  rows: Array<Row> | RowsGroup;
  columns: Array<Column>;
  showSummaryRow: boolean;
  sectionValueField?: keyof Row;
  sectionSummary?: string;
  expandedRowId?: string;
};

export const getRowsToRender = ({
  rows,
  columns,
  showSummaryRow,
  sectionValueField,
  sectionSummary,
  expandedRowId,
}: GetRowsToRenderProps) => {
  return [
    ...(isArray(rows)
      ? rows
      : Object.keys(rows).flatMap((key) => [
          {
            id: `section-${key}`,
            [sectionValueField ?? key]: rows[key].title,
            isGroup: true,
            section: [key],
          },
          ...rows[key].rows.map((row) => ({
            ...row,
            isExpanded: row.id === expandedRowId,
            section: [key, row.id],
          })),
          ...(rows[key].summary
            ? [
                {
                  section: [key, 'section-summary'],
                  id: `section-${key}-summary`,
                  isGroupSummary: true,
                  ...rows[key].summary,
                },
              ]
            : []),
        ])),
    ...(showSummaryRow
      ? [getSummaryRow(columns, sectionValueField, sectionSummary)]
      : []),
  ];
};
