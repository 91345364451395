import { FC } from 'react';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import { MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useListFirmBankDetails } from 'hooks/data/firm/useFirmBankDetails';
import { Api } from 'further-types/firm';
import { firmBankDetailsDisplay } from 'further-ui/utils';

const useStyles = makeStyles()(() => ({
  placeholder: {
    color: '#999999',
    paddingRight: '1rem',
  },
}));

const renderLabel = (
  firmBankDetails: Api.FirmBankDetailsResponse,
  value: string,
  labelOverride?: string,
) => {
  if (labelOverride) return labelOverride;

  const bankDetails = firmBankDetails?.find(
    (bankDetails) => bankDetails._id === value,
  );

  return bankDetails ? firmBankDetailsDisplay(bankDetails) : '';
};

type Props = {
  firmId: string;
  currentValue?: string;
  onChange: (bankDetailsId: string, bankDetails?: Api.FirmBankDetail) => void;
  placeholder: string;
  allowDeselection?: boolean;
  deselectionLabel?: string;
  allowCashBalanceSelection?: boolean;
  error?: boolean;
  helperText?: string;
};

export const CashBalanceOptionValue = 'CASH_BALANCE';

export const FirmBankDetailsSelector: FC<Props> = ({
  firmId,
  currentValue,
  onChange,
  placeholder,
  allowDeselection,
  deselectionLabel = 'None',
  allowCashBalanceSelection,
  ...rest
}) => {
  const firmBankDetails = useListFirmBankDetails(firmId);
  const { classes } = useStyles();

  const valueKey = '_id';
  const labelOverrideKey = 'label';

  const emptyOption = { _id: null, [labelOverrideKey]: deselectionLabel };
  const cashBalanceOption = {
    _id: CashBalanceOptionValue,
    [labelOverrideKey]: 'Cash balance payment',
  };
  const customOptions = [emptyOption, cashBalanceOption];
  const currentLabelOverride = customOptions.find(
    (option) => option._id === currentValue,
  )?.[labelOverrideKey];

  const hasNoOptions =
    firmBankDetails.data?.length === 0 && !allowCashBalanceSelection;

  return (
    <AppSelectBox
      required
      data={
        allowDeselection
          ? [
              emptyOption,
              ...(firmBankDetails.data ? firmBankDetails.data : []),
              ...(allowCashBalanceSelection ? [cashBalanceOption] : []),
            ]
          : [
              ...(firmBankDetails.data ? firmBankDetails.data : []),
              ...(allowCashBalanceSelection ? [cashBalanceOption] : []),
            ]
      }
      valueKey={valueKey}
      fullWidth
      value={currentValue ?? undefined}
      variant="outlined"
      disabled={firmBankDetails.isLoading || hasNoOptions}
      renderRow={(item) => (
        <MenuItem key={item[valueKey]} value={item[valueKey]}>
          {renderLabel(
            firmBankDetails.data ?? [],
            item[valueKey],
            item[labelOverrideKey],
          )}
        </MenuItem>
      )}
      renderValue={() => {
        if (firmBankDetails.isLoading)
          return <span className={classes.placeholder}>Loading...</span>;
        if (hasNoOptions)
          return (
            <span className={classes.placeholder}>No bank details found</span>
          );
        if (!currentValue)
          return <span className={classes.placeholder}>{placeholder}</span>;

        return renderLabel(
          firmBankDetails.data ?? [],
          currentValue,
          currentLabelOverride,
        );
      }}
      onChange={(e) => {
        const bankDetailsId = e.target.value as string;
        const bankDetails = firmBankDetails.data?.find(
          (item) => item?._id === bankDetailsId,
        );
        onChange(bankDetailsId, bankDetails);
      }}
      {...rest}
    />
  );
};
