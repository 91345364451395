import AlertDialog from 'components/AlertDialog';
import Typography from '@mui/material/Typography';

interface NoBankAccountWithdrawalModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

const NoBankAccountWithdrawalModal = ({
  onConfirm,
  onCancel,
  isOpen,
}: NoBankAccountWithdrawalModalProps) => {
  return (
    <AlertDialog
      open={isOpen}
      title={'No bank account for one or more investors'}
      content={
        <Typography textAlign="center">
          One or more of these investors has empty or incomplete bank details on
          their profile. Proceeding will result in a withdrawal file with
          missing bank details. Bank details can be edited by the investor on
          their “My account” page.
        </Typography>
      }
      onConfirm={onConfirm}
      onClose={onCancel}
      btnLabels={{
        confirm: 'Proceed',
        cancel: 'Go back',
      }}
    />
  );
};

export default NoBankAccountWithdrawalModal;
