import React from 'react';
import { createClosedTrancheRoute } from 'adminConstants/routes';
import { numberToCurrencyString } from 'further-ui/utils';
import { numberToPercentageString } from 'utils/numbers';
import Box from 'components/Box';
import { CardContent, Card } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from 'tss-react/mui';
import GridContainer from 'components/GridContainer';
import CompanyCategoryPieChart from '../CompanyCategoryPieChart';
import { ShowChart } from '@mui/icons-material';
import Heading from 'components/Heading';

const useStyles = makeStyles()(() => ({
  flexWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  pieWrapper: {
    marginTop: '3rem',
  },
  centeredPieWrapper: {
    marginTop: '3rem',
    display: 'flex',
    flex: 1,
  },
  centeredPie: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

type Props = {
  deploymentSummary: Record<string, any>;
  tranche?: Record<string, any>;
  classes: Record<string, string>;
  displayFundsRaisedInsteadOfDeployed?: boolean;
};

export const TrancheDeploymentSummary: React.FC<Props> = ({
  deploymentSummary,
  tranche,
  classes,
  displayFundsRaisedInsteadOfDeployed,
}) => {
  const { classes: componentClasses } = useStyles();

  const percentageRemainingToDeploy = numberToPercentageString(
    deploymentSummary?.remainingAmountToDeploy /
      deploymentSummary?.totalAmountInvestedIntoTranche,
  );
  const percentageDeployed = numberToPercentageString(
    deploymentSummary?.totalAmountDeployed /
      deploymentSummary?.totalAmountInvestedIntoTranche,
  );

  const centerPies = false;

  const showDetailedHeader = tranche && tranche.fundName && tranche._id;
  const title = showDetailedHeader
    ? `${tranche.firmId?.firmName} - ${tranche.fundName}`
    : 'Tranche deployment summary';

  const headingLink = showDetailedHeader
    ? { to: createClosedTrancheRoute(tranche._id), label: 'View dashboard' }
    : undefined;

  return (
    <Card
      className={
        centerPies
          ? `${componentClasses.flexWrapper} ${classes.flexGrow}`
          : classes.flexGrow
      }
    >
      <CardContent className={centerPies ? componentClasses.flexWrapper : ''}>
        <Heading variant="h3" icon={ShowChart} link={headingLink} showDivider>
          {title}
        </Heading>
        <GridContainer>
          <Grid size={{ xs: 6 }}>
            <Box
              title={numberToCurrencyString(
                deploymentSummary?.remainingAmountToDeploy,
                { hidePenniesIfInteger: true },
              )}
              titleTooltip={`${percentageRemainingToDeploy} remaining`}
              subtitle="Remaining to deploy"
            ></Box>
          </Grid>
          <Grid size={{ xs: 6 }}>
            {displayFundsRaisedInsteadOfDeployed ? (
              <Box
                title={numberToCurrencyString(
                  deploymentSummary?.totalAmountInvestedIntoTranche,
                  { hidePenniesIfInteger: true },
                )}
                subtitle="Funds raised"
              ></Box>
            ) : (
              <Box
                title={numberToCurrencyString(
                  deploymentSummary?.totalAmountDeployed,
                  { hidePenniesIfInteger: true },
                )}
                titleTooltip={`${percentageDeployed} deployed`}
                subtitle="Deployed"
              ></Box>
            )}
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Box
              title={deploymentSummary?.countOfInvestments}
              subtitle="Subscriptions"
            />
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Box
              title={deploymentSummary?.countOfCompanies}
              subtitle="Companies"
            />
          </Grid>
        </GridContainer>

        <GridContainer
          className={
            centerPies
              ? componentClasses.centeredPieWrapper
              : componentClasses.pieWrapper
          }
        >
          <Grid
            size={{ md: 6, sm: 12 }}
            className={centerPies ? componentClasses.centeredPie : ''}
          >
            <CompanyCategoryPieChart
              title="Subscriptions by stage"
              subtitle="(Ordered by current value)"
              firmId={tranche?.firmId?._id}
            />
          </Grid>
          <Grid
            size={{ md: 6, sm: 12 }}
            className={centerPies ? componentClasses.centeredPie : ''}
          >
            <CompanyCategoryPieChart
              title="Subscriptions by sector"
              subtitle="(Ordered by current value)"
              firmId={tranche?.firmId?._id}
            />
          </Grid>
        </GridContainer>
      </CardContent>
    </Card>
  );
};
