import { type as feeConst } from 'adminConstants/typeConstant';

export const displayFeeAccountingType = (
  feeAccounting?: string,
  viaExit?: boolean,
) => {
  switch (feeAccounting) {
    case feeConst.feeAccounting.accrued:
      return 'Accrued';
    case feeConst.feeAccounting.chargedToInvestor:
      return viaExit ? 'Charged on exit' : 'Charged to investor';
    default:
      return '';
  }
};
