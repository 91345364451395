import React from 'react';
import _ from 'lodash';
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from 'tss-react/mui';
import Heading from 'components/Heading';

const useStyles = makeStyles()((theme) => ({
  note: {
    fontSize: 16,
    color: '#656565',
    marginBottom: theme.spacing(6),
  },
  TableHead: {
    backgroundColor: 'rgba(234, 233, 232, .2)',
  },
  TableHeadCell: {
    minWidth: 120,
    borderBottomColor: '#EDEDED',
    lineHeight: '22px',
    fontSize: 12,
    fontWeight: 600,
    fontStyle: 'normal',
  },
  TableBodyCell: {
    borderBottomColor: '#EDEDED',
  },
}));

type Props = any;

const ErrorsTable: React.FC<Props> = ({ errors, title, description }) => {
  const { classes } = useStyles();

  // group errors by rowId and join the messages
  const groupedErrors = _.chain(errors)
    .groupBy('rowId')
    .map((group, rowId) => ({
      rowId,
      rowFullName: group[0].rowFullName,
      message: group.map((error) => error.message).join(', '),
    }));

  return (
    <>
      <Grid size={{ md: 12 }} className="mt-8">
        <Heading variant="h2">{title || 'Validation error'}</Heading>
        <div className={classes.note}>
          {description ||
            'The required fields have not been entered correctly above. Please see below for more information and try again.'}
        </div>
        <Table>
          <TableHead className={classes.TableHead}>
            <TableRow>
              <TableCell className={classes.TableHeadCell}>
                Row number
              </TableCell>
              <TableCell className={classes.TableHeadCell}>Name</TableCell>
              <TableCell className={classes.TableHeadCell}>Errors</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedErrors.value().map((row) => {
              return (
                <TableRow key={row.rowId + 1}>
                  <TableCell className={`${classes.TableBodyCell}`}>
                    {parseInt(row.rowId) + 1}
                  </TableCell>
                  <TableCell className={classes.TableBodyCell}>
                    {row.rowFullName}
                  </TableCell>
                  <TableCell className={classes.TableBodyCell}>
                    {row.message}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </>
  );
};

export default ErrorsTable;
