import { Button } from '@mui/material';
import React, { MouseEventHandler } from 'react';

type Props = {
  visible: boolean;
  buttonText: string;
  onClick: MouseEventHandler;
};

const KycResultsButton: React.FC<Props> = ({
  visible,
  buttonText,
  onClick,
}) => {
  return visible ? (
    <Button
      size="extra-small"
      variant="outlined"
      color="primary"
      onClick={onClick}
    >
      {buttonText}
    </Button>
  ) : null;
};

export default KycResultsButton;
