import { Avatar, Button, Input } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useState } from 'react';

const useStyles = makeStyles()(() => ({
  newNoteContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  avatar: {
    border: '3px solid white',
    boxShadow: '0px 4px 20px 0px #8689A540',
    height: '56px',
    width: '56px',
  },
  input: {
    border: '1px solid #C4C4C4',
    borderRadius: '2px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '10px',
  },
  button: {
    width: '100px',
    height: '40px',
    alignSelf: 'flex-end',
  },
}));

type NewNoteProps = {
  submitNote: (note: string) => void;
  image: string;
};

export const NewNote: React.FC<NewNoteProps> = ({ submitNote, image }) => {
  const { classes } = useStyles();

  const [note, setNote] = useState('');
  const [error, setError] = useState(false);

  return (
    <div className={classes.newNoteContainer}>
      <Avatar src={image} className={classes.avatar} />
      <div className={classes.input}>
        <Input
          type="text"
          multiline
          placeholder="Add your note..."
          fullWidth
          rows={3}
          value={note}
          onChange={(e) => {
            setError(false);
            setNote(e.target.value);
          }}
          error={error}
        />
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={() => {
            if (!note) {
              setError(true);
              return;
            }
            setNote('');
            submitNote(note);
          }}
        >
          Add Note
        </Button>
      </div>
    </div>
  );
};
