import { styled, Box } from '@mui/material';

const ButtonGroup = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  gap: '10px',
  justifyContent: 'flex-end',
});

export default ButtonGroup;
