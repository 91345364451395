import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import qs from 'qs';
async function fetchTopInvestors({ queryKey }) {
  const [, params] = queryKey;

  const queryParams = qs.stringify(params);

  const url = params.firmId
    ? `dashboard/top-investors/?${queryParams}`
    : `dashboard/top-investors`;

  const { data } = await api.get(url);

  return data.data;
}

function useTopInvestors({ params }) {
  return useQuery({
    queryKey: ['dashboard-top-investors', params],
    queryFn: fetchTopInvestors,
    placeholderData: keepPreviousData,
    staleTime: 4 * 60 * 60 * 1000,
  });
}

export default useTopInvestors;
