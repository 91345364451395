import PageContainer from 'components/PageContainer';
import Table, { TableActions } from 'components/Table';
import useTaxYears from 'hooks/data/taxReport/useTaxYears';
import { usePagination } from 'hooks/ui/usePagination';
import { dateToLabel } from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import { TaxYearStatus } from 'further-types/annual-tax-report';
import { FC, useState } from 'react';
import { useGetRole } from 'hooks/ui/useGetRole';
import { CircularProgress, Switch } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CloudDownload } from '@mui/icons-material';
import useTaxReportAudit from 'hooks/data/taxReport/useTaxReportAudit';
import NoResponse from 'components/SVGIcons/NoResponse';
import { capitalize, startCase } from 'lodash';
import AlertDialog from 'components/AlertDialog';
import PageContent from 'components/PageContent';
import { styled } from '@mui/material';

type TaxYear = ReturnType<
  typeof useTaxYears
  //@ts-expect-error
>['taxYears']['data']['results'][number];

const useStyles = makeStyles()(() => ({
  switch: {
    display: 'flex',
    gridGap: '0.5rem',
  },
}));

const ActionContainer = styled('div')({
  display: 'flex',
  justifyContent: 'start',
});

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Investor reporting' },
  {
    label: 'Tax report audit',
    isActive: true,
  },
];

const TaxReportAudit: FC = () => {
  const { classes } = useStyles();
  const { isSuperAdmin } = useGetRole();
  const approvalDisclosure = useDisclosure<{ taxYear: TaxYear }>();
  const [downloadingRow, setDownloadingRow] = useState<string>('');

  const pagination = usePagination({
    orderBy: 'taxYearStart',
    order: 'desc',
    id: 'tax-report-audit',
  });

  const { page, rowsPerPage } = pagination;
  const { taxYears, updateTaxYear } = useTaxYears({ page, rowsPerPage });
  const { download, isDownloading } = useTaxReportAudit();

  const handleDownload = async (taxYearStart: number, firmId: string) => {
    const rowId = `${taxYearStart}-${firmId}`;
    setDownloadingRow(rowId);

    await download(taxYearStart, firmId);
  };

  const handleUpdate = (
    taxYear: TaxYear,
    status: TaxYearStatus,
    taxReportDisabled: boolean,
  ) =>
    updateTaxYear.mutate({
      taxYearStart: taxYear.taxYearStart,
      firmId: taxYear.firmId,
      status,
      taxReportDisabled,
    });

  const columns = [
    {
      label: 'Tax year',
      sort: false,
      key: 'taxYear',
      render: (elm: TaxYear) =>
        isSuperAdmin
          ? `${elm.firmName} - ${elm.taxYearStart}/${elm.taxYearStart + 1}`
          : `${elm.taxYearStart}/${elm.taxYearStart + 1}`,
    },
    {
      label: 'Approval deadline',
      sort: false,
      key: 'approvalDeadline',
      render: (elm: TaxYear) => dateToLabel(elm.approvalDeadline),
    },
    {
      label: 'Approved on',
      sort: false,
      key: 'approvedAt',
      render: (elm: TaxYear) => dateToLabel(elm.approvedAt),
    },
    {
      label: 'Status',
      sort: false,
      key: 'status',
      render: (elm: TaxYear) =>
        elm.status === TaxYearStatus.NoResponse ? (
          capitalize(startCase(TaxYearStatus.NoResponse))
        ) : (
          <div className={classes.switch}>
            <span>Unapproved</span>
            <Switch
              disabled={
                elm.status === TaxYearStatus.Approved || updateTaxYear.isPending
              }
              checked={elm.status === TaxYearStatus.Approved}
              onChange={(e) => {
                if (e.target.checked) {
                  approvalDisclosure.onOpen();
                  approvalDisclosure.stageAction({
                    taxYear: elm,
                  });
                }
              }}
            />
            <span>Approved</span>
          </div>
        ),
    },
    ...(isSuperAdmin
      ? [
          {
            label: 'Disabled',
            key: 'disabled',
            sort: false,
            render: (elm: TaxYear) => (
              <div className={classes.switch}>
                <span>No</span>
                <Switch
                  disabled={elm.status === TaxYearStatus.Approved}
                  checked={elm.taxReportDisabled}
                  onChange={(e) => {
                    handleUpdate(elm, elm.status, !!e.target.checked);
                  }}
                />
                <span>Yes</span>
              </div>
            ),
          },
        ]
      : []),
    {
      label: 'Action',
      key: 'actions',
      sort: false,
      render: (elm: TaxYear) => {
        const rowId = `${elm.taxYearStart}-${elm.firmId}`;

        return (
          <ActionContainer>
            <TableActions
              showAsDropdown
              actions={[
                {
                  label: 'Download report',
                  color: 'primary',
                  icon:
                    isDownloading && downloadingRow === rowId
                      ? (props) => <CircularProgress {...props} size={24} />
                      : CloudDownload,
                  onClick: () => handleDownload(elm.taxYearStart, elm.firmId),
                  disabled: isDownloading && downloadingRow === rowId,
                },
                {
                  label: 'Mark as no response',
                  icon: NoResponse,
                  visible:
                    isSuperAdmin && elm.status === TaxYearStatus.NotApproved,
                  onClick: () =>
                    handleUpdate(
                      elm,
                      TaxYearStatus.NoResponse,
                      elm.taxReportDisabled,
                    ),

                  disabled: updateTaxYear.isPending,
                },
              ]}
            />
          </ActionContainer>
        );
      },
    },
  ];

  return (
    <PageContainer heading={'Tax report audit'} breadcrumbs={breadcrumbs}>
      <PageContent>
        <Table
          columns={columns}
          onRequestSort={pagination.handleRequestSort}
          order={pagination.order}
          orderBy={pagination.orderBy}
          tablebody={taxYears.data?.results ?? []}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          pagination={true}
          count={taxYears.data?.total ?? 0}
          loading={taxYears.isFetching}
          variant="nohover"
        />
        <AlertDialog
          open={approvalDisclosure.isOpen}
          onClose={approvalDisclosure.onClose}
          onConfirm={() => {
            handleUpdate(
              approvalDisclosure.actionProps.taxYear,
              TaxYearStatus.Approved,
              false,
            );
            approvalDisclosure.onClose();
          }}
          title="Approve report data"
          content={
            <p>
              Do you wish to approve your tax report data? You cannot undo this
              action once it is confirmed.
            </p>
          }
          btnLabels={{
            cancel: 'Go back',
            confirm: 'Approve',
          }}
        />
      </PageContent>
    </PageContainer>
  );
};
export default TaxReportAudit;
