import { z } from 'zod';

const schema = z
  .object({
    beneficiaryName: z
      .string({ required_error: 'Please enter a beneficiary name.' })
      .min(1, 'Please enter a beneficiary name.'),
    beneficiaryAddress: z
      .string({ required_error: 'Please enter a beneficiary address.' })
      .min(1, 'Please enter a beneficiary address.')
      .optional()
      .or(z.literal('')),
    bankName: z
      .string({ required_error: 'Please enter a bank name.' })
      .min(1, 'Please enter a bank name.')
      .optional()
      .or(z.literal('')),
    bankAddress: z
      .string({ required_error: 'Please enter a bank address.' })
      .min(1, 'Please enter a bank address.')
      .optional()
      .or(z.literal('')),
    bankAccountNumber: z
      .string({ required_error: 'Please enter a bank account number.' })
      .min(1, 'Please enter a bank account number.')
      .regex(/^[0-9]{8}$/g, {
        message: 'Please enter an 8 digit account number.',
      })
      .optional()
      .or(z.literal('')),
    bankSortCode: z
      .string({ required_error: 'Please enter a bank account sort code.' })
      .min(1, 'Please enter a bank account sort code.')
      .regex(/^[0-9]{2}-?[0-9]{2}-?[0-9]{2}$/, {
        message: 'Please enter a 6 digit account sort code.',
      })
      .optional()
      .or(z.literal('')),
    iban: z
      .string({ required_error: 'Please enter an IBAN.' })
      .min(1, 'Please enter an IBAN.')
      .optional()
      .or(z.literal('')),
    swift: z
      .string({ required_error: 'Please enter a SWIFT.' })
      .min(1, 'Please enter a SWIFT.')
      .optional()
      .or(z.literal('')),
  })
  .refine(
    (data) => data.iban || (data.bankAccountNumber && data.bankSortCode),
    {
      message:
        'Either IBAN, or both account number and sort code are required.',
      path: ['ibanOrAccountNumberAndSortCodeMissing'],
    },
  );

export default schema;
