import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { useNotification } from 'hooks/ui/useNotification';

// TODO: Duplicate type defined in services/Investor/upload-investor-cash-balance.ts
type Request = {
  rowIdx: number;
  email: string;
  fundId: string;
  investmentAmount: number;
  adviserFee?: number;
};

// TODO: Duplicate type defined in services/Investor/upload-investor-cash-balance.ts
type Response = {
  rowIdx: number;
  cashBalance: number;
};

async function getInvestorCashBalanceById({
  investorId,
  firmId,
}: {
  investorId: string;
  firmId: string;
}): Promise<{ data: number } | never> {
  const { data: response } = await api.post(
    `investor/cash-balance/${investorId}`,
    {
      firmId,
    },
  );
  return response;
}

export function useInvestorCashBalanceById() {
  const notification = useNotification();
  const mutation = useMutation({
    mutationFn: getInvestorCashBalanceById,
  });

  useEffect(() => {
    // @ts-expect-error
    if (mutation?.error?.response?.data?.responseMsg) {
      // @ts-expect-error
      notification.error(mutation.error.response.data.responseMsg);
    }
  }, [mutation.error]);

  return mutation;
}

async function getInvestorCashBalance(
  data: Request,
): Promise<Response | never> {
  const { data: response } = await api.post(
    'investor/upload-investor-investment/cash-balance',
    data,
  );
  return response;
}

export function useUploadedInvestorCashBalance() {
  const notification = useNotification();
  const mutation = useMutation({
    mutationFn: getInvestorCashBalance,
  });

  useEffect(() => {
    // @ts-expect-error
    if (mutation?.error?.response?.data?.responseMsg) {
      // @ts-expect-error
      notification.error(mutation.error.response.data.responseMsg);
    }
  }, [mutation.error]);

  return mutation;
}
