import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  tableHeader: {
    padding: 12,
    color: theme.palette.text.tableText,
    backgroundColor: theme.palette.background.header,
  },
  investmentHeader: {
    display: 'flex',

    justifyContent: 'space-between',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: theme.spacing(4),
    },
  },
  tableButtons: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& button': {
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
      },
    },
  },
  totalValueLabel: {
    minWidth: 150,
  },
  tooltip: {
    backgroundColor: theme.palette.text.white,
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(3),
    color: theme.palette.text.error,
    boxShadow: theme.shadows[8],
  },
  transferNotAvailableMessage: {
    borderBottomColor: theme.palette.borderColor?.main,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  tableFooter: {
    color: theme.palette.text.tableText,
    backgroundColor: theme.palette.background.header,
    '& td': {
      padding: '18px 6px 15px',
    },
  },
}));

export default useStyles;
