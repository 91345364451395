import { useAuth0 } from '@auth0/auth0-react';
import api from 'lib/trpcClient';

export default function useCurrentUser() {
  const apiUtils = api.useUtils();
  const { isAuthenticated } = useAuth0();

  return {
    profile: api.adminUser.profile.useQuery(undefined, {
      retry: 1,
      enabled: isAuthenticated,
      placeholderData: undefined,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
    }),
    updateProfile: api.adminUser.updateProfile.useMutation({
      onSuccess: () => {
        apiUtils.adminUser.profile.invalidate();
      },
    }),
  };
}
