import { Controller } from 'react-hook-form';
import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';
import Spacing from 'components/Spacing';

const GeneralInformation = ({ control, errors }) => {
  return (
    <Spacing>
      <FieldRow title="Firm name*" centerTitle>
        <Controller
          name="firmName"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="e.g. Acme Ventures"
              helperText={errors.firmName?.message}
              fullWidth
            />
          )}
        />
      </FieldRow>

      <FieldRow title="Key contact name*" centerTitle>
        <Controller
          name="keyContactName"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Who is your firm's main user of Further?"
              helperText={errors.keyContactName?.message}
              fullWidth
            />
          )}
        />
      </FieldRow>

      <FieldRow title="Key contact email address*" centerTitle>
        <Controller
          name="keyContactEmail"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Please enter their email address"
              helperText={errors.keyContactEmail?.message}
              fullWidth
            />
          )}
        />
      </FieldRow>
    </Spacing>
  );
};

export default GeneralInformation;
