import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/firm';

export function useListFirmBankDetails(firmId?: string) {
  return useQuery({
    queryKey: ['firm-bank-details', firmId],
    queryFn: async () =>
      (
        await api.get<ApiResponse<Api.FirmBankDetailsResponse>>(
          `/firm/${firmId}/bank-details`,
        )
      ).data.data,
    enabled: !!firmId,
    placeholderData: keepPreviousData,
    staleTime: 10 * 60 * 1000,
  });
}

export function useAddFirmBankDetails(firmId: string) {
  const queryClient = useQueryClient();

  return useMutation<
    Api.MutateFirmBankDetailResponse,
    unknown,
    Api.CreateFirmBankDetailBody
  >({
    mutationFn: async (data) => {
      const { data: result } = await api.post<
        ApiResponse<Api.MutateFirmBankDetailResponse>
      >(`/firm/${firmId}/bank-details`, data);

      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['firm-bank-details', firmId],
      });
    },
  });
}

export function useEditFirmBankDetails(firmId: string, bankDetailId: string) {
  const queryClient = useQueryClient();

  return useMutation<
    Api.MutateFirmBankDetailResponse,
    unknown,
    Api.CreateFirmBankDetailBody
  >({
    mutationFn: async (data) => {
      const { data: result } = await api.put<
        ApiResponse<Api.MutateFirmBankDetailResponse>
      >(`/firm/${firmId}/bank-details/${bankDetailId}`, data);

      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['firm-bank-details', firmId],
      });
    },
  });
}

export function useDeleteFirmBankDetails(firmId: string) {
  const queryClient = useQueryClient();

  return useMutation<
    Api.MutateFirmBankDetailResponse,
    unknown,
    { bankDetailId: string }
  >({
    mutationFn: async (data) => {
      const { data: result } = await api.delete<
        ApiResponse<Api.MutateFirmBankDetailResponse>
      >(`/firm/${firmId}/bank-details/${data.bankDetailId}`);

      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['firm-bank-details', firmId],
      });
    },
  });
}
