import React from 'react';

import { Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from 'tss-react/mui';

import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import GridContainer from 'components/GridContainer';
import { INTEREST_PAYMENTS_ADD } from 'adminConstants/routes';
import { Link } from 'react-router-dom';

export const optionValueAll = 'ALL';

const useStyles = makeStyles()(() => ({
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
    marginTop: '15px',
  },
}));

type Props = any; //TODO: define types

const InterestPaymentFilter: React.FC<Props> = ({
  filters,
  handleChangeFilters,
}) => {
  const { classes } = useStyles();
  return (
    <GridContainer>
      <Grid size={{ xs: 12 }}>
        <GridContainer spacing={3}>
          <Grid size={{ md: 4, xs: 12 }}>
            <label className={classes.label} htmlFor="uploadDateRange">
              Select upload date range
            </label>
            <MuiDateRangePicker
              dateRange={{
                startDate: filters.uploadDateRange.startDate,
                endDate: filters.uploadDateRange.endDate,
              }}
              onDateRangeChange={(dateRange) => {
                filters.uploadDateRange.startDate = dateRange.startDate;
                filters.uploadDateRange.endDate = dateRange.endDate;
                handleChangeFilters({ ...filters });
              }}
              TextFieldProps={{ placeholder: 'Select date' }}
            />
          </Grid>
          <Grid size={{ md: 4, xs: 12 }}>
            <label className={classes.label} htmlFor="paymentDateRange">
              Select payment date range
            </label>
            <MuiDateRangePicker
              dateRange={{
                startDate: filters.paymentDateRange.startDate,
                endDate: filters.paymentDateRange.endDate,
              }}
              onDateRangeChange={(dateRange) => {
                filters.paymentDateRange.startDate = dateRange.startDate;
                filters.paymentDateRange.endDate = dateRange.endDate;
                handleChangeFilters({ ...filters });
              }}
              TextFieldProps={{ placeholder: 'Select date' }}
            />
          </Grid>
          <Grid size={{ md: 4, xs: 12 }}>
            <div className={classes.actions}>
              <Link to={INTEREST_PAYMENTS_ADD}>
                <Button color="primary" variant="contained">
                  Upload new interest payments
                </Button>
              </Link>
            </div>
          </Grid>
        </GridContainer>
      </Grid>
    </GridContainer>
  );
};

export default InterestPaymentFilter;
