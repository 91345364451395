import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { removeEmptyParagraphs } from 'further-ui/utils';
import { makeStyles } from 'tss-react/mui';

//@ts-expect-error
const useStyles = makeStyles()(() => ({
  editor: {
    '& .ck.ck-editor__editable_inline': {
      padding: '0 0.5rem !important',
    },
    '& .ck-editor__editable ol, & .ck-editor__editable ul': {
      margin: '1rem 0 1rem 0 !important',
      paddingLeft: '1.5rem',

      listStylePosition: 'outside !important',
    },
    '& .ck-editor__editable ul, & .ck-editor__editable ul > li': {
      listStyle: 'disc outside !important',
    },
    '& .ck-editor__editable ul ul > li': {
      listStyleType: 'disc !important',
    },
    '& .ck-editor__editable ul ul ul > li': {
      listStyleType: 'disc !important',
    },
    '& .ck-editor__editable ol, & .ck-editor__editable ol > li': {
      listStyle: 'decimal outside !important',
    },
    '& .ck-editor__editable li': {
      position: 'relative',
      marginBottom: '0.5rem',
      lineHeight: 1.5,
      color: 'var(--txt-common)',
    },
    '& .ck-editor__editable li:last-child': {
      marginBottom: 0,
    },
    '& .ck-editor__editable ul ul, & .ck-editor__editable ol ol, & .ck-editor__editable ul ol, & .ck-editor__editable ol ul':
      {
        margin: '0.5rem 0 0 0 !important',
        paddingLeft: '1.5rem',
      },
    '& .ck-editor__editable li > p': {
      display: 'inline',
      margin: 0,
    },
    '& .ck-editor__editable ol ul': {
      margin: '0.5rem 0 0 0 !important',
      paddingLeft: '1.1rem !important',
    },
  },
}));

const removeWatermark = (editors) => {
  const observer = new MutationObserver(() => {
    const poweredByElements = document.querySelectorAll(
      '.ck-powered-by, .ck-powered-by-balloon',
    );
    poweredByElements.forEach((element) => element.remove());
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });

  editors.on('destroy', () => {
    observer.disconnect();
  });
};

const CkEditor = ({
  value,
  onChange,
  placeholder,
  disabled,
  onBlur,
  extraLine = false,
  showLists = true,
}) => {
  const { classes } = useStyles();

  const handlePaste = (event, data, editor) => {
    const textData = data.getData('text/html') || data.getData('text/plain');

    //if we detect a word document, we don't want to sanitize it and let CKEditor handle it
    if (isWordDocument(textData)) {
      return;
    }

    event.stop();

    const sanitizedText = removeEmptyParagraphs(textData);

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sanitizedText;

    // Clean up any remaining styles
    tempDiv.querySelectorAll('[style]').forEach((el) => {
      el.removeAttribute('style');
    });

    editor.model.change(() => {
      const viewFragment = editor.data.processor.toView(tempDiv.innerHTML);

      const modelFragment = editor.data.toModel(viewFragment);
      editor.model.insertContent(
        modelFragment,
        editor.model.document.selection,
      );
    });
  };

  return (
    <div className={classes.editor}>
      <CKEditor
        className="document-editor-editor"
        editor={DecoupledEditor}
        onReady={(editors) => {
          // Insert the toolbar before the editable area.
          editors.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editors.ui.view.toolbar.element,
              editors.ui.getEditableElement(),
            );
          if (extraLine) {
            editors.editing.view.change((writer) => {
              writer.setStyle(
                'min-height',
                '120px',
                editors.editing.view.document.getRoot(),
              );
            });
          }

          removeWatermark(editors);

          const editorElement = editors.ui.getEditableElement();
          editorElement.classList.add('text-component');
          // Listen for the paste event
          editors.editing.view.document.on('clipboardInput', (event, data) => {
            const { dataTransfer } = data;

            handlePaste(event, dataTransfer, editors);
          });
        }}
        config={{
          toolbar: [
            '|',
            'bold',
            'italic',
            'link',
            'underline',
            ...(showLists ? ['bulletedList', 'numberedList'] : []),
          ],
          list: {
            properties: {
              styles: false,
              startIndex: false,
              reversed: false,
            },
          },
          placeholder,
          pasteFromOffice: {
            keepZeroMargins: true,
            removeFontBackgroundColor: true,
            removeStyles: true,
            converterOptions: {
              preserveListIndentation: true,
            },
          },
          removePlugins: !showLists
            ? [
                'List',
                'ListUI',
                'ListStyle',
                'ListProperties',
                'TodoList',
                'ListEditing',
                'IndentList',
              ]
            : [],
        }}
        disabled={disabled}
        data={value || ''}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

const isWordDocument = (text) => {
  // Check for common Word document HTML markers
  const wordMarkers = [
    'urn:schemas-microsoft-com:office:word',
    'xmlns:w="urn:schemas-microsoft-com:office:word"',
    '<w:WordDocument>',
    'mso-',
    '<o:OfficeDocumentSettings>',
  ];

  return wordMarkers.some((marker) => text.includes(marker));
};

export default CkEditor;
