import { countryDropdownList } from 'further-ui/labels';
import {
  numberToCurrencyString,
  numberToCurrencyDefaultOptions,
} from 'further-ui/utils';
import { KycRiskRating } from 'further-types/kyc';
import { AccountType } from 'further-types/investor';
import { paymentEmailOptions } from 'further-ui/labels';
import {
  emailValidator,
  textValidator,
  decimalValidator,
  dateValidator,
  customDropdownValidator,
  bankAccountValidator,
  sortCodeValidator,
  phoneValidator,
} from './validators';
import { capitalize } from 'lodash';

const numberFormatter = {
  format: numberToCurrencyString,
  resolvedOptions: () => numberToCurrencyDefaultOptions,
};

const yesNoDropdownList = [
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' },
];

export const groups = [
  {
    groupId: 'index',
    title: 'Row #',
    columns: [
      {
        columnId: 'index',
        width: 50,
        header: 'Row',
        style: {
          background: 'rgba(128, 128, 128, 0.1)',
          justifyContent: 'center',
        },
        className: '',
        nonEditable: true,
      },
    ],
  },
  {
    groupId: 'investor',
    title:
      'Investor details (if existing user, please ensure details match the investor account)',
    columns: [
      {
        columnId: 'email',
        width: 150,
        header: 'Email address',
        placeholder: 'e.g. tom@smith.com',
        required: true,
        type: 'email',
        validator: emailValidator,
        resizable: true,
      },
      {
        columnId: 'dob',
        width: 170,
        header: 'Birth/registration date',
        placeholder: 'DD/MM/YYYY',
        required: true,
        type: 'custom_date',
        format: new Intl.DateTimeFormat('en-GB'),
        validator: dateValidator,
        resizable: true,
      },
      {
        columnId: 'account_type',
        width: 150,
        header: 'User type',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: Object.values(AccountType).map((value) => ({
          value,
          label: capitalize(value).replace('_', ' '),
        })),
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'external_investor_id',
        width: 150,
        header: 'External investor ID',
        placeholder: 'If none, leave blank',
        resizable: true,
        className: 'dark-border-right',
        style: {
          border: {
            right: {
              style: 'solid',
              width: '2px',
            },
          },
        },
      },
    ],
  },
  {
    groupId: 'investment',
    title: 'Investment details',
    columns: [
      {
        columnId: 'investment_amount',
        width: 150,
        header: 'Investment amount',
        placeholder: 'e.g. 1000.00',
        required: true,
        type: 'number',
        format: numberFormatter,
        validator: decimalValidator,
        resizable: true,
      },
      {
        columnId: 'investment_date',
        width: 150,
        header: 'Investment date',
        placeholder: 'DD/MM/YYYY',
        required: true,
        type: 'custom_date',
        format: new Intl.DateTimeFormat('en-GB'),
        validator: dateValidator,
        resizable: true,
      },
      {
        columnId: 'tranche',
        width: 150,
        header: 'Tranche',
        placeholder: 'Please choose',
        required: true,
        isDisabled: false,
        isOpen: false,
        type: 'custom_dropdown',
        values: [],
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'adviser',
        width: 250,
        header: 'Adviser (optional)',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        isOpen: false,
        values: [],
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'advice_fee',
        width: 150,
        header: 'Advice fee',
        placeholder: 'e.g. 1000.00',
        type: 'number',
        format: numberFormatter,
        validator: decimalValidator,
        resizable: true,
      },
      {
        columnId: 'use_cash_balance',
        width: 150,
        header: 'Use cash balance',
        placeholder: 'Please choose',
        required: false,
        type: 'custom_dropdown',
        values: yesNoDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'cash_balance',
        width: 150,
        header: 'Cash balance',
        required: false,
        type: 'number',
        format: numberFormatter,
        validator: decimalValidator,
        resizable: true,
      },
      {
        columnId: 'payment_email_status',
        width: 180,
        header: 'Payment and email status',
        placeholder: 'Please choose',
        required: true,
        type: 'custom_dropdown',
        values: paymentEmailOptions,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'payment_date',
        width: 170,
        header: 'Payment date',
        placeholder: 'DD/MM/YYYY',
        type: 'custom_date',
        format: new Intl.DateTimeFormat('en-GB'),
        validator: dateValidator,
        resizable: true,
      },
      {
        columnId: 'paid_to',
        width: 250,
        header: 'Paid to',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        isOpen: false,
        values: [],
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'payment_reference',
        width: 200,
        header: 'Payment reference',
        resizable: true,
      },
      {
        columnId: 'external_investment_id',
        width: 200,
        header: 'External investment ID',
        resizable: true,
        className: 'dark-border-right',
        style: {
          border: {
            right: {
              style: 'solid',
              width: '2px',
            },
          },
        },
      },
    ],
  },
  {
    groupId: 'new_investor',
    title:
      'Please fill in these columns if this is a new individual investor († = required if new investor)',
    columns: [
      {
        columnId: 'firstname',
        width: 150,
        header: 'First name †',
        placeholder: 'e.g. Thomas',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'middlename',
        width: 150,
        header: 'Middle name',
        placeholder: 'e.g. James',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'surname',
        width: 150,
        header: 'Surname †',
        placeholder: 'e.g. Smith',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'phone',
        width: 200,
        header: 'Phone †',
        placeholder: 'Numbers, + and () only',
        validator: phoneValidator,
        resizable: true,
      },
      {
        columnId: 'nino',
        width: 150,
        header: 'NINO †',
        placeholder: 'e.g. TS101010C',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'utr',
        width: 150,
        header: 'UTR',
        placeholder: 'e.g. 1234567890',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'bank_account_name',
        width: 150,
        header: 'Bank account name',
        placeholder: 'e.g. Mr T Smith',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'bank_name',
        width: 150,
        header: 'Bank name',
        placeholder: 'e.g. Natwest',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'bank_account_no',
        width: 150,
        header: 'Bank Account No',
        placeholder: 'e.g. 00000000',
        validator: bankAccountValidator,
        resizable: true,
      },
      {
        columnId: 'sort_code',
        width: 150,
        header: 'Sort Code',
        placeholder: 'e.g. 00-00-00',
        validator: sortCodeValidator,
        type: 'custom_sortcode',
        resizable: true,
      },
      {
        columnId: 'iban',
        width: 150,
        header: 'IBAN',
        placeholder: 'IBAN',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'swift',
        width: 150,
        header: 'SWIFT/BIC',
        placeholder: 'SWIFT/BIC',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'profession',
        width: 150,
        header: 'Profession',
        placeholder: 'e.g. Lawyer',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'nationality_1',
        width: 150,
        header: 'Nationality 1',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'nationality_2',
        width: 150,
        header: 'Nationality 2',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'nationality_3',
        width: 150,
        header: 'Nationality 3',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'is_us_person',
        width: 150,
        header: 'US Person?',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: yesNoDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'claiming_eis',
        width: 150,
        header: 'Claiming EIS? †',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: yesNoDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'tax_residency_1',
        width: 150,
        header: 'Tax residency 1 †',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'tax_residency_2',
        width: 150,
        header: 'Tax residency 2',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'tax_residency_3',
        width: 150,
        header: 'Tax residency 3',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'address_line_1',
        width: 150,
        header: 'Address Line 1 †',
        placeholder: '',
        resizable: true,
      },
      {
        columnId: 'address_line_2',
        width: 150,
        header: 'Address Line 2',
        placeholder: '',
        resizable: true,
      },
      {
        columnId: 'city',
        width: 150,
        header: 'City †',
        placeholder: '',
        resizable: true,
      },
      {
        columnId: 'postcode',
        width: 150,
        header: 'Postcode †',
        placeholder: 'e.g. SW1 1AA',
        resizable: true,
      },
      {
        columnId: 'country',
        width: 150,
        header: 'Country †',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'risk_rating',
        width: 150,
        header: 'Risk Rating',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: Object.values(KycRiskRating).map((value) => ({
          value,
          label: capitalize(value),
        })),
        validator: customDropdownValidator,
        resizable: true,
      },
    ],
  },
  {
    groupId: 'new_investor_address_history',
    title:
      'Please use these columns to fill in 3 years of previous addresses (only for new individual investors)',
    columns: [
      {
        columnId: 'prev_address_a_line_1',
        width: 200,
        header: 'Previous address A Line 1',
        placeholder: 'e.g. 123 Main St',
        resizable: true,
      },
      {
        columnId: 'prev_address_a_line_2',
        width: 200,
        header: 'Previous address A Line 2',
        placeholder: 'e.g. Apt 4',
        resizable: true,
      },
      {
        columnId: 'prev_address_a_city',
        width: 200,
        header: 'Previous address A City',
        placeholder: 'e.g. London',
        resizable: true,
      },
      {
        columnId: 'prev_address_a_postcode',
        width: 200,
        header: 'Previous address A Postcode',
        placeholder: 'e.g. SW1 1AA',
        resizable: true,
      },
      {
        columnId: 'prev_address_a_country',
        width: 200,
        header: 'Previous address A Country',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'prev_address_a_lived_here_3_years',
        width: 200,
        header: 'Has lived here for 3 years?',
        placeholder: 'Yes/No',
        type: 'custom_dropdown',
        values: yesNoDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'prev_address_b_line_1',
        width: 200,
        header: 'Previous address B Line 1',
        placeholder: 'e.g. 123 Main St',
        resizable: true,
      },
      {
        columnId: 'prev_address_b_line_2',
        width: 200,
        header: 'Previous address B Line 2',
        placeholder: 'e.g. Apt 4',
        resizable: true,
      },
      {
        columnId: 'prev_address_b_city',
        width: 200,
        header: 'Previous address B City',
        placeholder: 'e.g. London',
        resizable: true,
      },
      {
        columnId: 'prev_address_b_postcode',
        width: 200,
        header: 'Previous address B Postcode',
        placeholder: 'e.g. SW1 1AA',
        resizable: true,
      },
      {
        columnId: 'prev_address_b_country',
        width: 200,
        header: 'Previous address B Country',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'prev_address_c_line_1',
        width: 200,
        header: 'Previous address C Line 1',
        placeholder: 'e.g. 123 Main St',
        resizable: true,
      },
      {
        columnId: 'prev_address_c_line_2',
        width: 200,
        header: 'Previous address C Line 2',
        placeholder: 'e.g. Apt 4',
        resizable: true,
      },
      {
        columnId: 'prev_address_c_city',
        width: 200,
        header: 'Previous address C City',
        placeholder: 'e.g. London',
        resizable: true,
      },
      {
        columnId: 'prev_address_c_postcode',
        width: 200,
        header: 'Previous address C Postcode',
        placeholder: 'e.g. SW1 1AA',
        resizable: true,
      },
      {
        columnId: 'prev_address_c_country',
        width: 200,
        header: 'Previous address C Country',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
    ],
  },
  {
    groupId: 'new_investor_legal_entity_details',
    title: 'Please fill in these columns if this is a new legal entity user',
    columns: [
      {
        columnId: 'registered_name',
        width: 150,
        header: 'Registered name †',
        placeholder: 'Registered name',
      },
      {
        columnId: 'registration_number',
        width: 150,
        header: 'Registration number †',
        placeholder: 'Registration number',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'registration_state',
        width: 150,
        header: 'Registration state †',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'registered_tax_number',
        width: 180,
        header: 'Registered tax number',
        placeholder: 'Registered tax number',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'key_contact_first_name',
        width: 200,
        header: 'Key contact first name †',
        placeholder: 'Key contact first name',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'key_contact_surname',
        width: 200,
        header: 'Key contact surname †',
        placeholder: 'Key contact surname',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'key_contact_phone_number',
        width: 200,
        header: 'Key contact phone number †',
        placeholder: 'Key contact phone number',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'leu_bank_account_name',
        width: 150,
        header: 'Bank account name',
        placeholder: 'e.g. Mr T Smith',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'leu_bank_name',
        width: 150,
        header: 'Bank name',
        placeholder: 'e.g. Natwest',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'leu_bank_account_no',
        width: 150,
        header: 'Bank Account No',
        placeholder: 'e.g. 00000000',
        validator: bankAccountValidator,
        resizable: true,
      },
      {
        columnId: 'leu_sort_code',
        width: 150,
        header: 'Sort Code',
        placeholder: 'e.g. 00-00-00',
        validator: sortCodeValidator,
        type: 'custom_sortcode',
        resizable: true,
      },
      {
        columnId: 'leu_iban',
        width: 150,
        header: 'IBAN',
        placeholder: 'IBAN',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'leu_swift',
        width: 150,
        header: 'SWIFT/BIC',
        placeholder: 'SWIFT/BIC',
        validator: textValidator,
        resizable: true,
      },
      {
        columnId: 'leu_tax_residency_1',
        width: 150,
        header: 'Tax domicile 1 †',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'leu_tax_residency_2',
        width: 150,
        header: 'Tax domicile 2',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'leu_address_line_1',
        width: 150,
        header: 'Address Line 1 †',
        placeholder: '',
        resizable: true,
      },
      {
        columnId: 'leu_address_line_2',
        width: 150,
        header: 'Address Line 2',
        placeholder: '',
        resizable: true,
      },
      {
        columnId: 'leu_city',
        width: 150,
        header: 'City †',
        placeholder: '',
        resizable: true,
      },
      {
        columnId: 'leu_postcode',
        width: 150,
        header: 'Postcode †',
        placeholder: 'e.g. SW1 1AA',
        resizable: true,
      },
      {
        columnId: 'leu_country',
        width: 150,
        header: 'Country †',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
    ],
  },
  {
    groupId: 'new_investor_legal_entity_address_history',
    title:
      'Please use these columns to fill in 3 years of previous addresses (only for new legal entity user)',
    columns: [
      {
        columnId: 'leu_prev_address_a_line_1',
        width: 200,
        header: 'Previous address A Line 1',
        placeholder: 'e.g. 123 Main St',
        resizable: true,
      },
      {
        columnId: 'leu_prev_address_a_line_2',
        width: 200,
        header: 'Previous address A Line 2',
        placeholder: 'e.g. Apt 4',
        resizable: true,
      },
      {
        columnId: 'leu_prev_address_a_city',
        width: 200,
        header: 'Previous address A City',
        placeholder: 'e.g. London',
        resizable: true,
      },
      {
        columnId: 'leu_prev_address_a_postcode',
        width: 200,
        header: 'Previous address A Postcode',
        placeholder: 'e.g. SW1 1AA',
        resizable: true,
      },
      {
        columnId: 'leu_prev_address_a_country',
        width: 200,
        header: 'Previous address A Country',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'leu_prev_address_b_line_1',
        width: 200,
        header: 'Previous address B Line 1',
        placeholder: 'e.g. 123 Main St',
        resizable: true,
      },
      {
        columnId: 'leu_prev_address_b_line_2',
        width: 200,
        header: 'Previous address B Line 2',
        placeholder: 'e.g. Apt 4',
        resizable: true,
      },
      {
        columnId: 'leu_prev_address_b_city',
        width: 200,
        header: 'Previous address B City',
        placeholder: 'e.g. London',
        resizable: true,
      },
      {
        columnId: 'leu_prev_address_b_postcode',
        width: 200,
        header: 'Previous address B Postcode',
        placeholder: 'e.g. SW1 1AA',
        resizable: true,
      },
      {
        columnId: 'leu_prev_address_b_country',
        width: 200,
        header: 'Previous address B Country',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
      {
        columnId: 'leu_prev_address_c_line_1',
        width: 200,
        header: 'Previous address C Line 1',
        placeholder: 'e.g. 123 Main St',
        resizable: true,
      },
      {
        columnId: 'leu_prev_address_c_line_2',
        width: 200,
        header: 'Previous address C Line 2',
        placeholder: 'e.g. Apt 4',
        resizable: true,
      },
      {
        columnId: 'leu_prev_address_c_city',
        width: 200,
        header: 'Previous address C City',
        placeholder: 'e.g. London',
        resizable: true,
      },
      {
        columnId: 'leu_prev_address_c_postcode',
        width: 200,
        header: 'Previous address C Postcode',
        placeholder: 'e.g. SW1 1AA',
        resizable: true,
      },
      {
        columnId: 'leu_prev_address_c_country',
        width: 200,
        header: 'Previous address C Country',
        placeholder: 'Please choose',
        type: 'custom_dropdown',
        values: countryDropdownList,
        validator: customDropdownValidator,
        resizable: true,
      },
    ],
  },
];

/**
 * Build the columns from the groups (needed by the React grid component)
 * - flatten the groups into a single array of columns
 */
export const columns = groups
  .map((group) => group.columns.map((col) => ({ ...col })))
  .flat(1);

/**
 * Build the column groups from the groups (needed by the React grid component)
 * - add a totalSize property to each group
 * - this is used to set the width of the group header
 * - the width of the group header is the sum of the widths of the columns in the group
 */
export const columnGroups = groups.map((group) => ({
  groupId: group.groupId,
  title: group.title,
  totalSize: group.columns.reduce((acc, col) => acc + col.width, 0),
}));
