import { format } from 'date-fns';
import { capitalize, sortBy } from 'lodash';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'utils/pdfmakeVfsFonts';

pdfMake.vfs = pdfFonts;

export const downloadAppropriatenessPdf = (investorDetail, question) => {
  const { fullName, answerList, questionnaireData } = investorDetail;
  const name = capitalize(fullName) + ' ';

  const correct =
    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 50 50"><path fill="darkgreen" d="M47.293 6.94L14 40.232 2.707 28.94l-1.414 1.413L14 43.06 48.707 8.353z"/></svg>';
  const wrong =
    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 101 101"><path fill="red" d="M83.9 17.1c-.9-.9-2.5-.9-3.4 0l-30 30-30-30c-.9-.9-2.5-.9-3.4 0s-.9 2.5 0 3.4l30 30-30 30c-.9.9-.9 2.5 0 3.4.5.5 1.1.7 1.7.7.6 0 1.2-.2 1.7-.7l30-30 30 30c.5.5 1.1.7 1.7.7.6 0 1.2-.2 1.7-.7.9-.9.9-2.5 0-3.4l-30-30 30-30c.9-.9.9-2.4 0-3.4z"/></svg>';
  let record: Array<any> = [];

  let isLegacy = false;
  if (answerList.length) {
    record = sortBy(answerList, 'createdAt');
  } else if (questionnaireData?.length) {
    isLegacy = true;
    record = questionnaireData;
  }

  const timestampFormat = 'dd/MM/yyyy HH:mm:ss';

  const manuallyApprovedByAdminText = (testAttempt, index) => {
    return [
      {
        text: `Attempt ${index + 1} - ${format(
          new Date(testAttempt.createdAt),
          timestampFormat,
        )}`,
        style: 'attemptTitle',
      },
      'This user was manually passed through appropriateness by an admin.',
      { text: '', marginBottom: 10 },
      `Admin name: ${testAttempt.manuallyPassedBy?.firstName} ${testAttempt.manuallyPassedBy?.lastName}`,
      { text: '', marginBottom: 10 },
      `Admin email: ${testAttempt.manuallyPassedBy?.email}`,

      { text: '', marginBottom: 10 },
    ];
  };

  pdfMake
    .createPdf({
      content: [
        `Investor: ${name}`,
        { text: '', marginBottom: 10 },
        'Appropriateness record',
        { text: '', marginBottom: 10 },
        `Attempts : ${
          isLegacy
            ? questionnaireData?.length && questionnaireData[0].attempt
            : answerList?.filter((ans) => !ans.isManuallyPassedByAdmin)
                .length || 0
        }`,
        { text: '', marginBottom: 10 },
        ...record.map((testAttempt, index) => {
          const data: Array<any> = [];

          // this is to handle legacy usecases, before we
          // had the investorQuestionnaireAnswer collection
          // that recorded the results of each single attempt
          if (isLegacy) {
            data.push({
              text: `Attempt ${index + 1} - ${format(
                new Date(testAttempt.updatedAt),
                timestampFormat,
              )}  -  Score: ${testAttempt?.correct}`,
              style: 'attemptTitle',
            });
            data.push({
              text: 'No record of the questions asked/answered',
            });
            return data;
          }

          if (testAttempt?.isManuallyPassedByAdmin) {
            return manuallyApprovedByAdminText(testAttempt, index);
          }

          data.push({
            text: `Attempt ${index + 1} - ${format(
              new Date(testAttempt.createdAt),
              timestampFormat,
            )}  -  Score: ${testAttempt?.correct}/${
              testAttempt?.answers?.length
            }`,
            style: 'attemptTitle',
          });

          testAttempt?.answers?.forEach((answer, i) => {
            const questionAsked = question.find(
              (q) => q._id === answer?.appropriatenessQuestionId,
            );

            const isCorrect = answer?.selection === questionAsked?.answer;

            data.push({
              table: {
                widths: ['3%', '97%'],
                body: [
                  [
                    {
                      text: `${i + 1}.`,
                    },
                    { text: questionAsked?.title },
                  ],
                ],
              },
              layout: 'noBorders',
            });
            data.push({
              table: {
                widths: ['3%', '97%'],
                body: [
                  [
                    {
                      svg: isCorrect ? correct : wrong,
                      width: 15,
                      height: 15,
                    },
                    {
                      text: questionAsked?.option[
                        parseInt(answer.selection) - 1
                      ],
                    },
                  ],
                ],
              },
              layout: 'noBorders',
            });
            data.push({ text: '', marginBottom: 5 });
          });
          data.push({ text: '', marginBottom: 10 });
          return data;
        }),
      ],
      styles: {
        attemptTitle: {
          color: 'black',
          bold: true,
          fontSize: 16,
          marginTop: 15,
          marginBottom: 10,
        },
      },
    })
    .download(
      `Further Finance Ltd-${investorDetail?.fullName?.toLowerCase()}-Appropriateness question record`,
    );
};
