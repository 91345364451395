import React from 'react';
import TextField from 'components/FormElements/AppTextInput';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import { CircularProgress } from '@mui/material';
import FieldRow from 'components/FormElements/FieldRow';
import useGetInvestorsLeanResponse from 'hooks/data/investor/useGetInvestorsLeanResponse';

type Props = any;

const InvestorSearch: React.FC<Props> = ({
  onInvestorSelect,
  selectedInvestorId,
  queuedWithdrawals,
  firmId,
}) => {
  const investors = useGetInvestorsLeanResponse({ firmId });

  const selectedInvestor = investors.data?.find(
    (inv) => inv._id === selectedInvestorId,
  );

  const investorNotAlreadyQueued = (investor) => {
    return !queuedWithdrawals.some(
      (withdrawal) => withdrawal._id === investor._id,
    );
  };

  return (
    <FieldRow>
      {investors.isFetching ? (
        <CircularProgress size={20} />
      ) : (
        <Autocomplete
          options={investors.data?.filter(investorNotAlreadyQueued) ?? []}
          id="investorSearch"
          getOptionLabel={(option) => {
            return `${option.fullName} (${option.email})` || '';
          }}
          filterSelectedOptions
          onChange={(_, investor) => {
            if (!investor) return onInvestorSelect();
            onInvestorSelect(investor._id);
          }}
          value={selectedInvestor || null}
          renderInput={(params) => (
            <TextField
              {...params}
              value={'41'}
              variant="outlined"
              placeholder="Search for an investor"
            />
          )}
        />
      )}
    </FieldRow>
  );
};

export default InvestorSearch;
