import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { useNotification } from 'hooks/ui/useNotification';
import { useEffect } from 'react';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/investment';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';

type InvestmentFreezeResponse = {
  data: {
    success: boolean;
    investment?: {
      feeForeCasts: unknown[];
      fees: unknown[];
      freezeStartDate: string;
      investmentId: string;
    };
  };
};

type MoveFrozenFundsResponse = {
  data: {
    investment: {
      hasFrozenFundsMoved?: boolean;
      investmentBalancePropertyCache: unknown;
    };
  };
};

const useInvestmentFetchAPI = (investmentId: string) =>
  useQuery<Api.GetInvestmentDetailsResponse>({
    queryKey: ['investment', investmentId],
    queryFn: async () => {
      const {
        data: { data },
      } = await api.get(`investment/${investmentId}/details`);
      return data;
    },
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    enabled: !!investmentId,
  });

export const useInvestmentUpdateAPI = (
  investmentId: string,
  onNegativeBalanceError?: () => void,
) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  const update = useMutation<
    ApiResponse<Api.UpdateInvestmentResponse>,
    Error,
    Api.UpdateInvestmentRequest
  >({
    mutationFn: async (data) => {
      const { data: response } = await api.put(
        `investment/${investmentId}`,
        data,
      );
      return response;
    },
    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: ['investment', investmentId],
      });
      queryClient.invalidateQueries({ queryKey: ['payments', investmentId] });
      queryClient.invalidateQueries({
        queryKey: ['investment-fees', investmentId],
      });
    },
  });

  useEffect(
    function onSuccess() {
      const successMessage = update.isSuccess && update.data?.responseMsg;
      if (successMessage) {
        notification.success(successMessage);
      }
    },
    [update.isSuccess, update.data],
  );

  useEffect(
    function onError() {
      // @ts-expect-error
      const errorMessage = update.error?.response?.data?.responseMsg;

      const isNegativeBalanceError = errorMessage === 'Negative balance error';

      if (errorMessage?.length && !isNegativeBalanceError) {
        notification.error(errorMessage);
      }

      if (isNegativeBalanceError) {
        if (onNegativeBalanceError) {
          onNegativeBalanceError();
        } else {
          notification.error(errorMessage);
        }
      }
    },
    [update.error],
  );

  return update;
};

const useInvestmentFreeze = (investmentId: string) => {
  const queryClient = useQueryClient();
  return useMutation<
    InvestmentFreezeResponse,
    unknown,
    { freezeStartDate: Date | null }
  >({
    mutationFn: async (data) => {
      const { data: result } = await api.put<InvestmentFreezeResponse>(
        `investment/freeze/${investmentId}`,
        data,
      );
      return result;
    },
    onSuccess: async ({ data }) => {
      const queryKey = ['investment', investmentId];

      if (!data.success) {
        return;
      }
      queryClient.setQueryData(queryKey, {
        ...(queryClient.getQueryData(queryKey) as object),
        ...(data?.investment ?? {}),
      });
    },
  });
};

const useMoveFrozenFunds = (investmentId: string) => {
  const queryClient = useQueryClient();

  return useMutation<MoveFrozenFundsResponse, unknown, null>({
    mutationFn: async () => {
      const { data: response } = await api.put<MoveFrozenFundsResponse>(
        `investment/move-frozen-funds/${investmentId}`,
      );
      return response;
    },
    onSuccess: async ({ data }) => {
      const queryKey = ['investment', investmentId];

      queryClient.setQueryData(queryKey, {
        ...(queryClient.getQueryData(queryKey) as object),
        hasFrozenFundsMoved: data.investment.hasFrozenFundsMoved,
        investmentBalancePropertyCache:
          data.investment.investmentBalancePropertyCache,
        ...(data.investment.investmentBalancePropertyCache
          ? {
              investmentBalance: data.investment.investmentBalancePropertyCache,
            }
          : {}),
      });
    },
  });
};

export const useSendPaymentReminder = () => {
  const notification = useNotification();
  const sendPaymentReminder = useMutation({
    mutationFn: async (investmentId) => {
      try {
        const { data: response } = await api.post(
          `investment/${investmentId}/send-payment-reminder`,
        );
        notification.success(response.responseMsg);
        return response;
      } catch (error: any) {
        notification.error(error?.response?.data?.responseMsg ?? '');
        throw error;
      }
    },
  });
  return {
    sendPaymentReminder,
  };
};

export const useInvestments = ({ params }: { params: any }) =>
  useQuery({
    queryKey: ['investments', { params }],
    queryFn: async () => {
      const {
        data: { data },
      } = await api.get(`investment/list`, { params });
      return data;
    },
    placeholderData: keepPreviousData,
    enabled: !params.includeExportData,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

export const useCreateInvestment = ({
  onCreate,
  onError,
}: {
  onCreate: (id: string) => void;
  onError?: () => void;
}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  const handleApiException = useApiExceptionHandler();

  return useMutation({
    mutationFn: async (dto: Api.CreateInvestmentRequest) => {
      const { data: response } = await api.post<
        ApiResponse<Api.CreateInvestmentResponse>
      >(`investment/`, dto);
      return response;
    },
    onSuccess: (data) => {
      notification.success('Investment has been created.');
      queryClient.invalidateQueries({
        queryKey: ['investment'],
      });
      onCreate?.(`${data?.data?._id}`);
    },
    onError: (error: Error) => {
      handleApiException(error);
      onError?.();
    },
  });
};

export function useInvestment(
  investmentId: string,
  onNegativeBalanceError?: () => void,
) {
  const fetch = useInvestmentFetchAPI(investmentId);
  const update = useInvestmentUpdateAPI(investmentId, onNegativeBalanceError);
  const freeze = useInvestmentFreeze(investmentId);
  const moveFrozenFunds = useMoveFrozenFunds(investmentId);

  return {
    fetch,
    update,
    freeze,
    moveFrozenFunds,
  };
}

export function useInvestmentsExport({ params }: { params: any }) {
  return useQuery({
    queryKey: ['investments', { params }],
    queryFn: async () => {
      const {
        data: { data },
      } = await api.get(`investment/list`, { params });
      return data;
    },
    placeholderData: undefined,
    enabled: params.includeExportData,
  });
}
