import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/investor';
import { useNotification } from 'hooks/ui/useNotification';

interface BankDetailsRequest extends Api.UpdateBankDetailsRequest {
  investorId: string;
}

export default function useSetBankDetails() {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation({
    mutationFn: async (bankDetails: BankDetailsRequest) => {
      const { data: result } = await api.put<ApiResponse<unknown>>(
        `investor/add-bank-detail/${bankDetails?.investorId}`,
        bankDetails,
      );
      return result;
    },
    throwOnError: false,
    onSuccess: async (_: unknown, bankDetails) => {
      await queryClient.invalidateQueries({
        queryKey: ['investor', bankDetails.investorId],
      });
    },
    onError: () => {
      notification.error(
        'Something went wrong when trying to set bank details.',
      );
    },
  });
}
