import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/delegated-access';
import { api } from 'lib/httpClient';

async function fetchDelegatedAccessGiven({ queryKey }) {
  const [, params] = queryKey;

  const { data } = await api.get(
    `delegated-access/list-access-given/${params.investorId}`,
  );

  return data.data;
}

type Params = {
  investorId?: string;
};

export function useListDelegatedAccessGiven(params: Params) {
  return useQuery<ApiResponse<Api.ListDelegatedAccessGivenByFirmResponse>>({
    queryKey: ['delegated-access-given', params],
    queryFn: fetchDelegatedAccessGiven,
    enabled: !!params.investorId,
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
  });
}
