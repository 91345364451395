import api from 'lib/trpcClient';
import { useRef } from 'react';
import useCurrentUser from '../currentUser/useCurrentUser';
import { keepPreviousData } from '@tanstack/react-query';
import { BackgroundExportStatus } from 'further-types/background-exports';

export const useBackgroundExports = () => {
  const countRef = useRef(0);
  const apiUtils = api.useUtils();
  const user = useCurrentUser();

  const { data: backgroundExports } =
    api.backgroundExports.getBackgroundExports.useQuery(undefined, {
      placeholderData: keepPreviousData,
      enabled: !!user.profile.data,
      refetchInterval: (query) => {
        const hasPendingExports = query.state.data?.results?.some(
          (backgroundExport) =>
            backgroundExport.status === BackgroundExportStatus.Pending,
        );

        if (!hasPendingExports) {
          countRef.current = 0;
          return false;
        }

        const elapsedTime = countRef.current * 5;
        countRef.current++;

        if (elapsedTime <= 240) {
          return 5000;
        }

        if (elapsedTime <= 1440) {
          return 60000;
        }

        countRef.current = 0;
        return false;
      },
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
    });

  const downloadBackgroundExport =
    api.backgroundExports.downloadBackgroundExport.useMutation({
      onMutate: () => {
        countRef.current = 0; // Reset long poll
      },
      onSuccess: (data) => {
        const link = document.createElement('a');
        link.href = data.downloadLink;
        document.body.appendChild(link);
        link.click();
      },
    });

  const createBackgroundExport =
    api.backgroundExports.createBackgroundExport.useMutation({
      onMutate: () => {
        countRef.current = 0; // Reset long poll
      },
      onSuccess: () => {
        apiUtils.backgroundExports.getBackgroundExports.invalidate();
      },
    });

  return {
    backgroundExports,
    downloadBackgroundExport,
    createBackgroundExport,
    isExporting: createBackgroundExport.isPending,
  };
};
