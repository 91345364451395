export const signUpSourceLabel = {
  admin: 'Admin',
  upload: 'Upload',
  adviser: 'Adviser',
  selfSignup: 'Self signup',
  superAdmin: 'Super Admin',
};

export const getSignUpDetail = (
  investorDetail: {
    signUpSource: string;
    onBoardDomain?: string;
  },
  isSuperAdmin: boolean,
): string => {
  switch (true) {
    case investorDetail?.signUpSource === 'admin':
      return ` (created by a firm manager)`;
    case investorDetail?.signUpSource === 'superAdmin':
      return ' (created by Further Super Admin user)';
    case investorDetail?.onBoardDomain && isSuperAdmin:
      return ` via ${investorDetail.onBoardDomain}.joinfurther.com`;
    default:
      return '';
  }
};
