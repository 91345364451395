import React from 'react';
import { Button } from '@mui/material';
import TextField from 'components/FormElements/AppTextInput';
import { makeStyles } from 'tss-react/mui';
import FieldRow from 'components/FormElements/FieldRow';
import RecordView from 'components/RecordView';
import ButtonGroup from 'components/ButtonGroup';

const useStyles = makeStyles()(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

type Props = any;

const AdditionalInformation: React.FC<Props> = ({
  additionalInfo,
  additionalInfoError,
  handleCancel,
  handelClickAdditional,
  handleInputChangeAdditional,
  id,
  disabled = false,
}) => {
  const { classes } = useStyles();

  return (
    <RecordView>
      <FieldRow
        title="Daily summary notification list"
        informationText="Separate emails by a semi-colon"
      >
        <TextField
          name="additionalInfo"
          placeholder="Enter the email addresses you wish to receive daily subscription notifications. Please separate each email with a semi-colon. e.g. 'a@a.com;b@b.com'"
          fullWidth
          onChange={handleInputChangeAdditional}
          value={additionalInfo}
          variant="outlined"
          error={additionalInfoError}
          helperText={additionalInfoError}
          multiline
          minRows={4}
        />
      </FieldRow>

      <ButtonGroup>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className={classes.marginLeftBtn}
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={handelClickAdditional}
        >
          {id ? 'Update' : 'Save'}
        </Button>
      </ButtonGroup>
    </RecordView>
  );
};

export default AdditionalInformation;
