import React from 'react';
import { NumericFormat } from 'react-number-format';
import { Box, Typography, Button, Tooltip } from '@mui/material';
import { compact, sortBy } from 'lodash';

import Table, { useStyles as useTableStyles } from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { capitalize, lowerCase } from 'lodash';
import { displayFeeAccountingType } from 'utils/displayFeeAccounting';
import { Api } from 'further-types/investment';
import { CompareArrows } from '@mui/icons-material';
import useStyles from './styles';
import { useLoadMoreAndHideContent } from 'hooks/ui/useLoadMoreContent';

type Fee = Api.GetInvestmentFeesResponse['fees'][number] & {
  isTransferred: boolean;
};

const columns = [
  {
    label: 'Date',
    key: 'date',
    render: (fee: Fee) => dateToLabel(fee.date),
  },
  {
    label: 'Fee type',
    key: 'feeType',
    render: (fee: Fee) => capitalize(lowerCase(fee.feeType)),
  },
  {
    label: 'Fee name',
    key: 'name',
    render: (fee: Fee) => {
      if (!fee.sourceFee && !fee.isTransferred) return <>{fee.name}</>;

      const tooltipText = [
        ...(fee.sourceFee
          ? [
              `${numberToCurrencyString(fee.feeIncVAT)} was transferred from ${
                fee.sourceFee.investor.fullName
              } on ${dateToLabel(fee.sourceFee.investmentDate)}`,
            ]
          : []),
        ...(fee.isTransferred
          ? [
              `${numberToCurrencyString(fee.feeIncVAT)} was transferred to ${
                fee.investor.fullName
              } on ${dateToLabel(fee.investmentDate)}`,
            ]
          : []),
      ].join(' ');

      return (
        <>
          <Tooltip title={tooltipText}>
            <Box alignItems="center" display="flex" gap={4}>
              {fee.name} <CompareArrows fontSize="small" />
            </Box>
          </Tooltip>
        </>
      );
    },
  },
  {
    label: 'Fee %',
    key: 'grossFeePercentage',
    render: (fee: Fee) => fee.grossFeePercentage.toFixed(2),
  },
  {
    label: 'Fee £ (Net)',
    key: 'feeExVAT',
    render: (fee: Fee) => numberToCurrencyString(fee.feeExVAT),
  },
  {
    label: 'Fee £ (Gross)',
    key: 'feeIncVAT',
    render: (fee: Fee) => numberToCurrencyString(fee.feeIncVAT),
  },
  {
    label: 'Frequency',
    key: 'chargingFrequency',
    render: (fee: Fee) => capitalize(lowerCase(fee.chargingFrequency)),
  },
  {
    label: 'Starting period',
    key: 'startingPeriod',
  },
  {
    label: 'Finishing period',
    key: 'finishingPeriod',
  },
  {
    label: 'Fee accounting',
    key: 'feeAccounting',
    render: (fee: Fee) =>
      displayFeeAccountingType(fee.feeAccounting, fee.viaExit),
  },
  {
    label: 'Exported to custodian?',
    key: 'custodian',
    render: (fee: Fee) => (fee.isExportedToCustodian ? 'Yes' : 'No'),
  },
];

type Params = {
  fees: Api.GetInvestmentFeesResponse['fees'];
};

const CoreFees: React.FC<Params> = ({ fees }) => {
  const { classes: tableClasses } = useTableStyles();
  const { classes } = useStyles();

  const totals = {
    total: {
      netFee: 0,
      grossFee: 0,
    },
    cash: { netFee: 0, grossFee: 0 },
    accrued: { netFee: 0, grossFee: 0 },
  };

  compact(fees).forEach((rec) => {
    //TODO: Use Enum
    const isDiscount = rec?.feeType === 'ONE OFF DISCOUNT';
    const { feeExVAT = 0, feeIncVAT = 0 } = rec;

    if (isDiscount) {
      totals.total.netFee -= feeExVAT;
      totals.total.grossFee -= feeIncVAT;
    } else {
      totals.total.netFee += feeExVAT;
      totals.total.grossFee += feeIncVAT;
    }
    //TODO: Use Enum
    if (rec?.feeAccounting === 'CHARGED_TO_INVESTOR') {
      if (isDiscount) {
        totals.cash.netFee -= feeExVAT;
        totals.cash.grossFee -= feeIncVAT;
      } else {
        totals.cash.netFee += feeExVAT;
        totals.cash.grossFee += feeIncVAT;
      }
      //TODO: Use Enum
    } else if (rec?.feeAccounting === 'ACCRUED') {
      if (isDiscount) {
        totals.accrued.netFee -= feeExVAT;
        totals.accrued.grossFee -= feeIncVAT;
      } else {
        totals.accrued.netFee += feeExVAT;
        totals.accrued.grossFee += feeIncVAT;
      }
    }
  });

  //@ts-expect-error
  const combinedFees = sortBy(fees, 'date').reduce((combined, fee) => {
    return [
      ...combined,
      fee,
      ...(fee.transferredFees?.map((transferredFee) => ({
        ...transferredFee,
        isTransferred: true,
      })) ?? []),
    ];
  }, []);

  const { onToggle, displayedContent } =
    //@ts-expect-error
    useLoadMoreAndHideContent(combinedFees);

  return (
    <Table
      columns={columns.map((column) => ({
        ...column,
        getCellClassName: (fee: Fee) =>
          fee.isTransferred ? classes.inactiveCell : '',
      }))}
      tablebody={displayedContent}
      variant="nohover"
      TableFooter={
        <tfoot>
          {
            //@ts-expect-error
            combinedFees.length > 10 ? (
              <tr className={tableClasses.tFootRow}>
                <td colSpan={12} align="center">
                  <Button color="primary" onClick={onToggle}>
                    {displayedContent.length > 10
                      ? 'Collapse charged fees table'
                      : ' Click here to show full table contents'}
                  </Button>
                </td>
              </tr>
            ) : null
          }
          <tr className={tableClasses.tFootRow}>
            <td className={tableClasses.tFootCell} colSpan={4}>
              <Typography component="h4" variant="inherit">
                Total
              </Typography>
            </td>
            <td className={tableClasses.tFootCell}>
              <NumericFormat
                decimalScale={2}
                fixedDecimalScale
                value={totals.total?.netFee}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'£'}
              />
            </td>
            <td className={tableClasses.tFootCell} colSpan={6}>
              <NumericFormat
                decimalScale={2}
                fixedDecimalScale
                value={totals.total?.grossFee}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'£'}
              />
            </td>
          </tr>
          <tr className={tableClasses.tFootRow}>
            <td className={tableClasses.tFootCell} colSpan={4}>
              <Typography component="h4" variant="inherit">
                Of which cash
              </Typography>
            </td>
            <td className={tableClasses.tFootCell}>
              <NumericFormat
                decimalScale={2}
                fixedDecimalScale
                value={totals.cash.netFee}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'£'}
              />
            </td>
            <td className={tableClasses.tFootCell} colSpan={6}>
              <NumericFormat
                decimalScale={2}
                fixedDecimalScale
                value={totals.cash.grossFee}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'£'}
              />
            </td>
          </tr>
          <tr className={tableClasses.tFootRow}>
            <td className={tableClasses.tFootCell} colSpan={4}>
              <Typography component="h4" variant="inherit">
                Of which accrued
              </Typography>
            </td>
            <td className={tableClasses.tFootCell}>
              <NumericFormat
                decimalScale={2}
                fixedDecimalScale
                value={totals.accrued.netFee}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'£'}
              />
            </td>
            <td className={tableClasses.tFootCell} colSpan={6}>
              <NumericFormat
                decimalScale={2}
                fixedDecimalScale
                value={totals.accrued.grossFee}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'£'}
              />
            </td>
          </tr>
        </tfoot>
      }
    />
  );
};

export default CoreFees;
