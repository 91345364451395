import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Collapse, List, ListItem, styled } from '@mui/material';
import NavigationItem from './NavigationItem';

import { SidebarMenuItemType } from 'hooks/ui/useSidebarMenuItems';
import Link from './Link';
import { itemsContainUrl } from 'helpers/layout/sidebarMenu';

const CollapseArea = styled(Collapse)({
  position: 'relative',
  '& a': {
    padding: '9px 16px 9px 70px',
    transition: 'all 0.3s ease',
  },
});

const NavCollapse: React.FC<SidebarMenuItemType> = ({
  name,
  icon,
  children = [],
}) => {
  const history = useHistory();

  const [open, setOpen] = useState(
    itemsContainUrl(children, history.location.pathname),
  );

  const handleClick = () => {
    setOpen(!open);
  };

  if (!children?.length) {
    return null;
  }

  return (
    <Box position="relative">
      <ListItem component="div" onClick={handleClick} disablePadding>
        <Link isCollapsible isOpen={open} name={name} icon={icon} type="item" />
      </ListItem>
      <CollapseArea in={open} timeout="auto">
        <List component="div" disablePadding>
          {children.map((item) => (
            <NavigationItem
              item={item}
              key={`navigation-item-${item.type}-${item.name}`}
            />
          ))}
        </List>
      </CollapseArea>
    </Box>
  );
};

export default NavCollapse;
