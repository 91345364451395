import { Controller, useFormContext } from 'react-hook-form';
import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';
import Spacing from 'components/Spacing';

const GeneralInformation = () => {
  const { control } = useFormContext();

  return (
    <Spacing>
      <FieldRow title="Vehicle name*" centerTitle>
        <Controller
          name="vehicleName"
          control={control}
          render={({ fieldState: { error }, field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="e.g. Venture Fund I"
              helperText={error?.message?.toString()}
              fullWidth
            />
          )}
        />
      </FieldRow>

      <FieldRow title="Base currency*" centerTitle>
        <Controller
          name="baseCurrency"
          control={control}
          render={({ fieldState: { error }, field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="e.g. GBP"
              helperText={error?.message?.toString()}
              fullWidth
            />
          )}
        />
      </FieldRow>
    </Spacing>
  );
};

export default GeneralInformation;
