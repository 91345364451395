import React from 'react';
import TextField from 'components/FormElements/AppTextInput';
import FieldRow from 'components/FormElements/FieldRow';
import { PatternFormat } from 'react-number-format';

type Props = any; // TODO: add types

const BankDetailsInput: React.FC<Props> = ({
  editDisabled,
  adviserData: { bankAccountName, bankAccountNumber, bankName, bankSortCode },
  setAdviserData,
  errorText,
}) => {
  return (
    <>
      <FieldRow title="Bank name" centerTitle>
        <TextField
          required
          name="bankName"
          placeholder="Enter bank name"
          fullWidth
          disabled={editDisabled}
          value={bankName}
          error={!!errorText?.errorValue?.bankName}
          helperText={errorText?.errorValue?.bankName}
          onChange={(event) =>
            setAdviserData((adviserData) => ({
              ...adviserData,
              bankName: event.target.value,
            }))
          }
        />
      </FieldRow>

      <FieldRow title="Name on bank account" centerTitle>
        <TextField
          required
          name="bankName"
          placeholder="Enter name on bank account"
          fullWidth
          disabled={editDisabled}
          value={bankAccountName}
          error={!!errorText?.errorValue?.bankAccountName}
          helperText={errorText?.errorValue?.bankAccountName}
          onChange={(event) =>
            setAdviserData((adviserData) => ({
              ...adviserData,
              bankAccountName: event.target.value,
            }))
          }
        />
      </FieldRow>

      <FieldRow title="Bank account number" centerTitle>
        <TextField
          name="bankAccountNumber"
          placeholder="Enter bank account number"
          fullWidth
          disabled={editDisabled}
          value={bankAccountNumber}
          error={!!errorText?.errorValue?.bankAccountNumber}
          helperText={errorText?.errorValue?.bankAccountNumber}
          onChange={(event) =>
            setAdviserData((adviserData) => ({
              ...adviserData,
              bankAccountNumber: event.target.value,
            }))
          }
          inputProps={{ minLength: 8, maxLength: 8 }}
        />
      </FieldRow>

      <FieldRow title="Bank sort code" centerTitle>
        <PatternFormat
          required
          placeholder="Enter bank sort code"
          value={bankSortCode}
          name={'bankSortCode'}
          format="##-##-##"
          customInput={TextField}
          type="text"
          disabled={editDisabled}
          error={!!errorText?.errorValue?.bankSortCode}
          helperText={errorText?.errorValue?.bankSortCode}
          onChange={(event) =>
            setAdviserData((adviserData) => ({
              ...adviserData,
              bankSortCode: event.target.value,
            }))
          }
        />
      </FieldRow>
    </>
  );
};

export default BankDetailsInput;
