import TextField from 'components/FormElements/AppTextInput';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import { sectors, stages } from 'adminConstants/companyProperties';
import FieldRow from 'components/FormElements/FieldRow';
import RecordView from 'components/RecordView';

const StageAndSector = ({ errorText, setValues, values }) => {
  return (
    <RecordView size="full">
      <FieldRow title="Stage*" centerTitle>
        <Autocomplete
          id="stageAutocomplete"
          options={['', ...Object.values(stages)]}
          filterSelectedOptions
          onChange={(_event, stage) => {
            setValues({ ...values, stage });
          }}
          value={values.stage}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              name="stage"
              placeholder="Select stage"
              fullWidth
              error={errorText.stage}
              helperText={errorText.stage}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Sector*" centerTitle>
        <Autocomplete
          id="sectorAutocomplete"
          options={['', ...Object.values(sectors)]}
          filterSelectedOptions
          onChange={(_event, sector) => {
            setValues({ ...values, sector });
          }}
          value={values.sector}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              name="sector"
              placeholder="Select sector"
              fullWidth
              error={errorText.sector}
              helperText={errorText.sector}
            />
          )}
        />
      </FieldRow>
    </RecordView>
  );
};

export default StageAndSector;
