import { useGetRole } from 'hooks/ui/useGetRole';
import Collapse from './Collapse';
import Link from './Link';
import Section from './Section';
import { useGetAllPermissionsList } from 'hooks/ui/useGetPermissions';
import { SidebarMenuItemType } from 'hooks/ui/useSidebarMenuItems';

type Props = {
  item: SidebarMenuItemType;
};

const NavigationItem: React.FC<Props> = ({ item }) => {
  const { roleSlug } = useGetRole();
  const permissionsList = useGetAllPermissionsList()?.filter((permission) =>
    permission.includes('read'),
  );

  const isPermittedForRole = item.onlyShownForRoles?.length
    ? item.onlyShownForRoles?.includes(roleSlug ?? '')
    : true;

  if (
    !isPermittedForRole ||
    (item?.permission && !permissionsList?.includes(item?.permission))
  ) {
    return null;
  }

  switch (item.type) {
    case 'section':
      return <Section {...item} />;
    case 'collapse':
      return <Collapse {...item} />;
    case 'item':
      return <Link {...item} />;
    default:
      return null;
  }
};

export default NavigationItem;
