import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/investor';
import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';

type ResendInviteParams = {
  onResendInviteSuccess?: () => void;
  onResendInvitesSuccess?: () => void;
};

const useResendInvite = ({ onResendInviteSuccess }: ResendInviteParams) => {
  const queryClient = useQueryClient();
  const { error } = useNotification();

  return useMutation({
    mutationFn: async (investorId) => {
      await api.post(`investor/resend-platform-invite/${investorId}`);
      return investorId;
    },
    onError: () => {
      error('Error when trying to resend platform invite to Investor');
    },
    onSuccess: async (investorId) => {
      queryClient.invalidateQueries({ queryKey: ['investor', investorId] });
      onResendInviteSuccess?.();
    },
  });
};

const useResendInvites = ({ onResendInvitesSuccess }: ResendInviteParams) => {
  const queryClient = useQueryClient();
  const { error } = useNotification();

  return useMutation({
    mutationFn: async (investorsIds) => {
      const { data: response } = await api.post(
        `investor/resend-platform-invites`,
        {
          investorsIds,
        },
      );
      return response;
    },
    onError: () => {
      error('Error when trying to resend platform invites');
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['investor'] });
      onResendInvitesSuccess?.();
    },
  });
};

const useResendInvitesToInvestorsWithoutVisit = () => {
  const queryClient = useQueryClient();
  const { success } = useNotification();
  const { error } = useNotification();

  return useMutation({
    mutationFn: async () => {
      const { data: response } = await api.post<
        ApiResponse<Api.SendInvitationsToInvestorsWithoutVisitResponse>
      >(`investor/resend-invitations-to-investors-without-visit`);
      return response.data;
    },
    onError: () => {
      error('Error when trying to resend platform invites');
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries({ queryKey: ['investor'] });
      queryClient.invalidateQueries({ queryKey: ['investors'] });
      success(`Successfully sent ${data.invitesSent} investor invites.`);
    },
  });
};

const useInvestorPlatformInvites = (params: ResendInviteParams = {}) => {
  return {
    resendInvite: useResendInvite(params),
    resendInvites: useResendInvites(params),
    resendInvitesToInvestorsWithoutVisit:
      useResendInvitesToInvestorsWithoutVisit(),
  };
};

export default useInvestorPlatformInvites;
