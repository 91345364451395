import { numberToCurrencyString } from 'further-ui/utils';
import { Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginLeft: '5px',
    marginBottom: '2px',
    fontSize: '16px',
  },
}));

type Props = any; //TODO: define types

const NetFigureDisplay: React.FC<Props> = ({
  value,
  row,
  isBeingEdited,
  isBeingConstrained,
}) => {
  const { classes } = useStyles();

  if (!isBeingEdited) {
    if (isBeingConstrained) {
      return <em>{numberToCurrencyString(value)}</em>;
    }
    return <>{numberToCurrencyString(value)}</>;
  }

  const { previousAccruedFeesCharged, previousPerformanceFeesCharged } = row;
  let title;

  if (previousAccruedFeesCharged > 0) {
    title = `Includes ${numberToCurrencyString(
      row.previousAccruedFeesCharged,
    )} accrued fees`;

    if (previousPerformanceFeesCharged > 0) {
      title += ` and ${numberToCurrencyString(
        row.previousPerformanceFeesCharged,
      )} performance fees charged previously.`;
    } else {
      title += ' charged previously.';
    }
  } else if (previousPerformanceFeesCharged > 0) {
    title = `Includes ${numberToCurrencyString(
      row.previousPerformanceFeesCharged,
    )} performance fees charged previously.`;
  }

  return (
    <div className={classes.flex}>
      {isBeingConstrained ? (
        <em>{numberToCurrencyString(value)}</em>
      ) : (
        numberToCurrencyString(value)
      )}{' '}
      {title && (
        <Tooltip arrow title={title}>
          <InfoOutlined className={classes.icon} />
        </Tooltip>
      )}
    </div>
  );
};

export default NetFigureDisplay;
