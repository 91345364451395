import { api } from 'lib/httpClient';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/tag';
import { useNotification } from 'hooks/ui/useNotification';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';

const useCreateTag = () => {
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();
  const queryClient = useQueryClient();

  return useMutation({
    throwOnError: false,
    mutationFn: async (data: Api.CreateTagRequest) => {
      const result = await api.post<ApiResponse<Api.CreateTagResponse>>(
        'tag',
        data,
      );
      return result.data;
    },
    onSuccess: async (data) => {
      notification.success(data.responseMsg ?? '');
      await queryClient.invalidateQueries({
        queryKey: ['tag'],
      });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
};

const useUpdateTag = (tagId?: string) => {
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();
  const queryClient = useQueryClient();

  return useMutation({
    throwOnError: false,
    mutationFn: async (data: Api.UpdateTagRequest) => {
      const result = await api.put<ApiResponse<Api.UpdateTagResponse>>(
        `tag/${tagId}`,
        data,
      );
      return result.data;
    },
    onSuccess: async (data) => {
      notification.success(data.responseMsg ?? '');
      await queryClient.invalidateQueries({
        queryKey: ['tag'],
      });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
};

export function useTag(tagId?: string) {
  const tag = useQuery({
    queryKey: ['tag', tagId],
    queryFn: async () =>
      (await api.get<ApiResponse<Api.GetTagResponse>>(`/tag/${tagId}`)).data,
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
    enabled: !!tagId,
  });

  return {
    tag,
    createTag: useCreateTag(),
    updateTag: useUpdateTag(tagId),
  };
}
