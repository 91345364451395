import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import Heading from 'components/Heading';
import { groupBy, round, sumBy } from 'lodash';
import { numberToCurrencyString } from 'further-ui/utils';
import getColumns from './getColumns';
import InteractiveTable, { RowsGroup } from 'components/InteractiveTable';
import { Row } from 'components/Layout';
import { PaginationState } from 'further-types/utils';
import { ApiResponses } from 'lib/trpcClient';

const StyledAccordion = styled(Accordion)({
  fontFamily: 'IBMPlexSans',
  borderBottom: 0,
  '&:not(:first-of-type)': {
    borderTop: 0,
  },
});

const StickyAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  position: 'sticky',
  top: 0,
  zIndex: 10,
  borderBottom: `1px solid ${theme.palette.borderColor?.dark}`,
}));

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: 0,
});

const AccordionHeader = styled(Row)(({ theme }) => ({
  width: '100%',
  paddingRight: theme.spacing(2),
}));

const StyledTable = styled(InteractiveTable)({
  borderRadius: 0,
  border: 'none',
});

type Props = {
  sectionTitle: string;
  section: ApiResponses['generalLedger']['listTransactions']['sections'][string];
  pagination: PaginationState;
  loading?: boolean;
};

const SectionContent: React.FC<Props> = ({
  sectionTitle,
  section,
  pagination,
  loading,
}) => {
  const columns = getColumns({
    sectionTotal: section.total,
  });

  const groupedRows: RowsGroup = {};

  Object.values(groupBy(section.transactions, 'accountId')).forEach((rows) => {
    groupedRows[rows[0].accountId] = {
      title: rows[0].accountLabel,
      rows,
      summary: {
        accountLabel: `Subtotal - ${rows[0].accountLabel}`,
        amountIncVat: numberToCurrencyString(
          round(sumBy(rows, 'amountIncVat'), 2),
        ),
        amountExVat: numberToCurrencyString(
          round(sumBy(rows, 'amountExVat'), 2),
        ),
      },
    };
  });

  return (
    <StyledAccordion defaultExpanded>
      <StickyAccordionSummary
        expandIcon={<ExpandMoreIcon color="primary" />}
        aria-controls={`section-content-${sectionTitle}`}
        id={`section-header-${sectionTitle}`}
      >
        <AccordionHeader centered justify="space-between">
          <Heading variant="h3" noMargin>
            {sectionTitle}
          </Heading>
          <Heading variant="h6" noMargin>
            {numberToCurrencyString(section.total)}
          </Heading>
        </AccordionHeader>
      </StickyAccordionSummary>
      <StyledAccordionDetails>
        <StyledTable
          id={`general-ledger-${sectionTitle}`}
          columns={columns}
          rows={groupedRows}
          pagination={pagination}
          disablePagination
          loading={loading}
          sectionHeader="Account"
          sectionValueField="accountLabel"
          sectionSummary={`Total ${sectionTitle}`}
        />
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default SectionContent;
