import React, { PropsWithChildren } from 'react';
import { Auth0Provider as Provider } from '@auth0/auth0-react';

const Auth0Provider: React.FC<PropsWithChildren<{ history: any }>> = ({
  children,
  history,
}) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState) => {
    const redirectTo =
      (appState && appState.returnTo) || window.location.pathname;
    history.push(redirectTo);
  };

  return (
    <Provider
      domain={domain ?? ''}
      clientId={clientId ?? ''}
      authorizationParams={{
        audience,
        redirect_uri: window.location.origin,
        scope: 'profile, email',
      }}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Provider>
  );
};

export default Auth0Provider;
