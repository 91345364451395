import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, styled, useMediaQuery, useTheme } from '@mui/material';
import { useLayoutContext } from 'contexts/LayoutContext';
import { NavLink } from 'react-router-dom';

const Logo = styled('img')({
  maxWidth: '100px',
  cursor: 'pointer',
});

const MenuToggle = styled(IconButton)({
  position: 'absolute',
  display: 'block',
  left: '15px',
  top: '9px',
  '& svg': {
    color: '#000',
  },
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  width: '100%',
  paddingLeft: '16px',
  paddingRight: '16px',
  minHeight: '64px',
  [theme.breakpoints.up('md')]: {
    minHeight: '72px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  width: '100%',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  transition: 'all 0.3s ease',
  zIndex: 9,
  background: theme.palette.background.default,
  boxShadow: 'none',
  '& > div': {
    justifyContent: 'center',
  },
  '& img': {
    verticalAlign: 'middle',
  },
}));

const Header: React.FC = () => {
  const theme = useTheme();
  const { isSidebarOpen, setSidebarOpen } = useLayoutContext();
  const showHeader = useMediaQuery(
    theme.breakpoints.down(theme.sidebar?.drawerBreakpoint ?? 'sm'),
  );

  if (!showHeader) {
    return null;
  }

  return (
    <StyledAppBar position="fixed">
      <StyledToolbar>
        <MenuToggle
          onClick={() => setSidebarOpen(!isSidebarOpen)}
          edge="start"
          color="inherit"
          aria-label="menu"
          size="large"
        >
          <MenuIcon />
        </MenuToggle>

        <NavLink to="/">
          <Logo src="/images/logo.png" alt="logo" />
        </NavLink>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default Header;
