import { Typography } from '@mui/material';

type Props = {
  investment: {
    fundId?: {
      fundName: string;
    };
    investor?: {
      fullName: string;
    };
    investmentDate: string;
    externalInvestmentId?: string;
  };
};

const FrozenInvestment: React.FC<Props> = ({ investment }) => {
  return (
    <Typography variant="subtitle2">
      {investment.investor?.fullName} - {investment.fundId?.fundName} (
      {new Date(investment.investmentDate).toDateString()}{' '}
      {investment.externalInvestmentId
        ? `[${investment.externalInvestmentId}]`
        : null}
      )
    </Typography>
  );
};

export default FrozenInvestment;
