import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { api } from 'lib/httpClient';
import { useGetRole } from 'hooks/ui/useGetRole';
import { dateToLabel } from 'further-ui/utils';
import { InvestorType } from 'further-types/investor';
import useInvestorCertification from 'hooks/data/investor/useInvestorCertification';
import RecordRow from 'components/RecordRow';

type Props = {
  id: string;
};

const CoolOffStatus: React.FC<Props> = ({ id }) => {
  const [coolOffs, setCoolOffs] = useState<Array<Record<string, any>>>([]);
  const { isSuperAdmin } = useGetRole();
  const { certification } = useInvestorCertification(id);

  useEffect(() => {
    const getCoolOffs = async () => {
      try {
        const response = await api.get(`investment-cool-off/${id}`);
        if (!isEmpty(response.data.data)) {
          setCoolOffs(response.data.data);
        }
      } catch (_) {
        //TODO: handle error
      }
    };
    getCoolOffs();
  }, [id]);

  const professionalClientCertification = (certification.data ?? []).find(
    ({ forceExpired, investorType }) =>
      !forceExpired && investorType === InvestorType.ProfessionalClient,
  );

  return (
    <RecordRow label="24-hour cool off">
      {!coolOffs.length && <span>N/A</span>}
      {coolOffs.map((coolOff) => (
        <div key={coolOff._id}>
          {isSuperAdmin && coolOff.firmId?.firmName && (
            <span>{coolOff.firmId.firmName} - </span>
          )}
          {coolOff && !!professionalClientCertification ? (
            <>
              <span>Exempt as a professional client </span>
              <span>
                {dateToLabel(
                  professionalClientCertification.certificationPerformedAt,
                  undefined,
                  true,
                )}
              </span>
            </>
          ) : (
            <>
              {coolOff && coolOff.isElapsed && (
                <>
                  <span>Complete - </span>
                  <span>
                    {dateToLabel(coolOff.elapsesAfter, undefined, true)}
                  </span>
                </>
              )}
              {coolOff && !coolOff.isElapsed && (
                <>
                  <span>Pending </span>
                  <span>
                    (until {dateToLabel(coolOff.elapsesAfter, undefined, true)})
                  </span>
                </>
              )}
            </>
          )}
        </div>
      ))}
    </RecordRow>
  );
};
export default CoolOffStatus;
