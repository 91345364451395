import { api } from 'lib/httpClient';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

async function fetchFundsProgress({ queryKey }) {
  const [, params] = queryKey;
  const {
    data: { data },
  } = await api.get(`investor-reporting/closed-funds-progress`, {
    params,
  });
  return data;
}

export function useReportingFundsProgress(fundsIds, submitDate, enabled) {
  return useQuery({
    queryKey: [
      'investor-reporting-funds-progress',
      { fundsIds: fundsIds?.sort((a, b) => a.localeCompare(b)), submitDate },
    ],
    queryFn: fetchFundsProgress,
    enabled: !!fundsIds?.length && !!submitDate && enabled,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}
