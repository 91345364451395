import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { CardContent, Card, Skeleton } from '@mui/material';
import { ViewQuilt } from '@mui/icons-material';
import Table, { TableActions } from 'components/Table';
import { createOpenTrancheRoute } from 'adminConstants/routes';
import { numberToDisplayString } from 'utils/numbers';
import useOpenTranches from 'hooks/data/dashboards/useOpenTranches';
import Heading from 'components/Heading';
import LoadingError from '../LoadingError';
import useInitialElementSize from 'hooks/ui/useInitialElementSize';

const OpenTranchesTable = ({ firmId, classes }) => {
  const { data, isFetching, isRefetching, isError } = useOpenTranches({
    firmId,
  });

  const isLoading = isFetching || isRefetching;

  const columns = [
    {
      sort: false,
      label: 'Tranche',
      key: 'firmAndTrancheName',
      render: (elm) =>
        isLoading ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          `${elm?.firmName} - ${elm?.fundName}`
        ),
    },
    {
      sort: false,
      label: 'Scheduled close date',
      key: 'finishDate',
      render: (elm) =>
        isLoading ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          dateToLabel(elm?.finishDate)
        ),
    },
    {
      sort: false,
      label: 'Subscriptions (#)',
      key: 'sumInvestments',
      render: (elm) =>
        isLoading ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          numberToDisplayString(elm?.sumInvestments)
        ),
    },
    {
      sort: false,
      label: 'Subscriptions (£)',
      key: 'sumInvestmentAmount',
      render: (elm) =>
        isLoading ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          numberToCurrencyString(elm?.sumInvestmentAmount, {
            hidePenniesIfInteger: true,
          })
        ),
    },
    {
      sort: false,
      key: 'actions',
      label: '',
      render: (elm) =>
        isLoading ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          <TableActions
            actions={[
              {
                label: 'View',
                icon: ViewQuilt,
                link: createOpenTrancheRoute(elm?.trancheId),
                color: 'primary',
              },
            ]}
          />
        ),
    },
  ];

  const [ref, { height }] = useInitialElementSize();
  if (isError) {
    return <LoadingError height={height} />;
  }

  return (
    <Card ref={ref}>
      <CardContent className={classes.scrollableTable}>
        <Heading variant="h3" showDivider>
          Current open tranches
        </Heading>
        <Table
          columns={columns}
          tablebody={
            data || !isLoading ? data : Array.from({ length: 10 }, () => ({}))
          }
          pagination={false}
          emptyMessage="No open tranches found"
          variant="nohover"
        />
      </CardContent>
    </Card>
  );
};

export default OpenTranchesTable;
