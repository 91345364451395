import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid2';
import { makeStyles } from 'tss-react/mui';
import FormLabel from 'components/FormElements/FormLabel';

const useStyles = makeStyles()(() => ({
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

type Props = {
  title?: string;
  informationText?: string | React.ReactNode;
  children: React.ReactNode;
  centerTitle?: boolean;
  topTitle?: boolean;
  tooltipText?: ReactNode;
  spacing?: number;
  fullWidth?: boolean;
};

const FieldRow: React.FC<Props> = ({
  title,
  informationText,
  children,
  centerTitle,
  topTitle,
  tooltipText,
  spacing,
  fullWidth,
}) => {
  const { classes } = useStyles();
  const showLabel = !!title;

  return (
    <Grid container spacing={{ xs: 0, md: fullWidth ? 0 : 6 }}>
      {showLabel && (
        <>
          <Grid
            size={{ md: fullWidth ? 12 : 4, xs: 12 }}
            className={centerTitle ? classes.center : ''}
            style={{ paddingTop: spacing, paddingBottom: spacing }}
          >
            <FormLabel
              title={title}
              informationText={informationText}
              tooltipText={tooltipText}
              topTitle={topTitle}
            />
          </Grid>
          <Grid
            size={{ md: fullWidth ? false : 1, xs: false }}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          />
        </>
      )}
      <Grid
        size={{ md: showLabel ? (fullWidth ? 12 : 7) : 12, xs: 12 }}
        style={{ paddingTop: spacing, paddingBottom: spacing }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default FieldRow;
