import downloadFile from 'utils/downloadFile';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { Api } from 'further-types/fees';
import {
  FeeAccounting,
  FeeCalculationBasis,
  FeeType,
} from 'further-types/investment';
import { ApiResponse } from 'further-types/api';
import { useState } from 'react';

type Fee = {
  date: string;
  feeType: FeeType;
  feeName: string;
  grossFeePercentage: number;
  netFeePercentage: number;
  vat: number;
  feeIncVAT: number;
  feeExVAT: number;
  feeAccounting: FeeAccounting;
  vatFee: number;
  feeCalculationBasis: FeeCalculationBasis;
  custodian?: boolean;
};

type CashMovement = {
  amount: number;
  amountExVat: number;
  vat: number;
  date: string;
  destination: 'INVESTOR_FEE';
  firmId: string;
  investorId: string;
  notes: string;
  source?: string;
};

export type InvestmentWithFee = {
  investmentId: string;
  investmentDate: string;
  investmentAmount: number;
  externalInvestmentId: string;
  investorId: {
    fullName: string;
  };
  newFee: Fee;
};

export type InvestorFee = {
  investorId: {
    fullName: string;
  };
  cashMovement: CashMovement;
};

type CalculateInvestmentFeesResponse = {
  data: {
    investmentsWithFees: InvestmentWithFee[];
    investmentsWithErrorsIds: string[];
    investmentsWithDateErrors: string[];
  };
  statusCode: number;
};

type CalculateInvestorFeesResponse = {
  data: {
    investorFees: InvestorFee[];
    investmentsWithErrorsIds: string[];
  };
  statusCode: number;
};

type FeeInput = {
  feeType: string;
  feeName: string;
  investmentIds: string[];
  fundIds: string[];
  amount: string;
  vat: string;
  date: Date;
};

type CreateInvestorFeesInput = {
  firmId: string;
  investorFees: {
    cashMovement: CashMovement;
  }[];
};

const useCalculateInvestmentFees = () => {
  return useMutation<CalculateInvestmentFeesResponse, unknown, FeeInput>({
    mutationFn: async (data) => {
      const { data: result } = await api.post<CalculateInvestmentFeesResponse>(
        'fee/calculate-investment-fees',
        data,
      );
      return result;
    },
  });
};

const useCalculateInvestorFees = () => {
  return useMutation<CalculateInvestorFeesResponse, unknown, FeeInput>({
    mutationFn: async (data) => {
      const { data: result } = await api.post<CalculateInvestorFeesResponse>(
        `fee/calculate-investor-fees`,
        data,
      );
      return result;
    },
  });
};

const useCreateInvestorFees = () => {
  const queryClient = useQueryClient();
  return useMutation<ApiResponse<unknown>, unknown, CreateInvestorFeesInput>({
    mutationFn: async (data) => {
      const { data: result } = await api.post<ApiResponse<unknown>>(
        `fee/create-investor-fees`,
        data,
      );
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['one-off-fees-and-discounts'],
      });
    },
  });
};

const useCreateInvestmentFees = () => {
  const queryClient = useQueryClient();
  return useMutation<
    ApiResponse<unknown>,
    unknown,
    Api.CreateInvestmentFeesRequest
  >({
    mutationFn: async (data) => {
      const { data: result } = await api.post<ApiResponse<unknown>>(
        'fee/create-investment-fees',
        data,
      );
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['one-off-fees-and-discounts'],
      });
    },
  });
};

const useCreateFeesUploadFile = () => {
  const [isCreatingFeesUploadFile, setIsCreatingFeesUploadFile] =
    useState(false);

  return {
    createFeesUploadFile: async (
      fundIds: string[],
      feeName: string,
      chargeTo: 'investment' | 'investor',
      firmId: string,
    ) => {
      try {
        setIsCreatingFeesUploadFile(true);
        const response = await api.post(
          'fee/create-fees-upload-file',
          { fundIds, feeName, chargeTo, firmId },
          { responseType: 'blob' },
        );
        if (
          response.headers['content-type']?.startsWith(
            'application/vnd.openxmlformats',
          )
        ) {
          downloadFile(
            response.data,
            `Fees-and-Discounts-Upload-Sheet-${Date.now()}.xlsx`,
          );
          setIsCreatingFeesUploadFile(false);
          return true;
        }
      } catch (_) {
        return false;
      }
      setIsCreatingFeesUploadFile(false);
      return false;
    },
    isCreatingFeesUploadFile,
  };
};

const useUploadFeesFile = () => {
  const [isUploadingFeesFile, setIsUploadingFeesFile] = useState(false);

  return {
    isUploadingFeesFile,
    uploadFeesFile: async (formData: FormData) => {
      setIsUploadingFeesFile(true);
      try {
        const result = await api.post('fee/upload-fees-file', formData);
        return result;
      } finally {
        setIsUploadingFeesFile(false);
      }
    },
  };
};

const useAddFeesAndDiscounts = () => {
  const { isPending: isCalculatingFees, mutateAsync: calculateInvestmentFees } =
    useCalculateInvestmentFees();
  const { isPending: isCreatingFee, mutateAsync: createInvestmentFee } =
    useCreateInvestmentFees();
  const { mutateAsync: calculateInvestorFees } = useCalculateInvestorFees();
  const { mutateAsync: createInvestorFees } = useCreateInvestorFees();
  const { createFeesUploadFile, isCreatingFeesUploadFile } =
    useCreateFeesUploadFile();
  const { uploadFeesFile, isUploadingFeesFile } = useUploadFeesFile();

  return {
    createFeesUploadFile,
    isCreatingFeesUploadFile,
    uploadFeesFile,
    isUploadingFeesFile,
    calculateInvestmentFees,
    calculateInvestorFees,
    createInvestmentFee,
    createInvestorFees,
    isCalculatingFees,
    isCreatingFee,
  };
};

export default useAddFeesAndDiscounts;
