import { FC } from 'react';
import {
  CircularProgress,
  Typography,
  TablePagination,
  Box,
} from '@mui/material';
import PageContainer from 'components/PageContainer';
import { usePagination } from 'hooks/ui/usePagination';
import useOutstandingPayments from 'hooks/data/paymentExpectation/useOutstandingPayments';
import useFilters from 'hooks/ui/useFilters';
import PaymentExpectationRow from './PaymentExpectationRow';
import BulkPaymentAssignmentFilters from './Filters';
import PageContent from 'components/PageContent';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Bulk payment assignment', isActive: true },
];

const BulkPaymentAssignment: FC = () => {
  const pagination = usePagination({
    id: 'bulk-payment-assignment',
  });
  const { filters, handleChangeFilters } = useFilters(
    'bulk-payment-assignment',
    {},
  );
  const { data, isLoading } = useOutstandingPayments({
    page: pagination.page,
    rowsPerPage: pagination.rowsPerPage,
    ...filters,
  });

  return (
    <PageContainer heading="Bulk payment assignment" breadcrumbs={breadcrumbs}>
      <PageContent>
        <BulkPaymentAssignmentFilters
          filters={filters}
          onFiltersChange={handleChangeFilters}
        />
        {isLoading && <CircularProgress />}
        <Box gap={4} display="flex" flexDirection="column">
          {(data?.payments ?? []).map((payment) => (
            <PaymentExpectationRow key={payment._id} payment={payment} />
          ))}
        </Box>
        {!isLoading && data?.payments?.length === 0 && (
          <Typography fontWeight={500} fontSize="14px">
            No outstanding payments found
          </Typography>
        )}
        <TablePagination
          color="primary"
          component="div"
          count={data?.total ?? 0}
          page={pagination.page === 0 ? 0 : pagination.page - 1}
          onPageChange={pagination.handleChangePage}
          rowsPerPage={pagination.rowsPerPage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        />
      </PageContent>
    </PageContainer>
  );
};

export default BulkPaymentAssignment;
