import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Disclosure } from 'further-ui/hooks/useDisclosure';
import Dialog from 'components/Dialog';
import BankAccountForm from './BankAccountForm';
import { useAddFirmBankDetails } from 'hooks/data/firm/useFirmBankDetails';
import { useNotification } from 'hooks/ui/useNotification';
import { Api } from 'further-types/firm';
import schema from './schema';

type Props = {
  firmId: string;
  disclosure: Disclosure;
};

const AddBankAccountDialog = ({ firmId, disclosure }: Props) => {
  const notification = useNotification();
  const form = useForm({
    shouldFocusError: true,
    resolver: zodResolver(schema),
    criteriaMode: 'all',
    defaultValues: {
      beneficiaryName: '',
      beneficiaryAddress: '',
      bankName: '',
      bankAddress: '',
      bankAccountNumber: '',
      bankSortCode: '',
      iban: '',
      swift: '',
    },
  });
  const { mutateAsync } = useAddFirmBankDetails(firmId);

  const onAddNewBankAccount = async (data: Api.CreateFirmBankDetailBody) => {
    try {
      await mutateAsync(data);
      notification.success('Bank account added');
      form.reset();
    } catch (_) {
      notification.error('Bank account could not be added');
    } finally {
      disclosure.onClose();
    }
  };

  return (
    <Dialog
      open={disclosure.isOpen}
      title="Add new bank account"
      onConfirm={form.handleSubmit(onAddNewBankAccount)}
      onClose={disclosure.onClose}
      btnLabels={{ cancel: 'Cancel', confirm: 'Save' }}
    >
      <BankAccountForm errors={form.formState.errors} control={form.control} />
    </Dialog>
  );
};

export default AddBankAccountDialog;
