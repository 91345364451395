import { makeStyles } from 'tss-react/mui';
import { Tooltip } from '@mui/material';
import { Api } from 'further-types/eis-wizard';
import { FC } from 'react';
import Chip from 'components/Chip';

const useStyles = makeStyles()(() => ({
  naPadding: {
    margin: '0.25rem 0 ',
    display: 'block',
  },
}));

type Props = {
  tasklistItem: Api.GetEisWizardTaskListResponse['results'][number];
};

const ProcessCompletedChip: FC<Props> = ({ tasklistItem }) => {
  const { classes } = useStyles();

  if (!tasklistItem?.taxReliefElegible)
    return <span className={classes.naPadding}>N/A</span>;

  return tasklistItem.eisWizard?.id || tasklistItem.isKiFundCertificate ? (
    <>
      {tasklistItem.createdWithoutCertificates ? (
        <Tooltip
          arrow
          title={`Created without certificates (as ${tasklistItem?.eisWizard?.investmentType})`}
        >
          <span>
            <Chip variant="disabled">Yes</Chip>
          </span>
        </Tooltip>
      ) : (
        <Chip variant="success">Yes</Chip>
      )}
    </>
  ) : (
    <Chip variant="error">No</Chip>
  );
};

export default ProcessCompletedChip;
