import React, { useEffect, useState } from 'react';
import {
  TableRow,
  TableCell,
  Checkbox,
  Collapse,
  IconButton,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import { Skeleton } from '@mui/material';

import { alpha } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

import CircularProgress from '@mui/material/CircularProgress';
import { Column } from './types';

const useStyles = makeStyles<void, string>()((theme, _params, classes) => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor?.main}`,
    '&:not(.TableItem-noHover):hover, &.active': {
      backgroundColor: theme.palette.background.rootColor,

      [`& .${classes.tableCellRoot}, & .${classes.titleRoot}`]: {
        color: theme.palette.text.white,
      },
      [`& .${classes.showContent}`]: {
        width: 0,
      },
      [`& .${classes.hideContent}`]: {
        transform: 'translateX(0)',
        width: '100%',
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor?.main}`,
    },
    '&:first-of-type': {
      borderTopWidth: 0,
    },
    '&:not(.TableItem-noHover):hover': {
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(
        theme.palette.common?.dark ?? 'transparent',
        0.2,
      )}`,
      borderTopColor: 'transparent',
    },
    '&.collapse-table-row': {
      borderTop: '0 none',
      [`& .${classes.tableCellRoot}`]: {
        padding: 0,
      },
    },
    '&.active': {
      borderTop: '0 none',
      '&:hover': {
        transform: 'none',
        boxShadow: 'none',
      },
    },
  },
  tableCellRoot: {
    padding: '18px 6px 15px',
    fontSize: 14,
    color: theme.palette.text.secondary,
    borderBottom: '0 none',
    position: 'relative',
    '&:first-of-type': {
      paddingLeft: 24,
    },
  },
  tableCellClickable: {
    cursor: 'pointer',
  },
  tableCollapseCell: {
    borderBottom: '0 none',
  },
  tableCellHideShow: {
    width: '25%',
  },
  titleRoot: {
    color: theme.palette.text.secondary,
    letterSpacing: 0.25,
  },
  hideShowContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  showContent: {
    transition: 'all 0.3s ease-in-out',
    width: 0,
    // overflow: 'hidden',
    position: 'absolute',
    left: 0,
  },
  hideContent: {
    transition: 'all 0.3s ease-in-out',
    transform: 'translateX(110%)',
    overflow: 'hidden',
  },
  hideShowLink: {
    cursor: 'pointer',
  },
  collapseTable: {
    paddingLeft: 60,
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 12,
      letterSpacing: 0.4,
      padding: 0,
      borderBottom: '0 none',
    },
  },
  mtArrow: {
    display: 'flex',
  },
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255,255,255,0.5)',
    zIndex: 1,

    '& ~ *': {
      // this is to blur the table content
      filter: 'blur(1px)',
    },
  },
  ellipsis: {
    display: 'block',
    width: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

type Props<T> = {
  rows: Array<T>;
  columns: Array<Column<T>>;
  loading?: boolean;
  emptyMessage?: string;
  variant?: string;
  showSkeleton?: boolean;
  rowsPerPage?: number;
  selectable?: boolean;
  onSelect: (index: number) => void;
  selectedRowsIndexes: Array<number>;
  hideEmptyMessage?: boolean;
  collapsedContent?: (row: T) => React.ReactNode;
};

const TableItem = <T extends Record<string, any>>({
  rows,
  columns,
  loading,
  emptyMessage,
  variant,
  showSkeleton,
  rowsPerPage,
  selectable,
  onSelect,
  selectedRowsIndexes,
  hideEmptyMessage,
  collapsedContent,
}: Props<T>) => {
  const { classes, cx } = useStyles();
  const rowClassname = variant === 'nohover' ? 'TableItem-noHover' : '';

  const rowsToRender = showSkeleton
    ? Array.from(Array(rowsPerPage).keys())
    : rows;

  const [expandedRows, setExpandedRows] = useState<Array<string>>([]);

  const handleExpandedRow = (rowKey: string) => {
    if (expandedRows.includes(rowKey)) {
      setExpandedRows(expandedRows.filter((row) => row !== rowKey));
    } else {
      setExpandedRows([...expandedRows, rowKey]);
    }
  };

  useEffect(() => {
    setExpandedRows([]);
  }, [JSON.stringify(rowsToRender), rowsPerPage]);

  return (
    <React.Fragment>
      {loading && (
        <tr className={cx(classes.loadingContainer)}>
          <td>
            <CircularProgress />
          </td>
        </tr>
      )}
      {rowsToRender.length || showSkeleton ? (
        rowsToRender.map((row: T | number, index: number) => {
          const isExpanded = expandedRows.includes(`row-${index}`);
          return (
            <React.Fragment key={`row-${index}`}>
              <TableRow className={cx(classes.tableRowRoot, rowClassname)}>
                <React.Fragment key={`row-${index}`}>
                  {selectable ? (
                    <TableCell
                      className={cx(classes.tableCellRoot)}
                      padding="checkbox"
                    >
                      <Checkbox
                        color="primary"
                        checked={selectedRowsIndexes.includes(index)}
                        onClick={() => onSelect(index)}
                      />
                    </TableCell>
                  ) : null}
                </React.Fragment>

                {columns.map((sinCol, i) => {
                  const displayValue = showSkeleton
                    ? ''
                    : sinCol.render
                    ? sinCol.render(row as T, index)
                    : row[sinCol.key];

                  return (
                    <React.Fragment key={`all-col-${i}`}>
                      <TableCell
                        key={i}
                        className={cx(
                          classes.tableCellRoot,
                          collapsedContent ? classes.tableCellClickable : '',
                          sinCol.tdClassname,
                          !showSkeleton
                            ? sinCol.getCellClassName?.(row as T)
                            : null,
                        )}
                        size={sinCol.size}
                        width={columns.length === 2 ? '50%' : ''}
                        onClick={() =>
                          !!collapsedContent &&
                          handleExpandedRow(`row-${index}`)
                        }
                      >
                        {displayValue ? (
                          <div
                            className={sinCol.ellipsis ? classes.ellipsis : ''}
                          >
                            {displayValue}
                          </div>
                        ) : loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </React.Fragment>
                  );
                })}
                {!!collapsedContent && (
                  <TableCell className={cx(classes.tableCellRoot)}>
                    <IconButton
                      size="medium"
                      onClick={() => handleExpandedRow(`row-${index}`)}
                    >
                      {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
              {isExpanded && (
                <TableRow>
                  <TableCell
                    className={cx(classes.tableCollapseCell)}
                    colSpan={columns.length}
                  >
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                      {!showSkeleton && collapsedContent?.(row as T)}
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          );
        })
      ) : !hideEmptyMessage ? (
        <TableRow>
          <TableCell colSpan={columns?.length + (collapsedContent ? 1 : 0)}>
            <div className={cx(classes.emptyContainer)}>
              {emptyMessage || 'No record found'}
            </div>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
};

export default TableItem;
