import { useEffect } from 'react';
import { Avatar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Delete, Edit } from '@mui/icons-material';
import Table, { TableActions } from 'components/Table';
import PageContainer from 'components/PageContainer';

import { USER_ADD, createEditUserRoute } from 'adminConstants/routes';
import AlertDialog from 'components/AlertDialog';
import TextField from 'components/FormElements/AppTextInput';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { usePagination } from 'hooks/ui/usePagination';
import useFilters from 'hooks/ui/useFilters';
import { useGetAdminUsers } from 'hooks/data/adminUser/useAdminUsers';
import { useDisclosure } from 'further-ui/hooks';
import { useMutateAdminUser } from 'hooks/data/adminUser/useAdminUser';
import PageContent from 'components/PageContent';
import PageFilters, { Filter } from 'components/PageFilters';

const useStyles = makeStyles()(() => ({
  dFlex: {
    display: 'flex',
  },
  marginFirstName: {
    margin: '10px 0px 0px 10px',
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Users', isActive: true },
];

const User = () => {
  const { classes } = useStyles();
  const deleteDisclosure = useDisclosure<{ _id: string }>();
  const [createPermission, updatePermission, deletePermission] =
    useGetPermissions(['create:user', 'edit:user', 'delete:user']);

  const {
    page,
    rowsPerPage,
    order,
    orderBy,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    toFirstPage,
  } = usePagination({ id: 'users-table', orderBy: 'email' });

  const { filters, handleChangeFilters } = useFilters('users-table', {
    onFiltersChange: toFirstPage,
    defaultFilters: { search: null },
  });

  const { data: users, isFetching } = useGetAdminUsers({
    page: page === 0 ? 1 : page,
    perPage: rowsPerPage,
    type: order,
    keyName: orderBy,
    //@ts-expect-error
    search: filters.search,
  });
  const { remove } = useMutateAdminUser();

  useEffect(() => {
    deleteDisclosure.onClose();
  }, [remove.isSuccess]);

  const handleConfirmDelete = async () => {
    remove.mutate(deleteDisclosure.actionProps?._id, {
      onSuccess: () => {
        deleteDisclosure.onClose();
      },
    });
  };

  const columns = [
    {
      label: 'First name',
      key: 'firstName',
      render: (elm) => (
        <div className={classes.dFlex}>
          <Avatar src={elm?.profilePic} />
          <div className={classes.marginFirstName}>{elm?.firstName}</div>
        </div>
      ),
    },
    { label: 'Last name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    {
      label: 'Actions',
      key: 'actions',
      sort: false,
      render: (elm) => (
        <TableActions
          showAsDropdown
          actions={[
            {
              label: 'Edit',
              icon: Edit,
              color: 'primary',
              link: createEditUserRoute(elm._id),
              visible: updatePermission,
            },
            {
              label: 'Delete',
              icon: Delete,
              color: 'error',
              onClick: () => deleteDisclosure.stageAction({ _id: elm._id }),
              visible: deletePermission,
            },
          ]}
        />
      ),
    },
  ];

  return (
    <PageContainer heading="Users" breadcrumbs={breadcrumbs}>
      <PageContent>
        <PageFilters
          pageId="users-table"
          buttons={[
            {
              visible: createPermission,
              label: 'Add User',
              link: USER_ADD,
            },
          ]}
        >
          <Filter>
            <TextField
              type="search"
              name="search"
              placeholder="Search by name or email"
              fullWidth
              value={filters.search}
              onChange={(event) => {
                //@ts-expect-error
                handleChangeFilters({ search: event.target.value });
              }}
            />
          </Filter>
        </PageFilters>

        <Table
          columns={columns}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          tablebody={users?.result ?? []}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          count={users?.total}
          pagination={true}
          loading={isFetching}
          variant="nohover"
        />
      </PageContent>

      <AlertDialog
        open={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
        onConfirm={handleConfirmDelete}
        title={`Are you sure you wish to delete this user?`}
        content={
          <p>
            This cannot be undone and all information relating to the user type
            will be lost.
          </p>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Delete user',
        }}
        confirmBtnProps={{ disabled: remove.isPending }}
        cancelBtnProps={{ disabled: remove.isPending }}
      />
    </PageContainer>
  );
};

export default User;
