import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CkEditor from 'components/CkEditor';
import Dropzone, { UploadedFile } from 'components/Dropzone';
import { AppSelectBox } from 'components/FormElements';
import { Api, KycRiskRating } from 'further-types/kyc';
import useFileUpload from 'hooks/ui/useFileUpload';
import { useNotification } from 'hooks/ui/useNotification';
import { capitalize, compact, lowerCase } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { UploadType } from 'further-types/files';
import Heading from 'components/Heading';
import { Column, Row } from 'components/Layout';

const Close = styled(IconButton)({
  position: 'absolute',
  right: 0,
  top: 0,
});

const FullWidth = styled('div')({
  width: '100%',
});

const FlexGrow = styled('div')({
  flexGrow: 1,
});

const KycModal = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '100%',

    '& .MuiDialogContent-root': {
      paddingTop: 0,
    },
  },
});

const Title = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(4, 6),
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(4, 6),
}));

type FormValues = {
  manualApprovalNotes: string;
  riskRating: KycRiskRating;
  approve: boolean;
  manualApprovalFiles: Array<{ preview: string; fileName: string }>;
};

const formSchema = z.object({
  manualApprovalNotes: z.string().optional(),
  riskRating: z.nativeEnum(KycRiskRating),
  approve: z.boolean(),
  manualApprovalFiles: z.array(
    z.object({ preview: z.string(), fileName: z.string() }),
  ),
});

const getRiskRatingOptions = () => {
  return Object.values(KycRiskRating).map((riskRating) => ({
    value: riskRating,
    label: capitalize(lowerCase(riskRating)),
  }));
};

type Props = {
  onClose: () => void;
  processManualKyc: (data: Api.ManualKycProcessRequest) => void;
  kycData?: Api.KycDetails;
  isPassedManually: boolean;
  isLegalEntity: boolean;
};

const KycManualApprovalModal: React.FC<Props> = ({
  onClose,
  processManualKyc,
  kycData,
  isPassedManually,
  isLegalEntity,
}) => {
  const { uploadFile } = useFileUpload();
  const { error } = useNotification();
  const { control, setValue, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      manualApprovalNotes: kycData?.manualApprovalNotes,
      riskRating: kycData?.riskRating ?? KycRiskRating.Low,
      approve: isPassedManually,
      manualApprovalFiles: kycData?.manualApprovalFiles ?? [],
    },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (values: FormValues) => {
    processManualKyc({
      ...values,
      manualApprovalFiles: values.manualApprovalFiles.map(
        (file) => file.fileName,
      ),
    });
  };

  const handleFilesUpload = async (files: Array<UploadedFile>) =>
    compact(
      await Promise.all(
        files?.map(async (file) => {
          if (!file?.path) return null;

          try {
            const { filePath, url } = await uploadFile(
              file,
              UploadType.KycDocuments,
              undefined,
              file.name,
            );

            return { fileName: filePath, preview: url };
          } catch (_) {
            error("Something went wrong - the file couldn't be uploaded");
            return null;
          }
        }),
      ),
    );

  const title = isLegalEntity ? 'Manual KYB approval' : 'Manual KYC approval';
  const buttonText = isPassedManually
    ? 'Save changes'
    : isLegalEntity
    ? 'Save KYB'
    : 'Save KYC';
  return (
    <KycModal
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      maxWidth="sm"
    >
      <Close aria-label="close" onClick={onClose} size="large">
        <CloseIcon />
      </Close>
      <Title>
        <Heading variant="h2" noMargin>
          {title}
        </Heading>
      </Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Column spacing="md" centered>
            <Controller
              name="manualApprovalNotes"
              control={control}
              render={({
                field: { value, name },
                fieldState: { error },
                formState: { isSubmitting },
              }) => (
                <FullWidth>
                  <CkEditor
                    disabled={isSubmitting}
                    placeholder="Enter manual approval notes"
                    showLists={false}
                    onBlur={(_: unknown, editor: { getData: () => string }) => {
                      setValue(name, editor.getData());
                    }}
                    value={value}
                    onChange={undefined}
                  />
                  {error && <p>{error.message}</p>}
                </FullWidth>
              )}
            />
            <Controller
              name="riskRating"
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <AppSelectBox
                  inputRef={ref}
                  {...rest}
                  label="Risk rating"
                  data={getRiskRatingOptions()}
                  valueKey="value"
                  labelKey="label"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="manualApprovalFiles"
              control={control}
              render={({ field: { value, name }, fieldState: { error } }) => (
                <FullWidth>
                  <Dropzone
                    name={name}
                    accept={{
                      'image/jpeg': ['.jpg', '.jpeg'],
                      'image/png': ['.png'],
                      'application/pdf': ['.pdf'],
                    }}
                    maxFiles={100}
                    onDropFile={async (files) => {
                      setValue(name, [
                        ...value,
                        ...(await handleFilesUpload(files)),
                      ]);
                    }}
                    files={value}
                    onRemove={(deleteIndex) => {
                      setValue(
                        name,
                        value.filter((_, index) => index !== deleteIndex),
                      );
                    }}
                  />
                  {error && <p>{error.message}</p>}
                </FullWidth>
              )}
            />
          </Column>
        </DialogContent>
        <Actions>
          <FlexGrow>
            <Controller
              name="approve"
              control={control}
              render={({ field: { name, value } }) => (
                <Row centered spacing="xs">
                  <span>Save only</span>
                  <Switch
                    name={name}
                    checked={value}
                    inputProps={{ 'aria-label': 'Switch' }}
                    onChange={(_, checked) => {
                      setValue(name, checked);
                    }}
                  />

                  <span>Save and approve</span>
                </Row>
              )}
            />
          </FlexGrow>
          <Button onClick={onClose} variant="outlined">
            Go back
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {buttonText}
          </Button>
        </Actions>
      </form>
    </KycModal>
  );
};

export default KycManualApprovalModal;
