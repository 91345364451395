import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import qs from 'qs';

async function fetchAvailableTags({ queryKey }) {
  const [, firmId] = queryKey;
  const queryParams = qs.stringify({ firmId });

  const url = firmId
    ? `dashboard/available-tags?${queryParams}`
    : `dashboard/available-tags`;

  const { data } = await api.get(url);

  return data.data;
}

function useAvailableTags({ firmId }): {
  data?: { name: string; key: string; tagId: string }[];
} {
  return useQuery({
    queryKey: ['available-tags', firmId],
    queryFn: fetchAvailableTags,
    placeholderData: keepPreviousData,
    staleTime: 4 * 60 * 60 * 1000,
  });
}

export default useAvailableTags;
