import React, { useEffect, useMemo } from 'react';
import { useInvestorReportingFormContext } from 'contexts/InvestorReportingFormContext';
import CompanyInformationStepForm from './CompanyInformationStepForm';

type Props = any;

const CompanyInformationStep: React.FC<Props> = ({
  handleStepBack,
  isViewPage,
}) => {
  const { companyInformation } = useInvestorReportingFormContext();

  const sortedCompanyUpdates = useMemo(
    () =>
      //TODO: replace with proper types
      companyInformation.sort((companyA: any, companyB: any) =>
        `${companyA.tradingName} ${companyA.legalName} ${companyA.shareClass}`.localeCompare(
          `${companyB.tradingName} ${companyB.legalName} ${companyB.shareClass}`,
        ),
      ),
    [companyInformation],
  );

  useEffect(() => {
    // reset scroll state back to the top
    window.scrollTo(0, 0);
  }, []);

  return (
    <CompanyInformationStepForm
      sortedCompanyUpdates={sortedCompanyUpdates}
      handleStepBack={handleStepBack}
      isViewPage={isViewPage}
    />
  );
};

export default CompanyInformationStep;
