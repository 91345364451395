import { api } from 'lib/httpClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';

async function uploadInvestments(data) {
  try {
    return api.post(`investor/upload-investor-investment`, data);
  } catch (error) {
    throw error;
  }
}

async function validateInvestments(data) {
  try {
    return api.post(`investor/upload-investor-investment/validate`, data);
  } catch (error) {
    throw error;
  }
}

function useInvestmentUploadSaveAPI() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: uploadInvestments,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['investments'],
      });
      queryClient.invalidateQueries({
        queryKey: ['investors'],
      });
    },
  });
}

function useInvestmentUploadValidateAPI() {
  return useMutation({
    mutationFn: validateInvestments,
  });
}

export function useInvestmentUpload() {
  return {
    save: useInvestmentUploadSaveAPI(),
    validate: useInvestmentUploadValidateAPI(),
  };
}
