export const numberToPercentageString = (
  input: string | number,
  {
    accuracy = 1,
    noMultiply,
  }: { accuracy?: number; noMultiply?: boolean } = {},
) => {
  if (Number.isNaN(input)) return 'N/A';
  if (!input && input !== 0) return '';

  const inputNumber = typeof input === 'number' ? input : Number(input);

  const inputPcNumber = noMultiply ? inputNumber : inputNumber * 100;

  const isFractional = !Number.isInteger(inputPcNumber);
  const fractionDigitsConfig = isFractional
    ? {
        minimumFractionDigits: accuracy,
        maximumFractionDigits: accuracy,
      }
    : undefined;

  const figure = inputPcNumber.toLocaleString(undefined, fractionDigitsConfig);

  //check if the figure became a whole number after the rounding - e.g.  87.0 should become 87
  if (isFractional && Number.isInteger(Number(figure))) {
    return `${Number(figure)}%`;
  }

  return `${figure}%`;
};

export const numberToDisplayString = (
  input: number | string,
  options: Intl.NumberFormatOptions = {},
) => {
  if (Number.isNaN(input)) return 'N/A';
  if (!input && input !== 0) return '';

  return Number(input).toLocaleString(undefined, options);
};
