import React from 'react';
import { Button } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  Button: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '0 20px',
    },
  },
  SaveButton: {
    float: 'right',
    [theme.breakpoints.down('md')]: {
      float: 'none',
      marginTop: '20px',
    },
  },
}));

type Props = any;

const ActionButtons: React.FC<Props> = ({
  gridDataLength,
  handleAddRow,
  handleRemoveRow,
  loadingUploadPreview,
  handleValidateAndPreview,
}) => {
  const { classes } = useStyles();

  return (
    <>
      {gridDataLength < 350 && (
        <>
          <Button
            component="label"
            color="primary"
            onClick={() => handleAddRow(1)}
            className={`${classes.Button}`}
          >
            + Add 1 row
          </Button>
          {gridDataLength < 349 && (
            <Button
              component="label"
              color="primary"
              onClick={() =>
                handleAddRow(Math.min(Math.min(350 - gridDataLength, 350), 10))
              }
              className={`${classes.Button}`}
            >
              + Add {Math.min(Math.min(350 - gridDataLength, 350), 10)} rows
            </Button>
          )}
        </>
      )}
      {gridDataLength > 1 && (
        <Button
          component="label"
          color="secondary"
          onClick={() => handleRemoveRow(1)}
          className={`${classes.Button}`}
        >
          - Remove last row
        </Button>
      )}
      {gridDataLength > 2 && (
        <Button
          component="label"
          color="secondary"
          onClick={() =>
            handleRemoveRow(Math.min(Math.max(gridDataLength - 1, 1), 10))
          }
          className={`${classes.Button}`}
        >
          - Remove last {Math.min(Math.max(gridDataLength - 1, 1), 10)} rows
        </Button>
      )}
      {gridDataLength > 0 && (
        <Button
          variant="contained"
          color={loadingUploadPreview ? 'secondary' : 'primary'}
          component="label"
          disabled={loadingUploadPreview}
          onClick={handleValidateAndPreview}
          className={`${classes.SaveButton} ${classes.Button}`}
        >
          {loadingUploadPreview ? 'Validating...' : 'Validate and preview'}
        </Button>
      )}
    </>
  );
};

export default ActionButtons;
