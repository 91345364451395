import { Button, CircularProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, ReactNode } from 'react';
import GridContainer from 'components/GridContainer';
import useStyles from './styles';
import { FormValues } from './DetailsForm';
import { dateToLabel, getTaxYear } from 'further-ui/utils';
import Spacing from 'components/Spacing';
import ButtonGroup from 'components/ButtonGroup';

type Props = {
  onNext: () => void;
  onBack: () => void;
  values: FormValues;
  isLoading: boolean;
  selectedFund: {
    fundId: string;
    fundName: string;
    fundCloseDate: Date;
  };
};

const SummaryRow: FC<{ label: string; value: ReactNode }> = ({
  label,
  value,
}) => {
  const { classes } = useStyles();
  return (
    <GridContainer>
      <Grid size={{ md: 4, xs: 12 }} className={classes.summaryRow}>
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      </Grid>
      <Grid size={{ md: 1, xs: false }} />
      <Grid size={{ md: 7, xs: 12 }}>
        <Typography variant="body2" color="textSecondary">
          <strong>{value}</strong>
        </Typography>
      </Grid>
    </GridContainer>
  );
};

const Summary: FC<Props> = ({
  onNext,
  onBack,
  values,
  selectedFund,
  isLoading,
}) => {
  const { classes } = useStyles();

  return (
    <Spacing>
      <SummaryRow label="Tranche" value={selectedFund.fundName} />
      <SummaryRow
        label="Approved EIS KI fund reference number"
        value={values.fundReferenceNumber}
      />
      <SummaryRow
        label="Fund ‘closed for subscription’ date"
        value={`${dateToLabel(
          selectedFund.fundCloseDate,
        )}, which is in the tax year ending 5 April ${
          getTaxYear(selectedFund.fundCloseDate).end
        }`}
      />
      <SummaryRow
        label="Address 1"
        value={values.fundManagerAddress.address1}
      />
      <SummaryRow
        label="Address 2"
        value={values.fundManagerAddress.address2}
      />
      <SummaryRow label="City" value={values.fundManagerAddress.city} />
      <SummaryRow label="Country" value={values.fundManagerAddress.country} />
      <SummaryRow label="Postcode" value={values.fundManagerAddress.postcode} />
      <SummaryRow
        label="Name of fund manager signatory"
        value={values.fundManagerSignatoryName}
      />
      <SummaryRow
        label="Capacity in which signed"
        value={values.capicityInWhichSigned}
      />
      <SummaryRow
        label="Signature date"
        value={dateToLabel(values.signatureDate)}
      />

      <ButtonGroup>
        <Button color="primary" variant="outlined" onClick={onBack}>
          Back
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onNext}
          disabled={isLoading}
        >
          {isLoading && (
            <CircularProgress className={classes.buttonSpinner} size={16} />
          )}
          Submit
        </Button>
      </ButtonGroup>
    </Spacing>
  );
};

export default Summary;
