import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = {
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  centered?: boolean;
  breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  spacing?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
};

const spacingMap = {
  xs: 2,
  sm: 4,
  md: 6,
  lg: 8,
  xl: 10,
};

const StyledRow = styled('div')(
  ({ theme, centered, breakpoint, spacing, justify = 'flex-start' }) => ({
    display: 'flex',
    justifyContent: justify,
    alignItems: centered ? 'center' : undefined,
    gap: theme.spacing(spacing),
    flexDirection: 'row',

    [theme.breakpoints.down(breakpoint)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',

      '& > *': {
        width: '100%',
      },
    },
  }),
);

const Row: React.FC<PropsWithChildren<Props>> = ({
  children,
  spacing,
  className,
  ...props
}) => {
  return (
    <StyledRow
      className={className}
      spacing={spacing ? spacingMap[spacing] : 0}
      {...props}
    >
      {children}
    </StyledRow>
  );
};

export default Row;
