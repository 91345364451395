import { sortBy } from 'lodash';
import { format } from 'date-fns';
import { api } from 'lib/httpClient';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useGetRole } from 'hooks/ui/useGetRole';
import downloadFile from 'utils/downloadFile';

async function fetchTranches({ queryKey }) {
  const [, filters] = queryKey;

  const response = await api.get('fund/list', {
    params: {
      ...filters,
    },
  });

  return response.data.data;
}

const exportFunds = async (params) => {
  const response = await api.get(`fund/list`, {
    params,
    responseType: 'blob',
  });

  if (
    response.status !== 200 ||
    !response.headers['content-type']?.startsWith(
      'application/vnd.openxmlformats',
    )
  ) {
    throw Error('Unexpected export response.');
  }

  downloadFile(
    response.data,
    `Tranche export-${format(new Date(), 'dd/MM/yyyy')}.xlsx`,
  );
};

export function useFunds({
  endDate,
  firmId,
  fundId,
  fundStatus,
  includeTrancheValue,
  includeInvestmentsSummary,
  keyName,
  page,
  perPage,
  startDate,
  type,
  fullyDeployed,
  leanResponse,
  fundName,
  tagId,
  snapshotDate,
  disabled,
  closedDate,
  isVCT,
}: any = {}) {
  const { firmId: usersFirmId } = useGetRole();

  const tranches = useQuery({
    queryKey: [
      'tranches',
      {
        endDate,
        firmId: usersFirmId || firmId, // tranches should always be scoped to the user's firm. NB. tihs also happens on the BE
        fundId,
        fundStatus,
        includeTrancheValue,
        includeInvestmentsSummary,
        keyName,
        page,
        perPage,
        startDate,
        type,
        fullyDeployed,
        leanResponse,
        fundName,
        tagId,
        snapshotDate,
        closedDate,
        isVCT,
      },
    ],
    queryFn: fetchTranches,
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
    enabled: !disabled,
  });

  return {
    tranches: {
      ...tranches,
      data: tranches.data?.result,
      total: tranches.data?.total,
    },
    exportFunds,
  };
}
export function useSortTranches(tranches) {
  const mappedTranche = (tranches ?? []).map((x) => ({
    _id: x._id,
    label: `${x?.firmId?.firmName} ${x?.fundName}`,
  }));
  const sortedTranche = sortBy(mappedTranche ?? [], (x) =>
    x.label.toLowerCase(),
  );
  return sortedTranche;
}
