import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import GridContainer from 'components/GridContainer';
import Heading from 'components/Heading';
import SnapshotBox from 'components/SnapshotBox/SnapshotBox';

type Totals = {
  totalAdvisers: number;
  investments: number;
  investors: number;
  totalCurrentValue: number;
  totalInitialInvestment: number;
  totalRealisedValue: number;
  totalUnrealisedValue: number;
  childOrganisationsCount: number;
};

type OrganisationSnapshotProps = {
  totals: Totals;
  isParent: boolean;
  isLoading: boolean;
};

export const OrganisationSnapshot = ({
  totals,
  isParent,
  isLoading,
}: OrganisationSnapshotProps) => {
  const {
    totalAdvisers = 0,
    investments = 0,
    investors = 0,
    totalInitialInvestment = 0,
    totalUnrealisedValue = 0,
    totalRealisedValue = 0,
    childOrganisationsCount = 0,
  } = totals || {};

  const averageInvestmentAmount =
    investments > 0 ? totalInitialInvestment / investments : 0;

  if (isLoading) {
    return (
      <GridContainer>
        <Grid
          size={{ xs: 12 }}
          style={{ textAlign: 'center', padding: '2rem' }}
        >
          <CircularProgress />
        </Grid>
      </GridContainer>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Heading variant="h2">Organisation snapshot</Heading>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid size={{ xs: 'grow' }}>
            <SnapshotBox value={totalAdvisers} subtitle="Advisers" />
          </Grid>
          {isParent && (
            <Grid size={{ xs: 'grow' }}>
              <SnapshotBox
                value={childOrganisationsCount}
                subtitle="Organisations"
              />
            </Grid>
          )}
          <Grid size={{ xs: 'grow' }}>
            <SnapshotBox value={investors} subtitle="Investors" />
          </Grid>
          <Grid size={{ xs: 'grow' }}>
            <SnapshotBox value={investments} subtitle="Subscriptions" />
          </Grid>
        </Grid>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid size={{ xs: 'grow' }}>
            <SnapshotBox
              value={totalInitialInvestment}
              subtitle="Total subscribed"
              isCurrency
            />
          </Grid>
          <Grid size={{ xs: 'grow' }}>
            <SnapshotBox
              value={totalUnrealisedValue}
              subtitle="Unrealised value"
              isCurrency
            />
          </Grid>
          <Grid size={{ xs: 'grow' }}>
            <SnapshotBox
              value={totalRealisedValue}
              subtitle="Realised value"
              isCurrency
            />
          </Grid>
          <Grid size={{ xs: 'grow' }}>
            <SnapshotBox
              value={averageInvestmentAmount}
              subtitle="Average subscription"
              isCurrency
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrganisationSnapshot;
