import { useFunds } from 'hooks/data/fund/useFunds';
import { useGetRole } from './useGetRole';
import { useFirm } from 'hooks/data/firm/useFirm';
import {
  createEisSidebarNav,
  createLpSidebarNav,
  MenuItemType,
} from 'helpers/layout/sidebarMenu';
import {
  createOpenTrancheRoute,
  createSingleFirmRoute,
} from 'adminConstants/routes';
import { Realm } from 'further-types/admin-user';

export type SidebarMenuItemType = MenuItemType;

export default function useSidebarMenuItems(realm?: Realm) {
  const { isFirmManager } = useGetRole();
  const isEisOrSuperRealm = realm === Realm.Eis || realm === Realm.Super;

  const { tranches } = useFunds({
    fundStatus: 1,
    leanResponse: true,
    disabled: !isEisOrSuperRealm,
  });
  const { firms } = useFirm({ params: { disabled: !isEisOrSuperRealm } });

  if (realm === Realm.Lp) {
    return createLpSidebarNav();
  }

  if (isEisOrSuperRealm) {
    if (!tranches.data?.length) {
      return createEisSidebarNav();
    }

    const openTrancheDashboardLinks = (tranches.data ?? []).map((fund) => ({
      name: `${fund.firmId.firmName} ${fund.fundName}`,
      type: 'item',
      link: createOpenTrancheRoute(fund?._id),
    }));

    if (isFirmManager) {
      return createEisSidebarNav(openTrancheDashboardLinks);
    }

    if (!firms.data?.result?.length) {
      return createEisSidebarNav();
    }

    const firmDashboardLinks = firms.data.result.map((firm) => ({
      name: `${firm.firmName}`,
      type: 'item',
      link: createSingleFirmRoute(firm?._id),
    }));

    return createEisSidebarNav(openTrancheDashboardLinks, firmDashboardLinks);
  }
}
