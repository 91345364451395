import useSidebarMenuItems from 'hooks/ui/useSidebarMenuItems.ts';
import SidebarHeader from './SidebarHeader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { styled } from '@mui/material';
import Navigation from './Navigation';
import useCurrentUser from 'hooks/data/currentUser/useCurrentUser';

const SidebarWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: theme.sidebar.width,
  height: '100%',
  boxSizing: 'border-box',
  transition: 'all 0.3s ease',
  zIndex: 1103,
  position: 'fixed',
  left: 0,
  top: 0,
  bottom: 0,
}));

const Content = styled('div')(({ theme }) => ({
  height: '100%',
  position: 'relative',
  transition: 'all 0.3s ease',
  backgroundColor: theme.palette.sidebar.bgColor,
  color: theme.palette.sidebar.textColor,
  overflow: 'hidden',
  boxShadow:
    '0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 3px 4px rgba(0, 0, 0, 0.14)',
  '& > *': {
    position: 'relative',
  },
}));

const Scrollbar = styled(PerfectScrollbar)({
  transition: 'all 0.3s ease',
  height: 'calc(100% - 270px) !important',
});

const SidebarContent: React.FC = () => {
  const { profile } = useCurrentUser();
  const realm = profile?.data?.realm;

  const menuItems = useSidebarMenuItems(realm);

  return (
    <SidebarWrapper>
      <Content>
        <SidebarHeader />
        {!!menuItems && (
          <Scrollbar>
            <Navigation menuItems={menuItems} />
          </Scrollbar>
        )}
      </Content>
    </SidebarWrapper>
  );
};

export default SidebarContent;
