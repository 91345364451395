import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  colorRed: {
    color: theme.palette.text.red,
  },
  colorGreen: {
    color: theme.palette.text.green,
  },
  switchCls: {
    margin: '-6px auto',
  },
  customAccordion: {
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  expends: {
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'inherit',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  cmAccHeader: {
    padding: '0 20px',
    background: 'rgba(234,233,232,0.2)',
    color: '#282525',
    minHeight: '48px !important',
  },
  fundsAccHeader: {
    cursor: 'default !important',
  },
  cmAccDetail: {
    padding: '25px 20px',
  },
  accordionHeader: {
    textAlign: 'center',
    letterSpacing: 0,
    fontWeight: 'bold',
    fontSize: 13,
    color: '#282525',
  },
  accordionToggleLabel: {
    letterSpacing: 0,
    fontWeight: 'bold',
    fontSize: 13,
  },
  progressIndication: {
    marginLeft: '2rem',
  },
  flexGap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gridGap: '1rem',
  },
  errorCk: {
    color: '#f44336',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  dateInput: {
    margin: '1rem 0 2rem 0',
  },
  noTranchesInfoText: {
    fontSize: 13,
    fontWeight: 600,
    color: '#282525',
  },
  noTranchesInfoList: {
    marginLeft: '1rem',
    marginTop: '0.5rem',
    color: 'black',
  },
  characterCount: {
    fontSize: 12,
    marginTop: 4,
  },
  characterCountError: {
    color: '#E8453C',
  },
  buttonContainer: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonLeftContainer: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '1rem',
  },
  accordionHeaderFundName: {
    padding: '4px 0',
  },
}));

export default useStyles;
