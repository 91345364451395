import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import qs from 'qs';

async function fetchTopParentOrganisations({ queryKey }) {
  const [, params] = queryKey;

  const queryParams = qs.stringify(params);

  const url = params.firmId
    ? `dashboard/top-parent-organisations/?${queryParams}`
    : `dashboard/top-parent-organisations`;

  const { data } = await api.get(url);

  return data.data;
}

function useTopParentOrganisations({ params }) {
  return useQuery({
    queryKey: ['dasboard-top-parent-organisations', params],
    queryFn: fetchTopParentOrganisations,
    placeholderData: keepPreviousData,
    staleTime: 4 * 60 * 60 * 1000,
  });
}

export default useTopParentOrganisations;
