import { useGetRole } from 'hooks/ui/useGetRole';
import PageContainer from 'components/PageContainer';
import { useBackgroundExports } from 'hooks/data/backgroundExports/useBackgroundExports';
import { BackgroundExportTypes } from 'further-types/background-exports';
import FullPageSuccess from 'components/FullPageSuccess';
import { useState } from 'react';
import FieldRow from 'components/FormElements/FieldRow';
import { FirmSelector } from 'components/FirmSelector';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import InvestorSearch from 'components/InvestorSearch';
import Button from 'components/Button';
import PageContent from 'components/PageContent';
import PageSection from 'components/PageSection';
import { useForm } from 'react-hook-form';
import { FC } from 'react';
import { backgroundExports as rules } from 'further-ui/validations';
import { z } from 'zod';
import ButtonGroup from 'components/ButtonGroup';

type FormValues = {
  firmId: string | null;
  params: z.infer<typeof rules.createBackgroundExportInput>['params'];
};

const CashTransactionsReport: FC = () => {
  const { isSuperAdmin, firmId } = useGetRole();
  const { handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      params: {
        dateRange: {
          startDate: undefined,
          endDate: undefined,
        },
        investorId: undefined,
      },
      firmId,
    },
  });
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    { label: 'Cash transactions report', link: '/', isActive: true },
  ];

  const { createBackgroundExport, isExporting } = useBackgroundExports();

  const handleExport = async (data: FormValues) => {
    createBackgroundExport.mutate({
      exportType: BackgroundExportTypes.CashTransactionsReport,
      firmId: data.firmId ?? undefined,
      params: data.params,
    });

    setShowSuccessScreen(true);
  };

  const heading = 'System Exports: Cash transactions';

  if (showSuccessScreen) {
    return (
      <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
        <FullPageSuccess
          title="Your cash transactions report is being exported"
          additionalText="Your report will be available soon in the My Downloads section below"
          proceedText="Create another report"
          proceedCallback={() => setShowSuccessScreen(false)}
        />
      </PageContainer>
    );
  }

  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <PageContent>
        <form onSubmit={handleSubmit(handleExport)}>
          <PageSection title="Export cash transactions report" withSpacing>
            {isSuperAdmin && (
              <FieldRow centerTitle title="Firm">
                <FirmSelector
                  firmId={watch('firmId') ?? undefined}
                  onChange={(firmId) => setValue('firmId', firmId)}
                  required={false}
                />
              </FieldRow>
            )}

            <FieldRow centerTitle title="Date range (optional)">
              <MuiDateRangePicker
                dateRange={watch('params.dateRange')}
                onDateRangeChange={(dates: {
                  startDate: Date | null;
                  endDate: Date | null;
                }) => {
                  const dateRange = {
                    startDate: dates.startDate ?? undefined,
                    endDate: dates.endDate ?? undefined,
                  };
                  setValue('params.dateRange', dateRange);
                }}
                TextFieldProps={{ placeholder: 'Select date range' }}
              />
            </FieldRow>

            <FieldRow centerTitle title="Select single investor (optional)">
              <InvestorSearch
                onInvestorSelect={(investorId) =>
                  setValue('params.investorId', investorId)
                }
                selectedInvestorId={watch('params.investorId')}
                firmId={firmId}
              />
            </FieldRow>

            <ButtonGroup>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                loading={isExporting}
              >
                Export
              </Button>
            </ButtonGroup>
          </PageSection>
        </form>
      </PageContent>
    </PageContainer>
  );
};
export default CashTransactionsReport;
