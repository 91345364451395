import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Table from 'components/Table';
import PageContainer from 'components/PageContainer';
import TextField from 'components/FormElements/AppTextInput';
import AlertDialog from 'components/AlertDialog';
import { deleteCompany } from '@redux/actions/Company';
import { FirmSelector } from 'components/FirmSelector';
import { COMPANY_ADD } from 'adminConstants/routes';
import { useDisclosure } from 'further-ui/hooks';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { usePagination } from 'hooks/ui/usePagination';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useDebounce } from 'usehooks-ts';
import { useNotification } from 'hooks/ui/useNotification';
import useFilters from 'hooks/ui/useFilters';
import {
  generateCollapsedTableColumns,
  generateMainTableColumns,
} from './tableConfig';
import useGroupedCompanies from 'hooks/data/company/useGroupedCompanies';
import useExportCompanies from 'hooks/data/company/useExportCompanies';
import PageContent from 'components/PageContent';
import PageFilters, { Filter } from 'components/PageFilters';

const useStyles = makeStyles()((theme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
  },
  dFlex: {
    display: 'flex',
  },
  addRoleBtn: {
    textAlign: 'right',
  },
  socialIcons: {
    maxWidth: 24,
  },
  investmentTypeWidth: {
    width: 110,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    paddingBottom: 8,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  actionButton: {
    marginRight: 8,
  },
  childRowContainer: {
    marginBottom: '1rem',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    borderRadius: '4px',
  },

  cmTableDarkHeading: {
    '& th': {
      backgroundColor: '#f0f0f0',
    },
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Companies', isActive: true },
];

const Company: React.FC = () => {
  const history = useHistory();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const deleteDisclosure = useDisclosure();
  const { error, success } = useNotification();
  const { firmId, isSuperAdmin } = useGetRole();
  const [createPermission, deletePermission, updatePermission] =
    useGetPermissions([
      'create:companies',
      'delete:companies',
      'edit:companies',
    ]);

  const pagination = usePagination({
    id: 'companies-table',
    order: 'desc',
  });
  const { filters, handleChangeFilters } = useFilters('companies-table', {
    defaultFilters: {
      firmId,
      companyName: null,
    },
    onFiltersChange: pagination.toFirstPage,
  });

  const debouncedCompanyName = useDebounce(filters.companyName, 150);
  localStorage.removeItem('duplicate-company-id');

  const { mutateAsync: exportCompanies, isPending: isExportingCompanies } =
    useExportCompanies();

  const {
    data: groupedCompanies,
    isFetching: isFetchingGroupedCompanies,
    refetch: refetchGroupedCompanies,
  } = useGroupedCompanies({
    //@ts-expect-error
    firmId: filters.firmId,
    page: pagination.page,
    perPage: pagination.rowsPerPage,
    type: pagination.order,
    //@ts-expect-error
    companyName: debouncedCompanyName,
  });

  const columns = generateMainTableColumns({
    classes,
    isSuperAdmin,
  });

  const collapsedTableColumns = generateCollapsedTableColumns({
    classes,
    updatePermission,
    deletePermission,
    history,
    deleteDisclosure,
  });

  const handleConfirmDelete = async () => {
    const companiesList = groupedCompanies?.result;
    const record = deleteDisclosure.actionProps;

    try {
      // @ts-expect-error dispatch to be removed soon
      const response = await dispatch<{
        status: number;
        data?: { responseMsg: string };
      }>(deleteCompany(record._id));
      if (response.status === 200) {
        success(response.data?.responseMsg ?? '');
        if (companiesList?.length === 1) {
          pagination.pageBack();
        } else {
          refetchGroupedCompanies();
        }
      }
    } catch (err: any) {
      error(err.response.data.responseMsg ?? '');
      refetchGroupedCompanies();
    }
    deleteDisclosure.onClose();
  };

  const exportExcelSheet = async () => {
    try {
      //@ts-expect-error
      await exportCompanies({
        ...filters,
        type: pagination.order,
      });
    } catch (e: any) {
      error(e.response?.data?.responseMsg || 'Failed to export companies');
    }
  };

  return (
    <PageContainer heading="Companies" breadcrumbs={breadcrumbs}>
      <PageContent>
        <PageFilters
          pageId="companies"
          buttons={[
            {
              link: COMPANY_ADD,
              label: 'Add Company',
              visible: createPermission,
            },
            {
              label: 'Export',
              onClick: exportExcelSheet,
              loading: isExportingCompanies,
              disabled: !groupedCompanies?.result?.length,
            },
          ]}
        >
          {isSuperAdmin && (
            <Filter>
              <FirmSelector
                firmId={filters.firmId}
                allowDeselection
                placeholder="Search by firm"
                deselectionLabel="All firms"
                onChange={(firmId) => {
                  handleChangeFilters({ firmId });
                }}
              />
            </Filter>
          )}
          <Filter>
            <TextField
              type="search"
              name="companyName"
              placeholder="Search by company name"
              fullWidth
              value={filters.companyName}
              onChange={(event) => {
                //@ts-expect-error
                handleChangeFilters({ companyName: event.target.value });
              }}
            />
          </Filter>
        </PageFilters>

        <Table
          columns={columns}
          onRequestSort={pagination.handleRequestSort}
          order={pagination.order}
          orderBy={pagination.orderBy}
          tablebody={groupedCompanies?.result ?? []}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          count={groupedCompanies?.total}
          pagination={true}
          loading={isFetchingGroupedCompanies}
          variant="nohover"
          collapsedContent={(row) => {
            return (
              <div
                className={`${classes.childRowContainer} ${classes.cmTableDarkHeading}`}
              >
                <Table
                  columns={collapsedTableColumns}
                  tablebody={row.investees || []}
                />
              </div>
            );
          }}
        />
        <AlertDialog
          open={deleteDisclosure.isOpen}
          onClose={deleteDisclosure.onClose}
          onConfirm={handleConfirmDelete}
          title={`Are you sure you wish to delete this company?`}
          content={
            <p>
              This cannot be undone and all information relating to the company
              will be lost.
            </p>
          }
          btnLabels={{
            cancel: 'Go back',
            confirm: 'Delete company',
          }}
        />
      </PageContent>
    </PageContainer>
  );
};

export default Company;
