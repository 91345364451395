import React, { useState, useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import PageContainer from 'components/PageContainer';
import ExitDetailsForm from './ExitDetailsForm';
import ReviewShareholdings from './ReviewShareholdings';
import ButtonsAndErrorModals from './ButtonsAndErrorModals';
import FullPageSuccess from 'components/FullPageSuccess';
import {
  EXITS,
  EXITS_EDIT,
  createExitStatementAddRoute,
} from 'adminConstants/routes';
import { downloadExits } from '@redux/actions/Exit';
import { useExit } from 'hooks/data/exit';
import EditExitDetailsForm from './EditExitDetailsForm';
import { useNotification } from 'hooks/ui/useNotification';
import { BottomTextContent } from './BottomTextContent';
import { format } from 'date-fns';
import PageContent from 'components/PageContent';
import RecordView from 'components/RecordView';
import Heading from 'components/Heading';

const useStyles = makeStyles()(() => ({
  header: {
    marginTop: '3rem',
  },
}));

const CreateExit: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const isBeingEdited = useRouteMatch(EXITS_EDIT)?.isExact;
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const [previousExitDetails, setPreviousExitDetails] = useState({});
  const [exitDetails, setExitDetails] = useState({});
  const [exitDetailsStepCompleted, setExitDetailsStepCompleted] =
    useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [shareholdings, setShareholdings] = useState([]);
  const [exitId, setExitId] = useState(id);
  const [amendmentId, setAmendmentId] = useState(null);
  const [disableTable, setDisableTable] = useState(false);
  const notification = useNotification();

  const [isDownloading, setIsDownloading] = useState(false);

  const { exit, create, edit } = useExit({ params: { id } });

  const handleDownload = async () => {
    setIsDownloading(true);
    //@ts-expect-error
    await dispatch(downloadExits(exitId, notification));
    setIsDownloading(false);
  };

  useEffect(() => {
    if (isBeingEdited && id && exit.data) {
      const {
        firmId,
        date,
        companyId,
        noSharesExited,
        sharePrice,
        taxReliefElegible,
        taxReliefElegibleAllocationIds,
        taxableValueSharePrice,
      } = exit.data || {};
      const details = {
        firmId: firmId._id,
        exitDate: new Date(date),
        selectedCompanyId: companyId?._id,
        selectedCompany: companyId,
        sharesToBeSold: Number(noSharesExited),
        sharePrice: Number(sharePrice),
        taxableValueSharePrice: Number(taxableValueSharePrice),
        taxReliefElegible,
        taxReliefElegibleAllocationIds,
      };
      setExitDetails({
        ...details,
        chargeAccruedFees: true,
      });
      setPreviousExitDetails({
        ...details,
        firmName: exit.data?.firmId?.firmName,
      });
    }
  }, [id, isBeingEdited, exit.data]);

  useEffect(() => {
    if (create.data || edit.data) {
      setAmendmentId(edit?.data?.exitAmendmentId);
      setIsSuccess(true);
    }

    if (create.data) {
      setExitId(create.data?._id);
    }
  }, [create.data, edit.data]);

  if (isSuccess) {
    return (
      <FullPageSuccess
        title="Your exit has been successfully processed"
        additionalText="Your investors will now be able to view this exit in their investor portals. Would you like to send an exit update?"
        proceedText="Send exit update"
        proceedLink={createExitStatementAddRoute(exitId, amendmentId)}
        secondaryText="Do this later"
        secondaryLink={EXITS}
        bottomText={
          <BottomTextContent
            disableButton={isDownloading}
            handleDownload={handleDownload}
          />
        }
      />
    );
  }

  const removeEmptyShareholdingRows = (sh) => {
    if (
      !sh.sharesAllocatedForSale &&
      !sh.accruedFeesCharged &&
      !sh.performanceFee &&
      !sh.totalSaleValue
    )
      return false;
    return true;
  };

  const onSubmit = async () => {
    const payload = {
      //@ts-expect-error
      firmId: exitDetails.firmId,
      //@ts-expect-error
      sharePrice: exitDetails.sharePrice,
      //@ts-expect-error
      taxableValueSharePrice: exitDetails.taxableValueSharePrice,
      //@ts-expect-error
      taxReliefElegible: exitDetails.taxReliefElegible,
      taxReliefElegibleAllocationIds:
        //@ts-expect-error
        exitDetails.taxReliefElegibleAllocationIds,
      shareholdingsToBeExited: shareholdings
        .filter(removeEmptyShareholdingRows)
        .map((sh) => ({
          //@ts-expect-error
          accruedFeesCharged: sh.accruedFeesCharged,
          //@ts-expect-error
          performanceFee: sh.performanceFee,
          //@ts-expect-error
          sharePrice: sh.sharePrice,
          //@ts-expect-error
          taxableValueSharePrice: sh.taxableValueSharePrice,
          //@ts-expect-error
          shareholdingId: sh.shareholdingId,
          //@ts-expect-error
          sharesAllocatedForSale: sh.sharesAllocatedForSale,
          //@ts-expect-error
          totalSaleValue: sh.totalSaleValue, // NB. this is the "received value" cell
          //@ts-expect-error
          taxableValue: sh.grossTaxableValue,
          ...(isBeingEdited
            ? {
                //@ts-expect-error
                additionalGrossReceivedValue: sh.additionalGrossReceivedValue,
              }
            : {}),
        })),
    };

    if (isBeingEdited) {
      //@ts-expect-error
      edit.mutate({
        ...payload,
        //@ts-expect-error
        exitAmendmentDate: format(exitDetails.exitDate, 'dd/MM/yyyy'),
        exitId,
      });
    } else {
      //@ts-expect-error
      create.mutate({
        ...payload,
        //@ts-expect-error
        exitDate: format(exitDetails.exitDate, 'dd/MM/yyyy'),
        //@ts-expect-error
        companyId: exitDetails.selectedCompanyId,
      });
    }
  };

  const onCancel = () => {
    setExitDetailsStepCompleted(false);
    if (isBeingEdited) {
      setExitDetails({
        ...previousExitDetails,
      });
    } else {
      setExitDetails({});
    }
    setShareholdings([]);
    setDisableTable(false);
  };

  const pageTitle = isBeingEdited
    ? 'Exits: Edit exit'
    : 'Exits: Process new exit';

  return (
    <PageContainer heading={pageTitle}>
      <PageContent header="Enter exit details">
        <RecordView>
          {isBeingEdited ? (
            <EditExitDetailsForm
              previousExitDetails={previousExitDetails}
              //@ts-expect-error
              exitDetails={exitDetails}
              onSubmit={(details) => {
                setExitDetails(details);
                setExitDetailsStepCompleted(true);
              }}
              exitDetailsStepCompleted={exitDetailsStepCompleted}
            />
          ) : (
            <ExitDetailsForm
              onSubmit={(details) => {
                setExitDetails(details);
                setExitDetailsStepCompleted(true);
              }}
              exitDetailsStepCompleted={exitDetailsStepCompleted}
            />
          )}
        </RecordView>

        {exitDetailsStepCompleted && (
          <>
            <Heading variant="h2" className={classes.header}>
              Review and edit exit results
            </Heading>

            <ReviewShareholdings
              exitDetails={exitDetails}
              previousExitDetails={previousExitDetails}
              shareholdings={shareholdings}
              setShareholdings={setShareholdings}
              disableTable={disableTable}
              isBeingEdited={isBeingEdited}
              exitId={exitId}
            />
            <ButtonsAndErrorModals
              exitDetails={exitDetails}
              shareholdings={shareholdings}
              setShareholdings={setShareholdings}
              onSubmit={onSubmit}
              onCancel={onCancel}
              isLoading={edit.isPending || create.isPending}
              disableTable={disableTable}
              onAmendmentsFileUpload={() => setDisableTable(true)}
              isBeingEdited={isBeingEdited}
            />
          </>
        )}
      </PageContent>
    </PageContainer>
  );
};

export default CreateExit;
