import { stages, sectors } from 'adminConstants/companyProperties';

const validateCompanyHouseLength = (value) => {
  if (!/^[a-zA-Z0-9]{8,12}$/.test(value)) {
    return 'Companies house number must be between 8 and 12 alphanumeric characters.';
  }
  return false;
};
export const runValidationRules = (
  isVCT,
  values,
  revaluationItems,
  newsItems,
  dividendItems,
  fundId,
) => {
  const revalutionError: Array<Record<string, string>> = [];
  const dividendsError: Array<Record<string, string>> = [];
  const newsError: Array<Record<string, string>> = [];
  const errors: Record<string, string> = {};
  let isError = false;
  if (!isVCT) {
    if (!values.tradingName) {
      errors.tradingName = 'Please enter Trading name.';
      isError = true;
    }
    if (!values.legalName) {
      errors.legalName = 'Please enter Full legal name.';
      isError = true;
    }
    if (!values.description) {
      errors.description = 'Please enter description.';
      isError = true;
    }
    if (!values.sector) {
      errors.sector = 'Please enter the sector the company operates in.';
      isError = true;
    }
    if (values.sector && !Object.values(sectors).includes(values.sector)) {
      errors.sector = 'Please enter a company sector from the predefined list.';
      isError = true;
    }
    if (!values.stage) {
      errors.stage = 'Please enter the investment stage of the company.';
      isError = true;
    }
    if (values.stage && !Object.values(stages).includes(values.stage)) {
      errors.stage =
        'Please enter an investment stage from the predefined list.';
      isError = true;
    }
  }
  if (values?.companiesHouseNumber) {
    const error = validateCompanyHouseLength(values?.companiesHouseNumber);
    if (error) {
      errors.companiesHouseNumber = error;

      isError = true;
    }
  }
  if (!values.firmId) {
    errors.firmId = 'Please select firm.';
    isError = true;
  }
  if (!fundId && isVCT) {
    errors.fundId = 'Please select tranche.';
    isError = true;
  }
  if (!values.initialPrice) {
    errors.initialPrice = 'Enter initial price.';
    isError = true;
  }
  if (!values.date) {
    errors.date = 'Please enter the date.';
    isError = true;
  }
  if (!values.shareClass) {
    errors.shareClass = 'Please enter share class.';
    isError = true;
  }
  if (revaluationItems) {
    revaluationItems.forEach((singleRec) => {
      const errors: Record<string, string> = {};
      if (!singleRec.amount && singleRec.amount !== 0) {
        errors.amount = 'Please enter amount.';
        isError = true;
      }
      if (!singleRec.date) {
        errors.date = 'Please enter the date.';
        isError = true;
      }
      revalutionError.push(errors);
    });
  }
  if (newsItems) {
    newsItems.forEach((singleRec) => {
      const errors: Record<string, string> = {};
      if (!singleRec.title) {
        errors.title = 'Please enter title.';
        isError = true;
      }
      if (!singleRec.date) {
        errors.date = 'Please enter the date.';
        isError = true;
      }
      if (!singleRec.url) {
        errors.url = 'Please enter url.';
        isError = true;
      }
      newsError.push(errors);
    });
  }
  dividendItems.forEach((singleRec) => {
    const errors: Record<string, string> = {};
    if (!singleRec.amount) {
      errors.amount = 'Please enter amount.';
      isError = true;
    }
    if (!singleRec.date) {
      errors.date = 'Please enter the date.';
      isError = true;
    }
    dividendsError.push(errors);
  });

  return {
    dividendsError,
    revalutionError,
    newsError,
    errors,
    isError,
  };
};
