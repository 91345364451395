import Grid from '@mui/material/Grid2';
import { makeStyles } from 'tss-react/mui';
import GridContainer from 'components/GridContainer';
import Box from 'components/Box';
import { numberToCurrencyString } from 'further-ui/utils';
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles()({
  gridCard: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    marginBottom: 20,
    marginTop: 20,
  },
  gridItem: {
    width: '100%',
  },
  summaryBox: {
    justifyContent: 'flex-start',
    minHeight: '100px',
    padding: '0 16px',
    textAlign: 'left',

    '& > div': {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'flex-start',
    },

    '& h5': {
      fontSize: 18,
      fontWeight: 'bold',
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },

    '& p': {
      fontSize: 15,
      marginBottom: 4,
    },
  },
  tooltipIcon: {
    fontSize: 15,
    cursor: 'pointer',
    transform: 'translateY(3px)',
    marginLeft: 3,
  },
});

const TotalFeeSummary = ({ summaryTotal }) => {
  const { classes } = useStyles();
  const ToolTipContent = () => (
    <Tooltip title="Excluding VAT">
      <InfoOutlined className={classes.tooltipIcon} />
    </Tooltip>
  );
  return (
    <GridContainer className={classes.gridCard} spacing={3}>
      <Grid className={classes.gridItem}>
        <Box
          title={numberToCurrencyString(summaryTotal?.initial)}
          subtitle="Upfront fees"
          classes={classes.summaryBox}
        >
          <ToolTipContent />
        </Box>
      </Grid>
      <Grid className={classes.gridItem}>
        <Box
          title={numberToCurrencyString(summaryTotal?.recurring)}
          subtitle="Annual fees"
          classes={classes.summaryBox}
        >
          <ToolTipContent />
        </Box>
      </Grid>
      <Grid className={classes.gridItem}>
        <Box
          title={numberToCurrencyString(summaryTotal?.other)}
          subtitle="Other fees"
          classes={classes.summaryBox}
        >
          <ToolTipContent />
        </Box>
      </Grid>
      <Grid className={classes.gridItem}>
        <Box
          title={numberToCurrencyString(summaryTotal?.performance)}
          subtitle="Performance fees"
          classes={classes.summaryBox}
        >
          <ToolTipContent />
        </Box>
      </Grid>
      <Grid className={classes.gridItem}>
        <Box
          title={numberToCurrencyString(summaryTotal?.total)}
          subtitle="Total fees"
          classes={classes.summaryBox}
        >
          <ToolTipContent />
        </Box>
      </Grid>
    </GridContainer>
  );
};

export default TotalFeeSummary;
