import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/admin-user';
import { api } from 'lib/httpClient';
import { useNotification } from 'hooks/ui/useNotification';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { createEditUserRoute } from 'adminConstants/routes';
import { useHistory } from 'react-router-dom';

export function useMutateAdminUser(adminUserId?: string) {
  const queryClient = useQueryClient();
  const notification = useNotification();
  const history = useHistory();
  const handleApiException = useApiExceptionHandler();

  const create = useMutation({
    mutationFn: async (body: Api.CreateAdminUserRequestBody) => {
      const { data } = await api.post<
        ApiResponse<Api.CreateAdminUserResponseBody>
      >(`admin-user/create`, body);
      return data.data;
    },
    onSuccess: async (data) => {
      notification.success('User added successfully');
      history.push(createEditUserRoute(data._id));
      await queryClient.invalidateQueries({ queryKey: ['admin-users'] });
    },
    onError: (error: Error) => handleApiException(error),
  });

  const edit = useMutation({
    mutationFn: async (body: Api.UpdateAdminUserRequestBody) => {
      await api.put<ApiResponse<any>>(`admin-user/update/${adminUserId}`, body);
    },
    onSuccess: async () => {
      notification.success('User updated successfully');
      await queryClient.invalidateQueries({
        queryKey: ['admin-user', adminUserId],
      });
      await queryClient.invalidateQueries({ queryKey: ['admin-users'] });
    },
    onError: (error: Error) => handleApiException(error),
  });

  const remove = useMutation({
    mutationFn: async (id: string) => {
      await api.delete<ApiResponse<any>>(`admin-user/delete/${id}`);
    },
    onSuccess: async () => {
      notification.success('User deleted');
      await queryClient.invalidateQueries({ queryKey: ['admin-users'] });
    },
    onError: (error: Error) => handleApiException(error),
  });

  return {
    create,
    edit,
    remove,
  };
}

export function useGetAdminUser(adminUserId?: string) {
  return useQuery({
    queryKey: ['admin-user', adminUserId],
    queryFn: async () => {
      const { data } = await api.get<ApiResponse<Api.GetUserResponseBody>>(
        `admin-user/list/${adminUserId}`,
      );
      return data.data;
    },
    enabled: !!adminUserId,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
  });
}
