import { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Table from 'components/Table';
import PageContainer from 'components/PageContainer';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';

import { getPartiallyInvestment } from '@redux/actions/Investment';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useFunds } from 'hooks/data/fund/useFunds';
import { usePagination } from 'hooks/ui/usePagination';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import useFilters from 'hooks/ui/useFilters';
import PageContent from 'components/PageContent';
import PageFilters, { Filter } from 'components/PageFilters';

const exitedAtToLabel = (cart: { step?: number; adviserId?: string }) => {
  const { step, adviserId } = cart;

  const steps = {
    1: 'Appropriateness (1/5)',
    2: 'KYC (2/5)',
    3: 'Bank and tax details (3/5)',
    4: 'Subscription selection (4/5)',
    5: 'Manual payment (5/5)',
    6: 'Open Banking (5/5)',
  };

  return step ? steps[step] ?? '' : adviserId ? 'Invited, no action taken' : '';
};

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Incomplete Subscriptions', isActive: true },
];
// sorting not working
const columns = [
  {
    label: 'Investor name',
    key: 'fullName',
  },
  {
    label: 'Email',
    key: 'email',
    render: (elm) => elm?.investorId?.email,
  },
  {
    label: 'Firm',
    key: 'firmId',
    render: (elm) => elm?.firmId?.firmName,
  },
  {
    label: 'Tranche',
    key: 'fundId',
    render: (elm) => elm?.fundId?.fundName,
  },
  {
    label: 'Date',
    key: 'createdAt',
    render: (elm) => dateToLabel(elm?.createdAt),
  },
  {
    label: 'Exited',
    key: 'step',
    render: (elm) => exitedAtToLabel(elm),
  },
  {
    label: 'Advised',
    key: 'adviserId',
    render: (elm) => (elm?.adviserId ? 'Yes' : 'No'),
  },
  {
    label: 'Subscription',
    key: 'investmentAmount',
    render: (elm) => numberToCurrencyString(elm?.investmentAmount),
  },
];

const useFundId = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search).get('fundId'), [search]);
};

const IncompleteInvestments: React.FC = () => {
  const dispatch = useDispatch();
  const handleApiRequest = useApiRequestHandler();
  const fundId = useFundId();
  const [partiallyInvestment, setPartiallyInvestment] = useState({
    result: [],
    totalInvestment: null,
    total: 0,
  });
  const { firmId } = useGetRole();
  const {
    tranches: { data: tranchesList },
  } = useFunds({ firmId });

  const [loading, setLoading] = useState(true);

  const {
    orderBy,
    order,
    page,
    rowsPerPage,
    toFirstPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination({
    id: 'incomplete-investments-table',
    orderBy: 'createdAt',
    order: 'desc',
  });

  const { filters, handleChangeFilters } = useFilters(
    'incomplete-investments-table',
    {
      defaultFilters: {
        firmId,
        fundId,
        investorName: null,
      },
      onFiltersChange: toFirstPage,
    },
  );

  useEffect(() => {
    const getList = async () => {
      const response = await dispatch(
        // @ts-expect-error dispatch to be removed soon
        getPartiallyInvestment({
          page,
          firmId: filters.firmId,
          fundId: filters.fundId,
          perPage: rowsPerPage,
          type: order,
          keyName: orderBy,
          investorName: filters.investorName,
        }),
      );
      if (response) {
        //@ts-expect-error
        setPartiallyInvestment(response);
      }
      setLoading(false);
    };
    handleApiRequest(getList);
  }, [dispatch, page, rowsPerPage, order, orderBy, filters]);

  const exportExcelSheet = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Incomplete subscriptions');
    worksheet.columns = [
      { header: 'Investor name', key: 'fullName' },
      { header: 'Email', key: 'email' },
      { header: 'Firm', key: 'firmName' },
      { header: 'Tranche', key: 'fundName' },
      { header: 'Total Subscription Amount', key: 'investmentAmount' },
      { header: 'Exited', key: 'step' },
      { header: 'Subscription Date', key: 'InvestmentDate' },
    ];
    //@ts-expect-error
    partiallyInvestment?.totalInvestment?.forEach((item) => {
      worksheet.addRow({
        fullName: item?.fullName,
        email: item.investorId?.email,
        firmName: item.firmId?.firmName,
        fundName: item?.fundId?.fundName,
        investmentAmount: numberToCurrencyString(item.investmentAmount),
        step: exitedAtToLabel(item),
        InvestmentDate: dateToLabel(item.createdAt),
      });
    });
    //@ts-expect-error
    workbook.xlsx.writeBuffer().then((buffer: Buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'Incomplete investments.xlsx',
      );
    });
  };

  return (
    <PageContainer heading="Incomplete Subscriptions" breadcrumbs={breadcrumbs}>
      <PageContent>
        <PageFilters
          pageId="incomplete-investments-table"
          buttons={[
            {
              //@ts-expect-error
              disabled: !partiallyInvestment?.totalInvestment?.length,
              label: 'Export',
              onClick: exportExcelSheet,
            },
          ]}
        >
          <Filter>
            <TextField
              type="search"
              name="searchInvestorsByName"
              value={filters.investorName}
              placeholder="Search by investor name"
              onChange={(event) => {
                handleChangeFilters({
                  //@ts-expect-error
                  investorName: event.target.value,
                });
              }}
            />
          </Filter>
          <Filter>
            <Autocomplete
              id="fundId"
              value={tranchesList?.find(({ _id }) => _id === filters.fundId)}
              options={tranchesList ?? []}
              getOptionLabel={(option) =>
                `${option?.firmId?.firmName} ${option?.fundName}`
              }
              onChange={(_, newValue) => {
                handleChangeFilters({ fundId: newValue?._id });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select tranche"
                />
              )}
            />
          </Filter>
        </PageFilters>
        <Table
          columns={columns}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          tablebody={partiallyInvestment?.result}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          count={partiallyInvestment?.total ?? 0}
          pagination={true}
          loading={loading}
          variant="nohover"
        />
      </PageContent>
    </PageContainer>
  );
};

export default IncompleteInvestments;
