import React from 'react';
import { Breadcrumbs } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { startCase } from 'lodash';

const useStyles = makeStyles()(() => ({
  textSm: {
    fontSize: 12,
  },
  linkBlock: {
    display: 'block',
    color: 'inherit',
  },
  cursor: {
    cursor: 'pointer',
  },
  textColor: {
    color: '#828885',
  },
}));

const formatLabel = (input: string, withoutFormatting?: boolean) => {
  if (input.includes('/')) {
    return input
      .split('/')
      .map((segment) => (withoutFormatting ? segment : startCase(segment)))
      .join('/');
  } else {
    return withoutFormatting ? input : startCase(input);
  }
};

export type BreadcrumbItem = {
  label: string;
  onClick?: () => void;
  isActive?: boolean;
  withoutFormatting?: boolean;
  link?: string;
};

type Props = {
  items: Array<BreadcrumbItem>;
};

const PageBreadcrumbs: React.FC<Props> = ({ items }) => {
  const { classes, cx } = useStyles();
  return (
    <Breadcrumbs className="bread-crumbs" aria-label="breadcrumb">
      {items.map((item, index) =>
        item.onClick ? (
          <div
            key={index}
            onClick={item.onClick}
            className={classes.cursor}
            role="button"
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                item.onClick?.();
              }
            }}
          >
            <Typography
              className={cx(
                classes.textSm,
                !item.isActive && classes.textColor,
              )}
              color="textPrimary"
            >
              {formatLabel(item.label, item.withoutFormatting)}
            </Typography>
          </div>
        ) : item.isActive ? (
          <Typography
            key={index}
            className={classes.textSm}
            color="textPrimary"
          >
            {formatLabel(item.label, item.withoutFormatting)}
          </Typography>
        ) : (
          <NavLink
            key={index}
            className={cx(classes.textSm, classes.linkBlock)}
            color="inherit"
            to={item.link || '/'}
          >
            {formatLabel(item.label, item.withoutFormatting)}
          </NavLink>
        ),
      )}
    </Breadcrumbs>
  );
};

export default PageBreadcrumbs;
