import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageContainer from 'components/PageContainer';
import Summary from '../Common/Summary';
import { INVESTOR_REPORTING } from 'adminConstants/routes';
import { getDeploymentUpdate } from '@redux/actions/DeploymentUpdate';
import PageContent from 'components/PageContent';

const ViewCustomUpdate: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [deploymentUpdate, setDeploymentUpdate] = useState();
  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    {
      label: 'Investor Reporting',
      link: INVESTOR_REPORTING,
    },
  ];
  const heading = 'Investor reporting: View deployment update';
  const fetchDeploymentUpdate = async () => {
    const response = await dispatch<any>(getDeploymentUpdate(id));
    setDeploymentUpdate(response);
  };

  useEffect(() => {
    fetchDeploymentUpdate();
  }, [id]);

  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <PageContent header="Deployment update summary">
        <Summary
          data={deploymentUpdate}
          //@ts-expect-error
          company={deploymentUpdate?.companyId}
        />
      </PageContent>
    </PageContainer>
  );
};

export default ViewCustomUpdate;
