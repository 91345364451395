// TODO: Refactor this file as events are from old screen
import { Button, Typography, Avatar } from '@mui/material';
import React, { useMemo } from 'react';
import Table, { TableActions } from 'components/Table';
import Dialog from 'components/Dialog';
import AddPastInvestment from './AddPastInvestment';
import useFileUpload from 'hooks/ui/useFileUpload';
import { useDispatch } from 'react-redux';
import { Delete, Edit } from '@mui/icons-material';
import {
  createPortfolio,
  updatePortfolio,
  deletePortfolio,
} from '@redux/actions/InvestmentFirm';
import { deleteNews } from '@redux/actions/Company';
import AlertDialog from 'components/AlertDialog';
import { useNotification } from 'hooks/ui/useNotification';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { UploadType } from 'further-types/files';
import useCommonStyles from '../commonStyles';
import Spacing from 'components/Spacing';

type Props = any;

const FundPageInformation: React.FC<Props> = ({
  firmId,
  portfolioData,
  isCreatePage,
}) => {
  const { classes: commonClasses } = useCommonStyles();
  const { uploadFile } = useFileUpload();
  const dispatch = useDispatch();
  const [tableData, setTableData] = React.useState(portfolioData);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [pastInvestmentId, setPastInvestmentId] = React.useState();
  const [btnLabels] = React.useState({ cancel: 'Cancel', confirm: 'Save' });
  const [record, setRecord] = React.useState();
  const { error, success } = useNotification();
  const handleApiRequest = useApiRequestHandler();
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
    React.useState(false);
  const [currentValue, setCurrentValue] = React.useState({
    id: undefined,
    title: '',
    currentPortfolioDescription: '',
    url: '',
    twitterUrl: '',
    linkedinUrl: '',
    isExited: false,
    order: '',
    firmPortfolioLogo: null,
    firmPortfolioLogoUrl: null,
    previewFirmPortfolioLogo: null,
    news: [],
  });

  const handleAddMore = (rec) => {
    setCurrentValue({
      id: undefined,
      title: firmId ? rec?.title : '',
      currentPortfolioDescription: firmId
        ? rec?.currentPortfolioDescription
        : '',
      url: firmId ? rec?.title : '',
      twitterUrl: firmId ? rec?.twitterUrl : '',
      linkedinUrl: firmId ? rec?.linkedinUrl : '',
      isExited: firmId ? rec?.isExited : false,
      order: firmId ? rec?.order : '',
      firmPortfolioLogo: firmId ? rec?.firmPortfolioLogo : null,
      firmPortfolioLogoUrl: firmId ? rec?.firmPortfolioLogoUrl : '',
      previewFirmPortfolioLogo: firmId ? rec?.firmPortfolioLogo : null,
      news: firmId && rec?.news ? rec?.news : [],
    });
    setPastInvestmentId(rec?._id);
    setDialogOpen(!dialogOpen);
  };

  const onClickClose = () => {
    setCurrentValue({
      id: undefined,
      title: '',
      currentPortfolioDescription: '',
      url: '',
      twitterUrl: '',
      linkedinUrl: '',
      isExited: false,
      order: '',
      firmPortfolioLogo: null,
      firmPortfolioLogoUrl: null,
      previewFirmPortfolioLogo: null,
      news: [],
    });
    setDialogOpen(false);
  };

  const handleConfirmPortfolio = async () => {
    if (!firmId) {
      const data = [...tableData, currentValue];
      setTableData(data);
    } else {
      if (pastInvestmentId) {
        currentValue.id = pastInvestmentId;
        //@ts-expect-error
        if (currentValue?.firmPortfolioLogo?.includes('.com/')) {
          currentValue.firmPortfolioLogo =
            //@ts-expect-error
            currentValue?.firmPortfolioLogo.split('.com/')[1];
        }
        await handleApiRequest(async (showSuccess) => {
          const updateResponse = await dispatch<any>(
            updatePortfolio(currentValue),
          );
          if (updateResponse) {
            showSuccess(updateResponse.responseMsg);
          }
          tableData?.forEach((singleCurrent, index) => {
            if (singleCurrent?._id === updateResponse?.data._id) {
              tableData[index] = updateResponse.data;
            }
          });
          setTableData([...tableData]);
        });
      } else {
        //@ts-expect-error
        currentValue.firmId = firmId;
        await handleApiRequest(async (showSuccess) => {
          const createResponse = await dispatch<any>(
            createPortfolio(currentValue),
          );
          if (createResponse) {
            showSuccess(createResponse.responseMsg);
          }
          setTableData([...tableData, createResponse.data]);
        });
      }
    }
    setDialogOpen(false);
  };

  const handleAddNews = () => {
    //@ts-expect-error
    currentValue.news.push({ title: '', url: '', date: null });
    setCurrentValue({ ...currentValue });
  };

  const handleRemoveNews = (index) => {
    const selectedNews = currentValue?.news?.[index];
    //@ts-expect-error
    if (selectedNews?._id) {
      //@ts-expect-error
      dispatch<any>(deleteNews(selectedNews?._id))
        .then((response) => {
          if (response.status === 200) {
            success(response.data.responseMsg);
            // eslint-disable-next-line no-unused-expressions
            currentValue?.news?.splice(index, 1);
          }
        })
        .catch((err) => error(err.response.data.responseMsg));
    } else {
      // eslint-disable-next-line no-unused-expressions
      currentValue?.news?.splice(index, 1);
    }
    setCurrentValue({ ...currentValue });
  };

  const handleInputChangeNews = (e, index, isDate) => {
    if (isDate) {
      //@ts-expect-error
      currentValue.news[index]['date'] = e;
    } else {
      const { name, value } = e.target;
      //@ts-expect-error
      currentValue.news[index][name] = value;
    }
    setCurrentValue({ ...currentValue });
  };

  const handleDeletePortFolio = () => {
    currentValue.firmPortfolioLogo = null;
    setCurrentValue({ ...currentValue });
  };

  const handleChangePortFolioLogo = async (files) => {
    if (!files?.[0]?.path) return;

    try {
      const { url, filePath } = await uploadFile(
        files[0],
        UploadType.FirmLogos,
      );

      setCurrentValue({
        ...currentValue,
        //@ts-expect-error
        firmPortfolioLogo: filePath,
        //@ts-expect-error
        firmPortfolioLogoUrl: url,
        previewFirmPortfolioLogo: files[0].preview,
      });
    } catch (_) {
      error("Something went wrong - the file couldn't be uploaded");
    }
  };

  const handleDelete = (rec) => {
    setRecord(rec);
    setOpenConfirmDeleteDialog(!openConfirmDeleteDialog);
  };

  const handleConfirmDeletePortfolio = () => {
    let filter;
    if (firmId) {
      handleApiRequest(async (showSuccess) => {
        //@ts-expect-error
        const response = await dispatch<any>(deletePortfolio(record?._id));
        showSuccess(response.data.responseMsg);
        filter = tableData?.filter(function (value) {
          //@ts-expect-error
          return value?._id !== record?._id;
        });
        setTableData(filter);
      });
    } else {
      filter = tableData?.filter(function (name) {
        //@ts-expect-error
        return name?.title !== record?.title;
      });
      setTableData(filter);
    }
    setOpenConfirmDeleteDialog(!openConfirmDeleteDialog);
  };

  const columns = useMemo(
    () => [
      {
        label: 'Company logo',
        key: 'firmPortfolioLogo',
        render: (row) => {
          return (
            <div>
              <Avatar
                src={
                  firmId && row._id
                    ? row.firmPortfolioLogo
                    : row.firmPortfolioLogoUrl
                }
              />
              <div>{row.firmName}</div>
            </div>
          );
        },
      },
      {
        label: 'Title',
        key: 'title',
      },
      {
        label: 'Company description',
        key: 'currentPortfolioDescription',
        ellipsis: true,
      },
      {
        label: 'Order',
        key: 'order',
      },
      {
        label: 'Actions',
        key: '',
        render: (elm) => (
          <TableActions
            showAsDropdown
            actions={[
              {
                visible: !!firmId,
                label: 'Edit',
                icon: Edit,
                onClick: () => handleAddMore(elm),
                color: 'primary',
              },
              {
                label: 'Delete',
                icon: Delete,
                onClick: () => handleDelete(elm),
                color: 'error',
              },
            ]}
          />
        ),
      },
    ],
    [firmId],
  );

  return (
    <Spacing>
      {!isCreatePage && (
        <div className={commonClasses.container}>
          <Typography className={commonClasses.bodyCopy}>
            Add the portfolio companies you wish to display on your fund page
            here. You may add as many past or present portfolio companies as you
            like. Please add at least 3 companies and ideally 6 or more. VCTs
            must add all portfolio companies.
          </Typography>
          <Table
            pagination={false}
            tablebody={tableData}
            columns={columns}
            order={'asc'}
            orderBy={'name'}
            page={1}
            rowsPerPage={1}
            variant="nohover"
          />
          <div className={commonClasses.buttonContainer}>
            <Button
              className={commonClasses.addMoreButton}
              onClick={() => setDialogOpen(true)}
              variant="text"
              color="primary"
              size="small"
            >
              Add more
            </Button>
          </div>
        </div>
      )}

      <Dialog
        open={dialogOpen}
        title={
          pastInvestmentId
            ? `Update Past Subscription`
            : `Add Past Subscriptions`
        }
        onConfirm={handleConfirmPortfolio}
        onClose={onClickClose}
        btnLabels={btnLabels}
      >
        <AddPastInvestment
          setCurrentValue={setCurrentValue}
          currentValue={currentValue}
          handleDeletePortFolio={handleDeletePortFolio}
          handleChangePortFolioLogo={handleChangePortFolioLogo}
          handleInputChangeNews={handleInputChangeNews}
          handleRemoveNews={handleRemoveNews}
          handleAddNews={handleAddNews}
        />
      </Dialog>
      <AlertDialog
        open={openConfirmDeleteDialog}
        onClose={handleDelete}
        onConfirm={handleConfirmDeletePortfolio}
        title={`Are you sure you wish to delete this past subscription?`}
        content={
          <p>
            This cannot be undone and all information relating to the past
            subscription will be lost.
          </p>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Delete past subscription',
        }}
      />
    </Spacing>
  );
};

export default FundPageInformation;
