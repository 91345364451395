import React, { forwardRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { styled } from '@mui/material/styles';

const SwatchContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Swatch = styled('button')<{ colour?: string }>(({ colour }) => ({
  height: 22,
  width: 22,
  padding: 2,
  borderRadius: '11px',
  boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
  background: colour || 'transparent',
  cursor: 'pointer',
  border: 0,
}));

const SwatchColour = styled('span')<{ colour?: string }>(({ colour }) => ({
  background: colour || 'transparent',
  display: 'block',
  width: '100%',
  height: '100%',
  borderRadius: '100%',
}));

const Popover = styled('div')({
  position: 'absolute',
  marginTop: 10,
  zIndex: 2,
});

const Cover = styled('div')({
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
});

const ColourValue = styled('span')({
  fontSize: 14,
  fontFamily: 'MaisonNeue',
  textTransform: 'uppercase',
  marginLeft: 8,
});

type Props = any; // TODO: define types

const ColourPicker: React.FC<Props> = forwardRef(({ value, ...props }, ref) => {
  const [pickerShown, setPickerShown] = useState(false);

  const handleClick = () => {
    setPickerShown(!pickerShown);
  };

  const handleChange = (colour) => {
    props.onChange(colour.hex);
  };

  const handleClose = () => {
    setPickerShown(false);
  };

  return (
    <>
      <SwatchContainer>
        <Swatch onClick={handleClick} type="button" colour={value}>
          <SwatchColour colour={value} />
        </Swatch>
        <ColourValue>{value}</ColourValue>
      </SwatchContainer>
      {pickerShown ? (
        <Popover>
          <Cover onClick={handleClose} />
          <SketchPicker ref={ref} color={value} onChange={handleChange} />
        </Popover>
      ) : null}
    </>
  );
});

export default ColourPicker;
