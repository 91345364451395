import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = {
  centered?: boolean;
  spacing?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
};

const spacingMap = {
  xs: 2,
  sm: 4,
  md: 6,
  lg: 8,
  xl: 10,
};

const StyledColumn = styled('div')(({ theme, centered, spacing }) => ({
  display: 'flex',
  alignItems: centered ? 'center' : undefined,
  gap: theme.spacing(spacing),
  flexDirection: 'column',
}));

const Column: React.FC<PropsWithChildren<Props>> = ({
  children,
  spacing,
  className,
  ...props
}) => {
  return (
    <StyledColumn
      className={className}
      spacing={spacing ? spacingMap[spacing] : 0}
      {...props}
    >
      {children}
    </StyledColumn>
  );
};

export default Column;
