import { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useDebounce } from 'usehooks-ts';
import AlertDialog from 'components/AlertDialog';
import Table from 'components/Table';
import PageContainer from 'components/PageContainer';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { Api, Status } from 'further-types/eis-wizard';
import { useDisclosure } from 'further-ui/hooks';
import TaxReliefElegibilitySwitch from './TaxReliefElegibilitySwitch';
import { EIS_ADD } from 'adminConstants/routes';
import { usePagination } from 'hooks/ui/usePagination';
import useFilters from 'hooks/ui/useFilters';
import { useGetRole } from 'hooks/ui/useGetRole';
import ProcessCompletedChip from './ProcessCompletedChip';
import ListCertificateFilters from './ListCertificateFilters';
import RowActions from './RowActions';
import exportEisWizardTasklist from 'helpers/eisWizard/exportEisWizardTasklist';
import { InfoOutlined } from '@mui/icons-material';
import useEisTasklist from 'hooks/data/eisWizard/useEisTasklist';
import useKiFundCertificateBatch from 'hooks/data/kiFundCertificate/useKiFundCertificateBatch';
import { useEisWizard } from 'hooks/data/eisWizard/useEisWizard';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import PageContent from 'components/PageContent';
import PageFilters from 'components/PageFilters';

const eisTooltipText =
  'Please toggle here if a subscription loses its tax relief status during its hold period (e.g. the business starts a non-EIS qualifying trade). Do not toggle S/EIS eligibility here if the subscription has lost tax relief as result of an early exit. Tax status on exit is selected in the Exits function, at the point of processing an exit.';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'S/EIS Tasklist', link: '/eiswizard' },
];

type TaskListItem = Api.GetEisWizardTaskListResponse['results'][number];

const EisWizard: React.FC = () => {
  const handleApiException = useApiExceptionHandler();
  const deleteDisclosure = useDisclosure<{
    eisWizard?: { id: string };
    kiFundCertificatesBatchId?: string;
  }>();
  const { firmId: usersFirmId, isSuperAdmin } = useGetRole();

  const pagination = usePagination({
    orderBy: 'completionDate',
    order: 'desc',
    id: 'certificates-list',
  });

  const { filters, handleChangeFilters } = useFilters('certificates-list', {
    onFiltersChange: pagination.toFirstPage,
    defaultFilters: {
      firmId: usersFirmId,
      search: '',
      status: null,
    },
  });

  const [search, setSearch] = useState(filters.search);
  const debouncedSearch = useDebounce(search, 300);
  useEffect(() => {
    handleChangeFilters({ search: debouncedSearch });
  }, [debouncedSearch]);

  const mapUiStatusToApiStatus = {
    all: '',
    [Status.Complete]: Status.Complete,
    [Status.NotComplete]: Status.NotComplete,
    [Status.NA]: Status.NA,
  };

  const {
    tasklist: { data, isFetching },
    exportTasklist,
  } = useEisTasklist({
    rowsPerPage: pagination.rowsPerPage,
    page: pagination.page,
    order: pagination.order,
    orderBy: pagination.orderBy,
    firmId: filters.firmId,
    search: debouncedSearch,
    //@ts-expect-error
    status: mapUiStatusToApiStatus[filters.status],
  });
  const { deleteCertificateBatch } = useKiFundCertificateBatch();
  const { deleteEisWizard } = useEisWizard();

  const columns = [
    {
      label: 'Company Name',
      key: 'companyName',
    },
    {
      label: 'Share class',
      key: 'shareClass',
    },
    {
      label: 'Firm',
      key: 'firmName',
    },
    {
      label: 'Allocation date',
      key: 'allocationDate',
      render: (elm: TaskListItem) => dateToLabel(elm?.allocationDate),
    },
    {
      label: 'Tranche(s)',
      key: 'fundNames',
      sort: false,
      render: (elm: TaskListItem) => {
        if (elm.fundNames.length <= 2) {
          return elm.fundNames.join(', ');
        } else {
          return `${elm.fundNames.slice(0, 2).join(', ')} and ${
            elm.fundNames.length - 2
          } more`;
        }
      },
    },
    {
      label: 'Funds allocated',
      key: 'subscriptionAmount',
      sort: false,
      render: (elm: TaskListItem) =>
        numberToCurrencyString(elm.subscriptionAmount),
    },
    {
      label: 'Process completed?',
      key: 'eisWizard._id',
      render: (elm: TaskListItem) => (
        <ProcessCompletedChip tasklistItem={elm} />
      ),
    },
    {
      label: 'Date of completion',
      key: 'completionDate',
      render: (elm: TaskListItem) =>
        elm.completionDate ? dateToLabel(elm.completionDate, '-') : 'N/A',
    },
    {
      label: (
        <Box display="flex" alignItems="center" gap="4px">
          <span>S/EIS eligible?</span>
          <Tooltip title={eisTooltipText}>
            <IconButton size="small">
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      key: 'taxReliefElegible',
      render: (elm: TaskListItem) =>
        elm.shareAllocationUploadId ? (
          <TaxReliefElegibilitySwitch
            value={elm.taxReliefElegible}
            hasCertificate={
              !!elm.eisWizard?.id && !elm.createdWithoutCertificates
            }
            shareAllocationUploadId={elm.shareAllocationUploadId}
          />
        ) : (
          <>-</>
        ),
    },
    {
      label: 'Actions',
      key: '',
      sort: false,
      render: (elm: TaskListItem) => (
        <RowActions deleteDisclosure={deleteDisclosure} tasklistItem={elm} />
      ),
    },
  ];

  const handleConfirmDelete = async () => {
    if (deleteDisclosure.actionProps?.eisWizard?.id) {
      deleteEisWizard.mutate(deleteDisclosure.actionProps.eisWizard.id);
    } else if (deleteDisclosure.actionProps?.kiFundCertificatesBatchId) {
      deleteCertificateBatch.mutate(
        deleteDisclosure.actionProps.kiFundCertificatesBatchId,
      );
    }
    deleteDisclosure.onClose();
  };

  const handleExport = async () => {
    try {
      const exportData = await exportTasklist.refetch();
      exportEisWizardTasklist(exportData.data?.results ?? []);
    } catch (err: any) {
      handleApiException(err);
    }
  };

  return (
    <PageContainer heading="S/EIS Tasklist" breadcrumbs={breadcrumbs}>
      <PageContent>
        <PageFilters
          pageId="certificates-list"
          buttons={[
            {
              link: EIS_ADD,
              label: 'Create S/EIS Certificates',
            },
            {
              label: 'Export',
              onClick: handleExport,
              loading: exportTasklist.isFetching,
              disabled: isFetching,
            },
          ]}
        >
          <ListCertificateFilters
            filters={filters}
            handleChangeFilters={handleChangeFilters}
            search={search}
            setSearch={setSearch}
          />
        </PageFilters>

        <Table
          columns={
            isSuperAdmin
              ? columns
              : columns.filter((column) => column.key !== 'firmName')
          }
          onRequestSort={pagination.handleRequestSort}
          order={pagination.order}
          orderBy={pagination.orderBy}
          tablebody={data?.results || []}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          pagination={true}
          count={data?.total || 0}
          loading={isFetching}
          variant="nohover"
        />
      </PageContent>
      <AlertDialog
        open={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
        onConfirm={handleConfirmDelete}
        title="Are you sure you wish to delete these S/EIS Certificates?"
        content={
          <p>
            This cannot be undone and all information relating to the S/EIS
            certificates will be lost.
          </p>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Delete certificates',
        }}
      />
    </PageContainer>
  );
};

export default EisWizard;
