import React from 'react';
import { Button, styled } from '@mui/material';

const Icon = styled('img')({
  maxWidth: 24,
});

interface SocialMediaLinkProps {
  url: string;
  iconSrc: string;
  altText: string;
}

export const SocialMediaLink: React.FC<SocialMediaLinkProps> = ({
  url,
  iconSrc,
  altText,
}) => {
  if (!url) return null;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Button color="primary">
        <Icon src={iconSrc} alt={altText} />
      </Button>
    </a>
  );
};

export default SocialMediaLink;
