import React from 'react';
import { Divider, CardContent, Card, Avatar, styled } from '@mui/material';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { makeStyles } from 'tss-react/mui';
import { usePagination } from 'hooks/ui/usePagination';
import Heading from 'components/Heading';

const useStyles = makeStyles()(() => ({
  dcont: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.75rem',
  },
}));

const CompanyLogo = styled(Avatar)({
  width: '30px',
  height: '30px',
});

type Props = {
  rows: Array<any>;
  trancheId: string;
  trancheName: string;
  parentClasses: Record<string, string>;
};

export const TrancheCompaniesTable: React.FC<Props> = ({
  rows,
  trancheId,
  trancheName,
  parentClasses,
}) => {
  const { classes } = useStyles();
  const pagination = usePagination();

  const columns = [
    {
      label: 'Company',
      key: 'tradingName',
      render: (elm) => (
        <div className={classes.dcont}>
          <CompanyLogo src={elm?.companyLogo} />
          {elm?.tradingName}
        </div>
      ),
    },
    {
      label: 'Subscription date',
      key: 'investmentDate',
      render: (elm) => dateToLabel(elm?.investmentDate),
    },
    {
      label: 'Subscription amount',
      key: 'sumSubscriptionAmount',
      render: (elm) =>
        numberToCurrencyString(elm?.sumSubscriptionAmount, {
          hidePenniesIfInteger: true,
        }),
    },
  ];

  return (
    <Card className={parentClasses.flexGrow}>
      <Divider className={parentClasses.divider} />
      <CardContent className={parentClasses.scrollableTable}>
        <Heading
          variant="h3"
          showDivider
          link={{
            to: `/company?fundId=${trancheId}`,
            label: 'View all',
          }}
        >
          Companies in {trancheName}
        </Heading>
        <Table
          columns={columns}
          tablebody={rows.sort(pagination.genericSorter)}
          pagination={false}
          order={pagination.order}
          orderBy={pagination.orderBy}
          onRequestSort={pagination.handleRequestSort}
          emptyMessage="No companies yet"
          variant="nohover"
        />
      </CardContent>
    </Card>
  );
};
