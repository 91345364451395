import {
  Button,
  styled,
  TextField,
  TextFieldProps,
  useTheme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { Row } from 'components/Layout';

type SearchFieldProps = Pick<
  TextFieldProps,
  'variant' | 'name' | 'id' | 'placeholder' | 'fullWidth' | 'value'
> & {
  onSearch: (query: string) => void;
  allowEmptyQuery?: boolean;
  autoSearchOnDebounce?: boolean;
  autoSearchOnBlur?: boolean;
  useExplicitButton?: boolean;
  debounceTime?: number;
  width?: string | number;
  iconPlacement?: 'left' | 'right';
};

const Icon = styled(SearchIcon, {
  shouldForwardProp: (prop) => prop !== 'iconPlacement',
})<Pick<SearchFieldProps, 'iconPlacement'>>(({ theme, iconPlacement }) => ({
  position: 'absolute',
  right: iconPlacement === 'right' ? '10px' : 'auto',
  left: iconPlacement === 'left' ? '4px' : 'auto',
  cursor: 'pointer',

  '&:hover': {
    color: theme.palette.text?.rootColor,
  },
}));

const Container = styled(Row)<{ width?: string | number }>(({ width }) => ({
  position: 'relative',
  width,
}));

const Input = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'iconPlacement',
})<Pick<SearchFieldProps, 'iconPlacement'>>(({ theme, iconPlacement }) => ({
  '& input': {
    paddingRight: iconPlacement === 'right' ? theme.spacing(7) : 0,
    paddingLeft: iconPlacement === 'left' ? theme.spacing(7) : 0,
  },
}));

const SearchField: React.FC<SearchFieldProps> = ({
  onSearch,
  allowEmptyQuery,
  autoSearchOnDebounce,
  autoSearchOnBlur,
  useExplicitButton,
  debounceTime,
  width,
  iconPlacement = 'right',
  ...props
}) => {
  const theme = useTheme();

  const [query, setQuery] = useState<string>((props.value as string) ?? '');
  const debouncedQuery = useDebounce(query, debounceTime ?? 300);

  const handleSearch = () => {
    if (!query?.length && !allowEmptyQuery) return;
    onSearch(query);
  };

  useEffect(() => {
    if (!autoSearchOnDebounce) return;
    handleSearch();
  }, [debouncedQuery]);

  return (
    <Container width={width} centered spacing="sm">
      <Container width={'100%'} centered>
        <Input
          {...props}
          value={query ?? ''}
          type="text"
          onChange={(e) => setQuery(e.target.value)}
          onBlur={autoSearchOnBlur ? handleSearch : undefined}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          iconPlacement={iconPlacement}
        />
        <Icon
          iconPlacement={iconPlacement}
          htmlColor={theme.palette.text.secondary}
          onClick={handleSearch}
        />
      </Container>
      {useExplicitButton ? (
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleSearch}
        >
          Search
        </Button>
      ) : null}
    </Container>
  );
};

export default SearchField;
