import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import api from 'lib/trpcClient';
import { FIRM } from 'adminConstants/routes';
import PageContainer from 'components/PageContainer';
import useFileUpload from 'hooks/ui/useFileUpload';
import { useNotification } from 'hooks/ui/useNotification';
import GeneralInformation from './GeneralInformation';
import FirmLogos from './FirmLogos';
import { UploadType } from 'further-types/files';
import PageContent from 'components/PageContent';
import PageSection from 'components/PageSection';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import RecordView from 'components/RecordView';
import { lpFirm as rules } from 'further-ui/validations';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';

const LpFirmAddEditScreen: React.FC = () => {
  const { uploadFile } = useFileUpload();
  const { id } = useParams<{ id?: string }>();
  const { error } = useNotification();
  const mutationOpts = useHandleTrpcMutation();

  const createLpFirm = api.lpFirm.create.useMutation(
    mutationOpts({
      successMessage: 'LP Firm created successfully',
      successRedirect: FIRM,
      invalidations: ['firms'],
      invalidationHandler: (utils) => {
        utils.lpFirm.getById.invalidate();
      },
    }),
  );
  const updateLpFirm = api.lpFirm.update.useMutation(
    mutationOpts({
      successMessage: 'LP Firm updated successfully',
      successRedirect: FIRM,
      invalidations: ['firms'],
      invalidationHandler: (utils) => {
        utils.lpFirm.getById.invalidate();
      },
    }),
  );
  const lpFirmData = api.lpFirm.getById.useQuery(
    { id: id ?? '' },
    {
      enabled: !!id,
    },
  );

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Firms', link: '/firm' },
    {
      label: id ? 'Update LP Firm' : 'Add LP Firm',
      link: '/',
      isActive: true,
    },
  ];

  const [previewImages, setPreviewImages] = useState<{
    firmLogoPrimaryColorBg: string | null;
    firmLogoTransparentBg: string | null;
    firmLogoSquare: string | null;
  }>({
    firmLogoPrimaryColorBg: null,
    firmLogoTransparentBg: null,
    firmLogoSquare: null,
  });

  useEffect(() => {
    if (lpFirmData.data) {
      setPreviewImages({
        firmLogoPrimaryColorBg: lpFirmData.data?.firmLogoPrimaryColorBgPreview,
        firmLogoTransparentBg: lpFirmData.data?.firmLogoTransparentBgPreview,
        firmLogoSquare: lpFirmData.data?.firmLogoSquarePreview,
      });
    }
  }, [lpFirmData.data]);

  const formMethods = useForm({
    shouldFocusError: true,
    resolver: zodResolver(rules.Create),
    values: lpFirmData.data,
    criteriaMode: 'all',
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = formMethods;

  const onSubmit = async (data) => {
    if (id) {
      updateLpFirm.mutate({ ...data, id });
    } else {
      createLpFirm.mutate(data);
    }
  };

  const handleImageUpload = async (files, onSuccess) => {
    if (!files?.[0]?.path) return;
    try {
      const { url, filePath } = await uploadFile(
        files[0],
        UploadType.FirmLogos,
      );
      onSuccess(url, filePath);
    } catch (_) {
      error("Something went wrong - the file couldn't be uploaded");
    }
  };

  return (
    <PageContainer
      heading={id ? 'LP Firm: Firm Details' : 'Add LP Firm'}
      loading={!!id && lpFirmData.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <PageContent>
        <FormProvider {...formMethods}>
          <form>
            <RecordView size="full">
              <PageSection title="General information">
                <GeneralInformation control={control} errors={errors} />
              </PageSection>
              <PageSection title="Firm logos" showDivider>
                <FirmLogos
                  control={control}
                  setValue={setValue}
                  handleImageUpload={handleImageUpload}
                  previewImages={previewImages}
                  setPreviewImages={setPreviewImages}
                />
              </PageSection>

              <ButtonGroup>
                <Link to={FIRM}>
                  <Button variant="outlined">Cancel</Button>
                </Link>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                  loading={createLpFirm.isPending || updateLpFirm.isPending}
                >
                  Save
                </Button>
              </ButtonGroup>
            </RecordView>
          </form>
        </FormProvider>
      </PageContent>
    </PageContainer>
  );
};

export default LpFirmAddEditScreen;
