import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

const QUERY_CACHE_KEY = 'investor-reporting';

async function fetchInvestorReport({ queryKey }) {
  const [, id] = queryKey;
  const {
    data: { data },
  } = await api.get(`investor-reporting/${id}`);
  return data;
}

async function updateInvestorReporting(data) {
  const { data: response } = await api.post(
    `investor-reporting/update-report`,
    data,
  );
  return response;
}

async function createInvestorReporting(data) {
  const { data: response } = await api.post(
    `investor-reporting/create-report`,
    data,
  );
  return response;
}

function useFetchInvestorReportAPI(id) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEY, id],
    queryFn: fetchInvestorReport,
    enabled: !!id,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
}

function useFetchInvestorReportsAPI(filters = {}) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEY, { filters }],
    queryFn: async () => {
      const { data } = await api.get(`investor-reporting`, {
        params: { ...filters },
      });

      return data.data;
    },
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });
}

function useCreateInvestorReportingAPI() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createInvestorReporting,
    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_CACHE_KEY],
      }),
  });
}

type DeleteInvestorReportingAPIProps = {
  onDeleteSuccess?: () => void;
  onDeleteFailure?: () => void;
};

function useDeleteInvestorReportingAPI({
  onDeleteSuccess,
  onDeleteFailure,
}: DeleteInvestorReportingAPIProps = {}) {
  const queryClient = useQueryClient();
  const { error } = useNotification();
  return useMutation({
    mutationFn: async (reportingId) => {
      const { data: response } = await api.delete(
        `investor-reporting/delete/${reportingId}`,
      );
      return response;
    },
    onError: () => {
      error('Error when trying to delete Investor Reporting');
      onDeleteFailure?.();
    },
    onSuccess: async () => {
      onDeleteSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [QUERY_CACHE_KEY],
      });
    },
  });
}

function useUpdateInvestorReportingAPI() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateInvestorReporting,
    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_CACHE_KEY],
      }),
  });
}

export function useInvestorReporting(params = {}) {
  return {
    fetchInvestorReports: useFetchInvestorReportsAPI,
    fetchInvestorReport: useFetchInvestorReportAPI,
    createInvestorReport: useCreateInvestorReportingAPI(),
    updateInvestorReport: useUpdateInvestorReportingAPI(),
    deleteInvestorReport: useDeleteInvestorReportingAPI(params),
  };
}
