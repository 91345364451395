import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

async function fetchPartialInvestors({ queryKey }) {
  const [, { params }] = queryKey;

  try {
    const {
      data: { data },
    } = await api.get(`investor/partially-investor`, {
      params,
    });
    return data;
  } catch (error) {
    throw error;
  }
}

export function useIncompleteRegistrations({ params }) {
  return useQuery({
    queryKey: ['incomplete-registrations', { params }],
    queryFn: fetchPartialInvestors,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });
}
