import { IconButton, Tooltip } from '@mui/material';
import Button from 'components/Button';
import { Link } from 'react-router-dom';

export type FilterButtonProps = {
  label: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  link?: string;
  variant?: 'contained' | 'outlined' | 'text';
  visible?: boolean;
  isIcon?: boolean;
  tooltip?: string;
};

const FilterButton: React.FC<FilterButtonProps> = ({
  label,
  link,
  visible = true,
  isIcon = false,
  tooltip,
  ...props
}) => {
  if (!visible) return null;

  const ButtonComponent = isIcon ? IconButton : Button;

  const button = link ? (
    <Link to={link}>
      <ButtonComponent variant="contained" disabled={props.disabled}>
        {label}
      </ButtonComponent>
    </Link>
  ) : tooltip && props.disabled ? (
    <div>
      <ButtonComponent variant="contained" {...props}>
        {label}
      </ButtonComponent>
    </div>
  ) : (
    <ButtonComponent variant="contained" {...props}>
      {label}
    </ButtonComponent>
  );

  return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;
};

export default FilterButton;
