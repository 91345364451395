import React from 'react';

import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import TextField from 'components/FormElements/AppTextInput';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import GridContainer from 'components/GridContainer';
import Grid from '@mui/material/Grid2';
import { map, sortBy } from 'lodash';
import { getFeeAccountingOptions } from 'utils/getFeeAccountingOptions';
import TranchesMultiSelect from 'components/TrancheMultiSelect';
import { type as feeConst } from 'adminConstants/typeConstant';

export const optionValueAll = 'ALL';

const useStyles = makeStyles()(() => ({
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '100%',
    paddingBottom: 2,
  },
  actionButton: {
    marginRight: 8,
  },
  filterLoading: {
    paddingLeft: 6,
    paddingTop: 10,
  },
  exportSpinner: {
    color: 'rgba(0, 0, 0, 0.26)',
    marginRight: 10,
  },
}));

type Props = any; // TODO: define types

const FeeFilter: React.FC<Props> = ({
  filters,
  onFiltersChange,
  trancheListing,
  setIsOpen,
  onExportClick,
  isExportLoading,
}) => {
  const { classes } = useStyles();

  const mappedTranches = map(
    sortBy(trancheListing ?? [], (x) => x.firmId.firmName.toLowerCase),
    (tranche) => ({
      ...tranche,
      label: `${tranche.firmId.firmName} ${tranche.fundName}`,
    }),
  );
  const feeAccounting = getFeeAccountingOptions();
  return (
    <GridContainer>
      <Grid size={{ xs: 12 }}>
        <GridContainer spacing={3}>
          <Grid size={{ xs: 3, xl: 2 }}>
            <label className={classes.label} htmlFor="dateRange">
              Date range
            </label>
            <MuiDateRangePicker
              dateRange={{
                startDate: filters.startDate,
                endDate: filters.endDate,
              }}
              onDateRangeChange={(dateRange) => {
                onFiltersChange(dateRange);
              }}
              TextFieldProps={{ placeholder: 'Select date' }}
            />
          </Grid>
          <Grid size={{ xs: 3, xl: 2 }}>
            <label className={classes.label} htmlFor="feeAccounting">
              Fee accounting
            </label>
            <AppSelectBox
              required
              data={[{ label: 'All', value: optionValueAll }, ...feeAccounting]}
              name="feeAccounting"
              valueKey="value"
              labelKey="label"
              variant="outlined"
              value={filters?.feeAccounting}
              fullWidth
              onChange={(event) => {
                onFiltersChange({
                  feeAccounting: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid size={{ xs: 3, xl: 2 }}>
            <label className={classes.label} htmlFor="feeType">
              Fee display
            </label>
            <AppSelectBox
              data={[
                {
                  label: 'Fees to date',
                  value: feeConst.feeExistence.past,
                },
                { label: 'Future fees', value: feeConst.feeExistence.future },
                { label: 'All', value: optionValueAll },
              ]}
              name="feeType"
              valueKey="value"
              labelKey="label"
              variant="outlined"
              value={filters?.feeType}
              onChange={(event) => {
                onFiltersChange({ feeType: event.target.value });
              }}
            />
          </Grid>
          <Grid size={{ xs: 3, xl: 2 }}>
            <label className={classes.label} htmlFor="funds">
              Tranche
            </label>
            {mappedTranches?.length ? (
              //@ts-expect-error
              <TranchesMultiSelect
                tranches={mappedTranches}
                value={filters.funds}
                handleChangeSelectedTranches={(trancheIds) => {
                  onFiltersChange({ funds: trancheIds });
                }}
                selectAllLabel="Select all tranches (excludes investor balance fees)"
              />
            ) : (
              <div className={classes.filterLoading}>
                <CircularProgress size={18} />
              </div>
            )}
          </Grid>
          <Grid size={{ xs: 3, xl: 2 }}>
            <label className={classes.label} htmlFor="fullName">
              Fee name search
            </label>
            <TextField
              required
              name="fullName"
              placeholder="Enter name"
              value={filters?.fullName}
              fullWidth
              onChange={(event) => {
                onFiltersChange({
                  fullName: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid size={{ xs: 3, xl: 2 }}>
            <label className={classes.label} htmlFor="exportToCustodian">
              Exported to custodian?
            </label>
            <AppSelectBox
              data={[
                {
                  label: 'Yes',
                  value: true,
                },
                { label: 'No', value: false },
                { label: 'All', value: optionValueAll },
              ]}
              name="exportToCustodian"
              valueKey="value"
              labelKey="label"
              variant="outlined"
              value={filters?.exportToCustodian}
              onChange={(event) => {
                onFiltersChange({
                  exportToCustodian: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid size={{ xs: 6 }}>
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => setIsOpen(true)}
                color="primary"
                className={classes.actionButton}
                variant="contained"
                disabled={
                  isExportLoading ||
                  filters.exportToCustodian ||
                  filters?.feeAccounting !==
                    feeConst.feeAccounting.chargedToInvestor
                }
              >
                {isExportLoading ? (
                  <CircularProgress
                    className={classes.exportSpinner}
                    size={18}
                  />
                ) : null}
                Custodian export
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.actionButton}
                onClick={() => onExportClick(feeConst.feeExport.general)}
                disabled={isExportLoading}
              >
                {isExportLoading ? (
                  <CircularProgress
                    className={classes.exportSpinner}
                    size={18}
                  />
                ) : null}
                General export
              </Button>
            </div>
          </Grid>
        </GridContainer>
      </Grid>
    </GridContainer>
  );
};

export default FeeFilter;
