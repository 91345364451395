import { investorTypeLabel, InvestorType } from 'further-types/investor';
import { dateToLabel } from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import { Button } from '@mui/material';
import { useGetRole } from 'hooks/ui/useGetRole';
import AlertDialog from 'components/AlertDialog';
import { Row } from 'components/Layout';

type Props = {
  certification: {
    _id: string;
    investorType: InvestorType;
    certificationPerformedAt: string;
  };
  onReset: () => void;
  onDownload: (certification: Props['certification']) => void;
  isResetting: boolean;
};

const CurrentCertificationResult: React.FC<Props> = ({
  certification,
  onReset,
  isResetting,
  onDownload,
}) => {
  const { isSuperAdmin } = useGetRole();
  const resetDisclosure = useDisclosure();

  return (
    <Row centered spacing="xs">
      <div>
        {investorTypeLabel[certification.investorType]}{' '}
        {`${dateToLabel(
          certification.certificationPerformedAt,
          undefined,
          true,
        )}`}
      </div>
      <Button
        size="extra-small"
        variant="outlined"
        color="primary"
        onClick={() => {
          onDownload(certification);
        }}
      >
        Download investor certification
      </Button>
      {isSuperAdmin && (
        <Button
          size="extra-small"
          variant="outlined"
          color="primary"
          disabled={isResetting}
          onClick={resetDisclosure.onOpen}
        >
          Reset certification status
        </Button>
      )}
      <AlertDialog
        onClose={resetDisclosure.onClose}
        open={resetDisclosure.isOpen}
        title="Warning"
        content="Are you sure you wish to reset this user’s investor certification? This cannot be undone and they will be requested to re-classify themselves when they next make a subscription."
        btnLabels={{ confirm: 'Reset', cancel: 'Go back' }}
        confirmBtnProps={{
          variant: 'contained',
          color: 'primary',
          disabled: isResetting,
        }}
        onConfirm={() => {
          onReset();
          resetDisclosure.onClose();
        }}
      />
    </Row>
  );
};

export default CurrentCertificationResult;
