import { makeStyles } from 'tss-react/mui';
import FieldRow from 'components/FormElements/FieldRow';
import { useGetRole } from 'hooks/ui/useGetRole';
import { numberToPercentageString } from 'utils/numbers';

type Props = {
  changeToInitialInvestmentFees: any;
  commissionOnInitialInvestmentFees: any;
  firmsInFeeAmendments: any;
};

const useStyles = makeStyles()(() => ({
  row: {
    display: 'flex',
    gap: '.5rem',
    alignItems: 'center',
  },
  nameCell: {
    maxWidth: '10rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  pcCell: {
    minWidth: '3rem',
  },
}));

const AdviserFeeAmendments = ({
  changeToInitialInvestmentFees,
  commissionOnInitialInvestmentFees,
  firmsInFeeAmendments,
}: Props) => {
  const { classes } = useStyles();
  const { isSuperAdmin } = useGetRole();

  const getFirmName = (id: string) =>
    firmsInFeeAmendments?.find((firm: any) => firm._id === id)?.firmName ??
    'Unknown firm';

  return (
    <>
      <FieldRow title="Adviser-specific initial fee change" centerTitle>
        {changeToInitialInvestmentFees.length === 0 && (
          <div className={classes.row}>
            <span className={classes.nameCell}>No changes</span>
          </div>
        )}
        {changeToInitialInvestmentFees.map((feeAmendment: any, i: number) => (
          <div className={classes.row} key={i}>
            {isSuperAdmin && (
              <span
                className={classes.nameCell}
                title={getFirmName(feeAmendment.firmId)}
              >
                {getFirmName(feeAmendment.firmId)}
              </span>
            )}
            <span className={classes.pcCell}>
              {numberToPercentageString(feeAmendment.amendByPercentage, {
                accuracy: 2,
                noMultiply: true,
              })}
            </span>
            {Number(feeAmendment.vatPercentage) > 0 && (
              <span>
                +{' '}
                {numberToPercentageString(feeAmendment.vatPercentage, {
                  accuracy: 2,
                  noMultiply: true,
                })}{' '}
                VAT
              </span>
            )}
          </div>
        ))}
      </FieldRow>

      <FieldRow title="Commission" centerTitle>
        {commissionOnInitialInvestmentFees.length === 0 && (
          <div className={classes.row}>
            <span className={classes.nameCell}>No changes</span>
          </div>
        )}
        {commissionOnInitialInvestmentFees.map(
          (feeAmendment: any, i: number) => (
            <div className={classes.row} key={i}>
              {isSuperAdmin && (
                <span className={classes.nameCell}>
                  {getFirmName(feeAmendment.firmId)}
                </span>
              )}
              <span className={classes.pcCell}>
                {numberToPercentageString(feeAmendment.amendByPercentage, {
                  accuracy: 2,
                  noMultiply: true,
                })}
              </span>
              {Number(feeAmendment.vatPercentage) > 0 && (
                <span>
                  +{' '}
                  {numberToPercentageString(feeAmendment.vatPercentage, {
                    accuracy: 2,
                    noMultiply: true,
                  })}{' '}
                  VAT
                </span>
              )}
            </div>
          ),
        )}
      </FieldRow>
    </>
  );
};

export default AdviserFeeAmendments;
