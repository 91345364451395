import React, { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  pageHeaderRoot: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
}));

type Props = {
  heading?: string;
  breadcrumbComponent: React.ReactNode;
  subheaderComponent?: React.ReactNode;
};

const PageHeader: React.FC<PropsWithChildren<Props>> = ({
  heading,
  breadcrumbComponent,
  children,
  subheaderComponent,
}) => {
  const { classes, cx } = useStyles();
  const [headingBold, headingNormal] = heading?.split(':') || [];

  return (
    <Box
      className={cx(classes.pageHeaderRoot, 'page-header')}
      mb={{ xs: 5, md: 6, lg: 8 }}
      gap={4}
    >
      <Typography component="div" variant="h1">
        {headingBold}
        {headingNormal && (
          <>
            : <span style={{ fontWeight: 400 }}>{headingNormal}</span>
          </>
        )}
      </Typography>
      {subheaderComponent}
      {!!breadcrumbComponent && (
        <Box ml={{ sm: 'auto' }}>{breadcrumbComponent}</Box>
      )}
      {children}
    </Box>
  );
};

export default PageHeader;
