import FieldRow from 'components/FormElements/FieldRow';
import { Button } from '@mui/material';
import { dateToLabel } from 'further-ui/utils';
import ButtonGroup from 'components/ButtonGroup';
import RecordView from 'components/RecordView';

const SummaryStep = ({
  company,
  shareSplitDate,
  shareSplitFactor,
  currentSharePriceDisplay,
  newSharePriceDisplay,
  onSubmit,
  onBack,
}) => {
  return (
    <RecordView>
      <p>
        You are attempting to split the shares by a factor of{' '}
        <strong>{shareSplitFactor}</strong>. The new share price will be{' '}
        <strong>{newSharePriceDisplay}</strong>
      </p>
      <FieldRow title="Company" centerTitle>
        {company.data?.tradingName}
      </FieldRow>
      <FieldRow title="Share split date" centerTitle>
        {dateToLabel(shareSplitDate)}
      </FieldRow>
      <FieldRow title="Current share price" centerTitle>
        {currentSharePriceDisplay}
      </FieldRow>
      <FieldRow title="Share split factor" centerTitle>
        {shareSplitFactor}
      </FieldRow>
      <FieldRow title="New share price" centerTitle>
        {newSharePriceDisplay}
      </FieldRow>
      <ButtonGroup>
        <Button
          onClick={onBack}
          variant="outlined"
          style={{ marginRight: '0.5rem' }}
        >
          Back
        </Button>
        <Button onClick={onSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </ButtonGroup>
    </RecordView>
  );
};

export default SummaryStep;
