import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';

const Page = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

const Error = styled(Box)(({ theme }) => ({
  errorNumber: {
    color: theme.palette.text.primary,
    fontWeight: 800,
    lineHeight: 1,
    marginBottom: 30,
    textShadow: '10px 6px 8px hsla(0,0%,45.9%,.8)',
  },
}));

const Error404: React.FC = () => {
  return (
    <Page>
      <Error fontSize={{ xs: 100, sm: 160 }}>404</Error>
      <Box fontSize={{ xs: 16, sm: 24 }} mb={8} color="grey.500">
        Page not found
      </Box>
    </Page>
  );
};

export default Error404;
