import { TextField } from '@mui/material';
import Dialog from 'components/Dialog';
import { AppSelectBox } from 'components/FormElements';
import Spacing from 'components/Spacing';
import { AccountSectionLabels } from 'further-ui/labels';
import { account as rules } from 'further-ui/validations';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Schema } from 'further-types/lp/account';
import api from 'lib/trpcClient';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';

type FormValues = z.infer<typeof rules.CreateAccount>;

type Props = {
  vehicleId: string;
  firmId: string;
  onClose: () => void;
  account?: {
    id: string;
    section: Schema.AccountSection;
    accountName: string;
    accountCode: string;
  };
};

const sections = Object.entries(AccountSectionLabels).map(([key, label]) => ({
  key,
  label,
}));

const AddEditAccount: React.FC<Props> = ({ vehicleId, account, onClose }) => {
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(rules.CreateAccount),
    defaultValues: {
      section: account?.section,
      accountName: account?.accountName ?? '',
      accountCode: account?.accountCode ?? '',
    },
  });

  const mutationOpts = useHandleTrpcMutation()({
    successMessage: account
      ? 'Account updated successfully'
      : 'Account created successfully',
    invalidationHandler: (utils) => utils.account.invalidate(),
    onSuccess: onClose,
  });

  const createAccount = api.account.create.useMutation(mutationOpts);
  const updateAccount = api.account.update.useMutation(mutationOpts);

  const onSubmit = (data: FormValues) => {
    if (account) {
      updateAccount.mutate({
        ...data,
        id: account.id,
        vehicleId,
      });
    } else {
      createAccount.mutate({
        ...data,
        vehicleId,
      });
    }
  };

  return (
    <Dialog
      title="Add account"
      open
      onConfirm={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <form>
        <Spacing size="sm">
          <Controller
            name="section"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <AppSelectBox
                {...field}
                data={sections}
                valueKey="key"
                labelKey="label"
                placeholder="General ledger section"
                error={!!error}
                helperText={error?.message}
                fullWidth
                disabled={!!account}
              />
            )}
          />
          <Controller
            name="accountCode"
            control={control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                inputRef={ref}
                placeholder="Account code"
                error={!!error}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="accountName"
            control={control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                inputRef={ref}
                placeholder="Account name"
                error={!!error}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
        </Spacing>
      </form>
    </Dialog>
  );
};

export default AddEditAccount;
