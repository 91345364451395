import { round } from 'lodash';
import {
  AppAutocomplete,
  AppDatePicker,
  AppSelectBox,
  AppTextInput,
  FieldRow,
} from 'components/FormElements';
import { Button, ButtonGroup, Switch } from '@mui/material';
import PageContainer from 'components/PageContainer';
import { useEffect, useState } from 'react';
import AdviserSelect from '../EditInvestment/InvestmentDetails/AdviserSelect';
import { paymentEmailOptions } from 'further-ui/labels';
import InvestorSearch from 'components/InvestorSearch';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useSortTranches } from 'hooks/ui/useSortTranches';
import { useFunds } from 'hooks/data/fund/useFunds';
import { useFirm } from 'hooks/data/firm/useFirm';
import { NumericFormat } from 'react-number-format';
import TextField from 'components/FormElements/AppTextInput';
import { useCreateInvestment } from 'hooks/data/investment/useInvestments';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useInvestorCashBalanceById } from 'hooks/data/investor/useUploadedInvestorCashBalance';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { investmentFormSchema, isPaymentEmailStatusPaid } from './schema';
import { numberToCurrencyString } from 'further-ui/utils';
import { FirmBankDetailsSelector } from 'components/FirmBankDetailsSelector';
import PageContent from 'components/PageContent';
import RecordView from 'components/RecordView';
import { Row } from 'components/Layout';

const useStyles = makeStyles()((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
    marginBottom: 24,
    width: '100%',
  },
  switchBase: {
    display: 'flex',
    gap: 8,
  },
  textField: {
    padding: '0.6rem 0',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    marginTop: 4,
  },
}));

const fullCashBalancePaymentEmailOptions = paymentEmailOptions.map((item) => {
  if (isPaymentEmailStatusPaid(item.value)) {
    return item;
  }
  return { ...item, disabled: true };
});

const breadcrumbs = [
  { label: 'Dashboard' },
  { label: 'Subscriptions', link: '/investment' },
  { label: 'Create Subscription', link: '/', isActive: true },
];

const AddInvestment: React.FC = () => {
  const history = useHistory();
  const { classes } = useStyles();
  const { isSuperAdmin, firmId: userFirmId } = useGetRole();
  const investorCashBalance = useInvestorCashBalanceById();

  const [firm, setFirm] = useState<{
    _id: string;
    domain: string;
    firmName: string;
  }>();
  const { tranches } = useFunds({
    leanResponse: true,
    firmId: userFirmId || firm?._id,
  });
  const sortedTranches = useSortTranches(tranches?.data);
  const { firms } = useFirm({ params: { firmId: userFirmId } });
  const [investorId, setInvestorId] = useState<string>();
  const [useCashBalance, setUseCashBalance] = useState(false);
  const [useAdviser, setUseAdviser] = useState(false);
  const [cashBalanceError, setCashBalanceError] = useState(false);
  const [currentInvestorCashBalance, setCurrentInvestorCashBalance] =
    useState(0);

  const { mutate: createInvestment, isPending } = useCreateInvestment({
    onCreate: (id) => history.push(`/edit-investment/${id}`),
  });

  useEffect(() => {
    if (!investorId || (isSuperAdmin && !firm?._id)) return;
    investorCashBalance.mutate({
      investorId,
      //@ts-expect-error
      firmId: firm?._id || userFirmId,
    });
  }, [firm, investorId]);

  useEffect(() => {
    setCurrentInvestorCashBalance(investorCashBalance?.data?.data || 0);
  }, [investorCashBalance.data]);

  const formMethods = useForm({
    resolver: zodResolver(investmentFormSchema),
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = formMethods;

  const onSubmit = (data) => {
    if (data?.cashBalance > currentInvestorCashBalance) {
      setCashBalanceError(true);
      return;
    }
    if (data?.adviserId === undefined) {
      data.adviserFee = undefined;
    }

    if (Object.keys(errors).length) {
      return;
    } else {
      createInvestment({ ...data, domain: firm?.domain });
    }
  };

  useEffect(() => {
    setValue('investmentDate', new Date());
    setValue('paymentDate', new Date());
    setValue('firmId', userFirmId);
  }, []);

  const investmentAmount = formMethods.watch('investmentAmount');
  const adviserFee = formMethods.watch('adviserFee');
  const cashBalance = formMethods.watch('cashBalance');
  const fullCashBalancePayment =
    useCashBalance &&
    cashBalance >= round((investmentAmount ?? 0) + (adviserFee ?? 0), 2);

  const paymentEmailStatus = formMethods.watch('paymentEmailStatus');
  const useReconciledPayment =
    isPaymentEmailStatusPaid(paymentEmailStatus) && !fullCashBalancePayment;

  return (
    <PageContainer heading="Create Subscription" breadcrumbs={breadcrumbs}>
      <PageContent>
        <FormProvider {...formMethods}>
          <form>
            <RecordView>
              <FieldRow title="Investor*" centerTitle>
                <Controller
                  name="investorId"
                  control={control}
                  render={({ field: { ...rest }, fieldState: { error } }) => (
                    <InvestorSearch
                      {...rest}
                      onInvestorSelect={(value) => {
                        setValue('investorId', value);
                        setInvestorId(value);
                      }}
                      firmId={userFirmId}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FieldRow>

              <FieldRow title="Firm*" centerTitle>
                {isSuperAdmin ? (
                  <Controller
                    name="firmId"
                    control={control}
                    render={({ fieldState: { error } }) => (
                      <AppAutocomplete
                        id="firmId"
                        options={firms.data?.result || []}
                        getOptionLabel={(option) => option?.firmName}
                        filterSelectedOptions
                        value={firms.data?.result?.find(
                          ({ _id }) => _id === firm?._id,
                        )}
                        onChange={(_, value) => {
                          setFirm({
                            //@ts-expect-error
                            _id: value?._id,
                            //@ts-expect-error
                            domain: value?.domain,
                            //@ts-expect-error
                            firmName: value?.firmName,
                          });
                          setValue('firmId', value?._id);
                          setValue('fundId', undefined);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Firm"
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                ) : (
                  <div>{firms?.data?.result[0]?.firmName}</div>
                )}
              </FieldRow>

              <FieldRow title="Fund*" centerTitle>
                <Controller
                  name="fundId"
                  control={control}
                  render={({
                    fieldState: { error },
                    field: { value, name },
                  }) => (
                    <AppAutocomplete
                      key={firm?._id || 'no-firm'}
                      id="fundId"
                      name={name}
                      options={sortedTranches}
                      getOptionLabel={(option) => option?.label}
                      value={value}
                      onChange={(_, value) => {
                        setValue('fundId', value?._id);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Tranche"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </FieldRow>

              <FieldRow title="Subscription date*" centerTitle>
                <Controller
                  name="investmentDate"
                  control={control}
                  render={({ field: { name, value } }) => (
                    <AppDatePicker
                      name={name}
                      value={value}
                      onChange={(value) => setValue('investmentDate', value)}
                    />
                  )}
                />
              </FieldRow>

              <FieldRow title="Subscription amount*" centerTitle>
                <Controller
                  name="investmentAmount"
                  control={control}
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <NumericFormat
                      {...rest}
                      inputRef={ref}
                      required
                      placeholder="e.g. £5,000"
                      customInput={TextField}
                      prefix="£"
                      type="text"
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      onChange={(event) =>
                        setValue(
                          'investmentAmount',
                          Number(
                            event.target.value
                              .replace('£', '')
                              .replaceAll(/,/g, ''),
                          ),
                        )
                      }
                      thousandSeparator
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FieldRow>

              <FieldRow title="Adviser" centerTitle>
                <Controller
                  name="adviserId"
                  control={control}
                  render={({ field: { value } }) => (
                    <AdviserSelect
                      adviser={value}
                      onChange={(_event, adviser) => {
                        setValue('adviserId', adviser?._id || undefined);
                        setUseAdviser(!!adviser);
                      }}
                    />
                  )}
                />
              </FieldRow>

              {useAdviser && (
                <FieldRow title="Advice Fee" centerTitle>
                  <Controller
                    name="adviserFee"
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <NumericFormat
                        {...rest}
                        inputRef={ref}
                        required
                        placeholder="e.g. £5,000"
                        customInput={TextField}
                        prefix="£"
                        type="text"
                        decimalScale={2}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        onChange={(event) =>
                          setValue(
                            'adviserFee',
                            Number(
                              event.target.value
                                .replace('£', '')
                                .replaceAll(/,/g, ''),
                            ),
                          )
                        }
                        thousandSeparator
                      />
                    )}
                  />
                </FieldRow>
              )}

              <FieldRow title="External subscription ID" centerTitle>
                <Controller
                  name="externalInvestmentId"
                  control={control}
                  render={({ field: { name, value } }) => (
                    <AppTextInput
                      name={name}
                      variant="outlined"
                      style={{ maxWidth: 300 }}
                      value={value}
                      onChange={(event) =>
                        setValue('externalInvestmentId', event.target.value)
                      }
                    />
                  )}
                />
              </FieldRow>

              <FieldRow title="Use cash balance" centerTitle>
                <Controller
                  name="useCashBalance"
                  control={control}
                  render={({ field: { name, value } }) => (
                    <Row centered spacing="xs">
                      <span>No</span>
                      <Switch
                        name={name}
                        checked={value}
                        inputProps={{ 'aria-label': 'Switch' }}
                        onChange={(_, checked) => {
                          setValue(name, checked);
                          setUseCashBalance(checked);
                        }}
                      />
                      <span>Yes</span>
                    </Row>
                  )}
                />
              </FieldRow>

              {useCashBalance && (
                <>
                  <FieldRow title="Current cash balance" centerTitle>
                    <p>{numberToCurrencyString(currentInvestorCashBalance)}</p>
                  </FieldRow>

                  <FieldRow title="Amount to use from cash balance" centerTitle>
                    <Controller
                      name="cashBalance"
                      control={control}
                      render={({
                        field: { ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <NumericFormat
                          {...rest}
                          inputRef={ref}
                          required
                          customInput={TextField}
                          placeholder="e.g. £100"
                          prefix="£"
                          type="text"
                          decimalScale={2}
                          onChange={(event) =>
                            setValue(
                              'cashBalance',
                              Number(
                                event.target.value
                                  .replace('£', '')
                                  .replace(/,/g, ''),
                              ),
                            )
                          }
                          thousandSeparator
                          allowNegative={false}
                          allowLeadingZeros={false}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    {cashBalanceError && (
                      <div className={classes.errorText}>
                        Cash balance cannot exceed current cash balance
                      </div>
                    )}
                  </FieldRow>
                </>
              )}

              <FieldRow title="Payment and email status*" centerTitle>
                <Controller
                  name="paymentEmailStatus"
                  control={control}
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <AppSelectBox
                      inputRef={ref}
                      {...rest}
                      required
                      data={
                        fullCashBalancePayment
                          ? fullCashBalancePaymentEmailOptions
                          : paymentEmailOptions
                      }
                      valueKey="value"
                      labelKey="label"
                      variant="outlined"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FieldRow>

              {useReconciledPayment && (
                <>
                  <FieldRow title="Date of payment" centerTitle>
                    <Controller
                      name="paymentDate"
                      control={control}
                      render={({
                        field: { name, value },
                        fieldState: { error },
                      }) => (
                        <AppDatePicker
                          name={name}
                          value={value}
                          onChange={(value) => setValue('paymentDate', value)}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </FieldRow>

                  <FieldRow title="Paid to" centerTitle>
                    <Controller
                      name="paidToFirmBankDetailId"
                      control={control}
                      render={({
                        field: { value, ...rest },
                        fieldState: { error },
                      }) => (
                        <FirmBankDetailsSelector
                          {...rest}
                          currentValue={value}
                          //@ts-expect-error
                          firmId={userFirmId || firm?._id}
                          placeholder="Select receipt account"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </FieldRow>

                  <FieldRow title="Reference" centerTitle>
                    <Controller
                      name="reference"
                      control={control}
                      render={({
                        field: { ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <AppTextInput
                          {...rest}
                          inputRef={ref}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </FieldRow>
                </>
              )}

              <ButtonGroup>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                  disabled={isPending}
                >
                  Save
                </Button>
              </ButtonGroup>
            </RecordView>
          </form>
        </FormProvider>
      </PageContent>
    </PageContainer>
  );
};

export default AddInvestment;
