import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Button, CircularProgress, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AttachFile, Close, Warning } from '@mui/icons-material';

const useStyles = makeStyles()(() => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  spinner: {
    marginRight: '0.5rem',
  },
}));

type Props = {
  onUpload: (file: File) => void;
  onDelete: () => void;
  uploadError: string | null;
  isUploading: boolean;
};

export const FileUploadButton: React.FC<Props> = ({
  onUpload,
  onDelete,
  uploadError,
  isUploading,
}) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const [error, setError] = useState<string>();
  const { classes } = useStyles();

  useEffect(() => {
    if (uploadError) {
      setError(uploadError);
    }
  }, [uploadError]);

  if (isUploading) {
    return (
      <Button disabled color="primary" variant="outlined">
        <CircularProgress size={18} className={classes.spinner} />
        Uploading
      </Button>
    );
  }
  if (error) {
    return (
      <div className={classes.flex}>
        <Tooltip title={error}>
          <Warning color="error" />
        </Tooltip>
        <IconButton
          onClick={() => {
            setFile(undefined);
            onDelete();
            setError(undefined);
          }}
          size="large"
        >
          <Close />
        </IconButton>
      </div>
    );
  }

  if (file) {
    return (
      <div className={classes.flex}>
        <Tooltip title={file.name}>
          <AttachFile />
        </Tooltip>
        <IconButton
          onClick={() => {
            setFile(undefined);
            onDelete();
          }}
          size="large"
        >
          <Close />
        </IconButton>
      </div>
    );
  }

  return (
    <>
      <Button
        onClick={() => inputFile.current?.click()}
        color="primary"
        variant="outlined"
        style={{ minWidth: 130 }}
      >
        Choose file
      </Button>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={(e) => {
          const selectedFile = e.target.files?.[0];
          setFile(selectedFile);
          if (selectedFile) {
            onUpload(selectedFile);
          }
        }}
      />
    </>
  );
};
