import React from 'react';
import { DateRangePicker } from 'materialui-daterange-picker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';

import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import TextField from '../AppTextInput';
import { endOfDay, format, isSameDay, startOfDay } from 'date-fns';

const useStyles = makeStyles()(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconButton: {
    padding: '3px',
  },
}));

export const MuiDateRangePicker = (props) => {
  const { classes } = useStyles();

  const [open, setOpen] = React.useState(false);

  const formatString = props.format ?? 'dd/MM/yyyy';

  const toggle = () => setOpen(!open);

  const getDisplayDateRange = (dateRange) => {
    const startDate = dateRange?.startDate
      ? format(dateRange.startDate, formatString)
      : undefined;

    const endDate = dateRange?.endDate
      ? format(dateRange.endDate, formatString)
      : undefined;

    return startDate || endDate ? `${startDate} - ${endDate}` : '';
  };

  return (
    <>
      <TextField
        {...props.TextFieldProps}
        value={getDisplayDateRange(props.dateRange)}
        onClick={toggle}
        InputProps={{
          ...props.TextFieldProps?.InputProps,
          readOnly: true,
          endAdornment: (
            <>
              <IconButton className={classes.iconButton} size="large">
                <DateRangeIcon />
              </IconButton>
              {getDisplayDateRange(props.dateRange) && (
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    props.onDateRangeChange({
                      startDate: null,
                      endDate: null,
                    });
                    // JB: this is a bad hack I know - no time to refactor
                    // TextField to set onClick only on the appropriate part
                    window.setTimeout(() => {
                      setOpen(false);
                    }, 1);
                  }}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              )}
            </>
          ),
        }}
      />
      <Modal
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div>
            <DateRangePicker
              open={true}
              toggle={toggle}
              onChange={(range) => {
                if (
                  isSameDay(
                    range.startDate ?? new Date(),
                    range.endDate ?? new Date(),
                  )
                ) {
                  range.startDate = startOfDay(range.startDate ?? new Date());
                  range.endDate = endOfDay(range.endDate ?? new Date());
                } else {
                  range.endDate = endOfDay(range.endDate ?? new Date());
                }
                props.onDateRangeChange(range);
                toggle();
              }}
              initialDateRange={props.dateRange}
              definedRanges={props.definedRanges}
              wrapperClassName="date-range-picker-wrapper"
              minDate={new Date('1900-01-01')}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};
