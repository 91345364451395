import { omit } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';
import { Api } from 'further-types/investor';
import { ApiResponse } from 'further-types/api';

export function useInvestorDisability() {
  const handleApiException = useApiExceptionHandler();
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation({
    throwOnError: false,
    mutationFn: async (
      data: Api.UpdateInvestorDisabilityRequest & { investorId: string },
    ) => {
      const body = omit(data, 'investorId');
      await api.put<ApiResponse<null>>(
        `investor/${data.investorId}/update-disability`,
        body,
      );
      return data;
    },
    onSuccess: async ({ investorId, isDisabled, disableAllEmails }) => {
      let message = '';
      if (isDisabled !== undefined) {
        message = isDisabled ? 'Investor disabled.' : 'Investor enabled.';
      }
      if (disableAllEmails !== undefined) {
        message = disableAllEmails
          ? 'Emails have been disabled for this investor.'
          : 'Emails have been enabled for this investor.';
      }
      notification.success(message);

      await queryClient.invalidateQueries({
        queryKey: ['investor', investorId],
      });
      await queryClient.invalidateQueries({ queryKey: ['investors'] });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
}
