import React from 'react';
import TextField from 'components/FormElements/AppTextInput';
import CkEditor from 'components/CkEditor';
import DropzoneComponent from 'components/Dropzone';
import FieldRow from 'components/FormElements/FieldRow';
import { InputAdornment } from '@mui/material';

type Props = any; //TODO: define props

const NonVctFields: React.FC<Props> = ({
  values,
  setValues,
  handleChangeLogo,
  errorText,
  classes,
}) => (
  <>
    <FieldRow title="Trading name*" centerTitle>
      <TextField
        required
        name="tradingName"
        placeholder="Enter trading name, e.g. Acme"
        fullWidth
        onChange={(event) =>
          setValues({
            ...values,
            tradingName: event.target.value,
          })
        }
        value={values.tradingName}
        error={errorText.tradingName}
        helperText={errorText.tradingName}
      />
    </FieldRow>
    <FieldRow title="Full legal name*" centerTitle>
      <TextField
        required
        name="legalName"
        placeholder="Enter legal name, e.g. Acme Software Limited"
        fullWidth
        onChange={(event) =>
          setValues({ ...values, legalName: event.target.value })
        }
        value={values.legalName}
        error={errorText.legalName}
        helperText={errorText.legalName}
      />
    </FieldRow>
    <FieldRow title="URL" centerTitle>
      <TextField
        name="url"
        placeholder="Enter URL"
        fullWidth
        onChange={(event) => setValues({ ...values, url: event.target.value })}
        value={values.url}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">https://</InputAdornment>
          ),
        }}
      />
    </FieldRow>
    <FieldRow title="Twitter URL" centerTitle>
      <TextField
        name="twitterUrl"
        placeholder="Enter Twitter URL"
        fullWidth
        onChange={(event) =>
          setValues({ ...values, twitterUrl: event.target.value })
        }
        value={values.twitterUrl}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">https://</InputAdornment>
          ),
        }}
      />
    </FieldRow>
    <FieldRow title="LinkedIn URL" centerTitle>
      <TextField
        name="linkedinUrl"
        placeholder="Enter LinkedIn URL"
        fullWidth
        onChange={(event) =>
          setValues({
            ...values,
            linkedinUrl: event.target.value,
          })
        }
        value={values.linkedinUrl}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">https://</InputAdornment>
          ),
        }}
      />
    </FieldRow>
    <FieldRow title="Companies House number" centerTitle>
      <TextField
        name="companiesHouseNumber"
        placeholder="Enter Companies House Number"
        fullWidth
        onChange={(event) =>
          setValues({
            ...values,
            companiesHouseNumber: event.target.value,
          })
        }
        value={values.companiesHouseNumber}
        error={errorText.companiesHouseNumber}
        helperText={errorText.companiesHouseNumber}
      />
    </FieldRow>
    <FieldRow title="Description*">
      <CkEditor
        //@ts-expect-error
        name="description"
        placeholder="Enter a description of the company in approximately 30-40+ words"
        onChange={(_, editor) => {
          const value = editor.getData();
          setValues({
            ...values,
            description: value,
          });
        }}
        value={values.description}
      />
      <p className={classes.errorCk}>{errorText.description}</p>
    </FieldRow>
    <FieldRow
      title={'Logo'}
      informationText={'(Square, no transparent background, .jpg or .png)'}
    >
      <DropzoneComponent
        name="companyLogo"
        accept={{
          'image/jpeg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
        }}
        files={
          values?.companyLogo
            ? [{ preview: values?.previewCompanyLogo, fileName: '' }]
            : []
        }
        onRemove={() =>
          setValues({ ...values, previewCompanyLogo: null, companyLogo: null })
        }
        onDropFile={(files) => handleChangeLogo(files)}
      />
    </FieldRow>
  </>
);

export default NonVctFields;
