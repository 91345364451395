import { Typography } from '@mui/material';
import downloadCertificationPdf from 'helpers/investor/downloadCertificationPdf';
import useInvestorCertification from 'hooks/data/investor/useInvestorCertification';
import CurrentCertificationResult from './CurrentCertificationResult';
import ExpiredCertificationResult from './ExpiredCertificationResult';
import RecordRow from 'components/RecordRow';
import { Column } from 'components/Layout';

type Props = {
  investorDetail: {
    _id: string;
    fullName: string;
  };
};

const InvestorCertification: React.FC<Props> = ({ investorDetail }) => {
  const { certification, deleteCertification, resetCertification } =
    useInvestorCertification(investorDetail._id);

  const investorCertification = certification?.data ?? [];

  const currentCertifications = investorCertification.filter(
    ({ forceExpired }) => !forceExpired,
  );
  const expiredCertifications = investorCertification.filter(
    ({ forceExpired }) => forceExpired,
  );

  return (
    <>
      <RecordRow label="Investor self-certification">
        {!investorCertification.length ? (
          'N/A'
        ) : (
          <Column spacing="xs">
            {!currentCertifications.length &&
              !!expiredCertifications.length && (
                <span>Re-certification requested</span>
              )}
            {currentCertifications.map((certification) => (
              <CurrentCertificationResult
                key={certification._id}
                certification={certification}
                isResetting={resetCertification.isPending}
                onReset={() => resetCertification.mutate()}
                onDownload={(certification) =>
                  downloadCertificationPdf(
                    investorDetail.fullName,
                    certification,
                  )
                }
              />
            ))}
            {!!expiredCertifications.length && (
              <Typography component="i" variant="caption">
                Previous results
              </Typography>
            )}
            {expiredCertifications.map((certification) => (
              <ExpiredCertificationResult
                key={certification._id}
                certification={certification}
                isDeleting={deleteCertification.isPending}
                onDelete={(certificationId) =>
                  deleteCertification.mutate(certificationId)
                }
                onDownload={(certification) =>
                  downloadCertificationPdf(
                    investorDetail.fullName,
                    certification,
                  )
                }
              />
            ))}
          </Column>
        )}
      </RecordRow>
    </>
  );
};

export default InvestorCertification;
