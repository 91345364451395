import FieldRow from 'components/FormElements/FieldRow';
import { Button, TextField } from '@mui/material';
import CkEditor from 'components/CkEditor';
import { AppSelectBox } from 'components/FormElements';
import { z } from 'zod';
import { Control, Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import useStyles from '../Common/styles';
import { FC } from 'react';
import Spacing from 'components/Spacing';
import ButtonGroup from 'components/ButtonGroup';

const exitStatementSchema = z.object({
  title: z.string().min(1, 'Please enter a statement title.'),
  summary: z.string().min(1, 'Please enter a statement summary.'),
  letter: z.string().min(1, `Please enter a manager's letter.`),
  accountManager: z.object({
    name: z.string().min(1, `Please select a manager.`),
    _id: z.string(),
  }),
  role: z.string().min(1, `Please enter the manager's role.`),
  notes: z.string().optional(),
});

export type FormValues = Required<typeof exitStatementSchema>;
export type FormData = z.infer<FormValues>;

type Props = {
  onNext: (values: FormData) => void;
  defaultValues?: FormData;
  managers: { accountManagers: { _id: string; name: string }[] };
};

const FormTextField: FC<{
  name: string;
  placeholder: string;
  control: Control<FormData>;
  doubleLine?: boolean;
}> = ({ name, control, placeholder, doubleLine }) => {
  return (
    <Controller
      //@ts-expect-error
      name={name}
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
        <TextField
          {...rest}
          inputRef={ref}
          variant="outlined"
          placeholder={placeholder}
          fullWidth
          error={!!error}
          helperText={error?.message}
          multiline={doubleLine}
          minRows={doubleLine ? 2 : 1}
        />
      )}
    />
  );
};

const AddExitDetails = ({ defaultValues, onNext, managers }: Props) => {
  const { classes } = useStyles();

  const { handleSubmit, control, setValue } = useForm<FormData>({
    shouldFocusError: true,
    resolver: zodResolver(exitStatementSchema),
    criteriaMode: 'all',
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onNext)}>
      <Spacing>
        <FieldRow title="Statement title*" topTitle>
          <FormTextField
            name="title"
            placeholder="e.g. One of your companies, Company A, has sold to Acquirer A"
            control={control}
          />
        </FieldRow>
        <FieldRow
          title="Statement summary*"
          topTitle
          informationText="This is a two line preview of the statement that will show in each investor’s portal before they open the statement."
        >
          <FormTextField
            name="summary"
            placeholder="e.g. Company A has recently been acquired by Acquirer A. For full details on the exit, along with a breakdown of the exit financials, please click to see this exit statement."
            control={control}
            doubleLine
          />
        </FieldRow>
        <FieldRow title="Manager's letter*" topTitle>
          <Controller
            name="letter"
            control={control}
            render={({
              field: { value, name },
              fieldState: { error },
              formState: { isSubmitting },
            }) => (
              <div>
                <CkEditor
                  disabled={isSubmitting}
                  placeholder="A multi-paragraph cover letter, providing an overview of this exit."
                  onBlur={(_: unknown, editor: { getData: () => string }) => {
                    setValue(name, editor.getData());
                  }}
                  value={value}
                  onChange={undefined}
                />
                {error && <p className={classes.error}>{error.message}</p>}
              </div>
            )}
          />
        </FieldRow>
        <FieldRow title="Select manager*" topTitle>
          <Controller
            name="accountManager"
            control={control}
            render={({
              field: { value, name, ...rest },
              fieldState: { error },
            }) => (
              <AppSelectBox
                {...rest}
                data={managers?.accountManagers}
                valueKey="_id"
                labelKey="name"
                value={value}
                //@ts-expect-error
                renderValue={(value: { name: string }) => (
                  <>{value.name || 'Select manager'}</>
                )}
                variant="outlined"
                error={!!error}
                helperText={error?.message}
                onChange={(event) => {
                  const value = event.target.value as string;
                  setValue(
                    name,
                    //@ts-expect-error
                    managers?.accountManagers.find(
                      (manager) => manager._id === value,
                    ),
                  );
                }}
              />
            )}
          />
        </FieldRow>

        <FieldRow title="Manager's role*" topTitle>
          <FormTextField
            name="role"
            placeholder="e.g. Partner, Fund name"
            control={control}
          />
        </FieldRow>

        <FieldRow
          title="Notes"
          topTitle
          tooltipText={
            <>
              If this exit is a loss, but is subject to an escrow or earnout
              which may in future make it a gain, you may wish to include here
              guidance that the proceeds are subject to change during the escrow
              / earnout period (and outline the length of that period).
              <br />
              <br />
              You may wish to explain to investors that, if they claim any loss
              now, it may be subject to change and repayment of claimed loss
              relief in future.
              <br />
              <br />
              You may also wish to note that earnout (not escrow) gains that are
              either uncertain or unlikely at the time of initial exit do not
              attract EIS treatment when paid, and may be subject to capital
              gains tax. Tax advice should be sought in this situation.
            </>
          }
        >
          <Controller
            name="notes"
            control={control}
            render={({
              field: { value, name },
              formState: { isSubmitting },
            }) => (
              <CkEditor
                disabled={isSubmitting}
                placeholder="Use this box to add any notes under the metrics table, such as explanations of fees or other exit specifics. If this exit generated a cash return, you could use this space to encourage rollovers or point users to their cash balance page to process a withdrawal."
                onBlur={(_: unknown, editor: { getData: () => string }) => {
                  setValue(name, editor.getData());
                }}
                value={value}
                onChange={undefined}
                extraLine
              />
            )}
          />
        </FieldRow>
        <ButtonGroup>
          <Button type="submit" color="primary" variant="contained">
            Proceed to summary
          </Button>
        </ButtonGroup>
      </Spacing>
    </form>
  );
};
export default AddExitDetails;
