import React from 'react';
import { useParams } from 'react-router-dom';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import { useGetAdminUser } from 'hooks/data/adminUser/useAdminUser';
import AddUpdateUserForm from './AddUpdateUserForm';
import OrganisationMembershipsTable from './OrganisationMembershipsTable';
import { Divider } from '@mui/material';
import Spacing from 'components/Spacing';

const AddUpdateUser = () => {
  const { id } = useParams<{ id?: string }>();
  const adminUser = useGetAdminUser(id);

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'User', link: '/user' },
    {
      label: id ? 'Update User' : 'Add User',
      link: '/',
      isActive: true,
    },
  ];

  return (
    <React.Fragment>
      <PageContainer
        heading={id ? 'Update User' : 'Add User'}
        breadcrumbs={breadcrumbs}
        loading={!!(id && adminUser.isLoading)}
      >
        <PageContent>
          <Spacing>
            <AddUpdateUserForm id={id} defaultValues={adminUser.data} />
            {id && (
              <>
                <Divider />
                <OrganisationMembershipsTable adminUserId={id} />
              </>
            )}
          </Spacing>
        </PageContent>
      </PageContainer>
    </React.Fragment>
  );
};

export default AddUpdateUser;
