import { styled } from '@mui/material';
import React from 'react';
import api from 'lib/trpcClient';
import SectionContent from './SectionContent';
import { Schema as AccountSchema } from 'further-types/lp/account';
import Heading from 'components/Heading';
import { numberToCurrencyString } from 'further-ui/utils';
import { Row } from 'components/Layout';
import PageLoader from 'components/PageLoader';
import Spacing from 'components/Spacing';
import PageHeader from 'components/PageHeader';
import ResponsiveActions from 'components/PageHeader/ResposiveActions';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { createFundSetupRoute, FundSetupTab } from 'adminConstants/routes';
import PageFilters, { Filter } from 'components/PageFilters';
import useFilters from 'hooks/ui/useFilters';
import { AppDatePicker, FormLabel } from 'components/FormElements';
import SearchField from 'components/SearchField';
import { usePagination } from 'hooks/ui/usePagination';
import { useLayoutContext } from 'contexts/LayoutContext';

const { AccountSection } = AccountSchema;

const LedgerTotal = styled(Row)(({ theme }) => ({
  border: `1px solid ${theme.palette.borderColor?.dark}`,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(5, 9, 5, 2),
  width: '100%',
}));

const Label = styled(FormLabel)({
  flexBasis: 'auto',
  flexGrow: 0,
  flexShrink: 0,
});

const pageId = 'general-ledger';

const GeneralLedger: React.FC = () => {
  const { selectedVehicleId } = useLayoutContext();
  const { filters, handleChangeFilters } = useFilters(pageId, {
    defaultFilters: {
      date: new Date(),
      searchQuery: '',
    },
  });
  const pagination = usePagination({
    id: pageId,
    order: 'desc',
    orderBy: 'transactionDate',
  });

  const { data, isFetching } = api.generalLedger.listTransactions.useQuery({
    ...filters,
    orderBy: pagination.orderBy,
    order: pagination.order,
    vehicleId: selectedVehicleId ?? '',
  });

  const sections = data?.sections ?? [];

  if (isFetching) {
    return <PageLoader />;
  }

  return (
    <Spacing>
      <PageHeader>
        <Heading variant="h3" noMargin>
          General ledger
        </Heading>
        <ResponsiveActions>
          <Link to={createFundSetupRoute(FundSetupTab.ChartOfAccounts)}>
            <Button variant="outlined">Manage Chart of Accounts</Button>
          </Link>
          <Button variant="contained">Export</Button>
        </ResponsiveActions>
      </PageHeader>
      <PageFilters pageId={pageId}>
        <Filter>
          <Row centered spacing="sm">
            <Label title="Show as at date" />
            <AppDatePicker
              name="date"
              value={filters.date}
              onChange={(date) => {
                if (!date) {
                  return;
                }
                handleChangeFilters({ date });
              }}
              fullWidth
            />
          </Row>
        </Filter>
        <Filter>
          <SearchField
            autoSearchOnDebounce
            name="searchQuery"
            variant="standard"
            placeholder="Search by description, counterparty or author"
            fullWidth
            value={filters.searchQuery}
            onSearch={(searchQuery) => {
              handleChangeFilters({ searchQuery });
            }}
            allowEmptyQuery
            iconPlacement="left"
          />
        </Filter>
      </PageFilters>
      <div>
        <SectionContent
          sectionTitle="Assets"
          pagination={pagination}
          section={sections[AccountSection.Assets] ?? []}
          loading={isFetching}
        />
        <SectionContent
          sectionTitle="Payables"
          pagination={pagination}
          section={sections[AccountSection.Payables] ?? []}
          loading={isFetching}
        />
        <SectionContent
          sectionTitle="Partners' capital"
          pagination={pagination}
          section={sections[AccountSection.PartnersCapital] ?? []}
          loading={isFetching}
        />
        <SectionContent
          sectionTitle="Income"
          pagination={pagination}
          section={sections[AccountSection.Income] ?? []}
          loading={isFetching}
        />
        <SectionContent
          sectionTitle="Expenses"
          pagination={pagination}
          section={sections[AccountSection.Expenses] ?? []}
          loading={isFetching}
        />
        <LedgerTotal centered justify="space-between">
          <Heading variant="h3" noMargin>
            GL total
          </Heading>
          <Heading variant="h6" noMargin>
            GL total {numberToCurrencyString(data?.total ?? 0)}
          </Heading>
        </LedgerTotal>
      </div>
    </Spacing>
  );
};

export default GeneralLedger;
