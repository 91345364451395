import { investorTypeLabel, InvestorType } from 'further-types/investor';
import { dateToLabel } from 'further-ui/utils';
import { IconButton } from '@mui/material';
import { useDisclosure } from 'further-ui/hooks';
import { useGetRole } from 'hooks/ui/useGetRole';
import { CloudDownload, Delete } from '@mui/icons-material';
import AlertDialog from 'components/AlertDialog';
import { Row } from 'components/Layout';

type Props = {
  certification: {
    _id: string;
    investorType: InvestorType;
    certificationPerformedAt: string;
  };
  onDelete: (certificationId: string) => void;
  onDownload: (certification: Props['certification']) => void;
  isDeleting: boolean;
};

const ExpiredCertificationResult: React.FC<Props> = ({
  certification,
  onDelete,
  isDeleting,
  onDownload,
}) => {
  const { isSuperAdmin } = useGetRole();
  const deleteDisclosure = useDisclosure();

  return (
    <Row centered spacing="xs">
      <div>
        {investorTypeLabel[certification.investorType]}
        {` ${dateToLabel(
          certification.certificationPerformedAt,
          undefined,
          true,
        )}`}
      </div>
      <IconButton
        size="extra-small"
        title="Download"
        onClick={() => onDownload(certification)}
      >
        <CloudDownload color="primary" />
      </IconButton>
      {isSuperAdmin && (
        <IconButton
          size="extra-small"
          title="Download"
          disabled={isDeleting}
          onClick={() => deleteDisclosure.onOpen()}
        >
          <Delete color="error" />
        </IconButton>
      )}
      <AlertDialog
        onClose={deleteDisclosure.onClose}
        open={deleteDisclosure.isOpen}
        title="Warning"
        content="Are you sure you wish to delete this classification? This cannot be undone."
        btnLabels={{ confirm: 'Delete', cancel: 'Go back' }}
        confirmBtnProps={{
          variant: 'contained',
          color: 'primary',
          disabled: isDeleting,
        }}
        onConfirm={() => {
          onDelete(certification._id);
          deleteDisclosure.onClose();
        }}
      />
    </Row>
  );
};

export default ExpiredCertificationResult;
