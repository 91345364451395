import { useNotification } from './useNotification';
import useApiExceptionHandler from './useApiExceptionHandler';

export default function useApiRequestHandler() {
  const exceptionHandler = useApiExceptionHandler();
  const { success, error } = useNotification();

  const apiRequestHandler = async (
    apiRequestFunction: (
      showSuccess: (message: string) => void,
      showError: (message: string) => void,
    ) => Promise<void>,
    errorMessage?: string,
  ) => {
    try {
      await apiRequestFunction(success, error);
    } catch (error) {
      exceptionHandler(error as Error, errorMessage, !!errorMessage);
    }
  };

  return apiRequestHandler;
}
