import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
  styled,
} from '@mui/material';

const Spinner = styled(CircularProgress)<{ iconOnly?: boolean }>(
  ({ iconOnly }) => ({
    color: 'rgba(0, 0, 0, 0.26)',
    marginRight: iconOnly ? 0 : '10px',
  }),
);

interface Props extends ButtonProps {
  loading?: boolean;
  iconOnly?: boolean;
}

const Button: React.FC<Props> = ({
  loading,
  children,
  disabled,
  iconOnly,
  ...props
}) => {
  return (
    <MuiButton {...props} disabled={disabled || loading}>
      {loading ? <Spinner iconOnly={iconOnly} size={18} /> : null}
      {!loading || !iconOnly ? children : null}
    </MuiButton>
  );
};

export default Button;
