import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  BusinessCenter,
  Description,
  MonetizationOn,
  ViewQuilt,
} from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid2';
import InvestmentsByDate from 'components/DashboardWidgets/InvestmentsByDate';
import GridContainer from 'components/GridContainer';
import HoverInfoCard from 'components/HoverInfoCard';
import { numberToCurrencyString } from 'further-ui/utils';
import PageLoader from 'components/PageLoader';
import useOpenTrancheDashboard from 'hooks/data/dashboards/useOpenTrancheDashboard';
import {
  DASHBOARD_MY_FIRM,
  createSingleFirmRoute,
  createTrancheEditRoute,
} from 'adminConstants/routes';
import { InvestmentsByAdviserTable } from 'components/DashboardWidgets/InvestmentsByAdviserTable';
import { InvestmentsByLocation } from 'components/DashboardWidgets/InvestmentsByLocation';
import { InvestmentsByOrganisationTable } from 'components/DashboardWidgets/InvestmentsByOrganisationTable';
import { TrancheSnapshot } from 'components/DashboardWidgets/TrancheSnapshot';
import { RecentInvestmentsTable } from 'components/DashboardWidgets/RecentInvestmentsTable';
import { InvestmentsByTrancheTable } from 'components/DashboardWidgets/InvestmentsByTrancheTable';
import { useGetRole } from 'hooks/ui/useGetRole';
import PageContainer from 'components/PageContainer';

const useStyles = makeStyles()((theme) => ({
  scrollableTable: {
    maxHeight: '350px',
    marginBottom: '24px',
    paddingBottom: '0 !important',
    overflow: 'auto',
  },
  green: {
    color: theme.palette.text.rootColor,
  },
  divider: {
    margin: '0 24px',
  },
  gridGap1: {
    gridGap: '1rem',
  },
  dcont: {
    display: 'flex',
    flexDirection: 'column',
  },
  masth: {
    fontSize: '22px',
  },
  equalFlexSizing: {
    '& > *': {
      flex: 1,
    },
  },
  '@media screen and (max-width: 767px)': {
    masth: {
      fontSize: '18px',
    },
  },
}));

const OpenTrancheDashboard: React.FC = () => {
  const { firmId } = useGetRole();
  const { classes, cx } = useStyles();
  const { trancheId } = useParams<{ trancheId: string }>();

  const [recentInvestments, setRecentInvestments] = useState([]);
  const [incompleteInvestments, setIncompleteInvestments] = useState(0);
  const [trancheSnapshot, setTrancheSnapshot] = useState({});
  const [trancheDetails, setTrancheDetails] = useState<Record<string, any>>({});
  const [investmentsByAdviser, setInvestmentsByAdviser] = useState([]);
  const [investmentsByOrganisation, setInvestmentsByOrganisation] = useState(
    [],
  );
  const [investmentsByLocation, setInvestmentsByLocation] = useState({});
  const [initialFeesChargedToDate, setInitialFeesChargedToDate] = useState('0');
  const [investmentsByDate, setInvestmentsByDate] = useState([]);

  const openTrancheDashboard = useOpenTrancheDashboard({
    params: { trancheId },
  });

  useEffect(() => {
    const response = openTrancheDashboard.data;
    if (response) {
      setRecentInvestments(response?.recentInvestments);
      setInvestmentsByDate(response?.investmentsByDate);
      setTrancheSnapshot(response?.trancheSnapshot);
      setTrancheDetails(response?.trancheSnapshot.trancheDetails);
      setInvestmentsByAdviser(response?.investmentsByAdviser);
      setInvestmentsByOrganisation(response?.investmentsByOrganisation);
      setIncompleteInvestments(response?.incompleteInvestments);
      setInvestmentsByLocation(response?.investmentsByLocation);
      setInitialFeesChargedToDate(
        numberToCurrencyString(response?.initialFeesChargedToDate.feeExVAT),
      );
    }
  }, [openTrancheDashboard.data]);

  if (openTrancheDashboard.isFetching) return <PageLoader />;

  return (
    <PageContainer
      heading={`${trancheDetails?.firmId?.firmName}'s ${trancheDetails?.fundName} dashboard`}
    >
      <GridContainer spacing={5}>
        <Grid size={12}>
          <InvestmentsByDate data={investmentsByDate} />
        </Grid>
        <Grid
          size={{
            md: 6,
            xs: 12,
          }}
        >
          <TrancheSnapshot
            trancheSnapshot={trancheSnapshot}
            trancheDetails={trancheDetails}
            trancheId={trancheId}
            classes={classes}
          />
        </Grid>
        <Grid
          size={{
            md: 6,
            xs: 12,
          }}
        >
          <RecentInvestmentsTable
            rows={recentInvestments}
            trancheId={trancheId}
          />
        </Grid>
        <Grid size={12}>
          <InvestmentsByTrancheTable trancheId={trancheId} classes={classes} />
        </Grid>
        <Grid
          size={{
            md: 8,
            xs: 12,
          }}
        >
          <InvestmentsByAdviserTable
            rows={investmentsByAdviser}
            classes={classes}
          />
        </Grid>
        <Grid
          size={{
            md: 4,
            xs: 12,
          }}
        >
          <InvestmentsByLocation investmentData={investmentsByLocation} />
        </Grid>
        <Grid
          className={cx(
            classes.dcont,
            classes.gridGap1,
            classes.equalFlexSizing,
          )}
          size={{
            xs: 12,
            md: 4,
          }}
        >
          <HoverInfoCard
            icon={<BusinessCenter style={{ color: '#ffffff' }} />}
            backgroundColor="#56B26C"
            title={incompleteInvestments}
            subTitle="Incomplete subscriptions"
            linkOnArrow={`/incomplete-transaction?fundId=${trancheId}`}
          />
          <HoverInfoCard
            icon={<MonetizationOn style={{ color: '#ffffff' }} />}
            backgroundColor="#ECC9A2"
            title={initialFeesChargedToDate}
            subTitle="Initial fees due so far"
          />
        </Grid>
        <Grid
          size={{
            md: 8,
            xs: 12,
          }}
        >
          <InvestmentsByOrganisationTable
            rows={investmentsByOrganisation}
            classes={classes}
          />
        </Grid>
        <Grid
          size={{
            md: 6,
            xs: 12,
          }}
        >
          <HoverInfoCard
            icon={<Description style={{ color: '#ffffff' }} />}
            backgroundColor="#ECC9A2"
            subTitle="Edit tranche"
            linkOnArrow={createTrancheEditRoute(trancheId)}
          />
        </Grid>
        <Grid
          size={{
            md: 6,
            xs: 12,
          }}
        >
          <HoverInfoCard
            icon={<ViewQuilt style={{ color: '#ffffff' }} />}
            backgroundColor="#56B26C"
            subTitle="View firm dashboard"
            linkOnArrow={
              firmId
                ? DASHBOARD_MY_FIRM
                : createSingleFirmRoute(trancheDetails?.firmId?._id)
            }
          />
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default OpenTrancheDashboard;
