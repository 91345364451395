import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

export function useFundFullyDeployed(params) {
  const queryClient = useQueryClient();

  const fullyDeployedLog = useQuery({
    queryKey: ['fund-fully-deployed-logs', params.fundId],
    queryFn: async () => {
      const { data: response } = await api.get(
        `fund/fully-deployed-logs/${params.fundId}`,
      );
      return response?.data;
    },
    enabled: !!params.fundId,
  });

  const fullyDeployFund = useMutation({
    mutationFn: async () => {
      const { data: response } = await api.post(
        `fund/fully-deploy/${params.fundId}`,
      );
      return response;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['fund-fully-deployed-logs'],
      });
    },
  });

  const undoFundDeployment = useMutation({
    mutationFn: async () => {
      const { data: response } = await api.post(
        `fund/undo-fund-deployment/${params.fundId}`,
      );
      return response;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['fund-fully-deployed-logs'],
      });
    },
  });

  return {
    fullyDeployedLog,
    fullyDeployFund,
    undoFundDeployment,
  };
}
