import { Switch, styled } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';
import { forwardRef } from 'react';

const Container = styled('span')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

type BaseFieldSwitchProps = {
  checkedLabel: string;
  uncheckedLabel: string;
  checked: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type ControlledFieldSwitchProps = BaseFieldSwitchProps & {
  isControlled: true;
} & Omit<ControllerRenderProps, 'value'>;

type UncontrolledFieldSwitchProps = BaseFieldSwitchProps & {
  isControlled?: false;
};

type FieldSwitchProps =
  | ControlledFieldSwitchProps
  | UncontrolledFieldSwitchProps;

const FieldSwitch = forwardRef<HTMLDivElement, FieldSwitchProps>(
  (
    {
      checkedLabel,
      uncheckedLabel,
      checked,
      onChange,
      isControlled = true,
      ...rest
    },
    ref,
  ) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
    };

    return (
      <Container>
        <span>{uncheckedLabel}</span>
        <Switch
          {...(isControlled ? rest : {})}
          inputRef={isControlled ? ref : undefined}
          checked={checked}
          onChange={handleChange}
        />
        <span>{checkedLabel}</span>
      </Container>
    );
  },
);

FieldSwitch.displayName = 'FieldSwitch';

export default FieldSwitch;
