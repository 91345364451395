import { FC } from 'react';
import { useDelegatedAccess } from 'hooks/data/delegatedAccess/useDelegatedAccess';
import { Switch } from '@mui/material';

type Props = {
  copyIntoSystemEmails: boolean;
  delegatedAccessId: string;
};

const EditDelegatedAccessUserEmail: FC<Props> = ({
  copyIntoSystemEmails,
  delegatedAccessId,
}) => {
  const { edit } = useDelegatedAccess();

  const handleChange = async () => {
    edit.mutate({
      delegatedAccessId,
      copyIntoSystemEmails: !copyIntoSystemEmails,
    });
  };

  return (
    <Switch
      id={delegatedAccessId}
      key={delegatedAccessId}
      checked={copyIntoSystemEmails}
      disabled={edit.isPending}
      onChange={handleChange}
      color="primary"
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  );
};

export default EditDelegatedAccessUserEmail;
