import { IconButton, Button } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Table from 'components/Table';
import { Api } from 'further-types/adviser';
import {
  numberToCurrencyString,
  dateToLabel,
  renderMultiple,
} from 'further-ui/utils';
import { createInvestmentEditRoute } from 'adminConstants/routes';
import { EIS3Download } from 'components/SVGIcons';
import { sumBy } from 'lodash';
import { makeStyles } from 'tss-react/mui';

type PaginationState = {
  page: number;
  rowsPerPage: number;
  order: 'asc' | 'desc';
  orderBy: string;
  handleChangePage: (event: unknown, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    columnKey: string,
  ) => void;
};

type AdviserInvestmentsTableProps = {
  subscriptions: Api.AdviserSubscription[];
  totalInvestments: number;
  pagination: PaginationState;
  loading: boolean;
  onDownloadCertificates: (investment: Api.AdviserSubscription) => void;
  isSuperAdmin: boolean;
};

const useStyles = makeStyles()((theme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  dFlex: {
    display: 'flex',
  },
  trancheCell: {
    minWidth: '200px',
  },
  tableFooter: {
    backgroundColor: '#FBFBFA',
  },
  footerCell: {
    padding: '16px 8px',
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  totalCell: {
    textAlign: 'left',
  },
}));

const AdviserInvestmentsTable = ({
  subscriptions,
  totalInvestments,
  pagination,
  loading,
  onDownloadCertificates,
  isSuperAdmin,
}: AdviserInvestmentsTableProps) => {
  const { classes } = useStyles();

  const columns = [
    ...(isSuperAdmin
      ? [
          {
            label: 'Firm',
            render: (elm: any) => elm?.firmId?.firmName,
            key: 'firmId.firmName',
          },
        ]
      : []),
    {
      label: 'Investor',
      key: 'fullName',
    },
    {
      label: 'Tranche',
      key: 'fundName',
      sort: false,
      render: (subscription: Api.AdviserSubscription) =>
        subscription.fundId?.fundName,
      className: classes.trancheCell,
    },
    {
      label: 'External subscription ID',
      key: 'externalInvestmentId',
      sort: false,
      render: (subscription: Api.AdviserSubscription) =>
        subscription.externalInvestmentId,
    },
    {
      label: 'Subscription date',
      key: 'investmentDate',
      render: (subscription: Api.AdviserSubscription) =>
        dateToLabel(subscription.investmentDate),
    },
    {
      label: 'Advice fee',
      key: 'adviserFee',
      render: (subscription: Api.AdviserSubscription) =>
        numberToCurrencyString(subscription.adviserFee),
    },
    {
      label: 'Subscription amount',
      key: 'investmentAmount',
      render: (subscription: Api.AdviserSubscription) =>
        numberToCurrencyString(subscription.investmentAmount),
    },
    {
      label: 'Uninvested capital',
      key: 'investmentBalancePropertyCache.uninvestedCapital',
      render: (subscription: Api.AdviserSubscription) =>
        numberToCurrencyString(
          subscription.investmentBalancePropertyCache.unrealisedValue
            .heldByFundAwaitingInvestment ?? 0,
        ),
    },
    {
      label: 'Total current value',
      key: 'investmentBalancePropertyCache.investmentValue',
      render: (subscription: Api.AdviserSubscription) =>
        numberToCurrencyString(
          subscription.investmentBalancePropertyCache?.investmentValue ?? 0,
        ),
    },
    {
      label: 'Net realised value',
      key: 'investmentBalancePropertyCache.realisedValue.total',
      render: (subscription: Api.AdviserSubscription) =>
        numberToCurrencyString(
          subscription.investmentBalancePropertyCache?.realisedValue.total ?? 0,
        ),
    },
    {
      label: 'Multiple on investment',
      key: 'investmentBalancePropertyCache.multipleOnInvestment',
      render: (subscription: Api.AdviserSubscription) =>
        renderMultiple(
          subscription.investmentBalancePropertyCache
            ?.valueChangesOnInitialInvestment?.multipleOnInvestment ?? 0,
        ),
    },
    {
      label: 'Actions',
      key: 'actions',
      render: (elm: Api.AdviserSubscription) => (
        <div className={classes.dFlex}>
          <Link to={createInvestmentEditRoute(elm._id)}>
            <IconButton color="primary">
              <Edit />
            </IconButton>
          </Link>
          <Button
            title="Download all EIS certificates for this subscription"
            className={classes.customButton}
            onClick={() => onDownloadCertificates(elm)}
          >
            <EIS3Download />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      tablebody={subscriptions}
      count={totalInvestments}
      pagination
      onPageChange={pagination.handleChangePage}
      onRowsPerPageChange={pagination.handleChangeRowsPerPage}
      onRequestSort={pagination.handleRequestSort}
      page={pagination.page}
      rowsPerPage={pagination.rowsPerPage}
      loading={loading}
      emptyMessage={"This adviser doesn't have any linked subscriptions yet."}
      order={pagination.order}
      orderBy={pagination.orderBy}
      TableFooter={
        subscriptions?.length ? (
          <AdviserInvestmentsTableFooter subscriptions={subscriptions} />
        ) : null
      }
    />
  );
};

type AdviserInvestmentsTableFooterProps = {
  subscriptions: Api.AdviserSubscription[];
};

const AdviserInvestmentsTableFooter = ({
  subscriptions,
}: AdviserInvestmentsTableFooterProps) => {
  const { classes } = useStyles();

  return (
    <tfoot>
      <tr className={classes.tableFooter}>
        <td colSpan={5} />
        <td className={classes.footerCell}>
          {numberToCurrencyString(sumBy(subscriptions, 'adviserFee'))}
        </td>
        <td className={classes.footerCell}>
          {numberToCurrencyString(
            sumBy(subscriptions, (s) => s.investmentAmount ?? 0),
          )}
        </td>
        <td className={classes.footerCell}>
          {numberToCurrencyString(
            sumBy(
              subscriptions,
              (s) =>
                s.investmentBalancePropertyCache?.unrealisedValue
                  .heldByFundAwaitingInvestment ?? 0,
            ),
          )}
        </td>
        <td className={classes.footerCell}>
          {numberToCurrencyString(
            sumBy(
              subscriptions,
              (s) => s.investmentBalancePropertyCache?.investmentValue ?? 0,
            ),
          )}
        </td>
        <td colSpan={2} />
      </tr>
    </tfoot>
  );
};

export default AdviserInvestmentsTable;
