import { useState } from 'react';
import { isEqual, pick } from 'lodash';
import { isValid, parseISO } from 'date-fns';

interface IFilters {
  [key: string]: any;
}

export const FILTERS_KEY_SUFFIX = '-filters';

function useFilters<T extends IFilters>(
  filtersId: string,
  options: {
    defaultFilters?: T;
    onFiltersChange?: (x?: Partial<T>) => void;
  },
) {
  const [filters, setFilters] = useState<T>(() => {
    const serializedFilters = localStorage.getItem(
      `${filtersId}${FILTERS_KEY_SUFFIX}`,
    );
    const savedFilters = serializedFilters
      ? JSON.parse(serializedFilters, (_, value) => {
          if (value && isValid(parseISO(value))) {
            return new Date(value);
          }
          return value;
        })
      : null;

    return savedFilters ?? options?.defaultFilters ?? {};
  });

  const handleChangeFilters = (changedFilters: Partial<T>) => {
    const updatedKeys = Object.keys(changedFilters);
    const previousFilters = pick(filters, updatedKeys);
    const changeFiltersAreDifferent = !isEqual(changedFilters, previousFilters);

    const updatedFilters = {
      ...filters,
      ...changedFilters,
    };
    setFilters(updatedFilters);

    if (changeFiltersAreDifferent) {
      options?.onFiltersChange?.(updatedFilters);
    }

    localStorage.setItem(
      `${filtersId}${FILTERS_KEY_SUFFIX}`,
      JSON.stringify(updatedFilters),
    );
  };

  return {
    filters,
    handleChangeFilters,
  };
}

export default useFilters;
