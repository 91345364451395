import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import TextField from 'components/FormElements/AppTextInput';
import PageContainer from 'components/PageContainer';
import FieldRow from 'components/FormElements/FieldRow';
import { useOrganisationUser } from 'hooks/data/organisation/useOrganisationUsers';
import { useFetchOrganisation } from 'hooks/data/organisation/useOrganisation';
import { useGetRole } from 'hooks/ui/useGetRole';
import { createOrganisationViewRoute } from 'adminConstants/routes';
import { Button } from '@mui/material';
import { isValidEmail } from 'further-ui/utils';
import PageContent from 'components/PageContent';
import RecordView from 'components/RecordView';
import ButtonGroup from 'components/ButtonGroup';

type FormErrors = {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
};

const AddOrganisationUser: FC = () => {
  const { organisationId } = useParams<{ organisationId: string }>();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errors, setErrors] = useState<FormErrors>({});
  const { isSuperAdmin } = useGetRole();
  const history = useHistory();

  const { data, isFetching } = useFetchOrganisation({
    id: organisationId,
  });
  const { create } = useOrganisationUser({
    organisationId,
  });

  const onSubmit = () => {
    const errors: FormErrors = {};

    if (!email) {
      errors.email = 'Email is required';
    } else if (!isValidEmail(email)) {
      errors.email = 'Please enter a valid email';
    }
    if (!firstName) {
      errors.firstName = 'First name is required';
    }
    if (!lastName) {
      errors.lastName = 'Last name is required';
    }

    setErrors(errors);
    if (Object.values(errors).length > 0) {
      return;
    }

    create.mutate({
      organisationId,
      email,
      firstName,
      lastName,
    });
  };

  useEffect(() => {
    if (create.isSuccess) {
      history.push(createOrganisationViewRoute(organisationId));
    }
  }, [create.isSuccess]);

  const breadcrumbs = [
    { label: 'Dashboard' },
    {
      label: 'Organisation',
      link: createOrganisationViewRoute(organisationId),
    },
    {
      label: 'Add organisation user',
      link: '/',
      isActive: true,
    },
  ];

  if (isFetching) {
    return null;
  }

  return (
    <React.Fragment>
      <PageContainer
        heading="Organisation: Create organisation user"
        breadcrumbs={breadcrumbs}
      >
        <PageContent>
          <RecordView>
            <FieldRow title="Organisation" centerTitle>
              <TextField
                name="orgName"
                fullWidth
                disabled
                value={data?.organisation?.name}
              />
            </FieldRow>
            <FieldRow title="Email*" centerTitle>
              <TextField
                required
                name="email"
                fullWidth
                value={email}
                error={!!errors.email}
                helperText={errors.email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FieldRow>
            <FieldRow title="First name*" centerTitle>
              <TextField
                required
                name="firstName"
                fullWidth
                value={firstName}
                error={!!errors?.firstName}
                helperText={errors?.firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </FieldRow>
            <FieldRow title="Last name*" centerTitle>
              <TextField
                required
                name="lastName"
                fullWidth
                value={lastName}
                error={!!errors?.lastName}
                helperText={errors?.lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </FieldRow>
            <ButtonGroup>
              {isSuperAdmin && (
                <Alert severity="info">
                  No email invitation will be sent because you are a super
                  admin.
                </Alert>
              )}
              <Button
                onClick={onSubmit}
                variant="contained"
                disabled={create.isPending}
                color="primary"
              >
                {isSuperAdmin
                  ? 'Add new user'
                  : 'Add new user and send invitation'}
              </Button>
            </ButtonGroup>
          </RecordView>
        </PageContent>
      </PageContainer>
    </React.Fragment>
  );
};

export default AddOrganisationUser;
