import { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import FieldRow from 'components/FormElements/FieldRow';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import TranchesMultiSelect from 'components/TrancheMultiSelect';
import GridContainer from 'components/GridContainer';
import { createSpreadsheetByTranchesAndDate } from 'services/api/interestPayment';
import { useNotification } from 'hooks/ui/useNotification';
import downloadFile from 'utils/downloadFile';
import { fundStatusMap } from 'adminConstants/fundProperties';
import { useFunds } from 'hooks/data/fund/useFunds';
import Spacing from 'components/Spacing';

const useStyles = makeStyles()((theme) => ({
  error: {
    color: theme.palette.error.main,
    padding: '5px 0 0 14px',
  },
  buttonRow: {
    display: 'flex',
    gridGap: '0.3rem',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const DownloadSheet = ({ callback, skipCallback = null }) => {
  const { classes } = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState<{
    selectedFirms: string[];
    selectedTrancheIds: string[];
    interestPaymentsDate;
  }>({
    selectedFirms: [],
    selectedTrancheIds: [],
    interestPaymentsDate: new Date(),
  });

  const { error } = useNotification();

  const {
    tranches: { data },
  } = useFunds({ fundStatus: [fundStatusMap.open, fundStatusMap.closed] });

  const tranchesList =
    data
      ?.map((tranche) => {
        return {
          ...tranche,
          label: `${tranche.firmId.firmName} ${tranche.fundName}`,
        };
      })
      ?.sort((item) => item.label?.toLowerCase()) ?? [];

  const handleChangeDate = (date) => {
    setFormState({ ...formState, interestPaymentsDate: date });
  };

  const handleChangeSelectedTranches = (trancheIds) => {
    const selectedFirms = new Set<string>([]);
    trancheIds.forEach((trancheId) => {
      const tranche = data.find((tranche) => tranche._id === trancheId);
      if (tranche) {
        selectedFirms.add(tranche.firmId.firmName);
      }
    });

    setFormState({
      ...formState,
      selectedTrancheIds: trancheIds,
      selectedFirms: Array.from(selectedFirms),
    });
  };

  const handleDownloadButtonClick = async () => {
    try {
      setIsLoading(true);
      const response = await createSpreadsheetByTranchesAndDate(
        formState.selectedTrancheIds,
        formState.interestPaymentsDate,
      );
      if (response.status === 200) {
        downloadFile(
          response.data,
          `InterestPayments-upload-sheet-${Date.now()}.csv`,
        );
        callback(formState);
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      error('No data found.');
    }
  };
  return (
    <Spacing>
      <FieldRow key="1" title="Tranche(s)*">
        {/* @ts-expect-error props not optional */}
        <TranchesMultiSelect
          tranches={tranchesList}
          handleChangeSelectedTranches={handleChangeSelectedTranches}
          disabled={false}
        />
        {formState.selectedFirms.length > 1 && (
          <p className={classes.error}>
            Please note: you have selected tranches from two or more firms (
            {formState.selectedFirms.join(', ')}). Please select tranches
            relating to only one firm to continue.
          </p>
        )}
      </FieldRow>
      <FieldRow key="3" title="Date of interest payment*">
        <AppDatePicker
          name="interestPaymentsDate"
          value={formState.interestPaymentsDate}
          onChange={handleChangeDate}
          disableFuture={true}
        />
      </FieldRow>
      <FieldRow key="4">
        {skipCallback && (
          <GridContainer className={classes.buttonRow} spacing={0}>
            <Button variant="text" color="primary" onClick={skipCallback}>
              Skip
            </Button>
            {isLoading && <CircularProgress size={18} />}
            <Button
              variant="contained"
              color="primary"
              disabled={
                !formState.selectedTrancheIds.length ||
                !formState.interestPaymentsDate ||
                formState.selectedFirms.length > 1 ||
                isLoading
              }
              onClick={() => handleDownloadButtonClick()}
            >
              Create
            </Button>
          </GridContainer>
        )}
      </FieldRow>
    </Spacing>
  );
};

export default DownloadSheet;
