import React from 'react';
import {
  Radio,
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioProps,
  FormControlLabelProps,
} from '@mui/material';

interface AppRadioButtonProps
  extends RadioProps,
    Pick<FormControlLabelProps, 'label' | 'labelPlacement'> {
  error?: boolean;
  helperText?: string;
  type?: string;
}

const AppRadioButton: React.FC<AppRadioButtonProps> = ({
  name,
  id,
  value,
  label,
  onChange,
  required,
  error,
  helperText,
  checked = false,
  labelPlacement = 'end',
  color = 'primary',
  type = 'text',
  ...rest
}) => {
  return (
    <FormControl
      required={required}
      error={error}
      component="fieldset"
      type={type}
      {...rest}
    >
      <FormControlLabel
        value={value}
        control={
          <Radio
            checked={checked}
            name={name}
            id={id || name}
            onChange={onChange}
            color={color}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
      <FormHelperText error={error || helperText !== ''}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default AppRadioButton;
