import { createRoot } from 'react-dom/client';
import App from './App';
import 'react-app-polyfill/stable';
import { initSentry } from 'utils/tracking';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY ?? '');
initSentry();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
