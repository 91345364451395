import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import qs from 'qs';

async function fetchLifetimePerformanceSummary({ queryKey }) {
  const [, firmId, tagIds] = queryKey;
  const queryParams = qs.stringify({
    ...(firmId && { firmId }),
    ...(tagIds && { tagIds }),
  });

  const url =
    firmId || tagIds
      ? `dashboard/lifetime-performance-summary?${queryParams}`
      : `dashboard/lifetime-performance-summary`;

  const { data } = await api.get(url);

  return data.data;
}

function useLifetimePerformanceSummary({ firmId, tagIds }) {
  return useQuery({
    queryKey: ['lifetime-performance-summary', firmId, tagIds],
    queryFn: fetchLifetimePerformanceSummary,
    placeholderData: keepPreviousData,
    staleTime: 4 * 60 * 60 * 1000,
  });
}

export default useLifetimePerformanceSummary;
