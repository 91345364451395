import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import Chip from 'components/Chip';
import { IconButton, Tooltip, CardContent, Card } from '@mui/material';
import Table from 'components/Table';
import { usePagination } from 'hooks/ui/usePagination';
import { InfoOutlined } from '@mui/icons-material';
import { useInvestments } from 'hooks/data/investment/useInvestments';
import Heading from 'components/Heading';
import { Row } from 'components/Layout';

export const InvestmentsByTrancheTable = ({ trancheId, classes }) => {
  const pagination = usePagination();

  const investmentsQuery = useInvestments({
    params: {
      page: pagination.page,
      perPage: pagination.rowsPerPage,
      type: pagination.order,
      keyName: pagination.orderBy,
      fundId: trancheId,
    },
  });

  const columns = [
    {
      label: 'Investor name',
      key: 'fullName',
    },
    {
      label: 'Email',
      key: 'email',
      render: (elm) => elm?.investorId?.email,
    },
    {
      label: 'Subscription',
      key: 'investmentAmount',
      render: (elm) => numberToCurrencyString(elm?.investmentAmount),
    },
    {
      label: (
        <Row spacing="xs" centered>
          <span>Total current value</span>
          <Tooltip
            title={
              <>
                This is the combined value of all current holdings, exits and
                uninvested paid in capital. Where there are unpaid commitments,
                this figure may be materially lower than subscribed investment
                capital.
              </>
            }
          >
            <IconButton size="small">
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        </Row>
      ),
      key: 'investmentValue',
      render: (elm) =>
        numberToCurrencyString(elm?.investmentBalance?.investmentValue),
    },
    {
      label: 'Payment status',
      key: 'isPaymentReceived',
      render: (elm) => (
        <>
          {elm?.isFullyFunded ? (
            <Chip small variant="success">
              Fully funded
            </Chip>
          ) : elm?.isPartiallyFunded ? (
            <Chip small variant="disabled">
              Partially funded
            </Chip>
          ) : (
            <Chip small variant="disabled">
              No payment
            </Chip>
          )}
        </>
      ),
    },
    {
      label: 'Adviser',
      key: 'adviser',
      render: (elm) => elm.adviserId?.fullName || '-',
    },
    {
      label: 'Subscription ID',
      key: 'externalInvestmentId',
      render: (elm) => elm?.externalInvestmentId || '-',
    },
    {
      label: 'Subscription date',
      key: 'investmentDate',
      render: (elm) => dateToLabel(elm?.investmentDate),
    },
  ];

  return (
    <Card>
      <CardContent className={classes.scrollableTable}>
        <Heading variant="h3" showDivider>
          All Subscriptions
        </Heading>
        <Table
          columns={columns}
          tablebody={investmentsQuery?.data?.result ?? []}
          order={pagination.order}
          orderBy={pagination.orderBy}
          onRequestSort={pagination.handleRequestSort}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          count={investmentsQuery?.data?.total ?? 0}
          pagination={true}
          loading={investmentsQuery.isFetching}
          emptyMessage="No fund subscriptions yet"
          variant="nohover"
        />
      </CardContent>
    </Card>
  );
};
