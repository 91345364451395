import { AxiosError } from 'axios';
import { useNotification } from './useNotification';
import { ApiResponse } from 'further-types/api';

const isAxiosError = (error: Error): error is AxiosError<ApiResponse> => {
  return !!(error as AxiosError).isAxiosError;
};

export default function useApiExceptionHandler(
  defaultMessage = 'Something went wrong.',
) {
  const notification = useNotification();

  const exceptionHandler = (
    error: Error,
    defaultErrorMessage = defaultMessage,
    overrideApiErrorMessage = false,
  ) => {
    if (isAxiosError(error) && !overrideApiErrorMessage) {
      notification.error(
        error.response?.data.responseMsg ?? defaultErrorMessage,
      );
    } else {
      notification.error(defaultErrorMessage);
    }
  };

  return exceptionHandler;
}
