import { useDispatch } from 'react-redux';
import PageContainer from 'components/PageContainer';
import { downloadExits } from '@redux/actions/Exit';
import Table, { TableActions } from 'components/Table';
import {
  numberToCurrencyString,
  dateToLabel,
  getCompanyLabel,
} from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import { numberToDisplayString } from 'utils/numbers';
import { makeStyles } from 'tss-react/mui';
import { CloudDownload, Delete, Edit, Email } from '@mui/icons-material';
import { useGetRole } from 'hooks/ui/useGetRole';
import AlertDialog from 'components/AlertDialog';
import {
  EXITS_ADD,
  createExitEditRoute,
  createExitStatementAddRoute,
  createExitStatementPdfDownloadRoute,
} from 'adminConstants/routes';
import { useExit, useExits } from 'hooks/data/exit';
import CompanyListSelect from 'components/CompanyListSelect';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import { usePagination } from 'hooks/ui/usePagination';
import { useNotification } from 'hooks/ui/useNotification';
import useFilters from 'hooks/ui/useFilters';
import PageContent from 'components/PageContent';
import PageFilters, { Filter } from 'components/PageFilters';

const useStyles = makeStyles()(() => ({
  exitLogContainer: {
    marginBottom: '1rem',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    borderRadius: '4px',
  },
  cmTableDarkHeading: {
    '& th': {
      backgroundColor: '#f0f0f0',
    },
  },
}));

const ListExits = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const deleteDisclosure = useDisclosure();
  const { isSuperAdmin } = useGetRole();
  const notification = useNotification();

  const pagination = usePagination({
    id: 'exits-table',
    orderBy: 'date',
    order: 'desc',
  });

  const { filters, handleChangeFilters } = useFilters('exits-table', {
    onFiltersChange: pagination.toFirstPage,
    defaultFilters: {
      company: null,
      dateRange: {
        startDate: null,
        endDate: null,
      },
    },
  });

  const exits = useExits({
    params: {
      companyId: filters.company,
      page: pagination.page,
      perPage: pagination.rowsPerPage,
      startDate: filters.dateRange?.startDate
        ? dateToLabel(filters.dateRange.startDate)
        : null,
      endDate: filters.dateRange?.endDate
        ? dateToLabel(filters.dateRange.endDate)
        : null,
      order: pagination.order,
      orderBy: pagination.orderBy,
    },
  });

  const { deleteExit } = useExit({});

  const handleDownload = async (id) => {
    // @ts-expect-error dispatch to be removed soon
    await dispatch(downloadExits(id, notification));
  };

  const onDeleteSuccess = () => {
    deleteDisclosure.onClose();
  };

  const onDeleteExit = async () => {
    const exitId = deleteDisclosure.actionProps.id;
    const exitAmendmentId = deleteDisclosure.actionProps.exitAmendmentId;
    deleteExit.mutate(
      // @ts-expect-error - hook is not typed
      { exitId, exitAmendmentId, onDeleteSuccess },
    );
  };

  const columns = [
    ...(isSuperAdmin
      ? [
          {
            label: 'Firm',
            key: 'firmId',
            sort: false,
            render: (elm) => elm?.firmId?.firmName,
          },
        ]
      : []),
    {
      label: 'Company',
      key: 'companyTradingName',
      sort: false,
      render: (elm) => getCompanyLabel(elm.companyId),
    },
    {
      label: 'Received value',
      key: 'receivedValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.valueAfterDeductions),
    },
    {
      label: 'Taxable value',
      key: 'taxableValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.taxableValue),
    },
    {
      label: 'Accrued fees',
      key: 'accruedFeesCharged',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.accruedFeesCharged),
    },
    {
      label: 'Performance fees',
      key: 'performanceFeesCharged',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.performanceFeesCharged),
    },
    {
      label: 'First exit date',
      key: 'date',
      render: (elm) => dateToLabel(elm.date),
    },
    {
      label: 'Shares realised',
      key: 'noSharesExited',
      sort: false,
      render: (elm) => numberToDisplayString(elm.noSharesExited),
    },
    {
      label: 'Last edit date',
      key: 'lastEditDate',
      sort: false,
      render: (elm) =>
        elm.lastAmendmentDate ? dateToLabel(elm.lastAmendmentDate) : '-',
    },
    {
      label: 'Actions',
      key: 'actions',
      sort: false,
      render: (elm) => (
        <TableActions
          showAsDropdown
          actions={[
            {
              link: createExitEditRoute(elm._id),
              icon: Edit,
              label: 'Edit',
              color: 'primary',
            },
            {
              icon: CloudDownload,
              label: 'Download',
              color: 'primary',
              onClick: (e) => {
                handleDownload(elm._id);
                e.stopPropagation();
              },
            },
          ]}
        />
      ),
    },
  ];

  const collapsedTableColumns = [
    {
      label: 'Edit date',
      key: 'submitDate',
      sort: false,
      render: (elm) =>
        elm.date && !elm.isInitial ? dateToLabel(elm.date) : 'Original',
    },

    {
      label: 'Received value',
      key: 'receivedValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.valueAfterDeductions) || '-',
    },
    {
      label: 'Taxable value',
      key: 'taxableValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.taxableValue) || '-',
    },
    {
      label: 'Accrued fees',
      key: 'accruedFeesCharged',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.accruedFeesCharged) || '-',
    },
    {
      label: 'Performance fees',
      key: 'performanceFeesCharged',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm.performanceFeesCharged) || '-',
    },
    {
      label: 'Received value share price',
      key: 'receivedValueSharePrice',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm.sharePrice, {
          unlimitedDp: true,
        }),
    },
    {
      label: 'Taxable value share price',
      key: 'taxableValueSharePrice',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm.taxableValueSharePrice, {
          unlimitedDp: true,
        }),
    },
    {
      label: 'Actions',
      key: 'actions',
      sort: false,
      render: (elm) => (
        <TableActions
          showAsDropdown
          actions={[
            {
              label: 'Delete',
              disabled: elm?.deleteForbidden,
              icon: Delete,
              color: 'error',
              tooltip: elm?.deleteForbidden
                ? 'To delete this exit, subsequent edits must be deleted first.'
                : undefined,
              onClick: () =>
                deleteDisclosure.stageAction({
                  id: elm._id || elm.exitId,
                  exitAmendmentId: elm.exitAmendmentId,
                }),
            },
            {
              link: !elm?.exitStatementId
                ? createExitStatementAddRoute(elm.exitId, elm.exitAmendmentId)
                : createExitStatementPdfDownloadRoute(elm.exitStatementId),
              label: !elm?.exitStatementId
                ? 'Send exit statement'
                : 'View exit statement PDFs',
              icon: Email,
              color: !elm?.exitStatementId ? 'primary' : 'default',
            },
          ]}
        />
      ),
    },
  ];

  return (
    <PageContainer heading="Exits">
      <PageContent>
        <PageFilters
          pageId="exits-list"
          buttons={[
            {
              link: EXITS_ADD,
              label: 'Process new exit',
            },
          ]}
        >
          <Filter>
            <CompanyListSelect
              includeFirmNameInLabel={false}
              handleChangeCallback={(company) => {
                handleChangeFilters({
                  //@ts-expect-error
                  company,
                });
              }}
            />
          </Filter>
          <Filter>
            <MuiDateRangePicker
              dateRange={filters.dateRange}
              onDateRangeChange={(data) => {
                handleChangeFilters({
                  dateRange: data,
                });
              }}
              TextFieldProps={{ placeholder: 'Select date' }}
            />
          </Filter>
        </PageFilters>
        <Table
          columns={columns}
          tablebody={exits.data?.result || []}
          emptyMessage="No exits have been created yet"
          variant="nohover"
          loading={exits.isFetching}
          pagination={true}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          order={pagination.order}
          orderBy={pagination.orderBy}
          onRequestSort={pagination.handleRequestSort}
          count={exits.data?.total}
          collapsedContent={(row) => {
            return (
              <div
                className={`${classes.exitLogContainer} ${classes.cmTableDarkHeading}`}
              >
                <Table
                  columns={collapsedTableColumns}
                  tablebody={row.exitLog || []}
                />
              </div>
            );
          }}
        />
      </PageContent>
      <AlertDialog
        onClose={deleteDisclosure.onClose}
        open={deleteDisclosure.isOpen}
        title="Are you sure you wish to delete this exit?"
        content="This cannot be undone and all information relating to the exit will be lost."
        btnLabels={{ confirm: 'Delete exit', cancel: 'Go back' }}
        cancelBtnProps={{ variant: 'contained', color: 'primary' }}
        confirmBtnProps={{
          variant: 'outlined',
          color: 'primary',
          disabled: deleteExit?.isPending,
          isFetching: deleteExit?.isPending,
        }}
        onConfirm={onDeleteExit}
      />
    </PageContainer>
  );
};

export default ListExits;
