import { FC } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import FieldRow from 'components/FormElements/FieldRow';
import Dropzone from 'components/Dropzone';
import Spacing from 'components/Spacing';

type Props = {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  handleImageUpload: (
    files: File[],
    onSuccess: (url: string, filePath: string) => void,
  ) => Promise<void>;
  previewImages: {
    firmLogoPrimaryColorBg: string | null;
    firmLogoTransparentBg: string | null;
    firmLogoSquare: string | null;
  };
  setPreviewImages: (images: {
    firmLogoPrimaryColorBg: string | null;
    firmLogoTransparentBg: string | null;
    firmLogoSquare: string | null;
  }) => void;
};

const FirmLogos: FC<Props> = ({
  control,
  setValue,
  handleImageUpload,
  previewImages,
  setPreviewImages,
}) => {
  const onRemove = (key: string) => {
    setValue(key, null);
    setPreviewImages({
      ...previewImages,
      [key]: null,
    });
  };

  const onDropFile = (key: string) => async (files: File[]) => {
    await handleImageUpload(files, (url, filePath) => {
      setValue(key, filePath);
      setPreviewImages({
        ...previewImages,
        [key]: url,
      });
    });
  };

  const getPreviewFiles = (url?: string | null) => {
    return url ? [{ preview: url, fileName: '' }] : [];
  };

  return (
    <Spacing>
      <FieldRow title="Primary color background">
        <Controller
          name="firmLogoPrimaryColorBg"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <Dropzone
              {...rest}
              //@ts-expect-error
              ref={ref}
              onDropFile={onDropFile('firmLogoPrimaryColorBg')}
              files={getPreviewFiles(previewImages.firmLogoPrimaryColorBg)}
              onRemove={() => onRemove('firmLogoPrimaryColorBg')}
            />
          )}
        />
      </FieldRow>
      <FieldRow
        title="Transparent background"
        informationText="(Transparent/no background)"
      >
        <Controller
          name="firmLogoTransparentBg"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <Dropzone
              {...rest}
              //@ts-expect-error
              ref={ref}
              onDropFile={onDropFile('firmLogoTransparentBg')}
              files={getPreviewFiles(previewImages.firmLogoTransparentBg)}
              onRemove={() => onRemove('firmLogoTransparentBg')}
            />
          )}
        />
      </FieldRow>
      <FieldRow
        title="Square logo"
        informationText="(Square only, any colour background)"
      >
        <Controller
          name="firmLogoSquare"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <Dropzone
              {...rest}
              //@ts-expect-error
              ref={ref}
              onDropFile={onDropFile('firmLogoSquare')}
              files={getPreviewFiles(previewImages.firmLogoSquare)}
              onRemove={() => onRemove('firmLogoSquare')}
            />
          )}
        />
      </FieldRow>
    </Spacing>
  );
};

export default FirmLogos;
