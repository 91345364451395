import { useQuery, useMutation, keepPreviousData } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

async function fetchFees({ queryKey }) {
  const [, { params }] = queryKey;

  const { data } = await api.post('fee/list', params);

  return data.data;
}

const exportFees = async (params, exportType) => {
  const response = await api.post(
    'fee/export',
    {
      ...params,
      exportType,
    },
    {
      responseType: 'blob',
    },
  );

  if (response.status !== 200) {
    throw Error('Unexpected export response.');
  }
  return response.data;
};

export function useFees({ params }) {
  const { mutateAsync, isPending: isExportLoading } = useMutation({
    mutationFn: (exportType) => exportFees(params, exportType),
  });

  return {
    fees: useQuery({
      queryKey: ['fees', { params }],
      queryFn: fetchFees,
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
    }),
    exportFees: mutateAsync,
    isExportLoading,
  };
}
