import { FC, useState } from 'react';
import { Avatar, Menu, MenuItem, styled } from '@mui/material';
import { Realm } from 'further-types/admin-user';
import useSwitchOrganisation from 'hooks/auth/useSwitchOrganisation';

const AvatarWrapper = styled(Avatar)({
  width: '50px',
  height: '50px',
  borderStyle: 'solid',
  borderWidth: 4,
  borderColor: 'grey.200',
  cursor: 'pointer',
});

type Props = {
  orgs?: Array<{
    auth0OrgId?: string;
    firmName?: string;
    firmLogo?: string;
    color?: string;
  }>;
  firm?: {
    investmentFirmLogo?: string;
    firmName?: string;
  };
  realm?: Realm;
};

const OrganisationSwitcher: FC<Props> = ({ orgs, firm, realm }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const onSwitchOrganisation = useSwitchOrganisation();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getFallbackAvatarText = (firmName?: string) => {
    return firmName
      ? firmName
          .split(' ')
          .map((word) => word[0])
          .slice(0, 2)
          .join('')
      : '';
  };

  const superOrgLogo =
    'https://www.joinfurther.com/assets/images/invest-further-sq.jpg';

  if (!orgs?.length) return null;

  return (
    <>
      <AvatarWrapper
        src={realm === Realm.Super ? superOrgLogo : firm?.investmentFirmLogo}
        onClick={handleClick}
      >
        {realm == Realm.Super ? 'Fu' : getFallbackAvatarText(firm?.firmName)}
      </AvatarWrapper>
      <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
        {orgs?.map((org) => (
          <MenuItem
            key={org.auth0OrgId}
            onClick={() => onSwitchOrganisation(org)}
          >
            <Avatar
              sx={{ bgcolor: org.color, width: 32, height: 32, mr: 4 }}
              src={org.firmLogo}
            >
              {getFallbackAvatarText(org.firmName)}
            </Avatar>
            Switch to {org.firmName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default OrganisationSwitcher;
