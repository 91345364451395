import { InvestorType } from 'further-types/investor';

export const iconSelected =
  '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 50 50"><path d="M47.293 6.94L14 40.232 2.707 28.94l-1.414 1.413L14 43.06 48.707 8.353z"/></svg>';

export const qualificationCriteriaText = {
  [InvestorType.EverydayInvestor]:
    'Please confirm whether you qualify as a restricted investor on the basis that A and B apply to you.',
  [InvestorType.SophisticatedInvestor]:
    'Please confirm whether you qualify as a self-certified sophisticated investor on the basis that A, B, C, or D apply to you.\n\nIf you meet condition A, B, C or D below, you may choose to be classified as a self-certified sophisticated investor for the purposes of the Financial Services and Markets Act 2000 (Financial Promotion) Order 2005.\n\nIn the last two years have you:',
  [InvestorType.HighNetWorthInvestor]:
    'Please confirm whether you qualify as a high-net-worth investor on the basis that A or B apply to you.\n\nIf you meet condition A or B below, you may choose to be classified as a high net worth individual for the purposes of the Financial Services and Markets Act 2000 (Financial Promotion) Order 2005.\n\nIn the last financial year did you have:',
  [InvestorType.ProfessionalClient]:
    'Please confirm if two or more of the following criteria apply to you. If not, please tick the fourth box.',
};

export const footerText = {
  [InvestorType.SophisticatedInvestor]: [
    {
      text: 'I declare that I have answered yes to A and/or B and/or C and/or D and wish to be treated as a self-certified sophisticated investor. I understand that this means:\n\na) I can receive financial promotions where the content may not comply with rules made by the Financial Conduct Authority (FCA);\n\nb) I can expect no protection from the FCA, the Financial Ombudsman Service or the Financial Services Compensation Scheme.\n\nI am aware that it is open to me to seek advice from someone who specialises in advising on investments. I accept that I could lose all the money I invest.\n\nI also accept that being a self-certified sophisticated investor will expose me to promotions for investments where there is a significant risk of losing all the money I invest. I am aware that it is open to me seek advice from someone who specialises in advising on high risk investments including non-readily realisable securities and non-mainstream pooled investments.',
    },
  ],
  [InvestorType.HighNetWorthInvestor]: [
    {
      text: 'I declare that I have answered yes to A and/or B and wish to be treated as a high net worth individual. I understand that this means:\n\na) I can receive financial promotions where the content may not comply with rules made by the Financial Conduct Authority (FCA);\n\nb) I can expect no protection from the FCA, the Financial Ombudsman Service or the Financial Services Compensation Scheme.\n\nI am aware that it is open to me to seek advice from someone who specialises in advising on investments. I accept that I could lose all the money I invest.\n\nI also accept that being a high-net-worth investor will expose me to promotions for investment where there is a significant risk of losing all the money I invest. I am aware that it is open to me to seek professional advice before making any investment in a high-risk investment.',
    },
  ],
  [InvestorType.ProfessionalClient]: [
    {
      text: 'Loss of protections warning',
      bold: true,
    },
    {
      text: 'If you wish to elect to be treated as a professional client, please read the written warning below and confirm at the bottom of this page that you are aware of the consequences of your election.',
    },
    {
      text: 'Retail clients are entitled to certain protections under law (including the rules of the Financial Conduct Authority (the FCA Rules)) that you may not be entitled to as a professional client. Before you elect to be treated as a professional client, please be aware of the following differences in information and protections if you elect to be treated as a professional client:',
    },
    {
      ol: [
        {
          stack: [
            [
              { text: 'Financial promotions', bold: true },
              {
                text: 'FCA Rules relating to the form and content of financial promotions generally will not apply to you as an elective professional client. In particular, FCA Rules relating to restrictions on and the required contents of direct offer financial promotions do not apply to promotions issued to elective professional clients and such promotions need not contain sufficient information for you to make an informed assessment of the investment to which they relate, although they should still be clear, fair and not misleading.',
                marginBottom: 10,
              },
              'There is no requirement to give warnings to elective professional clients or seek to ensure that overseas firms whose services are promoted will treat elective professional clients in an honest and reliable way, nor is a regulated firm under any obligation to provide the same level of indication regarding past performance to elective professional clients as it would to retail clients.',
            ],
          ],
          marginBottom: 10,
        },
        {
          stack: [
            { text: 'Provision of information', bold: true },
            'CA Rules regarding disclosure of information to clients, including details about costs and associated charges and warnings to be given of the nature of any risks involved (both generally and in respect of a number of particular types of investment and investment activity) if transactions are recommended to, or carried out for, clients will not apply when you are treated as an elective professional client.',
          ],
          marginBottom: 10,
        },
        {
          stack: [
            { text: 'Confirmation of transactions to customers', bold: true },
            'As a consequence of your categorisation as an elective professional client, FCA Rules relating to obligations to provide you with confirmation of transactions will apply in modified form. In particular, provisions regarding extra reporting requirements for dealings with retail clients will not apply to elective professional clients. ',
          ],
          marginBottom: 10,
        },
        {
          stack: [
            { text: 'Periodic statements', bold: true },
            'FCA Rules relating to sending periodic statements will apply in a modified form. Provisions regarding extra reporting requirements for retail clients will not apply.',
          ],
          marginBottom: 10,
        },
        {
          stack: [
            { text: 'Financial Ombudsman Service', bold: true },
            'Access to the Financial Ombudsman Service will not apply to you as an elective professional client.',
          ],
          marginBottom: 10,
        },
        {
          stack: [
            { text: 'Financial Services Compensation Scheme', bold: true },
            'Any rights to claim compensation under the Financial Services Compensation Scheme will not apply to you as an elective professional client. ',
          ],
          marginBottom: 10,
        },
        {
          stack: [
            { text: 'Communications', bold: true },
            'Firms may have regard to your expertise as an elective professional client when complying with the requirements under the regulatory system that their communications be clear, fair and not misleading. Therefore, communications may not be as simple and frequent to clients that are not retail clients.',
          ],
          marginBottom: 10,
        },
        {
          stack: [
            { text: 'Understanding risk', bold: true },
            'FCA Rules requiring warnings to be given of the nature of any risks involved (both generally and in respect of a number of particular types of investment and investment activity) if transactions are recommended to, or carried out for, clients will not apply when you are treated as an elective professional client.',
          ],
          marginBottom: 10,
        },
        {
          stack: [
            {
              text: 'Disclosure of charges, remuneration and commission',
              bold: true,
            },
            {
              text: 'There will be no obligation under the FCA Rules to disclose in advance the basis and amount of the applicable charges or the amount of remuneration or commission or other income payable to firms or their associates for conducting the relevant business. ',
              marginBottom: 10,
            },
            'Particular restrictions on the remuneration structure may not be applicable to services provided to elective professional clients.',
          ],
          marginBottom: 10,
        },
        {
          stack: [
            { text: 'Suitability and appropriateness', bold: true },
            'Firms shall not be obliged to ensure the suitability and appropriateness of investments made. Firms are entitled to assume that you have the necessary level of experience, knowledge and expertise to understand the risks involved in a transaction.',
          ],
          marginBottom: 10,
        },
        {
          stack: [
            { text: 'Best execution', bold: true },
            'FCA Rules will not require firms to provide best execution to funds in which you invest providing certain procedural steps are followed.',
          ],
          marginBottom: 10,
        },
        {
          stack: [
            { text: 'Client money', bold: true },
            'FCA Rules relating to client money may be disapplied by agreement with you. In practice, many firms do not hold funds on your behalf (instead using regulated custodians) but, where they do, you will lose such protections.',
          ],
          marginBottom: 10,
        },
        {
          stack: [
            { text: 'Exclusion of liability', bold: true },
            'FCA Rules restrict the ability of regulated businesses to exclude or limit their liabilities to retail clients. As you are being categorised as an elective professional client, the FCA rules will not apply in the same way and firms will be able to restrict or exclude their liability to you.',
          ],
          marginBottom: 10,
        },
        {
          stack: [
            { text: 'FCA consumer duty', bold: true },
            'FCA rules relating consumer duty do not apply to you. In particular, the general principle that a firm must act to deliver good outcomes for retail clients, does not apply as you are being categorised as an elective professional client.',
          ],
        },
      ],
    },
    {
      text: 'If you have any doubts about the contents of the above warning, please seek legal advice before proceeding. The summary list set out above is not intended to be exhaustive or comprehensive nor is it intended to reflect the specific regulatory conduct of business obligations owed by specific firms to you.',
    },
    {
      style: 'table',
      layout: 'noBorders',
      table: {
        widths: ['5%', '95%'],
        body: [
          [
            {
              svg: iconSelected,
              width: 15,
              height: 15,
            },
            {
              text: 'I confirm that I have read and understood the above warning statement and agree that:',
              bold: true,
              marginBottom: 10,
            },
          ],
        ],
      },
    },
    {
      columns: [
        {
          width: '7%',
          text: '',
        },
        {
          width: '*',
          ul: [
            {
              text: 'I wish to be treated as an elective professional client generally for transactions available on or through this platform;',
              marginBottom: 10,
            },
            {
              text: 'I acknowledge the written warning above of the protections and investor compensation rights I may lose as a result of being treated as a professional client and I am aware of the consequences of losing such protections; ',

              marginBottom: 10,
            },
            {
              text: 'I do not require periodic statements or other reports in the format required by the FCA Rules, but only such reports as are to be provided to investors in accordance with any applicable opportunity’s investor agreement; and',
              marginBottom: 10,
            },
            'I wish to opt-out of the ‘client money’ rules set out in CASS 7 of the FCA Rules and acknowledge that any funds committed by me pursuant to an investment may not be subject to the protections conferred by such rules, may be used in the applicable firm’s general business, and I will rank only as a general creditor of a firm. ',
          ],
        },
      ],
    },
  ],
};
