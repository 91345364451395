import { compact, sortBy } from 'lodash';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'utils/pdfmakeVfsFonts';
import { numberToCurrencyString } from 'further-ui/utils';
import { InvestorCertificationQuestionType } from 'further-types/investor-certification';
import { investorTypeLabel, InvestorType } from 'further-types/investor';
import { ApiResponse } from 'further-types/api';
import { api } from 'lib/httpClient';
import {
  footerText,
  iconSelected,
  qualificationCriteriaText,
} from './documentElements';
import { format } from 'date-fns';

pdfMake.vfs = pdfFonts;

type CertificationDetails = {
  _id: string;
  investorType: InvestorType;
  certificationPerformedAt: string;
};

type CertificationQuestionAnswers = {
  investorCertificationQuestionId: {
    title?: string;
    subtitle?: string;
    option?: Array<{ value: number; label: string }>;
    type?: 'percentage' | 'number' | 'text';
    noPrefix?: boolean;
    questionType: InvestorCertificationQuestionType;
    order: number;
  };
  value?: number | string;
  optionSelected?: Array<number>;
};

const timestampFormat = 'dd/MM/yyyy HH:mm:ss';
const letters = ['A', 'B', 'C', 'D', 'E', 'F'];
const endAddornment = {
  percentage: '%',
};

const removeTags = (str?: string) =>
  str?.replace(/<b>/g, '').replace(/<\/b>/g, '');

const fetchAnswers = async (certificationId: string) => {
  try {
    const { data } = await api.get<
      ApiResponse<Array<CertificationQuestionAnswers>>
    >(`investor/certification-answers/${certificationId}`);
    return data.data;
  } catch (_) {
    return [];
  }
};

export default async function downloadCertificationPdf(
  fullName: string,
  certification: CertificationDetails,
) {
  const questionsAndAnswers = await fetchAnswers(certification._id);

  const isProffesionalInvestor =
    certification.investorType === InvestorType.ProfessionalClient;

  const hasFooterText = !!footerText[certification.investorType];

  const commonAnswersRenderer = () =>
    sortBy(questionsAndAnswers, 'investorCertificationQuestionId.order').map(
      (item, index) => {
        const { title, subtitle, option, type, noPrefix } =
          item.investorCertificationQuestionId;
        const selectedAnswers = compact(
          item.optionSelected?.map(
            (value) =>
              option?.find((o) => Number(o.value) === Number(value))?.label,
          ),
        );

        const formattedValue =
          type === 'number' && !noPrefix
            ? numberToCurrencyString(Number(item.value))
            : //@ts-expect-error
              `${removeTags(`${item.value}`)}${endAddornment[type] || ''}`;

        return {
          columns: [
            {
              width: '5%',
              text: '',
            },
            {
              width: '3%',
              text: `${letters[index]}.`,
            },
            {
              width: '*',
              stack: [
                {
                  text: removeTags(title),
                  marginBottom: 10,
                },
                ...selectedAnswers.map((answer) => ({
                  text: removeTags(answer),
                  bold: true,
                  marginBottom: 10,
                })),
                {
                  text: removeTags(subtitle),
                  marginBottom: 10,
                },
                item.value || item.value === 0
                  ? {
                      text: formattedValue,
                      bold: true,
                      marginBottom: 10,
                    }
                  : '',
              ],
            },
          ],
          marginBottom: 10,
        };
      },
    );

  const professionalInvestorRenderer = () =>
    sortBy(questionsAndAnswers, 'investorCertificationQuestionId.order').map(
      (answer) => {
        const { questionType, title, option } =
          answer.investorCertificationQuestionId;

        if (questionType === InvestorCertificationQuestionType.Checkbox) {
          return {
            table: {
              widths: ['5%', '3%', '97%'],
              body: (option ?? []).map(
                (questionOption: { value: number; label: string }) => {
                  const isSelected = answer.optionSelected?.includes(
                    questionOption.value,
                  );
                  return [
                    '',
                    isSelected
                      ? {
                          svg: isSelected ? iconSelected : '',
                          width: 15,
                          height: 15,
                        }
                      : {
                          text: '',
                        },
                    {
                      text: removeTags(questionOption?.label),
                      marginBottom: 10,
                    },
                  ];
                },
              ),
            },
            style: 'table',
            layout: 'noBorders',
          };
        } else if (questionType === InvestorCertificationQuestionType.Text) {
          return {
            stack: [
              { text: removeTags(title), bold: false, marginBottom: 10 },
              {
                text: answer.value,
                italics: true,
              },
            ],
            marginBottom: 10,
            marginTop: 10,
          };
        }

        return '';
      },
    );

  pdfMake
    .createPdf({
      content: [
        {
          text: isProffesionalInvestor
            ? 'Elective professional investor statement'
            : 'Investor certification record',
          bold: true,
        },
        { text: '', marginBottom: 20 },
        `Investor name: ${fullName}`,
        { text: '', marginBottom: 7 },
        `Date and time of submission: ${format(
          new Date(certification.certificationPerformedAt),
          timestampFormat,
        )}`,
        { text: '', marginBottom: 7 },
        certification.investorType !== InvestorType.ProfessionalClient
          ? `Investor type: ${investorTypeLabel[certification.investorType]}`
          : '',
        { text: '', marginBottom: 20 },
        isProffesionalInvestor
          ? { text: 'Experience', marginBottom: 20, bold: true }
          : { text: 'Certification answers:', marginBottom: 20, italics: true },
        {
          text: qualificationCriteriaText[certification.investorType],
          marginBottom: 10,
        },
        isProffesionalInvestor
          ? professionalInvestorRenderer()
          : commonAnswersRenderer(),
        hasFooterText
          ? footerText[certification.investorType].map((element) => ({
              ...element,
              marginBottom: 10,
            }))
          : '',
      ],
      styles: {},
    })
    .download(
      `Further Finance Ltd-${fullName.toLowerCase()}-Certification record`,
    );
}
