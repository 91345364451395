import { InvesteeDTO } from 'further-types/companies';
import { orderBy } from 'lodash';

export const getCurrentSharePrice = (company: InvesteeDTO) => {
  if (company?.revaluation?.length) {
    const latestRevaluation = orderBy(company.revaluation, 'date', 'desc')[0];
    return latestRevaluation.amount;
  }
  return Number(company?.initialPrice);
};

export const getLastValuationDate = (company: InvesteeDTO) => {
  if (company?.revaluation?.length) {
    const latestRevaluation = orderBy(company.revaluation, 'date', 'desc')[0];
    return latestRevaluation.date
      ? new Date(latestRevaluation.date)
      : undefined;
  }
  return company?.date ? new Date(company?.date) : undefined;
};
