import { CircularProgress, IconButton } from '@mui/material';
import { CloudDownload } from '@mui/icons-material';
import { useDownloadPdfExitStatement } from 'hooks/data/investorReporting/useDownloadPdfExitStatement';

type Props = any;

const DownloadPdfExitStatementButton: React.FC<Props> = ({
  exitStatementId,
  investorId,
  filename,
}) => {
  const { loading, download } = useDownloadPdfExitStatement({
    exitStatementId,
    investorId,
    filename,
  });

  return (
    <IconButton onClick={download} disabled={loading} size="large">
      {loading ? <CircularProgress size={24} /> : <CloudDownload />}
    </IconButton>
  );
};

export default DownloadPdfExitStatementButton;
