import React from 'react';
import { TextField } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';

const useStyles = makeStyles<{ backgroundColor?: string }>()(
  (theme, props) => ({
    option: {
      borderTop: `1px solid ${theme.palette.borderColor?.main}`,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      fontWeight: '300',
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    textFieldRoot: {
      backgroundColor: props.backgroundColor,
    },
  }),
);

interface Props<T> extends AutocompleteProps<T, false, boolean, false> {
  backgroundColor?: string;
  name?: string;
  label?: string;
  required?: boolean;
  onReset?: () => void;
}

const AppAutocomplete = <T,>({
  name,
  id,
  backgroundColor = 'transparent',
  fullWidth = true,
  size = 'small',
  placeholder = '',
  label = '',
  required = false,
  onReset,
  options,
  renderInput,
  ...rest
}: Props<T>) => {
  const { classes } = useStyles({ backgroundColor });

  return (
    <Box>
      <Autocomplete
        id={id || name}
        style={{ width: '100%' }}
        options={options ?? []}
        classes={{
          option: classes.option,
        }}
        onInputChange={(_e, _value, reason) => {
          if (reason === 'clear') {
            onReset?.();
          }
        }}
        renderInput={
          renderInput ??
          ((params) => (
            <React.Fragment>
              <TextField
                {...params}
                name={name}
                className={classes.textFieldRoot}
                label={label}
                placeholder={placeholder}
                id={'input-' + (id || name)}
                size={size}
                fullWidth={fullWidth}
                required={required}
                slotProps={{
                  htmlInput: {
                    ...params.inputProps,
                    autoComplete: 'off',
                  },
                }}
              />
            </React.Fragment>
          ))
        }
        {...rest}
      />
    </Box>
  );
};

export default AppAutocomplete;
