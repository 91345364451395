import { FC, useState } from 'react';
import {
  useAvailableOrganisations,
  useRolesForOrganisation,
  useMutateAdminUserOrganisation,
} from 'hooks/data/adminUser/useOrganisationMemberships';
import Button from 'components/Button';
import RecordView from 'components/RecordView';
import FieldRow from 'components/FormElements/FieldRow';
import { AppSelectBox } from 'components/FormElements';
import { Box } from '@mui/material';

type Props = {
  adminUserId: string;
  currentOrgMemberships: Array<string>;
};

const AddOrganisationMembership: FC<Props> = ({
  adminUserId,
  currentOrgMemberships,
}) => {
  const [auth0OrgId, setAuth0OrgId] = useState<string>();
  const [auth0RoleId, setAuth0RoleId] = useState<string>();

  const { data: allOrganisations } = useAvailableOrganisations();
  const { data: availableRoles, isFetching: isLoadingRoles } =
    useRolesForOrganisation(auth0OrgId);
  const { create } = useMutateAdminUserOrganisation(adminUserId);

  const onSelectOrganisation = (id: string) => {
    setAuth0OrgId(id);
    setAuth0RoleId(undefined);
  };

  const addUserToOrganisation = () => {
    create.mutate(
      //@ts-expect-error
      { auth0OrgId, auth0RoleId },
      {
        onSuccess: () => {
          setAuth0OrgId(undefined);
          setAuth0RoleId(undefined);
        },
      },
    );
  };

  const availableOrganisations = allOrganisations?.filter(
    (org) => !currentOrgMemberships.includes(org.auth0OrgId),
  );

  return (
    <RecordView>
      <FieldRow title="Organisation">
        <AppSelectBox
          data={
            availableOrganisations?.map((org) => ({
              label: org.displayName,
              value: org.auth0OrgId,
            })) ?? []
          }
          labelKey="label"
          valueKey="value"
          value={auth0OrgId}
          onChange={(e) => onSelectOrganisation(e.target.value as string)}
        />
      </FieldRow>
      <FieldRow title="Role">
        <Box display="flex" gap={4}>
          <AppSelectBox
            data={
              availableRoles?.map((role) => ({
                label: role.name,
                value: role.auth0RoleId,
              })) ?? []
            }
            labelKey="label"
            valueKey="value"
            value={auth0RoleId}
            disabled={!auth0OrgId || isLoadingRoles}
            onChange={(e) => setAuth0RoleId(e.target.value as string)}
          />
          <Button
            variant="contained"
            disabled={!auth0OrgId || !auth0RoleId || create.isPending}
            onClick={addUserToOrganisation}
          >
            Add
          </Button>
        </Box>
      </FieldRow>
    </RecordView>
  );
};

export default AddOrganisationMembership;
