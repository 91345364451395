import { IconButton, Typography } from '@mui/material';
import { dateToLabel } from 'further-ui/utils';

import { CloudDownload } from '@mui/icons-material';
import { Row } from 'components/Layout';

type Props = {
  type: 'idv' | 'aml';
  expiredKycData?: Array<{
    _id?: string;
    idvStatusLabel?: string;
    amlStatusLabel?: string;
    expiredAt?: string;
    isKycStepCompleted?: boolean;
    hasYotiData?: boolean;
  }> | null;
  //@ts-expect-error
  onDownloadResults?: (kycDetails: Props['expiredKycData'][number]) => void;
};

const PreviousResults: React.FC<Props> = ({
  expiredKycData,
  type,
  onDownloadResults,
}) => {
  if (!expiredKycData?.length) {
    return null;
  }

  const getStatusLabel = (kyc: (typeof expiredKycData)[number]) =>
    type === 'aml' ? kyc?.amlStatusLabel : kyc?.idvStatusLabel;

  return (
    <>
      <Typography component="i" variant="caption">
        Previous results
      </Typography>
      {expiredKycData.map((kyc) => (
        <Row key={kyc._id} centered spacing="sm">
          <span>
            {getStatusLabel(kyc)
              ? `${getStatusLabel(kyc)} - ${dateToLabel(
                  kyc.expiredAt,
                  'N/A',
                  true,
                )}`
              : '-'}
          </span>
          {kyc?.isKycStepCompleted && kyc?.hasYotiData && onDownloadResults && (
            <IconButton
              size="extra-small"
              title="Download"
              onClick={() => onDownloadResults(kyc)}
            >
              <CloudDownload color="primary" />
            </IconButton>
          )}
        </Row>
      ))}
    </>
  );
};

export default PreviousResults;
