import { columns } from './columns';

/**
 * Build the rows from the grid data (needed by the React grid component)
 * @param {[object]} gridData
 * @param {[object]} gridDropdownsData
 * @returns
 */
export const buildRowsFromGridData = (gridData, gridDropdownsData) => {
  // start with the header row and add the data rows
  let result = [
    {
      rowId: 'header',
      cells: columns.map((column) => {
        return {
          type: 'header',
          text: `${column.header}${column.required ? '*' : ''}`,
          groupId: column.groupId,
          style: column.style,
          className: column.className,
        };
      }),
    },
  ];

  if (!gridData.length) {
    return result;
  }

  result = [
    ...result,
    // add the rows of data
    ...gridData.map((rowData) => ({
      rowId:
        parseInt(rowData['index']) > 0 ? parseInt(rowData['index']) - 1 : 0, // first row is the header
      // treat each column differently depending on its type
      cells: columns.map((column) => {
        switch (column.type) {
          case 'custom_dropdown': {
            const valuesArray =
              gridDropdownsData[column.columnId] || column.values;
            return {
              ...column,
              type: 'custom_dropdown',
              value: rowData[column.columnId],
              text: valuesArray.find(
                (v) => v.value === rowData[column.columnId],
              )?.label,
              values: valuesArray,
            };
          }
          case 'email':
            return {
              ...column,
              type: 'email',
              text: rowData[column.columnId],
            };
          case 'date':
            return {
              ...column,
              type: 'date',
              value: rowData[column.columnId], // format dd/mm/yyyy
              date: new Date(
                rowData[column.columnId].split('/').reverse().join('-'),
              ),
            };
          case 'number':
            return {
              ...column,
              value: rowData[column.columnId],
            };
          default:
            return {
              ...column,
              type: column.type || 'text',
              text: rowData[column.columnId],
            };
        }
      }),
    })),
  ];
  return result;
};

/**
 * Gets an empty row with all the data fields set to empty strings
 * @param {number} k the row index
 * @returns
 */
export const getEmptyRow = (k) =>
  columns.reduce((acc, column) => {
    acc[column.columnId] = column.columnId === 'index' ? `${k + 1}` : '';
    return acc;
  }, {});

/**
 * @typedef {import('@silevis/reactgrid').Cell} Cell
 * @typedef {import('@silevis/reactgrid').Record} Row
 *
 * Applies the changes to the previous rows
 * @param {Array<Row>} changes
 * @param {[import('@silevis/reactgrid').TCell]} prevRowData
 * @returns [object]
 */
export const applyChangesToPrevRows = (changes, prevRowData) => {
  changes.forEach(({ rowId, columnId, type, newCell }) => {
    let rowData = prevRowData.length && prevRowData[rowId];
    if (!rowData) {
      rowData = getEmptyRow(prevRowData.length);
      prevRowData.push(rowData);
      return;
    }
    if (type === 'text' && typeof rowData[columnId] === 'string') {
      rowData[columnId] = newCell.text?.trim();
    } else if (type === 'number') {
      rowData[columnId] = newCell.value;
    } else if (type === 'checkbox' && typeof rowData[columnId] === 'boolean') {
      rowData[columnId] = newCell.checked;
    } else if (type === 'custom_dropdown') {
      rowData[columnId] = newCell.value;
    } else {
      rowData[columnId] = newCell.text?.trim();
    }
  });
  return [...prevRowData];
};

/**
 * Get the grid data from the cache
 * @deprecated Use useLocalStorage() hook from usehooks-ts
 */
export const getCachedGridData = () => {
  const cachedData = localStorage.getItem('investorUploadGridData');
  if (!cachedData) {
    return [];
  }
  return JSON.parse(cachedData);
};

/**
 * Get the grid data from the cache or return an empty row
 * @returns {[TCell]}
 * @deprecated Use useLocalStorage() hook from usehooks-ts
 */
export const getCachedGridDataOrEmptyRow = () => {
  const cachedData = getCachedGridData();

  const hasMissingData = columns.some((column) =>
    cachedData.some(
      (cachedRow) => !Object.keys(cachedRow).includes(column.columnId),
    ),
  );
  if (!cachedData?.length || hasMissingData) {
    return Array.from({ length: 1 }, (_, k) => getEmptyRow(k));
  }
  return cachedData;
};

/**
 * Save the grid data to the cache
 * @param {[Cell]} gridData
 * @deprecated Use useLocalStorage() hook from usehooks-ts
 */
export const saveGridDataToCache = (gridData) => {
  localStorage.setItem('investorUploadGridData', JSON.stringify(gridData));
};

/**
 * Remove the grid data from the cache
 * @deprecated Use useLocalStorage() hook from usehooks-ts
 */
export const clearGridDataFromCache = () => {
  // save an empty row
  saveGridDataToCache(Array.from({ length: 1 }, (_, k) => getEmptyRow(k)));
};
