import { Grid2 } from '@mui/material';
import GridContainer from 'components/GridContainer';
import Spacing from 'components/Spacing';
import { PropsWithChildren } from 'react';

type Props = {
  size?: 'small' | 'medium' | 'full';
};

const RecordView: React.FC<PropsWithChildren<Props>> = ({
  children,
  size = 'medium',
}) => {
  const columns = size === 'full' ? 12 : size === 'medium' ? 11 : 6;

  return (
    <GridContainer width="100%">
      <Grid2 size={{ xs: 12, md: columns }}>
        <Spacing>{children}</Spacing>
      </Grid2>
    </GridContainer>
  );
};

export default RecordView;
