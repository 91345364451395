import Table from 'components/Table';
import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import useTopInvestors from 'hooks/data/dashboards/useTopInvestors';
import { Link } from 'react-router-dom';
import { createInvestorViewRoute, INVESTOR } from 'adminConstants/routes';

import topInvestorTableStyles from './styles';
import { Visibility } from '@mui/icons-material';
import LoadingError from '../LoadingError';
import { TableActions } from 'components/Table';

const TopInvestorTable = ({ firmId, height }) => {
  const {
    data: investors,
    isFetching,
    isRefetching,
    isError,
  } = useTopInvestors({
    params: { firmId },
  });

  const isLoading = isFetching || isRefetching;

  const { classes } = topInvestorTableStyles();
  const columns = [
    {
      label: 'Name',
      key: 'fullName',
      sort: false,
    },
    {
      label: 'Subscription amount',
      key: 'totalInvestmentAmount',
      render: (investor) =>
        numberToCurrencyString(investor.totalInvestmentAmount),
      sort: false,
    },
    {
      label: 'Total portfolio value',
      key: 'totalPortfolioValue',
      render: (investor) =>
        numberToCurrencyString(investor.totalPortfolioValue),
      sort: false,
    },
    {
      label: 'Most recent subscription',
      key: 'mostRecentSubscription',
      render: (investor) => dateToLabel(investor.mostRecentSubscription),
      sort: false,
    },
    {
      label: '',
      key: 'actions',
      render: (investor) => (
        <TableActions
          actions={[
            {
              link: createInvestorViewRoute(investor._id),
              icon: Visibility,
              label: 'View',
              color: 'primary',
            },
          ]}
        />
      ),
      sort: false,
    },
  ];

  if (isError) {
    return <LoadingError height={height} showCard={false} />;
  }

  return (
    <>
      <Link className={classes.link} to={INVESTOR}>
        View all
      </Link>
      <Table
        columns={columns}
        tablebody={investors ?? []}
        pagination={false}
        emptyMessage="No investors found."
        variant="nohover"
        loading={isLoading}
        rowsPerPage={10}
      />
    </>
  );
};

export default TopInvestorTable;
