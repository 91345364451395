import React from 'react';
import { numberToCurrencyString } from 'further-ui/utils';
import { CardContent, Card } from '@mui/material';
import Table from 'components/Table';
import { usePagination } from 'hooks/ui/usePagination';
import Heading from 'components/Heading';

type Props = {
  rows: Array<any>;
  classes: Record<string, string>;
};

export const InvestmentsByOrganisationTable: React.FC<Props> = ({
  rows,
  classes,
}) => {
  const pagination = usePagination();
  const columns = [
    {
      label: 'Organisation name',
      key: 'organisationName',
    },
    {
      label: 'Subscriptions (#)',
      key: 'sumInvestments',
    },
    {
      label: 'Subscriptions (£)',
      key: 'sumInvestmentAmount',
      render: (elm) =>
        numberToCurrencyString(elm?.sumInvestmentAmount, {
          hidePenniesIfInteger: true,
        }),
    },
  ];

  return (
    <Card>
      <CardContent className={classes.scrollableTable}>
        <Heading variant="h3" showDivider>
          Subscriptions by adviser organisation
        </Heading>
        <Table
          columns={columns}
          tablebody={rows.sort(pagination.genericSorter)}
          pagination={false}
          order={pagination.order}
          orderBy={pagination.orderBy}
          onRequestSort={pagination.handleRequestSort}
          emptyMessage="No subscriptions through adviser organisations yet"
          variant="nohover"
        />
      </CardContent>
    </Card>
  );
};
