import { Person, AttachMoney, Notifications } from '@mui/icons-material';
import { styled, Tab, Tabs } from '@mui/material';
import Heading from 'components/Heading';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import PageSection from 'components/PageSection';
import { useState } from 'react';
import GeneralLedger from './GeneralLedger';
import Spacing from 'components/Spacing';
import TrialBalance from './TrialBalance';
import ActionHistory from './ActionHistory';

enum AccountingTabs {
  GeneralLedger,
  TrialBalance,
  ActionHistory,
}

const PageContentStyled = styled(PageContent)({
  overflow: 'visible',
});

const FundAccounting: React.FC = () => {
  const [activeTab, setActiveTab] = useState(AccountingTabs.GeneralLedger);

  const handleTabChange = (_: unknown, selectedTab: AccountingTabs) => {
    setActiveTab(selectedTab);
  };

  return (
    <PageContainer heading="Fund accounting: General ledger and trial balance">
      <PageContentStyled>
        <Spacing size="lg">
          <Tabs
            showDivider
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              value={AccountingTabs.GeneralLedger}
              label={
                <Heading variant="h5" noMargin icon={Person}>
                  General ledger
                </Heading>
              }
            />
            <Tab
              value={AccountingTabs.TrialBalance}
              label={
                <Heading variant="h5" noMargin icon={AttachMoney}>
                  Trial balance
                </Heading>
              }
            />
            <Tab
              value={AccountingTabs.ActionHistory}
              label={
                <Heading variant="h5" noMargin icon={Notifications}>
                  Action history
                </Heading>
              }
            />
          </Tabs>
          <PageSection>
            {activeTab === AccountingTabs.GeneralLedger && <GeneralLedger />}
            {activeTab === AccountingTabs.TrialBalance && <TrialBalance />}
            {activeTab === AccountingTabs.ActionHistory && <ActionHistory />}
          </PageSection>
        </Spacing>
      </PageContentStyled>
    </PageContainer>
  );
};

export default FundAccounting;
