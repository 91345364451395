import downloadFile from 'utils/downloadFile';
import { api } from 'lib/httpClient';

export const deleteInvestment = (id) => {
  return () => {
    return api
      .delete(`investment/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const getPartiallyInvestment = (value) => {
  return () => {
    return api
      .get('investment/partially-investment', { params: { ...value } })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data.data);
        }
      });
  };
};

// This is for share allocation upload
export const getExcelFile = (formData, notification) => {
  return () => {
    return api
      .post(
        '/upload-shareholding/download-share-allocation-template-file',
        formData,
        { responseType: 'blob' },
      )
      .then((response) => {
        downloadFile(
          response.data,
          `Allocation-upload-sheet-${Date.now()}.xlsx`,
        );
        return Promise.resolve(response);
      })
      .catch((error) => {
        notification.error(error.response.data.responseMsg);
        return Promise.resolve(error);
      });
  };
};
// This is for share allocation upload
export const uploadExcelFile = (formData) => {
  return () => {
    return api
      .post(`upload-shareholding/process-share-allocations-from-file`, formData)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
// This is for share allocation upload
export const uploadFinalExcelFile = (shareAllocationUploadId) => {
  return () => {
    return api
      .get(
        `upload-shareholding/create-share-allocations/${shareAllocationUploadId}`,
      )
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
