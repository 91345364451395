import React from 'react';
import { Button } from '@mui/material';
import { format } from 'date-fns';
import { Row } from 'components/Layout';

type Props = {
  certificate?: {
    adviserId: string;
    date: string;
    url: string;
  };
  advisersList: Array<{ _id: string; fullName: string }>;
};

const CertificateItem: React.FC<Props> = ({ certificate, advisersList }) => {
  const adviser = advisersList.find((a) => a._id === certificate?.adviserId);

  return (
    <Row spacing="sm" centered>
      <span>
        {certificate?.date
          ? format(new Date(certificate?.date), 'dd/MM/yyyy HH:mm:ss')
          : ''}{' '}
        by {adviser?.fullName}
      </span>
      <Button
        size="extra-small"
        variant="outlined"
        color="primary"
        onClick={() => window.open(certificate?.url)}
      >
        Download document
      </Button>
    </Row>
  );
};

export default CertificateItem;
