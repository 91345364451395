import { Box, styled } from '@mui/material';
import LayoutContextProvider from 'contexts/LayoutContext';
import MainContainer from './MainContainer';

const ContentWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  padding: '30px 15px',
  [theme.breakpoints.up('md')]: {
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
}));

const AppLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <LayoutContextProvider>
    <MainContainer>
      <ContentWrapper bgcolor="background.main">{children}</ContentWrapper>
    </MainContainer>
  </LayoutContextProvider>
);

export default AppLayout;
