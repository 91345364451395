import { FC } from 'react';

const SpreadsheetIcon: FC<{ fill?: string; size?: number }> = ({
  fill,
  size = 20,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="description_24px">
        <path
          id="icon/action/description_24px"
          d="M9.33268 1.33203H2.66602V14.6654H13.3327V5.33203L9.33268 1.33203Z"
          fill={fill}
        />
        <g id="border_all_24px">
          <path
            id="icon/editor/border_all_24px"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.16797 8.83203V13.832H9.16797V8.83203H4.16797ZM6.39019 13.2765H4.72352V11.6098H6.39019V13.2765ZM4.72352 11.0543H6.39019V9.38759H4.72352V11.0543ZM8.61241 13.2765H6.94575V11.6098H8.61241V13.2765ZM6.94575 11.0543H8.61241V9.38759H6.94575V11.0543Z"
            fill="white"
          />
        </g>
        <g id="border_all_24px_2">
          <path
            id="icon/editor/border_all_24px_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.5 8.83203V13.832H11.5V8.83203H6.5ZM8.72222 13.2765H7.05556V11.6098H8.72222V13.2765ZM7.05556 11.0543H8.72222V9.38759H7.05556V11.0543ZM10.9444 13.2765H9.27778V11.6098H10.9444V13.2765ZM9.27778 11.0543H10.9444V9.38759H9.27778V11.0543Z"
            fill="white"
          />
        </g>
        <g id="border_all_24px_3">
          <path
            id="icon/editor/border_all_24px_3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.16797 6.5V11.5H9.16797V6.5H4.16797ZM6.39019 10.9444H4.72352V9.27778H6.39019V10.9444ZM4.72352 8.72222H6.39019V7.05556H4.72352V8.72222ZM8.61241 10.9444H6.94575V9.27778H8.61241V10.9444ZM6.94575 8.72222H8.61241V7.05556H6.94575V8.72222Z"
            fill="white"
          />
        </g>
        <g id="border_all_24px_4">
          <path
            id="icon/editor/border_all_24px_4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.5 6.5V11.5H11.5V6.5H6.5ZM8.72222 10.9444H7.05556V9.27778H8.72222V10.9444ZM7.05556 8.72222H8.72222V7.05556H7.05556V8.72222ZM10.9444 10.9444H9.27778V9.27778H10.9444V10.9444ZM9.27778 8.72222H10.9444V7.05556H9.27778V8.72222Z"
            fill="white"
          />
        </g>
        <path
          id="Path"
          d="M8.66602 5.66667V2L12.3327 5.66667H8.66602Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
export default SpreadsheetIcon;
