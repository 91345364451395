import { FC } from 'react';
import { Button } from '@mui/material';
import { CheckCircle, PauseCircleFilled } from '@mui/icons-material';
import { useInvestorDisability } from 'hooks/data/investor/useInvestorDisability';
import { Row } from 'components/Layout';

interface Props {
  investorId: string;
  firmId: string;
  firmName?: string;
  emailsCurrentlyDisabled: boolean;
}

const SingleFirmEmailToggle: FC<Props> = ({
  investorId,
  firmId,
  firmName,
  emailsCurrentlyDisabled,
}) => {
  const { mutate: updateInvestorDisability } = useInvestorDisability();

  return (
    <Row centered spacing="xs">
      {emailsCurrentlyDisabled ? (
        <>
          <PauseCircleFilled color="secondary" style={{ fontSize: '1rem' }} />
          <span>{firmName ? `${firmName} - Disabled` : 'Disabled'}</span>
        </>
      ) : (
        <>
          <CheckCircle color="primary" style={{ fontSize: '1rem' }} />
          <span>{firmName ? `${firmName} - Enabled` : 'Enabled'}</span>
        </>
      )}
      <Button
        size="extra-small"
        variant="outlined"
        color="primary"
        onClick={() =>
          updateInvestorDisability({
            investorId,
            firmId,
            disableAllEmails: !emailsCurrentlyDisabled,
          })
        }
      >
        {emailsCurrentlyDisabled ? 'Enable emails' : 'Disable emails'}
      </Button>
    </Row>
  );
};

export default SingleFirmEmailToggle;
