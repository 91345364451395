import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/withdrawal';
import downloadFile from 'utils/downloadFile';

const queryKeyName = 'withdrawals';

export function useGetWithdrawals(params: Api.GetWithdrawalsRequest) {
  return useQuery({
    queryKey: [queryKeyName, params],
    queryFn: async () => {
      const { data } = await api.get<ApiResponse<Api.GetWithdrawalsResponse>>(
        '/withdrawals',
        {
          params,
        },
      );

      return data.data;
    },
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });
}

export function useRejectWithdrawal() {
  const handleError = useApiExceptionHandler();
  const queryClient = useQueryClient();
  const { success } = useNotification();

  return useMutation({
    mutationFn: async (withdrawalId: string) => {
      return await api.post<ApiResponse<Api.RejectWithdrawalResponse>>(
        `withdrawals/${withdrawalId}/reject`,
      );
    },
    onError: handleError,
    onSuccess: (data) => {
      success(data.data.responseMsg ?? '');
      queryClient.invalidateQueries({
        queryKey: [queryKeyName],
      });
    },
  });
}

export function useRevertWithdrawal() {
  const handleError = useApiExceptionHandler();
  const queryClient = useQueryClient();
  const { success } = useNotification();

  return useMutation({
    mutationFn: async (withdrawalId: string) => {
      return await api.post<ApiResponse<boolean>>(
        `withdrawals/${withdrawalId}/revert`,
      );
    },
    onError: handleError,
    onSuccess: (data) => {
      success(data.data.responseMsg ?? '');
      queryClient.invalidateQueries({
        queryKey: [queryKeyName],
      });
    },
  });
}

export function useExportWithdrawals(params: Api.ExportWithdrawalsRequest) {
  const handleError = useApiExceptionHandler();
  const queryClient = useQueryClient();
  const { success } = useNotification();

  return useMutation({
    mutationFn: async (sendToCustodian: boolean) => {
      return await api.get('withdrawals/export', {
        params: {
          ...params,
          isExportCustodian: sendToCustodian,
        },
        responseType: 'blob',
      });
    },
    onError: (error: Error) => handleError(error),
    onSuccess: (response, sendToCustodian) => {
      if (sendToCustodian) {
        success(
          'Export file downloaded, and the status of these requests has been updated in our system.',
        );

        queryClient.invalidateQueries({
          queryKey: [queryKeyName],
        });
      }

      downloadFile(response.data, `Withdrawals-${Date.now()}.xlsx`);
    },
  });
}
