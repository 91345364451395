import React from 'react';
import FieldRow from 'components/FormElements/FieldRow';
import CompanyCard from './CompanyCard';
import RecordView from 'components/RecordView';

type Props = any; //TODO: define props

const Summary: React.FC<Props> = ({ data, company }) => {
  return (
    <RecordView>
      <FieldRow title={'Subject line'} centerTitle>
        {data?.subject || '-'}
      </FieldRow>
      <FieldRow title="Email title text" centerTitle>
        {data?.title}
      </FieldRow>
      <FieldRow title="Email top paragraph" centerTitle>
        <div
          className="text-editor"
          dangerouslySetInnerHTML={{
            __html: data?.emailTopParagraph,
          }}
        />
      </FieldRow>
      <CompanyCard company={company} />
      <FieldRow title="Email bottom paragraph" centerTitle>
        <div
          className="text-editor"
          dangerouslySetInnerHTML={{
            __html: data?.emailBottomParagraph,
          }}
        />
      </FieldRow>
    </RecordView>
  );
};

export default Summary;
