import React, { forwardRef } from 'react';
import { TextField, InputAdornment, TextFieldProps } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  input: {
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
});

type Props = TextFieldProps & {
  gbpStartAdornment?: boolean;
};

const AppTextInput: React.FC<Props> = forwardRef(
  (
    {
      disabled,
      type = 'text',
      name,
      id,
      fullWidth = true,
      size = 'small',
      value,
      onChange,
      helperText = '',
      variant = 'outlined',
      error = false,
      onKeyPress,
      gbpStartAdornment,
      className,
      ...rest
    },
    ref,
  ) => {
    const { classes } = useStyles();
    const onKeyPressNumber = (event) => {
      if (type === 'number') {
        let needPrevent = false;
        if (event.key === '-') {
          needPrevent = true;
        } else if (event.key === '.') {
          if (value?.toString().includes('.')) {
            needPrevent = true;
          }
        } else if (isNaN(parseInt(event.key))) {
          needPrevent = true;
        }
        if (needPrevent) {
          event.preventDefault();
        }
      }
    };

    const inputProps = {
      ...rest.InputProps,
      ...(gbpStartAdornment
        ? {
            startAdornment: <InputAdornment position="start">£</InputAdornment>,
          }
        : {}),
      ...(type === 'number' && !rest.InputProps
        ? {
            inputProps: {
              min: 0,
            },
          }
        : {}),
    };

    return (
      <TextField
        {...rest}
        className={`${classes.input} ${className}`}
        type={type}
        name={name}
        id={id || name}
        size={size}
        fullWidth={fullWidth}
        value={value}
        variant={variant}
        onChange={onChange}
        error={error || helperText !== ''}
        helperText={helperText}
        disabled={disabled}
        onKeyPress={onKeyPress ?? onKeyPressNumber}
        ref={ref}
        slotProps={{
          input: inputProps,
        }}
      />
    );
  },
);

export default AppTextInput;
