import { useEffect } from 'react';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { Card, CardContent } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DASHBOARD_ALL_FIRMS, DASHBOARD_MY_FIRM } from 'adminConstants/routes';
import GridContainer from 'components/GridContainer';
import CompanyCategoryPieChart from 'components/DashboardWidgets/CompanyCategoryPieChart';
import EisAllotments from 'components/DashboardWidgets/EisAllotments';
import InvestmentsInflowSummary from 'components/DashboardWidgets/InvestmentsInflowSummary';
import TrancheCountsByStatus from 'components/DashboardWidgets/TrancheCountsByStatus';
import OpenTranchesTable from 'components/DashboardWidgets/OpenTranchesTable';
import { useGetRole } from 'hooks/ui/useGetRole';
import LifetimePerformanceSummary from 'components/DashboardWidgets/LifetimePerformanceSummary';
import EntitySummary from 'components/DashboardWidgets/EntitySummary';
import TopEntitiesTables from 'components/DashboardWidgets/TopEntitiesTables';
import ClosedTrancheDeployments from 'components/DashboardWidgets/ClosedTrancheDeployments';
import { useFirm } from 'hooks/data/firm/useFirm';
import PageContainer from 'components/PageContainer';

const useStyles = makeStyles()((theme) => ({
  scrollableTable: {
    maxHeight: '400px',
    overflow: 'auto',
    marginBottom: '24px',
    paddingBottom: '0 !important',
  },
  heading: {
    margin: '1rem 0',
  },
  green: {
    color: theme.palette.text.rootColor,
  },
  divider: {
    margin: '0 24px',
  },
  flexGrid: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '1rem',
    alignItems: 'stretch',
  },
  childDiv100: {
    '&> div': {
      height: '100%',
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  link: {
    fontSize: '14px',
    color: theme.palette.text.rootColor,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  linkContainer: {
    flex: '1',
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  dcont: {
    display: 'flex',
    flexDirection: 'column',
  },
  masth: {
    fontSize: '22px',
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  compactCardHeader: {
    padding: '20px 30px 14px 25px',
  },
  '@media screen and (max-width: 767px)': {
    masth: {
      fontSize: '18px',
    },
  },
}));

const FirmDashboard: React.FC = () => {
  const history = useHistory();
  const { classes, cx } = useStyles();
  const { firmId } = useParams<{ firmId: string }>();
  const { firmId: usersFirmId, firmName, isSuperAdmin } = useGetRole();
  const { firms } = useFirm({ params: {} });

  const isMyFirmPage = useRouteMatch(DASHBOARD_MY_FIRM)?.isExact;
  const isAllFirmsDashboard = useRouteMatch(DASHBOARD_ALL_FIRMS)?.isExact;

  const firmIdToQuery = isMyFirmPage ? usersFirmId : firmId;
  const adminUserOnMyFirmsPage = isMyFirmPage && isSuperAdmin;

  const params: { firmId?: string } = {};
  if (!isAllFirmsDashboard && !adminUserOnMyFirmsPage) {
    params.firmId = firmIdToQuery;
  }

  const dashboardFirmName = firmName
    ? firmName
    : firms?.data?.result?.find((firm) => firm._id === firmIdToQuery)?.firmName;

  useEffect(() => {
    if (isAllFirmsDashboard && !isSuperAdmin) {
      history.push(DASHBOARD_MY_FIRM);
    }
  }, [isAllFirmsDashboard, isSuperAdmin]);

  const heading = isAllFirmsDashboard
    ? 'Master dashboard'
    : `${
        dashboardFirmName ? `${dashboardFirmName} master` : 'Master'
      } dashboard`;

  return (
    <PageContainer heading={heading}>
      <GridContainer spacing={5}>
        <Grid size={{ md: 6, xs: 12, xl: 7 }} className={classes.flexGrid}>
          {/* @ts-expect-error */}
          <InvestmentsInflowSummary firmId={firmIdToQuery} />
        </Grid>

        <Grid
          size={{ md: 6, xs: 12, xl: 5 }}
          className={cx(classes.flexGrid, classes.childDiv100)}
        >
          <LifetimePerformanceSummary
            parentClasses={classes}
            firmId={firmIdToQuery}
            firmName={isAllFirmsDashboard ? '' : firmName}
          />
        </Grid>

        {/* @ts-expect-error */}
        <EntitySummary parentClasses={classes} firmId={firmIdToQuery} />

        <Grid size={{ md: 8, xs: 12 }} className={classes.flexGrid}>
          <EisAllotments firmId={firmIdToQuery} />
          <TrancheCountsByStatus firmId={firmIdToQuery} />
        </Grid>

        <Grid size={{ md: 4, xs: 12 }}>
          <Card className={classes.flexGrow}>
            <CardContent style={{ marginTop: '1rem' }}>
              <CompanyCategoryPieChart
                title="Fund portfolio by sector"
                subtitle="(Ordered by current value)"
                //@ts-expect-error
                firmId={firmIdToQuery}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <TopEntitiesTables firmId={firmIdToQuery} />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <OpenTranchesTable firmId={firmIdToQuery} classes={classes} />
        </Grid>

        <ClosedTrancheDeployments firmId={firmIdToQuery} classes={classes} />
      </GridContainer>
    </PageContainer>
  );
};

export default FirmDashboard;
