import { api } from 'lib/httpClient';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/eis-wizard';

export default function useEisTasklist(
  params: Api.GetEisWizardTaskListRequest,
) {
  const tasklist = useQuery({
    queryKey: ['eis-tasklist', params],
    queryFn: async () => {
      return (
        await api.get<ApiResponse<Api.GetEisWizardTaskListResponse>>(
          `/eis-wizard`,
          { params },
        )
      ).data.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
  });

  const exportParams = {
    ...params,
    page: null,
  };

  const exportTasklist = useQuery({
    queryKey: ['eis-tasklist-export', exportParams],
    queryFn: async () => {
      return (
        await api.get<ApiResponse<Api.GetEisWizardTaskListResponse>>(
          `/eis-wizard`,
          { params: exportParams },
        )
      ).data.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
    enabled: false,
  });

  return {
    tasklist,
    exportTasklist,
  };
}
