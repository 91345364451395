import FieldRow from 'components/FormElements/FieldRow';
import AppTextInput from 'components/FormElements/AppTextInput';
import { NumericFormat } from 'react-number-format';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import { Button } from '@mui/material';
import { getLastValuationDate } from 'helpers/company/getCurrentSharePrice';
import { isAfter } from 'date-fns';
import RecordView from 'components/RecordView';
import ButtonGroup from 'components/ButtonGroup';

const DetailsStep = ({
  company,
  shareSplitDate,
  setShareSplitDate,
  shareSplitFactor,
  setShareSplitFactor,
  steps,
  setStep,
  currentSharePriceDisplay,
}) => {
  const lastValuationDate = getLastValuationDate(company.data);
  const shareSplitDateIsBeforeLastRevaluation =
    //@ts-expect-error
    shareSplitDate && !isAfter(shareSplitDate, lastValuationDate);

  const datePickerErrorText =
    'The share split date must be after the last revaluation date for this company.';

  return (
    <RecordView>
      <FieldRow title="Company" centerTitle>
        {company.data?.tradingName}
      </FieldRow>
      <FieldRow title="Share split date" centerTitle>
        <AppDatePicker
          required
          name="date"
          value={shareSplitDate}
          onChange={(val) => setShareSplitDate(val)}
          maxDate={new Date()}
          helperText={
            shareSplitDateIsBeforeLastRevaluation ? datePickerErrorText : ''
          }
          error={shareSplitDateIsBeforeLastRevaluation}
        />
      </FieldRow>
      <FieldRow title="Current share price" centerTitle>
        {currentSharePriceDisplay}
      </FieldRow>
      <FieldRow
        title="Share split factor"
        centerTitle
        tooltipText="This is the factor by which shares will be split. A factor of 10 will multiple all shares by 10 (so that 10 shares of £1 each become 100 shares of £0.10 each). A factor below 1 will result in fewer shares (e.g. a factor of 0.5 will turn 10 shares of £1 each into 5 shares of £2 each)."
      >
        <NumericFormat
          decimalScale={20}
          onValueChange={({ value }) => {
            setShareSplitFactor(value);
          }}
          disabled={company.isLoading}
          value={shareSplitFactor}
          allowNegative={false}
          allowLeadingZeros={false}
          thousandSeparator={true}
          customInput={AppTextInput}
        />
      </FieldRow>
      <ButtonGroup>
        <Button
          onClick={() => setStep(steps.SUMMARY)}
          disabled={
            !shareSplitFactor ||
            !shareSplitDate ||
            company.isLoading ||
            shareSplitDateIsBeforeLastRevaluation
          }
          variant="contained"
          color="primary"
        >
          Next
        </Button>
      </ButtonGroup>
    </RecordView>
  );
};

export default DetailsStep;
