import Grid from '@mui/material/Grid2';
import { makeStyles } from 'tss-react/mui';
import CountUp from 'react-countup';
import { Api } from 'further-types/investment';

const useStyles = makeStyles()(() => ({
  boxContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    marginTop: '20px',
    justifyContent: 'flex-start',
  },
  box: {
    background: '#fafafa',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 calc(25% - 15px)',
    minWidth: '200px',
    marginBottom: '0',
    gap: '0.375rem',
  },
  boxTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  boxSubtitle: {
    fontSize: '14px',
    opacity: 0.6,
    fontWeight: 400,
    textAlign: 'center',
    width: '100%',
  },
  boxLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    color: '#656565',
    '& > span': {
      marginLeft: '5px',
    },
  },
  chartIcon: {
    marginRight: '8px',
    verticalAlign: 'middle',
  },
}));

type OverallPerformanceProps = {
  investmentBalance: Api.GetInvestmentDetailsResponse['investmentBalance'];
};

export const OverallPerformance = ({
  investmentBalance,
}: OverallPerformanceProps) => {
  const { classes } = useStyles();
  const { deploymentSummary, unrealisedValue, realisedValue, investmentValue } =
    investmentBalance || {};

  const { undeployedCommitment, totalCommitment, commitmentReceived } =
    deploymentSummary || {};

  const totalExitValue = realisedValue?.total || 0;

  const totalDeployedPercentage = totalCommitment
    ? ((totalCommitment - (undeployedCommitment ?? 0)) / totalCommitment) * 100
    : 0;

  const rvpi = commitmentReceived
    ? (unrealisedValue?.total ?? 0) / commitmentReceived
    : 0;

  const dpi = commitmentReceived ? totalExitValue / commitmentReceived : 0;

  const tvpi = commitmentReceived
    ? (investmentValue ?? 0) / commitmentReceived
    : 0;

  return (
    <Grid size={{ xs: 12 }}>
      <div className={classes.boxContainer}>
        {/* Initial subscription */}
        <div className={classes.box}>
          <div className={classes.boxTitle}>
            <CountUp
              redraw={true}
              end={totalCommitment || 0}
              decimals={2}
              prefix="£"
              duration={0}
              separator=","
            />
          </div>
          <div className={classes.boxSubtitle}>Initial subscription</div>
          <div className={classes.boxLabel}>
            <CountUp
              redraw={true}
              end={totalDeployedPercentage || 0}
              decimals={2}
              suffix="%"
              duration={0}
              separator=","
            />
            <span>deployed</span>
          </div>
        </div>

        {/* Unrealised value */}
        <div className={classes.box}>
          <div className={classes.boxTitle}>
            <CountUp
              redraw={true}
              end={unrealisedValue?.total || 0}
              decimals={2}
              prefix="£"
              duration={0}
              separator=","
            />
          </div>
          <div className={classes.boxSubtitle}>Unrealised value</div>
          <div className={classes.boxLabel}>
            <span>RVPI:</span>
            <CountUp
              redraw={true}
              end={rvpi || 0}
              decimals={2}
              suffix="x"
              duration={0}
              separator=","
            />
          </div>
        </div>

        {/* Realised value */}
        <div className={classes.box}>
          <div className={classes.boxTitle}>
            <CountUp
              redraw={true}
              end={realisedValue?.total || 0}
              decimals={2}
              prefix="£"
              duration={0}
              separator=","
            />
          </div>
          <div className={classes.boxSubtitle}>Realised value</div>
          <div className={classes.boxLabel}>
            <span>DPI:</span>
            <CountUp
              redraw={true}
              end={dpi || 0}
              decimals={2}
              suffix="x"
              duration={0}
              separator=","
            />
          </div>
        </div>

        {/* Total current value */}
        <div className={classes.box}>
          <div className={classes.boxTitle}>
            <CountUp
              redraw={true}
              end={investmentValue || 0}
              decimals={2}
              prefix="£"
              duration={0}
              separator=","
            />
          </div>
          <div className={classes.boxSubtitle}>Total current value</div>
          <div className={classes.boxLabel}>
            <span>TVPI:</span>
            <CountUp
              redraw={true}
              end={tvpi}
              decimals={2}
              suffix="x"
              duration={0}
              separator=","
            />
          </div>
        </div>
      </div>
    </Grid>
  );
};
