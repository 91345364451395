import { Control, Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import useCommonStyles from '../commonStyles';

import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';
import GridContainer from 'components/GridContainer';
import { Grid2, Typography } from '@mui/material';

type Props = {
  control: Control<any>;
  errors: any;
};

const AddNewBankAccountForm = ({ control, errors }: Props) => {
  const { classes: commonClasses } = useCommonStyles();

  return (
    <GridContainer>
      <Grid2 size={{ xs: 12 }}>
        <Typography
          variant="body2"
          sx={{
            padding: '0.75rem',
            fontSize: 14,
            marginBottom: 2,
          }}
        >
          Please enter your bank details. A minimum of the beneficiary name and
          either (i) account number and sort code or (ii) IBAN are required.
        </Typography>
      </Grid2>

      <Grid2
        size={{ xs: 12 }}
        sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
      >
        <FieldRow title="Beneficiary name*" centerTitle>
          <Controller
            name="beneficiaryName"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <>
                <TextField
                  {...rest}
                  inputRef={ref}
                  placeholder="Enter beneficiary name"
                  fullWidth
                  error={!!errors.beneficiaryName}
                />
                {errors.beneficiaryName && (
                  <p className={commonClasses.errorText}>
                    {errors.beneficiaryName.message}
                  </p>
                )}
              </>
            )}
          />
        </FieldRow>

        <FieldRow title="Beneficiary address" centerTitle>
          <Controller
            name={`beneficiaryAddress`}
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <>
                <TextField
                  {...rest}
                  inputRef={ref}
                  placeholder="Enter beneficiary address"
                  fullWidth
                  error={!!errors.beneficiaryAddress}
                />
                {errors.beneficiaryAddress && (
                  <p className={commonClasses.errorText}>
                    {errors.beneficiaryAddress.message}
                  </p>
                )}
              </>
            )}
          />
        </FieldRow>

        <FieldRow title="Bank name" centerTitle>
          <Controller
            name={`bankName`}
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <>
                <TextField
                  {...rest}
                  inputRef={ref}
                  placeholder="Enter bank name"
                  fullWidth
                  error={!!errors.bankName}
                />
                {errors.bankName && (
                  <p className={commonClasses.errorText}>
                    {errors.bankName.message}
                  </p>
                )}
              </>
            )}
          />
        </FieldRow>

        <FieldRow title="Bank address" centerTitle>
          <Controller
            name={`bankAddress`}
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <>
                <TextField
                  {...rest}
                  inputRef={ref}
                  placeholder="Enter bank address"
                  fullWidth
                  error={!!errors.bankAddress}
                />
                {errors.bankAddress && (
                  <p className={commonClasses.errorText}>
                    {errors.bankAddress.message}
                  </p>
                )}
              </>
            )}
          />
        </FieldRow>

        <FieldRow title="UK account number" centerTitle>
          <Controller
            name={`bankAccountNumber`}
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <>
                <PatternFormat
                  {...rest}
                  inputRef={ref}
                  placeholder="Enter bank account number"
                  format="########"
                  customInput={TextField}
                  type="text"
                  error={!!errors.bankAccountNumber}
                />
                {errors.bankAccountNumber && (
                  <p className={commonClasses.errorText}>
                    {errors.bankAccountNumber.message}
                  </p>
                )}
              </>
            )}
          />
        </FieldRow>

        <FieldRow title="UK sort code" centerTitle>
          <Controller
            name={`bankSortCode`}
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <>
                <PatternFormat
                  {...rest}
                  inputRef={ref}
                  placeholder="Enter bank sort code"
                  format="##-##-##"
                  customInput={TextField}
                  type="text"
                  error={!!errors.bankSortCode}
                />
                {errors.bankSortCode && (
                  <p className={commonClasses.errorText}>
                    {errors.bankSortCode.message}
                  </p>
                )}
              </>
            )}
          />
        </FieldRow>

        <FieldRow title="IBAN" centerTitle>
          <Controller
            name={`iban`}
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <>
                <TextField
                  {...rest}
                  inputRef={ref}
                  placeholder="Enter bank account IBAN"
                  fullWidth
                  error={!!errors.iban}
                />
                {errors.iban && (
                  <p className={commonClasses.errorText}>
                    {errors.iban.message}
                  </p>
                )}
              </>
            )}
          />
        </FieldRow>

        <FieldRow title="SWIFT/BIC" centerTitle>
          <Controller
            name={`swift`}
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <>
                <TextField
                  {...rest}
                  inputRef={ref}
                  placeholder="Enter bank account SWIFT/BIC"
                  fullWidth
                  error={!!errors.swift}
                />
                {errors.swift && (
                  <p className={commonClasses.errorText}>
                    {errors.swift.message}
                  </p>
                )}
              </>
            )}
          />
        </FieldRow>

        {errors.ibanOrAccountNumberAndSortCodeMissing && (
          <p className={commonClasses.errorText}>
            {errors.ibanOrAccountNumberAndSortCodeMissing.message}
          </p>
        )}
      </Grid2>
    </GridContainer>
  );
};

export default AddNewBankAccountForm;
