import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/kyc';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';

const useInvestorKyc = (investorId?: string) =>
  useQuery({
    queryKey: ['investor-kyc', investorId],
    queryFn: async () => {
      const {
        data: { data },
      } = await api.get<ApiResponse<Api.GetKycDetailsResponse>>(
        `kyc/${investorId}`,
      );
      return data;
    },
    placeholderData: keepPreviousData,
    enabled: !!investorId,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });

const useInvestorExpiredKyc = (investorId?: string) =>
  useQuery({
    queryKey: ['investor-expired-kyc', investorId],
    queryFn: async () => {
      const {
        data: { data },
      } = await api.get<ApiResponse<Api.GetKycExpiredResultsResponse>>(
        `kyc/expired/${investorId}`,
      );
      return data;
    },
    placeholderData: keepPreviousData,
    enabled: !!investorId,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });

const useKycMutation = <ResponseType, RequestType = void>(
  endpoint: string,
  investorId?: string,
) => {
  const queryClient = useQueryClient();
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();

  return useMutation({
    throwOnError: false,
    mutationFn: async (data: RequestType) => {
      const result = await api.post<ApiResponse<ResponseType>>(
        `kyc/${endpoint}/${investorId}`,
        data,
      );
      return result.data;
    },
    onSuccess: async (data) => {
      notification.success(data.responseMsg ?? '');
      await queryClient.invalidateQueries({
        queryKey: ['investor-kyc', investorId],
      });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
};

const useGetKycImages = (investorId?: string) => {
  const { refetch } = useQuery({
    queryKey: ['investor-kyc-images', investorId],
    queryFn: async () => {
      const {
        data: { data },
      } = await api.get<ApiResponse<Api.GetKycImagesResponse>>(
        `kyc/images/${investorId}`,
      );
      return data;
    },
    placeholderData: keepPreviousData,
    enabled: false,
    staleTime: 60 * 60 * 1000,
  });

  return () => refetch();
};

export default function useKyc(investorId?: string) {
  const kycDetails = useInvestorKyc(investorId);
  const expiredKyc = useInvestorExpiredKyc(investorId);
  const getKycImages = useGetKycImages(investorId);

  const { mutate: resetKyc } = useKycMutation<Api.ResetKycResponse>(
    'reset',
    investorId,
  );

  const { mutate: approveIdv } = useKycMutation<
    Api.ApproveIdvResponse,
    Api.ApproveIdvRequest
  >('approve-idv', investorId);

  const { mutate: approveAml } = useKycMutation<
    Api.ApproveAmlResponse,
    Api.ApproveAmlRequest
  >('approve-aml', investorId);

  const { mutate: manualKycProcess } = useKycMutation<
    Api.ManualKycProcessRequest,
    Api.ManualKycProcessRequest
  >('manual-kyc-process', investorId);

  const { mutate: saveAmlApprovalNotes } = useKycMutation<
    Api.SaveAmlApprovalNotesResponse,
    Api.SaveAmlApprovalNotesRequest
  >('aml-approval-notes', investorId);

  const { mutate: saveIdvApprovalNotes } = useKycMutation<
    Api.SaveIdvApprovalNotesResponse,
    Api.SaveIdvApprovalNotesRequest
  >('idv-approval-notes', investorId);

  return {
    kycDetails,
    getKycImages,
    expiredKyc,
    resetKyc,
    approveIdv,
    approveAml,
    manualKycProcess,
    saveAmlApprovalNotes,
    saveIdvApprovalNotes,
  };
}
