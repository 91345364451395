import * as React from 'react';
import {
  isNavigationKey,
  getCellProperty,
  isAlphaNumericKey,
  keyCodes,
} from '@silevis/reactgrid';
import { DropdownArrow } from 'components/SVGIcons';

/**
 * Custom dropdown template needed by the react grid library to render the dropdowns.
 * Class based component because of the way the library is implemented.
 * @see https://github.com/silevis/reactgrid/blob/develop/src/lib/Components/CellRenderer.tsx
 */
class CustomDropdownTemplate extends React.Component {
  getCompatibleCell(uncertainCell) {
    const value =
      //@ts-expect-error
      uncertainCell.value && getCellProperty(uncertainCell, 'value', 'string');
    const text =
      value && uncertainCell.values?.find((v) => v.value === value)?.label;
    return { ...uncertainCell, text, value };
  }
  handleKeyDown(cell, keyCode, ctrl, _, alt) {
    if (!ctrl && !alt && isAlphaNumericKey(keyCode))
      return { cell, enableEditMode: true };
    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }
  update(cell, cellToMerge) {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
      value: cellToMerge.value,
    });
  }
  //@ts-expect-error
  render(cell, isInEditMode, onCellChanged) {
    if (!isInEditMode) {
      return (
        <div
          onPointerDown={() =>
            onCellChanged(
              this.getCompatibleCell({ ...cell, isInEditMode: true }),
              true,
            )
          }
          className="rg-dropdown-menu"
          //@ts-expect-error
          style={{ color: !cell.text && '#dddddd' }}
          title="Double click for options"
        >
          <span className="select-placeholder">
            {cell.text || '(double click here)'}
          </span>
          {!cell.text && (
            <span className="select-arrow-down" aria-hidden="true">
              <DropdownArrow color="#dddddd" />
            </span>
          )}
        </div>
      );
    }
    return (
      <select
        style={{ width: '100%', height: '100%', outline: 'none' }}
        defaultValue={cell.value ? cell.value : '0'}
        onChange={(e) => {
          return onCellChanged(
            this.getCompatibleCell({
              ...cell,
              value: e.target.value,
              isInEditMode: false,
            }),
            true,
          );
        }}
        onBlur={() => {
          return onCellChanged(
            this.getCompatibleCell({ ...cell, isInEditMode: false }),
            true,
          );
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
            e.stopPropagation();
        }}
      >
        <option key="0" value="">
          Please choose...
        </option>
        {cell.values.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    );
  }
}

export default CustomDropdownTemplate;
