import FieldRow from 'components/FormElements/FieldRow';
import Spacing from 'components/Spacing';

type Props = {
  title: string;
  summary: string;
  letter: string;
  accountManager: string;
  role: string;
  notes?: string;
};

const Summary = ({
  title,
  summary,
  letter,
  accountManager,
  notes,
  role,
}: Props) => {
  return (
    <Spacing>
      <FieldRow title="Statement title" centerTitle>
        {title || '-'}
      </FieldRow>
      <FieldRow title="Statement summary" centerTitle>
        {summary}
      </FieldRow>
      <FieldRow title="Manager's letter" centerTitle>
        <div
          className="text-editor"
          dangerouslySetInnerHTML={{
            __html: letter,
          }}
        />
      </FieldRow>
      <FieldRow title="Manager" centerTitle>
        {accountManager}
      </FieldRow>
      <FieldRow title="Manager's role" centerTitle>
        {role}
      </FieldRow>
      {notes && (
        <FieldRow title="Notes" centerTitle>
          <div
            className="text-editor"
            dangerouslySetInnerHTML={{
              __html: notes,
            }}
          />
        </FieldRow>
      )}
    </Spacing>
  );
};

export default Summary;
