import React from 'react';

import { IconButton } from '@mui/material';
import { AddOutlined, Remove } from '@mui/icons-material';
import { Control, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { nationality } from 'further-ui/labels';
import { AccountType } from 'further-types/investor';
import FieldRow from 'components/FormElements/FieldRow';
import AppCheckbox from 'components/FormElements/AppCheckBox';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import { InvestorFormValues } from 'helpers/investor/investorForm';
import TextField from './InvestorDetails/TextField';
import { PatternFormat } from 'react-number-format';
import AppTextInput from 'components/FormElements/AppTextInput';
import PageSection from 'components/PageSection';
import Spacing from 'components/Spacing';
import { Row } from 'components/Layout';

type Props = {
  control: Control<InvestorFormValues>;
  setValue: (name: string, value: any) => void;
};

const TaxAndBankDetail: React.FC<Props> = ({ control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'taxResidencies',
  });

  const accountType = useWatch({
    control,
    name: 'accountType',
  });

  const taxResidenciesLabel =
    accountType === AccountType.LegalEntity
      ? 'Entity tax domicile/residency'
      : 'Tax residency (please list all)';

  return (
    <PageSection title="Tax and bank details">
      <Spacing>
        <FieldRow title="Bank account name" centerTitle>
          <TextField name="bankAccountName" control={control} />
        </FieldRow>
        <FieldRow title="Bank name" centerTitle>
          <TextField name="bankName" control={control} />
        </FieldRow>
        <FieldRow title="Bank sort code" centerTitle>
          <Controller
            name="bankSortCode"
            control={control}
            render={({
              field: { ref, ...rest },
              fieldState: { error: bankSortCodeError },
            }) => (
              <PatternFormat
                placeholder="Enter bank sort code"
                format="##-##-##"
                customInput={AppTextInput}
                type="text"
                {...rest}
                inputRef={ref}
                error={!!bankSortCodeError}
                helperText={bankSortCodeError?.message}
              />
            )}
          />
        </FieldRow>
        <FieldRow title="Bank account number" centerTitle>
          <TextField name="bankAccountNumber" control={control} />
        </FieldRow>
        <FieldRow title="IBAN (if applicable)" centerTitle>
          <TextField name="iban" control={control} />
        </FieldRow>
        <FieldRow title="SWIFT/BIC (if applicable)" centerTitle>
          <TextField name="swift" control={control} />
        </FieldRow>
        {accountType === AccountType.Individual ? (
          <>
            <FieldRow title="National insurance number" centerTitle>
              <TextField name="nationalInsuranceNumber" control={control} />
            </FieldRow>
            <FieldRow title="Unique Taxpayer Reference number" centerTitle>
              <TextField name="uniqueTaxpayerReference" control={control} />
            </FieldRow>
          </>
        ) : (
          <FieldRow title="Registered tax number (if applicable)" centerTitle>
            <TextField name="registeredTaxNumber" control={control} />
          </FieldRow>
        )}
        <FieldRow title={taxResidenciesLabel} centerTitle>
          {fields.map((field, i) => (
            <React.Fragment key={field.id}>
              <Row centered spacing="xs">
                <Controller
                  name={`taxResidencies.${i}.country`}
                  control={control}
                  render={({
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <AppSelectBox
                        {...rest}
                        data={nationality}
                        valueKey="Country"
                        labelKey="Country"
                        variant="outlined"
                        error={!!error}
                        helperText={error?.message}
                      />
                      <IconButton
                        onClick={() => append({ country: '' })}
                        size="large"
                      >
                        <AddOutlined />
                      </IconButton>
                      <IconButton
                        disabled={fields.length === 1}
                        onClick={() => (fields.length > 1 ? remove(i) : null)}
                        size="large"
                      >
                        <Remove />
                      </IconButton>
                    </>
                  )}
                />
              </Row>
            </React.Fragment>
          ))}
        </FieldRow>

        <FieldRow title="US person?" centerTitle>
          <Controller
            name="isUsPerson"
            control={control}
            render={({
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              field: { ref, value, ...rest },
              fieldState: { error },
            }) => (
              <AppCheckbox
                {...rest}
                label="Yes"
                key="isUsPerson"
                checked={!!value}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FieldRow>
      </Spacing>
    </PageSection>
  );
};
export default TaxAndBankDetail;
