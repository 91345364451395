import { FC, useState } from 'react';
import { Menu, MenuItem, styled } from '@mui/material';
import { ArrowDropDown, CheckCircle } from '@mui/icons-material';
import { useInvestorDisability } from 'hooks/data/investor/useInvestorDisability';
import SingleFirmEmailToggle from './SingleFirmEmailToggle';
import { useFirm } from 'hooks/data/firm/useFirm';
import { useGetRole } from 'hooks/ui/useGetRole';
import Button from 'components/Button';
import { Column, Row } from 'components/Layout';

const Divider = styled('div')({
  height: '1px',
  margin: '0.5rem 0',
  width: '100%',
  borderBottom: '1px dashed #e0e0e0',
});

interface Props {
  investorId: string;
  firmSpecificSettings: Array<{
    firmId: string;
    firmName: string;
    disableAllEmails: boolean;
  }>;
}

const MultiFirmEmailToggle: FC<Props> = ({
  firmSpecificSettings,
  investorId,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const { firmId } = useGetRole();
  const { firms } = useFirm({ params: { firmId } });

  const { mutate: updateInvestorDisability, isPending } =
    useInvestorDisability();

  const hasFirmSpecificSettings = !!firmSpecificSettings?.length;

  return (
    <>
      <Column spacing="xs">
        {firmSpecificSettings &&
          firmSpecificSettings.map((firmSetting) => (
            <SingleFirmEmailToggle
              key={firmSetting.firmId}
              investorId={investorId}
              firmId={firmSetting.firmId}
              firmName={firmSetting.firmName}
              emailsCurrentlyDisabled={firmSetting.disableAllEmails}
            />
          ))}
      </Column>

      {hasFirmSpecificSettings && <Divider />}

      <Row centered spacing="xs">
        <CheckCircle color="primary" sx={{ fontSize: '1rem' }} />
        <span>
          {hasFirmSpecificSettings
            ? 'All other firms - Enabled'
            : 'All firms - Enabled'}
        </span>
        <Button
          aria-controls="select-firm-menu"
          aria-haspopup="true"
          color="primary"
          variant="contained"
          size="extra-small"
          endIcon={<ArrowDropDown />}
          //@ts-expect-error
          onClick={(event) => setMenuAnchorEl(event.currentTarget)}
          loading={isPending}
        >
          Edit
        </Button>
        <Menu
          id="select-firm-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}
        >
          {firms?.data?.result
            ?.filter(
              (firm) =>
                !firmSpecificSettings
                  .map((setting) => setting.firmId)
                  .includes(firm._id),
            )
            .map((firm) => (
              <MenuItem
                key={firm._id}
                onClick={() => {
                  updateInvestorDisability({
                    investorId,
                    firmId: firm._id,
                    disableAllEmails: true,
                  });
                  setMenuAnchorEl(null);
                }}
              >
                Disable emails for {firm.firmName}
              </MenuItem>
            ))}
        </Menu>
      </Row>
    </>
  );
};

export default MultiFirmEmailToggle;
