import React from 'react';
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from '@mui/material';

interface Props extends Omit<CheckboxProps, 'size'> {
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  label?: FormControlLabelProps['label'];
  error?: boolean;
  helperText?: string;
}

const AppCheckbox: React.FC<Props> = ({
  name,
  id,
  value,
  label,
  labelPlacement = 'end',
  checked = false,
  onChange,
  color = 'primary',
  required,
  error = false,
  helperText = '',
  icon,
  checkedIcon,
  ...rest
}) => {
  return (
    <FormControl
      required={required}
      error={error}
      component="fieldset"
      {...rest}
    >
      <FormControlLabel
        value={value}
        control={
          <Checkbox
            checked={checked}
            name={name}
            id={id || name}
            onChange={onChange}
            color={color}
            icon={icon}
            checkedIcon={checkedIcon}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />

      {helperText && (
        <FormHelperText error={error || helperText !== ''}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default AppCheckbox;
