import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';
import { Api } from 'further-types/organisation';
import { ApiResponse } from 'further-types/api';
import { AxiosResponse } from 'axios';
import downloadFile from 'utils/downloadFile';
import { useState } from 'react';

async function fetchOrg({ queryKey }) {
  try {
    const [, id] = queryKey;

    const {
      data: { data },
    }: AxiosResponse<ApiResponse<Api.GetOrganisationResponse>> = await api.get(
      `organisation/${id}`,
    );
    return data;
  } catch (error) {
    throw error;
  }
}

export function useDownloadOrganisationStatement({ id }: OrganisationParams) {
  const [isDownloading, setIsDownloading] = useState(false);
  const notification = useNotification();

  return {
    isDownloading,
    download: async () => {
      try {
        setIsDownloading(true);
        const response: AxiosResponse<Blob> = await api.get(
          `organisation/${id}/statements`,
          {
            responseType: 'blob',
          },
        );

        if (response.status !== 200) {
          throw Error('Unexpected export response.');
        }

        const filename = response.headers['content-disposition']
          ?.split('filename=')?.[1]
          ?.replaceAll('"', '');

        downloadFile(response.data, filename);
      } catch (_) {
        notification.error('Could not retrieve organisation statement.');
      } finally {
        setIsDownloading(false);
      }
    },
  };
}

type OrganisationParams = {
  id?: string;
};

export function useFetchOrganisation({ id }: OrganisationParams) {
  return useQuery<Api.GetOrganisationResponse>({
    queryKey: ['organisation', id],
    queryFn: fetchOrg,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
}

export function useMutateOrganisation(
  onMutationSuccess: () => void,
  params?: OrganisationParams,
) {
  const { error, success } = useNotification();
  const queryClient = useQueryClient();
  const { id } = params || {};

  const useCreateOrganisation = () =>
    useMutation({
      mutationFn: async (body: Api.CreateOrganisationParams) => {
        const { data } = await api.post(`organisation/create`, body);
        return data;
      },
      onError: (err: any) => {
        error(
          err?.response?.data?.responseMsg ||
            'Encountered an error when trying to create this organisation',
        );
      },
      onSuccess: async (response: ApiResponse<unknown>) => {
        success(response?.responseMsg ?? '');
        queryClient.invalidateQueries({ queryKey: ['organisations'] });
        onMutationSuccess?.();
      },
    });

  const useUpdateOrganisation = ({ id }) =>
    useMutation({
      mutationFn: async (body: Api.UpdateOrganisationParams) => {
        const { data } = await api.put(`organisation/update/${id}`, body);

        return data;
      },
      onError: () => {
        error('Encountered an error when trying to update this organisation');
      },
      onSuccess: async (response: ApiResponse<unknown>) => {
        success(response?.responseMsg ?? '');
        queryClient.invalidateQueries({ queryKey: ['organisations'] });
        onMutationSuccess?.();
      },
    });

  return {
    create: useCreateOrganisation(),
    update: useUpdateOrganisation({ id }),
  };
}

export function useDeleteOrganisation() {
  const { error, success } = useNotification();

  return useMutation({
    mutationFn: async (id: string) => {
      const { data } = await api.delete(`organisation/${id}`);
      return data;
    },
    onError: (err: any) => {
      error(err?.response?.data?.responseMsg);
    },
    onSuccess: async () => {
      success('Organisation deleted');
    },
  });
}
