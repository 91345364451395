const useLocalStorage = <T>(
  key: string,
  options: {
    defaultData: T;
    validate?: (data: T) => boolean;
  },
) => {
  const saveData = (data: T) => {
    window.localStorage.setItem(key, JSON.stringify(data));
  };
  const getData = (): T => {
    const savedData = window.localStorage.getItem(key);

    if (!savedData) {
      return options.defaultData;
    }

    const parsedData: T = JSON.parse(savedData);

    if (options.validate && !options.validate(parsedData)) {
      saveData(options.defaultData);
      return options.defaultData;
    }

    return parsedData;
  };

  return {
    saveData,
    getData,
    clear: () => {
      window.localStorage.removeItem(key);
    },
  };
};

export default useLocalStorage;
