import React from 'react';
import { nationality } from 'further-ui/labels';
import TextField from 'components/FormElements/AppTextInput';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import FieldRow from 'components/FormElements/FieldRow';

type Props = any; // TODO: add types

const AddressInput: React.FC<Props> = ({
  address,
  index,
  isBeingEdited,
  setAdviserData,
  adviserData,
  errorText,
  canEdit,
}) => {
  const disableInput = isBeingEdited && !canEdit;

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...adviserData.address];
    list[index][name] = value;
    setAdviserData({ ...adviserData, address: list });
  };

  return (
    <>
      <FieldRow title="Address line 1*" centerTitle>
        <TextField
          required
          disabled={disableInput}
          name="address1"
          placeholder="Enter address line 1"
          fullWidth
          value={address?.address1}
          error={!!errorText?.addressError?.[index]?.address1}
          helperText={errorText?.addressError?.[index]?.address1}
          onChange={(e) => handleInputChange(e, index)}
        />
      </FieldRow>
      <FieldRow title="Address line 2" centerTitle>
        <TextField
          disabled={disableInput}
          name="address2"
          placeholder="Enter address line 2"
          fullWidth
          value={address?.address2}
          error={!!errorText?.addressError?.[index]?.address2}
          helperText={errorText?.addressError?.[index]?.address2}
          onChange={(e) => handleInputChange(e, index)}
        />
      </FieldRow>
      <FieldRow title="Town/city" centerTitle>
        <TextField
          required
          disabled={disableInput}
          name="city"
          placeholder="Enter town/city"
          fullWidth
          value={address?.city}
          onChange={(e) => handleInputChange(e, index)}
        />
      </FieldRow>
      <FieldRow title="Postal code" centerTitle>
        <TextField
          disabled={disableInput}
          required
          name="postcode"
          fullWidth
          placeholder="Enter postal code"
          value={address?.postcode}
          onChange={(e) => handleInputChange(e, index)}
        />
      </FieldRow>
      <FieldRow title="Country" centerTitle>
        <AppSelectBox
          required
          disabled={disableInput}
          name="country"
          data={nationality}
          valueKey="Country"
          labelKey="Country"
          value={address.country}
          variant="outlined"
          onChange={(e) => handleInputChange(e, index)}
          error={!!errorText?.addressError[index]?.country}
          helperText={errorText?.addressError[index]?.country}
        />
      </FieldRow>
    </>
  );
};

export default AddressInput;
