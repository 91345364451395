import { RouteComponentProps, RouteProps, Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import PageLoader from 'components/PageLoader';

const RestrictedRoute = ({
  component,
  ...rest
}: RouteProps & { component: React.FC<RouteComponentProps> }): JSX.Element => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => <PageLoader />,
    })}
    {...rest}
  />
);

export default RestrictedRoute;
