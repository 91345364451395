import { List, styled } from '@mui/material';
import NavigationItem from './NavigationItem';
import { SidebarMenuItemType } from 'hooks/ui/useSidebarMenuItems';

const SectionWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  fontWeight: Number(theme.typography.fontWeightRegular.valueOf),
  '&:not(:first-of-type)': {
    borderTop: `solid 1px ${theme.palette.sidebar.borderColor}`,
    marginTop: '25px',
  },
}));

const SectionHeader = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: '35px 16px 8px 32px',
  textTransform: 'uppercase',
  fontWeight: Number(theme.typography.fontWeightRegular.valueOf),
  fontSize: 10,
  letterSpacing: 1.5,
}));

type Props = {
  name: string;
  children?: Array<SidebarMenuItemType>;
};

const Section: React.FC<Props> = ({ name, children }) => {
  return (
    <SectionWrapper>
      <SectionHeader>{name}</SectionHeader>
      {!!children?.length && (
        <List component="div" disablePadding>
          {children.map((item) => (
            <NavigationItem
              item={item}
              key={`navigation-item-${item.type}-${item.name}`}
            />
          ))}
        </List>
      )}
    </SectionWrapper>
  );
};

export default Section;
