import { useParams } from 'react-router-dom';
import PageContainer from 'components/PageContainer';
import { CircularProgress } from '@mui/material';
import Summary from '../Common/Summary';
import { useExitStatement } from 'hooks/data/exit';
import PageContent from 'components/PageContent';
import RecordView from 'components/RecordView';

type Params = {
  id: string;
};

const ViewExitStatement = () => {
  const heading = 'Investor communications: Exit statement';
  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    { label: ' Exit statement', link: '/', isActive: true },
  ];

  const { id } = useParams<Params>();

  const {
    exitStatement: { data, isFetching },
  } = useExitStatement({ id });

  const statement = data?.exitStatement;

  if (isFetching) {
    return <CircularProgress size={20} />;
  }

  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <PageContent header="Exit statement summary">
        <RecordView>
          <Summary
            title={statement?.title ?? ''}
            summary={statement?.summary ?? ''}
            letter={statement?.letter ?? ''}
            accountManager={statement?.accountManager?.name ?? ''}
            role={statement?.managerRole ?? ''}
            notes={statement?.notes ?? ''}
          />
        </RecordView>
      </PageContent>
    </PageContainer>
  );
};
export default ViewExitStatement;
