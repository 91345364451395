import { z } from 'zod';
import { CashBalanceOptionValue } from 'components/FirmBankDetailsSelector';

export default z
  .object({
    amountPaid: z
      .string()
      .min(1, 'Enter an amount')
      .refine((val) => val !== '0' && val !== '£0', {
        message: 'Amount cannot be £0',
      }),
    datePaid: z.date({
      invalid_type_error: 'Enter a date',
      required_error: 'Enter a date',
    }),
    paidTo: z.string().min(1, 'Please select a payment source'),
    reference: z.string().optional(),
  })
  .refine(
    (data) =>
      data.paidTo === CashBalanceOptionValue
        ? Number(data.amountPaid?.replace(/£|,/g, '')) > 0
        : true,
    {
      message: 'Cash balance payments must be above £0.',
      path: ['amountPaid'],
    },
  );
