import { Info } from '@mui/icons-material';
import { useStyles } from './styles';
import AppNumberInput from 'components/FormElements/AppNumberInput';
import { Tooltip } from '@mui/material';

type Props = any; //TODO: define types

const SharesAllocatedForSaleCell: React.FC<Props> = ({
  cell,
  setOverride,
  disableTable,
}) => {
  const { classes } = useStyles();
  const isInputChanged =
    cell.sharesAllocatedForSale !== cell.suggestedSharesAllocatedForSale;
  const isInputTooHigh = cell.sharesAllocatedForSale > cell.noOfShares;
  const className = isInputChanged ? classes.inputChanged : '';
  const diffMessage =
    cell.shareAllocationRoundingDiff &&
    cell.shareAllocationRoundingDiff !== 0 &&
    !isInputChanged
      ? `To exit the target number of shares, this investor has had their share number adjusted by ${cell.shareAllocationRoundingDiff}.`
      : '';

  return (
    <div className={classes.flex}>
      <AppNumberInput
        disabled={disableTable}
        defaultValue={0}
        min={0}
        fullWidth
        decimals={5}
        value={cell.sharesAllocatedForSale}
        onChange={(value) => {
          setOverride(cell.shareholdingId, {
            sharesAllocatedForSale: Number(value),
          });
        }}
        error={isInputTooHigh}
        helperText={isInputTooHigh ? 'Not enough shares' : ''}
        className={className}
      />
      {diffMessage && (
        <Tooltip title={diffMessage}>
          <Info style={{ fontSize: 18 }} />
        </Tooltip>
      )}
    </div>
  );
};
export default SharesAllocatedForSaleCell;
