import TextField from '@mui/material/TextField';
import FieldRow from 'components/FormElements/FieldRow';
import Spacing from 'components/Spacing';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InvestmentFirmFormData } from './schema';

const WebhookSettings: React.FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<InvestmentFirmFormData>();

  return (
    <Spacing>
      <FieldRow
        title="New investment webhook URL"
        centerTitle
        tooltipText="Enter the URL for the webhook that will be called when a new investment is made."
      >
        <Controller
          name="webhookSettings.newInvestmentWebhookUrl"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Enter the URL for the webhook"
              fullWidth
              error={!!errors.webhookSettings?.newInvestmentWebhookUrl}
              helperText={
                errors.webhookSettings?.newInvestmentWebhookUrl?.message
              }
            />
          )}
        />
      </FieldRow>
    </Spacing>
  );
};

export default WebhookSettings;
