import { Delete } from '@mui/icons-material';
import Action from './Action';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
};

const DeleteAction: React.FC<Props> = ({ onClick, disabled }) => {
  return (
    <Action
      icon={Delete}
      label="Delete"
      onClick={onClick}
      disabled={disabled}
      color="error"
    />
  );
};

export default DeleteAction;
