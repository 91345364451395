import {
  numberToCurrencyString,
  renderMultiple,
  dateToLabel,
} from 'further-ui/utils';
import { companiesHouseUrl } from 'adminConstants/companyProperties';
import SocialMediaLink from './SocialMediaLink';
import { FileCopy, Edit, Delete } from '@mui/icons-material';
import { COMPANY_ADD } from 'adminConstants/routes';
import TableActions from 'components/Table/TableActions';

type GenerateMainTableColumnsArgs = {
  isSuperAdmin: boolean;
  classes: any;
};

type GenerateCollapsedTableColumnsArgs = {
  classes: any;
  updatePermission: boolean;
  deletePermission: boolean;
  deleteDisclosure: any;
  history: any;
};

export const generateMainTableColumns = ({
  isSuperAdmin,
  classes,
}: GenerateMainTableColumnsArgs) => [
  {
    label: 'Legal name',
    key: 'legalName',
  },
  {
    label: 'Company',
    key: 'tradingName',
    sort: true,
  },
  ...(isSuperAdmin
    ? [
        {
          label: 'Firm',
          render: (elm: any) => elm?.firmName,
          key: 'firmId',
        },
      ]
    : []),
  {
    label: 'Shares held',
    key: 'sharesHeldInCompany',
    render: (elm: any) => new String(elm.metrics?.sharesHeldInCompany),
  },
  {
    label: 'Initial value',
    key: 'initialValue',
    sort: false,
    render: (elm: any) =>
      numberToCurrencyString(elm.metrics?.initialValue, {
        unlimitedDp: false,
      }),
  },
  {
    label: 'Hold value',
    key: 'currentHoldValue',
    sort: false,
    render: (elm: any) =>
      numberToCurrencyString(elm.metrics?.currentHoldValue, {
        unlimitedDp: false,
      }),
  },
  {
    label: 'Net realised value',
    key: 'netRealisedValue',
    sort: false,
    render: (elm: any) =>
      numberToCurrencyString(elm.metrics?.netRealisedValue, {
        unlimitedDp: false,
      }),
  },
  {
    label: 'Total value',
    key: 'totalValue',
    sort: false,
    render: (elm: any) =>
      numberToCurrencyString(elm.metrics?.totalValue, {
        unlimitedDp: false,
      }),
  },
  {
    label: 'Blended multiple',
    key: 'blendedMultiple',
    sort: false,
    render: (elm: any) => renderMultiple(elm.metrics?.blendedMultiple),
  },
  {
    label: 'Last revaluation',
    key: 'latestRevaluationDate',
    sort: false,
    render: (elm: any) => dateToLabel(elm.metrics?.latestRevaluationDate),
  },
  {
    label: 'Links',
    key: '',
    sort: false,
    render: (elm: any) => (
      <div className={classes.dFlex}>
        <SocialMediaLink
          url={elm?.links?.linkedinUrl}
          iconSrc="images/company/linkedin.png"
          altText="LinkedIn"
        />
        <SocialMediaLink
          url={elm?.links?.twitterUrl}
          iconSrc="images/company/twitter.png"
          altText="Twitter"
        />
        <SocialMediaLink
          url={elm?.links?.url}
          iconSrc="images/company/url.png"
          altText="Website"
        />
        <SocialMediaLink
          url={
            elm?.links?.companiesHouseNumber
              ? companiesHouseUrl + elm.links.companiesHouseNumber
              : ''
          }
          iconSrc="images/company/companies-house-number.png"
          altText="Companies House"
        />
      </div>
    ),
  },
];

export const generateCollapsedTableColumns = ({
  updatePermission,
  deletePermission,
  deleteDisclosure,
  history,
}: GenerateCollapsedTableColumnsArgs) => [
  {
    label: 'Company',
    key: 'tradingName',
    sort: true,
  },
  {
    label: 'Share class',
    key: 'shareClass',
  },
  {
    label: 'Shares held',
    key: 'sharesHeldInCompany',
    render: (elm: any) => new String(elm.sharesHeldInCompany),
  },
  {
    label: 'Initial value',
    key: 'initialValue',
    sort: false,
    render: (elm: any) =>
      numberToCurrencyString(elm.initialValue, {
        unlimitedDp: false,
      }),
  },
  {
    label: 'Hold value',
    key: 'currentHoldValue',
    sort: false,
    render: (elm: any) =>
      numberToCurrencyString(elm.currentHoldValue, {
        unlimitedDp: false,
      }),
  },
  {
    label: 'Net realised value',
    key: 'netRealisedValue',
    sort: false,
    render: (elm: any) =>
      numberToCurrencyString(elm.netRealisedValue, {
        unlimitedDp: false,
      }),
  },
  {
    label: 'Total value',
    key: 'totalValue',
    sort: false,
    render: (elm: any) =>
      numberToCurrencyString(elm.totalValue, {
        unlimitedDp: false,
      }),
  },
  {
    label: 'Blended multiple',
    key: 'blendedMultiple',
    sort: false,
    render: (elm: any) => renderMultiple(elm.blendedMultiple),
  },
  {
    label: 'Last revaluation',
    key: 'latestRevaluationDate',
    sort: false,
    render: (elm: any) => dateToLabel(elm.latestRevaluationDate),
  },
  {
    label: 'Actions',
    key: 'actions',
    sort: false,
    render: (elm: any) => (
      <TableActions
        showAsDropdown
        actions={[
          {
            label: 'Duplicate',
            icon: FileCopy,
            color: 'primary',
            visible: updatePermission,
            onClick: () => {
              localStorage.setItem('duplicate-company-id', elm._id);
              history.push(COMPANY_ADD);
            },
          },
          {
            label: 'Edit',
            icon: Edit,
            color: 'primary',
            visible: updatePermission,
            link: `/edit-company/${elm._id}`,
          },
          {
            label: 'Delete',
            icon: Delete,
            color: 'error',
            visible: deletePermission,
            onClick: () => deleteDisclosure.stageAction(elm),
          },
        ]}
      />
    ),
  },
];
