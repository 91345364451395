import { Grid2, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = {
  label: string;
  direction?: 'row' | 'column';
};

const Label = styled(Grid2)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
}));

const Value = styled(Grid2)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
}));

const RecordRow: React.FC<PropsWithChildren<Props>> = ({
  label,
  children,
  direction = 'column',
}) => {
  return (
    <Grid2 container spacing={{ xs: 0, sm: 6 }}>
      <Label size={{ xs: 12, sm: 3 }}>{label}</Label>
      <Value
        size={{ xs: 12, sm: 9 }}
        display="flex"
        flexDirection={direction}
        alignContent="center"
        alignItems={direction === 'row' ? 'center' : 'flex-start'}
        gap={direction === 'row' ? 2 : 0}
      >
        {children}
      </Value>
    </Grid2>
  );
};

export default RecordRow;
