import React from 'react';
import {
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from 'tss-react/mui';
import { Delete, InfoOutlined } from '@mui/icons-material';
import Table, { Column } from 'components/Table';
import GridContainer from 'components/GridContainer';
import TextField from 'components/FormElements/AppTextInput';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import { type } from 'adminConstants/typeConstant';
import { getFeeAccountingOptions } from 'utils/getFeeAccountingOptions';
import { numberToCurrencyString } from 'further-ui/utils';

const useStyles = makeStyles()(() => ({
  performanceFeesCallout: {
    padding: '1rem',
  },
  divider: {
    margin: '40px 0 !important',
  },
  deleteButton: {
    color: '#E8453C',
  },
  addMoreContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginTop: 10,
  },
  perfNote: {
    backgroundColor: '#FBFBFA',
    display: 'block',
    fontSize: 12,
    fontWeight: 'bold',
    padding: '8px 10px',
  },
}));

type Props = any;

const FeeManagement: React.FC<Props> = ({
  onChangeCoreFee,
  onChangePerformanceFees,
  overallHurdleBasis,
  overallFeesInHurdle,
  setFeeManagement,
  feeManagement,
  feeManagementError,
  removePerformanceFee,
}) => {
  const { performanceFees } = feeManagementError;
  const { trancheHasHadAnExit } = feeManagement;
  const { classes: componentClasses } = useStyles();

  const onAddPerformanceFee = () => {
    feeManagement.performanceFees.push({
      feeName: '',
      hurdle: null,
      hurdleBasis: overallHurdleBasis,
      includeFeesInHurdle: overallFeesInHurdle,
      feePercentageIncVAT: 0,
      feePercentageExVAT: null,
      vatPercentage: null,
    });
    setFeeManagement({ ...feeManagement });
  };

  const columns = [
    {
      label: 'Fee name',
      key: 'feeName',
      sort: false,
      render: (elm, i) => (
        <TextField
          required
          name="feeName"
          placeholder="Enter fee name"
          fullWidth
          onChange={(e) => onChangeCoreFee(e, i)}
          value={elm?.feeName}
          error={elm[i]?.feeName}
          helperText={elm[i]?.feeName}
          variant="outlined"
          style={{ minWidth: 200 }}
        />
      ),
    },
    {
      label: (
        <>
          Fee type
          <Tooltip
            title={
              <>
                <b>Any recurring % fee</b> is charged at the annual rate set out
                in the 'Fee %/£ (ex VAT)' column and billed at the frequency you
                set. For example, a 2% fee billed quarterly will bill 0.5% a
                quarter.
                <br />
                <br />
                <b>Any recurring £ fee</b> is charged at the rate set out in the
                'Fee %/£ (ex VAT)' column and billed at the frequency you set.
                For example, a £250 fee billed quarterly will bill at £250 a
                quarter.
              </>
            }
          >
            <IconButton size="small">
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ),
      key: 'displayFeeType',
      sort: false,
      render: (elm, i) => (
        <AppSelectBox
          required
          data={[
            { label: 'Initial %', value: type.addFeeTypes.initialPercentage },
            {
              label: 'Recurring %',
              value: type.addFeeTypes.recurringPercentage,
            },
            { label: 'Initial £', value: type.addFeeTypes.initialFixed },
            { label: 'Recurring £', value: type.addFeeTypes.recurringFixed },
          ]}
          name="displayFeeType"
          valueKey="value"
          labelKey="label"
          variant="outlined"
          value={elm?.displayFeeType}
          onChange={(e) => {
            onChangeCoreFee(e, i);
          }}
          error={elm[i]?.feeType}
          style={{ minWidth: 120 }}
          helperText={elm[i]?.feeType}
        />
      ),
    },
    {
      label: 'Charging frequency',
      key: 'chargingFrequency',
      sort: false,
      render: (elm, i) => (
        <AppSelectBox
          required
          data={[
            { value: 'MONTHLY', label: 'Monthly' },
            { value: 'QUARTERLY', label: 'Quarterly' },
            { value: 'YEARLY', label: 'Annually' },
          ]}
          name="chargingFrequency"
          valueKey="value"
          disabled={elm.feeType === 'INITIAL'}
          labelKey="label"
          defaultValue={'MONTHLY'}
          variant="outlined"
          value={elm?.chargingFrequency}
          onChange={(e) => onChangeCoreFee(e, i)}
          error={elm[i]?.chargingFrequency}
          helperText={elm[i]?.chargingFrequency}
        />
      ),
    },
    {
      label: 'Start period',
      key: 'startingPeriod',
      size: 'medium',
      sort: false,
      render: (elm, i) => (
        <TextField
          type="number"
          required
          name="startingPeriod"
          placeholder="e.g. 1"
          disabled={elm.feeType === 'INITIAL'}
          fullWidth
          onChange={(e) => onChangeCoreFee(e, i)}
          value={elm?.startingPeriod}
          error={elm[i]?.startingPeriod}
          helperText={elm[i]?.startingPeriod}
          variant="outlined"
        />
      ),
    },
    {
      label: 'Finish period',
      key: 'finishingPeriod',
      sort: false,
      render: (elm, i) => (
        <TextField
          type="number"
          required
          name="finishingPeriod"
          placeholder="e.g. 15"
          fullWidth
          disabled={elm.feeType === 'INITIAL'}
          onChange={(e) => onChangeCoreFee(e, i)}
          value={elm?.finishingPeriod}
          error={elm[i]?.finishingPeriod}
          helperText={elm[i]?.finishingPeriod}
          variant="outlined"
        />
      ),
    },
    {
      label: 'Fee %/£ (ex VAT)',
      key: 'netFee',
      sort: false,
      render: (elm, i) => (
        <TextField
          type="number"
          required
          name="netFee"
          placeholder="e.g. 1"
          onChange={(e) => onChangeCoreFee(e, i)}
          value={elm?.netFee}
          error={elm[i]?.netFee}
          helperText={elm[i]?.netFee}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {elm?.feeCalculationBasis ===
                  type.feeCalculationBasis.percentage && '%'}
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                {elm?.feeCalculationBasis === type.feeCalculationBasis.fixed &&
                  '£'}
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      label: 'VAT',
      key: 'vat',
      sort: false,
      render: (elm, i) => (
        <AppSelectBox
          required
          data={[
            { label: '20%', value: 20 },
            { label: 'No VAT', value: 0 },
          ]}
          name="vat"
          valueKey="value"
          labelKey="label"
          variant="outlined"
          value={elm?.vat}
          onChange={(e) => onChangeCoreFee(e, i)}
          error={elm[i]?.vat}
          helperText={elm[i]?.vat}
          style={{ minWidth: 80 }}
        />
      ),
    },
    {
      label: 'Gross fee',
      key: 'grossFee',
      sort: false,
      render: (elm) => {
        return `${
          elm?.feeCalculationBasis === type.feeCalculationBasis.fixed
            ? numberToCurrencyString(elm?.grossFee)
            : Intl.NumberFormat('en-GB', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
              }).format(elm.grossFee) + '%'
        }`;
      },
    },
    {
      label: 'Fee accounting',
      key: 'feeAccounting',
      sort: false,
      render: (elm, i) => (
        <AppSelectBox
          required
          data={getFeeAccountingOptions({
            isAccruedDisable: elm.feeType === type.feeTypes.initial,
            isChargedToInvestorDisable: false,
          })}
          name="feeAccounting"
          valueKey="value"
          labelKey="label"
          variant="outlined"
          value={elm?.feeAccounting}
          error={elm[i]?.feeAccounting}
          helperText={elm[i]?.feeAccounting}
          onChange={(e) => onChangeCoreFee(e, i)}
          style={{ minWidth: 180 }}
        />
      ),
    },
    {
      label: 'Action',
      key: 'action',
      sort: false,
      render: (elm, i) => (
        <Button
          size="small"
          className={componentClasses.deleteButton}
          onClick={() => {
            removePerformanceFee(i, 'coreFee');
          }}
          disabled={trancheHasHadAnExit && elm?._id}
        >
          <Delete />
        </Button>
      ),
    },
  ];

  const performanceFeeSettingColumns = [
    {
      label: 'Hurdle basis',
      key: 'hurdleBasis',
      sort: false,
      render: () => (
        <AppSelectBox
          required
          data={[
            {
              label: 'When company hits hurdle',
              value: type.feeHurdleBasis.company,
            },
            {
              label: 'When whole subscription hits hurdle',
              value: type.feeHurdleBasis.investment,
            },
          ]}
          name="hurdleBasis"
          valueKey="value"
          labelKey="label"
          variant="outlined"
          value={overallHurdleBasis}
          onChange={(e) => onChangePerformanceFees(e)}
          error={performanceFees[0]?.hurdleBasis}
          helperText={performanceFees[0]?.hurdleBasis}
          disabled={trancheHasHadAnExit}
        />
      ),
    },
    {
      label: 'Gross/Net',
      key: 'grossNet',
      sort: false,
      render: () => (
        <AppSelectBox
          required
          data={[
            {
              label: 'Net of fees',
              value: 'true',
            },
            {
              label: 'Gross of fees',
              value: 'false',
            },
          ]}
          name="includeFeesInHurdle"
          valueKey="value"
          labelKey="label"
          variant="outlined"
          value={overallFeesInHurdle}
          onChange={(e) => onChangePerformanceFees(e)}
          error={performanceFees[0]?.includeFeesInHurdle}
          helperText={performanceFees[0]?.includeFeesInHurdle}
          disabled={trancheHasHadAnExit}
        />
      ),
    },
  ];

  const performanceFeeColumns = [
    {
      label: 'Fee name',
      key: 'feeName',
      sort: false,

      render: (elm, i) => (
        <TextField
          required
          name="feeName"
          fullWidth
          placeholder="Enter fee name"
          onChange={(e) => onChangePerformanceFees(e, i)}
          value={elm?.feeName}
          error={performanceFees[i]?.feeName}
          helperText={performanceFees[i]?.feeName}
          variant="outlined"
          disabled={trancheHasHadAnExit && elm?._id}
        />
      ),
    },
    {
      label: 'Hurdle (1.0 = 1.0x return)',
      key: 'hurdle',
      sort: false,
      render: (elm, i) => (
        <TextField
          required
          name="hurdle"
          fullWidth
          type="number"
          placeholder="e.g. 1.8"
          onChange={(e) => onChangePerformanceFees(e, i)}
          value={elm?.hurdle}
          error={performanceFees[i]?.hurdle}
          helperText={performanceFees[i]?.hurdle}
          variant="outlined"
        />
      ),
    },
    {
      label: '%',
      key: 'percentage',
      sort: false,
      render: (elm, i) => (
        <TextField
          required
          name="feePercentageExVAT"
          fullWidth
          type="number"
          placeholder="e.g. 20"
          onChange={(e) => onChangePerformanceFees(e, i)}
          value={elm?.feePercentageExVAT}
          error={performanceFees[i]?.feePercentageExVAT}
          helperText={performanceFees[i]?.feePercentageExVAT}
          variant="outlined"
        />
      ),
    },
    {
      label: 'VAT',
      key: 'vat',
      sort: false,
      render: (elm, i) => (
        <AppSelectBox
          required
          data={[
            { label: '20% VAT', value: 20 },
            { label: 'No VAT', value: 0 },
          ]}
          name="vatPercentage"
          valueKey="value"
          labelKey="label"
          variant="outlined"
          value={elm?.vatPercentage ?? undefined}
          onChange={(e) => onChangePerformanceFees(e, i)}
          error={performanceFees[i]?.vatPercentage}
          helperText={performanceFees[i]?.vatPercentage}
        />
      ),
    },
    {
      label: 'Gross fee',
      key: 'feePercentageIncVAT',
      sort: false,
      render: (elm) => {
        return `${Number(elm.feePercentageIncVAT).toFixed(2)}%`;
      },
    },
    {
      label: 'Action',
      key: 'action',
      sort: false,
      render: (_, i) => (
        <Button
          size="small"
          className={componentClasses.deleteButton}
          onClick={() => {
            removePerformanceFee(i, 'performanceFees');
          }}
        >
          <Delete />
        </Button>
      ),
    },
  ];

  return (
    <>
      <GridContainer>
        <Grid size={{ xs: 12 }}>
          <Typography component="h4" variant="inherit">
            Set core fees
          </Typography>
          <p>
            These fees apply to direct investors and can be adjusted for
            advisers and platforms within the Advisers section.
          </p>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Table
            columns={columns as Array<Column<any>>}
            tablebody={feeManagement?.coreFees}
            variant="nohover"
          />
          <div className={componentClasses.addMoreContainer}>
            <Button
              onClick={() => {
                feeManagement.coreFees.push({
                  feeName: '',
                  feeType: '',
                  netFee: null,
                  vat: null,
                  grossFee: 0,
                  feeAccounting: '',
                });
                setFeeManagement({ ...feeManagement });
              }}
              color="primary"
              variant="text"
            >
              Add fee
            </Button>
          </div>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Typography component="h4" variant="inherit">
            Performance fees
          </Typography>
          <p>
            These fees are charged on exits. Fees are calculated either before
            (gross) or after (net) non-performance fees. If you have multiple
            performance fee tiers (based on hurdle rate), add each one and the
            full fee (e.g. 2x 20%, 5x 25%, etc). Larger hurdle fees override
            smaller hurdle fees from the larger hurdle point.
          </p>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <p className={componentClasses.perfNote}>
            Apply these settings to all performance fees:
          </p>
          <Table
            columns={performanceFeeSettingColumns}
            tablebody={[{}]}
            variant="nohover"
          />
          <Table
            columns={performanceFeeColumns}
            tablebody={feeManagement?.performanceFees}
            variant="nohover"
          />
          <div className={componentClasses.addMoreContainer}>
            <Button
              onClick={onAddPerformanceFee}
              variant="text"
              color="primary"
            >
              Add fee
            </Button>
          </div>
        </Grid>
      </GridContainer>
    </>
  );
};

export default FeeManagement;
