import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';
import { Api } from 'further-types/investor';
import { ApiResponse } from 'further-types/api';

function useDeleteCertification(investorId: string) {
  const handleApiException = useApiExceptionHandler();
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation({
    throwOnError: false,
    mutationFn: async (investorCertificationId: string) => {
      const result = await api.delete<
        ApiResponse<Api.DeleteInvestorCertificationResponse>
      >(`investor/${investorId}/certification/${investorCertificationId}`);
      return result.data;
    },
    onSuccess: async () => {
      notification.success('Certification deleted successfully.');
      await queryClient.invalidateQueries({
        queryKey: ['investor-certification', investorId],
      });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
}

function useResetCertification(investorId: string) {
  const handleApiException = useApiExceptionHandler();
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation({
    throwOnError: false,
    mutationFn: async () => {
      const result = await api.post<
        ApiResponse<Api.ResetInvestorCertificationResponse>
      >(`investor/${investorId}/reset-certification`);
      return result.data;
    },
    onSuccess: async () => {
      notification.success('Certification reset successfully.');
      await queryClient.invalidateQueries({
        queryKey: ['investor-certification', investorId],
      });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
}

export default function useInvestorCertification(investorId: string) {
  const certification = useQuery({
    queryKey: ['investor-certification', investorId],
    queryFn: async () => {
      const {
        data: { data },
      } = await api.get<ApiResponse<Api.GetInvestorCertificationResponse>>(
        `investor/${investorId}/certification`,
      );
      return data;
    },
    enabled: !!investorId,
  });

  return {
    certification,
    deleteCertification: useDeleteCertification(investorId),
    resetCertification: useResetCertification(investorId),
  };
}
