export const type = {
  feeTypes: {
    initial: 'INITIAL',
    recurring: 'RECURRING',
    commission: 'COMMISSION',
    fee: 'ONE OFF FEE',
    discount: 'ONE OFF DISCOUNT',
    adviser: 'ADVISER',
    performance: 'PERFORMANCE',
  },
  addFeeTypes: {
    initialPercentage: 'INITIAL %',
    recurringPercentage: 'RECURRING %',
    initialFixed: 'INITIAL £',
    recurringFixed: 'RECURRING £',
  },
  feeCalculationBasis: {
    fixed: 'FIXED',
    percentage: 'PERCENTAGE',
  },
  feeAccounting: {
    chargedToInvestor: 'CHARGED_TO_INVESTOR',
    accrued: 'ACCRUED',
  },
  feeHurdleBasis: {
    company: 'COMPANY',
    investment: 'INVESTMENT',
  },
  feeExport: {
    general: 'GENERAL',
    custodian: 'CUSTODIAN',
  },
  feeExistence: {
    past: 'PAST',
    future: 'FUTURE',
  },
};
