import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid2';
import GridContainer from 'components/GridContainer';
import { Button, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import PageLoader from 'components/PageLoader';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import ManagerOverViewForm from './ManagerOverViewForm';
import ClosedFundsList from './ClosedFundsList';
import { useReportingAccountManagers } from 'hooks/data/investorReporting/useReportingAccountManagers';
import useStyles from './styles';
import useInvestorReportingForm, {
  fundInformationSchema,
} from 'hooks/form/useInvestorReportingForm';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { status } from 'adminConstants/investorReporting';
import { useInvestorReportingFormContext } from 'contexts/InvestorReportingFormContext';
import { useDownload } from 'hooks/ui/useDownload';
import DraftButtons from '../DraftButtons';
import Heading from 'components/Heading';

const FundInformationStep = ({
  firm,
  isFetching,
  isViewPage,
  hasActiveFundsToReportOn,
  onNextStep,
  onExitForm,
}) => {
  const { classes, cx } = useStyles();
  const [isDirty, setIsDirty] = useState(false);
  const { data: managers } = useReportingAccountManagers(firm?._id);
  const { submitForm, scrollToFirstError } = useInvestorReportingForm();

  const formContext = useInvestorReportingFormContext();
  // @ts-expect-error
  const { submitDate, setSubmitDate, processFundInformation, investorReport } =
    formContext;

  const { handleSubmit, control, setValue, getValues, watch } = useForm({
    shouldFocusError: true,
    resolver: zodResolver(fundInformationSchema),
    criteriaMode: 'all',
  });

  const fetchPdfSample = useDownload({
    filename: 'sample.pdf',
    errorMessage: 'The sample update is still being created. Please try again.',
  });

  useEffect(() => {
    // reset scroll state back to the top
    window.scrollTo(0, 0);
    const { unsubscribe } = watch(() => {
      setIsDirty(true);
    });

    return unsubscribe;
  }, [watch]);

  const onValidationError = (errors) => {
    scrollToFirstError(errors);
  };

  const onQuickSave = async (inputData) => {
    const data = inputData ?? getValues();

    const submissionData = {
      ...formContext,
      managerOverview: {
        ...(formContext.managerOverview ?? {}),
        ...data.managerOverview,
      },
      fundInformation: processFundInformation(data.fundInformation),
    };

    if (
      data.managerOverview?.managerOverViewTitle &&
      data.managerOverview?.managerOverViewShortDescription
    ) {
      await submitForm(submissionData, status.DRAFT, true);
      setIsDirty(false);
    }
  };

  const quickSaveBeforeNextStep = (data) => {
    const quickSaveDisabled =
      formContext?.investorReport?.status === status.SUBMITTED || isViewPage;

    if (!quickSaveDisabled) onQuickSave(data);
    onNextStep(data);
  };

  const handleDownloadSample = () => {
    if (!fetchPdfSample.isPending && investorReport?._id) {
      fetchPdfSample.mutate(
        `investor-reporting/export-sample-pdf/${investorReport?._id}`,
      );
    }
  };

  if (isFetching) return <PageLoader />;

  if (!firm) return null;

  return (
    <form onSubmit={handleSubmit(quickSaveBeforeNextStep, onValidationError)}>
      <GridContainer>
        <Grid
          className={classes.dateInput}
          size={{
            md: 6,
            xs: 12,
          }}
        >
          <Heading variant="h2">Enter date of report</Heading>
          <Controller
            name="submitDate"
            defaultValue={submitDate}
            control={control}
            render={({ formState: { errors } }) => (
              <AppDatePicker
                id="submitDate"
                required
                name="submitDate"
                value={submitDate}
                onChange={(date) => {
                  //@ts-expect-error
                  setSubmitDate(date);
                  setValue('submitDate', date);
                }}
                error={!!errors?.submitDate}
                helperText={errors?.submitDate?.message?.toString()}
                disabled={isViewPage}
              />
            )}
          />
        </Grid>
      </GridContainer>
      <Heading variant="h2">Enter fund information</Heading>
      {hasActiveFundsToReportOn ? (
        <>
          <ManagerOverViewForm
            classes={classes}
            manager={managers?.accountManagers ?? []}
            disabled={isViewPage}
            control={control}
            setValue={setValue}
            summaryClassName={cx(
              classes.cmAccHeader,
              classes.expends,
              classes.fundsAccHeader,
            )}
          />
          <ClosedFundsList
            firmName={firm?.firmName}
            managers={managers?.accountManagers ?? []}
            isViewPage={isViewPage}
            control={control}
            setValue={setValue}
          />
        </>
      ) : (
        <Alert severity="info">
          <Typography className={classes.noTranchesInfoText}>
            No tranches require a report on this date. There are either:
          </Typography>
          <ul className={classes.noTranchesInfoList}>
            <li>No closed tranches on the selected date, or;</li>
            <li>
              The closed tranches contain no active subscriptions on this date,
              or;
            </li>
            <li>
              The closed tranches only contain companies that have been fully
              exited on this date.
            </li>
          </ul>
        </Alert>
      )}
      <div className={classes.buttonContainer}>
        <div className={classes.buttonLeftContainer}>
          <Button variant="outlined" onClick={onExitForm}>
            Back
          </Button>
        </div>

        {!!hasActiveFundsToReportOn && (
          <Button type="submit" color="primary" variant="contained">
            Proceed to portfolio company updates
          </Button>
        )}
      </div>
      <DraftButtons
        onDownloadSample={handleDownloadSample}
        // @ts-expect-error
        onQuickSave={onQuickSave}
        isDownloading={fetchPdfSample.isPending}
        allowDownload={!!investorReport?._id && !isDirty}
      />
    </form>
  );
};

export default FundInformationStep;
