import { TRPCClientErrorBase } from '@trpc/client';
import { useQueryClient } from '@tanstack/react-query';
import { useNotification } from 'hooks/ui/useNotification';
import { useHistory } from 'react-router-dom';
import api from 'lib/trpcClient';

const useHandleTrpcMutation = () => {
  const { error, success } = useNotification();
  const queryClient = useQueryClient();
  const history = useHistory();
  const apiUtils = api.useUtils();

  type Args = {
    successMessage?: string;
    invalidations?: Array<string | Array<string>>;
    successRedirect?: string;
    invalidationHandler?: (utils: typeof apiUtils) => void;
    onSuccess?: () => void;
  };

  return ({
    successMessage,
    invalidations,
    successRedirect,
    onSuccess,
    invalidationHandler,
  }: Args) => ({
    onSuccess: () => {
      if (successMessage) {
        success(successMessage);
      }
      invalidationHandler?.(apiUtils);
      if (invalidations) {
        invalidations.forEach((invalidation) => {
          queryClient.invalidateQueries({ queryKey: [invalidation] });
        });
      }
      onSuccess?.();
      if (successRedirect) {
        history.push(successRedirect);
      }
    },
    onError: (err: TRPCClientErrorBase<any>) => {
      error(err?.message ?? 'Something went wrong');
    },
  });
};

export default useHandleTrpcMutation;
