import { useQuery, useMutation, keepPreviousData } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

async function fetchOrganisations({ queryKey }) {
  const [, params] = queryKey;

  try {
    const { data } = await api.get(`organisation/list`, { ...params });

    return data.data;
  } catch (error) {
    throw error;
  }
}
const exportOrganisation = async (params) => {
  const response = await api.post('organisation/export', params, {
    responseType: 'blob',
  });
  if (response.status !== 200) {
    throw Error('Unexpected export response.');
  }
  return response.data;
};

export function useOrganisations({ params }: Record<string, any>) {
  return useQuery({
    queryKey: ['organisations', { params }],
    queryFn: fetchOrganisations,
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
  });
}
export function useOrganisationExport({ params }) {
  const { mutateAsync, isPending: isExportLoading } = useMutation({
    mutationFn: () => exportOrganisation(params),
  });
  return {
    exportOrganisations: mutateAsync,
    isExportLoading,
  };
}
