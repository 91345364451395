import { useState } from 'react';
import { sumBy } from 'lodash';
import PageContainer from 'components/PageContainer';
import Table, {
  TableActions,
  useStyles as useTableStyles,
} from 'components/Table';
import { ViewQuilt, Edit, CloudDownload } from '@mui/icons-material';
import {
  createOpenTrancheRoute,
  createClosedTrancheRoute,
  createInvestmentsListByFundRoute,
  createTrancheEditRoute,
} from 'adminConstants/routes';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import HoverPointer from 'components/HoverPointer';
import { useFunds } from 'hooks/data/fund/useFunds';
import { usePagination } from 'hooks/ui/usePagination';
import { useDebounce } from 'usehooks-ts';
import TranchesFilters from './TranchesFilters';
import useFilters from 'hooks/ui/useFilters';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useFund } from 'hooks/data/fund/useFund';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { Box, IconButton, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import PageContent from 'components/PageContent';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'All Tranches', isActive: true },
];

const currentValueTooltipText = (
  <>
    This is the combined value of all current holdings, exits and uninvested
    paid in capital. Where there are unpaid commitments, this figure may be
    materially lower than <em>Initial value</em>, <em>Invested capital</em> or{' '}
    <em>Uninvested capital</em>.
  </>
);

const AllTranches = () => {
  const [updatePermission] = useGetPermissions(['edit:tranche']);
  const { classes: tableClasses } = useTableStyles();
  const exceptionHandler = useApiExceptionHandler();

  const columns = [
    {
      label: 'Firm name',
      key: 'firmId.firmName',
      render: (elm) => <>{elm?.firmId?.firmName}</>,
    },
    { label: 'Tranche name', key: 'fundName' },
    {
      label: 'Close date',
      key: 'closedDate',
      render: (elm) =>
        elm?.fundStatus ? 'Open' : dateToLabel(elm.closedDate, 'Not found'),
    },
    {
      label: 'Subscriptions',
      key: 'investmentsCount',
      sort: false,
      render: (elm) => {
        return (
          <HoverPointer to={createInvestmentsListByFundRoute(elm?._id)}>
            {elm?.investmentsCount}
          </HoverPointer>
        );
      },
    },
    {
      label: 'Companies',
      key: 'companiesInTranche',
      sort: false,
      render: (elm) => (
        <HoverPointer to={`/company?fundId=${elm?._id}`}>
          {elm?.companyCount}
        </HoverPointer>
      ),
    },
    {
      label: 'Initial value',
      key: 'initialValue',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(
          elm.investmentBalances?.initialInvestmentAmount,
          {
            fallback: 'N/A',
          },
        ),
    },
    {
      label: (
        <Box display="flex" alignItems="center" gap="4px">
          <span>Current value</span>
          <Tooltip title={currentValueTooltipText}>
            <IconButton size="small">
              <InfoOutlined style={{ fontSize: '1rem' }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      key: 'currentValue',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm.investmentBalances?.investmentValue, {
          fallback: 'N/A',
        }),
    },
    {
      label: 'Realised value',
      key: 'realisedValue',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm.investmentBalances?.realisedValue, {
          fallback: 'N/A',
        }),
    },
    {
      label: 'Invested capital',
      key: 'investedCapital',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm.investmentBalances?.investedCapital, {
          fallback: 'N/A',
        }),
    },
    {
      label: 'Uninvested capital',
      key: 'uninvestedCapital',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm.investmentBalances?.uninvestedCapital, {
          fallback: 'N/A',
        }),
    },
    {
      label: 'Actions',
      key: 'actions',
      sort: false,
      render: (elm) => (
        <TableActions
          showAsDropdown
          actions={[
            {
              visible: updatePermission,
              link: createTrancheEditRoute(elm._id),
              icon: Edit,
              color: 'primary',
              label: 'Edit',
            },

            {
              link: elm?.fundStatus
                ? createOpenTrancheRoute(elm?._id)
                : createClosedTrancheRoute(elm?._id),
              icon: ViewQuilt,
              color: 'primary',
              label: 'View dashboard',
            },
            {
              onClick: () =>
                exportFund(elm?._id, elm?.fundName, elm?.firmId?.firmName),
              icon: CloudDownload,
              color: 'primary',
              label: 'Export',
            },
          ]}
        />
      ),
    },
  ];

  const [isExportLoading, setExportLoading] = useState(false);
  const pagination = usePagination({
    id: 'all-tranches',
    orderBy: 'closedDate',
    order: 'desc',
  });

  const { filters, handleChangeFilters } = useFilters('all-tranches', {
    defaultFilters: {
      fundName: null,
      snapshotDate: null,
      tagId: null,
      status: null,
    },
    onFiltersChange: pagination.toFirstPage,
  });

  const debouncedFundName = useDebounce(filters.fundName, 150);
  const queryParams = {
    fundName: debouncedFundName,
    //@ts-expect-error
    tagId: filters?.tagId?._id,
    snapshotDate: filters.snapshotDate,
    keyName: pagination.orderBy,
    type: pagination.order,
    includeTrancheValue: true,
    includeInvestmentsSummary: true,
    //@ts-expect-error
    fundStatus: filters?.status?.fundStatus,
    //@ts-expect-error
    fullyDeployed: filters?.status?.fullyDeployed,
  };

  const { tranches, exportFunds } = useFunds({
    page: pagination.page === 0 ? 1 : pagination.page,
    perPage: pagination.rowsPerPage,
    ...queryParams,
  });
  const { exportFund } = useFund();

  const exportExcelData = async () => {
    try {
      setExportLoading(true);
      await exportFunds({ ...queryParams, includeExportData: true });
    } catch (e: any) {
      exceptionHandler(e, 'Error when exporting subscription transfer', true);
    } finally {
      setExportLoading(false);
    }
  };

  const FooterSummary = () => (
    <tfoot>
      <tr className={tableClasses.tFootRow}>
        <td colSpan={5} />
        <td className={tableClasses.tFootCell}>
          {numberToCurrencyString(
            sumBy(tranches?.data, 'investmentBalances.initialInvestmentAmount'),
          )}
        </td>
        <td className={tableClasses.tFootCell}>
          {numberToCurrencyString(
            sumBy(tranches?.data, 'investmentBalances.investmentValue'),
          )}
        </td>
        <td className={tableClasses.tFootCell}>
          {numberToCurrencyString(
            sumBy(tranches?.data, 'investmentBalances.realisedValue'),
          )}
        </td>
        <td className={tableClasses.tFootCell}>
          {numberToCurrencyString(
            sumBy(tranches?.data, 'investmentBalances.investedCapital'),
          )}
        </td>
        <td className={tableClasses.tFootCell}>
          {numberToCurrencyString(
            sumBy(tranches?.data, 'investmentBalances.uninvestedCapital'),
          )}
        </td>
        <td />
      </tr>
    </tfoot>
  );

  return (
    <PageContainer heading="All Tranches" breadcrumbs={breadcrumbs}>
      <PageContent>
        <TranchesFilters
          onFiltersChange={handleChangeFilters}
          filters={filters}
          onExportClick={exportExcelData}
          isExportLoading={isExportLoading}
        />
        <Table
          columns={columns}
          onRequestSort={pagination.handleRequestSort}
          order={pagination.order}
          orderBy={pagination.orderBy}
          tablebody={tranches?.data ?? []}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          count={tranches?.total ?? 0}
          pagination={true}
          loading={tranches.isFetching}
          emptyMessage="There are currently no tranches"
          variant="nohover"
          TableFooter={<FooterSummary />}
        />
      </PageContent>
    </PageContainer>
  );
};

export default AllTranches;
