import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import qs from 'qs';

async function fetchUnrealisedValueByDate({ queryKey }) {
  const [, firmId, dateRangeKey] = queryKey;
  const queryParams = qs.stringify({ firmId });

  const url = firmId
    ? `dashboard/unrealised-value-by-date/${dateRangeKey}?${queryParams}`
    : `dashboard/unrealised-value-by-date/${dateRangeKey}`;

  const { data } = await api.get(url);

  return data.data;
}

function useUnrealisedValueByDate({ firmId, dateRangeKey, enabled }) {
  return useQuery({
    queryKey: ['dashboard-unrealised-value-by-date', firmId, dateRangeKey],
    queryFn: fetchUnrealisedValueByDate,
    placeholderData: keepPreviousData,
    staleTime: 4 * 60 * 60 * 1000,
    enabled,
  });
}

export default useUnrealisedValueByDate;
