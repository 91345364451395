import {
  WithdrawalRequestSource,
  WithdrawalStatus,
} from 'further-types/withdrawal';

export const RequestSourceToLabelMap = {
  [WithdrawalRequestSource.Investor]: 'Client request',
  [WithdrawalRequestSource.AdminSite]: 'Fund request',
};

export const StatusToLabelMap = {
  [WithdrawalStatus.Requested]: 'Requested',
  [WithdrawalStatus.ExportedToCustodian]: 'Exported to custodian',
  [WithdrawalStatus.Rejected]: 'Rejected',
};
