import { makeStyles } from 'tss-react/mui';
import FieldRow from 'components/FormElements/FieldRow';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { createOrganisationViewRoute } from 'adminConstants/routes';

type Props = {
  org?: {
    _id: string;
    name?: string;
    parentId?: {
      _id: string;
      name: string;
    };
  };
};

const useStyles = makeStyles()(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    paddingTop: '0.2rem !important',
    paddingBottom: '0.2rem !important',
  },
}));

const OrganisationDetails = ({ org }: Props) => {
  const { classes } = useStyles();

  return (
    <>
      <FieldRow title="Organisation" centerTitle>
        <div className={classes.row}>
          {org?.name ?? '-'}
          {org?._id && (
            <Link to={createOrganisationViewRoute(org._id)}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                className={classes.button}
              >
                View organisation
              </Button>
            </Link>
          )}
        </div>
      </FieldRow>
      <FieldRow title="Parent organisation" centerTitle>
        <div className={classes.row}>
          {org?.parentId?.name ?? '-'}
          {org?.parentId?._id && (
            <Link to={createOrganisationViewRoute(org.parentId._id)}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                className={classes.button}
              >
                View parent organisation
              </Button>
            </Link>
          )}
        </div>
      </FieldRow>
    </>
  );
};

export default OrganisationDetails;
