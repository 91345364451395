import AbstracTextTemplate from './AbstractTextTemplate';

const formatDate = (input) => {
  const cleanedInput = input.replace(/\D/g, '');
  const truncatedInput = cleanedInput.slice(0, 8);

  return truncatedInput.replace(
    /(\d{0,2})?(\d{0,2})?(\d{0,4})?/,
    (_, p1, p2, p3) => {
      let result = p1 || '';
      if (p1 && p1.length === 2) result += '/';
      if (p2) result += p2;
      if (p2 && p2.length === 2) result += '/';
      if (p3) result += p3;
      return result;
    },
  );
};

class CustomSortcodeTemplate extends AbstracTextTemplate {
  formatValue(value) {
    return formatDate(value);
  }
}

export default CustomSortcodeTemplate;
