import React from 'react';
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from 'tss-react/mui';
import Heading from 'components/Heading';

const useStyles = makeStyles()((theme) => ({
  note: {
    fontSize: 16,
    color: '#656565',
    marginBottom: theme.spacing(6),
  },
  TableHead: {
    backgroundColor: 'rgba(234, 233, 232, .2)',
  },
  TableHeadCell: {
    minWidth: 120,
    borderBottomColor: '#EDEDED',
    lineHeight: '22px',
    fontSize: 12,
    fontWeight: 700,
    fontStyle: 'normal',
  },
  TableBodyCell: {
    borderBottomColor: '#EDEDED',
    color: '#656565',
    fontSize: 14,
  },
}));

const ErrorTable: React.FC<{ errors: Array<any> }> = ({ errors }) => {
  const { classes } = useStyles();

  return (
    <>
      <Grid size={{ md: 12 }}>
        <Heading variant="h2">Upload error</Heading>
        <div className={classes.note}>
          The following allocation data cannot be processed as the data provided
          is either incomplete or incorrect. Please review the below errors,
          correct your spreadsheet and process your upload again.
        </div>
        <Table>
          <TableHead className={classes.TableHead}>
            <TableRow>
              <TableCell className={classes.TableHeadCell}>Name</TableCell>
              <TableCell className={classes.TableHeadCell}>
                Spreadsheet errors
              </TableCell>
              <TableCell className={classes.TableHeadCell}>
                Allocation errors
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errors.map((row) => {
              return (
                <TableRow key={row.lineNo}>
                  <TableCell className={classes.TableBodyCell}>
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.TableBodyCell}>
                    {row.spreadsheetErrors.join(', ')}
                  </TableCell>
                  <TableCell className={classes.TableBodyCell}>
                    {row.allocationErrors.join(', ')}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </>
  );
};

export default ErrorTable;
