import useCurrentUser from 'hooks/data/currentUser/useCurrentUser';

export const useGetRole = () => {
  const { profile: user } = useCurrentUser();

  const roleSlug = user?.data?.roleSlug;
  const isSuperAdmin = roleSlug === 'super-admin';

  const firmId = user?.data?.firm?._id;
  const firmName = user?.data?.firm?.firmName;
  const isFirmManager = roleSlug === 'firm-manager' || firmId;

  return {
    isSuperAdmin,
    isFirmManager,
    roleSlug,
    firmId,
    firmName,
  };
};
