import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import PageContainer from 'components/PageContainer';
import Table from 'components/Table';
import { dateToLabel, dateofBirthToLabel } from 'further-ui/utils';
import { usePagination } from 'hooks/ui/usePagination';
import useFilters from 'hooks/ui/useFilters';
import { useDebounce } from 'usehooks-ts';
import { useIncompleteRegistrations } from 'hooks/data/investor/useIncompleteRegistrations';
import { useGetRole } from 'hooks/ui/useGetRole';
import React from 'react';
import PageContent from 'components/PageContent';
import PageFilters, { Filter } from 'components/PageFilters';
import {
  investorTypeDropdownList,
  investorTypeLabel,
} from 'further-types/investor';

const columns = [
  { label: 'Investor name', key: 'fullName' },
  { label: 'Email', key: 'email' },
  {
    label: 'Date of birth',
    key: 'dob',
    render: (elm) => <>{elm.dob ? dateToLabel(elm?.dob) : ''}</>,
  },
  { label: 'Phone number', key: 'phoneNumber' },
  {
    label: 'Investor type',
    key: 'investorType',
    render: (elm) => investorTypeLabel[elm?.investorType],
  },
  {
    label: 'Created date',
    key: 'createdAt',
    render: (elm) => dateToLabel(elm?.createdAt),
  },
];

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Incomplete Registrations', isActive: true },
];

const exportExcelSheet = (exportData) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Incomplete registrations');

  worksheet.columns = [
    { header: 'Investor name', key: 'fullName' },
    { header: 'Email', key: 'email' },
    { header: 'Email Verification', key: 'emailVerified' },
    { header: 'Date of Birth', key: 'dob' },
    { header: 'Phone Number', key: 'phoneNumber' },
    { header: 'Investor Type', key: 'investorType' },
    { header: 'Created Date', key: 'createdAt' },
  ];

  exportData.forEach((item) => {
    worksheet.addRow({
      fullName: item.fullName,
      email: item.email,
      emailVerified: item.isEmailVerify ? 'Verified' : 'Unverified',
      dob: item.dob ? dateofBirthToLabel(item.dob) : '',
      phoneNumber: item.phoneNumber,
      investorType: investorTypeLabel[item.investorType],
      createdAt: dateToLabel(item.createdAt),
    });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      'Incomplete registrations.xlsx',
    );
  });
};

const IncompleteRegistrations: React.FC = () => {
  const { firmId } = useGetRole();
  const pagination = usePagination({ id: 'incomplete-registrations-table' });
  const { filters, handleChangeFilters } = useFilters(
    'incomplete-registrations-table',
    {
      defaultFilters: {
        investorType: null,
        search: null,
      },
      onFiltersChange: pagination.toFirstPage,
    },
  );

  const debouncedFilters = useDebounce(filters, 400);

  const params = {
    firmId,
    //@ts-expect-error
    investorType: debouncedFilters.investorType?.id,
    search: debouncedFilters.search,
    page: pagination.page,
    perPage: pagination.rowsPerPage,
    type: pagination.order,
    keyName: pagination.orderBy,
  };

  const { data, isFetching } = useIncompleteRegistrations({ params });

  return (
    <PageContainer
      heading="Investors: Incomplete Registrations"
      breadcrumbs={breadcrumbs}
    >
      <PageContent>
        <PageFilters
          pageId="incomplete-registrations-table"
          buttons={[
            {
              label: 'Export',
              disabled: !data,
              onClick: () => exportExcelSheet(data?.orderByInvestor || []),
            },
          ]}
        >
          <Filter>
            <TextField
              type="search"
              name="newPassword"
              placeholder="Search by investor name or email"
              fullWidth
              value={filters.search}
              onChange={(event) => {
                //@ts-expect-error
                handleChangeFilters({ search: event.target.value });
              }}
            />
          </Filter>
          <Filter>
            <Autocomplete
              id="investorType"
              options={investorTypeDropdownList}
              getOptionLabel={(option) => option.label || ''}
              filterSelectedOptions
              value={filters.investorType}
              onChange={(_, newValue) => {
                //@ts-expect-error
                handleChangeFilters({ investorType: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select investor type"
                />
              )}
            />
          </Filter>
        </PageFilters>

        <Table
          columns={columns}
          onRequestSort={pagination.handleRequestSort}
          order={pagination.order}
          orderBy={pagination.orderBy}
          tablebody={data?.result || []}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          count={data?.total || 0}
          pagination={true}
          loading={isFetching}
          variant="nohover"
        />
      </PageContent>
    </PageContainer>
  );
};
export default IncompleteRegistrations;
