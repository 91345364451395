import GridContainer from 'components/GridContainer';
import Grid from '@mui/material/Grid2';
import { makeStyles } from 'tss-react/mui';
import DropzoneComponent from 'components/Dropzone';

import TextField from 'components/FormElements/AppTextInput';

const useStyles = makeStyles()(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  addBtn: {
    textAlign: 'right',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  label: {
    color: '#656565',
    fontSize: 12,
    marginBottom: 4,
    display: 'block',
  },
}));

function AddAccountManager({
  currentValue,
  errorCurrentValue,
  setCurrentValue,
  onDropFile,
}) {
  const { classes } = useStyles();

  return (
    <GridContainer>
      <Grid size={{ xs: 12 }}>
        <label className={classes.label} htmlFor="name">
          <strong>Name*</strong>
        </label>
        <TextField
          required
          name="name"
          placeholder="Enter name"
          fullWidth
          onChange={(event) =>
            setCurrentValue({ ...currentValue, name: event.target.value })
          }
          value={currentValue.name}
          error={errorCurrentValue?.name}
          helperText={errorCurrentValue?.name}
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <label className={classes.label} htmlFor="description">
          <strong>Background</strong>
        </label>
        <TextField
          name="description"
          placeholder="Enter the background of the investor here in approximately 50-75 words."
          fullWidth
          onChange={(event) =>
            setCurrentValue({
              ...currentValue,
              description: event.target.value,
            })
          }
          value={currentValue.description}
          error={errorCurrentValue?.description}
          helperText={errorCurrentValue?.description}
          multiline
          minRows={6}
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <label className={classes.label} htmlFor="profileImage">
          <strong>Profile image</strong> (Square, 1:1)
        </label>
        <DropzoneComponent
          name="profileImage"
          files={
            currentValue?.profileImage
              ? [{ preview: currentValue?.previewProfileImage, fileName: '' }]
              : []
          }
          onRemove={() =>
            setCurrentValue({
              ...currentValue,
              profileImage: null,
              previewProfileImage: null,
            })
          }
          onDropFile={(files) => onDropFile(files)}
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <label className={classes.label} htmlFor="order">
          <strong>Order</strong>
        </label>
        <TextField
          type="number"
          name="order"
          placeholder="Enter order"
          fullWidth
          onChange={(event) =>
            setCurrentValue({ ...currentValue, order: event.target.value })
          }
          value={currentValue.order}
          error={errorCurrentValue?.order}
          helperText={errorCurrentValue?.order}
        />
      </Grid>
    </GridContainer>
  );
}

export default AddAccountManager;
