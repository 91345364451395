import { status } from 'adminConstants/investorReporting';
import { PropsWithChildren, createContext, useContext, useState } from 'react';

type Props = {
  investorReport: Record<string, any> | null;
  isSuperAdmin: boolean;
  userFirmId?: string;
};

type ManagerOverview = {
  managerOverViewTitle: string;
  managerOverViewShortDescription: string;
  managerOverViewProvideCommentary: boolean;
  managerOverViewCommentary: string;
  managerOverViewAccountManagerId: string;
  managerOverViewRole: string;
};

type FundInformation = {
  copyFund: boolean;
  title: string;
  shortDescription: string;
  provideCommentary: boolean;
  commentary: string;
  accountManagerId: string;
  role: string;
};

type CompanyInformation = object;

type InvestorReportingFormContextType = {
  investorReport: Props['investorReport'];
  setInvestorReport: (investorReport: Props['investorReport']) => void;
  firmId?: string;
  submitDate: Date;
  managerOverview: ManagerOverview;
  fundInformation: FundInformation[];
  companyInformation: CompanyInformation[];
  showFirmDropdown: boolean;
  notifyInvestors: boolean;
  setFirmId: (firmId: string) => void;
  setManagerOverview: (managerOverview: ManagerOverview) => void;
  setFundInformation: (fundInformation: FundInformation[]) => void;
  setCompanyInformation: (companyInformation: CompanyInformation[]) => void;
  setSubmitDate: (submitDate: Date) => void;
  reportStatus: string;
  setNotifyInvestors: (notifyInvestors: boolean) => void;
  setAllActiveFundIds: (allActiveFundIds: string[]) => void;
  allActiveFundIds: string[];
};

const emptyManagerOverview = {
  managerOverViewTitle: '',
  managerOverViewShortDescription: '',
  managerOverViewProvideCommentary: false,
  managerOverViewCommentary: '',
  managerOverViewAccountManagerId: '',
  managerOverViewRole: '',
};

const emptyFundInformation = {
  title: '',
  shortDescription: '',
  copyFund: false,
  provideCommentary: false,
  accountManagerId: '',
  role: '',
  commentary: '',
};

const InvestorReportingFormContext =
  createContext<InvestorReportingFormContextType>({
    submitDate: new Date(),
    investorReport: null,
    setInvestorReport: () => undefined,
    showFirmDropdown: false,
    notifyInvestors: false,
    managerOverview: emptyManagerOverview,
    fundInformation: [],
    companyInformation: [],
    reportStatus: status.DRAFT,
    setFirmId: () => undefined,
    setManagerOverview: () => undefined,
    setFundInformation: () => undefined,
    setCompanyInformation: () => undefined,
    setSubmitDate: () => undefined,
    setNotifyInvestors: () => undefined,
    setAllActiveFundIds: () => undefined,
    allActiveFundIds: [],
  });

const getInitialCompanyUpdates = (investeeUpdates: any[]) => {
  investeeUpdates?.forEach((singleCompany) => {
    if (!singleCompany.copyFromCompanies) {
      singleCompany.copyFromCompanies = [];
    }
    if (typeof singleCompany.updatedSharePrice === 'number') {
      singleCompany.updatedSharePrice =
        singleCompany.updatedSharePrice.toString();
    }
  });
  return investeeUpdates;
};

const InvestorReportingFormContextProvider: React.FC<
  PropsWithChildren<Props>
> = ({
  children,
  investorReport: initialInvestorReport,
  userFirmId,
  isSuperAdmin,
}) => {
  const [investorReport, setInvestorReport] = useState(initialInvestorReport);
  const [firmId, setFirmId] = useState(investorReport?.firm?._id ?? userFirmId);
  const [submitDate, setSubmitDate] = useState<Date>(
    investorReport ? new Date(investorReport.submitDate) : new Date(),
  );
  const [managerOverview, setManagerOverview] = useState<ManagerOverview>({
    managerOverViewProvideCommentary:
      !!investorReport?.managerOverViewProvideCommentary,
    managerOverViewTitle: investorReport?.managerOverViewTitle ?? '',
    managerOverViewShortDescription:
      investorReport?.managerOverViewShortDescription ?? '',
    managerOverViewCommentary: investorReport?.managerOverViewCommentary ?? '',
    managerOverViewAccountManagerId:
      investorReport?.managerOverViewAccountManagerId ?? '',
    managerOverViewRole: investorReport?.managerOverViewRole ?? '',
  });
  const [fundInformation, setFundInformation] = useState<
    InvestorReportingFormContextType['fundInformation']
  >(investorReport?.fundUpdates ?? []);

  const [companyInformation, setCompanyInformation] = useState<
    InvestorReportingFormContextType['companyInformation']
  >(getInitialCompanyUpdates(investorReport?.investeeUpdates ?? []));

  const reportStatus = investorReport?.status ?? status.DRAFT;
  const [notifyInvestors, setNotifyInvestors] = useState(false);
  const [allActiveFundIds, setAllActiveFundIds] = useState<string[]>([]);

  const processFundInformation = (updatedFundInformation: FundInformation[]) =>
    fundInformation.map((fund, index) => {
      return updatedFundInformation[index].copyFund
        ? {
            ...fund,
            ...emptyFundInformation,
            copyFund: true,
          }
        : {
            ...fund,
            ...updatedFundInformation[index],
          };
    });

  const contextValue = {
    investorReport,
    setInvestorReport,
    firmId,
    setFirmId,
    submitDate,
    setSubmitDate,
    notifyInvestors,
    showFirmDropdown: isSuperAdmin && !investorReport?._id,
    managerOverview,
    setManagerOverview,
    fundInformation,
    setFundInformation,
    processFundInformation,
    companyInformation,
    setCompanyInformation,
    reportStatus,
    setNotifyInvestors,
    setAllActiveFundIds,
    allActiveFundIds,
  };

  return (
    <InvestorReportingFormContext.Provider value={contextValue}>
      {children}
    </InvestorReportingFormContext.Provider>
  );
};

export const useInvestorReportingFormContext = () =>
  useContext(InvestorReportingFormContext);

export default InvestorReportingFormContextProvider;
