import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert, Tooltip } from '@mui/material';
import PageContainer from 'components/PageContainer';
import { getQuestionList } from '@redux/actions/Investor';
import InvestorDetails from './InvestorDetails';
import InvestorCommunication from './InvestorCommunication';
import TaxAndBankDetails from './TaxAndBankDetails';
import InvestorAppropriateness from './InvestorAppropriateness';
import InvestorCertification from './InvestorCertification';
import KycResults from './KycResults';
import CoolOffStatus from './CoolOffStatus';
import InvestmentsTable from './InvestmentsTable';
import InvestorUpdates from './InvestorUpdates';
import DelegatedAccessUsers from './DelegatedAccessUsers';
import CashBalanceTable from './CashBalanceTable';
import InvestorChangeLog from './InvestorChangeLog';
import { downloadAppropriatenessPdf } from 'helpers/investor/downloadAppropriatenessPdf';
import { useInvestor } from 'hooks/data/investor/useInvestor';
import Notes from 'components/Notes';
import { NoteRelation } from 'further-types/notes';
import Chip from 'components/Chip';
import PageContent from 'components/PageContent';
import RecordView from 'components/RecordView';
import PageSection from 'components/PageSection';
import Spacing from 'components/Spacing';

const breadcrumbs = [
  { label: 'Dashboard' },
  { label: 'Investor', link: '/investor' },
  { label: 'View Investor', link: '/', isActive: true },
];

const InvestorDetail: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const [refetch, setRefetch] = useState(false);
  const [question, setQuestion] = useState([]);

  const { fetch } = useInvestor(id);
  const investorDetail = fetch.data;
  const { bankDetails } = investorDetail || {};

  useEffect(() => {
    const getQuestion = async () => {
      const resp = await dispatch<any>(getQuestionList());
      if (resp.status === 200) {
        setQuestion(resp.data.data);
      }
    };
    getQuestion();
  }, [dispatch]);

  const showDelegatedAccessUsers =
    investorDetail?.isEmailVerify &&
    //@ts-expect-error
    !investorDetail?.isAccountTemporary &&
    //@ts-expect-error
    !investorDetail?.isDisabled;

  if (!investorDetail && !fetch.isLoading)
    return <Alert severity="info">Investor not found</Alert>;

  return (
    <PageContainer
      heading="Investor: View Investor"
      breadcrumbs={breadcrumbs}
      loading={fetch.isLoading}
      subheaderComponent={
        //@ts-expect-error
        investorDetail?.isAccountTemporary && (
          <Tooltip title="This is a temporary account for a new investor that has been invited to make an advised subscription. When the investor completes their subscription, this account will become a live investor account.">
            <div>
              <Chip variant="success">Pending advised investor</Chip>
            </div>
          </Tooltip>
        )
      }
    >
      <PageContent>
        <RecordView size="full">
          <InvestorDetails investorDetail={investorDetail} id={id} />
          <PageSection title="Investor communications" showDivider>
            {/* @ts-expect-error */}
            <InvestorCommunication investorDetail={investorDetail} id={id} />
          </PageSection>
          <PageSection title="Tax and bank details" showDivider>
            <TaxAndBankDetails
              bankDetails={bankDetails ?? []}
              //@ts-expect-error
              investorDetails={investorDetail}
            />
          </PageSection>

          <PageSection
            title="Investor appropriateness and suitability"
            showDivider
          >
            <Spacing size="sm">
              {/* @ts-expect-error */}
              <InvestorCertification investorDetail={investorDetail} />
              <InvestorAppropriateness
                downloadQuestion={() =>
                  downloadAppropriatenessPdf(investorDetail, question)
                }
                id={id}
                investorDetail={investorDetail}
                refetchInvestor={() => setRefetch(!refetch)}
              />
              <CoolOffStatus id={id} />
            </Spacing>
          </PageSection>
          <PageSection showDivider>
            <KycResults
              investorId={id}
              //@ts-expect-error
              advisersList={investorDetail?.adviserDetail ?? []}
              fullName={investorDetail?.fullName}
              identityVerificationCertificates={
                //@ts-expect-error
                investorDetail?.identityVerificationCertificate
              }
              //@ts-expect-error
              accountType={investorDetail?.accountType}
            />
          </PageSection>

          <PageSection showDivider>
            <InvestmentsTable investorId={id} />
          </PageSection>
          <PageSection showDivider>
            {
              //@ts-expect-error
              (investorDetail?.cashBalances ?? []).map((cashBalance, index) => (
                <CashBalanceTable
                  key={`cash-balance-${index}`}
                  cashBalance={cashBalance}
                  investorDetails={investorDetail}
                />
              ))
            }
          </PageSection>

          <PageSection title="Investor updates" showDivider>
            <InvestorUpdates investorId={id} investorDetail={investorDetail} />
          </PageSection>

          {showDelegatedAccessUsers && (
            <PageSection showDivider>
              <DelegatedAccessUsers investorId={id} />
            </PageSection>
          )}
          <PageSection title="Change log" showDivider>
            <InvestorChangeLog investorId={id} />
          </PageSection>
          <PageSection showDivider>
            <Notes relationId={id} noteRelation={NoteRelation.Investor} />
          </PageSection>
        </RecordView>
      </PageContent>
    </PageContainer>
  );
};
export default InvestorDetail;
