import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import FieldRow from 'components/FormElements/FieldRow';

import Grid from '@mui/material/Grid2';
import AppSelectBox from 'components/FormElements/AppSelectBox';

import { NumericFormat } from 'react-number-format';
import { AppTextInput, FormLabel } from 'components/FormElements';
import { Button, Divider, IconButton, styled } from '@mui/material';
import { Fragment } from 'react';
import { Delete } from '@mui/icons-material';
import Spacing from 'components/Spacing';
import ButtonGroup from 'components/ButtonGroup';
import GridContainer from 'components/GridContainer';
import { InvestmentFirmFormData } from './schema';
import { Error } from 'components/FormElements/LayoutComponents';
import Row from 'components/Layout/Row';

type Fee = {
  amount: number;
  vat: number;
  rangeStart: number;
  rangeEnd: number;
  id: string;
};

const FeeButton = styled(Button)(() => ({
  padding: '0 !important',
}));

const WithdrawalFees = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<InvestmentFirmFormData>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'withdrawalFees',
  });

  return (
    <Spacing>
      <p>
        Use this section to manage any withdrawal fees. Multiple withdrawal fees
        can be set, if you have different fees for small or large withdrawals.
      </p>
      {!fields.length && (
        <ButtonGroup>
          <FeeButton
            variant="text"
            color="primary"
            onClick={() => {
              append({
                amount: 0,
                vat: 0,
                rangeStart: 0,
                rangeEnd: null,
              });
            }}
          >
            Add Fee
          </FeeButton>
        </ButtonGroup>
      )}
      {(fields as Fee[]).map((fee, index) => (
        <Fragment key={fee.id}>
          <FieldRow title="Enter withdrawal fee" centerTitle>
            <GridContainer>
              <Grid size={6}>
                <Controller
                  name={`withdrawalFees.${index}.amount`}
                  defaultValue={fee.amount}
                  control={control}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <Row centered spacing="sm">
                      <NumericFormat
                        {...rest}
                        inputRef={ref}
                        prefix="£"
                        onValueChange={({ value }) => {
                          onChange(+value);
                        }}
                        allowNegative={false}
                        decimalScale={2}
                        allowLeadingZeros={false}
                        thousandSeparator={true}
                        customInput={AppTextInput}
                        error={!!errors?.withdrawalFees?.[index]?.amount}
                        helperText={
                          errors?.withdrawalFees?.[index]?.amount?.message
                        }
                      />
                    </Row>
                  )}
                />
              </Grid>

              <Grid size={6}>
                <Controller
                  name={`withdrawalFees.${index}.vat`}
                  control={control}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <Row centered spacing="sm">
                      <FormLabel title="VAT" />
                      <AppSelectBox
                        required
                        {...rest}
                        inputRef={ref}
                        data={[
                          { label: '20% VAT', value: 20 },
                          { label: 'No VAT', value: 0 },
                        ]}
                        name="vat"
                        valueKey="value"
                        labelKey="label"
                        variant="outlined"
                        onChange={onChange}
                        error={!!errors?.withdrawalFees?.[index]?.vat}
                        helperText={
                          errors?.withdrawalFees?.[index]?.vat?.message
                        }
                      />
                    </Row>
                  )}
                />
              </Grid>
            </GridContainer>
          </FieldRow>

          <FieldRow title=" ">
            <FormLabel title="Range (if applicable - leave blank if fee applies to all withdrawals)" />
          </FieldRow>

          <FieldRow title=" ">
            <GridContainer>
              <Grid size={6}>
                <Controller
                  name={`withdrawalFees.${index}.rangeStart`}
                  defaultValue={fee.rangeStart}
                  control={control}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <Row centered spacing="sm">
                      <FormLabel title="From" />
                      <NumericFormat
                        {...rest}
                        inputRef={ref}
                        onValueChange={({ value }) => {
                          onChange(value ? +value : null);
                        }}
                        decimalScale={2}
                        prefix="£"
                        allowNegative={false}
                        allowLeadingZeros={false}
                        thousandSeparator={true}
                        customInput={AppTextInput}
                        error={!!errors?.withdrawalFees?.[index]?.rangeStart}
                        helperText={
                          errors?.withdrawalFees?.[index]?.rangeStart?.message
                        }
                      />
                    </Row>
                  )}
                />
              </Grid>

              <Grid size={6}>
                <Controller
                  name={`withdrawalFees.${index}.rangeEnd`}
                  defaultValue={fee.rangeEnd}
                  control={control}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <Row centered spacing="sm">
                      <FormLabel title="To" />
                      <NumericFormat
                        {...rest}
                        inputRef={ref}
                        onValueChange={({ value }) => {
                          onChange(value ? +value : null);
                        }}
                        decimalScale={2}
                        prefix="£"
                        allowNegative={false}
                        allowLeadingZeros={false}
                        thousandSeparator={true}
                        customInput={AppTextInput}
                        error={!!errors?.withdrawalFees?.[index]?.rangeEnd}
                        helperText={
                          errors?.withdrawalFees?.[index]?.rangeEnd?.message
                        }
                      />
                    </Row>
                  )}
                />
              </Grid>
            </GridContainer>
          </FieldRow>

          <ButtonGroup>
            <IconButton onClick={() => remove(index)} size="large">
              <Delete />
            </IconButton>

            {(index === fields.length - 1 || !fields.length) && (
              <FeeButton
                variant="text"
                color="primary"
                size="small"
                onClick={() => {
                  append({
                    // Set the new range to the previous fee's amount + 0.01
                    amount: fee.amount || 0,
                    vat: fee.vat || 0,
                    rangeStart: fee.rangeEnd ? fee.rangeEnd + 0.01 : null,
                    rangeEnd: null,
                  });
                }}
              >
                Add another fee and range
              </FeeButton>
            )}
          </ButtonGroup>

          {index !== fields.length - 1 && (
            <Divider variant="middle" sx={{ opacity: 0.6 }} />
          )}
        </Fragment>
      ))}
      {!!errors?.withdrawalFees?.root?.message && (
        <Error>{errors?.withdrawalFees?.root?.message}</Error>
      )}

      <FieldRow
        title="Withdrawal text"
        informationText="Use this field to explain the withdrawal fee to investors"
        centerTitle
      >
        <Controller
          name="withdrawalFeeText"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <AppTextInput
              {...rest}
              inputRef={ref}
              placeholder="e.g. There is a withdrawal fee of £10 for any withdrawals under £10,000."
              fullWidth
              multiline
              error={!!errors?.withdrawalFeeText}
              helperText={errors?.withdrawalFeeText?.message}
            />
          )}
        />
      </FieldRow>
    </Spacing>
  );
};

export default WithdrawalFees;
