import { AutocompleteProps, TextField } from '@mui/material';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import { useAdvisers } from 'hooks/data/adviser/useAdvisers';
import { useGetRole } from 'hooks/ui/useGetRole';
import { sortBy } from 'lodash';
import { useMemo } from 'react';

const labelRenderer = (option) =>
  option ? `${option.fullName ?? ''} (${option.email})` : 'Direct';

type Props = {
  adviser: any; //TODO: define type
  onChange: AutocompleteProps<any, any, any, any>['onChange'];
};

const AdviserSelect: React.FC<Props> = ({ adviser, onChange }) => {
  const { firmId } = useGetRole();
  const { data, isFetching } = useAdvisers({ params: { firmId } });

  const sortedOptions = useMemo(
    () => sortBy(data?.adviser, ['fullName']),
    [data?.adviser],
  );

  return !isFetching ? (
    <Autocomplete
      id="adviserId"
      name="adviserId"
      options={sortedOptions}
      getOptionLabel={labelRenderer}
      filterSelectedOptions
      value={adviser ?? null}
      style={{ maxWidth: 300 }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="N/A - Direct subscription"
        />
      )}
    />
  ) : (
    <span>Loading...</span>
  );
};

export default AdviserSelect;
