import React from 'react';
import Heading from 'components/Heading';
import PageLoader from 'components/PageLoader';
import Spacing from 'components/Spacing';

const ActionHistory: React.FC = () => {
  const isLoading = false;

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Spacing>
      <Heading variant="h3" noMargin>
        Action History
      </Heading>
    </Spacing>
  );
};

export default ActionHistory;
