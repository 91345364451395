import { FC, useState } from 'react';
import { Button, Menu, MenuItem, Tooltip, Switch } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { InfoOutlined, ArrowDropDown } from '@mui/icons-material';
import { useGetRole } from 'hooks/ui/useGetRole';
import { Alert } from '@mui/material';
import { Api } from 'further-types/eis-wizard';

const useStyles = makeStyles()(() => ({
  toggle: {
    display: 'flex',
    gridGap: '.5rem',
    alignItems: 'center',
    marginRight: 15,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
  },
  notifyButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  notifyInvestorWarning: {
    marginTop: 4,
    fontSize: 15,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: 0,
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationColor: '#b0b0b0',
    textUnderlineOffset: '2px',
    color: '#656565',

    '& svg': {
      marginTop: -2,
      marginLeft: 4,
      marginRight: 8,
    },
  },
}));

type FormDataType = Pick<
  Api.MutateEisWizardRequest,
  | 'companyId'
  | 'shareAllocationUploadId'
  | 'notifyInvestors'
  | 'createdWithoutCertificates'
>;

type Props = {
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
  handleCreateWithoutCertificates: (investmentType: string) => void;
  data: FormDataType;
  setData: (data: unknown) => void;
  isEditPage: boolean;
  isFinalStep: boolean;
};

const FormActionButtons: FC<Props> = ({
  activeStep,
  handleBack,
  handleNext,
  handleCreateWithoutCertificates,
  data,
  setData,
  isEditPage,
  isFinalStep,
}) => {
  const { classes } = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const { isSuperAdmin } = useGetRole();

  if (data.createdWithoutCertificates) {
    return (
      <div className={classes.buttonRow}>
        <Button color="primary" variant="outlined" onClick={handleBack}>
          Cancel
        </Button>
        <Alert severity="info">
          This allocation has been completed without generating certificates.
        </Alert>
      </div>
    );
  }

  return (
    <div className={classes.buttonRow}>
      <Button color="primary" variant="outlined" onClick={handleBack}>
        {activeStep === 0 ? 'Cancel' : 'Back'}
      </Button>
      <div className={classes.notifyButton}>
        {activeStep === 1 && !isEditPage && (
          <div className={classes.toggle}>
            <Tooltip title="If toggled, the system will send an email to each applicable investor notifying them that a new EIS certificate is available to view and download on their investor portal.">
              <span className={classes.notifyInvestorWarning}>
                Notify investors
                <InfoOutlined style={{ fontSize: 18 }} />
              </span>
            </Tooltip>
            <Switch
              checked={data?.notifyInvestors}
              onChange={(event: any) =>
                setData({
                  ...data,
                  notifyInvestors: event.target.checked,
                })
              }
            />
          </div>
        )}

        {isSuperAdmin && activeStep === 0 && !isEditPage && (
          <>
            <Button
              color="primary"
              variant="outlined"
              aria-controls="simple-menu"
              aria-haspopup="true"
              endIcon={<ArrowDropDown />}
              //@ts-expect-error
              onClick={(event) => setMenuAnchorEl(event.currentTarget)}
              disabled={!data.companyId || !data.shareAllocationUploadId}
              style={{ marginRight: '0.5rem' }}
            >
              Complete without certificates
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(null)}
            >
              <MenuItem onClick={() => handleCreateWithoutCertificates('EIS')}>
                EIS subscription
              </MenuItem>
              <MenuItem onClick={() => handleCreateWithoutCertificates('SEIS')}>
                SEIS subscription
              </MenuItem>
            </Menu>
          </>
        )}

        <Button
          color="primary"
          variant="contained"
          onClick={handleNext}
          disabled={!data.companyId || !data.shareAllocationUploadId}
        >
          {isFinalStep ? 'Submit' : 'Next'}
        </Button>
      </div>
    </div>
  );
};

export default FormActionButtons;
