import { FC, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, styled, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

const useStyles = makeStyles()({
  label: {
    padding: 0,
    margin: 0,
    fontSize: 13,
    letterSpacing: 0,
    fontWeight: 'bold',
    color: '#656565',
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.5rem',
  },
  informationText: {
    padding: 0,
    margin: '0 0 0 0',
    fontSize: 12,
    color: '#656565',
    width: '100%',
  },
  tooltipIcon: {
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: -2,
  },
  topTitle: {
    height: 35,
  },
});

const Container = styled(Box)({
  position: 'relative',
});

type FormLabelProps = {
  title: string;
  informationText?: string | ReactNode;
  tooltipText?: string | number | ReactNode;
  topTitle?: boolean;
  className?: string;
};

const FormLabel: FC<FormLabelProps> = ({
  title,
  informationText,
  tooltipText,
  topTitle, // topTitle prop aligns labels to match the top of the input fields (ck editor)
  className,
}) => {
  const { classes, cx } = useStyles();

  const tooltip = tooltipText ? (
    <Tooltip leaveDelay={250} arrow title={tooltipText}>
      <InfoOutlined className={classes.tooltipIcon} />
    </Tooltip>
  ) : null;

  return (
    <Container className={className}>
      <label className={cx(classes.label, topTitle && classes.topTitle)}>
        {title}
        {tooltip}
      </label>
      {informationText && (
        <span className={classes.informationText}>{informationText}</span>
      )}
    </Container>
  );
};

export default FormLabel;
