import React from 'react';
import FieldRow from 'components/FormElements/FieldRow';
import GridContainer from 'components/GridContainer';
import Grid from '@mui/material/Grid2';
import TextField from 'components/FormElements/AppTextInput';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { Controller } from 'react-hook-form';
import useStyles from './styles';

type Props = any;

const AdditionalMetric: React.FC<Props> = ({
  index,
  metricIndex,
  metric,
  handleRemove,
  disabled,
  control,
}) => {
  const { classes } = useStyles();

  return (
    <FieldRow title={`Additional metric ${metricIndex + 1}`}>
      <GridContainer key={metricIndex}>
        <Grid
          size={{
            md: 5,
            xs: 5,
          }}
        >
          <Controller
            name={`companyInformation[${index}][additionalMetrics][${metricIndex}][key]`}
            defaultValue={metric?.key}
            control={control}
            key={`${metricIndex}_${metric?.key}`}
            render={({ field: { ref, ...rest }, formState: { errors } }) => (
              <TextField
                inputRef={ref}
                {...rest}
                required
                placeholder="Enter key"
                fullWidth
                error={
                  !!errors?.companyInformation?.[index]?.additionalMetrics?.[
                    metricIndex
                  ]?.key
                }
                helperText={
                  errors?.companyInformation?.[index]?.additionalMetrics?.[
                    metricIndex
                  ]?.key?.message
                }
                variant="outlined"
                disabled={disabled}
                data-testid={`metric-key-${index}-${metricIndex}`}
              />
            )}
          />
        </Grid>
        <Grid
          size={{
            md: 5,
            xs: 5,
          }}
        >
          <Controller
            name={`companyInformation[${index}][additionalMetrics][${metricIndex}][value]`}
            defaultValue={metric?.value}
            control={control}
            key={`${metricIndex}_${metric?.value}`}
            render={({ field: { ref, ...rest }, formState: { errors } }) => (
              <TextField
                inputRef={ref}
                {...rest}
                required
                placeholder="Enter value"
                fullWidth
                error={
                  !!errors?.companyInformation?.[index]?.additionalMetrics?.[
                    metricIndex
                  ]?.key
                }
                helperText={
                  errors?.companyInformation?.[index]?.additionalMetrics?.[
                    metricIndex
                  ]?.value?.message
                }
                variant="outlined"
                disabled={disabled}
                data-testid={`metric-value-${index}-${metricIndex}`}
              />
            )}
          />
        </Grid>
        <Grid
          size={{
            md: 2,
            xs: 2,
          }}
        >
          <IconButton
            size="small"
            className={classes.deleteButton}
            onClick={() => handleRemove(metricIndex)}
            disabled={disabled}
            data-testid={`metric-delete-${index}-${metricIndex}`}
          >
            <Delete />
          </IconButton>
        </Grid>
      </GridContainer>
    </FieldRow>
  );
};

export default AdditionalMetric;
