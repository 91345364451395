import { useMutation } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import downloadFile from 'utils/downloadFile';
import { Api } from 'further-types/companies';

async function exportCompanies(query: Api.ExportCompanyListQuery) {
  const response = await api.get(`investee/list/export`, {
    params: query,
    responseType: 'blob',
  });
  if (response.status === 200) {
    downloadFile(response.data, `Company-${Date.now()}.xlsx`);
    return response.data;
  }
  throw new Error('Export failed');
}

function useExportCompanies() {
  return useMutation<Blob, Error, Api.ExportCompanyListQuery>({
    mutationFn: exportCompanies,
  });
}

export default useExportCompanies;
