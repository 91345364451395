import { Skeleton, CardContent, Card } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Link } from 'react-router-dom';
import React from 'react';
import { BusinessCenter, HowToReg, PeopleOutline } from '@mui/icons-material';
import Box from 'components/Box';
import { numberToDisplayString } from 'utils/numbers';
import {
  ADVISER,
  INVESTOR,
  ORGANISATION,
  COMPANY,
} from 'adminConstants/routes';
import useEntitySummary from 'hooks/data/dashboards/useEntitySummary';
import Heading from 'components/Heading';
import LoadingError from '../LoadingError';
import useInitialElementSize from 'hooks/ui/useInitialElementSize';

type Props = {
  parentClasses: Record<string, string>;
  firmId: string;
};

const EntitySummary: React.FC<Props> = ({ parentClasses, firmId }) => {
  const { data, isFetching, isRefetching, isError } = useEntitySummary({
    firmId,
  });

  const isLoading = isFetching || isRefetching;

  const [ref, { height }] = useInitialElementSize();
  if (isError) {
    return <LoadingError height={height} width="100%" />;
  }

  return (
    <>
      <Grid size={{ sm: 6, md: 3, xs: 12 }} ref={ref}>
        <Card>
          <CardContent>
            <Heading variant="h3" showDivider icon={HowToReg}>
              Investors
            </Heading>
            <Box
              title={
                isLoading ? (
                  <Skeleton animation="wave" variant="text" />
                ) : (
                  //@ts-expect-error
                  numberToDisplayString(data?.investors)
                )
              }
              subtitle={
                <span>
                  <Link to={INVESTOR} className={parentClasses.link}>
                    View all
                  </Link>
                </span>
              }
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid size={{ sm: 6, md: 3, xs: 12 }}>
        <Card>
          <CardContent>
            <Heading variant="h3" showDivider icon={PeopleOutline}>
              Advisers
            </Heading>
            <Box
              title={
                isLoading ? (
                  <Skeleton animation="wave" variant="text" />
                ) : (
                  //@ts-expect-error
                  numberToDisplayString(data?.advisers)
                )
              }
              subtitle={
                <span>
                  <Link to={ADVISER} className={parentClasses.link}>
                    View all
                  </Link>
                </span>
              }
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid size={{ sm: 6, md: 3, xs: 12 }}>
        <Card>
          <CardContent>
            <Heading variant="h3" showDivider icon={PeopleOutline}>
              Organisations
            </Heading>
            <Box
              title={
                isLoading ? (
                  <Skeleton animation="wave" variant="text" />
                ) : (
                  //@ts-expect-error
                  numberToDisplayString(data?.organisations)
                )
              }
              subtitle={
                <span>
                  <Link to={ORGANISATION} className={parentClasses.link}>
                    View all
                  </Link>
                </span>
              }
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid size={{ sm: 6, md: 3, xs: 12 }}>
        <Card>
          <CardContent>
            <Heading variant="h3" showDivider icon={BusinessCenter}>
              Companies
            </Heading>
            <Box
              title={
                isLoading ? (
                  <Skeleton animation="wave" variant="text" />
                ) : (
                  //@ts-expect-error
                  numberToDisplayString(data?.investees)
                )
              }
              subtitle={
                <span>
                  <Link to={COMPANY} className={parentClasses.link}>
                    View all
                  </Link>
                </span>
              }
            />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default EntitySummary;
