import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
  SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import Button from 'components/Button';
import { Row } from 'components/Layout';
import { useRef, useState } from 'react';

const Action = styled(Row)(({ theme }) => ({
  color: theme.palette.text?.primary,
}));

export type DropdownAction = {
  label: string;
  icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
  onClick?: () => void;
  disabled?: boolean;
  color?:
    | 'error'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning';
};

type Props = {
  id: string;
  title: string;
  actions: Array<DropdownAction>;
};

const DropdownActions: React.FC<Props> = ({ id, title, actions }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="Dropdown actions"
      >
        <Button onClick={handleToggle}>{title}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="Dropdown actions"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id={id} autoFocusItem>
                  {actions.map(
                    ({
                      label,
                      icon: Icon,
                      onClick,
                      disabled,
                      color = 'primary',
                    }) => (
                      <MenuItem
                        key={`dropdown-action-${id}-${label}`}
                        onClick={onClick}
                        disabled={disabled}
                      >
                        <Action centered spacing="xs">
                          {Icon && <Icon color={color} />}
                          {label}
                        </Action>
                      </MenuItem>
                    ),
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DropdownActions;
