import { FC } from 'react';
import {
  useMutateAdminUserOrganisation,
  useOrganisationMemberships,
} from 'hooks/data/adminUser/useOrganisationMemberships';
import Table from 'components/Table';
import { Avatar, Box, Divider, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { Api } from 'further-types/admin-user-organisation-membership';
import Chip from 'components/Chip';
import AddOrganisationMembership from './AddOrganisationMembership';
import Heading from 'components/Heading';
import Spacing from 'components/Spacing';
import { useDisclosure } from 'further-ui/hooks';
import AlertDialog from 'components/AlertDialog';
import Button from 'components/Button';

type Props = {
  adminUserId: string;
};

const OrganisationMembershipsTable: FC<Props> = ({ adminUserId }) => {
  const { data, isLoading } = useOrganisationMemberships(adminUserId);
  const { remove } = useMutateAdminUserOrganisation(adminUserId);
  const deleteDisclosure = useDisclosure<{ auth0OrgId: string }>();

  const columns = [
    {
      label: 'Organisation',
      key: 'display_name',
      render: (row: Api.UserOrganisationMembership) => (
        <Box display="flex" alignItems="center" gap={4}>
          <Avatar src={row.logoUrl} alt={row.displayName} />
          {row.displayName}
        </Box>
      ),
    },
    {
      label: 'Realm',
      key: 'realm',
      render: (row: Api.UserOrganisationMembership) => (
        <Chip variant="success">{row.realm}</Chip>
      ),
    },
    {
      label: 'Role',
      key: 'role',
      render: (row: Api.UserOrganisationMembership) =>
        row.roles?.map((role) => role.name).join(', '),
    },
    {
      label: 'Actions',
      key: 'actions',
      render: (row: Api.UserOrganisationMembership) => (
        <IconButton
          color="error"
          onClick={() =>
            deleteDisclosure.stageAction({ auth0OrgId: row.auth0OrgId })
          }
        >
          <Delete />
        </IconButton>
      ),
    },
  ];

  const onRemove = () => {
    const { auth0OrgId } = deleteDisclosure.actionProps ?? {};
    remove.mutate({ auth0OrgId }, { onSuccess: deleteDisclosure.onClose });
  };

  return (
    <>
      <Spacing>
        <Heading variant="h4">User's organisation memberships</Heading>
        <Table
          columns={columns}
          tablebody={data ?? []}
          loading={isLoading}
          emptyMessage="User not linked to any organisations. They will not be able to use the platform."
        />

        <Divider />
        <Spacing>
          <Heading variant="h4">Add organisation membership</Heading>
          <AddOrganisationMembership
            adminUserId={adminUserId}
            currentOrgMemberships={data?.map((org) => org.auth0OrgId) ?? []}
          />
        </Spacing>
      </Spacing>
      <AlertDialog
        open={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
        confirmBtnComponent={
          <Button
            color="primary"
            variant="contained"
            onClick={onRemove}
            loading={remove.isPending}
          >
            Remove
          </Button>
        }
        title="Remove user from organisation?"
        content="They will no longer have access to the organisation's data."
      />
    </>
  );
};

export default OrganisationMembershipsTable;
