import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/material';
import { Card, CardContent } from '@mui/material';

const LoadingErrorContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  gap: '10px',
  svg: {
    fontSize: '40px',
  },
}));

type Props = {
  height?: number | string;
  width?: number | string;
  showCard?: boolean;
};

const LoadingErrorContent: React.FC<Props> = ({ height, width }) => {
  return (
    <LoadingErrorContainer style={{ height, width }}>
      <ErrorOutlineIcon />
      <p>
        <strong>Sorry, something went wrong!</strong>
      </p>
      <p>Please try again.</p>
    </LoadingErrorContainer>
  );
};

const LoadingError: React.FC<Props> = ({ height, width, showCard = true }) => {
  if (!showCard) {
    return <LoadingErrorContent height={height} width={width} />;
  }

  return (
    <Card style={{ height, width }}>
      <CardContent>
        <LoadingErrorContent height={height} width={width} />
      </CardContent>
    </Card>
  );
};

export default LoadingError;
