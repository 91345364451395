export const adviserOnlyLinkedToSingleFirm = (
  adviserData: {
    associatedFirms: { _id: string }[];
    hasMoreThanOneFirm: boolean;
    allInvestmentsWithSingleFirm: boolean;
  },
  firmId: string,
): boolean => {
  const { associatedFirms, allInvestmentsWithSingleFirm, hasMoreThanOneFirm } =
    adviserData || {};

  if (!associatedFirms?.length || !allInvestmentsWithSingleFirm) {
    return false;
  }

  // This works because we will always filter down to the firms that the user can see.
  return !hasMoreThanOneFirm && associatedFirms[0]._id === firmId;
};
