import { styled } from '@mui/material';

export const FooterRow = styled('tr')({
  backgroundColor: '#fbfbfa',
  '&:nth-of-type(2)': {
    borderBottom: '1px solid #ededed',
    borderTop: '1px solid #ededed',
  },
});

export const FooterCell = styled('td')({
  fontWeight: 'bold',
  padding: '8px 8px 8px 6px',

  '&:nth-of-type(1)': {
    textAlign: 'right',
    paddingRight: 24,
  },
});
