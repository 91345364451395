import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Typography, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import InvestorSearch from 'components/InvestorSearch';
import { INVESTOR_ADD } from 'adminConstants/routes';
import useStyles from './styles';
import { InfoOutlined } from '@mui/icons-material';

type Props = {
  recipientId: string | null;
  handleRecipientSelect: (investorId?: string) => void;
  firmId: string | null;
};

const InvestorSearchRow: FC<Props> = ({
  recipientId,
  handleRecipientSelect,
  firmId,
}) => {
  const { classes } = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <Grid
      size={{ xs: 12 }}
      container
      direction="row"
      alignItems="center"
      className={classes.investorSearchRow}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        size={{ xs: 12, sm: 'grow' }}
        className={classes.investorSearch}
      >
        <Grid>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            component="label"
            htmlFor="investorSearch"
          >
            Select investor
          </Typography>
        </Grid>
        <Grid size={{ xs: 'auto', sm: 'auto', md: 6 }}>
          <InvestorSearch
            //@ts-expect-error
            selectedInvestorId={recipientId}
            onInvestorSelect={handleRecipientSelect}
            //@ts-expect-error
            firmId={firmId}
          />
        </Grid>
      </Grid>

      <Grid size={{ xs: 12, sm: 'auto' }}>
        <Box display="flex" alignItems="center" flexDirection="row" gap={10}>
          <Tooltip
            title="If you click to add a new investor, you will be returned to this
            screen once the investor creation process is complete."
          >
            <InfoOutlined />
          </Tooltip>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap={10}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() =>
                history.push(INVESTOR_ADD, {
                  returnTo: pathname,
                })
              }
            >
              Add new investor
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default InvestorSearchRow;
