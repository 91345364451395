import { Control, Controller } from 'react-hook-form';
import { InvestorFormValues } from 'helpers/investor/investorForm';
import { styled, Switch } from '@mui/material';

const StyledSwitch = styled(Switch)({
  marginLeft: 10,
  marginRight: 10,
});

const SwitchField: React.FC<{
  name: string;
  control: Control<InvestorFormValues>;
}> = ({ name, control }) => {
  return (
    <Controller
      name={name as keyof InvestorFormValues}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, value, ...rest } }) => (
        <StyledSwitch
          {...rest}
          checked={!!value}
          value={value}
          inputProps={{ 'aria-label': 'Switch' }}
        />
      )}
    />
  );
};

export default SwitchField;
