import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar } from '@mui/material';
import { Delete, Edit, Dvr } from '@mui/icons-material';
import TextField from 'components/FormElements/AppTextInput';
import Table, { TableActions } from 'components/Table';
import PageContainer from 'components/PageContainer';
import { FrontEndSiteSubdomainUrlToStageMap } from 'adminConstants/redirectConstant';
import { deleteFirm } from '@redux/actions/InvestmentFirm';
import {
  createFirmEditRoute,
  createLpFirmEditRoute,
  FIRM_ADD,
  LP_FIRM_ADD,
} from 'adminConstants/routes';
import { useFirm } from 'hooks/data/firm/useFirm';
import AlertDialog from 'components/AlertDialog';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useDebounce } from 'usehooks-ts';
import { usePagination } from 'hooks/ui/usePagination';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import useFilters from 'hooks/ui/useFilters';
import PageContent from 'components/PageContent';
import PageFilters, { Filter } from 'components/PageFilters';
import { Row } from 'components/Layout';
import { Api } from 'further-types/firm';

const stage = process.env.REACT_APP_STAGE;

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Firms', isActive: true },
];

const InvestmentFirm = () => {
  const dispatch = useDispatch();
  const handleApiRequest = useApiRequestHandler();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [record, setRecord] = useState();
  const [deleted, setDeleted] = useState(false);
  const [createPermission, updatePermission, deletePermission] =
    useGetPermissions(['create:firm', 'edit:firm', 'delete:firm']);
  const { firmId } = useGetRole();

  const pagination = usePagination({
    id: 'firms-table',
  });
  const { filters, handleChangeFilters } = useFilters('firms-table', {
    defaultFilters: {
      search: '',
    },
    onFiltersChange: pagination.toFirstPage,
  });

  const debouncedSearch = useDebounce(filters.search, 200);

  const { firms } = useFirm({
    params: {
      page: pagination.page,
      perPage: pagination.rowsPerPage,
      firmId,
      type: pagination.order,
      keyName: pagination.orderBy,
      search: debouncedSearch,
    },
  });

  const handleDelete = (rec) => {
    setRecord(rec);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleConfirmDelete = () => {
    const firm = firms.data?.result;
    handleApiRequest(async (showSuccess) => {
      // @ts-expect-error dispatch to be removed soon
      const response = await dispatch<{
        status: number;
        data?: { responseMsg: string };
      }>(
        //@ts-expect-error
        deleteFirm(record?._id),
      );
      if (response.status === 200) {
        showSuccess(response.data?.responseMsg ?? '');
        if (firm?.length === 1) {
          pagination.pageBack();
        } else {
          setDeleted(!deleted);
        }
      }
    });

    setOpenConfirmDialog(false);
  };

  const columns = [
    {
      label: 'Firm name',
      key: 'firmName',
      render: (elm: Api.Firm) => (
        <Row centered spacing="xs">
          <Avatar src={elm?.investmentFirmLogo} />
          <div>{elm?.firmName}</div>
        </Row>
      ),
    },
    {
      label: 'Key contact name',
      key: 'keyContactName',
    },
    {
      label: 'Key contact email',
      key: 'keyContactEmail',
    },
    {
      label: 'Actions',
      key: '',
      sort: false,
      render: (elm: Api.Firm) => (
        <TableActions
          showAsDropdown
          actions={
            elm?.isLpFirm
              ? [
                  {
                    visible: updatePermission,
                    label: 'Edit',
                    icon: Edit,
                    color: 'primary',
                    link: createLpFirmEditRoute(elm._id),
                  },
                ]
              : [
                  {
                    visible: updatePermission,
                    label: 'Edit',
                    icon: Edit,
                    color: 'primary',
                    link: createFirmEditRoute(elm._id),
                  },
                  {
                    visible: !!elm?.domain,
                    label: 'Copy URL',
                    icon: Dvr,
                    color: 'primary',
                    onClick: () => {
                      navigator.clipboard.writeText(
                        //@ts-expect-error
                        FrontEndSiteSubdomainUrlToStageMap[stage].replace(
                          '{domain}',
                          elm?.domain,
                        ),
                      );
                    },
                  },
                  {
                    visible: deletePermission,
                    label: 'Delete',
                    icon: Delete,
                    color: 'error',
                    onClick: () => handleDelete(elm),
                  },
                ]
          }
        />
      ),
    },
  ];

  return (
    <PageContainer heading="Firms" breadcrumbs={breadcrumbs}>
      <PageContent>
        <PageFilters
          pageId="firms-table"
          buttons={[
            {
              visible: createPermission,
              label: 'Add S/EIS Firm',
              link: FIRM_ADD,
            },
            {
              visible: createPermission,
              label: 'Add LP Firm',
              link: LP_FIRM_ADD,
            },
          ]}
        >
          <Filter>
            <TextField
              type="search"
              name="search"
              placeholder="Search by name or email"
              fullWidth
              value={filters.search}
              onChange={(event) => {
                handleChangeFilters({ search: event.target.value });
              }}
            />
          </Filter>
        </PageFilters>

        <Table
          columns={columns}
          onRequestSort={pagination.handleRequestSort}
          order={pagination.order}
          orderBy={pagination.orderBy}
          tablebody={firms.data?.result ?? []}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          count={firms.data?.total}
          pagination={true}
          loading={firms.isFetching}
          variant="nohover"
        />
      </PageContent>
      <AlertDialog
        open={openConfirmDialog}
        onClose={handleDelete}
        onConfirm={handleConfirmDelete}
        title="Are you sure you wish to delete this firm?"
        content={
          <p>
            This cannot be undone and all information relating to the firm will
            be lost.
          </p>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Delete firm',
        }}
      />
    </PageContainer>
  );
};

export default InvestmentFirm;
