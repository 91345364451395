import { red } from '@mui/material/colors';
import { createTheme, Direction, Theme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import {
  sectionCellClass,
  sectionSummaryCellClass,
  summaryCellClass,
} from 'components/InteractiveTable/helpers';
import './types';

const marginClasses = {};
for (let count = 1; count <= 8; count++) {
  marginClasses[`.mr-${count}`] = { marginRight: `${count * 4}px !important` };
  marginClasses[`.mt-${count}`] = { marginTop: `${count * 4}px !important` };
  marginClasses[`.mb-${count}`] = { marginBottom: `${count * 4}px !important` };
}

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 4,
  direction: 'ltr' as Direction,
  sidebar: {
    width: 304,
    drawerBreakpoint: 'lg',
  },
  fontSizes: {
    regular: 14,
    lg: 16,
    xl: 20,
    xxl: 22,
  },
  palette: {
    mode: 'light',
    common: {
      black: '#000',
      white: '#fff',
      dark: '#020202',
      brandColor: '#56b26c',
    },
    primary: {
      50: '#e6f4e9',
      100: '#c3e3c9',
      200: '#9cd0a7',
      300: '#74bf84',
      400: '#56b26c',
      500: '#37a553',
      600: '#2f9649',
      700: '#25853e',
      800: '#1c7434',
      900: '#085521',
    },
    secondary: red,
    sidebar: {
      bgColor: '#fff',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#56B26C',
      navHoverBgColor: 'rgb(229, 229, 229)',
      navActiveBgColor: 'rgb(239, 229, 253)',
      borderColor: 'rgba(33, 33, 33, 0.08)',
    },
    background: {
      paper: '#FFFFFF',
      default: '#F4F1EF',
      rootColor: '#56B26C',
      header: '#FBFBFA',
      danger: '#D71C1C',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.3)',
      white: '#fff',
      red: '#ee0432',
      green: '#56B26C',
      rootColor: '#56B26C',
      error: '#E00930',
      tableText: '#212121',
      txtLight: 'rgba(0, 0, 0, 0.60)',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.06)',
      dark: 'rgba(0, 0, 0, 0.12)',
      white: '#fff',
      rootColor: '#56B26C',
    },
    error: {
      main: '#E8453C',
    },
  },
  typography: {
    fontFamily: 'MaisonNeue',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 'bold',
    fontSize: 12.25,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: ({ theme }) => ({
          fontSize: theme.fontSizes?.xl,
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.text.primary,

          [theme.breakpoints.up('md')]: {
            fontSize: theme.fontSizes?.xxl,
          },
        }),
        h2: ({ theme }) => ({
          fontSize: 18,
          fontWeight: 'bold',
          letterSpacing: 0,
          color: theme.palette.text.secondary,
          [theme.breakpoints.up('md')]: {
            fontSize: 20,
          },
        }),
        h3: {
          fontSize: 18,
          fontWeight: 'bold',
        },
        h4: {
          fontSize: 18,
          fontWeight: 'bold',
        },
        h5: {
          fontSize: 14,
          fontWeight: 400,
        },
        h6: ({ theme }) => ({
          fontSize: theme.fontSizes?.regular,
          fontWeight: theme.typography.fontWeightBold,
        }),
        subtitle1: ({ theme }) => ({
          letterSpacing: 0.1,
          marginBottom: 0,
          marginTop: 4,
          fontSize: 12,
          color: theme.palette.text.rootColor,
          fontWeight: theme.typography.fontWeightRegular,
        }),
        subtitle2: {
          fontSize: 14,
          fontWeight: 'bold',
          letterSpacing: 0.1,
        },
        body1: {
          fontSize: 16,
          fontWeight: 400,
        },
        body2: {
          fontSize: 14,
          fontWeight: 400,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: ({ theme }) => ({
          width: theme.sidebar?.width,
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          minHeight: 37,
          padding: '1px 12px 0 !important',

          '.Mui-focused': {
            borderColor: '#56b26c!important',
          },
        },
        input: {
          padding: '0 !important',
        },
        multiline: {
          paddingTop: '10.5px!important',
          paddingBottom: '10.5px!important',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          padding: '4px 0 3px 0',
        },
        underline: ({ theme }) => ({
          '&:before': {
            borderColor: theme.palette.borderColor?.dark,
          },
        }),
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: '#56B26C',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          letterSpacing: '0',
          fontSize: 14,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          letterSpacing: '0',
          fontSize: 14,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: '#56B26C',
          },
          '&.Mui-active': {
            color: '#56B26C',
          },
        },
        active: {},
        completed: {},
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { size: 'extra-small' },
              style: {
                padding: 0,
              },
            },
          ],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          letterSpacing: 0,
          fontSize: 15,
          borderRadius: 3,
          textTransform: 'none',
          boxShadow: 'none',
          padding: '10px 16px 6px',
          lineHeight: '22px',

          variants: [
            {
              props: { size: 'extra-small' },
              style: {
                padding: '3px 16px 0px',
                fontSize: '0.8125rem',
                lineHeight: '16px',
              },
            },
          ],
        },
        containedPrimary: ({ theme }) => ({
          backgroundColor: theme.palette.background.rootColor,
          padding: '6px 16px 3px',
          lineHeight: '29px',
          '&:hover': {
            backgroundColor: theme.palette.background.rootColor,
            opacity: 0.9,
          },
          '&:disabled': {
            backgroundColor: theme.palette.background.rootColor,
            opacity: 1,
          },

          variants: [
            {
              props: { size: 'extra-small' },
              style: {
                padding: '3px 16px 0px',
                fontSize: '0.8125rem',
                lineHeight: '16px',
              },
            },
          ],
        }),
        sizeSmall: {
          fontSize: '0.8125rem',
        },
        textPrimary: {
          color: '#56b26c !important',
        },
        outlinedPrimary: {
          color: '#56b26c',
          border: '1px solid #56b26c',
          '&:hover': {
            borderColor: '#56b26c',
            backgroundColor: 'rgba(86, 178, 108, 0.06)',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: '40px',
          width: '40px',
          color: '#ffffff',
          backgroundColor: theme.palette.grey[400],
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          position: 'relative',
          boxShadow: '2px 20px 40px #EBEBEC',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 24,
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          zIndex: 1,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: 'rgb(204,204,204)',
        },
        tooltip: ({ theme }) => ({
          backgroundColor: 'white',
          border: '1px solid rgb(204,204,204)',
          fontSize: 15,
          fontWeight: '600',
          padding: theme.spacing(5),
          color: '#333',
          boxShadow: '0 0 12px 0px rgba(0,0,0,0.15)',

          variants: [
            {
              props: { wide: true },
              style: {
                maxWidth: '650px',
                width: '650px',
              },
            },
          ],
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          variants: [
            {
              props: { showDivider: true },
              style: {
                borderBottom: `1px solid ${theme.palette.borderColor?.dark}`,
              },
            },
          ],
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),

          '& svg': {
            color: theme.palette.text?.secondary,
          },

          '&.Mui-selected svg': {
            color: theme.palette.text?.rootColor,
          },
        }),
        textColorPrimary: {
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF',
          variants: [
            {
              props: { maxWidth: 'md' },
              style: {
                maxWidth: '500px',
              },
            },
            {
              props: { maxWidth: 'sm' },
              style: ({ theme }) => ({
                [theme.breakpoints.up('md')]: {
                  minWidth: '400px',
                },
              }),
            },
          ],
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          margin: 0,
          boxShadow: 'none',
          border: '1px solid rgba(224,224,224,1)',
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: '20px 0 !important',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: ({ theme }) => ({
          color: theme.palette.text.rootColor,
        }),
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginTop: 0,
          padding: '30px',
          backgroundColor: theme.palette.background.rootColor,
          color: theme.palette.text.white,
        }),
        switchViewIcon: ({ theme }) => ({
          color: theme.palette.text.white,
          fontWeight: 600,
          fontSize: '24px',
        }),
        labelContainer: {
          fontWeight: 600,
          fontSize: '16px',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: theme.typography.fontSize,
        }),
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        button: ({ theme }) => ({
          color: theme.palette.text.white,
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          maxWidth: '75%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: '0 !important',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        focused: ({ theme }) => ({
          color: theme.palette.text.rootColor,
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 32,
          height: 20,
          padding: 0,
        },
        switchBase: {
          padding: 0,
          margin: 2,
          top: '-0.1px',
          left: '-0.3px',
          transitionDuration: '200ms',

          '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',

            '& + .MuiSwitch-track': {
              backgroundColor: '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '4.5px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': ({ theme }) => ({
            color: theme.palette.grey[100],
          }),
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
          },
        },
        thumb: {
          boxSizing: 'border-box',
          width: 16.5,
          height: 16.5,
          boxShadow: 'none',
        },
        track: ({ theme }) => ({
          borderRadius: 10,
          backgroundColor: '#DADADA',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          paddingTop: 1,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          p: {
            bottom: '-22px',
            position: 'absolute',
            margin: 0,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          '&.Mui-checked': {
            color: `${theme.palette.text.rootColor} !important`,
          },
        }),
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        outlinedPrimary: ({ theme }) => ({
          '&.Mui-selected': {
            color: theme.palette.text.rootColor,
            border: '1px solid rgb(86 178 108)!important',
            backgroundColor: 'rgb(86 178 108 / 6%)!important',
            paddingTop: '4px!important',
          },
        }),
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: ({ theme }) => ({
          fontSize: theme.fontSizes?.regular,
        }),
        displayedRows: ({ theme }) => ({
          fontSize: theme.fontSizes?.regular,
        }),
        select: {
          paddingBottom: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          paddingTop: '6px!important',
          '&.Mui-checked': {
            color: theme.palette.text.rootColor,
          },
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(6),
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: 'IBMPlexSans',
          [`& .${sectionCellClass}`]: {
            fontWeight: theme.typography.fontWeightBold,
            overflow: 'visible',
          },

          [`& .${summaryCellClass}`]: {
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.background.default,
          },
          [`& .${sectionSummaryCellClass}`]: {
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.grey[100],
          },
        }),
      },
    },
  },
});

export const globalStyles = (theme: Theme) => ({
  '*': {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
  },
  '.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable)':
    {
      minHeight: '100px',
    },
  '.ck.ck-editor__editable_inline': {
    border: '1px solid #c4c4c4 !important',
    borderTop: 'none !important',
  },
  '.ck .ck-placeholder:before, .ck.ck-placeholder:before': {
    color: '#bababa !important',
  },
  '.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable)': {
    border: `1px solid #56b26c !important`,
  },
  '.ck-content.text-component p + p': {
    marginTop: theme.spacing(1),
  },
  '.ck-content.text-component p': {
    margin: 0,
  },
  '.ck-content p': {
    marginBottom: theme.spacing(1),
  },
  a: {
    textDecoration: 'none',
    '&:hover,&:focus': {
      textDecoration: 'none',
    },
    '&.disable-link': {
      pointerEvents: 'none',
      cursor: 'default',
      [theme.breakpoints.down('sm')]: {
        pointerEvents: 'inherit',
        cursor: 'pointer',
      },
    },
  },
  img: {
    maxWidth: '100%',
  },
  '.pointer': {
    cursor: 'pointer',
  },
  '.rounded': {
    borderRadius: '50%',
  },
  '.Cmt-table-responsive': {
    minHeight: '.01%',
    overflowX: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 15,
      overflowY: 'hidden',
      border: `1px solid ${theme.palette.borderColor?.main ?? ''}`,
      '& > table': {
        marginBottom: 0,
        '& > thead > tr > th': {
          paddingTop: 8,
        },
        '& > thead > tr > th, > tbody > tr > th, > tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td':
          {
            whiteSpace: 'nowrap',
          },
      },
    },
  },

  '.date-range-picker-wrapper .MuiListItem-gutters': {
    padding: '6px 24px',
  },

  '.date-range-picker-wrapper .MuiList-padding': {
    paddingTop: 20,
  },

  '@media screen and (max-width: 575.98px)': {
    bscBlock: {
      '& hoverContent': {
        transform: 'none !important',
      },
    },
  },
  '@media screen and (max-width: 359.98px)': {
    '.MuiOutlinedInput-inputMarginDense': {
      paddingTop: '18.5px!important',
      paddingBottom: '18.5px!important',
    },
    '.bt-cls': {
      margin: '10px 0!important',
    },
  },

  '.dropzone': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(10, 5),
    border: `2px dashed ${theme.palette.borderColor?.main ?? ''}`,
    borderRadius: 2,
    backgroundColor: theme.palette.background.default,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginTop: '10px !important',
  },
  // notistack
  '.notistack-CollapseWrapper, .notistack-CollapseWrapper .notistack-Snackbar, .notistack-CollapseWrapper .notistack-Snackbar .notistack-MuiContent':
    {
      minWidth: 'auto',
    },
  '.text-component': {
    margin: 0,
    '& p': {
      margin: 0,
    },
    '& p + p': {
      marginTop: '1rem',
    },
  },
  // Text editor list styles
  '.text-editor ul, .text-editor ol': {
    margin: '1rem 0 1rem 0 !important',
    paddingLeft: '1.5rem',
    listStylePosition: 'outside !important',
  },

  '.text-editor ul, .text-editor ul li': {
    listStyle: 'disc outside !important',
  },

  '.text-editor ol, .text-editor ol li': {
    listStyle: 'decimal outside !important',
  },

  '.text-editor ul ul li': {
    listStyleType: 'circle !important',
  },

  '.text-editor ul ul ul li': {
    listStyleType: 'square !important',
  },

  '.text-editor ol ol li': {
    listStyleType: 'lower-alpha !important',
  },

  '.text-editor ol ol ol li': {
    listStyleType: 'lower-roman !important',
  },

  '.text-editor li': {
    marginBottom: '0.5rem',
    lineHeight: 1.5,
  },

  '.text-editor li:last-child': {
    marginBottom: 0,
  },
  ...marginClasses,
});

export default defaultTheme;
