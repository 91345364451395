import { round } from 'lodash';
import { z } from 'zod';
import { PaymentEmailStatus } from 'further-types/investment';

type FormInput = {
  useCashBalance?: boolean;
  cashBalance?: number;
  investmentAmount?: number;
  adviserFee?: number;
  paymentEmailStatus?: PaymentEmailStatus;
};

const isFullCashBalancePayment = (form: FormInput) =>
  (form.cashBalance ?? 0) >=
  round((form.investmentAmount ?? 0) + (form.adviserFee ?? 0), 2);

export const isPaymentEmailStatusPaid = (
  paymentEmailStatus: PaymentEmailStatus,
) =>
  paymentEmailStatus === PaymentEmailStatus.PaidDisableEmail ||
  paymentEmailStatus === PaymentEmailStatus.PaidEmail ||
  paymentEmailStatus === PaymentEmailStatus.PaidNoEmail;

export const investmentFormSchema = z
  .object({
    investorId: z.string(),
    firmId: z.string(),
    fundId: z.string(),
    investmentDate: z.date(),
    investmentAmount: z.number(),
    adviserId: z.string().optional(),
    adviserFee: z.number().optional(),
    externalInvestmentId: z.string().optional(),
    cashBalance: z.number().optional(),
    paymentEmailStatus: z.nativeEnum(PaymentEmailStatus),
    paymentDate: z.date().optional(),
    paidToFirmBankDetailId: z.string().optional(),
    reference: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (
      isPaymentEmailStatusPaid(data.paymentEmailStatus) &&
      !isFullCashBalancePayment(data)
    ) {
      if (!data.paymentDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select a payment date.',
          path: ['paymentDate'],
        });
      }
      if (!data.paidToFirmBankDetailId?.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select a bank account.',
          path: ['paidToFirmBankDetailId'],
        });
      }
    }
  });
