import { api } from 'lib/httpClient';

const resourceExists = async (url: string, useGetMethod = false) => {
  try {
    const method = useGetMethod ? 'get' : 'head';
    const response = await api[method](url);
    return response.status < 400;
  } catch {
    return false;
  }
};

export default resourceExists;
