import { FC } from 'react';
import { AttachFile, Comment } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { Row } from 'components/Layout';

type Props = {
  notes?: string;
  attachment?: string;
  attachmentSignedGetUrl?: string;
};

const NotesAndAttachmentColumn: FC<Props> = ({
  notes,
  attachment,
  attachmentSignedGetUrl,
}) => {
  if (!notes && !attachment) {
    return <span>-</span>;
  }

  return (
    <Row centered spacing="xs">
      {notes && (
        <Tooltip title={notes}>
          <IconButton color="primary" size="small">
            <Comment />
          </IconButton>
        </Tooltip>
      )}
      {attachmentSignedGetUrl && (
        <a
          href={attachmentSignedGetUrl}
          target="_blank"
          rel="noreferrer noopener"
        >
          <IconButton color="primary" size="small">
            <AttachFile />
          </IconButton>
        </a>
      )}
    </Row>
  );
};

export default NotesAndAttachmentColumn;
