import { Delete } from '@mui/icons-material';
import AppDatePicker from 'components/FormElements/AppDatePicker';

import { Button, IconButton } from '@mui/material';
import TextField from 'components/FormElements/AppTextInput';
import AppCheckbox from 'components/FormElements/AppCheckBox';
import Grid from '@mui/material/Grid2';

import Dropzone from 'components/Dropzone';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.white,
    },
  },
  dropZone: {
    minHeight: 0,
  },
  dFlex: {
    display: 'flex',
  },
  commonMargin: {
    marginBottom: 10,
  },
  boxContainer: {
    borderBottom: '1px solid #dbdbdb',
    padding: '8px 0',
    marginBottom: 20,
  },
  marginFirstName: {
    margin: '10px 0px 0px 10px',
  },
  addMoreBtn: {
    textAlign: 'right',
    marginTop: 10,
  },
  marginLeftBtn: {
    marginLeft: 10,
  },
  marginTopBtn: {
    marginTop: 10,
  },
  marginBottom: {
    marginBottom: 20,
  },
  labelText: {
    color: '#656565',
    fontSize: 12,
    fontWeight: 'bold',

    '& > span': {
      fontWeight: 'normal',
    },
  },
  fieldBlock: {
    display: 'flex',
    marginBottom: 10,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  addNewsButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteBtn: {
    color: '#E8453C',
  },
  newsItem: {
    marginTop: 18,
  },
  deleteContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 12,
  },
}));

type Props = any; // TODO: define types

const AddPastInvestment: React.FC<Props> = ({
  setCurrentValue,
  currentValue,
  errorTextCurrent,
  handleDeletePortFolio,
  handleChangePortFolioLogo,
  handleInputChangeNews,
  handleRemoveNews,
  handleAddNews,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.fieldBlock}>
        <label className={classes.labelText} htmlFor="title">
          Title*
        </label>
        <TextField
          required
          name="title"
          placeholder="Enter title"
          fullWidth
          onChange={(event) =>
            setCurrentValue({ ...currentValue, title: event.target.value })
          }
          value={currentValue?.title}
          error={errorTextCurrent?.title}
          helperText={errorTextCurrent?.title}
        />
      </div>
      <div className={classes.fieldBlock}>
        <label
          className={classes.labelText}
          htmlFor="currentPortfolioDescription"
        >
          Company description*
        </label>
        <TextField
          multiline
          rows={3}
          required
          name="currentPortfolioDescription"
          fullWidth
          placeholder="Enter company description in approximately 30-40 words."
          onChange={(event) =>
            setCurrentValue({
              ...currentValue,
              currentPortfolioDescription: event.target.value,
            })
          }
          value={currentValue?.currentPortfolioDescription}
          error={errorTextCurrent?.currentPortfolioDescription}
          helperText={errorTextCurrent?.currentPortfolioDescription}
        />
      </div>
      <div className={classes.fieldBlock}>
        <label className={classes.labelText} htmlFor="firmLogo">
          Company logo <span>(Square, 1:1)</span>
        </label>
        <Dropzone
          onDropFile={handleChangePortFolioLogo}
          files={
            currentValue?.firmPortfolioLogo
              ? [
                  {
                    preview: currentValue?.previewFirmPortfolioLogo,
                    fileName: '',
                  },
                ]
              : []
          }
          name="firmLogo"
          onRemove={() => handleDeletePortFolio()}
        />
      </div>
      <div className={classes.fieldBlock}>
        <label className={classes.labelText} htmlFor="url">
          URL
        </label>
        <TextField
          name="url"
          placeholder="Enter URL"
          fullWidth
          onChange={(event) =>
            setCurrentValue({ ...currentValue, url: event.target.value })
          }
          value={currentValue?.url}
          error={errorTextCurrent?.url}
          helperText={errorTextCurrent?.url}
        />
      </div>
      <div className={classes.fieldBlock}>
        <label className={classes.labelText} htmlFor="linkedinUrl">
          LinkedIn URL
        </label>
        <TextField
          name="linkedinUrl"
          placeholder="Enter LinkedIn URL"
          fullWidth
          onChange={(event) =>
            setCurrentValue({
              ...currentValue,
              linkedinUrl: event.target.value,
            })
          }
          value={currentValue?.linkedinUrl}
          error={errorTextCurrent?.linkedinUrl}
          helperText={errorTextCurrent?.linkedinUrl}
        />
      </div>
      <div className={classes.fieldBlock}>
        <label className={classes.labelText} htmlFor="twitterUrl">
          Twitter URL
        </label>
        <TextField
          name="twitterUrl"
          placeholder="Enter Twitter URL"
          fullWidth
          onChange={(event) =>
            setCurrentValue({ ...currentValue, twitterUrl: event.target.value })
          }
          value={currentValue?.twitterUrl}
          error={errorTextCurrent?.twitterUrl}
          helperText={errorTextCurrent?.twitterUrl}
        />
      </div>
      <div>
        <AppCheckbox
          key="isExited"
          label="Exited"
          checked={currentValue?.isExited}
          onChange={(event) =>
            setCurrentValue({ ...currentValue, isExited: event.target.checked })
          }
        />
      </div>
      <div className={classes.fieldBlock}>
        <label className={classes.labelText} htmlFor="order">
          Order
        </label>
        <TextField
          name="order"
          placeholder="Enter order"
          fullWidth
          onChange={(event) =>
            setCurrentValue({ ...currentValue, order: event.target.value })
          }
          value={currentValue?.order}
        />
      </div>
      {currentValue?.news?.map((x, i) => {
        return (
          <div className={classes.newsItem} key={i}>
            <div className={classes.fieldBlock}>
              <label className={classes.labelText}>{`News ${i + 1}`}</label>
            </div>
            <div className={classes.fieldBlock}>
              <label className={classes.labelText} htmlFor="title">
                Title*
              </label>
              <TextField
                required
                name="title"
                placeholder="Enter title"
                fullWidth
                onChange={(e) => handleInputChangeNews(e, i)}
                value={x?.title}
                error={errorTextCurrent?.news[i]?.title}
                helperText={errorTextCurrent?.news[i]?.title}
                variant="outlined"
              />
            </div>
            <div className={classes.fieldBlock}>
              <label className={classes.labelText} htmlFor="url">
                URL*
              </label>
              <TextField
                required
                name="url"
                placeholder="Enter URL"
                fullWidth
                onChange={(e) => handleInputChangeNews(e, i)}
                value={x?.url}
                error={errorTextCurrent?.news[i]?.url}
                helperText={errorTextCurrent?.news[i]?.url}
                variant="outlined"
              />
            </div>
            <div className={classes.fieldBlock}>
              <label className={classes.labelText} htmlFor="date">
                Date*
              </label>
              <AppDatePicker
                required
                name="date"
                value={x?.date}
                onChange={(e) => handleInputChangeNews(e, i, true)}
                error={errorTextCurrent?.news[i]?.date}
                helperText={errorTextCurrent?.news[i]?.date}
              />
            </div>
            <div className={classes.deleteContainer}>
              <IconButton
                className={classes.deleteBtn}
                size="small"
                onClick={() => handleRemoveNews(i)}
              >
                <Delete />
              </IconButton>
            </div>
          </div>
        );
      })}
      <Grid
        className={classes.addNewsButton}
        size={{
          xs: 12,
          lg: 12,
        }}
      >
        <Button
          onClick={handleAddNews}
          variant="text"
          color="primary"
          size="small"
        >
          Add News
        </Button>
      </Grid>
    </>
  );
};

export default AddPastInvestment;
