import { useAuth0 } from '@auth0/auth0-react';
import useCurrentUser from 'hooks/data/currentUser/useCurrentUser';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { identifySentry, initLogrocket } from 'utils/tracking';

export default function useAuth() {
  const { hash } = useLocation();
  const {
    isLoading,
    isAuthenticated,
    user: auth0User,
    loginWithRedirect,
  } = useAuth0();

  const { profile: user } = useCurrentUser();

  useEffect(() => {
    if (hash === '#refresh') {
      user.refetch();
    }
  }, [hash]);

  useEffect(() => {
    if (!user.isError) return;
    loginWithRedirect();
  }, [user.isError]);

  useEffect(() => {
    if (!auth0User?.org_id || !user.data?.auth0OrgId) return;

    if (auth0User.org_id !== user.data.auth0OrgId) {
      user.refetch();
    }
  }, [auth0User?.org_id, user.data?.auth0OrgId]);

  useEffect(() => {
    if (!user.data?._id) return;

    initLogrocket(user.data);
    identifySentry(user.data);
  }, [user.data?.firm?._id, user.data?._id]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated]);

  return {
    realm: user.data?.realm,
    isLoading: isLoading || !user.data?.realm,
  };
}
