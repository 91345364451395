import { Grid2, Slider, Tooltip } from '@mui/material';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import { numberToCurrencyString } from 'further-ui/utils';
import { InvestmentPaymentStatus } from 'further-types/investment';
import Spacing from 'components/Spacing';
import { FieldRow } from 'components/FormElements';
import GridContainer from 'components/GridContainer';

const ValueLabelComponent = ({ children, open, value }) => (
  <Tooltip
    open={open}
    enterTouchDelay={0}
    placement="bottom"
    title={`${value}`}
  >
    {children}
  </Tooltip>
);
const paymentMethod = [
  { paymentStatus: InvestmentPaymentStatus.FullyFunded, text: 'Fully funded' },
  {
    paymentStatus: InvestmentPaymentStatus.PartiallyFunded,
    text: 'Partially funded',
  },
  { paymentStatus: InvestmentPaymentStatus.NoPayment, text: 'No payment' },
];

type Props = any;

const FilterPanel: React.FC<Props> = ({
  filters,
  handleChangeFilters,
  errorText,
}) => {
  const informationText = `Range: ${numberToCurrencyString(
    filters.amount?.[0],
    {
      hidePennies: true,
    },
  )} - ${numberToCurrencyString(filters.amount?.[1], {
    hidePennies: true,
  })}`;

  return (
    <Spacing>
      <FieldRow
        title="Subscription amount"
        informationText={informationText}
        fullWidth
      >
        <Slider
          slots={{
            valueLabel: ValueLabelComponent,
          }}
          value={filters.amount}
          onChange={(_, newValue) => {
            handleChangeFilters({ amount: newValue as number[] });
          }}
          valueLabelDisplay="auto"
          min={1}
          step={1}
          max={2000000}
          track={false}
        />
      </FieldRow>
      <FieldRow title="Payment status" fullWidth>
        <AppSelectBox
          fullWidth
          data={paymentMethod}
          placeholder="Search by payment status"
          valueKey="paymentStatus"
          variant="outlined"
          labelKey="text"
          name="paymentStatus"
          value={filters.paymentStatus}
          onChange={(e) => {
            handleChangeFilters({
              paymentStatus: e.target.value,
            });
          }}
        />
      </FieldRow>
      <GridContainer>
        <Grid2 size={{ md: 6, xs: 12 }}>
          <FieldRow title="Start date" fullWidth>
            <AppDatePicker
              required
              name="startDate"
              label=""
              value={filters.startDate}
              maxDate={filters.endDate ? filters.endDate : undefined}
              onChange={(date) => {
                handleChangeFilters({ startDate: date });
              }}
              error={!!errorText.startDate}
              helperText={errorText.startDate}
            />
          </FieldRow>
        </Grid2>
        <Grid2 size={{ md: 6, xs: 12 }}>
          <FieldRow title="End date" fullWidth>
            <AppDatePicker
              required
              name="endDate"
              label=""
              value={filters.endDate}
              onChange={(date) => {
                handleChangeFilters({ endDate: date });
              }}
              minDate={filters.startDate ? filters.startDate : undefined}
              error={!!errorText.endDate}
              helperText={errorText.endDate}
            />
          </FieldRow>
        </Grid2>
      </GridContainer>
    </Spacing>
  );
};
export default FilterPanel;
