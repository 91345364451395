import React from 'react';
import { Drawer, useMediaQuery, useTheme } from '@mui/material';
import SidebarContent from './SidebarContent';
import { useLayoutContext } from 'contexts/LayoutContext';

const Sidebar: React.FC = () => {
  const theme = useTheme();
  const { isSidebarOpen, setSidebarOpen } = useLayoutContext();

  const hasDrawer = useMediaQuery(
    theme.breakpoints.down(theme.sidebar?.drawerBreakpoint ?? 'sm'),
  );

  if (hasDrawer) {
    return (
      <Drawer
        variant="temporary"
        open={isSidebarOpen}
        onClose={() => setSidebarOpen(false)}
      >
        <SidebarContent />
      </Drawer>
    );
  } else {
    return <SidebarContent />;
  }
};

export default Sidebar;
