import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/admin-user';
import { api } from 'lib/httpClient';

export function useGetAdminUsers(params: Api.ListUsersRequestQueryParams) {
  return useQuery({
    queryKey: ['admin-users', params],
    queryFn: async () => {
      const { data } = await api.get<ApiResponse<Api.ListUsersResponseBody>>(
        `admin-user/list`,
        { params },
      );
      return data.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
  });
}
