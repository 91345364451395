import { styled, Avatar } from '@mui/material';
import OrganisationSwitcher from '../OrganisationSwitcher';
import VehicleSwitcher from '../VehicleSwitcher';
import useCurrentUser from 'hooks/data/currentUser/useCurrentUser';
import { Realm } from 'further-types/admin-user';

const Logo = styled('img')({
  width: '120px',
  marginBottom: '30px',
});

const AvatarWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
});

const UserAvatar = styled(Avatar)({
  filter: 'drop-shadow(0px 4px 20px rgba(134, 137, 165, 0.25))',
  border: '2px solid #fff',
});

const Wrapper = styled('div')(({ theme }) => ({
  padding: '30px 16px 20px 32px',
  borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`,
  [theme.breakpoints.up('xl')]: {
    padding: '30px 40px 20px 40px',
  },
}));

const UserInfo = styled('div')({
  paddingTop: 20,
  transition: 'all 0.1s ease',
  height: 75,
  opacity: 1,
});

const UserName = styled('h3')(({ theme }) => ({
  color: theme.palette.sidebar.textDarkColor,
  marginBottom: 6,
  lineHeight: '13px',
  fontSize: '16px',
  display: 'block',
  width: '100%',
}));

const UserEmail = styled('p')({
  width: '100%',
  fontSize: 14,
  fontWeight: 800,
  letterSpacing: 0.25,
});

const SidebarHeader: React.FC = () => {
  const { profile: user } = useCurrentUser();

  const userName =
    user?.data?.firstName && user?.data?.lastName
      ? `${user.data.firstName} ${user.data.lastName}`
      : '-';
  const firm = user?.data?.firm;
  const realm = user?.data?.realm;

  return (
    <Wrapper>
      <Logo src="images/logo.png" alt="profile" />
      <AvatarWrapper>
        <UserAvatar src={user?.data?.profilePic} alt="User Avatar" />
        <OrganisationSwitcher
          orgs={user?.data?.otherOrgs}
          firm={firm}
          realm={realm}
        />
      </AvatarWrapper>
      <UserInfo>
        <UserName>{userName}</UserName>
        <UserEmail>{user?.data?.email}</UserEmail>
      </UserInfo>
      {realm === Realm.Lp && <VehicleSwitcher />}
    </Wrapper>
  );
};

export default SidebarHeader;
