import DownloadTray from './DownloadTray';
import { useBackgroundExports } from 'hooks/data/backgroundExports/useBackgroundExports';

const DownloadTrayContainer: React.FC = () => {
  const { backgroundExports, downloadBackgroundExport } =
    useBackgroundExports();

  if (
    !backgroundExports?.results?.some(
      (backgroundExport) => !backgroundExport.downloaded,
    )
  ) {
    return null;
  }

  return (
    <DownloadTray
      backgroundExports={backgroundExports?.results}
      downloadBackgroundExport={(exportId: string) =>
        downloadBackgroundExport.mutate({ exportId })
      }
    />
  );
};

export default DownloadTrayContainer;
