import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useInvestorReportingFormContext } from 'contexts/InvestorReportingFormContext';

import useStyles from './styles';
import ClosedFund from './ClosedFund';
import AccordionTitle from './AccordionTitle';
import AccordionToggle from './AccordionToggle';

type Props = any;

const ClosedFundsList: React.FC<Props> = ({
  managers,
  isViewPage,
  firmName,
  control,
  setValue,
}) => {
  const { classes, cx } = useStyles();

  const { fundInformation, submitDate } = useInvestorReportingFormContext();

  const [fundsWithData, setFundsWithData] = useState(
    fundInformation
      .map((fund, index) => (!fund.copyFund ? index : -1))
      .filter((index) => index >= 0),
  );

  const handleChangeSwitch = (index, isChecked) => {
    setFundsWithData(
      isChecked
        ? [...fundsWithData, index]
        : fundsWithData.filter((fundIndex) => fundIndex !== index),
    );
  };

  return (
    <>
      {fundInformation?.map((single, index) => (
        <Accordion
          key={index}
          expanded={fundsWithData.includes(index)}
          className={classes.customAccordion}
        >
          <AccordionSummary
            className={cx(
              classes.cmAccHeader,
              classes.expends,
              classes.fundsAccHeader,
            )}
            expandIcon={
              <AccordionToggle
                item={single}
                index={index}
                handleChangeSwitch={handleChangeSwitch}
                isViewPage={isViewPage}
                setValue={setValue}
                control={control}
              />
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <AccordionTitle
              item={single}
              firmName={firmName}
              submitDate={submitDate}
            />
          </AccordionSummary>
          <AccordionDetails className={classes.cmAccDetail}>
            <ClosedFund
              control={control}
              setValue={setValue}
              fund={single}
              index={index}
              managers={managers}
              isViewPage={isViewPage}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default ClosedFundsList;
