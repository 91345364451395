import { Button } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import useStyles from './styles';
import { createInvestmentEditRoute } from 'adminConstants/routes';
import { dateToLabel } from 'further-ui/utils';

const ReversedButton = withStyles(Button, (theme: any) => ({
  root: {
    color: theme.palette.text.white,
    borderColor: theme.palette.borderColor.white,
    fontWeight: 700,
    '&:hover': {
      borderColor: theme.palette.borderColor.white,
    },
  },
}));

type Props = {
  investment: {
    _id: string;
    transferDate: string;
    investor?: {
      fullName: string;
    };
  };
};

const TransferLabel: React.FC<Props> = ({
  investment: { _id, transferDate, investor },
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.transferLabel}>
      <span>
        Holdings from this subscription were transferred to {investor?.fullName}{' '}
        on {dateToLabel(transferDate)}.
      </span>
      <ReversedButton
        size="small"
        variant="outlined"
        color="primary"
        href={createInvestmentEditRoute(_id)}
      >
        View transferee subscription
      </ReversedButton>
    </div>
  );
};

export default TransferLabel;
