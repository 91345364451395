import AppDatePicker from 'components/FormElements/AppDatePicker';
import GridContainer from 'components/GridContainer';
import Grid from '@mui/material/Grid2';
import FieldRow from 'components/FormElements/FieldRow';
import { NumericFormat } from 'react-number-format';
import AppTextInput from 'components/FormElements/AppTextInput';
import React from 'react';

type InitialShareProceProps = {
  classes: Record<string, any>;
  setValue: (x: Record<string, any>) => void;
  value: Record<string, any>;
  errorText: Record<string, string>;
};

export const InitialSharePrice: React.FC<InitialShareProceProps> = ({
  classes,
  setValue,
  value,
  errorText,
}) => {
  return (
    <FieldRow title="Initial share price" centerTitle>
      <GridContainer>
        <Grid size={{ md: 5 }}>
          <div className={classes.smallFlexContainer}>
            <span className={classes.spanLabel}>Initial price</span>
            <NumericFormat
              onValueChange={(event) => {
                setValue({
                  ...value,
                  initialPrice: event.value,
                });
              }}
              required
              name="initialPrice"
              placeholder="Enter amount"
              value={value.initialPrice}
              allowNegative={false}
              allowLeadingZeros={false}
              thousandSeparator={true}
              prefix="£"
              customInput={AppTextInput}
              error={!!errorText.initialPrice}
              helperText={errorText.initialPrice}
            />
          </div>
        </Grid>
        <Grid size={{ md: 7 }}>
          <div className={classes.smallFlexContainer}>
            <span className={classes.spanLabel}>Initial date</span>
            <AppDatePicker
              required
              name="date"
              value={new Date(value?.date)}
              onChange={(date) => {
                setValue({
                  ...value,
                  date,
                });
              }}
              error={!!errorText.date}
              helperText={errorText.date}
              maxDate={new Date()}
            />
          </div>
        </Grid>
      </GridContainer>
    </FieldRow>
  );
};
