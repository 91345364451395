import Grid, { Grid2Props } from '@mui/material/Grid2';

const GridContainer: React.FC<Grid2Props> = ({ children, ...rest }) => {
  return (
    <Grid container spacing={6} {...rest}>
      {children}
    </Grid>
  );
};

export default GridContainer;
