import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { Api } from 'further-types/companies';

async function fetchCompanies({
  queryKey,
}: {
  queryKey: [string, Api.ListInvesteeQuery];
}) {
  const [, params] = queryKey;

  const { data } = await api.get(`investee/list`, { params });

  return data.data;
}

function useCompanies({ params }) {
  return useQuery<
    Api.ListInvesteeQuery,
    Error,
    Api.ListInvesteeResponse,
    [string, Api.ListInvesteeQuery]
  >({
    queryKey: ['companies', params],
    queryFn: fetchCompanies,
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
  });
}

export default useCompanies;
