import { api } from 'lib/httpClient';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/fund';

export default function useKiEligibleFunds() {
  const funds = useQuery({
    queryKey: ['ki-eligible-funds'],
    queryFn: async () =>
      (
        await api.get<ApiResponse<Api.GetKiEligibleFundsResponse>>(
          'fund/ki-eligible',
        )
      ).data.data,
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
  });

  return funds;
}
