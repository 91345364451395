import { Grid2 } from '@mui/material';
import GridContainer from 'components/GridContainer';
import { Row } from 'components/Layout';
import React, { PropsWithChildren } from 'react';
import FilterButton, { FilterButtonProps } from './FilterButton';
import DropdownActions, { DropdownAction } from 'components/DropdownActions';

export { default as Filter } from './Filter';

type Props = {
  pageId: string;
  buttons?: Array<FilterButtonProps>;
  dropdownActions?: Array<DropdownAction>;
};

const PageFilters: React.FC<PropsWithChildren<Props>> = ({
  pageId,
  children,
  buttons,
  dropdownActions,
}) => {
  return (
    <GridContainer
      alignContent="center"
      alignItems="center"
      marginBottom="10px"
    >
      <Grid2 size={{ xs: 12, md: 'grow' }}>
        <GridContainer>{children}</GridContainer>
      </Grid2>
      {!!buttons?.length && (
        <Grid2 size={{ xs: 12, md: 'auto' }}>
          <Row spacing="xs" centered>
            {buttons.map((buttonProps) => (
              <FilterButton
                {...buttonProps}
                key={`${pageId}-filter-button-${buttonProps.label}`}
              />
            ))}
          </Row>
        </Grid2>
      )}
      {!!dropdownActions?.length && (
        <Grid2 size={{ xs: 12, md: 'auto' }}>
          <DropdownActions
            id={`${pageId}-dropdown-actions`}
            actions={dropdownActions}
            title="Actions"
          />
        </Grid2>
      )}
    </GridContainer>
  );
};

export default PageFilters;
