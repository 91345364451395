import {
  createTRPCReact,
  TRPCLink,
  type inferReactQueryProcedureOptions,
} from '@trpc/react-query';
import type { ApiRouter } from 'further-api/trpc/routes';
import { history } from '@redux/store';
import { observable } from '@trpc/server/observable';
import { inferRouterInputs, inferRouterOutputs } from '@trpc/server';

export type ReactQueryOptions = inferReactQueryProcedureOptions<ApiRouter>;
export type ApiInputs = inferRouterInputs<ApiRouter>;
export type ApiResponses = inferRouterOutputs<ApiRouter>;

const api = createTRPCReact<ApiRouter>();

export const logoutLink: TRPCLink<ApiRouter> = () => {
  return ({ next, op }) => {
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          observer.next(value);
        },
        error(err) {
          const authorisationError = err.data as {
            forceLogout?: boolean;
          };
          if (authorisationError?.forceLogout) {
            history.replace(
              `${history.location.pathname}${history.location.search}#refresh`,
            );
          }

          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
      return unsubscribe;
    });
  };
};

export const TrcpProvider = api.Provider;

export default api;
