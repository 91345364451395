import { FC } from 'react';
import { Delete } from '@mui/icons-material';
import { TableActions } from 'components/Table';
import { useDelegatedAccess } from 'hooks/data/delegatedAccess/useDelegatedAccess';
import { Box } from '@mui/material';

type Props = {
  delegateAccessId: string;
};

const DelegatedAccessUserActions: FC<Props> = ({ delegateAccessId }) => {
  const { remove } = useDelegatedAccess();

  return (
    <Box display="flex" justifyContent="centre" width="100%">
      <TableActions
        actions={[
          {
            label: 'Delete',
            icon: Delete,
            color: 'error',
            onClick: () => remove.mutate(delegateAccessId),
          },
        ]}
      />
    </Box>
  );
};

export default DelegatedAccessUserActions;
