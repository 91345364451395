import { api } from 'lib/httpClient';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/ki-fund-certificate';
import { useNotification } from 'hooks/ui/useNotification';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';

const useCreateKiFundCertificateBatch = () => {
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();
  const queryClient = useQueryClient();

  return useMutation({
    throwOnError: false,
    mutationFn: async (data: Api.CreateKiFundCertificateBatchRequest) => {
      const result = await api.post<
        ApiResponse<Api.CreateKiFundCertificateBatchResponse>
      >('ki-fund-certificate', data);
      return result.data;
    },
    onSuccess: async () => {
      notification.success('Certificates created successfully');
      await queryClient.invalidateQueries({
        queryKey: ['ki-fund-certificate-batch'],
      });
      await queryClient.invalidateQueries({ queryKey: ['ki-eligible-funds'] });
      await queryClient.invalidateQueries({ queryKey: ['eis-tasklist'] });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
};

const useUpdateKiFundCertificateBatch = () => {
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();
  const queryClient = useQueryClient();

  return useMutation({
    throwOnError: false,
    mutationFn: async (data: Api.UpdateKiFundCertificateBatchRequest) => {
      const result = await api.put<
        ApiResponse<Api.UpdateKiFundCertificateBatchResponse>
      >(`ki-fund-certificate/${data._id}`, data);
      return result.data;
    },
    onSuccess: async () => {
      notification.success('Certificates updated successfully');
      await queryClient.invalidateQueries({
        queryKey: ['ki-fund-certificate-batch'],
      });
      await queryClient.invalidateQueries({ queryKey: ['eis-tasklist'] });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
};

function useDeleteKiFunCertificateBatch() {
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();
  const queryClient = useQueryClient();

  return useMutation({
    throwOnError: false,
    mutationFn: async (certificatesBatchId: string) => {
      const result = await api.delete<
        ApiResponse<Api.DeleteKiFundCertificateBatchResponse>
      >(`ki-fund-certificate/${certificatesBatchId}`);
      return result.data;
    },
    onSuccess: async () => {
      notification.success('Certificates deleted successfully');
      await queryClient.invalidateQueries({
        queryKey: ['ki-fund-certificate-batch'],
      });
      await queryClient.invalidateQueries({ queryKey: ['ki-eligible-funds'] });
      await queryClient.invalidateQueries({ queryKey: ['eis-tasklist'] });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
}

function useGetKiFundCertificateBatch(kiFundCertificateBatchId: string) {
  return useQuery({
    queryKey: ['ki-fund-certificate-batch', kiFundCertificateBatchId],
    queryFn: async () =>
      (
        await api.get<ApiResponse<Api.GetKiFundCertificateBatchResponse>>(
          `/ki-fund-certificate/${kiFundCertificateBatchId}`,
        )
      ).data.data,
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
    enabled: !!kiFundCertificateBatchId,
  });
}

export default function useKiFundCertificateBatch() {
  return {
    getCertificateBatch: useGetKiFundCertificateBatch,
    createCertificateBatch: useCreateKiFundCertificateBatch(),
    updateCertificateBatch: useUpdateKiFundCertificateBatch(),
    deleteCertificateBatch: useDeleteKiFunCertificateBatch(),
  };
}
