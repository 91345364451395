import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { z } from 'zod';

import DropzoneComponent from 'components/Dropzone';
import TextField from 'components/FormElements/AppTextInput';
import AppPasswordInput from 'components/FormElements/AppPasswordTextField';
import GridContainer from 'components/GridContainer';

import { validationMessages, validationRegexes } from 'further-ui/utils';
import { UploadType } from 'further-types/files';
import useFileUpload from 'hooks/ui/useFileUpload';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { useMutateAdminUser } from 'hooks/data/adminUser/useAdminUser';

const adminUserSchema = z.object({
  firstName: z
    .string()
    .min(1, 'Please enter a first name.')
    .regex(
      validationRegexes.userName,
      'First name contains invalid characters',
    ),
  lastName: z
    .string()
    .min(1, 'Please enter a last name.')
    .regex(validationRegexes.userName, 'Last name contains invalid characters'),
  email: z
    .string()
    .min(1, `Please enter an email.`)
    .regex(validationRegexes.email, 'Email is not valid'),
  password: z
    .string()
    .regex(
      validationRegexes.password,
      validationMessages.passwordValidationError,
    )
    .optional(),
  profilePic: z.string().nullable().optional(),
  previewProfilePic: z.string().nullable().optional(),
});

export type FormValues = Required<typeof adminUserSchema>;
export type FormData = z.infer<FormValues>;

type Props = {
  id?: string;
  defaultValues?: FormData;
};

const AddUpdateUserForm: FC<Props> = ({ id, defaultValues }) => {
  const { edit, create } = useMutateAdminUser(id);

  const handleApiRequest = useApiRequestHandler();
  const { uploadFile } = useFileUpload();

  const form = useForm<FormData>({
    shouldFocusError: true,
    resolver: zodResolver(adminUserSchema),
    defaultValues: {
      ...defaultValues,
      previewProfilePic: defaultValues?.profilePic,
      profilePic: defaultValues?.profilePic
        ? defaultValues.profilePic.split('.com/')[1]
        : null,
    },
    criteriaMode: 'all',
  });

  const onSubmit = async (formValues) => {
    if (id) {
      edit.mutate(formValues);
    } else {
      create.mutate(formValues);
    }
  };

  const handleProfileImage = async (file) => {
    if (!file?.[0]?.path) return;

    await handleApiRequest(async () => {
      const { filePath } = await uploadFile(file[0], UploadType.UserImages);
      form.setValue('profilePic', filePath);
      form.setValue('previewProfilePic', file[0]?.preview);
    }, "Something went wrong - the file couldn't be uploaded");
  };

  const clearProfileImage = () => {
    form.setValue('profilePic', null);
    form.setValue('previewProfilePic', null);
  };

  const formIsSubmitting = edit.isPending || create.isPending;

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <GridContainer>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Controller
            name="firstName"
            control={form.control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                inputRef={ref}
                fullWidth
                variant="outlined"
                label="First name"
                error={!!error}
                helperText={error?.message}
                disabled={formIsSubmitting}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Controller
            name="lastName"
            control={form.control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                inputRef={ref}
                fullWidth
                variant="outlined"
                label="Last name"
                error={!!error}
                helperText={error?.message}
                disabled={formIsSubmitting}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Controller
            name="email"
            control={form.control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                inputRef={ref}
                fullWidth
                variant="outlined"
                label="Email"
                error={!!error}
                helperText={error?.message}
                disabled={!!id || formIsSubmitting}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Controller
            name="password"
            control={form.control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <AppPasswordInput
                {...rest}
                label="Password"
                inputRef={ref}
                error={!!error}
                helperText={error?.message}
                disabled={formIsSubmitting}
              />
            )}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}>
          <Typography component="h4" variant="inherit">
            Profile Image
          </Typography>

          <Controller
            name="previewProfilePic"
            control={form.control}
            render={({ field: { value, name } }) => (
              <DropzoneComponent
                name={name}
                files={value ? [{ preview: value, fileName: '' }] : []}
                onDropFile={(files) => handleProfileImage(files)}
                onRemove={clearProfileImage}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12 }}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={formIsSubmitting}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </Grid>
      </GridContainer>
    </form>
  );
};

export default AddUpdateUserForm;
