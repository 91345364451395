export const baseDomain = 'joinfurther.com';

export const FrontEndSiteSubdomainUrlToStageMap = {
  dev: `https://{domain}.dev.${baseDomain}`,
  uat: `https://{domain}.uat.${baseDomain}`,
  production: `https://{domain}.${baseDomain}`,
  local: `http://{domain}.localhost:2500`,
};

export const FrontEndSiteUrlToStageMap = {
  dev: `https://dev.${baseDomain}`,
  uat: `https://uat.${baseDomain}`,
  production: `https://${baseDomain}`,
  local: `http://localhost:2500`,
};
