import { useState } from 'react';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { capitalize, toLower, sortBy } from 'lodash';
import { Box, Button, Divider, Tooltip } from '@mui/material';
import { CompareArrows, ExpandLess, ExpandMore } from '@mui/icons-material';
import { displayFeeAccountingType } from 'utils/displayFeeAccounting';
import { Api } from 'further-types/investment';
import useStyles from './styles';

type Fee = Api.GetInvestmentFeesResponse['fees'][number] & {
  isTransferred: boolean;
};

const FutureFees = ({ fees }) => {
  const { classes } = useStyles();
  const [showFees, setShowFees] = useState(false);

  const columns = [
    {
      label: 'Date',
      key: 'date',
      render: (fee: Fee) => dateToLabel(fee.date),
    },
    {
      label: 'Type',
      key: 'feeType',
      render: (fee: Fee) => capitalize(toLower(fee.feeType)),
    },
    {
      label: 'Fee name',
      key: 'name',
      render: (fee: Fee) => {
        if (!fee.sourceFee && !fee.isTransferred) return <>{fee.name}</>;

        const tooltipText = [
          ...(fee.sourceFee
            ? [
                `${numberToCurrencyString(
                  fee.feeIncVAT,
                )} was transferred from ${
                  fee.sourceFee.investor.fullName
                } on ${dateToLabel(fee.sourceFee.investmentDate)}`,
              ]
            : []),
          ...(fee.isTransferred
            ? [
                `${numberToCurrencyString(fee.feeIncVAT)} was transferred to ${
                  fee.investor.fullName
                } on ${dateToLabel(fee.investmentDate)}`,
              ]
            : []),
        ].join(' ');

        return (
          <>
            <Tooltip title={tooltipText}>
              <Box alignItems="center" display="flex" gap={4}>
                {fee.name} <CompareArrows fontSize="small" />
              </Box>
            </Tooltip>
          </>
        );
      },
    },
    {
      label: 'Fee % (Gross)',
      key: 'grossFeePercentage',
      render: (fee: Fee) => fee?.grossFeePercentage.toFixed(2),
    },
    {
      label: 'Fee £ (Net)',
      key: 'feeExVAT',
      render: (fee: Fee) => numberToCurrencyString(fee.feeExVAT),
    },
    {
      label: 'VAT',
      key: 'vat',
      render: (fee: Fee) => numberToCurrencyString(fee.vat),
    },
    {
      label: 'Fee £ (Gross)',
      key: 'feeIncVAT',
      render: (fee: Fee) => numberToCurrencyString(fee.feeIncVAT),
    },
    {
      label: 'Fee accounting',
      key: 'feeAccounting',
      render: (fee: Fee) =>
        displayFeeAccountingType(fee.feeAccounting, fee.viaExit),
    },
  ];

  const combinedFees = sortBy(fees, 'date').reduce((combined, fee) => {
    return [
      ...combined,
      fee,
      ...fee.transferredFees.map((transferredFee) => ({
        ...transferredFee,
        isTransferred: true,
      })),
    ];
  }, []);

  return (
    <>
      <Button
        color="primary"
        className={classes.feeForecastsButton}
        onClick={() => {
          setShowFees(!showFees);
        }}
      >
        {`${showFees ? 'Hide' : 'Show'} fee forecast`}
        {showFees ? <ExpandLess /> : <ExpandMore />}
      </Button>
      {showFees && (
        <Table
          columns={columns.map((column) => ({
            ...column,
            getCellClassName: (fee: Fee) =>
              fee.isTransferred ? classes.inactiveCell : '',
          }))}
          variant="nohover"
          tablebody={combinedFees}
        />
      )}
      <Divider />
    </>
  );
};

export default FutureFees;
