import React, { ComponentType, PropsWithChildren } from 'react';
import { Typography, Divider, styled, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Row, Margin } from 'components/Layout';

const TooltipIcon = styled(InfoOutlined)({
  fontSize: '16px',
  cursor: 'pointer',
  marginTop: -2,
});

const HeadingLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.rootColor,
  textTransform: 'inherit',
  fontWeight: 700,
  letterSpacing: '.5px',
  backgroundColor: 'transparent !important',
  padding: '0',
}));

const HeadingIcon = styled('svg')(({ theme }) => ({
  color: theme.palette.text.rootColor,
}));

export type HeadingProps = {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  icon?: ComponentType;
  subtitle?: React.ReactNode;
  className?: string;
  link?: {
    label: string;
    to: string;
  };
  tooltip?: React.ReactNode;
  showDivider?: boolean;
  centered?: boolean;
  noMargin?: boolean;
};

const marginBottom: Record<
  HeadingProps['variant'],
  'xl' | 'lg' | 'md' | 'xs' | undefined
> = {
  h1: 'xl',
  h2: 'lg',
  h3: 'md',
  h4: 'xs',
  h5: 'xs',
  h6: undefined,
};

const Heading: React.FC<PropsWithChildren<HeadingProps>> = ({
  variant,
  icon,
  className,
  tooltip,
  children,
  showDivider,
  link,
  subtitle,
  noMargin,
  centered,
}) => {
  return (
    <>
      <Margin
        bottom={noMargin ? undefined : marginBottom[variant]}
        className={className}
      >
        <Row centered justify={centered ? 'center' : 'space-between'}>
          <Row spacing="xs" centered>
            {!!icon && (
              <HeadingIcon as={icon} style={{ color: 'text.rootColor' }} />
            )}
            <div>
              <Typography
                variant={variant}
                component="div"
                textAlign={centered ? 'center' : 'inherit'}
              >
                {children}
              </Typography>
              {subtitle && (
                <Typography variant="subtitle1">{subtitle}</Typography>
              )}
            </div>
            {tooltip && (
              <Tooltip arrow title={tooltip}>
                <TooltipIcon />
              </Tooltip>
            )}
          </Row>
          {link && <HeadingLink to={link.to}>{link.label}</HeadingLink>}
        </Row>
      </Margin>

      {showDivider && (
        <Margin bottom="md">
          <Divider />
        </Margin>
      )}
    </>
  );
};

export default Heading;
