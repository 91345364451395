import { useState } from 'react';
import { useEffect } from 'react';
import { useElementSize } from 'usehooks-ts';

const useInitialElementSize = (): [
  (node: HTMLDivElement) => void,
  { width: number; height: number },
] => {
  const [ref, { width, height }] = useElementSize();

  const [initialWidth, setInitialWidth] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);

  useEffect(() => {
    if (width !== 0 && initialWidth === 0) {
      setInitialWidth(width);
    }
    if (height !== 0 && initialHeight === 0) {
      setInitialHeight(height);
    }
  }, [width, height, initialWidth, initialHeight]);

  return [ref, { width: initialWidth, height: initialHeight }];
};

export default useInitialElementSize;
