import {
  keepPreviousData,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import qs from 'qs';

async function fetchEntitySummary({ queryKey }) {
  const [, firmId] = queryKey;
  const queryParams = qs.stringify({ firmId });

  const url = firmId
    ? `dashboard/entity-summary?${queryParams}`
    : `dashboard/entity-summary`;

  const { data } = await api.get(url);

  return data.data;
}

function useEntitySummary({ firmId }): UseQueryResult<
  {
    name: string;
    key: string;
    tagId: string;
  }[]
> {
  return useQuery({
    queryKey: ['entity-summary', firmId],
    queryFn: fetchEntitySummary,
    placeholderData: keepPreviousData,
    staleTime: 4 * 60 * 60 * 1000,
  });
}

export default useEntitySummary;
