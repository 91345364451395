import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { sortBy } from 'lodash';

import { useFunds } from 'hooks/data/fund/useFunds';
import TranchesMultiSelect from '../TrancheMultiSelect';
import getFirmsInTranches from 'helpers/firm/get-firms-in-tranches';

const useStyles = makeStyles()((theme) => ({
  error: {
    color: theme.palette.error.main,
    padding: '5px 0 0 14px',
  },
}));

type FormState = {
  selectedTrancheIds: Array<string>;
  tranchesSelectAll: boolean;
  selectedFirmIds: Array<string>;
  selectedFirmNames: Array<string>;
};

type Props = {
  handleChangeSelectedTranches: (formState: FormState) => void;
  trancheFilters?: {
    fundStatus?: number | Array<number>;
    fullyDeployed?: boolean;
  };
  disabled?: boolean;
  value?: Array<string>;
};

const MultiFirmTrancheSelector: React.FC<Props> = ({
  handleChangeSelectedTranches,
  trancheFilters = { fundStatus: 0 },
  disabled,
  value,
}) => {
  const { classes } = useStyles();
  const { tranches } = useFunds({ ...trancheFilters, leanResponse: true });

  const [formState, setFormState] = useState<FormState>({
    selectedTrancheIds: value || [],
    tranchesSelectAll: false,
    selectedFirmIds: [],
    selectedFirmNames: [],
  });

  const mappedTranche = (tranches.data ?? []).map((tranche) => ({
    _id: tranche._id,
    label: `${tranche.firmId.firmName} ${tranche.fundName}`,
    firmId: tranche.firmId,
  }));

  const sortedTranche = sortBy(mappedTranche ?? [], ({ label }) =>
    label.toLowerCase(),
  );

  const handleChangeSelectedTranche = (trancheIds: Array<string>) => {
    // find the selected firms based on the selectedTrancheIds
    const { selectedFirmNames, selectedFirmIds } = getFirmsInTranches(
      trancheIds,
      sortedTranche,
    );

    const newFormState = {
      ...formState,
      selectedTrancheIds: trancheIds,
      selectedFirmNames,
      selectedFirmIds,
    };

    setFormState({ ...newFormState });
    handleChangeSelectedTranches(newFormState);
  };

  return (
    <>
      <TranchesMultiSelect
        tranches={sortedTranche}
        handleChangeSelectedTranches={handleChangeSelectedTranche}
        disabled={!!disabled}
        value={formState.selectedTrancheIds}
      />
      {formState.selectedFirmIds.length > 1 && (
        <p className={classes.error}>
          Please note: you have selected tranches from two or more firms (
          {formState.selectedFirmNames.join(', ')}). Please select tranches
          relating to only one firm to continue.
        </p>
      )}
    </>
  );
};

export default MultiFirmTrancheSelector;
