import { makeStyles } from 'tss-react/mui';
import { Switch } from '@mui/material';
import { useDisclosure } from 'further-ui/hooks';
import { useAllocation } from 'hooks/data/allocation/useAllocation';
import { useNotification } from 'hooks/ui/useNotification';
import AlertDialog from 'components/AlertDialog';
import { FC } from 'react';

const useStyles = makeStyles()(() => ({
  taxTreamentSwitch: {
    display: 'flex',
    gridGap: '0.5rem',
  },
}));

type Props = {
  value: boolean;
  shareAllocationUploadId: string;
  hasCertificate: boolean;
};

const TaxReliefElegibilitySwitch: FC<Props> = ({
  value,
  shareAllocationUploadId,
  hasCertificate,
}) => {
  const { classes } = useStyles();
  const { error } = useNotification();
  const taxReliefStatusDisclosure = useDisclosure();
  const { update } = useAllocation({ params: { id: shareAllocationUploadId } });

  if (update.isPending) return <div>Updating...</div>;

  const handleChangeTaxReliefStatus = async (taxReliefElegible: boolean) => {
    try {
      await update.mutateAsync({
        taxReliefElegible,
      });
    } catch {
      error('Error updating the S/EIS eligibility');
    }
    taxReliefStatusDisclosure.onClose();
  };

  return (
    <>
      <div className={classes.taxTreamentSwitch}>
        <span>No</span>
        <Switch
          disabled={update.isPending}
          checked={value}
          onChange={(e) => {
            if (e.target.checked) {
              handleChangeTaxReliefStatus(true);
            } else {
              taxReliefStatusDisclosure.onOpen();
            }
          }}
        />
        <span>Yes</span>
      </div>
      <AlertDialog
        open={taxReliefStatusDisclosure.isOpen}
        onClose={taxReliefStatusDisclosure.onClose}
        onConfirm={() => handleChangeTaxReliefStatus(false)}
        title="Warning"
        content={
          <p>
            {hasCertificate
              ? 'Toggling this allocation’s S/EIS status off will list the allocation in the Lost tax relief column of the relevant annual tax report.'
              : 'Toggling this allocation’s S/EIS status off will remove this allocation from the relevant annual tax report for the purpose of reporting initial income tax relief.'}
          </p>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Continue',
        }}
      />
    </>
  );
};

export default TaxReliefElegibilitySwitch;
