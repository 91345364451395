import Button from 'components/Button';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import PageSection from 'components/PageSection';
import { BackgroundExportTypes } from 'further-types/background-exports';
import { useBackgroundExports } from 'hooks/data/backgroundExports/useBackgroundExports';
import { useGetRole } from 'hooks/ui/useGetRole';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import FullPageSuccess from 'components/FullPageSuccess';
import { FieldRow } from 'components/FormElements';
import { FirmSelector } from 'components/FirmSelector';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import InvestorSearch from 'components/InvestorSearch';
import { backgroundExports as rules } from 'further-ui/validations';
import { z } from 'zod';
import ButtonGroup from 'components/ButtonGroup';

type FormValues = {
  firmId: string | null;
  params: z.infer<typeof rules.createBackgroundExportInput>['params'];
};

const InvestmentHoldingsReport: FC = () => {
  const { isSuperAdmin, firmId } = useGetRole();
  const { handleSubmit, setValue, watch, register } = useForm<FormValues>({
    defaultValues: {
      params: {
        dateRange: {
          startDate: undefined,
          endDate: undefined,
        },
        investorId: undefined,
        externalInvestmentId: undefined,
      },
      firmId,
    },
  });
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    { label: 'Investment holdings report', link: '/', isActive: true },
  ];

  const { createBackgroundExport, isExporting } = useBackgroundExports();

  const handleExport = async (data: FormValues) => {
    createBackgroundExport.mutate({
      exportType: BackgroundExportTypes.InvestmentHoldingsReport,
      firmId: data.firmId ?? undefined,
      params: data.params,
    });

    setShowSuccessScreen(true);
  };

  const heading = 'System Exports: Investment holdings';

  if (showSuccessScreen) {
    return (
      <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
        <FullPageSuccess
          title="Your investment holdings report is being exported"
          additionalText="Your report will be available soon in the My Downloads section below"
          proceedText="Create another report"
          proceedCallback={() => setShowSuccessScreen(false)}
        />
      </PageContainer>
    );
  }

  return (
    <>
      <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
        <PageContent>
          <form onSubmit={handleSubmit(handleExport)}>
            <PageSection title="Export investment holdings report" withSpacing>
              {isSuperAdmin && (
                <FieldRow centerTitle title="Firm">
                  <FirmSelector
                    firmId={watch('firmId') ?? undefined}
                    onChange={(firmId) => setValue('firmId', firmId)}
                    required={false}
                  />
                </FieldRow>
              )}

              <FieldRow centerTitle title="Date range (optional)">
                <MuiDateRangePicker
                  dateRange={watch('params.dateRange')}
                  onDateRangeChange={(dates: {
                    startDate: Date | null;
                    endDate: Date | null;
                  }) => {
                    const dateRange = {
                      startDate: dates.startDate ?? undefined,
                      endDate: dates.endDate ?? undefined,
                    };
                    setValue('params.dateRange', dateRange);
                  }}
                  TextFieldProps={{
                    placeholder: 'Select date range',
                  }}
                />
              </FieldRow>

              <FieldRow centerTitle title="Select single investor (optional)">
                <InvestorSearch
                  onInvestorSelect={(investorId) =>
                    setValue('params.investorId', investorId)
                  }
                  selectedInvestorId={watch('params.investorId')}
                  firmId={firmId}
                />
              </FieldRow>

              <FieldRow centerTitle title="External subscription ID (optional)">
                <TextField
                  fullWidth
                  placeholder="Filter by external subscription ID"
                  {...register('params.externalInvestmentId')}
                />
              </FieldRow>

              <ButtonGroup>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isExporting}
                >
                  Export
                </Button>
              </ButtonGroup>
            </PageSection>
          </form>
        </PageContent>
      </PageContainer>
    </>
  );
};

export default InvestmentHoldingsReport;
