import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CheckCircleOutline } from '@mui/icons-material';
import PageContent from 'components/PageContent';

const useStyles = makeStyles()((theme) => ({
  tickIcon: {
    color: theme.palette.text.green,
    opacity: 0.4,
    width: '140px !important',
    height: '140px !important',
  },
  title: {
    fontSize: 26,
    color: theme.palette.text.green,
    marginBottom: '1rem',
  },
  additionalText: {
    color: '#656565',
    marginBottom: '23px',
    fontSize: 15,
    maxWidth: 590,
  },
  textCenter: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  secondaryButton: {
    margin: '15px 0 20px 0',
  },
  bottomText: {
    color: '#656565',
    fontSize: 14,
    maxWidth: 600,
    marginTop: '0.4rem',
  },
  buttonWidth: {
    minWidth: 280,
  },
  link: {
    color: theme.palette.text.green,
  },
}));

type Props = {
  title: string;
  additionalText: ReactNode;
  proceedText: string;
  proceedLink?: string;
  proceedCallback?: () => void;
  secondaryText?: string;
  secondaryLink?: string;
  secondaryCallback?: () => void;
  bottomText?: ReactNode;
  children?: any;
};

const FullPageSuccess: FC<Props> = ({
  title,
  additionalText,
  proceedText,
  proceedLink,
  proceedCallback = null,
  secondaryText = null,
  secondaryLink = null,
  secondaryCallback = null,
  bottomText = null,
  children = null,
}) => {
  const { classes } = useStyles();

  const ProceedButton = ({
    proceedCallback,
  }: {
    proceedCallback?: () => void;
  }) => (
    <Button
      className={classes.buttonWidth}
      color="primary"
      variant="contained"
      onClick={proceedCallback}
    >
      {proceedText}
    </Button>
  );

  const SecondaryButton = ({
    secondaryCallback,
  }: {
    secondaryCallback?: () => void;
  }) => (
    <Button
      className={`${classes.secondaryButton} ${classes.buttonWidth}`}
      color="primary"
      variant="outlined"
      onClick={secondaryCallback}
    >
      {secondaryText}
    </Button>
  );

  const hasSecondaryLink = secondaryText && secondaryLink;
  const hasSecondaryCallback = secondaryText && secondaryCallback;

  return (
    <PageContent fullHeight>
      <div className={classes.flexBox}>
        <div className={classes.textCenter}>
          <CheckCircleOutline className={classes.tickIcon} />
          <h3 className={classes.title}>{title}</h3>
          <p className={classes.additionalText}>{additionalText}</p>
          {proceedCallback ? (
            <ProceedButton proceedCallback={proceedCallback} />
          ) : (
            <Link to={proceedLink ?? '#'}>
              <ProceedButton />
            </Link>
          )}

          {hasSecondaryCallback ? (
            <SecondaryButton secondaryCallback={secondaryCallback} />
          ) : hasSecondaryLink ? (
            <Link to={secondaryLink}>
              <SecondaryButton />
            </Link>
          ) : null}

          {bottomText && <p className={classes.bottomText}>{bottomText}</p>}

          <div className={classes.additionalText}>{children}</div>
        </div>
      </div>
    </PageContent>
  );
};
export default FullPageSuccess;
