import { FC, useState } from 'react';
import { isEmpty } from 'lodash';
import { dateToLabel } from 'further-ui/utils';
import Table from 'components/Table';
import { useListDelegatedAccessGiven } from 'hooks/data/delegatedAccess/useListDelegatedAccess';
import { useGetRole } from 'hooks/ui/useGetRole';
import { Button } from '@mui/material';
import { Api } from 'further-types/delegated-access';
import AddNewDelegatedAccessUser from './AddNewDelegatedAccessUser';
import EditDelegatedAccessUserEmail from './EditDelegatedAccessUserEmail';
import DelegatedAccessUserActions from './DelegatedAccessUserActions';
import Heading from 'components/Heading';
import { Column, Row } from 'components/Layout';

type Props = {
  investorId: string;
};

const columns = [
  {
    label: 'Delegated access user',
    key: 'investorName',
    sort: false,
    render: (elm: Api.DelegatedAccessGiven) =>
      `${elm?.delegateReceivingAccess?.fullName}`,
  },
  {
    label: 'Email address',
    key: 'email',
    sort: false,
    render: (elm: Api.DelegatedAccessGiven) =>
      elm?.delegateReceivingAccess?.email,
  },
  {
    label: 'Access given on',
    key: 'accessGrantedDate',
    sort: false,
    render: (elm: Api.DelegatedAccessGiven) =>
      dateToLabel(elm?.accessGrantedDate),
  },
  {
    label: 'Copied into emails?',
    key: 'accessGrantedDate',
    sort: false,
    render: (elm: Api.DelegatedAccessGiven) => (
      <EditDelegatedAccessUserEmail
        copyIntoSystemEmails={elm.copyIntoSystemEmails}
        delegatedAccessId={elm._id}
      />
    ),
  },
  {
    label: 'Actions',
    key: 'actions',
    sort: false,
    render: (elm: Api.DelegatedAccessGiven) => (
      <DelegatedAccessUserActions delegateAccessId={elm._id} />
    ),
  },
];

const DelegatedUsersTable = ({ users }) => (
  <Table
    columns={columns}
    tablebody={users ?? []}
    variant="nohover"
    emptyMessage="This investor has no delegated access users."
    pagination={false}
  />
);

const DelegatedAccessUsers: FC<Props> = ({ investorId }) => {
  const { isSuperAdmin, firmId } = useGetRole();
  const [isAddNewPanelOpen, setIsAddNewPanelOpen] = useState(false);
  const usersWithAccessByFirm = useListDelegatedAccessGiven({
    investorId,
  });

  const toggleAddNewDelegatedAccessUserPanel = () => {
    setIsAddNewPanelOpen(!isAddNewPanelOpen);
  };

  const addNewDelegatedAccessButton = (
    <Row justify="flex-end">
      <Button onClick={toggleAddNewDelegatedAccessUserPanel} color="primary">
        Add new delegated access user
      </Button>
    </Row>
  );

  if (!isSuperAdmin) {
    //@ts-expect-error
    const users = usersWithAccessByFirm.data?.[firmId];

    return (
      <>
        <DelegatedUsersTable users={users} />
        {addNewDelegatedAccessButton}
        {!isAddNewPanelOpen ? null : (
          <AddNewDelegatedAccessUser
            investorId={investorId}
            closePanel={toggleAddNewDelegatedAccessUserPanel}
          />
        )}
      </>
    );
  }

  const usersByFirm = !isEmpty(usersWithAccessByFirm.data)
    ? Object.entries(usersWithAccessByFirm.data)
    : [[]];

  return (
    <Column spacing="md">
      {/* @ts-expect-error */}
      {usersByFirm.map(([, users], i) => (
        <div key={i}>
          <Heading variant="h2">
            {users
              ? `Delegated access users - ${users[0]?.firmId?.firmName}`
              : 'Delegated access users'}
          </Heading>
          <DelegatedUsersTable users={users} />
        </div>
      ))}
      {addNewDelegatedAccessButton}
      {!isAddNewPanelOpen ? null : (
        <AddNewDelegatedAccessUser
          investorId={investorId}
          closePanel={toggleAddNewDelegatedAccessUserPanel}
        />
      )}
    </Column>
  );
};

export default DelegatedAccessUsers;
