import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

async function updateAllocation(id, data) {
  try {
    const { data: response } = await api.put(`upload-shareholding/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

const useAllocationUpdateAPI = ({ params }) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, { taxReliefElegible: boolean }>({
    mutationFn: (data) => updateAllocation(params.id, data),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['allocations'],
      });
      queryClient.invalidateQueries({
        queryKey: ['eis-tasklist'],
      });
    },
  });
};

const useAllocationDelete = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, { id: string }>({
    mutationFn: async (data) => {
      const { data: response } = await api.delete(
        `upload-shareholding/${data.id}`,
      );
      return response;
    },
    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey: ['allocations'],
      }),
  });
};

export function useAllocation(params) {
  const update = useAllocationUpdateAPI(params);

  return {
    update,
    delete: useAllocationDelete(),
  };
}
