import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';

const useResendInvite = ({ onResendInviteSuccess }) => {
  const { error } = useNotification();

  return useMutation({
    mutationFn: async (organisationUserId) => {
      const { data: response } = await api.post(
        `organisation/resend-platform-invite/${organisationUserId}`,
      );
      return response;
    },
    onError: () => {
      error('Error when trying to resend platform invite to organisation');
    },
    onSuccess: async () => {
      onResendInviteSuccess();
    },
  });
};

type UseOrganisationPlatformInvitesArgs = {
  onResendInviteSuccess: () => void;
};

const useOrganisationPlatformInvites = (
  params: UseOrganisationPlatformInvitesArgs,
): {
  resendInvite: UseMutationResult<any, unknown, void, unknown>;
} => ({
  resendInvite: useResendInvite(params),
});

export default useOrganisationPlatformInvites;
