import { Button, styled, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { useGetRole } from 'hooks/ui/useGetRole';
import { Row } from 'components/Layout';

const TooltipIcon = styled(InfoOutlined)({
  position: 'relative',
  fontSize: '18px',
  cursor: 'pointer',
  top: '4px',
});

const NotesContainer = styled(Row)({
  flexGrow: 1,
});

const NotesButton = styled(Button)({
  flex: 'none',
  flexBasis: 'auto',
});

type Props = {
  statusLabel: string;
  approveButtonText: string;
  showApproveButton: boolean;
  approvalNotes?: string;
  showDownloadResultsButton: boolean;
  handleApprove?: (notes?: string) => void;
  handleDowonloadResults?: () => void;
  showNotesTooltip: boolean;
  handleSaveNotes?: (notes?: string) => void;
};

const KycResult: React.FC<Props> = ({
  approveButtonText,
  handleApprove,
  handleDowonloadResults,
  showApproveButton,
  showDownloadResultsButton,
  statusLabel,
  approvalNotes,
  showNotesTooltip,
  handleSaveNotes,
}) => {
  const { isSuperAdmin } = useGetRole();

  const [notes, setNotes] = useState(approvalNotes);
  const [showNotesField, setShowNotesField] = useState(showApproveButton);

  const showEditNotesButton =
    isSuperAdmin && showNotesTooltip && !showNotesField;
  const showSaveNotesButton =
    isSuperAdmin && showNotesTooltip && showNotesField;

  return (
    <Row centered spacing="sm">
      <span>
        {statusLabel}{' '}
        {showNotesTooltip && (
          <Tooltip
            placement="top"
            title={`Approval note: ${
              approvalNotes?.length ? approvalNotes : 'none'
            }`}
          >
            <TooltipIcon />
          </Tooltip>
        )}
      </span>
      {showDownloadResultsButton && (
        <Button
          size="extra-small"
          variant="outlined"
          color="primary"
          onClick={handleDowonloadResults}
        >
          Download results
        </Button>
      )}
      {showEditNotesButton && (
        <Button
          size="extra-small"
          variant="outlined"
          color="primary"
          onClick={() => setShowNotesField(true)}
        >
          Edit note
        </Button>
      )}
      {showNotesField && (
        <NotesContainer spacing="sm">
          <TextField
            value={notes}
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Enter approval note"
            onChange={(e) => setNotes(e.target.value)}
            sx={{ minHeight: 0 }}
            slotProps={{
              input: {
                sx: { minHeight: 0 },
              },
            }}
          />
          {showApproveButton && (
            <NotesButton
              size="extra-small"
              variant="outlined"
              color="primary"
              onClick={() => handleApprove?.(notes)}
            >
              {approveButtonText}
            </NotesButton>
          )}
          {showSaveNotesButton && (
            <NotesButton
              size="extra-small"
              variant="outlined"
              color="primary"
              onClick={() => handleSaveNotes?.(notes)}
            >
              Save note
            </NotesButton>
          )}
        </NotesContainer>
      )}
    </Row>
  );
};

export default KycResult;
