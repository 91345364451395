import { FC } from 'react';
import AlertDialog from 'components/AlertDialog';
import Typography from '@mui/material/Typography';
import { Disclosure } from 'further-ui/hooks/useDisclosure';

type Props = {
  disclosure: Disclosure;
};

const UpdatedCommitmentErrorModal: FC<Props> = ({ disclosure }) => (
  <AlertDialog
    open={disclosure.isOpen}
    title="Funds received is too high"
    content={
      <>
        <Typography>
          Your change will result in the total commitment (plus any advice fee,
          if applicable) being below the funds received to date. To make this
          change, please first lodge a refund transaction or unreconcile a
          connected transaction.
        </Typography>
      </>
    }
    onClose={disclosure.onClose}
    btnLabels={{
      confirm: false,
      cancel: 'OK',
    }}
  />
);

export default UpdatedCommitmentErrorModal;
