import { AxiosError } from 'axios';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api, AccountType } from 'further-types/investor';
import { api } from 'lib/httpClient';
import { useNotification } from 'hooks/ui/useNotification';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { ErrorMessages } from 'further-ui/labels';

type Options = {
  onExportInvestorStatement?: (data: object, date?: Date) => void;
};

function useGetInvestor(investorId?: string) {
  return useQuery({
    queryKey: ['investor', investorId],
    queryFn: async () => {
      const {
        data: { data },
      } = await api.get<ApiResponse<Api.GetInvestorResponse>>(
        `investor/list/${investorId}`,
      );
      return data;
    },
    placeholderData: keepPreviousData,
    enabled: !!investorId,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });
}

function useUpdateInvestor(investorId?: string) {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation({
    throwOnError: false,
    mutationFn: async (data: Api.UpdateInvestorRequest) => {
      const path =
        data.accountType === AccountType.LegalEntity
          ? `investor/update/${investorId}/legal-entity`
          : `investor/update/${investorId}`;

      const { data: result } = await api.put<
        ApiResponse<Api.UpdateInvestorResponse>
      >(path, data);
      return result;
    },
    onSuccess: async () => {
      if (!investorId) return;
      notification.success('Investor has been updated.');
      await queryClient.invalidateQueries({ queryKey: ['investors'] });
      await queryClient.invalidateQueries({
        queryKey: ['investor', investorId],
      });
    },
    onError: (error) => {
      const errorMessage = (error as AxiosError<ApiResponse>).response?.data
        ?.responseMsg;
      notification.error(errorMessage ?? 'Something went wrong');
    },
  });
}

function useCreateInvestor() {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation({
    mutationFn: async (data: Api.CreateInvestorRequest) => {
      const path =
        data.accountType === AccountType.LegalEntity
          ? '/investor/create/legal-entity'
          : '/investor/create/';
      const { data: result } = await api.post<
        ApiResponse<Api.CreateInvestorResponse>
      >(path, data);
      return result;
    },
    onSuccess: async () => {
      notification.success('Investor has been created.');
      await queryClient.invalidateQueries({ queryKey: ['investors'] });
      await queryClient.invalidateQueries({ queryKey: ['investors-lean'] });
    },
    onError: (error) => {
      const errorMessage = (error as AxiosError<ApiResponse>).response?.data
        ?.responseMsg;
      notification.error(errorMessage ?? 'Something went wrong');
    },
  });
}

function useExportInvestorStatement(
  investorId?: string,
  onExport?: Options['onExportInvestorStatement'],
) {
  const handleApiException = useApiExceptionHandler();
  const notification = useNotification();

  return useMutation({
    mutationFn: async (valuationDate?: Date) => {
      const response = await api.post(
        'investor/get-investor-statements',
        { investorId, valuationDate },
        {
          responseType: 'blob',
        },
      );

      return response.data;
    },
    onSuccess: async (data, valuationDate) => {
      onExport?.(data, valuationDate); // pass back valuation date for filename
    },
    onError: async (error: AxiosError) => {
      if (error?.response?.status === 404) {
        notification.error(ErrorMessages.NoInvestmentsFoundForDate);
      } else {
        handleApiException(error);
      }
    },
  });
}

export function useInvestor(
  investorId?: string,
  { onExportInvestorStatement }: Options = {},
) {
  const fetch = useGetInvestor(investorId);
  const { isPending: isUpdatingInvestor, mutate: updateInvestor } =
    useUpdateInvestor(investorId);
  const { isPending: isCreatingInvestor, mutate: createInvestor } =
    useCreateInvestor();
  const {
    isPending: isExportInvestorStatementLoading,
    mutate: exportInvestorStatement,
  } = useExportInvestorStatement(investorId, onExportInvestorStatement);

  return {
    fetch,
    isUpdatingInvestor,
    isCreatingInvestor,
    updateInvestor,
    createInvestor,
    exportInvestorStatement,
    isExportInvestorStatementLoading,
  };
}
