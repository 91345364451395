import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PageContainer from 'components/PageContainer';
import { getCurrentSharePrice } from 'helpers/company/getCurrentSharePrice';
import { numberToCurrencyString } from 'further-ui/utils';
import { useMutateShareSplit } from 'hooks/data/company/useShareSplits';
import { createCompanyEditRoute } from 'adminConstants/routes';
import DetailsStep from './DetailsStep';
import SummaryStep from './SummaryStep';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import useCompany from 'hooks/data/company/useCompany';
import { format } from 'date-fns';
import PageContent from 'components/PageContent';

const steps = {
  DETAILS: 0,
  SUMMARY: 1,
};

const AddShareSplit: React.FC = () => {
  const history = useHistory();
  const handleApiRequest = useApiRequestHandler();
  const { companyId } = useParams<{ companyId: string }>();
  const [step, setStep] = useState(steps.DETAILS);
  const [shareSplitFactor, setShareSplitFactor] = useState(null);
  const [shareSplitDate, setShareSplitDate] = useState<Date>();
  const companyQuery = useCompany(companyId);
  const { createShareSplit } = useMutateShareSplit({ params: { companyId } });

  useEffect(() => {
    setStep(steps.DETAILS);
  }, [companyId]);

  //@ts-expect-error
  const currentSharePrice = getCurrentSharePrice(companyQuery.data);
  const currentSharePriceDisplay = numberToCurrencyString(currentSharePrice, {
    unlimitedDp: true,
  });
  // This prevents an issue with JS where it cannot accurately represent some
  // decimal numbers. For example, 2.9 / 100 = 0.028999999999999998. In this
  // case we can assume that a share price will never need to be more than 13
  // decimal places, so anything above that is a maths error that can be rounded out.
  const newSharePrice = Number(
    ((currentSharePrice ?? 0) / Number(shareSplitFactor)).toFixed(13),
  );
  const newSharePriceDisplay = numberToCurrencyString(newSharePrice, {
    unlimitedDp: true,
  });

  const onSubmit = async () => {
    const data = {
      companyId,
      date: shareSplitDate ? format(shareSplitDate, 'dd/MM/yyyy') : undefined,
      shareSplitFactor,
      sharePricePreSplit: currentSharePrice,
      sharePricePostSplit: newSharePrice,
    };

    await handleApiRequest(async (success) => {
      if (createShareSplit.isPending) return;
      const response = await createShareSplit.mutateAsync({ data });
      success(response?.responseMsg);
      history.push(createCompanyEditRoute(companyId));
    });
  };

  return (
    <PageContainer heading="Update company: Split shares">
      <PageContent header="Split shares">
        {step === steps.DETAILS && (
          <DetailsStep
            company={companyQuery}
            shareSplitDate={shareSplitDate}
            setShareSplitDate={setShareSplitDate}
            shareSplitFactor={shareSplitFactor}
            setShareSplitFactor={setShareSplitFactor}
            steps={steps}
            setStep={setStep}
            currentSharePriceDisplay={currentSharePriceDisplay}
          />
        )}

        {step === steps.SUMMARY && (
          <SummaryStep
            company={companyQuery}
            shareSplitDate={shareSplitDate}
            shareSplitFactor={shareSplitFactor}
            currentSharePriceDisplay={currentSharePriceDisplay}
            newSharePriceDisplay={newSharePriceDisplay}
            onSubmit={onSubmit}
            onBack={() => setStep(steps.DETAILS)}
          />
        )}
      </PageContent>
    </PageContainer>
  );
};

export default AddShareSplit;
