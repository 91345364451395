import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Edit, FlashOn, ViewQuilt, Mail } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid2';
import GridContainer from 'components/GridContainer';
import HoverInfoCard from 'components/HoverInfoCard';
import useClosedTrancheDashboard from 'hooks/data/dashboards/useClosedTrancheDashboard';
import {
  ALLOCATIONS_UPLOAD,
  DASHBOARD_MY_FIRM,
  EIS_ADD,
  INVESTOR_REPORTING_ADD,
  createSingleFirmRoute,
} from 'adminConstants/routes';
import { InvestmentsByAdviserTable } from 'components/DashboardWidgets/InvestmentsByAdviserTable';
import { InvestmentsByLocation } from 'components/DashboardWidgets/InvestmentsByLocation';
import { InvestmentsByOrganisationTable } from 'components/DashboardWidgets/InvestmentsByOrganisationTable';
import { TrancheSnapshot } from 'components/DashboardWidgets/TrancheSnapshot';
import { TrancheCompaniesTable } from 'components/DashboardWidgets/TrancheCompaniesTable';
import { TrancheDeploymentSummary } from 'components/DashboardWidgets/TrancheDeploymentSummary';
import { TrancheValueByDate } from 'components/DashboardWidgets/TrancheValueByDate';
import { useGetRole } from 'hooks/ui/useGetRole';
import { InvestmentsByTrancheTable } from 'components/DashboardWidgets/InvestmentsByTrancheTable';
import PageContainer from 'components/PageContainer';
import { Box } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  scrollableTable: {
    maxHeight: '500px',
    marginBottom: '24px',
    paddingBottom: '0 !important',
    overflow: 'auto',
  },
  green: {
    color: theme.palette.text.rootColor,
  },
  divider: {
    margin: '0 24px',
  },
  gridGap1: {
    gridGap: '1rem',
  },
  flexGrid: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '1rem',
    alignItems: 'stretch',

    '&> div': {
      height: 'auto',
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  dcont: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ClosedTrancheDashboard: React.FC = () => {
  const { firmId } = useGetRole();
  const { classes, cx } = useStyles();
  const { trancheId } = useParams<{ trancheId: string }>();
  const [trancheSnapshot, setTrancheSnapshot] = useState({});
  const [trancheDetails, setTrancheDetails] = useState<Record<string, any>>({});
  const [investmentsByAdviser, setInvestmentsByAdviser] = useState([]);
  const [investmentsByOrganisation, setInvestmentsByOrganisation] = useState(
    [],
  );
  const [investmentsByLocation, setInvestmentsByLocation] = useState({});
  const [trancheCompanies, setTrancheCompanies] = useState([]);
  const [deploymentSummary, setDeploymentSummary] = useState({});
  const [valueByDate, setValueByDate] = useState({});

  const closedTrancheDashboard = useClosedTrancheDashboard({
    params: { trancheId },
  });

  useEffect(() => {
    const response = closedTrancheDashboard.data;
    if (response) {
      setTrancheSnapshot(response?.trancheSnapshot);
      setTrancheDetails(response?.trancheSnapshot.trancheDetails);
      setInvestmentsByAdviser(response?.investmentsByAdviser);
      setInvestmentsByOrganisation(response?.investmentsByOrganisation);
      setInvestmentsByLocation(response?.investmentsByLocation);
      setTrancheCompanies(response?.companies);
      setDeploymentSummary(response?.deploymentSummary);
      setValueByDate(response?.valueByDate);
    }
  }, [closedTrancheDashboard.data]);

  return (
    <PageContainer
      heading={`${trancheDetails.firmId?.firmName}'s ${trancheDetails.fundName} dashboard`}
      loading={closedTrancheDashboard.isFetching}
    >
      <Box marginBottom={4}>
        <TrancheValueByDate data={valueByDate} />
      </Box>
      <GridContainer>
        <Grid
          className={classes.flexGrid}
          size={{
            md: 6,
            xs: 12,
          }}
        >
          <TrancheSnapshot
            trancheSnapshot={trancheSnapshot}
            trancheDetails={trancheDetails}
            trancheId={trancheId}
            classes={classes}
          />
          <TrancheCompaniesTable
            rows={trancheCompanies}
            trancheId={trancheId}
            trancheName={trancheDetails.fundName}
            parentClasses={classes}
          />
        </Grid>

        <Grid
          className={classes.flexGrid}
          size={{
            md: 6,
            xs: 12,
          }}
        >
          <TrancheDeploymentSummary
            deploymentSummary={deploymentSummary}
            classes={classes}
          />
        </Grid>

        <Grid size={12}>
          <InvestmentsByTrancheTable trancheId={trancheId} classes={classes} />
        </Grid>

        <Grid
          size={{
            md: 8,
            xs: 12,
          }}
        >
          <InvestmentsByAdviserTable
            rows={investmentsByAdviser}
            classes={classes}
          />
        </Grid>

        <Grid
          size={{
            md: 4,
            xs: 12,
          }}
        >
          <InvestmentsByLocation investmentData={investmentsByLocation} />
        </Grid>

        <Grid
          className={cx(classes.dcont, classes.gridGap1)}
          size={{
            md: 5,
            xs: 12,
          }}
        >
          <HoverInfoCard
            icon={<FlashOn style={{ color: '#ffffff' }} />}
            backgroundColor="#656565"
            subTitle="Start EIS Wizard"
            linkOnArrow={`${EIS_ADD}?trancheId=${trancheId}`}
          />
          <HoverInfoCard
            icon={<Edit style={{ color: '#ffffff' }} />}
            backgroundColor="#ECC9A2"
            subTitle="Create new allocation"
            linkOnArrow={ALLOCATIONS_UPLOAD}
          />
          <HoverInfoCard
            icon={<Mail style={{ color: '#ffffff' }} />}
            backgroundColor="#2E9A9A"
            subTitle="Create investor report"
            linkOnArrow={`${INVESTOR_REPORTING_ADD}?trancheId=${trancheId}`}
          />
          <HoverInfoCard
            icon={<ViewQuilt style={{ color: '#ffffff' }} />}
            backgroundColor="#56B26C"
            subTitle="View firm dashboard"
            linkOnArrow={
              firmId
                ? DASHBOARD_MY_FIRM
                : createSingleFirmRoute(trancheDetails.firmId?._id)
            }
          />
        </Grid>

        <Grid
          size={{
            md: 7,
            xs: 12,
          }}
        >
          <InvestmentsByOrganisationTable
            rows={investmentsByOrganisation}
            classes={classes}
          />
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default ClosedTrancheDashboard;
