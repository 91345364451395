import { Row } from 'components/Layout';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const PageHeader: React.FC<Props> = ({ children, className }) => {
  return (
    <Row
      spacing="md"
      breakpoint="sm"
      justify="space-between"
      centered
      className={className}
    >
      {children}
    </Row>
  );
};

export default PageHeader;
