import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { calculatePerformanceFee } from '@redux/actions/Exit';
import AppNumberInput from 'components/FormElements/AppNumberInput';
import { useNotification } from 'hooks/ui/useNotification';

type Props = any; //TODO: define types

const PerformanceFeeCell: React.FC<Props> = ({
  shareholdingId,
  totalSaleValue,
  setOverride,
  performanceFee,
  disableTable,
}) => {
  const dispatch = useDispatch();
  const notification = useNotification();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPerformanceFee = async () => {
      setLoading(true);

      const response = await dispatch<any>(
        calculatePerformanceFee(
          {
            shareholdingId,
            totalSaleValue,
          },
          notification,
        ),
      );
      setLoading(false);
      if (response) {
        setOverride(shareholdingId, {
          performanceFee: Number(response.totalPerformanceFee),
        });
      }
    };
    if (totalSaleValue > 0 && !disableTable) fetchPerformanceFee();

    if (totalSaleValue <= 0 && !disableTable) {
      setOverride(shareholdingId, {
        performanceFee: 0,
      });
    }
  }, [totalSaleValue]);

  if (loading) return <>{'...'}</>;

  return (
    <AppNumberInput
      disabled={disableTable}
      decimals={2}
      placeholder="0"
      defaultValue={0}
      min={0}
      gbpStartAdornment={true}
      value={performanceFee}
      onChange={(newValue) => {
        setOverride(shareholdingId, {
          performanceFee: Number(newValue),
        });
      }}
    />
  );
};

export default PerformanceFeeCell;
