import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { UseQueryResult } from '@tanstack/react-query';
import { AppSelectBox, FieldRow } from 'components/FormElements';
import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import { useAllocations } from 'hooks/data/allocation/useAllocations';
import { pluralize } from 'utils/pluralize';

const useStyles = makeStyles()((theme) => ({
  errorText: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
  },
}));

type Props = {
  firmId: string;
  companyId: string;
  taxReliefElegibleAllocationIds: Array<string>;
  onChange: (taxReliefElegibleAllocationIds: Array<string>) => void;
  disabled: boolean;
  error?: string;
};

const TaxReliefElegibleAllocations: React.FC<Props> = ({
  firmId,
  companyId,
  taxReliefElegibleAllocationIds,
  onChange,
  disabled,
  error,
}) => {
  const { classes } = useStyles();

  const allocations: UseQueryResult<{
    uploadShareHoldings: Array<{
      _id: string;
      totalInvestmentAmount: number;
      numberOfTranches: number;
      date: string;
      numberOfInvestments: number;
    }>;
  }> = useAllocations({
    params: { firmId, companyId },
  });

  const availableAllocations = (
    allocations?.data?.uploadShareHoldings ?? []
  ).map((allocation) => {
    const amountInvested = numberToCurrencyString(
      allocation?.totalInvestmentAmount,
    );
    const noOfTranches = `${allocation?.numberOfTranches} ${pluralize(
      'tranche',
      allocation?.numberOfTranches,
    )}`;
    const noOfInvestments = `${allocation?.numberOfInvestments} ${pluralize(
      'subscription',
      allocation?.numberOfInvestments,
    )}`;

    return {
      _id: allocation._id,
      label: `${dateToLabel(
        allocation?.date,
      )} - ${amountInvested} - ${noOfTranches} - ${noOfInvestments}`,
    };
  });

  const isSelectedAll =
    taxReliefElegibleAllocationIds.length === availableAllocations.length;

  return (
    <FieldRow title="S/EIS eligible allocations" centerTitle>
      <AppSelectBox
        data={availableAllocations}
        valueKey="_id"
        displayEmpty
        name="taxReliefElegibleAllocationIds"
        labelKey="label"
        multiple
        variant="outlined"
        disabled={disabled}
        value={taxReliefElegibleAllocationIds}
        isCheckedAll={isSelectedAll}
        onChange={(event) => {
          const value = event.target.value as Array<string>;

          if (value.includes('all')) {
            if (isSelectedAll) {
              onChange([]);
            } else {
              onChange(
                availableAllocations.map((allocation) => allocation._id),
              );
            }
          } else {
            onChange(value);
          }
        }}
        //@ts-expect-error
        renderValue={(selected: Array<string>) => (
          <Box
            sx={{
              overflow: 'hidden',
            }}
          >
            {selected.length === 0 ? (
              <span>Select allocation(s)</span>
            ) : (
              selected
                .map((allocationId) => {
                  return availableAllocations.find(
                    (allocation) => allocation?._id === allocationId,
                  )?.label;
                })
                .join(', ')
            )}
          </Box>
        )}
      />
      {error && <p className={classes.errorText}>{error}</p>}
    </FieldRow>
  );
};

export default TaxReliefElegibleAllocations;
