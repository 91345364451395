import React from 'react';
import FullPageSuccess from 'components/FullPageSuccess';
import { INVESTOR_REPORTING } from 'adminConstants/routes';
import { useGetInvestorsInReport } from 'hooks/data/investorReporting/useGetInvestorsInReport';
import { makeStyles } from 'tss-react/mui';
import { useParams } from 'react-router-dom';
import { useDownload } from 'hooks/ui/useDownload';
import Button from 'components/Button';

const useStyles = makeStyles()((theme) => ({
  text: {
    fontSize: 15,
    textAlign: 'center',
    marginTop: '2rem',
    marginBottom: '0.4rem',
    fontStyle: 'italic',
  },
  downloadText: {
    fontSize: 15,
    textAlign: 'center',
    color: theme.palette.text.green,
    textDecoration: 'underline',
    fontStyle: 'italic',
    cursor: 'pointer',
    marginTop: '1rem',
  },
}));

const SuccessPage: React.FC = () => {
  const { id: reportingId } = useParams<{ id: string }>();
  const { isLoading, data } = useGetInvestorsInReport({
    params: { reportingId, countOnly: true },
  });
  const fetchPdfSample = useDownload({
    filename: 'sample.pdf',
    errorMessage: 'The sample update is still being created. Please try again.',
  });
  const { classes } = useStyles();

  const downloadSample = () => {
    if (!fetchPdfSample.isPending) {
      fetchPdfSample.mutate(
        `investor-reporting/export-sample-pdf/${reportingId}`,
      );
    }
  };

  return !isLoading && data ? (
    <FullPageSuccess
      title="Your update has been successfully processed"
      additionalText={`This update has been created for ${data.investorsCount} investors. Your investors will now be able to view this update in their investor portals.`}
      proceedLink={INVESTOR_REPORTING}
      proceedText="View all submitted updates"
    >
      <>
        <p className={classes.text}>
          The system is currently creating individual PDF updates which will be
          available for download from the Investor Reporting table in 15-30
          minutes.
        </p>

        <Button
          onClick={downloadSample}
          variant="text"
          loading={fetchPdfSample.isPending}
        >
          Download a sample update
        </Button>
      </>
    </FullPageSuccess>
  ) : null;
};

export default SuccessPage;
