import { Grid2 } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = {
  pageId?: string;
};

const Filter: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  return <Grid2 size={{ xs: 12, md: 'grow' }}>{children}</Grid2>;
};

export default Filter;
