import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Button, Switch } from '@mui/material';
import { useGetRole } from 'hooks/ui/useGetRole';
import PageContainer from 'components/PageContainer';
import InvestorDetails from './InvestorDetails';
import TaxAndBankDetail from './TaxAndBankDetail';
import { useInvestor } from 'hooks/data/investor/useInvestor';
import AlertDialog from 'components/AlertDialog';
import { useDisclosure } from 'further-ui/hooks';
import { toRequestDate } from 'further-ui/utils';
import { AccountType } from 'further-types/investor';
import { createInvestorViewRoute } from 'adminConstants/routes';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  InvestorFormValues,
  getInitialValues,
  investorSchema,
} from 'helpers/investor/investorForm';
import useSetBankDetails from 'hooks/data/investor/useSetBankDetails';
import PageContent from 'components/PageContent';
import RecordView from 'components/RecordView';
import ButtonGroup from 'components/ButtonGroup';
import Heading from 'components/Heading';
import PageHeader from 'components/PageHeader';
import ResponsiveActions from 'components/PageHeader/ResposiveActions';
import { Row } from 'components/Layout';
import Spacing from 'components/Spacing';

const UpdateInvestor: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const { isSuperAdmin } = useGetRole();

  const history = useHistory();
  const nameChangeDisclosure = useDisclosure();

  const { state } = useLocation<{ returnTo: string }>();

  const {
    fetch,
    updateInvestor,
    createInvestor,
    isCreatingInvestor,
    isUpdatingInvestor,
  } = useInvestor(id);
  const setBankDetails = useSetBankDetails();

  const investorDetails = fetch.data;

  //@ts-expect-error
  const initialValues = getInitialValues(investorDetails);

  const { control, handleSubmit, setValue } = useForm<InvestorFormValues>({
    shouldFocusError: true,
    resolver: zodResolver(investorSchema),
    criteriaMode: 'all',
    //@ts-expect-error
    defaultValues: initialValues,
    //@ts-expect-error
    values: id ? initialValues : undefined,
  });

  const accountType = useWatch({ control, name: 'accountType' });

  const handleBankDetails = async (
    investorId: string,
    values: InvestorFormValues,
  ) => {
    setBankDetails.mutate({ investorId, ...values });
    if (state?.returnTo) {
      history.push(state.returnTo);
    } else {
      history.push('/investor');
    }
  };

  const onCancel = async () => {
    history.push('/investor');
  };

  const getSubmitHandler =
    ({ disableAllEmails = null, ignoreNameChange = null } = {}) =>
    (values: InvestorFormValues) => {
      const identificationDetailsChanged =
        (values.accountType === AccountType.Individual &&
          (investorDetails?.firstName !== values.firstName ||
            investorDetails?.lastName !== values.lastName ||
            investorDetails?.middleName !== values.middleName)) ||
        (values.accountType === AccountType.LegalEntity &&
          values.registrationNumber !== investorDetails?.registrationNumber);

      if (id && ignoreNameChange === null && identificationDetailsChanged) {
        nameChangeDisclosure.onOpen();
        return;
      }

      const request = {
        ...values,
        dob:
          values.accountType === AccountType.Individual
            ? toRequestDate(values.dob)
            : null,
        registrationDate:
          values.accountType === AccountType.LegalEntity
            ? toRequestDate(values.registrationDate)
            : null,
        newPassword: values.newPassword ? values.newPassword : undefined,
        dateRegister: values.dateRegister?.toString(),
      };

      const submitHandler = id ? updateInvestor : createInvestor;

      submitHandler(
        //@ts-expect-error request types need improvement
        {
          ...{
            id,
            type: 'admin',
            signUpSource: isSuperAdmin ? 'superAdmin' : 'admin',
            ignoreNameChange,
          },
          ...request,
          ...(!id && disableAllEmails !== null ? { disableAllEmails } : {}),
        },
        {
          onSuccess: (data) => handleBankDetails(data.data._id, values),
        },
      );
      nameChangeDisclosure.onClose();
    };

  const disableSubmit = isCreatingInvestor || isUpdatingInvestor;
  const isLegalEntity = accountType === AccountType.LegalEntity;

  return (
    <PageContainer heading={`Investor: ${id ? 'Edit' : 'Add'} Investor`}>
      <PageContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Spacing>
            <PageHeader>
              <Heading variant="h2" noMargin>
                Investor details
              </Heading>
              <ResponsiveActions>
                {id ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push(createInvestorViewRoute(id))}
                  >
                    View Investor
                  </Button>
                ) : (
                  <Controller
                    name="accountType"
                    control={control}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                      <Row centered spacing="xs">
                        <span>Individual</span>
                        <Switch
                          {...rest}
                          onChange={(e) => {
                            onChange(
                              e.target.checked
                                ? AccountType.LegalEntity
                                : AccountType.Individual,
                            );
                          }}
                          checked={value === AccountType.LegalEntity}
                          inputProps={{ 'aria-label': 'Switch' }}
                        />
                        <span>Legal entity</span>
                      </Row>
                    )}
                  />
                )}
              </ResponsiveActions>
            </PageHeader>

            <RecordView>
              <InvestorDetails
                id={id}
                isSuperAdmin={isSuperAdmin}
                control={control}
                accountType={accountType}
              />
              {/* @ts-expect-error */}
              <TaxAndBankDetail control={control} setValue={setValue} />
              <ButtonGroup>
                {id ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit(getSubmitHandler())()}
                    type="submit"
                    disabled={disableSubmit}
                  >
                    Update
                  </Button>
                ) : (
                  <>
                    {!isSuperAdmin && (
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={disableSubmit}
                        onClick={() =>
                          handleSubmit(
                            //@ts-expect-error
                            getSubmitHandler({ disableAllEmails: false }),
                          )()
                        }
                      >
                        Add and invite investor
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={disableSubmit}
                      onClick={() =>
                        handleSubmit(
                          //@ts-expect-error
                          getSubmitHandler({ disableAllEmails: true }),
                        )()
                      }
                    >
                      {isSuperAdmin
                        ? 'Add investor'
                        : 'Add investor with emails disabled'}
                    </Button>
                  </>
                )}
                <Button
                  variant="contained"
                  onClick={onCancel}
                  disabled={disableSubmit}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </RecordView>
          </Spacing>
        </form>
      </PageContent>
      <AlertDialog
        open={nameChangeDisclosure.isOpen}
        onClose={() =>
          //@ts-expect-error
          handleSubmit(getSubmitHandler({ ignoreNameChange: false }))()
        }
        onConfirm={() =>
          //@ts-expect-error
          handleSubmit(getSubmitHandler({ ignoreNameChange: true }))()
        }
        title="Warning"
        content={
          isLegalEntity ? (
            <p>
              You have changed this investor’s registration number. Do you want
              to reset the investor’s KYB as well? If so, the investor will be
              asked to re-complete KYB when they create their next
              subscription.. Old KYB information will remain on their account.
            </p>
          ) : (
            <p>
              You have changed this investor’s name. Do you want to reset the
              investor’s KYC as well? If so, the investor will be asked to
              re-complete KYC when they make their next subscription. Old KYC
              information will remain on their account.
            </p>
          )
        }
        btnLabels={{
          cancel: isLegalEntity ? 'Yes, reset KYB ' : 'Yes, reset KYC',
          confirm: isLegalEntity ? 'No, maintain KYB' : 'No, maintain KYC',
        }}
      />
    </PageContainer>
  );
};
export default UpdateInvestor;
