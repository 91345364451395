import { useStyles } from './styles';
import AppNumberInput from 'components/FormElements/AppNumberInput';

const AccruedFeesPercentageAllocationCell = ({
  areInlineAccruedFeesSet,
  accruedFeesPercentageAllocation,
  setAccruedFeesPercentageAllocation,
  disableTable,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.flex}>
      <AppNumberInput
        percentEndAdornment
        disabled={disableTable}
        decimals={10}
        min={0}
        max={100}
        value={areInlineAccruedFeesSet ? '-' : accruedFeesPercentageAllocation}
        onChange={(newValue) => {
          setAccruedFeesPercentageAllocation(Number(newValue));
        }}
      />
    </div>
  );
};

export default AccruedFeesPercentageAllocationCell;
