import React, { useEffect, useState, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import { TrancheVisibility } from 'further-types/fund';
import FeeManagement from './FeeManagement';
import BasicInformation from './BasicInformation';
import TrancheDataSnapshot from './TrancheDataSnapshot';
import TranchePromotionInformation from './TrancheInvestmentDocumentation';
import BankDetails from './BankDetails';
import FinalDealToggleAndLog from './FinalDealToggleAndLog';
import AdditionalInformation from './AdditionalInformation';
import ConfirmationEmailCustomisation from './ConfirmationEmailCustomisation';
import AccordionItem from './AccordionItem';
import { type } from 'adminConstants/typeConstant';
import { useGetRole } from 'hooks/ui/useGetRole';
import { isValidEmail } from 'further-ui/utils';
import { useNotification } from 'hooks/ui/useNotification';
import useFileUpload from 'hooks/ui/useFileUpload';
import { UploadType } from 'further-types/files';
import { useTags } from 'hooks/data/tag/useTags';
import { useFund } from 'hooks/data/fund/useFund';
import PageContent from 'components/PageContent';

const useStyles = makeStyles()(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const AddUpdateTranche: React.FC = () => {
  const history = useHistory();
  const { firmId: usersFirmId } = useGetRole();
  const { error: showError } = useNotification();
  const { uploadFile } = useFileUpload();
  const { id: idInUrl } = useParams<{ id?: string }>();
  const [id, setId] = useState(idInUrl ?? '');
  const [isSyndicate, setIsSyndicate] = useState(false);
  const { get, edit, create } = useFund(id);

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Tranches', link: '/tranche' },
    {
      label: idInUrl ? 'Update Tranche' : 'Add Tranche',
      link: '/',
      isActive: true,
    },
  ];

  const { tags } = useTags();
  const [expanded, setExpanded] = useState('basicInformation');
  const [loading, setLoading] = useState(true);
  const { classes } = useStyles();
  const [errorTextBasic, setErrorTextBasic] = useState({});
  const [errorTextKeyData, setErrorTextKeyData] = useState({});

  const [tagName, setTagName] = useState();
  const [bank, setBank] = useState({ openBankingMode: false });
  const [bankError, setBankError] = useState();
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [hasKiCertificate, setHasKiCertificate] = useState(false);
  const [additionalInfoError, setAdditionalInfoError] = useState();
  const [overallHurdleBasis, setOverallHurdleBasis] = useState('');
  const [overallFeesInHurdle, setOverallFeesInHurdle] = useState();

  const [value, setValue] = useState({
    firmId: usersFirmId || '',
    fundName: '',
    fundStatus: 1,
    startDate: null,
    finishDate: null,
    closedDate: null,
    tag: null,
    isProfessionalClientsOnly: false,
    trancheVisibility: TrancheVisibility.ShowOnFirm,
  });
  const [keyData, setKeyData] = useState({
    incomeTaxRebate: '',
    targetReturn: '',
    targetPortfolioSize: '',
    minimumInvestment: '',
  });
  const [tranchePromotion, setTranchePromotion] = useState({
    applicationFormFile: null,
    keyInfoFile: null,
    prospectusFile: null,
    prospectusFilePreview: null,
    keyInfoFilePreview: null,
    applicationFormFilePreview: null,
  });
  const [investment, setInvestment] = useState([]);
  const [feeManagement, setFeeManagement] = useState({
    key: 'feeManagement',
    coreFees: [],
    performanceFees: [],
  });
  const [feeManagementError, setFeeManagementError] = useState({
    coreFee: [],
    performanceFees: [],
  });
  const [confirmationEmailCustomisation, setConfirmationEmailCustomisation] =
    useState({
      whatToExpectNow:
        'We are now processing your application. If any questions arise as we complete this process, our team may contact you. If you do not hear from us, your subscription has been accepted successfully.',
      additionalAttachment: null,
      additionalAttachmentPreview: null,
    });

  const calculateCoreFee = (vat, netFee, index) => {
    if (vat) {
      //@ts-expect-error
      feeManagement.coreFees[index]['grossFee'] = Number(
        (netFee * 1.2).toFixed(10),
      );
    } else {
      //@ts-expect-error
      feeManagement.coreFees[index]['grossFee'] = netFee;
    }
  };

  const onChangeCoreFee = (event, index) => {
    const { name, value } = event.target;
    if (name === 'vat') {
      //@ts-expect-error
      calculateCoreFee(value, feeManagement.coreFees[index].netFee, index);
    } else if (name === 'netFee') {
      //@ts-expect-error
      calculateCoreFee(feeManagement.coreFees[index].vat, value, index);
    }
    if (name === 'displayFeeType') {
      let isInitial = false;
      let isFixed = false;
      if (
        type.addFeeTypes.initialFixed === value ||
        type.addFeeTypes.initialPercentage === value
      ) {
        isInitial = true;
      }
      if (
        type.addFeeTypes.initialFixed === value ||
        type.addFeeTypes.recurringFixed === value
      ) {
        isFixed = true;
      }
      //@ts-expect-error
      feeManagement.coreFees[index].feeCalculationBasis = isFixed
        ? type.feeCalculationBasis.fixed
        : type.feeCalculationBasis.percentage;
      //@ts-expect-error
      feeManagement.coreFees[index].feeType = isInitial
        ? type.feeTypes.initial
        : type.feeTypes.recurring;
      if (isInitial) {
        //@ts-expect-error
        delete feeManagement.coreFees[index].chargingFrequency;
        //@ts-expect-error
        delete feeManagement.coreFees[index].startingPeriod;
        //@ts-expect-error
        delete feeManagement.coreFees[index].finishingPeriod;
      } else {
        //@ts-expect-error
        feeManagement.coreFees[index].chargingFrequency =
          //@ts-expect-error
          feeManagement?.coreFees[index]?.chargingFrequency || null;
        //@ts-expect-error
        feeManagement.coreFees[index].startingPeriod =
          //@ts-expect-error
          feeManagement?.coreFees[index]?.startingPeriod || null;
        //@ts-expect-error
        feeManagement.coreFees[index].finishingPeriod =
          //@ts-expect-error
          feeManagement?.coreFees[index]?.finishingPeriod || null;
      }
    }
    //@ts-expect-error
    feeManagement.coreFees[index][name] = value;
    setFeeManagement({ ...feeManagement });
  };

  const calculateFeePerformance = (vat, netFee, index) => {
    if (vat) {
      //@ts-expect-error
      feeManagement.performanceFees[index]['feePercentageIncVAT'] = (
        netFee * 1.2
      ).toFixed(2);
    } else {
      //@ts-expect-error
      feeManagement.performanceFees[index]['feePercentageIncVAT'] = netFee;
    }
  };

  const onChangePerformanceFees = (event, index) => {
    const { name, value } = event.target;

    if (name === 'hurdleBasis') {
      setOverallHurdleBasis(value);
    }

    if (name === 'includeFeesInHurdle') {
      setOverallFeesInHurdle(value);
    }

    if (!index && index !== 0) {
      // if no index supplied, apply the value to all performance fees
      feeManagement.performanceFees.forEach((fee) => {
        //@ts-expect-error
        fee[name] = value;
      });
    } else {
      if (name === 'vatPercentage') {
        calculateFeePerformance(
          value,
          //@ts-expect-error
          feeManagement.performanceFees[index].feePercentageExVAT,
          index,
        );
      } else if (name === 'feePercentageExVAT') {
        calculateFeePerformance(
          //@ts-expect-error
          feeManagement.performanceFees[index].vatPercentage,
          value,
          index,
        );
      }
      //@ts-expect-error
      feeManagement.performanceFees[index][name] = value;
    }
    setFeeManagement({ ...feeManagement });
  };

  const handleInputChangeAdditional = (e) => {
    const { value } = e.target;
    setAdditionalInfo(value);
  };

  const validateBasicInformation = () => {
    const errors: Record<string, string> = {};
    let isError = false;
    if (!value.firmId) {
      errors.firmId = 'Please select firm.';
      isError = true;
    }
    if (!value.fundName) {
      errors.fundName = 'Please enter tranche name.';
      isError = true;
    }
    if (!value.finishDate) {
      errors.finishDate = 'Please select finish date.';
      isError = true;
    }
    if (value.tag === null) {
      errors.tag = 'Please select fund type.';
      isError = true;
    }
    setErrorTextBasic(errors);
    return {
      errors,
      isError,
    };
  };
  const validateKeyData = () => {
    const errors: Record<string, string> = {};
    let isError = false;

    if (!keyData.minimumInvestment) {
      errors.minimumInvestment = 'Please enter minimum subscription.';
      isError = true;
    }

    if (!keyData.incomeTaxRebate) {
      errors.incomeTaxRebate = 'Please enter income tax relief.';
      isError = true;
    }

    if (!keyData.targetReturn) {
      errors.targetReturn = 'Please select target return.';
      isError = true;
    }

    if (!keyData.targetPortfolioSize) {
      errors.targetPortfolioSize = 'Please enter target portfolio size.';
      isError = true;
    }

    setErrorTextKeyData(errors);

    return {
      errors,
      isError,
    };
  };

  const handleClick = async () => {
    let response;
    const validateData = validateBasicInformation();
    if (!validateData.isError) {
      const requestPayload = {
        key: 'basic',
        ...value,
      };
      if (id) {
        //@ts-expect-error
        edit.mutate(requestPayload);
      } else {
        //@ts-expect-error
        response = await create.mutateAsync(requestPayload);
        setId(response?._id);
      }
      setExpanded('trancheDataSnapshot');
    }
  };

  const handelClickAdditional = async () => {
    if (!additionalInfo) {
      //@ts-expect-error
      setAdditionalInfoError('Please enter notification list.');
    } else {
      const trimmedAdditionalInfo = additionalInfo
        .split(';')
        .map((email) => email.trim());
      const isValid = trimmedAdditionalInfo.every((email) => {
        if (email) {
          return isValidEmail(email);
        }
        return true;
      });
      if (!isValid) {
        setAdditionalInfoError(
          //@ts-expect-error
          'Separate emails with a semi-colon, e.g. name@domain.com;name2@domain.com',
        );
      } else {
        const value = {
          additionalInfo: trimmedAdditionalInfo.join(';'),
          key: 'additionalInfo',
        };
        //@ts-expect-error
        edit.mutate(value);
        //@ts-expect-error
        setAdditionalInfoError('');
        setExpanded('confirmationEmailCustomisation');
      }
    }
  };

  const handleClickKeyData = async () => {
    const validateData = validateKeyData();
    if (!validateData.isError) {
      const requestPayload = {
        key: 'keydata',
        ...keyData,
      };
      //@ts-expect-error
      edit.mutate(requestPayload);
      setExpanded('trancheInvestmentDocumentation');
    }
  };
  const handleClickTranchePromotion = async () => {
    //@ts-expect-error
    if (tranchePromotion?.prospectusFile?.includes('.com/')) {
      tranchePromotion.prospectusFile =
        //@ts-expect-error
        tranchePromotion?.prospectusFile.split('.com/')[1];
    }
    //@ts-expect-error
    if (tranchePromotion?.applicationFormFile?.includes('.com/')) {
      tranchePromotion.applicationFormFile =
        //@ts-expect-error
        tranchePromotion?.applicationFormFile.split('.com/')[1];
    }
    //@ts-expect-error
    if (tranchePromotion?.keyInfoFile?.includes('.com/')) {
      tranchePromotion.keyInfoFile =
        //@ts-expect-error
        tranchePromotion?.keyInfoFile.split('.com/')[1];
    }
    setTranchePromotion({ ...tranchePromotion });
    const value = {
      key: 'prospectus',
      prospectusFile: tranchePromotion?.prospectusFile,
      keyInfoFile: tranchePromotion?.keyInfoFile,
      applicationFormFile: tranchePromotion?.applicationFormFile,
    };
    //@ts-expect-error
    edit.mutate(value);
    setExpanded('feeManagement');
  };

  const handleInputChangeEmailCustomisation = async (name, value) => {
    if (name === 'additionalAttachment') {
      if (!value?.[0]?.path) return;

      try {
        const { filePath } = await uploadFile(
          value[0],
          UploadType.FundDocuments,
        );
        confirmationEmailCustomisation[name] = filePath;
        confirmationEmailCustomisation[name + 'Preview'] = value[0].preview;
      } catch (_) {
        showError("Something went wrong - the file couldn't be uploaded");
      }
    } else if (name === 'additionalAttachmentRemove') {
      confirmationEmailCustomisation.additionalAttachment = null;
      confirmationEmailCustomisation.additionalAttachmentPreview = null;
    } else {
      confirmationEmailCustomisation[name] = value;
    }

    setConfirmationEmailCustomisation({ ...confirmationEmailCustomisation });
  };
  const handleClickConfirmationEmailCustomisation = async () => {
    if (
      //@ts-expect-error
      confirmationEmailCustomisation?.additionalAttachment?.includes('.com/')
    ) {
      confirmationEmailCustomisation.additionalAttachment =
        //@ts-expect-error
        confirmationEmailCustomisation?.additionalAttachment.split('.com/')[1];
    }

    //@ts-expect-error
    edit.mutate({
      key: 'confirmationEmailCustomisation',
      ...confirmationEmailCustomisation,
    });
    setExpanded('trancheHighlights');
  };

  const handleClickBank = async () => {
    let error = false;
    const errors: Record<string, string> = {};
    //@ts-expect-error
    if (!bank.firmBankDetailId) {
      error = true;
      errors.firmBankDetailId = 'Please select a payment account.';
    }
    if (!error) {
      const formData = new FormData();
      formData.append('key', 'bankDetails');
      //@ts-expect-error
      formData.append('firmBankDetailId', bank.firmBankDetailId);
      //@ts-expect-error
      formData.append('title', bank.title || '');
      //@ts-expect-error
      formData.append('openBankingMode', bank.openBankingMode);
      //@ts-expect-error
      edit.mutate(formData);
      setExpanded('additionalInformation');
      setBankError(undefined);
    } else {
      //@ts-expect-error
      setBankError(errors);
    }
  };

  useEffect(() => {
    if (idInUrl) {
      const getTranchebyId = async () => {
        //TODO: fix this
        const response = get.data as any;
        if (response) {
          const value = {
            firmId: response?.firmId?._id,
            fundName: response?.fundName,
            fundStatus: response?.fundStatus,
            tag: response?.tag ? response?.tag?._id : '',
            startDate: response?.startDate
              ? new Date(response.startDate)
              : null,
            closedDate: response?.closedDate
              ? new Date(response.closedDate)
              : null,
            finishDate: response?.finishDate
              ? new Date(response.finishDate)
              : null,
            isProfessionalClientsOnly: response?.isProfessionalClientsOnly,
            trancheVisibility: response?.trancheVisibility
              ? response?.trancheVisibility
              : TrancheVisibility.ShowOnFirm,
          };
          //@ts-expect-error
          setValue({ ...value });
          setTagName(response?.tag?.name);
          setIsSyndicate(response.tag?.isSyndicate);
          setHasKiCertificate(response?.hasKiCertificate);
          setKeyData({
            targetReturn: response?.targetReturn,
            targetPortfolioSize: response?.targetPortfolioSize,
            minimumInvestment: response?.minimumInvestment,
            incomeTaxRebate: response?.incomeTaxRebate,
          });
          setBank({
            //@ts-expect-error
            firmBankDetailId: response?.firmBankDetailId,
            title: response?.title,
            openBankingMode: response?.openBankingMode,
          });

          setTranchePromotion({
            keyInfoFile: response?.keyInfoFile,
            applicationFormFile: response?.applicationFormFile,
            prospectusFile: response?.prospectusFile,
            keyInfoFilePreview: response?.keyInfoFile,
            applicationFormFilePreview: response?.applicationFormFile,
            prospectusFilePreview: response?.prospectusFile,
          });
          if (response.additionalInfo) {
            setAdditionalInfo(response?.additionalInfo);
          }
          setInvestment(response.investment);
          response.coreFees?.forEach((singleFee) => {
            if (
              singleFee.feeCalculationBasis === type.feeCalculationBasis.fixed
            ) {
              singleFee.displayFeeType =
                singleFee.feeType === type.feeTypes.initial
                  ? type.addFeeTypes.initialFixed
                  : type.addFeeTypes.recurringFixed;
            } else {
              singleFee.displayFeeType =
                singleFee.feeType === type.feeTypes.initial
                  ? type.addFeeTypes.initialPercentage
                  : type.addFeeTypes.recurringPercentage;
            }
          });
          setFeeManagement({
            key: 'feeManagement',
            performanceFees: response?.performanceFees,
            coreFees: response?.coreFees,
            //@ts-expect-error
            trancheHasHadAnExit: response?.trancheHasHadAnExit,
          });
          setOverallHurdleBasis(response?.performanceFees[0]?.hurdleBasis);
          setOverallFeesInHurdle(
            response?.performanceFees[0]?.includeFeesInHurdle,
          );
          if (response.whatToExpectNow) {
            confirmationEmailCustomisation.whatToExpectNow =
              response.whatToExpectNow;
          }
          setConfirmationEmailCustomisation({
            ...confirmationEmailCustomisation,
            additionalAttachment: response.additionalAttachment,
            additionalAttachmentPreview: response.additionalAttachment,
          });
          setLoading(false);
        }
      };
      getTranchebyId();
    } else {
      setLoading(false);
    }
  }, [id, idInUrl, get.data]);

  const handleChange = (panel) => (_, isExpanded) => {
    if (id) {
      setExpanded(isExpanded ? panel : false);
    }
  };
  const handleCancel = () => {
    history.push('/tranche');
  };
  const handleChangeTag = (event, item) => {
    setValue({ ...value, tag: event });
    setIsSyndicate(item.isSyndicate);
    setTagName(item?.name);
  };
  const handleDateChange = (date) => {
    setValue({ ...value, finishDate: date });
  };

  const onClickFeeManagement = async () => {
    let error = false;
    const { coreFees, performanceFees } = feeManagement;
    const coreError = [];
    if (coreFees?.length) {
      coreFees.forEach((coreFee: Record<string, any>) => {
        const data = {
          feeName: '',
          feeType: '',
          netFee: '',
          vat: '',
          chargingFrequency: '',
          startingPeriod: '',
          finishingPeriod: '',
        };
        if (!coreFee?.feeName) {
          data.feeName = 'Please enter fee name';
          error = true;
        }
        if (!coreFee?.feeType) {
          data.feeType = 'Please select fee type';
          error = true;
        } else {
          if (coreFee?.feeType === 'RECURRING') {
            if (!coreFee?.chargingFrequency) {
              data.chargingFrequency = 'Please enter charging frequency';
              error = true;
            }
            if (!coreFee?.startingPeriod) {
              data.startingPeriod = 'Please enter starting period';
              error = true;
            } else if (coreFee?.startingPeriod === '0') {
              data.startingPeriod = 'Lowest starting period is 1';
              error = true;
            }
            if (!coreFee?.finishingPeriod) {
              data.finishingPeriod = 'Please enter finishing period';
              error = true;
            } else if (coreFee?.finishingPeriod === '0') {
              data.finishingPeriod = 'Lowest finishing period is 1';
              error = true;
            } else if (
              parseInt(coreFee?.startingPeriod) >
              parseInt(coreFee?.finishingPeriod)
            ) {
              data.finishingPeriod =
                'Please ensure the finishing period is not before the starting period.';
              error = true;
            }
          }
        }
        if (!coreFee?.netFee) {
          data.netFee = 'Please enter net fee';
          error = true;
        }
        if (!coreFee?.vat) {
          if (coreFee?.vat !== 0) {
            data.vat = 'Please select VAT';
            error = true;
          }
        }
        if (!coreFee.feeAccounting) {
          //@ts-expect-error
          data.feeAccounting = 'Please select fee accounting.';
          error = true;
        }
        //@ts-expect-error
        coreError.push(data);
      });
      feeManagementError.coreFee = coreError;
    }
    const performError = [];
    if (performanceFees?.length) {
      performanceFees.forEach((rec: Record<string, any>) => {
        const data = {
          feeName: '',
          includeFeesInHurdle: '',
          feePercentageExVAT: '',
          vatPercentage: '',
          hurdle: '',
          hurdleBasis: '',
        };
        if (!rec?.feeName) {
          data.feeName = 'Please enter fee name.';
          error = true;
        }
        if (!rec?.includeFeesInHurdle && rec?.includeFeesInHurdle !== false) {
          data.includeFeesInHurdle = 'Please select fee type.';
          error = true;
        }
        if (!rec?.hurdle && rec?.hurdle !== 0) {
          data.hurdle = 'Please enter hurdle.';
          error = true;
        } else {
          if (parseFloat(rec?.hurdle) < 0) {
            data.hurdle = 'Hurdle should be greater or equal 0';
            error = true;
          }
        }
        if (!rec?.hurdleBasis) {
          data.hurdleBasis = 'Please select hurdle basis.';
          error = true;
        }
        if (!rec?.feePercentageExVAT) {
          data.feePercentageExVAT = 'Please enter net fee.';
          error = true;
        }
        if (!rec?.vatPercentage) {
          if (rec?.vatPercentage !== 0) {
            data.vatPercentage = 'Please select VAT.';
            error = true;
          }
        }

        //@ts-expect-error
        performError.push(data);
      });
      feeManagementError.performanceFees = performError;
    }
    if (!error) {
      //@ts-expect-error
      edit.mutate(feeManagement);
      setExpanded('bankDetails');
    } else {
      setFeeManagementError({ ...feeManagementError });
    }
  };

  const removePerformanceFee = (index, type) => {
    if (type === 'coreFee') {
      feeManagement.coreFees.splice(index, 1);
      if (feeManagementError?.coreFee?.length) {
        feeManagementError.coreFee.splice(index, 1);
      }
    } else {
      feeManagement.performanceFees.splice(index, 1);
      if (feeManagementError?.performanceFees?.length) {
        feeManagementError.performanceFees.splice(index, 1);
      }
    }
    setFeeManagement({ ...feeManagement });
    setFeeManagementError({ ...feeManagementError });
  };

  const onChangeTranchePromotion = async (files, name) => {
    if (!files?.[0]?.path) return;

    try {
      const { filePath } = await uploadFile(files[0], UploadType.FundDocuments);
      tranchePromotion[name] = filePath;
      tranchePromotion[name + 'Preview'] = files[0].preview;
      setTranchePromotion({
        ...tranchePromotion,
      });
    } catch (_) {
      showError("Something went wrong - the file couldn't be uploaded");
    }
  };

  return (
    <Fragment>
      <PageContainer
        heading={id ? 'Tranche: Tranche details' : 'Add Tranche'}
        breadcrumbs={breadcrumbs}
        loading={loading}
      >
        <PageContent>
          <AccordionItem
            expanded={expanded}
            heading="Basic information"
            handleChange={handleChange}
            panelName="basicInformation"
          >
            <BasicInformation
              errorTextBasic={errorTextBasic}
              handleCancel={handleCancel}
              handleChangeTag={handleChangeTag}
              handleClick={handleClick}
              handleDateChange={handleDateChange}
              id={id}
              investment={investment}
              setValue={setValue}
              tagList={tags.data ?? []}
              tagName={tagName}
              value={value}
              disabled={edit.isPending || create.isPending}
            />
          </AccordionItem>

          <AccordionItem
            expanded={expanded}
            heading="Tranche data snapshot"
            handleChange={handleChange}
            panelName="trancheDataSnapshot"
            disabled={!id}
          >
            <TrancheDataSnapshot
              errorTextKeyData={errorTextKeyData}
              handleCancel={handleCancel}
              disabled={edit.isPending}
              handleClickKeyData={handleClickKeyData}
              id={id}
              keyData={keyData}
              setKeyData={setKeyData}
              tagName={tagName}
            />
          </AccordionItem>

          <AccordionItem
            expanded={expanded}
            heading="Tranche subscription documentation"
            handleChange={handleChange}
            panelName="trancheInvestmentDocumentation"
            disabled={!id}
          >
            <TranchePromotionInformation
              handleCancel={handleCancel}
              handleClickTranchePromotion={handleClickTranchePromotion}
              id={id}
              disabled={edit.isPending}
              onChangeTranchePromotion={onChangeTranchePromotion}
              setTranchePromotion={setTranchePromotion}
              isSyndicate={isSyndicate}
              tranchePromotion={tranchePromotion}
            />
          </AccordionItem>
          <AccordionItem
            expanded={expanded}
            heading="Fee management"
            handleChange={handleChange}
            panelName="feeManagement"
            disabled={!id}
          >
            <GridContainer>
              <Grid size={{ xs: 12 }}>
                <FeeManagement
                  classes={classes}
                  feeManagement={feeManagement}
                  onChangeCoreFee={onChangeCoreFee}
                  onChangePerformanceFees={onChangePerformanceFees}
                  setFeeManagement={setFeeManagement}
                  feeManagementError={feeManagementError}
                  removePerformanceFee={removePerformanceFee}
                  overallHurdleBasis={overallHurdleBasis}
                  overallFeesInHurdle={overallFeesInHurdle}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <div className={classes.buttonContainer}>
                  <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.marginLeftBtn}
                    disabled={edit.isPending}
                    color="primary"
                    onClick={onClickFeeManagement}
                  >
                    {id ? 'Update' : 'Save'}
                  </Button>
                </div>
              </Grid>
            </GridContainer>
          </AccordionItem>

          <AccordionItem
            expanded={expanded}
            heading="Bank details"
            handleChange={handleChange}
            panelName="bankDetails"
            disabled={!id}
          >
            <BankDetails
              bank={bank}
              bankError={bankError}
              handleCancel={handleCancel}
              handleClickBank={handleClickBank}
              id={id}
              disabled={edit.isPending}
              setBank={setBank}
              firmId={value.firmId}
            />
          </AccordionItem>
          <AccordionItem
            expanded={expanded}
            heading="Additional information"
            handleChange={handleChange}
            panelName="additionalInformation"
            disabled={!id}
          >
            <AdditionalInformation
              additionalInfo={additionalInfo}
              additionalInfoError={additionalInfoError}
              handleCancel={handleCancel}
              disabled={edit.isPending}
              handelClickAdditional={handelClickAdditional}
              handleInputChangeAdditional={handleInputChangeAdditional}
              id={id}
            />
          </AccordionItem>
          <AccordionItem
            expanded={expanded}
            heading="Confirmation email customisation"
            handleChange={handleChange}
            panelName="confirmationEmailCustomisation"
            disabled={!id}
          >
            <ConfirmationEmailCustomisation
              confirmationEmailCustomisation={confirmationEmailCustomisation}
              handleCancel={handleCancel}
              disabled={edit.isPending}
              handleClickConfirmationEmailCustomisation={
                handleClickConfirmationEmailCustomisation
              }
              handleInputChangeEmailCustomisation={
                handleInputChangeEmailCustomisation
              }
              id={id}
            />
          </AccordionItem>

          <AccordionItem
            expanded={expanded}
            heading="Final deal"
            handleChange={handleChange}
            panelName="finalDeal"
            disabled={!id}
          >
            {/* This one does not need a "disabled" as it's handled internally by this component. */}
            <FinalDealToggleAndLog
              hasKiCertficate={hasKiCertificate}
              fundId={id}
            />
          </AccordionItem>
        </PageContent>
      </PageContainer>
    </Fragment>
  );
};
export default AddUpdateTranche;
