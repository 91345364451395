import { useState, forwardRef } from 'react';
import { TextFieldProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';

interface AppDatePickerProps extends DatePickerProps<Date> {
  openOnFocus?: boolean;
  clearable?: boolean;
  inputVariant?: TextFieldProps['variant'];
  helperText?: TextFieldProps['helperText'];
  error?: TextFieldProps['error'];
  fullWidth?: TextFieldProps['fullWidth'];
  size?: TextFieldProps['size'];
  id?: TextFieldProps['id'];
  required?: boolean;
}

const useStyles = makeStyles()(() => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const AppDatePicker = forwardRef<HTMLDivElement, AppDatePickerProps>(
  (
    {
      name,
      id,
      value,
      onChange,
      helperText,
      error,
      className,
      disableFuture,
      disabled,
      inputVariant = 'outlined',
      fullWidth = true,
      size = 'small',
      //TODO: fix this
      //openOnFocus = false,
      clearable,
      required,
      ...rest
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const { classes } = useStyles();

    return (
      <div
        className={classes.flex}
        ref={ref}
        style={{ width: fullWidth ? '100%' : 'auto' }}
      >
        <DatePicker
          format="dd/MM/yyyy"
          name={name}
          disableFuture={disableFuture}
          value={value}
          onChange={onChange}
          slotProps={{
            textField: {
              variant: inputVariant,
              helperText,
              error,
              fullWidth,
              size,
              id: id || name,
              onClick: () => !disabled && setOpen(true),
              required,
              //TODO: fix this
              // onFocus: openOnFocus ? () => setOpen(true) : undefined,
            },
            actionBar: { actions: ['today', 'cancel', 'accept'] },
            field: {
              clearable,
              onClear: () => onChange?.(null, { validationError: null }),
            },
          }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          className={className}
          disabled={disabled}
          views={['year', 'month', 'day']}
          {...rest}
        />
      </div>
    );
  },
);

export default AppDatePicker;
