import { FC } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { useDisclosure } from 'further-ui/hooks';
import Table, { TableActions } from 'components/Table';
import PageContainer from 'components/PageContainer';
import { TAG_ADD, createTagEditRoute } from 'adminConstants/routes';
import { Link } from 'react-router-dom';
import AlertDialog from 'components/AlertDialog';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useTags } from 'hooks/data/tag/useTags';
import PageContent from 'components/PageContent';
import { Button } from '@mui/material';
import { styled } from '@mui/material';
import ButtonGroup from 'components/ButtonGroup';
import Spacing from 'components/Spacing';

export const FlexContainer = styled('div')({
  display: 'flex',
});

export const ActionsCell = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
});

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Fund Types', isActive: true },
];

const Tag: FC = () => {
  const [createPermission, deletePermission, updatePermission] =
    useGetPermissions(['create:tag', 'delete:tag', 'edit:tag']);

  const deleteTagDisclosure = useDisclosure<{ tagId: string }>();
  const { tags, deleteTag } = useTags();

  const columns = [
    {
      label: 'Fund Type',
      key: 'name',
    },
    {
      label: 'Actions',
      key: '',
      sort: false,
      render: (elm: { _id: string }) => (
        <ActionsCell>
          <TableActions
            actions={[
              {
                label: 'Edit',
                icon: Edit,
                color: 'primary',
                visible: updatePermission,
                link: createTagEditRoute(elm._id),
              },
              {
                label: 'Delete',
                icon: Delete,
                color: 'error',
                visible: deletePermission,
                onClick: () => handleDelete(elm._id),
              },
            ]}
          />
        </ActionsCell>
      ),
    },
  ];

  const handleDelete = (tagId: string) => {
    deleteTagDisclosure.stageAction({ tagId });
    deleteTagDisclosure.onOpen();
  };

  const handleConfirmDelete = () => {
    deleteTag.mutate(deleteTagDisclosure.actionProps.tagId);
    deleteTagDisclosure.onClose();
  };

  return (
    <PageContainer heading="Fund Types" breadcrumbs={breadcrumbs}>
      <PageContent>
        {createPermission && (
          <ButtonGroup>
            <Link to={TAG_ADD}>
              <Button color="primary" variant="contained">
                Add Fund Type
              </Button>
            </Link>
          </ButtonGroup>
        )}
        <Spacing direction="column">
          <Table
            columns={columns}
            tablebody={tags.data ?? []}
            loading={tags.isLoading}
            variant="nohover"
          />
        </Spacing>
      </PageContent>

      <AlertDialog
        open={deleteTagDisclosure.isOpen}
        onClose={deleteTagDisclosure.onClose}
        onConfirm={handleConfirmDelete}
        title={`Are you sure you wish to delete this fund type?`}
        content={
          <p>
            This cannot be undone and all information relating to the fund type
            will be lost.
          </p>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Delete fund type',
        }}
      />
    </PageContainer>
  );
};

export default Tag;
