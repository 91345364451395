import { Typography } from '@mui/material';
import Heading from 'components/Heading';
import InteractiveTable, { RowsGroup } from 'components/InteractiveTable';
import PageFilters, { Filter } from 'components/PageFilters';
import PageLoader from 'components/PageLoader';
import SearchField from 'components/SearchField';
import Spacing from 'components/Spacing';
import { useLayoutContext } from 'contexts/LayoutContext';
import useFilters from 'hooks/ui/useFilters';
import { useGetRole } from 'hooks/ui/useGetRole';
import api from 'lib/trpcClient';
import AddEditAccount from './AddEditAccount';
import { Add } from '@mui/icons-material';
import { AccountSectionLabels } from 'further-ui/labels';

import { useDisclosure } from 'further-ui/hooks';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import getColumns from './getColumns';

const pageId = 'fund-setup-chart-of-accounts';

const ChartOfAccounts: React.FC = () => {
  const { firmId } = useGetRole();
  const { selectedVehicleId } = useLayoutContext();

  const accountFormDisclosure = useDisclosure<{ accountId: string }>();
  const { filters, handleChangeFilters } = useFilters(pageId, {
    defaultFilters: {
      searchQuery: '',
    },
  });

  const mutationOpts = useHandleTrpcMutation()({
    successMessage: 'Account deleted successfully',
    invalidationHandler: (utils) => utils.account.invalidate(),
  });

  const deleteAccount = api.account.remove.useMutation(mutationOpts);

  const accounts = api.account.listAllAccounts.useQuery({
    vehicleId: selectedVehicleId ?? '',
    searchQuery: filters.searchQuery,
  });

  if (accounts.isLoading) {
    return <PageLoader />;
  }

  const groupedRows: RowsGroup = {};

  Object.keys(accounts.data ?? {}).forEach((section) => {
    groupedRows[section] = {
      title: AccountSectionLabels[section],
      rows: accounts.data?.[section] ?? [],
    };
  });

  const selectedAccount = Object.values(accounts.data ?? [])
    .flat()
    .find(
      (account) => account.id === accountFormDisclosure?.actionProps?.accountId,
    );

  const handleDeleteAccount = (id: string) => {
    deleteAccount.mutate({
      id,
      vehicleId: selectedVehicleId ?? '',
    });
  };

  return (
    <Spacing>
      <Spacing size="sm">
        <Heading variant="h3" noMargin>
          Chart of accounts
        </Heading>
        <Typography variant="body1" color="text.secondary">
          You may add or edit accounts within this table. For bulk changes, you
          may export the chart, make changes, and import it again.
        </Typography>
      </Spacing>
      <PageFilters
        pageId={pageId}
        dropdownActions={[
          {
            label: 'Add Account',
            icon: Add,
            onClick: () => {
              accountFormDisclosure.onOpen();
            },
          },
        ]}
      >
        <Filter>
          <SearchField
            autoSearchOnDebounce
            name="searchQuery"
            variant="standard"
            placeholder="Search by code or account name"
            fullWidth
            value={filters.searchQuery}
            onSearch={(searchQuery) => {
              handleChangeFilters({ searchQuery });
            }}
            allowEmptyQuery
            iconPlacement="left"
          />
        </Filter>
      </PageFilters>
      <InteractiveTable
        id={`${pageId}-table`}
        columns={getColumns({
          handleDeleteAccount,
          accountFormDisclosure,
        })}
        rows={groupedRows ?? []}
        sectionHeader="Code"
        sectionValueField="accountCode"
        loading={accounts.isFetching}
        disableTableConfiguration
      />
      {accountFormDisclosure.isOpen && (
        <AddEditAccount
          vehicleId={selectedVehicleId ?? ''}
          firmId={firmId ?? ''}
          account={selectedAccount}
          onClose={accountFormDisclosure.onClose}
        />
      )}
    </Spacing>
  );
};

export default ChartOfAccounts;
