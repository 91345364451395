export const getCurrentAppropriatenessStatus = (appropriatenessItem) => {
  if (!appropriatenessItem) return 'Not attempted';

  if (appropriatenessItem.status && appropriatenessItem.isManuallyPassedByAdmin)
    return 'Admin pass';

  if (!appropriatenessItem.status && appropriatenessItem.isExpired)
    return 'Expired';

  return appropriatenessItem.status ? 'Pass ' : 'Fail ';
};
