import { FC } from 'react';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import { makeStyles } from 'tss-react/mui';
import { useFirm } from 'hooks/data/firm/useFirm';

const useStyles = makeStyles()(() => ({
  placeholder: {
    color: '#999999',
  },
}));

type Firm = {
  _id: string;
  firmName: string;
};

interface Props {
  firmId?: string;
  onChange: (firmId: string, firm?: Firm) => void;
  placeholder?: string;
  allowDeselection?: boolean;
  deselectionLabel?: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
}

export const FirmSelector: FC<Props> = ({
  firmId,
  onChange,
  placeholder,
  allowDeselection,
  deselectionLabel = 'None',
  ...rest
}) => {
  const { firms } = useFirm({ params: {} });
  const { classes } = useStyles();

  const emptyOption = { firmId: null, firmName: deselectionLabel };

  return (
    <AppSelectBox
      required
      data={
        allowDeselection
          ? [emptyOption, ...(firms.data?.result ? firms.data.result : [])]
          : firms.data?.result ?? []
      }
      valueKey="_id"
      labelKey="firmName"
      fullWidth
      value={firmId ?? undefined}
      variant="outlined"
      disabled={firms.isLoading || firms.data?.result?.length === 0}
      renderValue={() => {
        if (firms.isLoading)
          return <span className={classes.placeholder}>Loading...</span>;
        if (firms.data?.result?.length === 0)
          return <span className={classes.placeholder}>No firms found</span>;
        if (!firmId && placeholder)
          return <span className={classes.placeholder}>{placeholder}</span>;

        return firms.data?.result?.find((firm) => firm?._id === firmId)
          ?.firmName;
      }}
      onChange={(e) => {
        const firmId = e.target.value;
        const firm = firms.data?.result?.find((firm) => firm?._id === firmId);
        onChange(firmId as string, firm);
      }}
      {...rest}
    />
  );
};
