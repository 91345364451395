import { useState, useEffect } from 'react';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { Alert, AlertTitle, styled } from '@mui/material';
import PageContainer from 'components/PageContainer';
import AlertDialog from 'components/AlertDialog';
import {
  INVESTOR_REPORTING,
  INVESTOR_REPORTING_EDIT,
  DASHBOARD_ALL_FIRMS,
} from 'adminConstants/routes';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useInvestorReporting } from 'hooks/data/investorReporting/useInvestorReporting';
import { useDisclosure } from 'further-ui/hooks';
import InvestorReportingForm from './InvestorReportingForm';
import InvestorReportingFormContextProvider from 'contexts/InvestorReportingFormContext';
import PageContent from 'components/PageContent';

const WizardPageContent = styled(PageContent)({
  overflow: 'visible',
});

const AddUpdateInvestorReport: React.FC = () => {
  const { isSuperAdmin, firmId } = useGetRole();
  const backDisclosure = useDisclosure();
  const history = useHistory();
  const { id: investorReportId } = useParams<{ id: string }>();
  const isUpdatePage = useRouteMatch(INVESTOR_REPORTING_EDIT)?.isExact;

  const [pageTitle, setPageTitle] = useState(
    'Investor reporting: Create update',
  );

  const breadcrumbs = [
    {
      label: 'Dashboard',
      //@ts-expect-error
      onClick: () => backDisclosure.stageAction(DASHBOARD_ALL_FIRMS),
    },
    {
      label: 'Investor Reporting',
      //@ts-expect-error
      onClick: () => backDisclosure.stageAction(INVESTOR_REPORTING),
    },
    {
      label: investorReportId
        ? 'Update periodic report'
        : 'Create periodic report',
      link: '/',
      isActive: true,
    },
  ];

  const { data: investorReportData, error: fetchError } =
    useInvestorReporting().fetchInvestorReport(investorReportId);

  useEffect(() => {
    if (!investorReportData) return;

    setPageTitle(
      `Investor reporting - ${
        investorReportData.investorReport?.firm?.firmName
      }: ${isUpdatePage ? 'Edit' : 'Create update'}`,
    );
  }, [investorReportData, isUpdatePage]);

  useEffect(() => {
    if (!isSuperAdmin) {
      setPageTitle(
        `Investor reporting: ${isUpdatePage ? 'Edit' : 'Create update'}`,
      );
    }
  }, [investorReportId, isSuperAdmin, isUpdatePage]);

  const handleExitForm = () => {
    //@ts-expect-error
    backDisclosure.stageAction(INVESTOR_REPORTING);
  };

  if (fetchError)
    return (
      <PageContainer heading={pageTitle} breadcrumbs={breadcrumbs}>
        <PageContent>
          <Alert severity="warning">
            <AlertTitle>Sorry</AlertTitle>
            This investor report could not be loaded. Please try again later.
          </Alert>
        </PageContent>
      </PageContainer>
    );

  return (
    <PageContainer
      heading={pageTitle}
      breadcrumbs={breadcrumbs}
      loading={!!investorReportId && !investorReportData?.investorReport}
    >
      <WizardPageContent>
        <InvestorReportingFormContextProvider
          investorReport={investorReportData?.investorReport ?? null}
          isSuperAdmin={isSuperAdmin}
          userFirmId={firmId}
        >
          <InvestorReportingForm onExitForm={handleExitForm} />
        </InvestorReportingFormContextProvider>
      </WizardPageContent>
      <AlertDialog
        onClose={backDisclosure.onClose}
        open={backDisclosure.isOpen}
        title="Are you sure you wish to go back?"
        content="Any changes made to this investor update will be lost if you go back without saving."
        btnLabels={{
          confirm: 'Keep editing',
          cancel: 'Go back',
        }}
        cancelBtnProps={{
          variant: 'outlined',
          color: 'primary',
          onClick: () => {
            history.push(backDisclosure.actionProps);
            backDisclosure.onClose();
          },
        }}
        confirmBtnProps={{ color: 'primary' }}
        onConfirm={backDisclosure.onClose}
      />
    </PageContainer>
  );
};

export default AddUpdateInvestorReport;
