import { Box, Tabs, Tab } from '@mui/material';
import { OverallPerformance } from './OverallPerformance';
import { PortfolioSnapshotGraph } from './PortfolioSnapshotGraph';
import { useState } from 'react';
import { Exits, InvestmentBalance, Shareholding } from '.';
import Heading from 'components/Heading';

type OverallPerformanceTabsProps = {
  investmentBalance: InvestmentBalance;
  combinedShareholdings: Array<Shareholding>;
  exits: Exits;
};

export const OverallPerformanceTabs = ({
  investmentBalance,
  combinedShareholdings,
  exits,
}: OverallPerformanceTabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          label={
            <Heading variant="h5" noMargin>
              Overall performance
            </Heading>
          }
        />
        <Tab
          label={
            <Heading variant="h5" noMargin>
              Portfolio snapshot
            </Heading>
          }
        />
      </Tabs>
      <Box mt={2}>
        {activeTab === 0 && (
          <OverallPerformance investmentBalance={investmentBalance} />
        )}
        {activeTab === 1 && (
          <PortfolioSnapshotGraph
            combinedShareholdings={combinedShareholdings}
            exits={exits}
          />
        )}
      </Box>
    </Box>
  );
};
