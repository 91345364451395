import { Link, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import api from 'lib/trpcClient';
import { VEHICLES } from 'adminConstants/routes';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import PageSection from 'components/PageSection';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import RecordView from 'components/RecordView';
import { vehicle as rules } from 'further-ui/validations';
import GeneralInformation from './GeneralInformation';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';

const AddEditVehicle: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const mutationOpts = useHandleTrpcMutation();

  const addVehicle = api.vehicle.create.useMutation(
    mutationOpts({
      successMessage: 'Vehicle created successfully',
      successRedirect: VEHICLES,
      invalidationHandler: (utils) => utils.vehicle.invalidate(),
    }),
  );
  const updateVehicle = api.vehicle.update.useMutation(
    mutationOpts({
      successMessage: 'Vehicle updated successfully',
      successRedirect: VEHICLES,
      invalidationHandler: (utils) => utils.vehicle.invalidate(),
    }),
  );

  const vehicleData = api.vehicle.getById.useQuery(
    { vehicleId: id ?? '' },
    { enabled: !!id },
  );

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Firms', link: '/firm' },
    {
      label: id ? 'Update Vehicle' : 'Add Vehicle',
      link: '/',
      isActive: true,
    },
  ];

  const formMethods = useForm({
    shouldFocusError: true,
    resolver: zodResolver(rules.Create),
    values: vehicleData.data,
    criteriaMode: 'all',
  });

  const onSubmit = async (data) => {
    if (id) {
      updateVehicle.mutate({ ...data, vehicleId: id });
    } else {
      addVehicle.mutate({ ...data });
    }
  };

  return (
    <PageContainer
      heading={id ? 'Vehicle: Details' : 'Add Vehicle'}
      loading={!!id && vehicleData.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <PageContent>
        <FormProvider {...formMethods}>
          <form>
            <RecordView size="full">
              <PageSection title="General information">
                <GeneralInformation />
              </PageSection>

              <ButtonGroup>
                <Link to={VEHICLES}>
                  <Button variant="outlined">Cancel</Button>
                </Link>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={formMethods.handleSubmit(onSubmit)}
                  loading={addVehicle.isPending || updateVehicle.isPending}
                >
                  Save
                </Button>
              </ButtonGroup>
            </RecordView>
          </form>
        </FormProvider>
      </PageContent>
    </PageContainer>
  );
};

export default AddEditVehicle;
