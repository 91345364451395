import PageContainer from 'components/PageContainer';
import Table from 'components/Table';
import { startCase } from 'lodash';
import {
  useCollectionHistoryEvents,
  useGetCollectionNames,
} from 'hooks/data/collectionHistory/useCollectionHistory';
import { usePagination } from 'hooks/ui/usePagination';
import { dateToLabel } from 'further-ui/utils';
import { FC } from 'react';
import { CollectionHistoryEventType } from 'further-types/collection-history';
import useFilters from 'hooks/ui/useFilters';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import { MenuOpen, Subject } from '@mui/icons-material';
import { useDisclosure } from 'further-ui/hooks';
import Filters from './ChangeLogFilters';
import { Api } from 'further-types/collection-history';
import PageContent from 'components/PageContent';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Change log', isActive: true },
];
const eventTypeToLabelMap: Record<CollectionHistoryEventType, string> = {
  [CollectionHistoryEventType.Edited]: 'Edited',
  [CollectionHistoryEventType.Created]: 'Created',
  [CollectionHistoryEventType.Deleted]: 'Deleted',
  [CollectionHistoryEventType.SoftDeleted]: 'Soft deleted',
  [CollectionHistoryEventType.Restored]: 'Restored',
};

type Filters = {
  collection?: string;
  eventType?: CollectionHistoryEventType;
  docId?: string;
};

const getChangedByLabel = (
  changedBy: Api.CollectionHistoryEventItem['changedBy'],
) => {
  if (!changedBy) return '-';
  const identifier = changedBy.email ?? 'deleted user';
  return `${identifier} (${startCase(changedBy.userType)})`;
};

const ChangeLog: FC = () => {
  const additionalInfoDisclosure = useDisclosure();
  const pagination = usePagination({
    id: 'change-log',
  });
  const { filters, handleChangeFilters } = useFilters<Filters>('change-log', {
    onFiltersChange: pagination.toFirstPage,
  });
  const { data: collectionNames } = useGetCollectionNames();
  const { data: historyEvents, isFetching } = useCollectionHistoryEvents({
    //@ts-expect-error
    collection: filters.collection,
    eventType: filters.eventType,
    docId: filters.docId,
    page: pagination.page,
    pageSize: pagination.rowsPerPage,
  });

  const columns = [
    {
      label: 'Date',
      sort: false,
      key: 'date',
      render: (elm: Api.CollectionHistoryEventItem) =>
        dateToLabel(elm.date, undefined, true),
    },
    {
      label: 'ID',
      sort: false,
      key: 'docId',
      render: (elm: Api.CollectionHistoryEventItem) => (
        <>
          <p>{elm.docId.toString()}</p>
          {elm.secondaryId
            ? `(linked with ${elm.secondaryId?.toString()})`
            : ''}
        </>
      ),
    },
    {
      label: 'Event type',
      sort: false,
      key: 'eventType',
      render: (elm: Api.CollectionHistoryEventItem) =>
        eventTypeToLabelMap[elm.eventType],
    },
    {
      ...((filters.eventType === CollectionHistoryEventType.Edited ||
        !filters.eventType) && {
        label: 'Updated fields',
        sort: false,
        key: 'updatedFields',
        render: (elm: Api.CollectionHistoryEventItem) =>
          Object.entries(elm.updatedFields ?? {}).map(([key, value]) => (
            <p key={key}>
              <strong>{startCase(key)}:</strong>{' '}
              {typeof value === 'string' ? value : JSON.stringify(value)}
            </p>
          )),
      }),
    },
    {
      label: 'Changed by',
      key: 'changedBy',
      sort: false,
      render: (elm: Api.CollectionHistoryEventItem) =>
        getChangedByLabel(elm.changedBy),
    },
    {
      label: 'Full document',
      sort: false,
      key: 'actions',
      render: (elm: Api.CollectionHistoryEventItem) =>
        elm.eventType === CollectionHistoryEventType.Edited ? (
          <>
            <Tooltip arrow title="Before change">
              <IconButton
                onClick={() =>
                  additionalInfoDisclosure.stageAction({
                    content: elm.fullDocumentBeforeChange,
                  })
                }
                size="large"
              >
                <MenuOpen />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="After change">
              <IconButton
                onClick={() =>
                  additionalInfoDisclosure.stageAction({
                    content: elm.fullDocument,
                  })
                }
                size="large"
              >
                <Subject />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <IconButton
            onClick={() =>
              additionalInfoDisclosure.stageAction({
                content: elm.fullDocument,
              })
            }
            size="large"
          >
            <Subject />
          </IconButton>
        ),
    },
  ];

  return (
    <PageContainer heading="Change log" breadcrumbs={breadcrumbs}>
      <PageContent>
        <Filters
          filters={filters}
          handleChangeFilters={handleChangeFilters}
          //@ts-expect-error
          collectionNames={collectionNames}
          eventTypeToLabelMap={eventTypeToLabelMap}
        />
        <Table
          //@ts-expect-error
          columns={columns}
          tablebody={historyEvents?.results ?? []}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          pagination={true}
          count={historyEvents?.total ?? 0}
          loading={isFetching}
          variant="nohover"
        />
        <Dialog
          open={additionalInfoDisclosure.isOpen}
          onClose={additionalInfoDisclosure.onClose}
          aria-labelledby="additional-info-dialog"
          maxWidth={'xl'}
        >
          <DialogContent>
            <pre>
              {JSON.stringify(
                additionalInfoDisclosure.actionProps?.content,
                null,
                2,
              )}
            </pre>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={additionalInfoDisclosure.onClose}
              variant="outlined"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </PageContent>
    </PageContainer>
  );
};

export default ChangeLog;
