import { api } from 'lib/httpClient';
import { useQuery } from '@tanstack/react-query';
import { Api } from 'further-types/investor-reporting';
import { ApiResponse } from 'further-types/api';

async function fetchInvestee({ queryKey }) {
  try {
    const [, params] = queryKey;
    const {
      data: { data },
    } = await api.get<ApiResponse<Api.GetInvesteeListResponse>>(
      `investor-reporting/investee`,
      { params },
    );
    return data;
  } catch (error) {
    throw error;
  }
}

export function useReportingInvestee({ firmId, submitDate, enabled }) {
  return useQuery({
    queryKey: ['reporting-investee', { firmId, submitDate }],
    queryFn: fetchInvestee,
    enabled,
    placeholderData: undefined,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
}
