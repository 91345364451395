import React from 'react';
import FieldRow from 'components/FormElements/FieldRow';
import { Box, Avatar } from '@mui/material';

type Props = any; //TODO: define props

const CompanyCard: React.FC<Props> = ({ company }) => {
  return (
    <FieldRow
      title="Company tile preview"
      informationText="This tile will show in the middle of your email, as previewed here."
    >
      <Box display="flex" gap={10}>
        <Avatar src={company?.companyLogo} />
        <div>
          <b>{company?.tradingName || '-'}</b>
          <div className="text-component text-editor">
            <p
              dangerouslySetInnerHTML={{
                __html: company?.description,
              }}
            />
          </div>
        </div>
      </Box>
    </FieldRow>
  );
};

export default CompanyCard;
