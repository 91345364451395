import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PageContainer from 'components/PageContainer';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AddDeploymentUpdateDetails from './AddDetail';
import Summary from '../Common/Summary';
import { INVESTOR_REPORTING } from 'adminConstants/routes';
import { useDeploymentUpdate } from 'hooks/data/deploymentUpdate/useDeploymentUpdate';
import useCompany from 'hooks/data/company/useCompany';
import PageContent from 'components/PageContent';
import ButtonGroup from 'components/ButtonGroup';
import Spacing from 'components/Spacing';

const useStyles = makeStyles()(() => ({
  cardContentPadding: {
    padding: '2rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  buttonLeftMargin: {
    marginLeft: 12,
  },
  errorCk: {
    color: '#f44336',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: ' Deployment update', link: '/', isActive: true },
];

const DeploymentUpdate: React.FC = () => {
  const history = useHistory();
  const { classes } = useStyles();
  const heading = 'Investor reporting: Deployment update';

  const { shareAllocationUploadId, companyId } = useParams<{
    shareAllocationUploadId: string;
    companyId: string;
  }>();
  const steps = { addDeploymentUpdateDetails: 1, summary: 2 };
  const [activeStep, setActiveStep] = useState(
    steps.addDeploymentUpdateDetails,
  );
  const [data, setData] = useState({ shareAllocationUploadId });
  const [errorText, setErrorText] = useState<Record<string, string>>();
  const deploymentUpdate = useDeploymentUpdate();
  const { data: company } = useCompany(companyId);

  const validate = () => {
    const errorMessage: Record<string, string> = {};
    //@ts-expect-error
    if (!data.subject) {
      errorMessage.subject = 'Please enter an email subject.';
    }
    //@ts-expect-error
    if (!data.title) {
      errorMessage.title = 'Please enter an email title.';
    }

    //@ts-expect-error
    if (!data.emailTopParagraph) {
      errorMessage.emailTopParagraph = 'Please enter an email top paragraph.';
    }

    //@ts-expect-error
    if (!data.emailBottomParagraph) {
      errorMessage.emailBottomParagraph =
        'Please enter an email bottom paragraph.';
    }
    setErrorText(errorMessage);
    return Object.keys(errorMessage).length;
  };

  const onClick = async () => {
    if (steps.addDeploymentUpdateDetails === activeStep) {
      if (!validate()) {
        setActiveStep(steps.summary);
      }
    } else {
      deploymentUpdate.create.mutate(data);
    }
  };

  useEffect(() => {
    if (deploymentUpdate.create.isSuccess) {
      history.push(INVESTOR_REPORTING);
    }
  }, [deploymentUpdate.create.isSuccess]);

  const pageTitle =
    activeStep === steps.addDeploymentUpdateDetails
      ? 'Deployment update details'
      : 'Deployment update summary';

  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <PageContent header={pageTitle}>
        <Spacing>
          {steps.addDeploymentUpdateDetails === activeStep ? (
            <AddDeploymentUpdateDetails
              errorText={errorText}
              classes={classes}
              data={data}
              setData={setData}
              company={company ?? {}}
            />
          ) : (
            <Summary data={data} company={company ?? {}} />
          )}
          <ButtonGroup>
            {activeStep === steps.summary && (
              <Button
                variant="outlined"
                onClick={() => {
                  setActiveStep(steps.addDeploymentUpdateDetails);
                }}
              >
                Back
              </Button>
            )}
            <Button
              className={classes.buttonLeftMargin}
              color="primary"
              variant="contained"
              onClick={onClick}
              disabled={deploymentUpdate.create.isPending}
            >
              {activeStep === steps.addDeploymentUpdateDetails
                ? 'Proceed to Summary'
                : 'Submit'}
            </Button>
          </ButtonGroup>
        </Spacing>
      </PageContent>
    </PageContainer>
  );
};
export default DeploymentUpdate;
