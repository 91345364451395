import { Column } from 'components/InteractiveTable';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { ApiResponses } from 'lib/trpcClient';

type Transaction =
  ApiResponses['generalLedger']['listTransactions']['sections'][string]['transactions'][number];

type TransactionColumn = Column & {
  field: keyof Transaction;
};

const getColumns = ({
  sectionTotal,
}: {
  sectionTotal: number;
}): Array<TransactionColumn> => [
  {
    field: 'transactionCurrency',
    headerName: 'Currency (TCY)',
    width: 150,
  },
  {
    field: 'transactionDate',
    headerName: 'Date',
    renderCell: (cell) => {
      return dateToLabel(cell.value);
    },
  },
  {
    field: 'transactionType',
    headerName: 'Transaction type',
    width: 150,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 150,
  },
  {
    field: 'amountIncVat',
    headerName: 'Amount (LCY)',
    width: 150,
  },
  {
    field: 'amountExVat',
    headerName: 'Amount (TCY)',
    width: 150,
    summary: numberToCurrencyString(sectionTotal),
  },
  {
    field: 'counterpartyLabel',
    headerName: 'Counterparty',
  },
  {
    field: 'bankAccountLabel',
    headerName: 'Bank account',
  },
  {
    field: 'ledgerComment',
    headerName: 'Action comment',
    width: 150,
  },
];

export default getColumns;
