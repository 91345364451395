import React, { forwardRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Delete, Attachment } from '@mui/icons-material';
import { Alert } from '@mui/material';

import { Uploads } from 'adminConstants/files';

import useStyles from './styles';

const defaultAcceptedFiles = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/svg+xml': ['.svg'],
};

export interface UploadedFile extends File {
  preview: string;
  path?: string;
}

type Props = {
  name: string;
  files: Array<{
    fileName: string;
    preview: string;
  }>;
  onDropFile: (files: Array<UploadedFile>) => void;
  onRemove: (index: number) => void;
  accept?: Record<string, string[]>;
  maxFiles?: number;
  disabled?: boolean;
  maxFileSize?: number;
};

const Dropzone: React.FC<Props> = forwardRef(
  (
    {
      files,
      onDropFile,
      name,
      accept = defaultAcceptedFiles,
      onRemove,
      maxFiles = 1,
      disabled,
      maxFileSize = Uploads.maxFileSize,
    },
    ref,
  ) => {
    const { classes, cx } = useStyles({});

    const onDrop = (acceptedFiles: File[]) => {
      onDropFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    };

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
      fileRejections,
    } = useDropzone({
      onDrop,
      accept,
      maxFiles,
      disabled,
      maxSize: maxFileSize,
    });

    const rootClasses = cx({
      [classes.root]: true,
      [classes.active]: isDragActive,
      [classes.accept]: isDragAccept,
      [classes.reject]: isDragReject,
    });

    const onlyImagesAccepted = Object.keys(accept).every((a) =>
      a.startsWith('image/'),
    );

    const thumbs = files.map((file, index) => (
      <div key={index}>
        {accept && !onlyImagesAccepted ? (
          <div className={classes.fileContent}>
            <div className={classes.fileName}>
              <Attachment className={classes.attachmentIcon} />{' '}
              <div
                className={classes.ellipsis}
                onClick={() => file.preview && window.open(file.preview)}
                role="button"
                tabIndex={0}
                onKeyUp={() => {}}
              >
                {
                  file?.fileName?.split('/')[
                    file?.fileName?.split('/')?.length - 1
                  ]
                }
              </div>
              <div>
                <Delete
                  onClick={() => onRemove(index)}
                  className={classes.removeIcon}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.thumbParent}>
            <div className={classes.thumb}>
              <div className={classes.thumbInner}>
                <img
                  src={file.preview}
                  className={classes.previewImage}
                  alt={'Preview of the file just uploaded'}
                />
              </div>
              <div className={classes.trashIcon}>
                <Delete onClick={() => onRemove(index)} />
              </div>
            </div>
          </div>
        )}
      </div>
    ));

    return (
      //@ts-expect-error
      <section ref={ref}>
        <div {...getRootProps({})} className={rootClasses}>
          <input {...getInputProps()} name={name} />
          <span className={classes.dropzoneInstructions}>
            Drag and drop a file here or click to search
          </span>
        </div>
        {fileRejections?.length ? (
          <Alert severity="error" className={classes.fileErrors}>
            {fileRejections.map((rejectedFile) =>
              rejectedFile.errors.map((error) => error.message).join(', '),
            )}
          </Alert>
        ) : null}
        {files?.length ? (
          <aside className={!onlyImagesAccepted ? '' : classes.thumbsContainer}>
            {thumbs}
          </aside>
        ) : (
          ''
        )}
      </section>
    );
  },
);

export default Dropzone;
