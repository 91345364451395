import { Card, CardContent, styled, Typography } from '@mui/material';
import Spacing from 'components/Spacing';
import React from 'react';

const Content = styled(Card)<{ fullheight?: boolean }>(({ fullheight }) => ({
  position: 'relative',
  boxShadow: '2px 20px 40px #EBEBEC',
  height: fullheight ? '100%' : 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const InnerContent = styled(CardContent)({
  padding: '24px',
  position: 'relative',
});

const ContentHeader = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  letterSpacing: 0,
  margin: '0rem 0 1.5rem 0',
}));

type Props = {
  children: React.ReactNode;
  header?: React.ReactNode;
  fullHeight?: boolean;
  className?: string;
  withSpacing?: boolean;
};

const PageContent: React.FC<Props> = ({
  children,
  header,
  fullHeight,
  className,
  withSpacing,
}) => {
  const Wrapper = withSpacing ? Spacing : React.Fragment;
  return (
    <Content fullheight={fullHeight} className={className}>
      <InnerContent>
        <Wrapper>
          {!!header && <ContentHeader>{header}</ContentHeader>}
          {children}
        </Wrapper>
      </InnerContent>
    </Content>
  );
};

export default PageContent;
