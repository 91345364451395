import AppSelectBox from 'components/FormElements/AppSelectBox';
import AppTextInput from 'components/FormElements/AppTextInput';
import { FirmSelector } from 'components/FirmSelector';
import { useGetRole } from 'hooks/ui/useGetRole';
import { Status } from 'further-types/eis-wizard';
import { Filter } from 'components/PageFilters';

type Props = any; // TODO: define types

const ListCertificateFilters: React.FC<Props> = ({
  filters,
  search,
  setSearch,
  handleChangeFilters,
}) => {
  const { isSuperAdmin } = useGetRole();

  const statusOptions = [
    { label: 'All', value: 'all' },
    { label: 'Completed', value: Status.Complete },
    { label: 'Not Completed', value: Status.NotComplete },
    { label: 'N/A', value: Status.NA },
  ];

  return (
    <>
      <Filter>
        <AppTextInput
          placeholder="Search by company or share class"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Filter>
      <Filter>
        <AppSelectBox
          label="Select status"
          value={filters.status}
          data={statusOptions}
          valueKey="value"
          labelKey="label"
          displayEmpty={false}
          onChange={(event) =>
            handleChangeFilters({ status: event.target.value })
          }
        />
      </Filter>
      {isSuperAdmin && (
        <Filter>
          <FirmSelector
            firmId={filters.firmId}
            onChange={(firmId) => handleChangeFilters({ firmId })}
            placeholder="Select firm"
          />
        </Filter>
      )}
    </>
  );
};

export default ListCertificateFilters;
