import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.5rem',
    justifyContent: 'flex-start',
  },
  inputChanged: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#88B8FF',
    },
  },
}));
