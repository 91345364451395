import React, { useState } from 'react';
import {
  Paper as MuiPaper,
  Box as MuiBox,
  List,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  styled,
  CircularProgress,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';

import GetAppIcon from '@mui/icons-material/GetApp';

import { DropdownArrow, SpreadsheetIcon } from 'components/SVGIcons';
import CloseIcon from '@mui/icons-material/Close';
import { BackgroundExportStatus } from 'further-types/background-exports';
import { BackgroundExportTypeLabels } from 'further-ui/labels';
import Heading from 'components/Heading';

const Box = styled(MuiBox)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  right: '3rem',
  zIndex: 2000,
  overflow: 'hidden',
  border: `2px solid ${theme.palette.borderColor?.main}`,
  borderRadius: '1rem 1rem 0 0',
  borderBottom: 'none',
  maxHeight: 'calc(100vh - 2rem)',
  overflowY: 'auto',
  width: '440px',
  [theme.breakpoints.down('sm')]: {
    right: '1rem',
    width: 'unset',
    maxWidth: 'calc(100vw - 2rem)',
  },
}));

const Paper = styled(MuiPaper)({
  borderRadius: 0,
});

const Spinner = styled(CircularProgress)({
  color: 'rgba(0, 0, 0, 0.26)',
  marginRight: '1px',
});

const MyDownloadsRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `2px solid ${theme.palette.borderColor.main}`,
  cursor: 'pointer',
  padding: '1.125rem',
  minWidth: '340px',
  [theme.breakpoints.down('sm')]: {
    minWidth: 'unset',
  },
}));

interface ListItemProps {
  isCompleted: boolean;
  disabled: boolean;
}

interface ListItemTextProps {
  isCompleted: boolean;
}

interface RotatingDivProps {
  isExpanded: boolean;
}

const ListItem = styled(MuiListItem, {
  shouldForwardProp: (prop: PropertyKey) =>
    !['isCompleted'].includes(prop as string),
})<ListItemProps>(({ theme, isCompleted, disabled }) => ({
  padding: '0.75rem',
  borderBottom: `2px solid ${theme.palette.borderColor?.main}`,
  cursor: disabled ? 'default' : isCompleted ? 'pointer' : 'default',
  '&:hover': {
    backgroundColor: disabled
      ? 'inherit'
      : isCompleted
      ? theme.palette.action.hover
      : 'inherit',
  },
}));

const ListItemIcon = styled(MuiListItemIcon)({
  minWidth: '20px',
  marginRight: '0.75rem',
});

const ListItemText = styled(MuiListItemText, {
  shouldForwardProp: (prop: PropertyKey) =>
    !['isCompleted'].includes(prop as string),
})<ListItemTextProps>(({ theme, isCompleted }) => ({
  marginRight: '4rem',
  color: isCompleted ? 'inherit' : theme.palette.grey[400],
  lineHeight: '24px',
}));

const RotatingDiv = styled('div')<RotatingDivProps>(({ isExpanded }) => ({
  transform: !isExpanded ? 'rotate(180deg)' : 'none',
}));

const DownloadIcon = styled(GetAppIcon)({
  fontSize: '24px',
});

const CloseIconButton = styled(CloseIcon)(({ theme }) => ({
  fontSize: '24px',
  padding: '2px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderRadius: '50%',
  },
}));

const StyledHeading = styled(Heading)({
  paddingTop: '4px',
  paddingRight: '1rem',
});

const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const DropdownArrowButton = styled(DropdownArrow)(({ theme }) => ({
  fontSize: '24px',
  padding: '2px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderRadius: '50%',
  },
}));

type Params = {
  backgroundExports: Array<{
    status?: string;
    _id?: string;
    exportType?: string;
    downloaded?: boolean;
    createdAt?: Date;
  }>;
  downloadBackgroundExport: (exportId: string) => void;
};

const DownloadTray: React.FC<Params> = ({
  backgroundExports,
  downloadBackgroundExport,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [downloadClicked, setDownloadClicked] = useState<
    Record<string, boolean>
  >({});
  const [hidden, setHidden] = useState(false);
  const theme = useTheme();

  const allDownloadsCompleted = backgroundExports.every(
    (exportItem) => exportItem.downloaded || downloadClicked[exportItem._id!],
  );

  if (!allDownloadsCompleted && hidden) setHidden(false);

  if (hidden) return null;

  return (
    <Box>
      <Paper elevation={3}>
        <MyDownloadsRow onClick={() => setIsExpanded(!isExpanded)}>
          <StyledHeading noMargin variant="h3">
            My Downloads
          </StyledHeading>

          <ActionsContainer>
            {allDownloadsCompleted && (
              <CloseIconButton
                aria-label="close"
                onClick={() => setHidden(true)}
                color="secondary"
              />
            )}
            <RotatingDiv isExpanded={isExpanded}>
              <DropdownArrowButton color="primary" />
            </RotatingDiv>
          </ActionsContainer>
        </MyDownloadsRow>

        {isExpanded && (
          <List disablePadding>
            {backgroundExports.map((item) => {
              const isCompleted =
                item.status === BackgroundExportStatus.Completed;
              return (
                <ListItem
                  isCompleted={isCompleted}
                  key={item._id}
                  onClick={async () => {
                    if (isCompleted && item._id) {
                      setDownloadClicked((prev) => ({
                        ...prev,
                        [item._id!]: true,
                      }));
                      downloadBackgroundExport(item._id);
                    }
                  }}
                  disabled={!isCompleted}
                >
                  <ListItemIcon>
                    <SpreadsheetIcon
                      size={22}
                      fill={
                        isCompleted
                          ? theme.palette.common.brandColor
                          : theme.palette.grey[300]
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    isCompleted={isCompleted}
                    primary={
                      item.exportType && item.createdAt
                        ? `${
                            BackgroundExportTypeLabels[item.exportType]
                          } - ${format(item.createdAt, 'HH:mm')}`
                        : ''
                    }
                    primaryTypographyProps={{
                      paddingBottom: '0',
                      paddingTop: '4px',
                      fontSize: '16px',
                      color: isCompleted
                        ? theme.palette.grey[900]
                        : theme.palette.grey[400],
                    }}
                  />

                  {isCompleted ? (
                    <DownloadIcon
                      color={
                        !item.downloaded && !downloadClicked[item._id!]
                          ? 'primary'
                          : 'disabled'
                      }
                    />
                  ) : (
                    <Spinner size={20} />
                  )}
                </ListItem>
              );
            })}
          </List>
        )}
      </Paper>
    </Box>
  );
};

export default DownloadTray;
