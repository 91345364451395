import useCurrentUser from 'hooks/data/currentUser/useCurrentUser';
import { useGetRole } from 'hooks/ui/useGetRole';

export const useGetAllPermissionsList = () => {
  const { profile } = useCurrentUser();
  return profile?.data?.permissions;
};

export const useGetPermissions = (
  permissionsSet: Array<string>,
): Array<boolean> => {
  const { profile } = useCurrentUser();
  const { isSuperAdmin } = useGetRole();

  const permissionData = profile?.data?.permissions;

  return permissionsSet.map((permissionName) => {
    if (isSuperAdmin) return true;

    return !!permissionData?.some(
      (permission) => permission === permissionName,
    );
  });
};
