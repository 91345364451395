import { FC } from 'react';

const NoResponse: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="speaker_notes_off_24px">
        <path
          id="icon/action/speaker_notes_off_24px"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1 2.27L2.27 1L23 21.73L21.73 23L16 17.27H7L3 21.27L3.01 4.28L1 2.27ZM7 13.27H9V11.27H7V13.27ZM7 10.27V8.27L9 10.27H7ZM5.08 1.27H23V17.26H21.07L14.08 10.27H19V8.27H12.08L11.08 7.27H19V5.27H11V7.19L5.08 1.27Z"
          fill="black"
          fill-opacity="0.38"
        />
      </g>
    </svg>
  );
};
export default NoResponse;
