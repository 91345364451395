import { useLayoutContext } from 'contexts/LayoutContext';
import api from 'lib/trpcClient';
import Table from 'components/Table';

const columns = [
  {
    key: 'firstName',
    label: 'First name',
  },
  {
    key: 'lastName',
    label: 'Last name',
  },
  {
    key: 'email',
    label: 'Email',
  },
];

const UserManagement: React.FC = () => {
  const { selectedVehicleId } = useLayoutContext();

  const vehicleData = api.vehicle.usersInVehicle.useQuery({
    vehicleId: selectedVehicleId ?? '',
  });

  return (
    <Table
      tablebody={vehicleData.data ?? []}
      columns={columns}
      loading={vehicleData.isFetching}
    />
  );
};

export default UserManagement;
