import { NavLink } from 'react-router-dom';
import useLogout from 'hooks/ui/useLogout';
import { styled } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { SidebarMenuItemType } from 'hooks/ui/useSidebarMenuItems';

const LinkWrapper = styled('div')({
  paddingRight: '16px',
  position: 'relative',
  width: '100%',
});

const ItemText = styled('span')({
  flex: 1,
  fontSize: 14,
  paddingTop: '5px',
  fontWeight: 600,
});

const CollapseIcon = styled('svg')({
  position: 'absolute',
  left: 8,
  top: '50%',
  zIndex: 1,
  transform: 'translateY(-50%)',
  fontSize: 16,
});

const MenuLabel = styled('div')(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  padding: '9px 16px 9px 32px',
  borderTopRightRadius: '30px',
  borderBottomRightRadius: '30px',
  color: theme.palette.sidebar.textColor,
  '& svg': {
    marginRight: '16px',
    fontSize: 20,
  },
  '&.active, &:hover, &:focus': {
    color: theme.palette.text.rootColor,
    backgroundColor: theme.palette.sidebar.navHoverBgColor,
    '& svg': {
      color: theme.palette.text.rootColor,
    },
  },
}));

type Props = SidebarMenuItemType & {
  isCollapsible?: boolean;
  isOpen?: boolean;
};

const Link: React.FC<Props> = ({
  name,
  icon,
  link,
  isLogout,
  onClick,
  isCollapsible,
  isOpen,
}) => {
  const { handleLogout } = useLogout();

  const handleClick = isLogout ? handleLogout : !link ? onClick : undefined;

  return (
    <LinkWrapper>
      <MenuLabel as={link ? NavLink : 'div'} to={link} onClick={handleClick}>
        {isCollapsible && (
          <CollapseIcon as={isOpen ? ExpandLess : ExpandMore} />
        )}
        {icon}
        <ItemText>{name}</ItemText>
      </MenuLabel>
    </LinkWrapper>
  );
};

export default Link;
