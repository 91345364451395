import { CloudDownload } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { useDownloadPdfUpdate } from 'hooks/data/investorReporting/useDownloadPdfUpdate';

type Props = {
  reportingId: string;
  investorId: string;
  filename: string;
};

export const DownloadPdfUpdateButton: React.FC<Props> = ({
  reportingId,
  investorId,
  filename,
}) => {
  const { loading, download } = useDownloadPdfUpdate({
    reportingId,
    investorId,
    filename,
  });

  return (
    <IconButton onClick={download} disabled={loading} size="large">
      {loading ? <CircularProgress size={24} /> : <CloudDownload />}
    </IconButton>
  );
};
