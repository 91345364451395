import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PageContainer from 'components/PageContainer';
import { Button } from '@mui/material';
import AddExitDetails, { FormData } from './AddDetail';
import { INVESTOR_REPORTING } from 'adminConstants/routes';
import Summary from '../Common/Summary';
import { useReportingAccountManagers } from 'hooks/data/investorReporting/useReportingAccountManagers';
import useExitStatement from 'hooks/data/exit/useExitStatement';
import { useExit } from 'hooks/data/exit';
import useStyles from '../Common/styles';
import CreateConfirmationDialog from '../Common/CreateConfirmationDialog';
import { useDisclosure } from 'further-ui/hooks';
import PageContent from 'components/PageContent';
import ButtonGroup from 'components/ButtonGroup';
import RecordView from 'components/RecordView';

type Params = {
  id: string;
  exitAmendmentId?: string;
};

const CreateExitStatement = () => {
  const history = useHistory();
  const { classes } = useStyles();
  const heading = 'Investor communications: Exit statement';
  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    { label: 'Exit statement', link: '/', isActive: true },
  ];

  const confirmationDisclosure = useDisclosure();

  const { id, exitAmendmentId } = useParams<Params>();

  const steps = { addExitStatementDetails: 1, summary: 2 };
  const [activeStep, setActiveStep] = useState(steps.addExitStatementDetails);

  const { exit } = useExit({ params: { id } });

  const { data: managers } = useReportingAccountManagers(
    exit.data?.companyId?.firmId,
  );

  const { createExitStatement } = useExitStatement({
    exitId: id,
    exitAmendmentId,
  });

  const [formValues, setFormValues] = useState<
    FormData & { notifyInvestors?: boolean }
  >({
    title: '',
    summary: '',
    letter: '',
    accountManager: { name: '', _id: '' },
    role: '',
    notes: '',
    notifyInvestors: false,
  });

  const handleNext = () => {
    setActiveStep((currentStep) => currentStep + 1);
  };

  const handleSubmitDetails = (values: FormData) => {
    setFormValues(values);
    handleNext();
  };

  const handleSubmit = () => {
    if (!formValues) return;
    confirmationDisclosure.onClose();
    createExitStatement.mutate(formValues);
  };

  useEffect(() => {
    if (createExitStatement.isSuccess) {
      history.push(INVESTOR_REPORTING);
    }
  }, [createExitStatement.isSuccess]);

  const pageTitle =
    activeStep === steps.addExitStatementDetails
      ? 'Exit statement details'
      : 'Exit statement summary';

  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <PageContent header={pageTitle}>
        <RecordView>
          {steps.addExitStatementDetails === activeStep ? (
            <AddExitDetails
              managers={managers}
              onNext={handleSubmitDetails}
              defaultValues={formValues}
            />
          ) : (
            <Summary
              title={formValues.title}
              summary={formValues.summary}
              letter={formValues.letter}
              accountManager={formValues.accountManager?.name}
              role={formValues.role}
              notes={formValues.notes}
            />
          )}
          {activeStep === steps.summary && (
            <ButtonGroup>
              <Button
                variant="outlined"
                onClick={() => {
                  setActiveStep(steps.addExitStatementDetails);
                }}
              >
                Back
              </Button>

              <Button
                className={classes.buttonLeftMargin}
                color="primary"
                variant="contained"
                disabled={createExitStatement.isPending}
                onClick={confirmationDisclosure.onOpen}
              >
                Submit
              </Button>
            </ButtonGroup>
          )}
        </RecordView>
      </PageContent>

      <CreateConfirmationDialog
        disclosure={confirmationDisclosure}
        notifyInvestors={formValues.notifyInvestors}
        setNotifyInvestors={(value) =>
          setFormValues({ ...formValues, notifyInvestors: value })
        }
        isEdit={false}
        onConfirmCreateStatement={handleSubmit}
        submitting={createExitStatement.isPending}
      />
    </PageContainer>
  );
};
export default CreateExitStatement;
