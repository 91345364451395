import React, { useCallback, useMemo, useState } from 'react';
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  GridProSlotsComponent,
  GridRenderCellParams,
  GridRow,
  GridRowParams,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { PaginationState } from 'further-types/utils';
import { styled } from '@mui/material';
import {
  collapseIconClass,
  columnVisibilityModelKey,
  defaultColumnVisibilityModel,
  expandIconClass,
  getColumns,
  getDetailPanelHeight,
  getRowHeight,
  getRowsToRender,
  getSavedModel,
  getSortModel,
  getTreeDataPath,
  handleRowHover,
  handleRowMouseLeave,
  pagSizeOptions,
  rowHeight,
  saveModel,
  sectionCellClass,
  sectionSummaryCellClass,
  slotProps,
  summaryCellClass,
} from './helpers';
import SectionCell from './SectionCell';
import ColumnMenu from './ColumnMenu';

const ExpandedRow = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

const ExpandIcon = styled(KeyboardArrowDown)(({ theme }) => ({
  position: 'absolute',
  right: '20px',
  backgroundColor: theme.palette.common.white,
  display: 'none',
}));

const TableContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '500px',
});

export interface Row extends GridValidRowModel {
  id?: string;
  isGroup?: boolean;
  isSummary?: boolean;
  isGroupSummary?: boolean;
  isExpandable?: boolean;
  isExpanded?: boolean;
}

export type RowsGroup = {
  [key: string]: {
    title: string;
    rows: Array<Row>;
    summary?: Record<string, React.ReactNode>;
  };
};

export interface Column extends Omit<GridColDef<Row>, 'cellClassName'> {
  summary?: React.ReactNode;
}

const tableSlots: Partial<GridProSlotsComponent> = {
  columnMenu: ColumnMenu,
  row: (props) => (
    <GridRow
      {...props}
      onMouseEnter={(event) => handleRowHover(props.row, event)}
      onMouseLeave={(event) => handleRowMouseLeave(event)}
    />
  ),
  detailPanelCollapseIcon: () => null,
  detailPanelExpandIcon: () => null,
};

const getGroupingColDef = (
  sectionHeader?: string,
  sectionValueField?: keyof Row,
  columnsCount = 0,
) => ({
  headerName: sectionHeader,
  flex: 1,
  renderCell: (params: GridRenderCellParams<Row>) => {
    const displayedValue = sectionValueField
      ? params.row[sectionValueField]
      : params.formattedValue;
    return params.rowNode.type === 'group' ? (
      <SectionCell {...params} displayedValue={displayedValue} />
    ) : (
      displayedValue
    );
  },
  colSpan: (_: unknown, row: Row) => {
    if (row.isGroup) {
      return columnsCount + 1;
    }
    return undefined;
  },
  cellClassName: (params: GridCellParams<Row>) => {
    if (params.rowNode.type === 'group') {
      return sectionCellClass;
    }
    if (params.row.isSummary) {
      return summaryCellClass;
    }
    if (params.row.isGroupSummary) {
      return sectionSummaryCellClass;
    }
    return '';
  },
});

type Props = {
  id: string;
  pagination?: PaginationState;
  disablePagination?: boolean;
  columns: Array<Column>;
  rows: Array<Row> | RowsGroup;
  selectable?: boolean;
  getExpandedContent?: (row: Row) => React.ReactNode;
  className?: string;
  loading?: boolean;
  sectionValueField?: keyof Row;
  sectionHeader?: string;
  sectionSummary?: string;
  disableTableConfiguration?: boolean;
};

/**
 * This component is still work in progress - shouldn't be used in user-facing features yet.
 */
const InteractiveTable: React.FC<Props> = ({
  id,
  pagination,
  disablePagination,
  columns,
  rows,
  selectable,
  getExpandedContent,
  className,
  loading,
  sectionValueField,
  sectionHeader,
  sectionSummary,
  disableTableConfiguration,
}) => {
  const [expandedRowId, setExpandedRowId] = useState<string>();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() =>
    getSavedModel<GridColumnVisibilityModel>(
      id,
      columnVisibilityModelKey,
      defaultColumnVisibilityModel,
    ),
  );

  const showFooter = !!pagination && !disablePagination;
  const showSummaryRow = columns.some((col) => col.summary);
  const interactiveColumns = useMemo(() => getColumns(columns), [columns]);

  const sortModel = useMemo(
    () => getSortModel(pagination),
    [pagination?.orderBy, pagination?.order],
  );

  const rowsToRender = useMemo(
    () =>
      getRowsToRender({
        rows,
        columns,
        showSummaryRow,
        sectionValueField,
        sectionSummary,
        expandedRowId,
      }),
    [
      rows,
      columns,
      showSummaryRow,
      sectionValueField,
      sectionSummary,
      expandedRowId,
    ],
  );

  const handleRowClick = useCallback<GridEventListener<'rowClick'>>(
    (params) => {
      if (!params.row.isExpandable) {
        return;
      }

      const clickedRowId = params.id.toString();
      setExpandedRowId(params.row.isExpanded ? undefined : clickedRowId);
    },
    [expandedRowId],
  );

  const handleColumnVisibilityModelChange = useCallback(
    (model: GridColumnVisibilityModel) => {
      saveModel(id, columnVisibilityModelKey, model);
      setColumnVisibilityModel(model);
    },
    [id, columnVisibilityModelKey],
  );

  const handleSortModelChange = useCallback(
    (model: GridSortModel) => {
      if (!pagination) {
        return;
      }

      if (
        model?.[0]?.field === sortModel?.[0]?.field &&
        model?.[0]?.sort === sortModel?.[0]?.sort
      ) {
        return;
      }
      pagination.handleRequestSort(null, model?.[0]?.field ?? '');
    },
    [pagination, sortModel],
  );

  const getDetailPanelContent = useCallback(
    (params: GridRowParams<Row>) => {
      return params.row.isExpandable ? (
        <ExpandedRow>{getExpandedContent?.(params.row)}</ExpandedRow>
      ) : null;
    },
    [getExpandedContent],
  );

  const groupingColDef = useMemo(
    () =>
      getGroupingColDef(
        sectionHeader,
        sectionValueField,
        interactiveColumns.length,
      ),
    [sectionHeader, sectionValueField, interactiveColumns.length],
  );

  const paginationModel = useMemo(() => {
    return {
      pageSize: pagination?.rowsPerPage ?? rowsToRender.length,
      page: pagination?.page ?? 0,
    };
  }, [pagination?.rowsPerPage, pagination?.page]);

  return (
    <TableContainer className="interactive-table">
      <DataGridPro
        treeData
        getTreeDataPath={getTreeDataPath}
        defaultGroupingExpansionDepth={1}
        groupingColDef={groupingColDef}
        className={className}
        rows={rowsToRender}
        columns={interactiveColumns}
        loading={loading}
        pagination={!!pagination && !disablePagination}
        paginationMode="server"
        paginationModel={paginationModel}
        getRowHeight={getRowHeight}
        onRowClick={handleRowClick}
        pageSizeOptions={pagSizeOptions}
        columnHeaderHeight={rowHeight}
        checkboxSelection={selectable}
        disableMultipleRowSelection
        disableRowSelectionOnClick
        disableColumnPinning
        hideFooterPagination={!pagination}
        hideFooter={!showFooter}
        disableVirtualization
        sortModel={sortModel}
        sortingMode="server"
        rowCount={rowsToRender.length}
        onSortModelChange={handleSortModelChange}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        detailPanelExpandedRowIds={expandedRowId ? [expandedRowId] : []}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        slotProps={slotProps}
        disableMultipleColumnsSorting
        slots={tableSlots}
        disableColumnMenu={disableTableConfiguration}
        disableColumnResize={disableTableConfiguration}
        disableColumnSorting={disableTableConfiguration}
        disableColumnReorder={disableTableConfiguration}
      />
      <ExpandIcon className={expandIconClass} />
      <ExpandIcon as={KeyboardArrowUp} className={collapseIconClass} />
    </TableContainer>
  );
};

export default InteractiveTable;
