import React, { useState } from 'react';
import {
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  OutlinedInputProps,
  FormControlProps,
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';

type Props = OutlinedInputProps &
  FormControlProps & {
    helperText?: string;
  };

const AppPasswordInput: React.FC<Props> = ({
  name,
  id,
  value,
  onChange,
  helperText = '',
  error = false,
  size = 'small',
  required,
  variant = 'outlined',
  className,
  label,
}) => {
  const [show, setShow] = useState(false);

  return (
    <FormControl
      size={size}
      required={required}
      fullWidth
      className={className}
      variant={variant}
    >
      <InputLabel error={error}>{label}</InputLabel>
      <OutlinedInput
        name={name}
        label={label}
        id={id}
        type={show ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        error={error || helperText !== ''}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShow(!show)}
              onMouseDown={(event) => event.preventDefault()}
              edge="end"
              size="large"
            >
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && (
        <FormHelperText error id="username-error">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default AppPasswordInput;
