import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import downloadFile from 'utils/downloadFile';
import { api } from 'lib/httpClient';
import { useState } from 'react';

export default function useTaxReportAudit() {
  const [isDownloading, setIsDownloading] = useState(false);
  const exceptionHandler = useApiExceptionHandler();

  const download = async (taxYearStart: number, firmId: string) => {
    setIsDownloading(true);
    try {
      const response = await api.get(
        `/annual-tax-report/${taxYearStart}/audit`,
        {
          params: {
            firmId,
          },
          responseType: 'blob',
        },
      );

      downloadFile(response.data, 'Annual tax report - audit sheet.xlsx');
    } catch (error) {
      exceptionHandler(error as Error, 'Failed to download tax report audit.');
    } finally {
      setIsDownloading(false);
    }
  };

  return {
    download,
    isDownloading,
  };
}
