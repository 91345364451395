import { useEffect } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { useNotification } from 'hooks/ui/useNotification';

async function fetchExits({ queryKey }) {
  const [, { params }] = queryKey;

  const {
    data: { data },
  } = await api.get(`exit/list`, { params });
  return data;
}

export default function useExits({ params }) {
  const notification = useNotification();

  const list = useQuery({
    queryKey: ['exits', { params }],
    queryFn: fetchExits,
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
  });

  useEffect(() => {
    //@ts-expect-error
    const errorMessage = list.error?.response?.data?.responseMsg;

    if (errorMessage) {
      notification.error(errorMessage);
    }
    //@ts-expect-error
  }, [list.error?.response?.data?.responseMsg, notification]);

  return list;
}
