import { useEffect } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';
import { Api } from 'further-types/adviser';

type Params = {
  organisationId?: string;
  params: Api.AdviserSubscriptionsParams;
};

export function useOrganisationLinkedSubscriptions(params: Params) {
  const notification = useNotification();

  const fetchQuery = useQuery<Api.AdviserSubscriptionsResponse>({
    queryKey: ['organisation-subscriptions', params],
    queryFn: async () => {
      const response = await api.get(
        `organisation/${params.organisationId}/subscriptions/list`,
        {
          params: params.params,
        },
      );

      return response.data.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!params.organisationId,
  });

  useEffect(() => {
    if (fetchQuery.isError) {
      notification.error(
        'Could not retrieve subscriptions for this organisation',
      );
    }
  }, [fetchQuery.isError, notification]);

  return fetchQuery;
}
