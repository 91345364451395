import { Undo } from '@mui/icons-material';
import Action from './Action';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
};

const UndoAction: React.FC<Props> = ({ onClick, disabled }) => {
  return (
    <Action icon={Undo} label="Undo" onClick={onClick} disabled={disabled} />
  );
};

export default UndoAction;
