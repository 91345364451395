import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { Api } from 'further-types/investor';
import { ApiResponse } from 'further-types/api';

export default function useInvestorChangeLog(investorId?: string) {
  return useQuery<Api.InvestorChangeLogResponse>({
    queryKey: ['investor-change-log', investorId],
    queryFn: async () => {
      const {
        data: { data },
      } = await api.get<ApiResponse<Api.InvestorChangeLogResponse>>(
        `investor/${investorId}/change-log`,
      );
      return data;
    },
    enabled: !!investorId,
  });
}
