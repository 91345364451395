import { api } from 'lib/httpClient';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

async function fetchInvestorsCount({ queryKey }) {
  const [, { reportingId, countOnly }] = queryKey;
  const {
    data: { data },
  } = await api.get(`investor-reporting/investors-in-report/${reportingId}`, {
    params: { countOnly },
  });
  return data;
}

function useGetInvestorsInReportAPI({ params }) {
  return useQuery({
    queryKey: ['investors-in-report', params],
    queryFn: fetchInvestorsCount,
    enabled: !!params.reportingId,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}

export function useGetInvestorsInReport(params) {
  return useGetInvestorsInReportAPI(params);
}
