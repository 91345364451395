import React from 'react';
import { CardContent, Card } from '@mui/material';
import PieChart, { useStyles } from 'components/PieChart';
import { RegionKeyToLabelMap } from 'adminConstants/labels';
import { numberToCurrencyString } from 'further-ui/utils';
import Heading from 'components/Heading';

type Props = {
  investmentData: Record<string, any>;
};

export const InvestmentsByLocation: React.FC<Props> = ({ investmentData }) => {
  const { classes: classnames } = useStyles();

  return (
    <Card>
      <CardContent>
        <Heading
          variant="h3"
          showDivider
          subtitle="(Ordered by initial subscription)"
        >
          Subscriptions by location
        </Heading>
        <PieChart
          height={300}
          data={Object.entries(investmentData)
            .filter(([, regionData]) => regionData.countOfInvestments > 0)
            .map(([key, regionData]) => ({
              key: RegionKeyToLabelMap[key],
              value: regionData.initialInvestment,
              ...regionData,
            }))}
          noDataMessage="No subscriptions yet"
          CustomTooltip={({ payload }) => {
            const item = payload?.[0];
            if (!item) return null;

            return (
              <div className={classnames.tooltipWrapper}>
                <p>
                  <strong>{item.name}</strong>
                </p>
                <p>
                  Initial subscriptions:{' '}
                  {numberToCurrencyString(item.value ?? 0, {
                    hidePenniesIfInteger: true,
                  })}
                </p>
                <p>Subscriptions: {item.payload?.countOfInvestments}</p>
              </div>
            );
          }}
        />
      </CardContent>
    </Card>
  );
};
