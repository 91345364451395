import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useParams } from 'react-router-dom';
import PageContainer from 'components/PageContainer';
import Summary from '../Add/Summary';
import { INVESTOR_REPORTING } from 'adminConstants/routes';
import { getCompanyLabel } from 'further-ui/utils';

import { useCustomUpdate } from 'hooks/data/customUpdate/useCustomUpdate';
import PageContent from 'components/PageContent';

const useStyles = makeStyles()((theme) => ({
  cardContentPadding: {
    padding: '2rem',
  },
  divider: {
    margin: '30px 0',
  },
  attachmentLink: {
    fontSize: '12px',
    color: theme.palette.text.primary,

    '&:hover': {
      color: theme.palette.text.rootColor,
    },
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  {
    label: 'Investor Reporting',
    link: INVESTOR_REPORTING,
  },
];

const ViewCustomUpdate: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const heading = 'Investor Reporting: View custom update';
  const { classes } = useStyles();
  const { fectchCustomUpdate } = useCustomUpdate();
  const { data } = fectchCustomUpdate(id);

  let selectedTranchesOrCompanies = [];
  if (data?.funds?.length) {
    selectedTranchesOrCompanies = data.funds.map((rec) => rec.fundName);
  } else {
    selectedTranchesOrCompanies = data?.companies?.map((rec) =>
      getCompanyLabel(rec),
    );
  }
  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <PageContent header="Custom update summary">
        <Summary
          data={{
            ...data,
          }}
          classes={classes}
          selectedTranchesOrCompanies={selectedTranchesOrCompanies}
          isTrancheSelected={data?.fundIds?.length}
        />
      </PageContent>
    </PageContainer>
  );
};

export default ViewCustomUpdate;
