import React from 'react';
import { Box, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { createShareSplitAddRoute } from 'adminConstants/routes';
import { useShareSplits } from 'hooks/data/company/useShareSplits';
import ButtonGroup from 'components/ButtonGroup';

const columns = [
  {
    label: 'Date',
    key: 'date',
    sort: false,
    render: (elm) => dateToLabel(elm.date),
  },
  {
    key: 'shareSplitFactor',
    label: 'Share split factor',
    sort: false,
  },
  {
    key: 'sharePricePreSplit',
    label: 'Original share price',
    sort: false,
    render: (elm) =>
      numberToCurrencyString(elm.sharePricePreSplit, { unlimitedDp: true }),
  },
  {
    key: 'sharePricePostSplit',
    label: 'New share price',
    sort: false,
    render: (elm) =>
      numberToCurrencyString(elm.sharePricePostSplit, { unlimitedDp: true }),
  },
];

type Props = {
  companyId?: string;
};

const ShareSplitRows: React.FC<Props> = ({ companyId }) => {
  const history = useHistory();

  const { shareSplits } = useShareSplits({ params: { companyId } });

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Table columns={columns} tablebody={shareSplits?.data ?? []} />
      <ButtonGroup>
        <Button
          onClick={() => history.push(createShareSplitAddRoute(companyId))}
          variant="contained"
          color="primary"
        >
          Split shares
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default ShareSplitRows;
