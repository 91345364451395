import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  box: {
    background: '#fafafa',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    marginBottom: '0',
    gap: '0.375rem',
  },
  boxTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  boxSubtitle: {
    fontSize: '14px',
    opacity: 0.6,
    fontWeight: 400,
    textAlign: 'center',
    width: '100%',
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    color: '#56B26C',
    '& > a': {
      marginLeft: '5px',
    },
  },
});

type SnapshotBoxProps = {
  value: number;
  subtitle: string;
  linkTo?: string;
  isCurrency?: boolean;
};

export const SnapshotBox = ({
  value,
  subtitle,
  linkTo,
  isCurrency = false,
}: SnapshotBoxProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.box}>
      <div className={classes.boxTitle}>
        {isCurrency ? (
          <CountUp
            redraw={true}
            end={value || 0}
            decimals={2}
            prefix="£"
            duration={0}
            separator=","
          />
        ) : (
          value
        )}
      </div>
      <div className={classes.boxSubtitle}>{subtitle}</div>
      {linkTo && (
        <Link className={classes.link} to={linkTo}>
          View all
        </Link>
      )}
    </div>
  );
};

export default SnapshotBox;
