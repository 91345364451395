import { useMutation } from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { api } from 'lib/httpClient';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { FrontEndSiteSubdomainUrlToStageMap } from 'adminConstants/redirectConstant';

type UseLaunchPortalAsDelegateMutateParams = {
  firmId: string;
  firmDomain: string;
};
type UseLaunchPortalAsDelegateMutateResponse = {
  token: string;
};

export function useLaunchPortalAsDelegate({ investorId }) {
  const handleApiException = useApiExceptionHandler();
  const stage = process.env.REACT_APP_STAGE ?? '';

  return useMutation({
    mutationFn: async (params: UseLaunchPortalAsDelegateMutateParams) => {
      const response = await api.post<
        ApiResponse<UseLaunchPortalAsDelegateMutateResponse>
      >(`delegated-access/enter/${investorId}/${params.firmId}`);

      const token = response?.data?.data?.token;

      const host = FrontEndSiteSubdomainUrlToStageMap[stage].replace(
        '{domain}',
        params.firmDomain,
      );
      window.open(`${host}/admin-delegate-entry/${token}`, '_blank');
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
}
