import React from 'react';

import Box from '@mui/material/Box';
import Header from './Header';
import Sidebar from '../Sidebar';
import { styled } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: `calc(100% - ${theme.sidebar.width}px)`,
  boxSizing: 'border-box',
  transition: 'all 0.3s ease',
  marginLeft: theme.sidebar.width,
  [theme.breakpoints.down(theme.sidebar.drawerBreakpoint)]: {
    width: '100%',
    marginLeft: 0,
    paddingTop: '64px !important',
  },
}));

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100vh',
  boxSizing: 'border-box',
  position: 'relative',
  transition: 'all 0.01s ease',
});

const MainContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Root>
      <Box
        display="flex"
        width="100%"
        height="100%"
        position="relative"
        flexDirection="column"
        flex={1}
      >
        <Sidebar />
        <Container>
          <Header />
          {children}
        </Container>
      </Box>
    </Root>
  );
};

export default MainContainer;
