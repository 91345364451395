import { TableActions } from 'components/Table';
import { Edit, Visibility, Delete } from '@mui/icons-material';
import {
  createOrganisationEditRoute,
  createOrganisationViewRoute,
} from 'adminConstants/routes';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';

type TableActionsProps = {
  row: any;
  deleteDisclosure: {
    stageAction: (props: { id: string }) => void;
  };
};

const OrganisationTableActions: React.FC<TableActionsProps> = ({
  row,
  deleteDisclosure,
}) => {
  const [editPermission, deletePermission] = useGetPermissions([
    'edit:organisations',
    'delete:organisations',
  ]);

  return (
    <TableActions
      showAsDropdown
      actions={[
        {
          label: 'View organisation',
          icon: Visibility,
          color: 'primary',
          link: createOrganisationViewRoute(row?._id),
        },
        {
          label: 'Edit',
          icon: Edit,
          color: 'primary',
          link: createOrganisationEditRoute(row?._id),
          visible: editPermission,
        },
        {
          label: 'Delete',
          icon: Delete,
          color: 'error',
          visible: deletePermission,
          disabled: row?.deleteForbidden,
          tooltip: row?.deleteForbidden
            ? 'This organisation is linked with one or more advisers or organisations, so it cannot be deleted.'
            : undefined,
          onClick: () => deleteDisclosure.stageAction({ id: row._id }),
        },
      ]}
    />
  );
};

export default OrganisationTableActions;
