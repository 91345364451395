import React, { PropsWithChildren } from 'react';
import { ArrowForward } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';

const useStyles = makeStyles<void, 'titleRoot' | 'titleRootHide'>()(
  (_theme, _params, classes) => ({
    hoverPointer: {
      display: 'flex',
      gridGap: '0.75rem',
      alignItems: 'center',
      color: 'inherit',

      '&:hover': {
        cursor: 'pointer',

        [`&  .${classes.titleRoot}`]: {
          display: 'none',
        },

        [`& .${classes.titleRootHide}`]: {
          display: 'block',
        },
      },
    },
    titleRoot: {
      display: 'block',
    },
    titleRootHide: {
      display: 'none',
    },
  }),
);

type Props = {
  hoverText?: string;
  to: string;
};

const HoverPointer: React.FC<PropsWithChildren<Props>> = ({
  hoverText,
  to,
  children,
}) => {
  const { classes } = useStyles();

  return (
    <Link className={classes.hoverPointer} to={to}>
      {hoverText ? (
        <>
          <span className={classes.titleRoot}>{children}</span>
          <span className={classes.titleRootHide}>{hoverText}</span>
        </>
      ) : (
        <span>{children}</span>
      )}
      <ArrowForward fontSize="small" />
    </Link>
  );
};

export default HoverPointer;
