import { api } from 'lib/httpClient';

export const deleteInvestor = (id) => {
  return () => {
    return api
      .delete(`investor/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const updateInvestorQuestion = (id) => {
  return () => {
    return api
      .put(`investor/allow-investor-question/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const manuallyPassAppropriateness = (id) => {
  return () => {
    return api
      .put(`investor/manually-pass-appropriateness/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const getAddress = (value) => {
  return () => {
    return api
      .get(`investor/get-uk-address`, { params: { input: value } })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMsg });
      });
  };
};
export const getQuestionList = () => {
  return () => {
    return api
      .get(`investor/question-list?isAdmin=true`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMsg });
      });
  };
};
