import React, { useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { sortBy } from 'lodash';

import { useGetRole } from 'hooks/ui/useGetRole';
import useCompanies from 'hooks/data/company/useCompanies';
import AppSelectBox from 'components/FormElements/AppSelectBox';

const useStyles = makeStyles()((theme) => ({
  error: {
    color: theme.palette.error.main,
    padding: '5px 0 0 14px',
  },
  placeholder: {
    color: '#999999',
  },
}));

type FormState = {
  selectedCompanyIds: Array<string>;
  companySelectAll: boolean;
  selectedFirmIds: Array<string>;
  selectedFirmNames: Array<string>;
};

type Props = {
  handleCompanyMultiSelectChanges: (formState: FormState) => void;
  error?: string;
  disabled?: boolean;
  value?: Array<string>;
};

const MultiFirmCompanySelector: React.FC<Props> = ({
  handleCompanyMultiSelectChanges,
  error,
  disabled,
  value,
}) => {
  const { firmId } = useGetRole();
  const { data: companies } = useCompanies({ params: { firmId } });
  const { classes } = useStyles();

  const [formState, setFormState] = useState<FormState>({
    selectedCompanyIds: value || [],
    companySelectAll: false,
    selectedFirmIds: [],
    selectedFirmNames: [],
  });
  const companiesWithLabel = useMemo(
    () =>
      companies?.result?.map((company) => ({
        label: `${company.tradingName} (${company.shareClass})`,
        //@ts-expect-error Investee list API endpoint has invalid type definition
        _id: company._id,
        firmId: company?.firmId,
      })),
    [companies?.result],
  );

  const sortedCompany = companiesWithLabel
    ? sortBy(companiesWithLabel, (c) => c.label?.toLowerCase())
    : [];

  // This function handles the change event for the multiselect in the company dropdown.
  // It updates the state with the selected company ids.
  const handleCompanyMultiSelectChange = async (event) => {
    const {
      target: { value },
    } = event;
    let companyIds = formState.selectedCompanyIds;
    let companySelectAll = formState.companySelectAll;
    if (value.includes('all')) {
      // if already checked all, then uncheck all
      if (formState.companySelectAll) {
        companyIds = [];
      } else {
        companyIds = sortedCompany.map((company) => company._id);
      }
      companySelectAll = !companySelectAll;
    } else {
      if (value.length === companyIds.length) {
        if (!companySelectAll) companySelectAll = !companySelectAll;
      } else {
        companySelectAll = false;
      }
      companyIds = value;
    }
    // set the new state
    const selectedFirmNames = new Set([]);
    const selectedFirmIds = new Set([]);

    companyIds.forEach((companyId) => {
      const company = sortedCompany.find(
        (selectedCompany) => selectedCompany._id === companyId,
      );
      if (company) {
        //@ts-expect-error Investee list API endpoint has invalid type definition
        selectedFirmNames.add(company?.firmId?.firmName);
        //@ts-expect-error Investee list API endpoint has invalid type definition
        selectedFirmIds.add(company?.firmId?._id);
      }
    });
    const formValue = {
      ...formState,
      companySelectAll,
      selectedCompanyIds: companyIds,
      selectedFirmNames: Array.from(selectedFirmNames),
      selectedFirmIds: Array.from(selectedFirmIds),
    };
    setFormState({
      ...formValue,
    });
    handleCompanyMultiSelectChanges(formValue);
  };

  return (
    <>
      <AppSelectBox
        data={sortedCompany}
        valueKey="_id"
        displayEmpty
        name="companyId"
        labelKey="label"
        multiple
        variant="outlined"
        value={formState.selectedCompanyIds}
        isCheckedAll={formState.companySelectAll}
        onChange={handleCompanyMultiSelectChange}
        error={!!error}
        helperText={error}
        disabled={disabled}
        //@ts-expect-error
        renderValue={(selected: Array<string>) => (
          <Box
            sx={{
              overflow: 'hidden',
            }}
          >
            {selected.length === 0 ? (
              <span className={classes.placeholder}>Select company(s)</span>
            ) : (
              selected
                .map((companyId) => {
                  return sortedCompany?.find((val) => val._id === companyId)
                    ?.label;
                })
                .join(', ')
            )}
          </Box>
        )}
      />

      {formState.selectedFirmIds.length > 1 && (
        <p className={classes.error}>
          Please note: you have selected companies from two or more firms (
          {formState.selectedFirmNames.join(', ')}). Please select companies
          relating to only one firm to continue.
        </p>
      )}
    </>
  );
};

export default MultiFirmCompanySelector;
