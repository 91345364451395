import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { Delete, Edit, FlashOn } from '@mui/icons-material';
import {
  EIS_ADD,
  createEisEditRoute,
  createKiFundCertificatesBatchEditRoute,
} from 'adminConstants/routes';
import { dateToLabel } from 'further-ui/utils';
import { downloadEIS1Certificate } from '@redux/actions/UploadShareHolding';
import { EIS1Download, EIS3Download, EIS5Download } from 'components/SVGIcons';
import { useNotification } from 'hooks/ui/useNotification';
import { useDownloadByEisWizardId } from 'hooks/data/eisWizard/useDownloadEisCertificatePdfs';
import { FC } from 'react';
import { Disclosure } from 'further-ui/hooks';
import { Api } from 'further-types/eis-wizard';
import useDownloadKiFundCertificates from 'hooks/data/kiFundCertificate/useDownloadKiFundCertificates';
import { TableActions } from 'components/Table';

type Props = {
  tasklistItem: Api.GetEisWizardTaskListResponse['results'][number];
  deleteDisclosure: Disclosure;
};

const RowActions: FC<Props> = ({ tasklistItem, deleteDisclosure }) => {
  const dispatch = useDispatch();
  const {
    shareAllocationUploadId,
    companyId,
    companyName,
    taxReliefElegible,
    createdWithoutCertificates,
    kiFundCertificatesBatchId,
    eisWizard,
    hasKiCertificates,
  } = tasklistItem;
  const notification = useNotification();
  const downloadCertificates = useDownloadByEisWizardId();
  const downloadEis5Certficates = useDownloadKiFundCertificates();

  const isDownloading =
    downloadCertificates.isLoading || downloadEis5Certficates.isDownloading;

  const handleDownloadPdfs = async () => {
    if (kiFundCertificatesBatchId) {
      await downloadEis5Certficates.download(kiFundCertificatesBatchId);
    } else {
      await downloadCertificates.download({
        //@ts-expect-error
        eisWizardId: eisWizard?.id,
        allocationName: dateToLabel(tasklistItem.allocationDate),
        companyName,
        investmentTypeLabel:
          eisWizard?.investmentType === 'EIS'
            ? 'EIS certificates'
            : 'SEIS certificates',
        splitFolders: true,
      });
    }
  };

  const handleDownloadEIS1 = async () => {
    await dispatch(
      // @ts-expect-error dispatch to be removed soon
      downloadEIS1Certificate(
        {
          id: tasklistItem.shareAllocationUploadId,
          isExportToCustodian: true,
        },
        notification,
      ),
    );
  };

  const downloadTitle = kiFundCertificatesBatchId
    ? 'Download EIS5'
    : 'Download EIS3s';
  const DownloadIcon = kiFundCertificatesBatchId ? EIS5Download : EIS3Download;
  const editItemRoute = eisWizard?.id
    ? createEisEditRoute(eisWizard.id)
    : createKiFundCertificatesBatchEditRoute(kiFundCertificatesBatchId);

  const tooltipText =
    'Tax certificates for this allocation cannot be newly processed or deleted while an EIS5 relating to this allocation has been created. Please delete the relevant EIS5 and try again.';

  if (eisWizard?.id || kiFundCertificatesBatchId) {
    return (
      <TableActions
        showAsDropdown
        actions={[
          {
            label: 'Edit',
            icon: Edit,
            link: editItemRoute,
            disabled: hasKiCertificates,
            tooltip: hasKiCertificates ? tooltipText : undefined,
            color: 'primary',
          },
          {
            label: 'Delete',
            icon: Delete,
            onClick: () => deleteDisclosure.stageAction(tasklistItem),
            disabled: hasKiCertificates,
            tooltip: hasKiCertificates ? tooltipText : undefined,
            color: 'error',
          },
          {
            label: downloadTitle,
            icon: isDownloading
              ? () => <CircularProgress size={18} />
              : DownloadIcon,
            onClick: handleDownloadPdfs,
            disabled: createdWithoutCertificates || isDownloading,
            visible: !createdWithoutCertificates,
            color: 'primary',
          },
        ]}
      />
    );
  }

  if (taxReliefElegible) {
    return (
      <TableActions
        showAsDropdown
        actions={[
          {
            label: 'Create certificates for this allocation',
            icon: FlashOn,
            link: `${EIS_ADD}?presetCompanyId=${companyId}&presetShareAllocationUploadId=${shareAllocationUploadId}`,
            disabled: hasKiCertificates,
            tooltip: hasKiCertificates ? tooltipText : undefined,
            color: 'primary',
          },
          {
            label: 'Download EIS1 data sheet',
            icon: EIS1Download,
            onClick: handleDownloadEIS1,
            color: 'primary',
          },
        ]}
      />
    );
  }

  return null;
};

export default RowActions;
