import RestrictedRoute from './RestrictedRoute';

import ProfilePage from '../Pages/Profile';
import Error404 from '../Pages/404';

import { PROFILE } from 'adminConstants/routes';

const CommonRoutes = [
  <RestrictedRoute component={ProfilePage} path={PROFILE} />,
  <RestrictedRoute component={Error404} />,
];

export default CommonRoutes;
