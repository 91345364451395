import { EIS_ADD } from 'adminConstants/routes';
import { Skeleton, CardContent, Card } from '@mui/material';
import { FlashOn } from '@mui/icons-material';
import Grid from '@mui/material/Grid2';
import GridContainer from 'components/GridContainer';
import Box from 'components/Box';
import useTrancheEisAllotments from 'hooks/data/dashboards/useTrancheEisAllotments';
import Heading from 'components/Heading';
import LoadingError from '../LoadingError';
import useInitialElementSize from 'hooks/ui/useInitialElementSize';

const EisAllotments = ({ firmId }) => {
  const { data, isFetching, isRefetching, isError } = useTrancheEisAllotments({
    firmId,
  });

  const isLoading = isFetching || isRefetching;

  const [ref, { height }] = useInitialElementSize();
  if (isError) {
    return <LoadingError height={height} />;
  }

  return (
    <Card ref={ref}>
      <CardContent>
        <Heading
          icon={FlashOn}
          link={{ to: EIS_ADD, label: 'Create S/EIS Certificates' }}
          variant="h3"
          showDivider
        >
          S/EIS Certificates
        </Heading>
        <GridContainer>
          <Grid size={{ md: 6, xs: 12 }}>
            <Box
              title={
                isLoading ? (
                  <Skeleton animation="wave" variant="text" />
                ) : (
                  data?.awaitingEisWizard
                )
              }
              subtitle="Allocations awaiting S/EIS processing"
            />
          </Grid>
          <Grid size={{ md: 6, xs: 12 }}>
            <Box
              title={
                isLoading ? (
                  <Skeleton animation="wave" variant="text" />
                ) : (
                  data?.totalEisWizard
                )
              }
              subtitle="Allocations with S/EIS processed"
            />
          </Grid>
        </GridContainer>
      </CardContent>
    </Card>
  );
};

export default EisAllotments;
