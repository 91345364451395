import { adviserOnlyLinkedToSingleFirm } from './adviserOnlyLinkedToSingleFirm';

export const canEditAdviser = (
  isSuperAdmin: boolean,
  adviserData: {
    associatedFirms: { _id: string }[];
    hasMoreThanOneFirm: boolean;
    allInvestmentsWithSingleFirm: boolean;
  },
  firmId: string,
): boolean => {
  return isSuperAdmin || adviserOnlyLinkedToSingleFirm(adviserData, firmId);
};

export const canDeleteAdviser = (
  isSuperAdmin: boolean,
  adviserData: {
    investmentsCount: number;
    associatedFirms: { _id: string }[];
  },
  firmId: string,
  deletePermission: boolean,
) => {
  const isSuperAdminAndNoInvestments =
    isSuperAdmin && !adviserData.investmentsCount;
  const isAdviserLinkedToSingleFirmAndNoInvestments =
    adviserData?.associatedFirms.length === 1 &&
    adviserData.associatedFirms[0]._id === firmId &&
    !adviserData?.investmentsCount;

  return (
    deletePermission &&
    (isSuperAdminAndNoInvestments ||
      isAdviserLinkedToSingleFirmAndNoInvestments)
  );
};
