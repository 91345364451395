import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

type InvestorBasicListParams = {
  firmId?: string;
  search?: string;
};

type InvestorResult = {
  data: Array<{
    _id: string;
    email: string;
    fullName: string;
  }>;
};

export default function useGetInvestorsLeanResponse(
  params: InvestorBasicListParams,
  disable = false,
) {
  return useQuery({
    queryKey: ['investors-lean', params],
    queryFn: async () => {
      const {
        data: { data },
      } = await api.get<InvestorResult>('investor/list-lean-response', {
        params,
      });
      return data;
    },
    enabled: !disable,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });
}
