import AppTextInput from 'components/FormElements/AppTextInput';
import { InvestorFormValues } from 'helpers/investor/investorForm';
import { Control, Controller } from 'react-hook-form';

const TextField: React.FC<{
  name: string;
  control: Control<InvestorFormValues>;
}> = ({ name, control }) => (
  <Controller
    name={name as keyof InvestorFormValues}
    control={control}
    render={({ field: { ref, ...rest }, fieldState: { error } }) => (
      <AppTextInput
        {...rest}
        inputRef={ref}
        variant="outlined"
        error={!!error}
        helperText={error?.message}
      />
    )}
  />
);

export default TextField;
