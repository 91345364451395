import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { Router } from 'react-router-dom';
import configureStore, { history } from './redux/store';
import AppWrapper from './AppWrapper';
import Routes from './routes';
import api, { logoutLink, TrcpProvider } from './lib/trpcClient';
import Auth0Provider from './providers/auth0Provider';

import 'react-perfect-scrollbar/dist/css/styles.css';
import { httpBatchLink } from '@trpc/client';
import { useAuth0 } from '@auth0/auth0-react';
import { setupAxiosInstance } from 'lib/httpClient';
import SuperJSON from 'superjson';

export const store = configureStore();

const App: React.FC = () => {
  return (
    <SnackbarProvider
      autoHideDuration={10000}
      maxSnack={5}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      preventDuplicate={true}
    >
      <Provider store={store}>
        <Router history={history}>
          <Auth0Provider history={history}>
            <AppInner />
          </Auth0Provider>
        </Router>
      </Provider>
    </SnackbarProvider>
  );
};

const AppInner: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [queryClient] = useState(() => {
    setupAxiosInstance(getAccessTokenSilently);
    return new QueryClient({
      defaultOptions: {
        queries: {
          placeholderData: keepPreviousData,
          staleTime: 60 * 60 * 1000,
        },
      },
    });
  });
  const [trpcClient] = useState(() =>
    api.createClient({
      links: [
        logoutLink,
        httpBatchLink({
          url: `${process.env.REACT_APP_API_URL}/v2/`,
          transformer: SuperJSON,
          headers: async () => {
            const token = await getAccessTokenSilently();

            return {
              authorization: token ? `Bearer ${token}` : '',
            };
          },
        }),
      ],
    }),
  );

  return (
    <TrcpProvider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <AppWrapper>
          <Routes />
        </AppWrapper>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </TrcpProvider>
  );
};
export default App;
