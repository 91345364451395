import { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { omit } from 'lodash';
import FieldRow from 'components/FormElements/FieldRow';
import AppDatePicker from 'components/FormElements/AppDatePicker';

import GridContainer from 'components/GridContainer';
import { getExcelFile } from '@redux/actions/Investment';
import CompanyListSelect from 'components/CompanyListSelect';
import MultiFirmTrancheSelector from 'components/MultiFirmTrancheSelector';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { useNotification } from 'hooks/ui/useNotification';
import { format } from 'date-fns';
import RecordView from 'components/RecordView';
import { fundStatusMap } from 'adminConstants/fundProperties';

const useStyles = makeStyles()((theme) => ({
  placeholder: {
    color: '#999999',
  },
  error: {
    color: theme.palette.error.main,
    padding: '5px 0 0 14px',
  },
  buttonRow: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    gridGap: '0.5rem',
    marginTop: '0',
  },
}));

/**
 * @callback CallbackWithFormState
 * @param {{selectedTrancheIds: [string], investmentDate: string}} formState The new form state
 */

/**
 * @param {{callback: CallbackWithFormState, skipCallback: bool}} props
 */
const CreateSheetForm = ({
  callback,
  skipCallback = null,
  hideButtons = false,
}) => {
  const { classes } = useStyles();
  const [excelFileGenerating, setExcelFileGenerating] = useState(false);
  const [formState, setFormState] = useState<{
    selectedFirmNames: Array<string>;
    selectedFirmIds: Array<string>;
    selectedTrancheIds: Array<string>;
    selectedCompany: any;
    investmentDate: Date | null;
  }>({
    selectedFirmNames: [],
    selectedFirmIds: [],
    selectedTrancheIds: [],
    selectedCompany: null,
    investmentDate: new Date(),
  });
  const dispatch = useDispatch();
  const notification = useNotification();
  const handleApiRequest = useApiRequestHandler();

  /**
   * This function is called when the user selects one or more tranches from the multiselect dropdown.
   * It takes the tranche ids as an argument and updates the formState with the new tranche ids.
   * @param trancheIds - the selected tranche ids
   * @returns {Promise<void>}
   */
  const handleChangeSelectedTranches = (values) => {
    setFormState((formState) => ({
      ...formState,
      ...values,
    }));
  };

  /**
   * This function is called when the user clicks the download button.
   */
  const handleDownloadButtonClick = async () => {
    setExcelFileGenerating(true);
    const body = {
      ...omit(formState, ['selectedCompany']),
      selectedCompanyId: formState.selectedCompany,
      investmentDate: formState.investmentDate
        ? format(formState.investmentDate, "yyyy-MM-dd'T'00:00:00+00:00")
        : '',
    };

    await handleApiRequest(async () => {
      const response = await dispatch<any>(getExcelFile(body, notification));
      setExcelFileGenerating(false);

      if (response.status === 200) {
        callback(body);
      }
    }, 'Something went wrong');
  };

  return (
    <RecordView>
      <FieldRow key="tranches" title="Tranche(s)*">
        <MultiFirmTrancheSelector
          trancheFilters={{
            fullyDeployed: false,
            fundStatus: [fundStatusMap.open, fundStatusMap.closed],
          }}
          handleChangeSelectedTranches={handleChangeSelectedTranches}
        />
      </FieldRow>
      <FieldRow key="companies" title="Company*">
        <CompanyListSelect
          value={formState.selectedCompany}
          firmId={
            formState.selectedFirmIds.length
              ? formState.selectedFirmIds[0]
              : undefined
          }
          handleChangeCallback={(selected) => {
            setFormState((formState) => ({
              ...formState,
              selectedCompany: selected,
            }));
          }}
        />
      </FieldRow>
      <FieldRow key="date" title="Date of subscription*">
        <AppDatePicker
          name="investmentDate"
          value={formState.investmentDate}
          onChange={(date) =>
            setFormState((formState) => ({
              ...formState,
              investmentDate: date,
            }))
          }
          disableFuture
        />
      </FieldRow>
      {!hideButtons && (
        <FieldRow key="actions">
          {skipCallback && (
            <GridContainer className={classes.buttonRow}>
              <Button variant="text" color="primary" onClick={skipCallback}>
                Skip
              </Button>
              {excelFileGenerating && <CircularProgress size={20} />}
              <Button
                variant="contained"
                color="primary"
                disabled={
                  excelFileGenerating ||
                  formState.selectedTrancheIds.length === 0 ||
                  !formState.investmentDate ||
                  !formState.selectedCompany ||
                  formState.selectedFirmIds.length > 1
                }
                onClick={handleDownloadButtonClick}
              >
                Create
              </Button>
            </GridContainer>
          )}
        </FieldRow>
      )}
    </RecordView>
  );
};

export default memo(CreateSheetForm);
