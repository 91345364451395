import { Button, CircularProgress, Typography } from '@mui/material';
import { AppSelectBox, FieldRow } from 'components/FormElements';
import { FC, useState } from 'react';
import useKiEligibleFunds from 'hooks/data/fund/useKiEligibleFunds';
import Spacing from 'components/Spacing';
import ButtonGroup from 'components/ButtonGroup';

type Props = {
  onNext: (fundDetails: {
    fundId: string;
    fundName: string;
    fundCloseDate: Date;
  }) => void;
  onBack: () => void;
  isEditMode?: boolean;
  defaultFundId?: string;
};

const FundSelector: FC<Props> = ({
  onNext,
  onBack,
  isEditMode,
  defaultFundId,
}) => {
  const tranches = useKiEligibleFunds();
  const [selectedFundId, setSelectedFundId] = useState<string | undefined>(
    defaultFundId,
  );
  const [error, setError] = useState<string>();

  const handleNext = () => {
    setError(undefined);

    if (!selectedFundId) {
      setError('Please select a tranche');
      return;
    }
    const selectedFund = tranches.data?.find(
      (tranche) => tranche._id === selectedFundId,
    );
    if (!selectedFund) {
      setError("Can't find selected tranche");
      return;
    }

    onNext({
      fundId: selectedFundId,
      fundName: selectedFund.fundName,
      fundCloseDate: new Date(selectedFund.closedDate),
    });
  };

  const availableTranches =
    tranches.data?.filter(
      (tranche) => !tranche.hasCertificate || tranche._id === defaultFundId,
    ) ?? [];

  return (
    <Spacing>
      <FieldRow title="Tranche*" centerTitle>
        {tranches.isLoading ? (
          <CircularProgress size={32} />
        ) : (
          <AppSelectBox
            required
            data={availableTranches}
            valueKey="_id"
            disabled={isEditMode}
            name="fundId"
            labelKey="fundName"
            variant="outlined"
            value={selectedFundId}
            error={!!error}
            helperText={error}
            placeholder={
              availableTranches.length
                ? 'Select tranche'
                : 'No KI funds available'
            }
            onChange={(event) => {
              setSelectedFundId(event.target.value as string);
            }}
          />
        )}
      </FieldRow>
      <ButtonGroup>
        <Button color="primary" variant="outlined" onClick={onBack}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleNext}
          disabled={tranches.isLoading}
        >
          Next
        </Button>
      </ButtonGroup>
      <Typography>
        <strong>Note:</strong> This feature can only be used after completing
        the final allocation for your selected knowledge-intensive EIS fund
        tranche. The system will use tranche EIS3 data to populate each EIS5.
      </Typography>
    </Spacing>
  );
};

export default FundSelector;
