import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material';

const Loader = styled('div')({
  left: 0,
  top: 0,
  zIndex: 999999,
  height: '80vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const PageLoader: React.FC = () => {
  return (
    <Loader>
      <CircularProgress />
    </Loader>
  );
};

export default PageLoader;
