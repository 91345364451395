import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles()(() => ({
  heading: {
    fontWeight: 700,
  },
  cmAcc: {
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    margin: '0 !important',
    '&::before': {
      display: 'none',
    },
  },
  cmAccHeader: {
    color: '#656565',
    padding: '0',
    minHeight: 'initial !important',
  },
  cmAccDetail: {
    padding: '5px 0 25px',
  },
}));

type Props = any;

const AccordionItem: React.FC<Props> = ({
  children,
  expanded,
  handleChange,
  panelName,
  heading,
  disabled,
}) => {
  const { classes } = useStyles();

  return (
    <Accordion
      className={classes.cmAcc}
      expanded={expanded === panelName}
      onChange={handleChange(panelName)}
      disabled={disabled}
    >
      <AccordionSummary
        className={classes.cmAccHeader}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${panelName}bh-content`}
        id={`${panelName}bh-header`}
      >
        <Typography className={classes.heading}>{heading}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.cmAccDetail}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionItem;
