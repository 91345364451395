import { useCallback, useEffect, useMemo, useState } from 'react';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { numberToDisplayString } from 'utils/numbers';
import { Box, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AccruedFeesCell from './AccruedFeesCell';
import SharesAllocatedForSaleCell from './SharesAllocatedForSaleCell';
import PerformanceFeeCell from './PerformanceFeeCell';
import TotalSaleValueCell from './TotalSaleValueCell';
import AccruedFeesPercentageAllocationCell from './AccruedFeesPercentageAllocationCell';
import SharePriceCell from './SharePriceCell';
import ClearColumnButton from './ClearColumnButton';
import { useExit } from 'hooks/data/exit';
import NetFigureDisplay from './NetFigureDisplay';
import ReviewShareholdingsTableFooter from './ReviewShareholdingsTableFooter';
import { CompareArrows } from '@mui/icons-material';
import { createInvestmentEditRoute } from 'adminConstants/routes';
import { round } from 'lodash';
import { format } from 'date-fns';

const useStyles = makeStyles()((theme) => ({
  trancheColumn: {
    minWidth: '7rem',
  },
  tableHeadingTr: {
    borderBottom: '1px solid #EDEDED',
  },
  tableHeadingTd: {
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '18px 6px 15px',
    position: 'relative',
    fontSize: '14px',
    borderBottom: '0 none',
  },
  tooltipLink: {
    color: theme.palette.text.rootColor,
  },
}));

type Props = any; //TODO: define types

const ReviewShareholdings: React.FC<Props> = ({
  exitDetails,
  previousExitDetails,
  shareholdings,
  setShareholdings,
  disableTable,
  isBeingEdited,
  exitId,
}) => {
  const { classes } = useStyles();
  const [accruedFeesPercentageAllocation, setAccruedFeesPercentageAllocation] =
    useState<number | null>(exitDetails.chargeAccruedFees ? 100 : 0);
  const [areInlineAccruedFeesSet, setAreInlineAccruedFeesSet] = useState(false);
  const { stageForEdit, stageForCreation } = useExit({});

  useEffect(() => {
    if (stageForEdit.data && !shareholdings.length) {
      const formattedInitialShareholdingData = stageForEdit.data.data?.map(
        (sh) => ({
          ...sh,
          // store the maximum accrued fees allowed to be charged so we
          // can use it for validation even if the user changes the value
          investorTotalAccruedFees: sh.accruedFeesCharged,
          previousGrossReceivedValue: sh.previousGrossReceivedValue,
        }),
      );
      setInitialShareholdings(formattedInitialShareholdingData);
    }
  }, [stageForEdit.data]);

  useEffect(() => {
    if (stageForCreation.data && !shareholdings.length) {
      const formattedInitialShareholdingData = stageForCreation.data.data?.map(
        (sh) => ({
          ...sh,
          // store the original sharesAllocatedForSale so we know
          // if the user has changed it via the input box
          suggestedSharesAllocatedForSale: sh.sharesAllocatedForSale,
          // store the maximum accrued fees allowed to be charged so we
          // can use it for validation even if the user changes the value
          investorTotalAccruedFees: sh.accruedFeesCharged,
        }),
      );
      setInitialShareholdings(formattedInitialShareholdingData);
    }
  }, [stageForCreation.data]);

  useEffect(() => {
    const getSaleAllocations = async () => {
      // fetch the investors with shareholdings and their sale allocations
      if (isBeingEdited) {
        //@ts-expect-error
        stageForEdit.mutate({
          exitId,
          exitDate: format(exitDetails.exitDate, 'dd/MM/yyyy'),
          firmId: exitDetails.firmId,
          companyId: exitDetails.selectedCompanyId,
          sharePrice: Number(exitDetails.sharePrice),
        });
      } else {
        //@ts-expect-error
        stageForCreation.mutate({
          exitDate: format(exitDetails.exitDate, 'dd/MM/yyyy'),
          companyId: exitDetails.selectedCompanyId,
          firmId: exitDetails.firmId,
          sharePrice: Number(exitDetails.sharePrice),
          sharesToBeSold: Number(exitDetails.sharesToBeSold),
        });
      }
    };
    if (exitDetails && Object.keys(exitDetails).length > 0) {
      getSaleAllocations();
    }
  }, [exitDetails]);

  const getAccruedFeesAutoValue = (sh) => {
    if (accruedFeesPercentageAllocation === 0) {
      return {
        accruedFeesCharged: 0,
        accruedFeesSetBelowTarget: false,
      };
    }

    const targetAccruedFeesCharged =
      sh.investorTotalAccruedFees *
      ((accruedFeesPercentageAllocation ?? 0) / 100);
    const maximumAccruedFees = round(
      (isBeingEdited ? sh.additionalGrossReceivedValue : sh.totalSaleValue) -
        (sh.performanceFee || 0),
      8,
    );
    const maximumAccruedFeesAboveZero = Math.max(maximumAccruedFees, 0);

    if (targetAccruedFeesCharged > maximumAccruedFeesAboveZero) {
      return {
        accruedFeesCharged: round(maximumAccruedFeesAboveZero, 2),
        accruedFeesSetBelowTarget: true,
      };
    }

    return {
      accruedFeesCharged: round(targetAccruedFeesCharged, 2),
      accruedFeesSetBelowTarget: false,
    };
  };

  const setInitialShareholdings = (input) => {
    const isReceivedValueSharePriceUnchanged =
      exitDetails.sharePrice === previousExitDetails?.sharePrice;

    const newShareholdings = input.map((sh) => {
      const totalSaleValue =
        sh.previousGrossReceivedValue && isReceivedValueSharePriceUnchanged
          ? sh.previousGrossReceivedValue
          : round(Number(sh.sharesAllocatedForSale) * Number(sh.sharePrice), 2);

      const grossTaxableValue = round(
        Number(sh.sharesAllocatedForSale) *
          Number(exitDetails.taxableValueSharePrice),
        2,
      );

      let additionalGrossReceivedValue;
      if (
        sh.previousGrossReceivedValue ||
        sh.previousGrossReceivedValue === 0
      ) {
        additionalGrossReceivedValue =
          totalSaleValue - sh.previousGrossReceivedValue;
      }

      const { accruedFeesCharged, accruedFeesSetBelowTarget } =
        getAccruedFeesAutoValue({
          ...sh,
          totalSaleValue, // supply the value of totalSaleValue set above, since it's not set on `sh` yet
          additionalGrossReceivedValue, // supply this also, since its not set on `sh` yet
        });

      let totalToBeReturned =
        totalSaleValue - accruedFeesCharged - (sh.performanceFee || 0);
      let netTaxableValue =
        grossTaxableValue - accruedFeesCharged - (sh.performanceFee || 0);

      if (sh.previousAccruedFeesCharged || sh.previousPerformanceFeesCharged) {
        totalToBeReturned -=
          sh.previousAccruedFeesCharged + sh.previousPerformanceFeesCharged;
        netTaxableValue -=
          sh.previousAccruedFeesCharged + sh.previousPerformanceFeesCharged;
      }

      return {
        ...sh,
        taxableValueSharePrice: exitDetails.taxableValueSharePrice,
        totalSaleValue,
        grossTaxableValue,
        netTaxableValue,
        totalToBeReturned,
        accruedFeesCharged,
        accruedFeesSetBelowTarget,
        additionalGrossReceivedValue,
      };
    });
    setShareholdings(newShareholdings);
  };

  const setOverride = useCallback(
    (shareholdingId, overrideProperties) => {
      setShareholdings((prevState) => {
        return prevState.map((sh) => {
          if (sh.shareholdingId === shareholdingId) {
            const updatedSh = {
              ...sh,
              ...overrideProperties,
            };

            if (overrideProperties.hasOwnProperty('sharesAllocatedForSale')) {
              updatedSh.totalSaleValue = round(
                Number(updatedSh.sharesAllocatedForSale) *
                  Number(updatedSh.sharePrice),
                2,
              );
            }

            if (
              overrideProperties.hasOwnProperty('taxableValueSharePrice') ||
              overrideProperties.hasOwnProperty('sharesAllocatedForSale') ||
              overrideProperties.hasOwnProperty('totalSaleValue')
            ) {
              updatedSh.grossTaxableValue = round(
                Number(updatedSh.sharesAllocatedForSale) *
                  Number(updatedSh.taxableValueSharePrice),
                2,
              );
            }

            if (overrideProperties.hasOwnProperty('accruedFeesCharged')) {
              setAreInlineAccruedFeesSet(true);
              setAccruedFeesPercentageAllocation(null);
            }

            // as a failsafe, ensure that grossTaxableValue is never less than the received value
            updatedSh.grossTaxableValueBeingConstrained =
              updatedSh.totalSaleValue > updatedSh.grossTaxableValue;
            updatedSh.grossTaxableValue = Math.max(
              updatedSh.grossTaxableValue,
              updatedSh.totalSaleValue,
            );

            const totalToBeReturned =
              updatedSh.totalSaleValue -
              (updatedSh.accruedFeesCharged || 0) -
              (updatedSh.performanceFee || 0);

            const netTaxableValue =
              updatedSh.grossTaxableValue -
              (updatedSh.accruedFeesCharged || 0) -
              (updatedSh.performanceFee || 0);

            const record = {
              ...updatedSh,
              totalToBeReturned,
              netTaxableValue,
            };

            if (sh.previousGrossReceivedValue) {
              record.additionalGrossReceivedValue =
                updatedSh.totalSaleValue - sh.previousGrossReceivedValue;
            }

            if (
              sh.previousAccruedFeesCharged ||
              sh.previousPerformanceFeesCharged
            ) {
              record.totalToBeReturned -=
                sh.previousAccruedFeesCharged +
                sh.previousPerformanceFeesCharged;
              record.netTaxableValue -=
                sh.previousAccruedFeesCharged +
                sh.previousPerformanceFeesCharged;
            }

            return record;
          }
          return sh;
        });
      });
    },
    [setShareholdings],
  );

  useEffect(() => {
    if (accruedFeesPercentageAllocation === null) return;

    const newShareholdings = shareholdings.map((sh) => {
      const { accruedFeesCharged, accruedFeesSetBelowTarget } =
        getAccruedFeesAutoValue(sh);
      const totalToBeReturned =
        sh.totalSaleValue - accruedFeesCharged - (sh.performanceFee || 0);
      const netTaxableValue =
        sh.grossTaxableValue - accruedFeesCharged - (sh.performanceFee || 0);

      return {
        ...sh,
        accruedFeesCharged,
        accruedFeesSetBelowTarget,
        totalToBeReturned,
        netTaxableValue,
      };
    });
    setShareholdings(newShareholdings);
    setAreInlineAccruedFeesSet(false);
  }, [accruedFeesPercentageAllocation]);

  const columns = useMemo(
    () => [
      {
        label: 'Investor name',
        key: 'investorName',
        sort: false,
        render: (elm) => {
          if (!elm.sourceInvestmentId) return elm.investorName;

          const sourceInvestorName = elm?.transferSourceInvestor?.fullName;
          const tooltipText = `This investor is the recipient of a transfer from <a class="${
            classes.tooltipLink
          }" href="${createInvestmentEditRoute(
            elm?.sourceInvestmentId,
          )}" target="_blank">${sourceInvestorName}</a> on ${dateToLabel(
            elm?.transferDate,
          )}.`;
          return (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: tooltipText }} />}
            >
              <Box alignItems="center" display="flex" gap={4}>
                {elm?.investorName}
                <CompareArrows />
              </Box>
            </Tooltip>
          );
        },
      },
      {
        label: 'Tranche',
        key: 'trancheName',
        sort: false,
        className: classes.trancheColumn,
        render: (elm) => elm?.tranche?.fundName,
      },
      {
        label: 'Shares held',
        key: 'noOfShares',
        sort: false,
        render: (elm) => numberToDisplayString(elm?.noOfShares),
      },
      {
        label: 'Shares for sale',
        key: 'sharesAllocatedForSale',
        sort: false,
        render: (elm) =>
          isBeingEdited ? (
            numberToDisplayString(elm.sharesAllocatedForSale)
          ) : (
            <SharesAllocatedForSaleCell
              cell={elm}
              setOverride={setOverride}
              disableTable={disableTable}
            />
          ),
      },
      {
        label: 'Received value',
        key: 'totalSaleValue',
        sort: false,
        tooltipText: (
          <>
            This is the capital returned and attributable to each individual,
            based on the transaction’s current <i>Received value share price</i>
            .
          </>
        ),
        render: (elm) => (
          <TotalSaleValueCell
            setOverride={setOverride}
            cell={elm}
            disableTable={disableTable}
          />
        ),
      },
      ...(isBeingEdited
        ? [
            {
              label: 'Additional received value',
              key: 'additionalGrossReceivedValue',
              sort: false,
              render: (elm) =>
                numberToCurrencyString(elm.additionalGrossReceivedValue),
            },
          ]
        : []),
      {
        label: isBeingEdited ? 'Additional performance fee' : 'Performance fee',
        key: 'performanceFee',
        sort: false,
        render: (elm) => (
          <PerformanceFeeCell
            shareholdingId={elm.shareholdingId}
            totalSaleValue={
              isBeingEdited
                ? elm.additionalGrossReceivedValue
                : elm.totalSaleValue
            }
            setOverride={setOverride}
            performanceFee={elm.performanceFee}
            disableTable={disableTable}
          />
        ),
      },
      {
        label: isBeingEdited ? 'Additional accrued fees' : 'Accrued fees',
        key: 'accruedFeesCharged',
        sort: false,
        render: (elm) => (
          <AccruedFeesCell
            cell={elm}
            setOverride={setOverride}
            disableTable={disableTable}
          />
        ),
      },
      {
        label: 'Net return',
        key: 'totalToBeReturned',
        sort: false,
        tooltipText: (
          <>
            This is the <i>Received value</i>, net of any accrued or performance
            fees charged.
          </>
        ),
        render: (elm) => (
          <NetFigureDisplay
            value={elm.totalToBeReturned}
            row={elm}
            isBeingEdited={isBeingEdited}
          />
        ),
      },
      {
        label: 'Share price',
        key: 'sharePrice',
        sort: false,
        tooltipText: (
          <>
            This is the transaction’s <i>Taxable value share price</i>, which is
            always the same or more than the transaction’s{' '}
            <i>Received value share price.</i>
          </>
        ),
        render: (elm) => (
          <SharePriceCell
            cell={elm}
            setOverride={setOverride}
            disableTable={disableTable}
          />
        ),
      },
      {
        label: 'Net taxable value',
        key: 'netTaxableValue',
        sort: false,
        tooltipText: (
          <>
            <p>
              This figure is the transaction’s gross taxable value, net of any
              accrued or performance fees charged.
            </p>
            <br />
            <p>
              If this figure is italicised, it is being calculated based on the
              row's received value figure, because it is higher than the row's
              stated taxable value share price.
            </p>
          </>
        ),
        render: (elm) => (
          <NetFigureDisplay
            value={elm.netTaxableValue}
            row={elm}
            isBeingEdited={isBeingEdited}
            isBeingConstrained={elm.grossTaxableValueBeingConstrained}
          />
        ),
      },
    ],
    [setOverride, disableTable],
  );

  const tableHeading = (
    <tr className={classes.tableHeadingTr}>
      <td></td>
      <td></td>
      <td></td>
      <td>
        {!isBeingEdited && (
          <ClearColumnButton
            keyToClear="sharesAllocatedForSale"
            setShareholdings={setShareholdings}
            disable={disableTable}
          />
        )}
      </td>
      <td></td>
      {isBeingEdited && <td></td>}
      <td>
        <ClearColumnButton
          keyToClear="performanceFee"
          setShareholdings={setShareholdings}
          disable={disableTable}
        />
      </td>
      <td className={classes.tableHeadingTd}>
        <AccruedFeesPercentageAllocationCell
          disableTable={disableTable}
          areInlineAccruedFeesSet={areInlineAccruedFeesSet}
          accruedFeesPercentageAllocation={accruedFeesPercentageAllocation}
          setAccruedFeesPercentageAllocation={
            setAccruedFeesPercentageAllocation
          }
        />
      </td>
    </tr>
  );

  return (
    <Table
      columns={columns}
      tablebody={shareholdings}
      loading={stageForCreation.isPending || stageForEdit.isPending}
      CustomTableHeading={tableHeading}
      TableFooter={
        <ReviewShareholdingsTableFooter
          shareholdings={shareholdings}
          isBeingEdited={isBeingEdited}
        />
      }
      emptyMessage="No shareholdings found for this company. Possibly the shareholdings did not exist before the selected exit date."
      variant="nohover"
    />
  );
};

export default ReviewShareholdings;
