import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sortBy } from 'lodash';
import PageContainer from 'components/PageContainer';
import { dateToLabel, toRequestDate } from 'further-ui/utils';
import { type } from 'adminConstants/typeConstant';
import { useFunds } from 'hooks/data/fund/useFunds';
import { useGetRole } from 'hooks/ui/useGetRole';
import { getInvestmentList } from '@redux/actions/FeeManagement';

import FeesAndDiscountError from './FeesAndDiscountError';
import AddFeeAndDiscountForm from './AddFeeAndDiscountForm';
import Summary from './Summary';
import useAddFeesAndDiscounts from 'hooks/data/investment/useAddFeesAndDiscounts';
import { useNotification } from 'hooks/ui/useNotification';
import PageContent from 'components/PageContent';
import { Typography } from '@mui/material';
import Heading from 'components/Heading';
import Spacing from 'components/Spacing';

const stepLabels = [
  'Add fee or discount',
  'Could not add fee or discount',
  'Summary',
];

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  {
    label: 'Add Fee Or Discount',
    link: '/',
    isActive: true,
  },
];

const validate = (value) => {
  let hasError = false;
  const errors: Record<string, string> = {
    feeType: '',
    feeName: '',
    chargeTo: '',
    investmentIds: '',
    fundIds: '',
    amount: '',
    vat: '',
  };

  const amount = parseFloat(value.amount.replace(/,/g, ''));

  if (!value.feeType) {
    errors.feeType = 'Please select fee type';
    hasError = true;
  }
  if (!value.feeName) {
    errors.feeName = 'Please enter fee or discount name';
    hasError = true;
  }
  if (!value.fundIds) {
    errors.fundIds = 'Please select fund';
    hasError = true;
  }
  if (!amount) {
    errors.amount = 'Please enter fee or discount percentage';
    hasError = true;
  }
  if (!value.feeAccounting) {
    errors.feeAccounting = 'Please select fee accounting type';
    hasError = true;
  }
  if (value.isPercentage === null || value.isPercentage === undefined) {
    errors.isPercentage = 'Please select amount type';
    hasError = true;
  }
  if (!value?.vat) {
    if (value?.vat !== 0) {
      errors.vat = 'Please select VAT';
      hasError = true;
    }
  }
  if (!value.investmentIds.length) {
    errors.investmentIds = 'Please select investors';
    hasError = true;
  }

  return {
    hasError,
    errors,
  };
};

const AddFeeAndDiscount: React.FC = () => {
  const userRole = useGetRole();
  const dispatch = useDispatch();
  const notification = useNotification();
  const [activeStep, setActiveStep] = useState(0);
  const [investments, setInvestments] = useState([]);
  const [allInvestmentsSelected, setAllInvestmentsSelected] = useState(false);
  const [allTranchesSelected, setAllTranchesSelected] = useState(false);
  const [firmId, setFirmId] = useState(userRole.firmId);

  const { tranches } = useFunds({ firmId });

  const initialValue = {
    feeType: '',
    feeName: '',
    investmentIds: [],
    fundIds: [],
    amount: '',
    vat: '',
    inputMethod: 'individual',
    chargeTo: 'investment',
    date: null,
  };

  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState({
    feeType: '',
    feeName: '',
    investmentIds: '',
    fundIds: '',
    amount: '',
    vat: '',
    inputMethod: '',
    chargeTo: '',
    date: '',
  });
  const [investmentIdsError, setInvestmentIdsError] = useState([]);
  const [investmentsWithDateErrors, setInvestmentsWithDateErrors] = useState(
    [],
  );
  const [investmentFees, setInvestmentFees] = useState([]);
  const [investorFees, setInvestorFees] = useState([]);

  const isFee = value?.feeType === type.feeTypes.fee;
  const steps = { AddDetails: 0, BalancesError: 1, Summary: 2 };

  const { calculateInvestmentFees, isCalculatingFees, calculateInvestorFees } =
    useAddFeesAndDiscounts();

  const handleChangeFirm = (firmId) => {
    setFirmId(firmId);
    setValue(initialValue);
  };

  const validateFees = (
    investmentsWithErrorsIds = [],
    investmentsWithDateErrors = [],
  ) => {
    if (!investmentsWithErrorsIds.length && !investmentsWithDateErrors.length)
      return true;

    const insufficientBalance = investmentsWithErrorsIds?.map((id) => ({
      investorName:
        /* @ts-expect-error */
        investments?.find((investment) => investment?.id === id)?.label,
    }));
    const incorrectFeeDates = investmentsWithDateErrors?.map((id) => ({
      investorName:
        /* @ts-expect-error */
        investments?.find((investment) => investment?.id === id)?.label,
    }));

    //@ts-expect-error
    setInvestmentIdsError(insufficientBalance);
    //@ts-expect-error
    setInvestmentsWithDateErrors(incorrectFeeDates);
    return false;
  };

  const handleCalculatedFees = (calculationResult) => {
    if (value.chargeTo === 'investment') {
      const {
        investmentsWithFees,
        investmentsWithErrorsIds,
        investmentsWithDateErrors,
      } = calculationResult;
      handleInvestmentFees(
        investmentsWithFees,
        investmentsWithErrorsIds,
        investmentsWithDateErrors,
      );
    } else {
      const { investorFees, investmentsWithErrorsIds } = calculationResult;
      handleInvestorFees(investorFees, investmentsWithErrorsIds);
    }
  };

  const handleInvestmentFees = (
    validFees,
    investmentsWithErrorsIds,
    investmentsWithDateErrors,
  ) => {
    if (validateFees(investmentsWithErrorsIds, investmentsWithDateErrors)) {
      setInvestmentFees(validFees);
      setActiveStep(steps.Summary);
    } else {
      setActiveStep(steps.BalancesError);
    }
  };

  const handleInvestorFees = (validFees, invalidFees) => {
    if (validateFees(invalidFees)) {
      setInvestorFees(validFees);
      setActiveStep(steps.Summary);
    } else {
      setActiveStep(steps.BalancesError);
    }
  };

  const getSelectedTranches = (trancheDropdownValue) => {
    if (trancheDropdownValue.includes('all')) {
      if (!allTranchesSelected) {
        setAllTranchesSelected(true);
        return tranches.data?.map((tranche) => tranche._id);
      } else {
        setAllTranchesSelected(false);
        return [];
      }
    }
    setAllTranchesSelected(
      trancheDropdownValue.length === tranches.data?.length,
    );
    return trancheDropdownValue;
  };

  const handleGoBack = () => setActiveStep(steps.AddDetails);

  const handleGoToSummary = async () => {
    const { errors, hasError } = validate(value);
    //@ts-expect-error
    setError({ ...errors });

    if (hasError) return;

    const amount = parseFloat(value.amount.replace(/,/g, ''));

    try {
      const response =
        value.chargeTo === 'investor'
          ? //@ts-expect-error
            await calculateInvestorFees({ ...value, firmId, amount })
          : //@ts-expect-error
            await calculateInvestmentFees({ ...value, firmId, amount });

      if (response.statusCode === 200) {
        handleCalculatedFees(response.data);
      }
    } catch (_) {
      notification.error('Error when calculating fees.');
    }
  };

  const loadInvestors = async (fundIds) => {
    if (!fundIds?.length) {
      setInvestments([]);
      return;
    }

    const response = await dispatch<any>(getInvestmentList({ fundIds }));
    if (response.status === 200) {
      const sortedList = sortBy(response.data.data, (investment) =>
        investment.investor.fullName.toLocaleLowerCase(),
      );

      setInvestments(
        //@ts-expect-error
        sortedList.map(
          ({ _id, investor, investmentDate, externalInvestmentId }) => ({
            id: _id,
            label: `${investor?.fullName} (${dateToLabel(investmentDate)}${
              externalInvestmentId ? ` - ${externalInvestmentId}` : ''
            })`,
          }),
        ),
      );
    }
  };

  const onChange = async (event) => {
    const { target } = event;
    if (target.name === 'investmentIds') {
      if (target.value.includes('all')) {
        if (!allInvestmentsSelected) {
          value.investmentIds = investments.map(({ id }) => id);
        } else {
          value.investmentIds = [];
        }
        setAllInvestmentsSelected(!allInvestmentsSelected);
      } else {
        if (target.value.length === investments.length) {
          if (!allInvestmentsSelected)
            setAllInvestmentsSelected(!allInvestmentsSelected);
        } else {
          setAllInvestmentsSelected(false);
        }
        value.investmentIds = target.value;
      }
    } else if (target.name === 'fundIds') {
      value.investmentIds = [];
      value.fundIds = getSelectedTranches(target.value);
      await loadInvestors(value.fundIds);
      setAllInvestmentsSelected(false);
    } else {
      value[target.name] = target.value;
    }

    setValue({ ...value });
  };

  const handleInvestorChargeMode = () => {
    setValue({
      ...value,
      //@ts-expect-error
      feeAccounting: type.feeAccounting.chargedToInvestor,
      isPercentage: false,
      chargeTo: 'investor',
    });
  };

  return (
    <PageContainer
      heading="Fee manager: Add fee or discount"
      breadcrumbs={breadcrumbs}
    >
      <PageContent withSpacing>
        <Spacing size="sm">
          <Heading variant="h2" noMargin>
            {stepLabels[activeStep]}
          </Heading>
          {activeStep === steps.AddDetails && (
            <Typography variant="body2" color="text.secondary">
              Use this section to add a one-off (non-recurring) fee or discount
              to a tranche or selected group of investors.
            </Typography>
          )}
        </Spacing>
        {activeStep === steps.AddDetails ? (
          <AddFeeAndDiscountForm
            onChange={onChange}
            value={value}
            tranches={tranches.data}
            investments={investments}
            allInvestmentsSelected={allInvestmentsSelected}
            allTranchesSelected={allTranchesSelected}
            errors={error}
            onDateChange={(date) => {
              //@ts-expect-error
              setValue((feeData) => ({
                ...feeData,
                date: toRequestDate(date),
              }));
            }}
            firmId={firmId}
            setFirmId={handleChangeFirm}
            isFetchingTranches={tranches.isFetching}
            onUploadProcessed={handleCalculatedFees}
            onSubmit={handleGoToSummary}
            onChargeToInvestor={handleInvestorChargeMode}
            isCalculatingFees={isCalculatingFees}
          />
        ) : activeStep === steps.BalancesError ? (
          <FeesAndDiscountError
            onGoBack={handleGoBack}
            investmentsWithBalanceErrors={investmentIdsError}
            investmentsWithDateErrors={investmentsWithDateErrors}
            isFee={isFee}
            //@ts-expect-error
            dateEntered={value.date}
            chargeTo={value.chargeTo}
            isBulk={value.inputMethod === 'bulk'}
          />
        ) : (
          <Summary
            investmentFees={investmentFees}
            investorFees={investorFees}
            //@ts-expect-error
            chargeTo={value.chargeTo}
            onGoBack={handleGoBack}
            //@ts-expect-error
            firmId={firmId}
          />
        )}
      </PageContent>
    </PageContainer>
  );
};

export default AddFeeAndDiscount;
