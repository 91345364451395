export const investorReportingType = {
  CUSTOM_UPDATE: 'Custom Update',
  PERIODIC: 'Periodic',
  DEPLOYMENT_UPDATE: 'Deployment Update',
  EXIT_STATEMENT: 'Exit Statement',
};

export const InvestorReportingMediaType = {
  SLIDESHOW: 'SLIDESHOW',
  VIDEO: 'VIDEO',
};
export const status = {
  SUBMITTED: 'SUBMITTED',
  DRAFT: 'DRAFT',
};
