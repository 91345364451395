import { InfoOutlined } from '@mui/icons-material';
import AppAutocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import { useTags } from 'hooks/data/tag/useTags';
import { FundStatus } from 'adminConstants/fundProperties';
import { useEffect } from 'react';
import { endOfDay } from 'date-fns';
import PageFilters, { Filter } from 'components/PageFilters';
import { Grid2, styled, Tooltip } from '@mui/material';
import GridContainer from 'components/GridContainer';

const TooltipIcon = styled(InfoOutlined)({
  fontSize: '18px',
  cursor: 'pointer',
  transform: 'translateY(3px)',
  marginTop: '0.4rem',
  marginLeft: '-1rem',
});

const statusFilterOptions = [
  {
    fundStatus: FundStatus.OPEN,
    fullyDeployed: null,
    name: 'Open',
    key: 'open',
  },
  {
    fundStatus: FundStatus.CLOSED,
    fullyDeployed: false,
    name: 'Closed (In deployment)',
    key: 'closed-in-deployment',
  },
  {
    fundStatus: FundStatus.CLOSED,
    fullyDeployed: true,
    name: 'Closed (Fully deployed)',
    key: 'closed-fully-deployed',
  },
  {
    fundStatus: FundStatus.CLOSED,
    fullyDeployed: undefined,
    name: 'Closed (All)',
    key: 'closed-all',
  },
];

const TranchesFilters = ({
  filters,
  onFiltersChange,
  onExportClick,
  isExportLoading,
}) => {
  const { tags } = useTags();

  const qs = new URLSearchParams(window.location.search);
  const fundStatusParam = qs.get('fundStatus');

  useEffect(() => {
    if (fundStatusParam) {
      const foundStatus = statusFilterOptions.find(
        (status) => status.key === fundStatusParam,
      );
      if (foundStatus) {
        onFiltersChange({
          status: foundStatus,
        });
      }
    }
  }, [fundStatusParam]);

  const selectedStatus =
    statusFilterOptions.find((option) => option.key === filters?.status?.key) ||
    null;

  return (
    <PageFilters
      pageId="all-tranches"
      buttons={[
        { label: 'Export', onClick: onExportClick, loading: isExportLoading },
      ]}
    >
      <Filter>
        <TextField
          required
          name="fundName"
          placeholder="Search by tranche name"
          value={filters?.fundName}
          fullWidth
          onChange={(event) => {
            onFiltersChange({
              fundName: event.target.value,
            });
          }}
        />
      </Filter>
      <Filter>
        <AppAutocomplete
          name="tagId"
          options={tags?.data ?? []}
          getOptionLabel={(option) => option?.name}
          filterSelectedOptions
          value={filters?.tagId}
          onChange={(_, newValue) => {
            onFiltersChange({
              tagId: newValue,
            });
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" placeholder="Fund type" />
          )}
        />
      </Filter>
      <Filter>
        <AppAutocomplete
          id="status"
          options={statusFilterOptions}
          getOptionLabel={(option) => option?.name ?? ''}
          value={selectedStatus}
          filterSelectedOptions
          onChange={(_, newValue) => {
            onFiltersChange({
              ...filters,
              status: newValue,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select tranche status"
            />
          )}
        />
      </Filter>
      <Filter>
        <GridContainer>
          <Grid2 size="grow">
            <AppDatePicker
              name="snapshotDate"
              value={filters.snapshotDate}
              onChange={(date) => {
                onFiltersChange({
                  snapshotDate: date ? endOfDay(date) : null,
                });
              }}
              disableFuture={true}
            />
          </Grid2>
          <Grid2 size="auto">
            <Tooltip
              title={
                <>
                  Use the date picker to view this table on any date in the
                  past. <em>Invested capital</em>, <em>Uninvested capital</em>{' '}
                  and <em>Current value</em> will change to reflect their
                  historic values as at the selected date.
                </>
              }
              placement="top"
            >
              <TooltipIcon />
            </Tooltip>
          </Grid2>
        </GridContainer>
      </Filter>
    </PageFilters>
  );
};

export default TranchesFilters;
