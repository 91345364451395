import { FC } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Email, Delete } from '@mui/icons-material';
import {
  useListOrganisationUsers,
  useOrganisationUser,
} from 'hooks/data/organisation/useOrganisationUsers';
import useOrganisationPlatformInvites from 'hooks/data/organisation/useOrganisationPlatformInvites';
import Table, { TableActions } from 'components/Table';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useNotification } from 'hooks/ui/useNotification';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useDisclosure } from 'further-ui/hooks';
import AlertDialog from 'components/AlertDialog';
import { NavLink } from 'react-router-dom';
import { createOrganisationAddUserRoute } from 'adminConstants/routes';

type Props = {
  organisationId: string;
};

const useStyles = makeStyles()(() => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    marginBottom: '1.5rem',
  },
  title: {
    color: '#656565',
    margin: '1.5rem 0',
  },
}));

const OrganisationUsers: FC<Props> = ({ organisationId }) => {
  const { classes } = useStyles();
  const { isSuperAdmin } = useGetRole();
  const { success } = useNotification();
  const [createPermission, deletePermission] = useGetPermissions([
    'create:organisation-users',
    'delete:organisation-users',
  ]);
  const deleteDisclosure = useDisclosure();

  const { data } = useListOrganisationUsers({ organisationId });
  const { resendInvite } = useOrganisationPlatformInvites({
    onResendInviteSuccess: () => {
      success('Organisation invitation sent successfully.');
    },
  });
  const { remove } = useOrganisationUser({ organisationId });

  const columns = [
    { label: 'First name', key: 'firstName' },
    { label: 'Last name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    {
      label: 'Actions',
      key: 'actions',
      render: (row) => (
        <TableActions
          showAsDropdown
          actions={[
            {
              icon: Delete,
              color: 'error',
              visible: deletePermission,
              label: 'Delete',
              onClick: () => deleteDisclosure.stageAction({ id: row._id }),
            },
            {
              icon: Email,
              color: 'primary',
              disabled: isSuperAdmin || resendInvite.isPending,
              label: 'Resend invitation',
              tooltip: isSuperAdmin
                ? 'Resending organisation invites is only possible for firm admin users.'
                : 'Resend invitation to this user.',
              onClick: (e) => {
                e?.preventDefault();
                resendInvite.mutate(row._id);
              },
            },
          ]}
        />
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.flex}>
        <h2 className={classes.title}>Organisation users</h2>
        {createPermission && (
          <NavLink to={createOrganisationAddUserRoute(organisationId)}>
            <Button color="primary" variant="outlined">
              Add user
            </Button>
          </NavLink>
        )}
      </div>
      <Table
        tablebody={data ?? []}
        columns={columns}
        emptyMessage="No organisation users found"
      />
      <AlertDialog
        open={deleteDisclosure.isOpen}
        title="Delete organisation user"
        content="Are you sure you want to delete this user?"
        onClose={deleteDisclosure.onClose}
        onConfirm={() => {
          remove.mutate(deleteDisclosure.actionProps.id);
          deleteDisclosure.onClose();
        }}
        btnLabels={{ confirm: 'Delete user', cancel: 'Cancel' }}
      />
    </div>
  );
};

export default OrganisationUsers;
