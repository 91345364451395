import { IconButton, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { InfoOutlined } from '@mui/icons-material';
import useClosedTrancheDeployments from 'hooks/data/dashboards/useClosedTrancheDeployments';
import { TrancheDeploymentSummary } from '../TrancheDeploymentSummary';
import { useInView } from 'react-intersection-observer';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  gridItem: {
    margin: '1.5rem 0 0.5rem',
  },
}));

const ClosedTrancheDeployments = ({ firmId, classes: parentClasses }) => {
  const { data } = useClosedTrancheDeployments({ firmId });
  const { classes, cx } = useStyles();

  const TrancheItem = ({ closedTranche, parentClasses }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });

    return (
      <Grid size={{ md: 6, xs: 12 }} ref={ref}>
        {inView && (
          <TrancheDeploymentSummary
            tranche={closedTranche.tranche}
            deploymentSummary={closedTranche.deploymentSummary}
            classes={parentClasses}
            displayFundsRaisedInsteadOfDeployed
          />
        )}
      </Grid>
    );
  };

  return (
    <>
      {data?.length > 0 && (
        <Grid
          size={{ xs: 12 }}
          className={cx(parentClasses.dFlex, classes.gridItem)}
        >
          <Typography variant="h2" component="div">
            Tranche deployment progress
          </Typography>
          <Tooltip
            arrow
            title="This section is an overview of the fund's closed tranches that are currently in deployment. To view all tranches (including deployed tranches), navigate to the Closed Tranches section in the side menu."
          >
            <IconButton>
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}

      {/* only show 100 closed tranches for now, in lieu of proper pagination/searching */}
      {data?.slice(0, 100).map((closedTranche) => (
        <TrancheItem
          key={closedTranche.tranche._id}
          closedTranche={closedTranche}
          parentClasses={parentClasses}
        />
      ))}
    </>
  );
};

export default ClosedTrancheDeployments;
