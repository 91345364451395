import React from 'react';
import { InfoOutlined } from '@mui/icons-material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  shareSplitWarning: {
    marginTop: 4,
    fontSize: 15,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: 0,
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationColor: '#b0b0b0',
    textUnderlineOffset: '2px',
    color: '#656565',

    '& svg': {
      marginTop: -2,
      marginLeft: 4,
      marginRight: 8,
    },
  },
}));

type Props = {
  content: React.ReactNode;
  icon?: boolean;
};

const TooltipTriggerText: React.FC<Props> = ({ content, icon }) => {
  const { classes } = useStyles();

  return (
    <span className={classes.shareSplitWarning}>
      {content} {icon ? <InfoOutlined style={{ fontSize: 18 }} /> : null}
    </span>
  );
};

export default TooltipTriggerText;
