import React from 'react';
import { CircularProgress, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import useGetInvestorsLeanResponse from 'hooks/data/investor/useGetInvestorsLeanResponse';
import AppAutocomplete from 'components/FormElements/AppAutocomplete';
import { useGetRole } from 'hooks/ui/useGetRole';

type InvestorSearchProps = {
  onInvestorSelect: (investorId?: string) => void;
  selectedInvestorId?: string;
  firmId?: string;
  error?: boolean;
  helperText?: string;
};

const InvestorSearch: React.FC<InvestorSearchProps> = ({
  onInvestorSelect,
  selectedInvestorId,
  firmId,
  error,
  helperText,
  ...rest
}) => {
  const { isSuperAdmin } = useGetRole();

  const { data: investors, isFetching } = useGetInvestorsLeanResponse({
    ...(isSuperAdmin ? {} : { firmId }),
  });

  if (isFetching) {
    return (
      <Grid size={{ xs: 12 }}>
        <CircularProgress size={24} />
      </Grid>
    );
  }

  return (
    <AppAutocomplete
      {...rest}
      options={investors ?? []}
      id="investorSearch"
      //@ts-expect-error
      getOptionLabel={(option: (typeof investors)[number]) =>
        `${option.fullName} (${option.email})`
      }
      filterSelectedOptions
      //@ts-expect-error
      onChange={(_: unknown, investor: (typeof investors)[number]) => {
        if (!investor) return onInvestorSelect();
        onInvestorSelect(investor._id);
      }}
      value={investors?.find(({ _id }) => _id === selectedInvestorId)}
      renderInput={(params: object) => (
        <TextField
          error={error}
          helperText={helperText}
          {...params}
          variant="outlined"
          placeholder="Search by name or email"
        />
      )}
    />
  );
};

export default InvestorSearch;
