import { FC, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Tooltip, styled } from '@mui/material';
import { Check, CloudDownload, Save } from '@mui/icons-material';
import { status } from 'adminConstants/investorReporting';
import { useInvestorReportingFormContext } from 'contexts/InvestorReportingFormContext';
import { INVESTOR_REPORTING_VIEW } from 'adminConstants/routes';
import { Row } from 'components/Layout';
import Button from 'components/Button';

const FloatingButtonContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  bottom: theme.spacing(5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const FloatingButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  bottom: '-2px',
  borderRadius: '10rem',
  width: '3rem',
  height: '3rem',
  minWidth: 'auto',
  padding: '10px',
  backgroundColor: theme.palette.primary.main,

  '& svg': {
    color: 'white',
    fontSize: '24px',
  },

  '&:disabled svg': {
    color: 'inherit',
  },
}));

type Props = {
  onQuickSave: () => Promise<void>;
  onDownloadSample: () => void;
  isDownloading: boolean;
  allowDownload: boolean;
};

const QuickSaveDraftButton: FC<Props> = ({
  onQuickSave,
  onDownloadSample,
  isDownloading,
  allowDownload,
}) => {
  const [isQuickSaving, setIsQuickSaving] = useState(false);
  const [recentlyQuickSaved, setRecentlyQuickSaved] = useState(false);

  const onButtonClick = async () => {
    setIsQuickSaving(true);
    await onQuickSave();
    setIsQuickSaving(false);
    setRecentlyQuickSaved(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (recentlyQuickSaved) {
        setRecentlyQuickSaved(false);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [recentlyQuickSaved]);

  const isViewPage = useRouteMatch(INVESTOR_REPORTING_VIEW)?.isExact;
  const formContext = useInvestorReportingFormContext();
  const quickSaveDisabled =
    formContext?.investorReport?.status === status.SUBMITTED || isViewPage;

  if (quickSaveDisabled) return null;

  return (
    <FloatingButtonContainer>
      <Row centered justify="center" spacing="xs">
        <FloatingButton
          variant="contained"
          color="primary"
          onClick={onButtonClick}
          disabled={recentlyQuickSaved || isDownloading}
          loading={isQuickSaving}
          iconOnly
        >
          {recentlyQuickSaved ? <Check /> : <Save />}
        </FloatingButton>
        <Tooltip
          title="You have to save your report before you can download a sample."
          disableHoverListener={allowDownload}
        >
          <div>
            <FloatingButton
              variant="contained"
              color="primary"
              onClick={onDownloadSample}
              disabled={isDownloading || !allowDownload}
              iconOnly
              loading={isDownloading}
            >
              <CloudDownload />
            </FloatingButton>
          </div>
        </Tooltip>
      </Row>
    </FloatingButtonContainer>
  );
};

export default QuickSaveDraftButton;
