import { FC } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import TextField from 'components/FormElements/AppTextInput';

type Props = {
  control: Control<any>;
  disabled?: boolean;
  error?: FieldError;
};

const FormReferenceInput: FC<Props> = ({ control, disabled, error }) => {
  return (
    <Controller
      name="reference"
      control={control}
      render={({ field: { ref, value, ...rest } }) => (
        <TextField
          {...rest}
          value={disabled ? 'N/A' : value}
          disabled={disabled}
          inputRef={ref}
          placeholder="Enter reference"
          error={!!error?.message}
          helperText={error?.message || ''}
          type="text"
        />
      )}
    />
  );
};

export default FormReferenceInput;
