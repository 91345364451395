import { Box, Divider, styled } from '@mui/material';
import Heading, { HeadingProps } from 'components/Heading';
import Spacing from 'components/Spacing';
import React, { PropsWithChildren } from 'react';

const SectionDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

type Props = {
  showDivider?: boolean;
  tooltip?: React.ReactNode;
  title?: string;
  variant?: HeadingProps['variant'];
  withSpacing?: boolean;
};

const PageSection: React.FC<PropsWithChildren<Props>> = ({
  variant = 'h2',
  showDivider,
  title,
  tooltip,
  children,
  withSpacing,
}) => {
  const SectionComponent = withSpacing ? Spacing : React.Fragment;
  return (
    <Box>
      {showDivider && <SectionDivider />}
      <SectionComponent>
        {!!title && (
          <Heading tooltip={tooltip} variant={variant} noMargin={withSpacing}>
            {title}
          </Heading>
        )}
        {children}
      </SectionComponent>
    </Box>
  );
};

export default PageSection;
