import React from 'react';
import { GridColumnMenu, GridColumnMenuProps } from '@mui/x-data-grid-pro';

const ColumnMenu: React.FC<GridColumnMenuProps> = (props) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuSortItem: null,
      }}
    />
  );
};

export default ColumnMenu;
