import { styled } from '@mui/material';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';

const SectionRow = styled('div')({
  display: 'flex',
  fontWeight: 'bold',
  overflow: 'visible',
  '& > div:first-of-type': {
    position: 'sticky',
    left: '10px',
  },
  '& > div:last-of-type': {
    width: '100%',
  },
});

const SectionCell: React.FC<
  GridRenderCellParams & { displayedValue: string }
> = (props) => {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (rowNode.type !== 'group') {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <SectionRow onClick={handleClick}>
      <div>{props.displayedValue}</div>
      <div />
    </SectionRow>
  );
};

export default SectionCell;
