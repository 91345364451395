import { useState, useMemo } from 'react';
import {
  Box,
  CardContent,
  Card,
  Divider,
  Skeleton,
  styled,
} from '@mui/material';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import InvestmentInflowChart from './InvestmentInflowChart';
import useInvestmentInflowSummary from 'hooks/data/dashboards/useInvestmentInflowSummary';
import useUnrealisedValueByDate from 'hooks/data/dashboards/useUnrealisedValueByDate';
import Heading from 'components/Heading';
import { DateRangeKey } from 'further-types/dashboard';
import LoadingError from '../LoadingError';
import useInitialElementSize from 'hooks/ui/useInitialElementSize';

const LoadingSkeletonContainer = styled('div')`
  height: 100%;
  padding: 20px 0;
`;

const InvestmentsInflowSummary: React.FC<{ firmId: string }> = ({ firmId }) => {
  const labels = [
    { title: 'All time', slug: DateRangeKey.ALL_TIME, renderCurrentAum: true },
    {
      title: 'Year to date',
      slug: DateRangeKey.THIS_YEAR,
      renderCurrentAum: false,
    },
    {
      title: 'UK tax year to date',
      slug: DateRangeKey.THIS_UK_TAX_YEAR,
      renderCurrentAum: false,
    },
    {
      title: 'Past year',
      slug: DateRangeKey.PAST_YEAR,
      renderCurrentAum: true,
    },
    {
      title: 'Past UK tax year',
      slug: DateRangeKey.PAST_UK_TAX_YEAR,
      renderCurrentAum: false,
    },
    {
      title: 'Past 6 months',
      slug: DateRangeKey.PAST_SIX_MONTHS,
      renderCurrentAum: false,
    },
    {
      title: 'Past quarter',
      slug: DateRangeKey.PAST_QUARTER,
      renderCurrentAum: false,
    },
    {
      title: 'Past month',
      slug: DateRangeKey.PAST_MONTH,
      renderCurrentAum: false,
    },
    {
      title: 'Past week',
      slug: DateRangeKey.PAST_WEEK,
      renderCurrentAum: false,
    },
  ];

  const [selectedDateRange, setSelectedDateRange] = useState(labels[0].slug);
  const {
    data: investmentInflowSummary,
    isFetching: isFetchingInvestmentInflowSummary,
    isRefetching: isRefetchingInvestmentInflowSummary,
    isError: isErrorInvestmentInflowSummary,
  } = useInvestmentInflowSummary({
    firmId,
    dateRangeKey: selectedDateRange,
  });

  const renderCurrentAum = labels.find(
    (label) => label.slug === selectedDateRange,
  )?.renderCurrentAum;

  const {
    data: unrealisedValueByDate,
    isFetching: isFetchingUnrealisedValue,
    isRefetching: isRefetchingUnrealisedValue,
    isError: isErrorUnrealisedValue,
  } = useUnrealisedValueByDate({
    firmId,
    dateRangeKey: selectedDateRange,
    enabled: renderCurrentAum,
  });

  const mergedData = useMemo(() => {
    return (
      investmentInflowSummary?.inflowByDate.map((item) => {
        const unrealisedValueItem = unrealisedValueByDate?.find(
          (unrealisedItem) => unrealisedItem.date === item.date,
        );
        return {
          ...item,
          unrealisedValue: unrealisedValueItem?.unrealisedValue || 0,
        };
      }) || []
    );
  }, [investmentInflowSummary, unrealisedValueByDate]);

  const dataSource = mergedData || {
    inflowByDate: [],
    investmentAmount: 0,
    investors: 0,
    unrealisedValue: 0,
  };

  const isLoading =
    isFetchingInvestmentInflowSummary ||
    isFetchingUnrealisedValue ||
    isRefetchingInvestmentInflowSummary ||
    isRefetchingUnrealisedValue;

  const [ref, { height }] = useInitialElementSize();
  if (isErrorInvestmentInflowSummary && isErrorUnrealisedValue) {
    return <LoadingError height={height} />;
  }

  return (
    <Card ref={ref}>
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom={5}
          alignItems="center"
        >
          <Heading variant="h3" subtitle="All tranches" noMargin>
            Lifetime fundraise record
          </Heading>
          <div>
            <AppSelectBox
              fullWidth
              style={{ minWidth: '180px' }}
              data={labels}
              label="Date range"
              valueKey="slug"
              variant="outlined"
              labelKey="title"
              value={selectedDateRange}
              onChange={(e) =>
                setSelectedDateRange(e.target.value as DateRangeKey)
              }
            />
          </div>
        </Box>
        <Divider />
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          zIndex={1}
          flexBasis="auto"
          flexGrow={1}
          flexShrink={1}
        >
          {isLoading && (
            <LoadingSkeletonContainer>
              <Skeleton
                variant="rounded"
                height="100%"
                width="100%"
                animation="wave"
              />
              <Skeleton variant="text" width="100%" animation="wave" />
            </LoadingSkeletonContainer>
          )}
          {!isLoading && (
            <InvestmentInflowChart
              data={dataSource}
              isFetchingUnrealisedValue={isFetchingUnrealisedValue}
              renderCurrentAum={renderCurrentAum && !isErrorUnrealisedValue}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default InvestmentsInflowSummary;
