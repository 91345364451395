import { useAuth0 } from '@auth0/auth0-react';

const useLogout = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    localStorage.clear();

    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return { handleLogout };
};

export default useLogout;
