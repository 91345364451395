import { Edit } from '@mui/icons-material';
import Table, { TableActions } from 'components/Table';
import PageContainer from 'components/PageContainer';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { usePagination } from 'hooks/ui/usePagination';
import useFilters from 'hooks/ui/useFilters';
import PageContent from 'components/PageContent';
import PageFilters, { Filter } from 'components/PageFilters';
import api from 'lib/trpcClient';
import { VEHICLE_ADD, createVehicleEditRoute } from 'adminConstants/routes';
import { useGetRole } from 'hooks/ui/useGetRole';
import SearchField from 'components/SearchField';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Vehicles', isActive: true },
];

const Vehicles: React.FC = () => {
  const { firmId } = useGetRole();
  const [createPermission, updatePermission] = useGetPermissions([
    'lp:create:vehicle',
    'lp:edit:vehicle',
  ]);
  const pagination = usePagination({
    id: 'vehicles-table',
    order: 'desc',
    orderBy: 'vehicleName',
  });
  const { filters, handleChangeFilters } = useFilters('vehicles-table', {
    defaultFilters: {
      search: '',
    },
    onFiltersChange: pagination.toFirstPage,
  });

  const { data, isFetching } = api.vehicle.listVehiclesPaginated.useQuery({
    ...pagination.properties,
    search: filters.search,
    //@ts-expect-error
    firmId,
  });

  const columns = [
    {
      label: 'Vehicle name',
      key: 'vehicleName',
    },
    {
      label: 'Base currency',
      key: 'baseCurrency',
    },
    {
      label: 'Actions',
      key: '',
      sort: false,
      render: (elm) => (
        <TableActions
          showAsDropdown
          actions={[
            {
              visible: updatePermission,
              label: 'Edit',
              icon: Edit,
              color: 'primary',
              link: createVehicleEditRoute(elm._id),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <PageContainer heading="Vehicles" breadcrumbs={breadcrumbs}>
      <PageContent>
        <PageFilters
          pageId="vehicles-table"
          buttons={[
            {
              visible: createPermission,
              label: 'Add Vehicle',
              link: VEHICLE_ADD,
            },
          ]}
        >
          <Filter>
            <SearchField
              placeholder="Search by vehicle name"
              value={filters.search}
              onSearch={(query) => {
                handleChangeFilters({ search: query });
              }}
              autoSearchOnDebounce
              allowEmptyQuery
              fullWidth
            />
          </Filter>
        </PageFilters>

        <Table
          columns={columns}
          onRequestSort={pagination.handleRequestSort}
          order={pagination.order}
          orderBy={pagination.orderBy}
          tablebody={data?.result ?? []}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          count={data?.total}
          pagination={true}
          loading={isFetching}
          emptyMessage="No vehicles found"
          variant="nohover"
        />
      </PageContent>
    </PageContainer>
  );
};

export default Vehicles;
