import { useNotification } from 'hooks/ui/useNotification';
import { useMutation } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { ExitStatement } from 'further-types/exit';

type SelectedInvestor = {
  _id: string;
  fullName: string;
};

export function useBulkDownloadExitStatementPdf(
  exitStatementId: string,
  exitStatement: ExitStatement,
  selectedInvestors: SelectedInvestor[],
) {
  const notification = useNotification();

  const statementDate = format(
    new Date(exitStatement?.submitDate || new Date()),
    'dd-MM-yyyy',
  );

  const fileName = `${exitStatement?.firm?.firmName} - Exit Statements - ${exitStatement?.company?.tradingName} - ${exitStatement?.company?.shareClass} - ${statementDate}`;

  // Remove any character that is not a letter, number, space, bracket, & or hyphen
  // which would be invalid as a zip folder name
  const sanitisedFileName = fileName.replace(/[^a-zA-Z0-9() \-&_]/g, '');

  async function requestBulkExitStatementPdfData() {
    const response = await api.post(
      `exit/statement/bulk-export-pdf-by-statement/${exitStatementId}`,
      {
        selectedInvestors,
      },
      { responseType: 'blob' },
    );

    if (response.status !== 200) {
      throw new Error('Failed to fetch PDF zip data');
    }

    saveAs(response.data, sanitisedFileName);
  }

  return useMutation({
    mutationFn: requestBulkExitStatementPdfData,
    onError: () => {
      notification.error('Unable to download PDFs');
    },
  });
}
