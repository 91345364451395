import { type } from 'adminConstants/typeConstant';

type Props = {
  isChargedToInvestorDisable?: boolean;
  isAccruedDisable?: boolean;
};

export const getFeeAccountingOptions = ({
  isChargedToInvestorDisable,
  isAccruedDisable,
}: Props = {}) => {
  return [
    {
      label: 'Charged to investor',
      value: type.feeAccounting.chargedToInvestor,
      disabled: isChargedToInvestorDisable,
    },
    {
      label: 'Accrued',
      value: type.feeAccounting.accrued,
      disabled: isAccruedDisable,
    },
  ];
};
