import React, { useState } from 'react';
import { IconButton, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from 'tss-react/mui';
import { Delete } from '@mui/icons-material';
import GridContainer from 'components/GridContainer';
import TextField from 'components/FormElements/AppTextInput';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import { deleteNews } from '@redux/actions/Company';
import { useDispatch } from 'react-redux';
import { useNotification } from 'hooks/ui/useNotification';
import RecordView from 'components/RecordView';
import ButtonGroup from 'components/ButtonGroup';
import AlertDialog from 'components/AlertDialog';

const useStyles = makeStyles()(() => ({
  fieldLabel: {
    display: 'block',
    fontWeight: 'bold',
    marginBottom: 4,
  },
}));

const NewsRows = ({ news, setNews, errorTextNews, classes }) => {
  const { classes: newsClasses, cx } = useStyles();
  const [newsIndex, setSelectedNewsIndex] = useState(0);
  const [openConfirmNews, setOpenConfirmNews] = React.useState(false);
  const dispatch = useDispatch();
  const { error, success } = useNotification();

  const handleInputChangeNews = (e, index, isDate) => {
    if (isDate) {
      const list = [...news];
      list[index]['date'] = e;
      setNews(list);
    } else {
      const { name, value } = e.target;
      const list = [...news];
      list[index][name] = value;
      setNews(list);
    }
  };

  const handleConfirmNewsDelete = () => {
    const list = [...news];
    const selectedNews = news[newsIndex];
    if (selectedNews._id) {
      dispatch<any>(deleteNews(selectedNews._id))
        .then((response) => {
          if (response.status === 200) {
            success(response.data.responseMsg);
            list.splice(newsIndex, 1);
            setNews(list);
          }
        })
        .catch((err) => error(err.response.data.responseMsg));
    } else {
      list.splice(newsIndex, 1);
      setNews(list);
    }

    setOpenConfirmNews(!openConfirmNews);
  };

  const handleRemoveNews = (index) => {
    setSelectedNewsIndex(index);
    setOpenConfirmNews(!openConfirmNews);
  };

  const handleAddNews = () => {
    setNews([
      ...news,
      {
        title: '',
        url: '',
        date: null,
      },
    ]);
  };

  return (
    <RecordView size="full">
      {news &&
        news.map((x, i) => {
          return (
            <div key={i}>
              <label className={newsClasses.fieldLabel}>{`News ${
                i + 1
              }`}</label>
              <GridContainer>
                <Grid size={{ md: 5, xs: 12 }}>
                  <TextField
                    required
                    name="title"
                    placeholder="Enter title"
                    fullWidth
                    onChange={(e) => handleInputChangeNews(e, i, false)}
                    value={x.title}
                    error={errorTextNews[i]?.title}
                    helperText={errorTextNews[i]?.title}
                    variant="outlined"
                  />
                </Grid>
                <Grid size={{ md: 3, xs: 12 }}>
                  <TextField
                    required
                    name="url"
                    placeholder="Enter URL"
                    fullWidth
                    onChange={(e) => handleInputChangeNews(e, i, false)}
                    value={x.url}
                    error={errorTextNews[i]?.url}
                    helperText={errorTextNews[i]?.url}
                    variant="outlined"
                  />
                </Grid>
                <Grid size={{ md: 3, xs: 12 }}>
                  <AppDatePicker
                    required
                    name="date"
                    value={new Date(x.date)}
                    onChange={(e) => handleInputChangeNews(e, i, true)}
                    error={errorTextNews[i]?.date}
                    helperText={errorTextNews[i]?.date}
                  />
                </Grid>
                <Grid size={{ md: 1, xs: 12 }}>
                  {news.length && (
                    <IconButton
                      size="small"
                      className={classes.deleteButton}
                      onClick={() => handleRemoveNews(i)}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Grid>
              </GridContainer>
            </div>
          );
        })}
      <ButtonGroup>
        <Button
          className={cx(classes.marginLeftBtn, 'mb-3')}
          onClick={handleAddNews}
          variant="text"
          color="primary"
        >
          Add News
        </Button>
      </ButtonGroup>
      <AlertDialog
        open={openConfirmNews}
        title={`Confirm delete`}
        content={'Are you sure you wish to delete this news item?'}
        onClose={() => setOpenConfirmNews(false)}
        onConfirm={handleConfirmNewsDelete}
      />
    </RecordView>
  );
};

export default NewsRows;
