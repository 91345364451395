import { useStyles } from './styles';
import AppNumberInput from 'components/FormElements/AppNumberInput';

type Props = any; //TODO: define types

const SharePriceCell: React.FC<Props> = ({
  cell,
  setOverride,
  disableTable,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.flex}>
      <AppNumberInput
        disabled={disableTable}
        decimals={11}
        placeholder="0"
        defaultValue={0}
        min={0}
        gbpStartAdornment={true}
        value={cell.taxableValueSharePrice}
        onChange={(newValue) => {
          setOverride(cell.shareholdingId, {
            taxableValueSharePrice: Number(newValue),
          });
        }}
      />
    </div>
  );
};

export default SharePriceCell;
