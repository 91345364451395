import { ALL_FUNDS } from 'adminConstants/routes';
import { Skeleton, CardContent, Card } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import Grid from '@mui/material/Grid2';
import GridContainer from 'components/GridContainer';
import Box from 'components/Box';
import useClosedTranchesByDeploymentStatusCount from 'hooks/data/dashboards/useClosedTranchesByDeploymentStatusCount';
import Heading from 'components/Heading';
import LoadingError from '../LoadingError';
import useInitialElementSize from 'hooks/ui/useInitialElementSize';

const TrancheCountsByStatus = ({ firmId }) => {
  const {
    data: trancheCountsByStatus,
    isFetching,
    isRefetching,
    isError,
  } = useClosedTranchesByDeploymentStatusCount({
    firmId,
  });

  const isLoading = isFetching || isRefetching;

  const [ref, { height }] = useInitialElementSize();
  if (isError) {
    return <LoadingError height={height} />;
  }

  return (
    <Card ref={ref}>
      <CardContent>
        <Heading
          variant="h3"
          showDivider
          icon={LockOutlined}
          link={{
            to: `${ALL_FUNDS}?fundStatus=closed-all`,
            label: 'View all',
          }}
        >
          Closed tranches
        </Heading>
        <GridContainer>
          <Grid size={{ md: 6, xs: 12 }}>
            <Box
              title={
                isLoading ? (
                  <Skeleton variant="text" animation="wave" />
                ) : (
                  trancheCountsByStatus?.fullyDeployed
                )
              }
              subtitle="Fully deployed"
            />
          </Grid>
          <Grid size={{ md: 6, xs: 12 }}>
            <Box
              title={
                isLoading ? (
                  <Skeleton variant="text" animation="wave" />
                ) : (
                  trancheCountsByStatus?.notFullyDeployed
                )
              }
              subtitle="Closed, not fully deployed"
            />
          </Grid>
        </GridContainer>
      </CardContent>
    </Card>
  );
};

export default TrancheCountsByStatus;
