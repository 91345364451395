import { useEffect } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';
import { Api } from 'further-types/adviser';

type Params = {
  adviserId: string;
  params: Api.AdviserSubscriptionsParams;
};

export function useAdviserSubscriptions(params: Params) {
  const notification = useNotification();

  const fetchQuery = useQuery<Api.AdviserSubscriptionsResponse>({
    queryKey: ['adviser-subscriptions', params],
    queryFn: async () => {
      const response = await api.get(
        `adviser/${params.adviserId}/subscriptions/list`,
        {
          params: params.params,
        },
      );

      return response.data.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
  });

  useEffect(() => {
    if (fetchQuery.isError) {
      notification.error('Could not retrieve subscriptions for this adviser');
    }
  }, [fetchQuery.isError, notification]);

  return fetchQuery;
}
