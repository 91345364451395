import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query';
import { api } from 'lib/httpClient';

async function fetchShareSplits({ queryKey }) {
  const [, { params }] = queryKey;

  try {
    const { data } = await api.get(`share-split/list/${params.companyId}`);

    return data.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data.responseMsg;
    }

    throw new Error(error);
  }
}

async function createShareSplitRequest({ data }) {
  try {
    const response = await api.post(`share-split/create`, data);

    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data.responseMsg;
    }

    throw new Error(error);
  }
}

export function useShareSplits({ params }) {
  const shareSplits = useQuery({
    queryKey: ['shareSplits', { params: { companyId: params?.companyId } }],
    queryFn: fetchShareSplits,
    enabled: !!params?.companyId,
    placeholderData: keepPreviousData,
  });
  return { shareSplits };
}

export function useMutateShareSplit({ params }) {
  const queryClient = useQueryClient();

  const createShareSplit = useMutation({
    mutationFn: createShareSplitRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['shareSplits', { params: { companyId: params?.companyId } }],
      });
    },
  });

  return {
    createShareSplit,
  };
}
