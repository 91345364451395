import React from 'react';
import {
  fundStatusToLabel,
  fundStatusMap,
} from 'adminConstants/fundProperties';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import DataBox from 'components/Box';
import {
  alpha,
  Button,
  CardContent,
  Card,
  Box,
  Divider,
  styled,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from 'tss-react/mui';
import GridContainer from 'components/GridContainer';
import Heading from 'components/Heading';
import { Link } from 'react-router-dom';

const HeadingDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}));

const useStyles = makeStyles()((theme) => ({
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(
      theme.palette.background?.rootColor ?? 'transparent',
      0.15,
    ),
    color: theme.palette.text.rootColor,
    padding: '5px 12px 3px',
    borderRadius: 30,
    lineHeight: '1.5',
    fontWeight: '600',
  },
  viewBtn: {
    padding: '0 !important',
    marginTop: '10px',
    textTransform: 'uppercase',
    fontSize: '12px',
    margin: 'auto',
    display: 'block',
    color: theme.palette.text.rootColor,
  },
}));

type Props = {
  trancheSnapshot: Record<string, any>;
  trancheDetails: Record<string, any>;
  trancheId: string;
  classes: Record<string, string>;
};

export const TrancheSnapshot: React.FC<Props> = ({
  trancheSnapshot,
  trancheDetails,
  trancheId,
}) => {
  const { classes } = useStyles();
  const isClosedTranche = trancheDetails?.fundStatus === fundStatusMap.closed;

  return (
    <Card>
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={5}
        >
          <Heading variant="h3" noMargin>
            Tranche snapshot
          </Heading>
          {trancheDetails?.fundStatus > -1 && (
            <span className={classes.badgeRoot}>
              {fundStatusToLabel[trancheDetails?.fundStatus]}
            </span>
          )}
        </Box>
        <HeadingDivider />
        <GridContainer>
          <Grid size={{ xs: 6 }}>
            <DataBox
              title={numberToCurrencyString(
                trancheSnapshot.sumInvestmentAmount,
                { hidePenniesIfInteger: true },
              )}
              subtitle="Subscriptions"
            >
              <Link to={`/investment?fundId=${trancheId}`}>
                <Button className={classes.viewBtn}>View</Button>
              </Link>
            </DataBox>
          </Grid>
          <Grid size={{ xs: 6 }}>
            <DataBox title={trancheSnapshot.sumInvestors} subtitle="Investors">
              <Link to={`/investor?fundId=${trancheId}`}>
                <Button className={classes.viewBtn}>View</Button>
              </Link>
            </DataBox>
          </Grid>
          <Grid size={{ xs: 6 }}>
            <DataBox
              title={dateToLabel(trancheSnapshot.firstInvestmentDate)}
              subtitle="First subscription date"
            ></DataBox>
          </Grid>
          <Grid size={{ xs: 6 }}>
            {isClosedTranche ? (
              <DataBox
                title={dateToLabel(trancheDetails?.closedDate)}
                subtitle="Tranche close date"
              ></DataBox>
            ) : (
              <DataBox
                title={dateToLabel(trancheDetails?.finishDate)}
                subtitle="Scheduled close date"
              ></DataBox>
            )}
          </Grid>
        </GridContainer>
      </CardContent>
    </Card>
  );
};
