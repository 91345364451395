import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = {
  direction?: 'column' | 'row';
  size?: 'sm' | 'md' | 'lg';
};

const sizeMap = {
  sm: 3,
  md: 6,
  lg: 8,
};

const Spacing: React.FC<PropsWithChildren<Props>> = ({
  children,
  direction = 'column',
  size = 'md',
}) => {
  return (
    <Box display="flex" flexDirection={direction} gap={sizeMap[size]}>
      {children}
    </Box>
  );
};

export default Spacing;
