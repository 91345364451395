export const RegionKeyToLabelMap = {
  scotland: 'Scotland',
  northEast: 'North East',
  northWest: 'North West',
  eastMidlands: 'East Midlands',
  westMidlands: 'West Midlands',
  wales: 'Wales',
  southWest: 'South West',
  southEast: 'South East',
  greaterLondon: 'Greater London',
  ni: 'Northern Ireland',
  dependencies: 'Crown Dependencies',
  unknown: 'Other',
  nonUk: 'Non-UK',
};
