import { Controller, useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';

import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';
import Spacing from 'components/Spacing';
import { InvestmentFirmFormData } from './schema';

const BankAccountDetailsForFees = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<InvestmentFirmFormData>();

  return (
    <Spacing>
      <FieldRow title="Bank account name*" centerTitle>
        <Controller
          name="bankAccountForFeesExport.accountName"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Enter bank account name"
              fullWidth
              error={!!errors.bankAccountForFeesExport?.accountName}
              helperText={errors.bankAccountForFeesExport?.accountName?.message}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Bank account number*" centerTitle>
        <Controller
          name="bankAccountForFeesExport.accountNumber"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <PatternFormat
              {...rest}
              inputRef={ref}
              placeholder="Enter bank account number"
              format="########"
              customInput={TextField}
              type="text"
              error={!!errors.bankAccountForFeesExport?.accountNumber}
              helperText={
                errors.bankAccountForFeesExport?.accountNumber?.message
              }
            />
          )}
        />
      </FieldRow>

      <FieldRow title="Bank account sort code*" centerTitle>
        <Controller
          name="bankAccountForFeesExport.accountSortCode"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <PatternFormat
              {...rest}
              inputRef={ref}
              placeholder="Enter bank sort code"
              format="##-##-##"
              customInput={TextField}
              type="text"
              error={!!errors.bankAccountForFeesExport?.accountSortCode}
              helperText={
                errors.bankAccountForFeesExport?.accountSortCode?.message
              }
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Bank transfer reference*" centerTitle>
        <Controller
          name="bankAccountForFeesExport.reference"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Enter bank transfer reference"
              fullWidth
              error={!!errors.bankAccountForFeesExport?.reference}
              helperText={errors.bankAccountForFeesExport?.reference?.message}
            />
          )}
        />
      </FieldRow>
    </Spacing>
  );
};

export default BankAccountDetailsForFees;
