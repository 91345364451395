import React from 'react';
import { format } from 'date-fns';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Typography, CardContent, Card } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  noDataCard: {
    padding: '3rem',
    textAlign: 'center',
  },
  chartTitle: {
    color: '#282525',
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

type Props = {
  data: Record<string, any>;
};

export const TrancheValueByDate: React.FC<Props> = ({ data }) => {
  const { classes: classnames } = useStyles();

  if (data.message)
    return (
      <Card className={classnames.noDataCard}>
        <CardContent>{data.message}</CardContent>
      </Card>
    );

  return (
    <Card>
      <CardContent>
        <Typography className={classnames.chartTitle}>
          Lifetime tranche performance record
        </Typography>
        <ResponsiveContainer width="100%" height={270}>
          <AreaChart
            data={data.dates}
            margin={{ top: 0, right: 0, left: 0, bottom: 3 }}
          >
            <XAxis dataKey="date" hide />
            <YAxis
              hide
              type="number"
              domain={[0, (dataMax) => dataMax * 1.3]}
            />

            <Tooltip
              labelStyle={{ color: 'black' }}
              cursor={false}
              formatter={(value) =>
                numberToCurrencyString(Number(value), {
                  hidePenniesIfInteger: true,
                })
              }
              labelFormatter={(value) => {
                if (data.dateStepper === 'week')
                  return format(new Date(value), 'E dd/MM/yyyy');
                return dateToLabel(value);
              }}
              itemSorter={(item) => {
                if (item.dataKey === 'unrealisedValue') return 1;
                return -1;
              }}
            />
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="15%" stopColor="#56b26c" stopOpacity={0.6} />
                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
              </linearGradient>
              <linearGradient id="colorRv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="15%" stopColor="#DC9E5A" stopOpacity={0.6} />
                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              name="Unrealised value"
              dataKey="unrealisedValue"
              strokeWidth={3}
              stackId={'1'}
              stroke="#56b26c"
              fill="url(#colorUv)"
            />
            <Area
              type="monotone"
              name="Realised value"
              dataKey="realisedValue"
              strokeWidth={3}
              stackId={'1'}
              stroke="#DC9E5A"
              fill="url(#colorRv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
