import { z } from 'zod';
import { isValidEmail } from 'further-ui/utils';
import { sortBy } from 'lodash';
import {
  CompanyBreakdownDisplayMode,
  TaxReliefDisplayMode,
  InvestmentIdentifierDisplayMode,
  TileType,
  ShowSubscriptionPagePerformanceChartMode,
  SyndicatesArrangement,
} from 'further-types/firm';

export const investmentFirmSchema = z.object({
  firmName: z
    .string({ required_error: 'Please enter a firm name.' })
    .min(1, 'Please enter a firm name.'),
  keyContactName: z
    .string({ required_error: 'Please enter a key contact name.' })
    .min(1, 'Please enter a key contact name.'),
  keyContactEmail: z
    .string({ required_error: 'Please enter a valid email address.' })
    .email({ message: 'Please enter a valid email address.' })
    .min(1, 'Please enter a valid email address.'),
  secondaryContactName: z.string().nullish().optional(),
  secondaryContactEmail: z
    .string()
    .optional()
    .refine((value) => !value || z.string().email().safeParse(value).success, {
      message: 'Please enter a valid email address.',
    }),
  systemEmailRecipient: z
    .string()
    .optional()
    .refine(
      (value) => {
        if (!value) return true;
        const trimmedValue = value.split(';').map((email) => email.trim());
        const isValid = trimmedValue.every((email) => {
          if (email) {
            return isValidEmail(email);
          }
          return true;
        });
        return isValid;
      },
      {
        message:
          'Separate emails with a semi-colon, e.g. name@domain.com;name2@domain.com',
      },
    ),
  excelFilesPassword: z.string().nullish().optional(),
  domain: z.string({ required_error: 'Please enter subdomain.' }),
  firmLogo: z.string().nullable().optional(),
  investmentFirmLogo: z.string().nullable().optional(),
  contactDetails: z.object({
    keyChecks: z.array(z.string().nullish()),
    phoneHours: z.string().optional(),
    email: z
      .string()
      .email({ message: 'Please enter a valid email address.' })
      .optional(),
    phoneNo: z.string().optional(),
  }),
  whiteLabelConfig: z.object({
    colorBgLogo: z.object({ url: z.string().optional() }),
    whiteBgLogo: z.object({ url: z.string().optional() }),
    primaryColor: z.string().optional(),
    primaryHoverColor: z.string().optional(),
    secondaryColor: z.string().optional(),
    bgColor: z.string().optional(),
    clientServiceEmail: z
      .string({ required_error: 'Please enter a valid email address.' })
      .email({ message: 'Please enter a valid email address.' }),
    showInvestmentLink: z.boolean().optional(),
    enableKyb: z.boolean().optional(),
    showMultipleOnInvestment: z.boolean().optional(),
    showMultipleOnInvestedCapital: z.boolean().optional(),
    allowPortalCashWithdrawals: z.boolean().optional(),
    configurableTile1: z.nativeEnum(TileType).optional(),
    configurableTile2: z.nativeEnum(TileType).optional(),
    hideCashBalanceBefore: z.date().optional().nullable(),
    syndicatesArrangement: z.nativeEnum(SyndicatesArrangement).optional(),
    showSubscriptionPagePerformanceChart: z
      .nativeEnum(ShowSubscriptionPagePerformanceChartMode)
      .optional(),
    syndicatePortalDisplayName: z
      .string({
        required_error: 'Please enter a display name for syndicates.',
      })
      .min(1, 'Please enter a display name for syndicates.'),
    emailFooterText: z.string().optional(),
    taxReliefDisplayMode: z.nativeEnum(TaxReliefDisplayMode).optional(),
    companyBreakdownDisplayMode: z
      .nativeEnum(CompanyBreakdownDisplayMode)
      .optional(),
    investmentIdentifierDisplayMode: z
      .nativeEnum(InvestmentIdentifierDisplayMode)
      .optional(),
  }),
  bankAccountForFeesExport: z.object({
    accountName: z
      .string({ required_error: 'Please enter a bank account name.' })
      .min(1, 'Please enter a bank account name.'),
    accountNumber: z
      .string({ required_error: 'Please enter a bank account number.' })
      .min(1, 'Please enter a bank account number.')
      .regex(/^[0-9]{8}$/g, {
        message: 'Please enter an 8 digit account number.',
      }),
    accountSortCode: z
      .string({ required_error: 'Please enter a bank account sort code.' })
      .min(1, 'Please enter a bank account sort code.')
      .regex(/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/, {
        message: 'Please enter an 6 digit account sort code.',
      }),
    reference: z
      .string({ required_error: 'Please enter a bank transfer reference.' })
      .min(1, 'Please enter a bank transfer reference.'),
  }),
  summaryAttachments: z
    .array(
      z.object({
        fileName: z.string().optional(),
        filePath: z.string().optional(),
      }),
    )
    .optional(),
  webhookSettings: z.object({
    newInvestmentWebhookUrl: z.string().url().optional(),
  }),
  withdrawalFeeText: z.string().optional(),
  withdrawalFees: z
    .array(
      z.object({
        vat: z.number(),
        amount: z.number().positive(),
        rangeEnd: z.number().optional().nullable(),
        rangeStart: z.number().optional().nullable(),
      }),
    )
    .optional()
    .refine(
      (fees) => {
        if (!fees?.length) return true;

        return !fees.some(
          (fee) => fee.rangeEnd && !fee.rangeStart && fee.rangeStart !== 0,
        );
      },
      {
        message:
          'A `From` value must be entered if a `To` value is supplied. Please adjust the ranges accordingly.',
      },
    )
    .refine(
      (fees) => {
        if (!fees?.length) return true;

        return !fees.some(
          (fee) => fee.rangeEnd && (fee.rangeStart ?? 0) >= fee.rangeEnd,
        );
      },
      {
        message:
          'The start of each range must be less than the end of the range. Please adjust the ranges accordingly.',
      },
    )
    .refine(
      (fees) => {
        if (!fees?.length) return true;

        // Sort fees by rangeStart to simplify overlap checking
        const sortedFees = sortBy(fees, 'rangeStart', 'asc');

        return !sortedFees.find(
          (fee, idx) =>
            sortedFees[idx - 1] &&
            fee.rangeStart &&
            fee.rangeStart <= (sortedFees[idx - 1]?.rangeEnd || Infinity), // If the previous fee has no rangeEnd, no new higher ranges can apply
        );
      },
      {
        message:
          'There is an overlap in the withdrawal fee ranges. Please adjust the ranges accordingly.',
      },
    )
    .refine(
      (fees) => {
        if (!fees?.length) return true;

        // An open range must be the only fee
        return !(
          fees.length > 1 &&
          fees.some((fee) => !fee.rangeStart && !fee.rangeEnd)
        );
      },
      {
        message:
          'A fee with no range must be the only fee. Please adjust the ranges accordingly',
      },
    ),
});

export type InvestmentFirmFormData = z.infer<typeof investmentFirmSchema>;
