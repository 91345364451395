import {
  keepPreviousData,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import qs from 'qs';

async function fetchClosedTranchesByDeploymentStatusCount({ queryKey }) {
  const [, firmId] = queryKey;
  const queryParams = qs.stringify({ firmId });

  const url = firmId
    ? `dashboard/closed-tranches-by-deployment-status-count?${queryParams}`
    : `dashboard/closed-tranches-by-deployment-status-count`;

  const { data } = await api.get(url);

  return data.data;
}

function useClosedTranchesByDeploymentStatusCount({
  firmId,
}: {
  firmId: string;
}): UseQueryResult<{
  notFullyDeployed: number;
  fullyDeployed: number;
}> {
  return useQuery({
    queryKey: ['closed-tranches-by-deployment-status-count', firmId],
    queryFn: fetchClosedTranchesByDeploymentStatusCount,
    placeholderData: keepPreviousData,
    staleTime: 4 * 60 * 60 * 1000,
  });
}

export default useClosedTranchesByDeploymentStatusCount;
