import { api } from 'lib/httpClient';

export const createWithdrawalRequests = (withdrawals) => {
  return () => {
    return api
      .post(`withdrawals/create`, { withdrawals })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
