import { Box, MenuItem, SvgIconTypeMap, styled } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const ActionItem = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '14px',
  fontWeight: 500,
  display: 'flex',
  gap: theme.spacing(4),
  alignItems: 'center',
  padding: theme.spacing(0.5, 0),
}));

const ActionIcon = styled('svg')(({ theme, color }) => ({
  fontSize: '22px',
  color:
    color === 'primary'
      ? theme.palette.primary.main
      : color === 'error'
      ? theme.palette.text.red
      : 'inherit',
}));

type Props = {
  label: string;
  icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
  color?: 'primary' | 'error' | 'default';
  onClick?: () => void;
  disabled?: boolean;
};

const Action: React.FC<Props> = ({ label, onClick, icon, color, disabled }) => {
  return (
    <MenuItem onClick={onClick} disabled={disabled}>
      <ActionItem>
        {!!icon && <ActionIcon as={icon} color={color} />}
        <span>{label}</span>
      </ActionItem>
    </MenuItem>
  );
};

export default Action;
