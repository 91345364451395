import React, { useEffect, useState } from 'react';
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from 'tss-react/mui';
import { InputProps } from '@mui/material';
import { round } from 'lodash';

const useStyles = makeStyles()({
  input: {
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
});

interface Props extends Omit<OutlinedTextFieldProps, 'onChange' | 'variant'> {
  value?: number;
  defaultValue?: number;
  min?: number;
  max?: number;
  decimals?: number;
  gbpStartAdornment?: boolean;
  percentEndAdornment?: boolean;
  onChange: (value: number) => void;
}

const AppNumberInput: React.FC<Props> = ({
  min,
  max,
  decimals = 0,
  disabled,
  name,
  id,
  fullWidth = true,
  size = 'small',
  value,
  defaultValue = 0,
  onChange,
  helperText = '',
  error = false,
  gbpStartAdornment,
  percentEndAdornment,
  className,
  placeholder = '',
  ...rest
}) => {
  const parseNumber = (value) => {
    const updatedValue =
      typeof value === 'string'
        ? parseFloat(value.replace(/[^0-9.]/g, ''))
        : parseFloat(value);

    if (!isNaN(updatedValue)) {
      return round(updatedValue, decimals);
    }
  };

  const { classes } = useStyles();
  const [fieldValue, setFieldValue] = useState(
    parseNumber(value ?? defaultValue ?? ''),
  );
  const [displayValue, setDisplayValue] = useState<string>(
    fieldValue?.toString() ?? '',
  );

  const numberFormat = Intl.NumberFormat(
    'en-GB',
    decimals
      ? {
          maximumFractionDigits: decimals,
        }
      : {},
  );

  useEffect(() => {
    const newValue = value ?? defaultValue;
    if (newValue !== fieldValue) {
      setFieldValue(newValue);
      if (newValue.toString() === '-') {
        // allow a dash to indicate a blank value
        setDisplayValue(newValue.toString());
      } else {
        setDisplayValue(numberFormat.format(Number(newValue)));
      }
    }
  }, [value, defaultValue, setFieldValue]);

  const inputProps: InputProps = {
    inputProps: {
      step: (10 ** decimals) ** -1,
      min,
      max,
      type: 'text',
      pattern: decimals ? '[0-9]+([,\\.][0-9]+)?' : '[0-9]',
    },
  };

  if (gbpStartAdornment) {
    inputProps.startAdornment = (
      <InputAdornment position="start">£</InputAdornment>
    );
  }
  if (percentEndAdornment) {
    inputProps.endAdornment = (
      <InputAdornment position="start">%</InputAdornment>
    );
  }

  return (
    <TextField
      {...rest}
      className={`${classes.input} ${className}`}
      type={'text'}
      name={name}
      placeholder={placeholder?.toString()}
      id={id ?? name}
      size={size}
      fullWidth={fullWidth}
      value={displayValue}
      onChange={({ target: { value } }) => {
        setFieldValue(parseNumber(value));
        setDisplayValue(value);
      }}
      variant="outlined"
      error={error}
      helperText={helperText}
      disabled={disabled}
      onBlur={() => {
        const parsedValue = parseNumber(fieldValue);
        if (parsedValue) {
          if ((max || max === 0) && parsedValue > max) {
            onChange(max);
            setFieldValue(max);
            setDisplayValue(numberFormat.format(max));
          } else if ((min || min === 0) && parsedValue < min) {
            onChange(min);
            setFieldValue(min);
            setDisplayValue(numberFormat.format(min));
          } else {
            onChange(parsedValue);
            setFieldValue(parsedValue);
            setDisplayValue(numberFormat.format(parsedValue));
          }
        } else if (!isNaN(Number(defaultValue))) {
          const newValue = parseNumber(Number(defaultValue));
          setFieldValue(newValue);
          setDisplayValue(numberFormat.format(newValue ?? 0));
          onChange(defaultValue);
        }
      }}
      onFocus={() => {
        setDisplayValue(`${value ?? defaultValue ?? ''}`);
      }}
      slotProps={{
        input: inputProps,
      }}
    />
  );
};

export default AppNumberInput;
