import React from 'react';
import { Control } from 'react-hook-form';
import { InvestorFormValues } from 'helpers/investor/investorForm';
import PersonalDetails from './PersonalDetails';
import { AccountType } from 'further-types/investor';
import EntityDetails from './EntityDetails';

type Props = {
  id?: string;
  isSuperAdmin: boolean;
  control: Control<InvestorFormValues>;
  accountType: AccountType;
};

const InvestorDetails: React.FC<Props> = ({
  id,
  isSuperAdmin,
  control,
  accountType,
}) => {
  return (
    <>
      {accountType === AccountType.LegalEntity ? (
        <EntityDetails control={control} isSuperAdmin={isSuperAdmin} id={id} />
      ) : (
        <PersonalDetails
          id={id}
          isSuperAdmin={isSuperAdmin}
          control={control}
        />
      )}
    </>
  );
};
export default InvestorDetails;
