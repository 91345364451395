import { FrontEndSiteSubdomainUrlToStageMap } from 'adminConstants/redirectConstant';

const stage = process.env.REACT_APP_STAGE ?? '';

export const createEncodedLink = (tranche, amount) => {
  const encodedAmount = btoa(amount.replace('£', '').toString());
  return `${createTrancheOnboardingLink(tranche)}?amount=${encodedAmount}`;
};

export const createTrancheOnboardingLink = (tranche) => {
  return `${FrontEndSiteSubdomainUrlToStageMap[stage].replace(
    '{domain}',
    tranche?.firmId?.domain,
  )}/fund-onboarding/${tranche.fundNameSlug}`;
};
