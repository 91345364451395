import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import qs from 'qs';

async function fetchOpenTranches({ queryKey }) {
  const [, firmId] = queryKey;
  const queryParams = qs.stringify({
    firmId,
  });

  const url = firmId
    ? `dashboard/open-tranches?${queryParams}`
    : `dashboard/open-tranches`;

  const { data } = await api.get(url);

  return data.data;
}

function useOpenTranches({ firmId }) {
  return useQuery({
    queryKey: ['open-tranches', firmId],
    queryFn: fetchOpenTranches,
    placeholderData: keepPreviousData,
    staleTime: 4 * 60 * 60 * 1000,
  });
}

export default useOpenTranches;
