import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import { FeeCalculationBasis, FeeType } from 'further-types/investment';
import PageContainer from 'components/PageContainer';
import Table, { TableActions } from 'components/Table';
import { usePagination } from 'hooks/ui/usePagination';
import {
  useDeleteOneOffFeesAndDiscounts,
  useGetOneOffFeesAndDiscounts,
} from 'hooks/data/fees/useOneOffFeesAndDiscounts';
import { Delete } from '@mui/icons-material';
import { FEE_DISCOUNT_ADD } from 'adminConstants/routes';
import AlertDialog from 'components/AlertDialog';
import { useDisclosure } from 'further-ui/hooks';
import PageContent from 'components/PageContent';
import PageFilters from 'components/PageFilters';

type OneOffFeesBatch = ReturnType<
  typeof useGetOneOffFeesAndDiscounts
  //@ts-expect-error
>['data']['results'][number];

function getTypeLabel(batch: OneOffFeesBatch) {
  if (!batch.feeType) {
    return 'Bulk upload';
  }

  return `${batch.feeType === FeeType.Fee ? 'Fee' : 'Discount'} ${
    batch.feeCalculationBasis === FeeCalculationBasis.Percentage ? '%' : '£'
  }`;
}

const OneOffFeesAndDiscounts: React.FC = () => {
  const deleteDisclosure = useDisclosure<{ oneOffFeesBatchId: string }>();

  const { handleChangePage, handleChangeRowsPerPage, page, rowsPerPage } =
    usePagination({
      id: 'one-off-fees-and-discounts',
    });

  const oneOffFeesAndDiscounts = useGetOneOffFeesAndDiscounts({
    page,
    rowsPerPage,
  });
  const deleteOneOffFeesBatch = useDeleteOneOffFeesAndDiscounts();

  const handleConfirmDelete = () => {
    deleteOneOffFeesBatch.mutate(
      deleteDisclosure.actionProps?.oneOffFeesBatchId,
    );
    deleteDisclosure.onClose();
  };

  const columns = [
    {
      label: 'Fee name',
      key: 'name',
    },
    {
      label: 'Date',
      key: 'date',
      render: (row: OneOffFeesBatch) => dateToLabel(row.date),
    },
    {
      label: 'Type',
      key: 'type',
      render: (row: OneOffFeesBatch) => getTypeLabel(row),
    },
    {
      label: 'Total fee/discount',
      key: 'totalAmount',
      render: (row: OneOffFeesBatch) => numberToCurrencyString(row.totalAmount),
    },
    {
      label: 'Tranche(s)',
      key: 'fundNames',
      render: (row: OneOffFeesBatch) =>
        row.fundNames.length > 2
          ? `${row.fundNames.slice(0, 2).join(', ')} and ${
              row.fundNames.length - 2
            } more`
          : row.fundNames.length > 0
          ? row.fundNames.join(', ')
          : 'Investor balance fee',
    },
    {
      label: '# investors',
      key: 'investorIds',
      render: (row: OneOffFeesBatch) => row.investorIds.length,
    },
    {
      label: 'Actions',
      key: 'actions',
      sort: false,
      render: (row: OneOffFeesBatch) => (
        <TableActions
          actions={[
            {
              label: 'Delete',
              icon: Delete,
              color: 'error',
              onClick: () => {
                deleteDisclosure.stageAction({ oneOffFeesBatchId: row._id });
              },
              disabled: deleteOneOffFeesBatch.isPending,
            },
          ]}
        />
      ),
    },
  ];

  return (
    <PageContainer heading="Fees: One off fees / discounts">
      <PageContent>
        <PageFilters
          pageId="one-off-fees-and-discounts"
          buttons={[
            {
              link: FEE_DISCOUNT_ADD,
              label: 'Add fee or discount',
            },
          ]}
        />

        <Table
          loading={false}
          columns={columns}
          tablebody={oneOffFeesAndDiscounts.data?.results ?? []}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          count={oneOffFeesAndDiscounts.data?.total ?? 0}
          pagination={true}
          variant="nohover"
          emptyMessage={'No on-off fees / discounts found'}
        />
        <AlertDialog
          onClose={deleteDisclosure.onClose}
          onConfirm={handleConfirmDelete}
          open={deleteDisclosure.isOpen}
          title="Confirm delete"
          content={<p>Are you sure you wish to delete these fees?</p>}
          confirmBtnProps={{ disabled: deleteOneOffFeesBatch.isPending }}
          cancelBtnProps={{ variant: 'outlined', color: 'primary' }}
        />
      </PageContent>
    </PageContainer>
  );
};

export default OneOffFeesAndDiscounts;
