import { Box, styled } from '@mui/material';
import React, { PropsWithChildren } from 'react';

const Container = styled(Box)(({ theme }) => ({
  '&>*': {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

type Props = any;

const ResponsiveActions: React.FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  return (
    <Container
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      alignItems="center"
      gap={2}
    >
      {children}
    </Container>
  );
};

export default ResponsiveActions;
