import React from 'react';

import NavigationItem from './NavigationItem';
import { SidebarMenuItemType } from 'hooks/ui/useSidebarMenuItems';
import { Box } from '@mui/material';

type Props = {
  menuItems: Array<SidebarMenuItemType>;
};

const Navigation: React.FC<Props> = ({ menuItems }) => {
  return (
    <Box position="relative">
      {menuItems.map((item) => (
        <NavigationItem
          item={item}
          key={`navigation-item-${item.type}-${item.name}`}
        />
      ))}
    </Box>
  );
};

export default Navigation;
