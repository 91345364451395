import { PictureAsPdf, Edit, Delete, Visibility } from '@mui/icons-material';
import { TableActions } from 'components/Table';
import {
  createInvestorReportingEditRoute,
  createCustomUpdateViewRoute,
  createDeploymentUpdateViewRoute,
  createInvestorReportingViewRoute,
  createInvestorReportingPdfDownloadRoute,
  createExitStatementViewRoute,
  createExitStatementPdfDownloadRoute,
  createUpdateExitStatementRoute,
} from 'adminConstants/routes';
import {
  investorReportingType,
  status,
} from 'adminConstants/investorReporting';
import React from 'react';
import { useDisclosure } from 'further-ui/hooks/useDisclosure';

type Props = {
  investorReport: {
    _id: string;
    updateType: string;
    status: string;
    viewable: boolean;
  };
  canUpdate: boolean;
  canDelete: boolean;
  deleteDisclosure: ReturnType<typeof useDisclosure>;
};

const ActionTools: React.FC<Props> = ({
  investorReport,
  canUpdate,
  canDelete,
  deleteDisclosure,
}) => {
  const isCustomUpdate =
    investorReport.updateType === investorReportingType.CUSTOM_UPDATE;
  const isDeploymentUpdate =
    investorReport.updateType === investorReportingType.DEPLOYMENT_UPDATE;
  const isPeriodicUpdate =
    investorReport.updateType === investorReportingType.PERIODIC;
  const isExitStatement =
    investorReport.updateType === investorReportingType.EXIT_STATEMENT;

  const isSubmitted = investorReport.status === status.SUBMITTED;

  const showViewButton =
    investorReport.viewable ||
    isCustomUpdate ||
    isDeploymentUpdate ||
    isExitStatement;
  const viewButtonLink = isCustomUpdate
    ? createCustomUpdateViewRoute(investorReport._id)
    : isDeploymentUpdate
    ? createDeploymentUpdateViewRoute(investorReport._id)
    : isExitStatement
    ? createExitStatementViewRoute(investorReport._id)
    : createInvestorReportingViewRoute(investorReport._id);

  const editButtonLink = isExitStatement
    ? createUpdateExitStatementRoute(investorReport._id)
    : createInvestorReportingEditRoute(investorReport._id);

  const showEditButton = canUpdate && !isCustomUpdate && !isDeploymentUpdate;
  const showDeleteButton = canDelete && !isCustomUpdate && !isDeploymentUpdate;

  return (
    <TableActions
      showAsDropdown
      actions={[
        {
          label: 'View',
          icon: Visibility,
          color: 'primary',
          link: viewButtonLink,
          visible: showViewButton,
        },
        {
          label: 'Edit',
          icon: Edit,
          color: 'primary',
          link: editButtonLink,
          visible: showEditButton,
        },
        {
          label: 'Delete',
          icon: Delete,
          color: 'error',
          onClick: () =>
            deleteDisclosure.stageAction({
              id: investorReport._id,
              isExitStatement,
            }),
          visible: showDeleteButton,
        },
        {
          label: 'Download PDFs',
          icon: PictureAsPdf,
          color: 'primary',
          link: createInvestorReportingPdfDownloadRoute(investorReport._id),
          visible: isPeriodicUpdate && isSubmitted,
        },
        {
          label: 'Download PDFs',
          icon: PictureAsPdf,
          color: 'primary',
          link: createExitStatementPdfDownloadRoute(investorReport._id),
          visible: isExitStatement,
        },
      ]}
    />
  );
};

export default ActionTools;
