import React from 'react';
import { IconButton, Button, Box, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Delete } from '@mui/icons-material';
import GridContainer from 'components/GridContainer';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import FieldRow from 'components/FormElements/FieldRow';
import { NumericFormat } from 'react-number-format';
import AppTextInput from 'components/FormElements/AppTextInput';
import TooltipTriggerText from 'components/TooltipTriggerText';

type RevaluationRowsProps = {
  revaluations: Record<string, any>[];
  setRevaluations: (x: Record<string, any>[]) => void;
  errorTextRevaluation: Record<string, any>[];
  companyId: string;
};

const RevaluationRows: React.FC<RevaluationRowsProps> = ({
  revaluations,
  setRevaluations,
  errorTextRevaluation,
  companyId,
}) => {
  const handleInputChange = (e, index, isDate) => {
    if (isDate) {
      const list = [...revaluations];
      list[index]['date'] = e;
      setRevaluations(list);
    } else {
      const { name, value } = e;
      const list = [...revaluations];
      list[index][name] = value;
      setRevaluations(list);
    }
  };

  const handleRemoveRow = (index) => {
    const list = [...revaluations];
    list.splice(index, 1);
    setRevaluations(list);
  };

  const handleAddRow = () => {
    setRevaluations([
      ...revaluations,
      {
        amount: '',
        date: null,
      },
    ]);
  };

  const isDisabled = (revaluation) =>
    revaluation.setVia === 'EXIT' ||
    revaluation.setVia === 'EXIT_AMENDMENT' ||
    revaluation.setVia === 'SHARE_SPLIT' ||
    revaluation.setVia === 'ALLOCATION_WIZARD' ||
    revaluation.setVia === 'INVESTOR_REPORTING';

  const setViaToLabel = (type, shareSplitFactor) => {
    switch (type) {
      case 'DIRECT':
        return 'Set in the company page';
      case 'INVESTOR_REPORTING':
        return 'Set via the update function';
      case 'EXIT':
        return 'Set via an exit';
      case 'EXIT_AMENDMENT':
        return 'Set via an edited exit';
      case 'SHARE_SPLIT':
        return `Set via a share split (Factor: ${shareSplitFactor})`;
      case 'ALLOCATION_WIZARD':
        return 'Set via the allocation wizard';
      default:
        return '';
    }
  };

  return (
    <>
      {revaluations &&
        revaluations.map((x, i) => {
          return (
            <FieldRow
              key={i}
              title={`Revaluation ${i + 1}`}
              centerTitle
              informationText={setViaToLabel(
                x.setVia,
                x.shareSplitId?.shareSplitFactor,
              )}
            >
              <GridContainer alignItems="center">
                <Grid size={{ md: 5 }}>
                  <NumericFormat
                    onValueChange={({ value }) => {
                      handleInputChange(
                        {
                          name: 'amount',
                          value,
                        },
                        i,
                        false,
                      );
                    }}
                    required
                    disabled={isDisabled(x)}
                    name="amount"
                    placeholder="Enter amount"
                    value={x.amount}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    thousandSeparator={true}
                    prefix="£"
                    customInput={AppTextInput}
                    error={errorTextRevaluation[i]?.amount}
                    helperText={errorTextRevaluation[i]?.amount}
                  />
                </Grid>

                <Grid size={{ md: 5 }}>
                  <AppDatePicker
                    required
                    name="date"
                    value={new Date(x.date)}
                    onChange={(e) => handleInputChange(e, i, true)}
                    error={errorTextRevaluation[i]?.date}
                    helperText={errorTextRevaluation[i]?.date}
                    disabled={isDisabled(x)}
                    maxDate={new Date()}
                  />
                </Grid>

                <Grid size={{ md: 2 }}>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => handleRemoveRow(i)}
                    disabled={isDisabled(x)}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </GridContainer>
            </FieldRow>
          );
        })}
      <Box display="flex" justifyContent="flex-end">
        {companyId && (
          <Tooltip
            arrow
            title="If there has been a share split since the company's last share price update, please process this in the share split function before adding a new revaluation."
          >
            <Box display="flex" alignItems="center">
              <TooltipTriggerText content="Share split?" icon />
            </Box>
          </Tooltip>
        )}
        <Button onClick={handleAddRow} variant="text" color="primary">
          Add revaluation
        </Button>
      </Box>
    </>
  );
};

export default RevaluationRows;
