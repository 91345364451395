import { useState } from 'react';
import { usePagination } from 'hooks/ui/usePagination';
import { useAdvisers } from 'hooks/data/adviser/useAdvisers';

export const useLinkedAdvisers = (organisationId?: string) => {
  const [search, setSearch] = useState<string>('');
  const pagination = usePagination({
    id: `organisation-linked-advisers-${organisationId}`,
  });

  const { data, isFetching } = useAdvisers({
    params: {
      search,
      organisationId,
      page: pagination.page,
      perPage: pagination.rowsPerPage,
      keyName: pagination.orderBy,
      type: pagination.order,
    },
  });

  const handleSearch = (value: string) => {
    setSearch(value);
    pagination.toFirstPage();
  };

  return {
    search,
    handleSearch,
    pagination,
    data,
    isFetching,
  };
};
