import { useState } from 'react';
import PageContainer from 'components/PageContainer';
import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';
import { NumericFormat } from 'react-number-format';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { createEncodedLink } from 'helpers/trancheLink/trancheLink';
import { useLocation } from 'react-router-dom';
import FullPageSuccess from 'components/FullPageSuccess';
import { useFunds } from 'hooks/data/fund/useFunds';
import { Autocomplete } from '@mui/material';
import { fundStatusMap } from 'adminConstants/fundProperties';
import PageContent from 'components/PageContent';
import RecordView from 'components/RecordView';
import ButtonGroup from 'components/ButtonGroup';

const useStyles = makeStyles()((theme) => ({
  introCopy: {
    color: '#656565',
    marginBottom: 20,
    marginTop: -10,
  },
  link: {
    color: theme.palette.text.green,
  },
}));

export const TrancheLink: React.FC = () => {
  const { classes } = useStyles();
  const [link, setLink] = useState<string>();
  const [investmentAmount, setInvestmentAmount] = useState(null);

  const { state } = useLocation<{ trancheId: string }>();

  const id = state?.trancheId;
  const [selectedTrancheId, setSelectedTrancheId] = useState(id);

  const {
    tranches: { data },
  } = useFunds({ fundStatus: fundStatusMap.open });

  const tranchesList =
    data
      ?.map((tranche) => {
        return {
          value: tranche._id,
          label: `${tranche.firmId.firmName} ${tranche.fundName}`,
        };
      })
      ?.sort((item) => item.label?.toLowerCase()) ?? [];

  const [errors, setErrors] = useState<{
    investmentAmount: boolean;
    tranche: boolean;
  }>({ investmentAmount: false, tranche: false });

  const copyLinkToClipboard = async () => {
    await navigator.clipboard.writeText(link ?? '');
  };

  const onSubmit = () => {
    if (!selectedTrancheId || !investmentAmount) {
      setErrors({
        ...errors,
        tranche: !selectedTrancheId,
        investmentAmount: !investmentAmount,
      });
      return;
    }
    const tranche = data?.find((tranche) => tranche?._id === selectedTrancheId);
    setLink(createEncodedLink(tranche, investmentAmount));
  };

  const heading = 'Tranches: Bespoke subscription link';
  if (link) {
    const additionalText = (
      <div>
        Your link is available here:{' '}
        <a href={link} className={classes.link}>
          {link}
        </a>
      </div>
    );

    return (
      <PageContainer heading={heading}>
        <FullPageSuccess
          title={'Your link has been successfully created'}
          additionalText={additionalText}
          proceedCallback={copyLinkToClipboard}
          proceedText="Copy link"
          secondaryCallback={() => {
            setLink(undefined);
            setInvestmentAmount(null);
          }}
          secondaryText="Create another link"
        ></FullPageSuccess>
      </PageContainer>
    );
  }

  return (
    <PageContainer heading={heading}>
      <PageContent header="Create a bespoke subscription link">
        <p className={classes.introCopy}>
          Use this section to create a bespoke subscription link for any amount
          into your chosen tranche.
        </p>
        <RecordView>
          <FieldRow title="Tranche" centerTitle>
            <Autocomplete
              disableClearable
              options={tranchesList}
              getOptionLabel={(option) => option.label || ''}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              value={
                tranchesList?.find(
                  (item) => item.value === selectedTrancheId,
                ) ?? null
              }
              onChange={(_event, selectedTranche) =>
                setSelectedTrancheId(selectedTranche.value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Tranche"
                />
              )}
            />
          </FieldRow>

          <FieldRow title="Subscription Amount" centerTitle>
            <NumericFormat
              required
              placeholder="e.g. £5,000"
              value={investmentAmount}
              name={'investmentAmount'}
              customInput={TextField}
              prefix="£"
              type="text"
              error={errors.investmentAmount}
              //@ts-expect-error
              onChange={(event) => setInvestmentAmount(event.target.value)}
              thousandSeparator
            />
          </FieldRow>

          <ButtonGroup>
            <Button onClick={onSubmit} variant="contained" color="primary">
              Create link
            </Button>
          </ButtonGroup>
        </RecordView>
      </PageContent>
    </PageContainer>
  );
};
