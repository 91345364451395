import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PageContainer from 'components/PageContainer';
import { Button, CircularProgress } from '@mui/material';
import { INVESTOR_REPORTING } from 'adminConstants/routes';
import Summary from '../Common/Summary';
import { useReportingAccountManagers } from 'hooks/data/investorReporting/useReportingAccountManagers';
import useExitStatement from 'hooks/data/exit/useExitStatement';
import useStyles from '../Common/styles';
import CreateConfirmationDialog from '../Common/CreateConfirmationDialog';
import { useDisclosure } from 'further-ui/hooks';
import UpdateExitStatementDetails, { FormData } from './UpdateDetail';
import PageContent from 'components/PageContent';
import RecordView from 'components/RecordView';
import ButtonGroup from 'components/ButtonGroup';

type Params = {
  id: string;
  exitAmendmentId?: string;
};

const UpdateExitStatement = () => {
  const history = useHistory();
  const { classes } = useStyles();
  const heading = 'Investor communications: Exit statement';
  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    { label: ' Exit statement', link: '/', isActive: true },
  ];

  const confirmationDisclosure = useDisclosure();

  const { id } = useParams<Params>();

  const steps = { updateExitStatementDetails: 1, summary: 2 };
  const [activeStep, setActiveStep] = useState(
    steps.updateExitStatementDetails,
  );

  const {
    exitStatement: { data, isFetching },
    updateExitStatement,
  } = useExitStatement({ id });

  const statement = data?.exitStatement;

  const { data: managers } = useReportingAccountManagers(
    statement?.company?.firmId,
  );

  const [formValues, setFormValues] = useState<
    FormData & { notifyInvestors?: boolean }
  >({
    title: '',
    summary: '',
    letter: '',
    accountManager: {
      name: '',
      _id: '',
    },
    role: '',
    notes: '',
    notifyInvestors: false,
  });

  const handleNext = () => {
    setActiveStep((currentStep) => currentStep + 1);
  };

  const handleSubmitDetails = (values: FormData) => {
    setFormValues(values);
    handleNext();
  };

  const handleSubmit = () => {
    if (!formValues) return;
    confirmationDisclosure.onClose();
    updateExitStatement.mutate(formValues);
  };

  useEffect(() => {
    if (updateExitStatement.isSuccess) {
      history.push(INVESTOR_REPORTING);
    }
  }, [updateExitStatement.isSuccess]);

  if (isFetching) {
    return <CircularProgress size={20} />;
  }

  const pageTitle =
    activeStep === steps.updateExitStatementDetails
      ? 'Exit statement details'
      : 'Exit statement summary';

  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <PageContent header={pageTitle}>
        <RecordView>
          {steps.updateExitStatementDetails === activeStep ? (
            managers && (
              <UpdateExitStatementDetails
                managers={managers}
                onNext={handleSubmitDetails}
                defaultValues={{
                  title: statement?.title || '',
                  summary: statement?.summary || '',
                  letter: statement?.letter || '',
                  accountManager: {
                    name:
                      managers?.accountManagers.find(
                        (manager) =>
                          manager?._id?.toString() ===
                          statement?.accountManager?._id.toString(),
                      )?.name || '',
                    _id: statement?.accountManager?._id || '',
                  },
                  role: statement?.managerRole || '',
                  notes: statement?.notes || '',
                }}
              />
            )
          ) : (
            <Summary
              title={formValues.title}
              summary={formValues.summary}
              letter={formValues.letter}
              accountManager={formValues.accountManager?.name}
              role={formValues.role}
              notes={formValues.notes}
            />
          )}

          {activeStep === steps.summary && (
            <ButtonGroup>
              <Button
                variant="outlined"
                onClick={() => {
                  setActiveStep(steps.updateExitStatementDetails);
                }}
              >
                Back
              </Button>

              <Button
                className={classes.buttonLeftMargin}
                color="primary"
                variant="contained"
                disabled={updateExitStatement.isPending}
                onClick={confirmationDisclosure.onOpen}
              >
                Submit
              </Button>
            </ButtonGroup>
          )}
        </RecordView>
      </PageContent>

      <CreateConfirmationDialog
        disclosure={confirmationDisclosure}
        notifyInvestors={formValues.notifyInvestors}
        setNotifyInvestors={(value) =>
          setFormValues({ ...formValues, notifyInvestors: value })
        }
        isEdit={true}
        onConfirmCreateStatement={handleSubmit}
        submitting={updateExitStatement.isPending}
      />
    </PageContainer>
  );
};
export default UpdateExitStatement;
