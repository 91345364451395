import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

function configureStore() {
  const store = createStore(() => ({}), {}, applyMiddleware(thunk));
  return store;
}

export default configureStore;
export { history };
