import { api } from 'lib/httpClient';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import downloadFile from 'utils/downloadFile';
import { useNotification } from './useNotification';

async function fetchFile(url) {
  try {
    return api.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
    });
  } catch (error) {
    throw error;
  }
}

export function useDownload({
  filename,
  errorMessage = 'The file is not available for download, please try again later.',
}) {
  const mutation = useMutation({
    mutationFn: fetchFile,
  });
  const notification = useNotification();

  useEffect(() => {
    if (mutation.data) {
      if (mutation.data.status === 200 && mutation.data.data) {
        downloadFile(mutation.data.data, filename);
      }
    }
  }, [mutation.data]);

  useEffect(() => {
    if (mutation.error) {
      notification.error(errorMessage);
    }
  }, [mutation.error]);

  return mutation;
}
