import { FC, ReactNode } from 'react';
import Table from 'components/Table';
import { styled } from '@mui/material';
import useInvestorChangeLog from 'hooks/data/investor/useInvestorChangeLog';
import { getFormattedAddress, dateToLabel } from 'further-ui/utils';
import { startCase } from 'lodash';
import { Api, investorTypeLabel, InvestorType } from 'further-types/investor';

const EmptyBadge = styled('span')({
  padding: '3px 16px 2px 16px',
  borderRadius: '4px',
  border: '1px solid #c5c5c5',
  textTransform: 'uppercase',
  fontSize: '0.65rem',
  color: '#828282',
});

type Props = {
  investorId: string;
};

const getChangedByLabel = (
  changedBy: Api.InvestorChangeLogEntry['changedBy'],
  investorId: string,
) => {
  const name =
    changedBy?.firstName || changedBy?.lastName
      ? `${changedBy.firstName ?? ''} ${changedBy.lastName ?? ''}`
      : 'Unknown user';
  const isChangedBySelf =
    changedBy?.userType === 'investor' && changedBy?._id === investorId;

  if (isChangedBySelf) return 'This investor';
  if (!changedBy) return '';
  return `${name} (${startCase(changedBy?.userType)})`;
};

const formatEntry = (
  entry: [string, any],
  multiFieldChange: boolean,
  emptyBadge: ReactNode,
) => {
  const [field, value] = entry;
  const isAddress = field === 'address';

  if (isAddress && Array.isArray(value) && value.length > 0) {
    return (
      <div>
        {multiFieldChange ? <span>{startCase(field)}: </span> : null}
        <span>{value.map((address) => getFormattedAddress(address))}</span>
      </div>
    );
  }

  const formatArrayValue = (arr: any[]) => {
    if (arr.length === 0) return '';
    return arr.every((item) => item.country)
      ? arr.map((item) => item.country).join(', ')
      : arr.join(', ');
  };

  const formatStringValue = (value: string) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}/;
    if (dateRegex.test(value)) {
      return dateToLabel(value);
    } else if (field === 'investorType') {
      return investorTypeLabel[value as InvestorType];
    } else {
      return value;
    }
  };

  const formatValue = (value: any) => {
    if (value === null || value === undefined || value === '')
      return emptyBadge;
    if (typeof value === 'string') return formatStringValue(value);
    if (Array.isArray(value)) return formatArrayValue(value);
    return JSON.stringify(value); // as a fallback just output whatever we have
  };

  return (
    <div>
      {multiFieldChange ? <span>{startCase(field)}: </span> : null}
      <span>{formatValue(value)}</span>
    </div>
  );
};

const InvestorChangeLog: FC<Props> = ({ investorId }) => {
  const { data } = useInvestorChangeLog(investorId);

  const emptyBadge = <EmptyBadge>Empty</EmptyBadge>;

  const columns = [
    {
      label: 'Details changed',
      key: 'detailsChanged',
      sort: false,
      render: (elm: Api.InvestorChangeLogEntry) =>
        elm.detailsChanged.length > 1
          ? 'Various'
          : startCase(elm.detailsChanged[0]),
    },
    {
      label: 'Original details',
      key: 'originalDetails',
      sort: false,
      render: (elm: Api.InvestorChangeLogEntry) =>
        Object.entries(elm.originalDetails).map((entry) =>
          formatEntry(
            entry,
            Object.keys(elm.originalDetails).length > 1,
            emptyBadge,
          ),
        ),
    },
    {
      label: 'New details',
      key: 'newDetails',
      sort: false,
      render: (elm: Api.InvestorChangeLogEntry) =>
        Object.entries(elm.newDetails).map((entry) =>
          formatEntry(
            entry,
            Object.keys(elm.newDetails).length > 1,
            emptyBadge,
          ),
        ),
    },
    {
      label: 'Date changed',
      key: 'date',
      sort: false,
      render: (elm: Api.InvestorChangeLogEntry) =>
        dateToLabel(elm.date, 'N/A', true),
    },
    {
      label: 'Changed by',
      key: 'changedBy',
      sort: false,
      render: (elm: Api.InvestorChangeLogEntry) =>
        getChangedByLabel(elm.changedBy, investorId),
    },
  ];

  return (
    <Table
      columns={columns}
      tablebody={data ?? []}
      variant="nohover"
      emptyMessage="This investor has no changes."
      pagination={false}
    />
  );
};

export default InvestorChangeLog;
