import { Info } from '@mui/icons-material';
import { useStyles } from './styles';
import AppNumberInput from 'components/FormElements/AppNumberInput';
import { numberToCurrencyString } from 'further-ui/utils';
import { Tooltip } from '@mui/material';

const AccruedFeesCell = ({ cell, setOverride, disableTable }) => {
  const { classes } = useStyles();
  const isInputTooHigh =
    cell?.accruedFeesCharged > cell?.investorTotalAccruedFees;
  const diffMessage =
    cell.accruedFeesSetBelowTarget &&
    'This investor did not have sufficient exited capital for the requested accrued fee percentage to be charged. As much of the investor’s accrued fees as possible have been charged.';

  return (
    <div className={classes.flex}>
      <AppNumberInput
        disabled={disableTable}
        decimals={2}
        placeholder="0"
        defaultValue={0}
        min={0}
        gbpStartAdornment={true}
        value={cell.accruedFeesCharged}
        onChange={(newValue) => {
          setOverride(cell.shareholdingId, {
            accruedFeesCharged: Number(newValue),
          });
        }}
        error={isInputTooHigh}
        helperText={
          isInputTooHigh
            ? `This investor only has ${numberToCurrencyString(
                cell?.investorTotalAccruedFees,
              )} in accrued fees`
            : ''
        }
      />
      {diffMessage && (
        <Tooltip title={diffMessage}>
          <Info style={{ fontSize: 18 }} />
        </Tooltip>
      )}
    </div>
  );
};

export default AccruedFeesCell;
