import { FC } from 'react';
import { Typography, Divider, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { numberToCurrencyString, renderMultiple } from 'further-ui/utils';
import { InfoOutlined } from '@mui/icons-material';
import { Model } from 'further-types/investmentBalance';
import Heading from 'components/Heading';

const useRowStyles = makeStyles()(() => ({
  header: {
    marginBottom: '1rem',
  },
  divider: {
    backgroundColor: '#EDEDED',
    margin: '2rem 0',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #EDEDED',
    padding: '1rem 0.75rem 1rem 0',
    position: 'relative',
  },
  noBorderBottom: {
    borderBottom: 'none',
  },
  summaryRow: {
    backgroundColor: '#FBFBFA',
    padding: '0.5rem 0.75rem 0.5rem 0',
  },
  postSummaryRow: {
    backgroundColor: '#FBFBFA',
    padding: '1.25rem 0.75rem 1.25rem 0',
  },
  largeSummaryRow: {
    backgroundColor: 'rgba(196, 196, 196, 0.4)',
    padding: '1.25rem 0.75rem 1.25rem 0',
  },
  labelText: {
    fontWeight: 'normal',
    padding: 0,
    margin: '0 0 0 0.75rem',
    display: 'flex',
    alignItems: 'center',
  },
  valueText: {
    fontWeight: 'bold',
    padding: 0,
    margin: 0,
  },
  bold: {
    fontWeight: 'bold',
  },
  normal: {
    fontWeight: 'normal',
  },
  italic: {
    fontStyle: 'italic',
  },
  sectionTitle: {
    fontWeight: 'bold',
    margin: '1.5rem 0 0.5rem 0.75rem',
    color: '#656565',
  },
}));

const useRowAccentStyles = makeStyles<{ accent?: string }>()((_, params) => ({
  accent: {
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: '25%',
      height: '50%',
      width: '4px',
      backgroundColor: params.accent || 'transparent',
    },
  },
}));

type RowProps = {
  label: string;
  value: number;
  bold?: boolean;
  subtraction?: boolean;
  accent?: string;
  noBorderBottom?: boolean;
  tooltipText?: string;
};

const Row: FC<RowProps> = ({
  label,
  value,
  bold,
  subtraction,
  accent,
  noBorderBottom,
  tooltipText,
}) => {
  const { classes: rowClasses, cx } = useRowStyles();
  const { classes: accentClasses } = useRowAccentStyles({ accent });

  const labelClassname = cx(
    rowClasses.labelText,
    bold && rowClasses.bold,
    subtraction && rowClasses.italic,
  );
  const rowClassname = cx(
    rowClasses.row,
    accentClasses.accent,
    noBorderBottom && rowClasses.noBorderBottom,
  );

  return (
    <div className={rowClassname}>
      <Typography className={labelClassname} component="h4" variant="inherit">
        {label}
        {tooltipText && (
          <Tooltip title={tooltipText}>
            <InfoOutlined style={{ marginLeft: '0.5rem', fontSize: 18 }} />
          </Tooltip>
        )}
      </Typography>
      <Typography
        className={rowClasses.valueText}
        component="h4"
        variant="inherit"
      >
        {subtraction
          ? `(${numberToCurrencyString(value)})`
          : numberToCurrencyString(value)}
      </Typography>
    </div>
  );
};

type SummaryRowProps = {
  label: string;
  value: number;
  large?: boolean;
};

const SummaryRow: FC<SummaryRowProps> = ({ label, value, large }) => {
  const { classes: rowClasses, cx } = useRowStyles();

  const extraRowClass = large
    ? rowClasses.largeSummaryRow
    : rowClasses.summaryRow;

  return (
    <div className={cx(rowClasses.row, extraRowClass)}>
      <Typography
        className={cx(rowClasses.labelText, rowClasses.bold)}
        component="h4"
        variant="inherit"
      >
        {label}
      </Typography>
      <Typography
        className={rowClasses.valueText}
        component="h4"
        variant="inherit"
      >
        {numberToCurrencyString(value)}
      </Typography>
    </div>
  );
};

const PostSummaryRow = ({ label, value }) => {
  const { classes: rowClasses, cx } = useRowStyles();

  return (
    <div className={cx(rowClasses.row, rowClasses.postSummaryRow)}>
      <Typography
        className={rowClasses.labelText}
        component="h4"
        variant="inherit"
      >
        {label}
      </Typography>
      <Typography
        className={cx(rowClasses.valueText, rowClasses.normal)}
        component="h4"
        variant="inherit"
      >
        {renderMultiple(value)}
      </Typography>
    </div>
  );
};

type Props = {
  investmentBalance?: Model.InvestmentBalance;
};

const InvestmentBalanceAndValue: FC<Props> = ({ investmentBalance }) => {
  const { classes: rowClasses } = useRowStyles();

  if (!investmentBalance) return null;

  const {
    deploymentSummary,
    realisedValue,
    unrealisedValue,
    valueChangesOnInitialInvestment,
    investmentValue,
  } = investmentBalance;

  return (
    <>
      <Row
        label="Commitment received"
        value={deploymentSummary.commitmentReceived}
      />
      <Row
        label="- Of which allocated to holdings"
        value={deploymentSummary.purchasePriceOfAllShareholdings}
      />
      <Row
        label="- Of which allocated to cash fees"
        value={deploymentSummary.cashFeesOnInitialInvestment}
      />
      <Row
        label="- Of which undeployed"
        value={deploymentSummary.undeployedInitialInvestment}
      />
      <Row
        label="Reserved for future cash fees"
        value={deploymentSummary.reservedForFutureCashFee}
        bold
        accent="#E07973"
      />
      <Row
        label="Transferred to investor cash balance"
        value={deploymentSummary.transferredToInvestorCashBalance}
        bold
        accent="#7BCDEF"
      />
      <Row
        label="Held by manager awaiting investment"
        value={deploymentSummary.heldByFundAwaitingInvestment}
        bold
        accent="#56B26C"
        noBorderBottom
      />
      <Divider className={rowClasses.divider} />
      <Heading variant="h2">Subscription value</Heading>
      <Typography className={rowClasses.sectionTitle} component="h4">
        Realised return
      </Typography>
      <Row
        label="Total exit value"
        value={realisedValue?.exitProceeds}
        tooltipText="This is the subscription’s total gross exit value net of performance fees."
      />
      <Row label="Dividends" value={realisedValue?.dividends} />
      <Row label="Interest" value={realisedValue?.interest} />
      <Row
        label="Undeployed capital transferred to investor cash balance"
        value={realisedValue?.residualCash}
      />
      <Row
        label="Less accrued fees charged to investor"
        value={realisedValue?.accruedFeesChargedToInvestor}
        subtraction
      />
      <SummaryRow label="Total realised return" value={realisedValue.total} />
      <Typography className={rowClasses.sectionTitle} component="h4">
        Unrealised return
      </Typography>
      <Row
        label="Current value of unexited shareholdings"
        value={unrealisedValue.valueOfAllShareholdings}
      />
      <Row
        label="Undeployed capital held by fund"
        value={deploymentSummary.undeployedInitialInvestmentHeldByFund}
      />
      <Row
        label="Accrued fees not yet charged"
        value={unrealisedValue.accruedFeesNotYetCharged}
        subtraction
        tooltipText="This fee figure is presented here for information but is not included in the total unrealised return figure, as the fees are contingent until charged."
      />
      <SummaryRow
        label="Total unrealised return"
        value={unrealisedValue.total}
      />
      <SummaryRow
        large
        label="Total investment value"
        value={investmentValue}
      />
      <PostSummaryRow
        label="Multiple on investment"
        value={valueChangesOnInitialInvestment.multipleOnInvestment}
      />
      <PostSummaryRow
        label="Multiple on invested capital"
        value={valueChangesOnInitialInvestment.multipleOnInvestedCapital}
      />
    </>
  );
};

export default InvestmentBalanceAndValue;
