import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/firm';
import { api } from 'lib/httpClient';
import { compact } from 'lodash';

function stripUrl(data, id?: string) {
  const value = data;

  if (id) {
    value.id = id;
  }

  value.whiteLabelConfig.colorBgLogo.key =
    value.whiteLabelConfig.colorBgLogo.url;
  value.whiteLabelConfig.whiteBgLogo.key =
    value.whiteLabelConfig.whiteBgLogo.url;

  // TODO: Clean this up and move it to a helper function
  if (value?.investmentFirmLogo?.includes('.com/')) {
    value.investmentFirmLogo = value.investmentFirmLogo.split('.com/')[1];
  }

  if (value?.firmLogo?.includes('.com/')) {
    value.firmLogo = value.firmLogo.split('.com/')[1];
  }

  if (value?.whiteLabelConfig?.colorBgLogo?.key?.includes('.com/')) {
    value.whiteLabelConfig.colorBgLogo.key =
      value.whiteLabelConfig.colorBgLogo.key.split('.com/')[1];
    value.whiteLabelConfig.colorBgLogo.url =
      value.whiteLabelConfig.colorBgLogo.url.split('.com/')[1];
  }

  if (value?.whiteLabelConfig?.whiteBgLogo?.key?.includes('.com/')) {
    value.whiteLabelConfig.whiteBgLogo.key =
      value.whiteLabelConfig.whiteBgLogo.key.split('.com/')[1];
    value.whiteLabelConfig.whiteBgLogo.url =
      value.whiteLabelConfig.whiteBgLogo.url.split('.com/')[1];
  }

  if (value?.firmLogo?.includes('.com/')) {
    value.firmLogo = value.firmLogo.split('.com/')[1];
  }

  return value;
}

async function createFirmRequest(data) {
  const value = stripUrl(data);

  value.contactDetails.keyChecks = compact(value.contactDetails.keyChecks);

  try {
    const response = await api.post(`firm`, value);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data.responseMsg;
    }
    throw error;
  }
}

async function updateFirmRequest(id, data) {
  const value = stripUrl(data, id);

  value.contactDetails.keyChecks = compact(value.contactDetails.keyChecks);

  try {
    const response = await api.put(`firm/${id}`, value);

    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data.responseMsg;
    }

    throw new Error(error);
  }
}

async function createAccountManagerRequest(accountManagerData, firmId) {
  try {
    const response = await api.post(`/account-manager/create`, {
      accountManagerData,
      firmId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function updateAccountManagerRequest(data) {
  try {
    const response = await api.put(`/account-manager/update/${data.id}`, data);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data.responseMsg;
    }

    throw new Error(error);
  }
}
async function deleteAccountManagerRequest(id) {
  try {
    const response = await api.delete(`/account-manager/${id}`);
    return response;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data.responseMsg;
    }
    throw new Error(error);
  }
}

export function useFirm({
  params,
}: {
  params: Api.GetFirmListRequestQuery & {
    disabled?: boolean;
  };
}) {
  const queryClient = useQueryClient();

  const firm = useQuery({
    queryKey: ['firms', { params: { firmId: params.firmId } }],
    queryFn: async () => {
      const { data } = await api.get<ApiResponse<Api.GetFirmResponse>>(
        `firm/${params.firmId}`,
      );

      return data.data;
    },
    placeholderData: keepPreviousData,
    enabled: !!params?.firmId,
  });

  const firms = useQuery({
    queryKey: ['firms', { ...params }],
    queryFn: async () => {
      const { data } = await api.get<ApiResponse<Api.GetFirmListResponse>>(
        'firm',
        { params },
      );

      return data.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
    enabled: !params.disabled,
  });

  const createFirm = useMutation({
    //@ts-expect-error
    mutationFn: ({ data }) => createFirmRequest(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['firms'],
      });
    },
  });

  const updateFirm = useMutation({
    mutationFn: (data) => updateFirmRequest(params?.firmId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['firms'],
      });
    },
  });
  const createAccountManager = useMutation({
    //@ts-expect-error
    mutationFn: ({ accountManagerData, firmId }) =>
      createAccountManagerRequest(accountManagerData, firmId),
  });
  const updateAccountManager = useMutation({
    mutationFn: (data) => updateAccountManagerRequest(data),
  });
  const deleteAccountManager = useMutation({
    mutationFn: (id) => deleteAccountManagerRequest(id),
  });

  return {
    firm,
    firms,
    createFirm,
    updateFirm,
    createAccountManager,
    updateAccountManager,
    deleteAccountManager,
  };
}
