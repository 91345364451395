import React from 'react';
import { Box, Card } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CountUp from 'react-countup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles<void, string>()((_theme, _params, classes) => ({
  cardRoot: {
    position: 'relative',
    paddingLeft: 95,
    minHeight: 120,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:hover': {
      boxShadow:
        '0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2)',
      [`& .${classes.iconThumb}`]: {
        width: 95,
        height: '100%',
        borderRadius: 0,
      },
      [`& .${classes.hoverContent}`]: {
        transform: 'translate(0, 0)',
      },
    },
  },
  cardContent: {
    padding: 20,
  },
  iconWrapper: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: 95,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconThumb: {
    width: 56,
    height: 56,
    transition: 'all 0.3s ease',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hoverContent: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    padding: 10,
    transform: 'translate(100%, 0)',
    transition: 'all 0.3s ease',
    color: '#fff',
    background: '#fafafa',

    '& span': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      color: '#56B26C',
    },
  },
}));

interface Props {
  icon?: React.ReactNode;
  backgroundColor?: string;
  title?: string | number;
  subTitle?: string;
  linkOnArrow?: string;
}

const HoverInfoCard: React.FC<Props> = ({
  backgroundColor,
  icon,
  title,
  subTitle,
  linkOnArrow,
}) => {
  const { classes } = useStyles();

  const content = (
    <Card className={classes.cardRoot}>
      <Box className={classes.iconWrapper}>
        <Box className={classes.iconThumb} style={{ backgroundColor }}>
          {icon}
        </Box>
      </Box>
      <Box className={classes.cardContent}>
        <Box
          component="h2"
          fontSize={{ xs: 18, md: 20, xl: 22 }}
          fontWeight="fontWeightBold"
        >
          {typeof title === 'number' ? (
            <CountUp start={0} end={title} useEasing={false} />
          ) : (
            title
          )}
        </Box>
        <Box
          component="span"
          fontSize={16}
          fontWeight="fontWeightBold"
          color="text.secondary"
        >
          {subTitle}
        </Box>
      </Box>
      {linkOnArrow && (
        <Box className={classes.hoverContent}>
          <span>
            <ArrowForwardIcon />
          </span>
        </Box>
      )}
    </Card>
  );

  if (!linkOnArrow) return content;

  if (linkOnArrow.startsWith('http'))
    return (
      <a href={linkOnArrow} target="blank">
        {content}
      </a>
    );

  return <NavLink to={linkOnArrow || ''}>{content}</NavLink>;
};

export default HoverInfoCard;
