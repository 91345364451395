import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { sumBy, round } from 'lodash';
import Chip from 'components/Chip';
import { CompareArrows } from '@mui/icons-material';
import { Api } from 'further-types/investment';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles()(() => ({
  ml: {
    marginLeft: '0.5rem',
  },
}));

type Props = {
  paymentExpectations: Array<Api.InvestmentPaymentExpectation>;
  fundsReceived: number;
  isTransferorInvestment: boolean;
  isTransfereeInvestment: boolean;
};

const FundsReceivedStatusLabel: FC<Props> = ({
  paymentExpectations,
  fundsReceived,
  isTransferorInvestment,
  isTransfereeInvestment,
}) => {
  const { classes } = useStyles();
  const fundsExpected = round(sumBy(paymentExpectations, 'amount'), 2);

  if (isTransferorInvestment) {
    return (
      <span className={classes.ml}>
        <Tooltip
          title={
            <>
              For transferred subscriptions, the notional{' '}
              <em>Total commitment</em> is used to calculate{' '}
              <em>Commitment received.</em>
            </>
          }
        >
          <CompareArrows />
        </Tooltip>
      </span>
    );
  }

  if (isTransfereeInvestment) {
    return (
      <span className={classes.ml}>
        <Tooltip
          title={
            <>
              For subscriptions resulting from transfers, the notional{' '}
              <em>Total commitment</em> is used to calculate{' '}
              <em>Commitment received.</em>
            </>
          }
        >
          <CompareArrows />
        </Tooltip>
      </span>
    );
  }

  if (fundsReceived > 0) {
    if (fundsReceived >= fundsExpected) {
      return (
        <span className={classes.ml}>
          <Chip variant="success">Fully funded</Chip>
        </span>
      );
    } else {
      return (
        <span className={classes.ml}>
          <Chip variant="disabled">Partially funded</Chip>
        </span>
      );
    }
  }

  return null;
};

export default FundsReceivedStatusLabel;
