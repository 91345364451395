import React, { useState, useContext } from 'react';
import { useLocalStorage } from 'usehooks-ts';

type LayoutContextType = {
  isSidebarOpen: boolean;
  setSidebarOpen: (isSidebarOpen: boolean) => void;
  selectedVehicleId: string | null;
  setSelectedVehicleId: (vehicleId: string | null) => void;
};

const LayoutContext = React.createContext<LayoutContextType>({
  isSidebarOpen: false,
  setSidebarOpen: () => {},
  selectedVehicleId: null,
  setSelectedVehicleId: () => {},
});

const LayoutContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useLocalStorage<
    string | null
  >('selectedVehicleId', null);

  const layoutContextValue = {
    isSidebarOpen,
    setSidebarOpen,
    selectedVehicleId,
    setSelectedVehicleId,
  };

  return (
    <LayoutContext.Provider value={layoutContextValue}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);

export default LayoutContextProvider;
