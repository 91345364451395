import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Api } from 'further-types/investmentTransfer';
import { ApiResponse } from 'further-types/api';
import { api } from 'lib/httpClient';
import downloadFile from 'utils/downloadFile';

type Params = {
  page?: number;
  rowsPerPage?: number;
  search?: string;
  startDate?: Date;
  finishDate?: Date;
  order: string;
  orderBy: string;
};

const exportInvestmentTransfers = async (
  investmentTransfersIds: Array<string>,
) => {
  const response = await api.get(`investment-transfer/export`, {
    responseType: 'blob',
    params: {
      investmentTransfersIds,
    },
  });

  if (
    response.status !== 200 ||
    !response.headers['content-type']?.startsWith(
      'application/vnd.openxmlformats',
    )
  ) {
    throw Error('Unexpected export response.');
  }

  downloadFile(response.data, `Investment-Transfer-${Date.now()}.xlsx`);
};

const useInvestmentTransfers = (params: Params) => {
  return {
    investmentTransfers: useQuery({
      queryKey: ['investment-transfers', params],
      queryFn: async () => {
        const result = await api.get<
          ApiResponse<Api.GetInvestmentTransfersResponse>
        >('/investment-transfer', { params });

        return result.data.data;
      },
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
    }),
    exportInvestmentTransfers,
  };
};

export default useInvestmentTransfers;
