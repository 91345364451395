import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

type Props = {
  top?: Size;
  bottom?: Size;
  left?: Size;
  right?: Size;
  className?: string;
};

const StyledElement = styled('div')(({ theme, top, bottom, left, right }) => ({
  marginTop: theme.spacing(top),
  marginBottom: theme.spacing(bottom),
  marginLeft: theme.spacing(left),
  marginRight: theme.spacing(right),
}));

const marginMap = {
  xs: 2,
  sm: 4,
  md: 5,
  lg: 6,
  xl: 8,
  xxl: 10,
};

const Margin: React.FC<PropsWithChildren<Props>> = ({
  children,
  top,
  bottom,
  left,
  right,
  className,
}) => {
  return (
    <StyledElement
      className={className}
      top={top ? marginMap[top] : 0}
      bottom={bottom ? marginMap[bottom] : 0}
      left={left ? marginMap[left] : 0}
      right={right ? marginMap[right] : 0}
    >
      {children}
    </StyledElement>
  );
};

export default Margin;
